<template lang="pug">
.main-column
  .main-column_header
    back-button(v-if="backButton"
                :text="backButtonText"
                @click="$emit('back')")
    .select-panel__title {{ title }}
    search-and-create-box(:search="searchTerms"
                          @create="$emit('create', $event)"
                          @search="onSearch")
  ul.main-column_list.scroll(v-show="items.length || newName"
                            ref="scrollWrapper")
    li.main-column_list-item(v-if="newName")
      aiq-button.is-selected.el-button--list-item
        span {{ newName }}
    li.main-column_list-item(v-for="item in items"
                            :key="item.id"
                            @click="$emit('select', item.id)")
      aiq-button.el-button--list-item(
                :class="getItemClass(item.id)")
        span {{ item.name }}
        i.el-icon-close.button-delete(@click.stop.prevent="$emit('delete', item.id)")
    infinite-loading(class="infinite-loading"
                     :distance="100"
                     :identifier="identifier"
                     @infinite="$emit('load', $event, searchTerms)")
  .no-items-container(v-if="!newName && !items.length") No items found
</template>

<script>
/**
 * This component is intended to be used for searching. It also emits an object on load that
 * controls the loading state.
 */
import get from 'lodash/get';

import BackButton from '@/components/BackButton.vue';
import SearchAndCreateBox from '@/components/SearchAndCreateBox/SearchAndCreateBox.vue';

import SearchListScrollerMixin from '@/libs/mixins/SearchListScroller';
import { KEYBOARD_KEYS } from '@/constants';

export default {
  components: {
    BackButton,
    SearchAndCreateBox,
  },
  mixins: [SearchListScrollerMixin],
  props: {
    backButton: Boolean,
    backButtonText: {
      type: String,
      default: 'Back',
    },
    // Item should have an 'id' and 'name' key
    items: {
      type: Array,
      default: () => [],
    },
    newName: String,
    selectedId: [String, Number],
    title: {
      type: String,
      default: '',
    },

    searchTerms: {
      type: String,
      default: '',
    },
    identifier: {
      type: [String, Number],
      default: '',
    },
  },
  emits: ['select', 'search', 'load', 'back', 'create', 'delete'],
  data() {
    return {
      createBoxShown: false,
      dataLoaded: false,
      newItemName: '',
      searchSubscription: null,

      // For SearchListScrollerMixin
      highlightedKey: null,
      useParentOffset: true,
    };
  },
  watch: {
    searchTerms() {
      this.highlightedKey = null;
      this.scrollToTop();
    },
    selectedId(id) {
      const item = this.items.find(i => i.id === id);

      this.highlightedKey = get(item, 'id', null);
    },
  },
  created() {
    // Select first item by default if it exists
    if (!this.selectedId && this.items[0]) {
      this.$emit('select', this.items[0].id);
    }
  },
  mounted() {
    const scrollWrapper = this.$refs.scrollWrapper;
    scrollWrapper.addEventListener('keydown', this.onKeyDown);
  },
  beforeUnmount() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }

    const scrollWrapper = this.$refs.scrollWrapper;
    scrollWrapper.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    getItemClass(id) {
      const highlightedItem = this.items[this.highlightedIdx];
      const showHighlight = this.shouldShowHighlight(highlightedItem, id);

      return {
        'is-selected': this.selectedId === id,
        'is-highlighted': showHighlight,
      };
    },
    onKeyDown(evt) {
      const { key } = evt;
      evt.preventDefault();

      switch (key) {
        case KEYBOARD_KEYS.BACKSLASH:
          this.$el.querySelector('#item-search').focus();
          break;
        case KEYBOARD_KEYS.ENTER: {
          if (this.highlightedKey) {
            this.$emit('select', this.highlightedKey);
          }
          break;
        }
        default:
      }
    },
    onSearch(terms) {
      this.$emit('search', terms);
    },
    scrollToTop() {
      const container = this.$refs.scrollWrapper;
      if (container) { container.scrollTop = 0 }
    },
    shouldShowHighlight(highlightedItem, id) {
      return highlightedItem && (highlightedItem.id === id);
    },
  },
};
</script>

<style lang="scss" scoped>
.selection-title {
  align-items: center;
  display: flex;
  margin-bottom: 10px;

  .el-button {
    font-size: 14px;
    margin-right: 8px;
    padding: 0;
  }

  .el-button--text {
    color: #8492A6;
  }

  h4 {
    margin-bottom: 0px;
  }

  i {
    display: flex;
    &::before {
      height: 22px;
      width: 22px;
    }
  }
}
.main-column {
  flex-basis: 1px;
  flex-grow: 0;

  .is-highlighted {
    background-color: #ECF2FB;
  }

  .select-panel__title {
    margin-bottom: 10px;
  }
}
.no-items-container {
  align-self: center;
  margin-top: 8px;
  color: #8492A6;
}
</style>
