import { mapState } from 'vuex';

const getCustomList = state => state.entitySources.entitySources
  .filter(source => source.type.startsWith('custom.list'));

export default {
  name: 'entityCustomSourceList',
  computed: {
    ...mapState({
      customListState: state => getCustomList(state).sort((a, b) => {
        const A = a.name.toUpperCase();
        const B = b.name.toUpperCase();

        if (A === B) {
          return 0;
        }

        return A < B ? -1 : 1;
      }),
    }),
    customlist() {
      return this.customListState.map(item => item);
    },
  },
  watch: {
    customListState(newChips) {
      this.newChips = newChips.map(() => '');
    },
  },
  data: function () {
    return {
      // A new customList Name
      newListName: '',
      showNewListFlag: false,
      newChips: [],
    };
  },
  mounted() {
    this.newChips = getCustomList(this.$store.state).map(() => '');
    this.$store.dispatch('entities/getEntitiesList', [
      { q: '', limit: 1000, offset: 0 },
      this.offset === 0,
    ]);
  },
  methods: {
    onNewList() {
      this.showNewListFlag = !this.showNewListFlag;
      this.nextTick(() => {
        document.querySelector('#new-tags-input').children[0].focus();
      });
    },

    onDeleteItem(listIndex, itemIndex) {
      this.customlist[listIndex].items.splice(itemIndex, 1);
      this.updateList(this.customlist[listIndex]);
    },

    onAddItem(listIndex) {
      const listItem = this.customlist[listIndex];

      if (this.newChips[listIndex]
          && !(listItem.items.includes(this.newChips[listIndex]))) {
        listItem.items.push(this.newChips[listIndex]);
        this.newChips[listIndex] = '';
        this.updateList(listItem);
      } else if (listItem.items.includes(this.newChips[listIndex])) {
        this.$aiq.notify.info(
          `${this.newChips[listIndex]} is already in the list.`,
        );
      }
    },

    updateList(item) {
      return this.$store.dispatch('entitySources/updateEntitysource', {
        id: item.id,
        item,
      }).then(() => this.$aiq.notify.success(`${item.name} has been updated.`)).catch(() => this.$aiq.notify.error(`${item.name} is not updated.`));
    },

    createList() {
      if (!this.newListName) {
        return;
      }

      if (this.customlist.find(l => l.name === this.newListName)) {
        this.$aiq.notify.info(
          `${this.newListName} is already being used.`,
        );
        return;
      }

      return this.$store.dispatch('entitySources/createEntitysource', {
        type: 'custom.list',
        name: this.newListName,
      }).then(() => {
        this.$aiq.notify.success(`${this.newListName} has been saved.`);
        this.showNewListFlag = false;
        this.newListName = '';
      }).catch(() => this.$aiq.notify.error(`${this.newEntityName} is not saved.`));
    }
  },
};
