import Conversations from './Conversations.vue';

export default [
  {
    path: '/conversations/:id?',
    name: 'ConversationByCustomerId',
    component: Conversations,
    meta: {
      auth: true,
      resource: '/messages',
      label: 'Conversations',
    },
  },
];
