import get from 'lodash/get';

export default {
  name: 'aiqCommandView',
  props: {
    // command object
    command: {
      type: Object,
    },
    // lookup table to find name of function in the command
    lookup: {
      type: Object,
    },
  },
  computed: {
    description() {
      return get(this.command, 'payload.description', '');
    },
    functions() {
      const functions = get(this.command, 'payload.functions', []);

      return functions.map(func => {
        const args = [];
        const lookupFunctionTable = this.lookup.functions
          .filter(funcObj => funcObj.commandName === func.command_name);

        if (lookupFunctionTable.length !== 0) {
          // args can be bool or array.
          const paramArgs = get(func, 'params.arguments', []);
          if (paramArgs instanceof Array && paramArgs.length > 0) {
            // multi arguments
            get(func, 'params.arguments', []).forEach(arg => {
              lookupFunctionTable[0].params.forEach(param => {
                if (param.id === arg.id) {
                  args.push(param.name);
                }
              });
            });
          } else if (typeof args === 'boolean') {
            args.push(args.toString());
          } else if (get(func, 'params.id', -1) !== -1) {
            // single argument : workflow & dialog
            const id = get(func, 'params.id', -1);
            lookupFunctionTable[0].params.forEach(param => {
              if (param.id === id) {
                args.push(param.name);
              }
            });
          }
        }

        return {
          name: func.function_name,
          chips: args,
        };
      });
    },
  },
};
