<template lang="pug" id="categoryBar">
extends ./Base.pug
</template>

<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import CategoryBarChart from './CategoryBar.vue';


export default {
  extends: CategoryBarChart,
  name: 'aiqCategoryBarChartNoLines',
  methods: {
    dataValues() {
      const { dataset } = this.report;
      if (isEmpty(dataset)) {
        return [];
      }
      return get(dataset[0], 'data_points', []);
    },
    xCategories() {
      return this.dataValues().map(point => point.x);
    },
    yValues() {
      return this.dataValues().map(point => get(point, 'ys.0.value'));
    },
  },
};
</script>

<style lang="scss">
</style>
