<template lang="pug">
.today-container
  template(v-if="showAgentsTab")
    aiq-tabs(v-if="tabs.length"
              v-model="activeTab")
      aiq-tab-pane(v-for="tab in tabs"
                  :key="tab.name"
                  :label="tab.label"
                  :name="tab.name"
                  lazy)
        component(:is="tab.component"
                  :metrics="metrics")
  conversations.conversations-only(v-else :metrics="metrics")
</template>

<script>
import { mapState } from 'vuex';
import PulsePanel from '../common/panel.vue';
import Conversations from './children/Conversations.vue';
import Agents from './children/Agents.vue';

const TABS = [
  {
    name: 'conversations',
    label: 'Conversations Metrics',
    component: 'Conversations',
  },
  {
    name: 'agents',
    label: 'Agent Metrics',
    component: 'Agents',
  },
];

export default {
  name: 'PulseToday',
  extends: PulsePanel,
  components: {
    Conversations,
    Agents,
  },
  props: {
    action: {
      type: String,
      default: 'getTimesliceMetrics',
    },
    storeKey: {
      type: String,
      default: 'timeslice',
    },
  },
  computed: {
    tabs() {
      return TABS;
    },
    ...mapState({
      showAgentsTab: state => state.featureFlags.FEATURE_FLAGS.PULSE_TODAY_AGENTS,
    }),
  },
  data() {
    return {
      activeTab: TABS[0].name,
      activeTabMetrics: null,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/aiq-mixins.scss";
@import "@/styles/aiq-variables.scss";

// remove when the agents tab feature flag is turned on everywhere
.today-conversations-container.conversations-only {
  :deep(.today-heatmaps-container) {
    .all-heatmaps {
      @include scrollable(calc(100vh - 240px));
    }
  }
}
</style>
