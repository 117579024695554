<template lang="pug">
.today-heatmap
  .title 
    span {{ heatmapData.header.title }}
    aiq-tooltip(placement="top"
                v-if="heatmapData.header.titleTooltip"
                :content="isString(heatmapData.header.titleTooltip) ? heatmapData.header.titleTooltip : ''"
                popper-class="chart-title-tooltip-popup")
      fa-icon.explanation(:icon="['fas', 'circle-question']" size="sm")
      template(v-slot:content v-if="isPlainObject(heatmapData.header.titleTooltip)")
        span {{ heatmapData.header.titleTooltip.intro }}
        ul
          li(v-for="detail in heatmapData.header.titleTooltip.details" :key="detail") {{ detail }}
  .aggregates(v-if="includeAggregates")
    .left
      .label {{ heatmapData.header.left.label }}
      .value {{ heatmapData.header.left.value }}
    .right
      .label {{ heatmapData.header.right.label }}
      .value {{ heatmapData.header.right.value }}
  .today-heatpmap-chart(:style="{ height: chartHeight }")
    overview-chart(v-if="heatmapData"
                  :keepChartOnly="true"
                  :chartConfig="heatmapData.config"
                  :preLoadedchartData="heatmapData.preLoadedchartData"
                  :heat-map-colors="heatmapData.colors"
                  :yTickValues="yTickValues"
                  :formatYTick="formatYTick"
                  :height="chartHeight"
                  :margin="chartMargin"
                  :heatmap-tooltips-config="heatmapData.tooltipsConfig"
                  :heatmap-tooltips-classname="heatmapData.tooltipsClassName"
                  breakdown="day"
                  displayValues="none"
                  visualization="aiq-heat-map")
</template>

<script>
import has from 'lodash/has';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import OverviewChart from '@/components/Chart.vue';

const CELL_HEIGHT = 33;

export default {
  name: 'TodayHeatmap',
  components: {
    OverviewChart,
  },
  props: {
    heatmapData: {
      type: Object,
      required: true,
    },
    selectedOptions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    includeAggregates() {
      return has(this.heatmapData, 'header.left') && has(this.heatmapData, 'header.right');
    },
    chartMargin() {
      return {
        top: 20,
        right: 0,
        bottom: 0,
        left: 150,
        axis: 6,
      };
    },
    chartHeight() {
      return (CELL_HEIGHT * this.selectedOptions.length) +
        this.chartMargin.top +
        this.chartMargin.bottom;
    },
    yTickValues() {
      return [...Array(this.selectedOptions.length).keys()];
    },
  },
  methods: {
    formatYTick(v) {
      return this.selectedOptions[v].name;
    },
    isPlainObject,
    isString,
  },
};
</script>

<style lang="scss" scoped>
.today-heatmap {
  background: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 15px 5px;

  .title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;

    .explanation {
      margin-left: 8px;
      color: #6B6D70;

      &:hover {
        color: #152E53;
      }
    }
  }

  .aggregates {
    display: flex;
    justify-content: center;

    &>div {
      width: 25%;
      display: flex;
      justify-content: center;
      column-gap: 30px;
    }
  }
  
  .today-heatpmap-chart {
    :deep(.chart-container) {
      height: 100%;
    }
  }  
}
</style>

<style lang="scss">
.chart-title-tooltip-popup.el-popper {
  max-width: 500px !important;

  ul {
    margin-block-start: 3px;
    margin-block-end: 3px;
    padding-inline-start: 20px;

    li {
      display: list-item;
      list-style-type: disc;
    }
  }
}
</style>