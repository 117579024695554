import axios from 'axios';
import merge from 'lodash/merge';

import { getUrl } from '@/config/api.routes';
import { CRUDStoreBuilder, updateKeywordsActionBuilder } from '../StoreBuilder';

const routingAlias = 'management.tags';

export default merge(
  new CRUDStoreBuilder(
    'management.tags',
    'tag',
    [],
    'data.models',
    true,
  ).createStore(),
  {
    getters: {
      tags: state => state.tags,
      tagModel: () => ({
        name: '',
        keywords: [],
      }),
    },
    actions: {
      createTag({ commit }, [item]) {
        return axios.post(getUrl(routingAlias), item).then(data => {
          commit('POST_TAG_SUCCESS', data.data);
          return data;
        });
      },
      deleteTag({ commit }, tag) {
        const { id } = tag;
        return axios.put(`${getUrl(routingAlias)}/${id}`, { ...tag, archived: true }).then(data => {
          commit('DELETE_TAG_SUCCESS', id);
          return data;
        });
      },
      ...updateKeywordsActionBuilder(routingAlias),
    },
  },
);
