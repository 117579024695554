<template lang="pug">
  .search-container
    back-button(v-if="backButton"
                text="Back to live results"
                @click="onBackButtonClicked")
    FilteredInput#item-search.search-item-input(
      v-show="!backButton"
      :id="id"
      size="small"
      placeholder="Search"
      v-model="search"
      @update:modelValue="$emit('search', $event)")
    slot.component-container
</template>

<script>
// Only use this component if item is set up as a root module of the same name
import { mapState } from 'vuex';
import { KB_MODES } from '@/constants';
import BackButton from '@/components/BackButton.vue';

export default {
  components: {
    BackButton,
  },
  props: {
    name: String,
    config: Object,
  },
  emits: ['back', 'search'],
  computed: {
    // TODO: remove direct mapState from this component
    ...mapState({
      itemForReset: state => state.conversations.selected,
    }),
    id() {
      return `search-container_input--${this.name}`;
    },
    backButton() {
      return this.config.name === KB_MODES.SELECTED;
    },
  },
  data() {
    return {
      search: '',
    };
  },
  mounted() {
  },
  watch: {
    itemForReset() {
      this.search = '';
    },
    'config.name': {
      handler(name) {
        if (name === KB_MODES.SELECTED) {
          this.search = '';
        }
      },
      immediate: true,
    },
  },
  methods: {
    onBackButtonClicked() {
      this.$emit('back');
    },
  },
};
</script>

<style lang="scss">
.search-container {
  padding-top: 8px;

  .el-input {
    margin-bottom: 12px;
  }

  .el-input--small .el-input__inner {
    height: 22px;
  }
}
</style>
