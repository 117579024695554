import AgentsDashboard from './AgentsDashboard.vue';
import AgentEditor from '../Settings/Agents/children/Manage/Edit.vue';

const rootRoute = {
  path: '/users',
  name: 'Users',
  props: true,
  component: AgentsDashboard,
  meta: {
    auth: true,
    basePath: '/users',
  },
};

export default {
  ...rootRoute,
  children: [
    {
      path: 'user/:id/detail',
      name: 'UsersDashboardUserDetail',
      component: AgentEditor,
      meta: {
        auth: true,
        resource: '/users/user/:id/detail',
        parentName: rootRoute.name,
      },
    },
  ],
};
