<template lang="pug">
.icon-container
  .channel-container
    AuthenticatedIcon.channel-icon(v-if="!isNotWebchatChannel && !isAnonymousCustomer"
                                    :class="{ selected: selected }")
    FacebookIcon.channel-icon(v-else-if="isNotWebchatChannel && channel === 'facebook'"
                                :class="{ selected: selected }")
    SmsIcon.channel-icon(v-else-if="isNotWebchatChannel && channel === 'sms'"
                          :class="{ selected: selected }")
    WhatsappIcon.channel-icon(v-else-if="isNotWebchatChannel && channel === 'whatsapp'"
                                :class="{ selected: selected }")
    AnonymousIcon.channel-icon(v-else :class="{ selected: selected }")
  .verified-container
    i.el-icon-check(v-if="isVerifiedCustomer")
</template>

<script>
import get from 'lodash/get';
import AnonymousIcon from '@/assets/svg/channels/anonymous.vue';
import AuthenticatedIcon from '@/assets/svg/channels/authenticated.vue';
import FacebookIcon from '@/assets/svg/channels/facebook.vue';
import SmsIcon from '@/assets/svg/channels/sms.vue';
import WhatsappIcon from '@/assets/svg/channels/whatsapp.vue';

export default {
  name: 'channelIcon',
  components: {
    AnonymousIcon,
    AuthenticatedIcon,
    FacebookIcon,
    WhatsappIcon,
    SmsIcon,
  },
  props: {
    conversation: {
      type: Object,
      default: () => ({}),
    },
    customer: {
      type: Object,
      default: () => ({}),
    },
    selected: {
      type: Boolean,
    },
  },
  computed: {
    channel() {
      return get(this.conversation, 'channel', 'webchat');
    },
    isNotWebchatChannel() {
      return this.channel !== 'webchat';
    },
    isAnonymousCustomer() {
      return !get(this.customer, 'identified', false);
    },
    isVerifiedCustomer() {
      return Boolean(get(this.customer, 'profile.external_id'));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/aiq-variables.scss";

.icon-container {
  position: relative;
}

.channel-icon {
  width: 16px;
  height: 16px;
  fill: #4671b3;
  stroke: #4671b3;
}

.channel-icon.selected {
  fill: white;
  stroke: white;
}

.verified-container {
  position: absolute;
  right: -3px;
  bottom: -3px;
  i {
    font-size: 8px;
    color: #00E500;
    font-weight: bold;
  }
}
</style>
