<template lang="pug">
  .result-indicators(v-if="isFiltered")
    span Matched: 
      b.mr-5 {{ totalResults }}
      | Showing: 
      b 0 - {{ visibleResults }}
</template>

<script>
import { mapState } from 'vuex';
import isUndefined from 'lodash/isUndefined';
import assign from 'lodash/assign';

export default {
  name: 'ResultsIndicators',
  computed: {
    ...mapState({
      isFiltered: state => !isUndefined(state.conversations.filter),
      pagination: state => assign({ offset: 0, limit: 0, rowCount: 0 },
        state.conversations.pagination),
    }),
    totalResults() {
      return this.pagination.rowCount;
    },
    visibleResults() {
      return Math.min(this.pagination.offset + this.pagination.limit, this.pagination.rowCount);
    },
  },
};
</script>

<style lang="scss" scoped>
.result-indicators {
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  color: #5e718a;
  background: #e5e9f2;
  padding: 8px 16px 8px 16px;
}

.mr-5 {
  margin-right: 5px;
}
</style>
