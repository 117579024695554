import Compliance from './Compliance.vue';

const rootRoute = {
  path: '/reporting',
  name: 'Reporting',
  component: Compliance,
  props: true,
  meta: {
    auth: true,
    basePath: '/Reporting',
  },
};

export default {
  ...rootRoute
};
