<template lang="pug">
.schedule-response_content
  .schedule-response_settings
    aiq-date-picker.schedule-date(v-model="formatDate"
                                  type="date"
                                  placeholder="Select date"
                                  format="YYYY/MM/DD"
                                  size="small")
      // time-range(v-model="date", :readonly="true", :show-seconds="true")
    aiq-time-picker.schedule-time(v-model="date", placeholder="Select time", size="small")
    aiq-input.schedule-message(type="text", v-model="message", placeholder="Message", size="small")

  .el-dialog__footer
    aiq-button(@click="cancel()") Cancel
    aiq-button(type="primary", @click="save()", :disabled="!showSendButton") Save

</template>

<script>
import timeRange from '@/components/TimeRange/TimeRange.vue';

export default {
  name: 'scheduleForm',
  components: {
    timeRange,
  },
  emits: ['save', 'cancel'],
  data() {
    return {
      message: '',
      date: new Date().toISOString(),
    };
  },
  computed: {
    formatDate: {
      get() {
        const date = new Date(this.date);
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      },
      set(value) {
        value = new Date(value);
        const date = new Date(this.date);
        date.setFullYear(value.getFullYear());
        date.setMonth(value.getMonth());
        date.setDate(value.getDate());
        this.date = date.toISOString();
      },
    },
    showSendButton() {
      return this.message.replace(/&nbsp;/g, '').trim().length > 0;
    },
  },
  methods: {
    save() {
      this.$emit('save', { message: this.message, date: this.date });
    },

    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss">
.schedule-response_content {

  .el-dialog__footer {
    padding-right: 0;
    padding-left: 0;
  }
}
.schedule-response_settings {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.schedule-date.el-input {
  width: calc(50% - 4px);

  input {
    &::-webkit-input-placeholder {
      color: #BFCBD9;
      font-size: 12px;
      font-weight: 300;
    }
    &::-moz-placeholder {
      color: #BFCBD9;
      font-size: 12px;
      font-weight: 300;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: #BFCBD9;
      font-size: 12px;
      font-weight: 300;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #BFCBD9;
      font-size: 12px;
      font-weight: 300;
    }
  }

  .el-input__icon {
    color: #D3DCE6;
    font-size: 16px;
  }
}
.schedule-time.el-input {
  width: calc(50% - 4px);

  input {
    &::-webkit-input-placeholder {
      color: #BFCBD9;
      font-size: 12px;
      font-weight: 300;
    }
    &::-moz-placeholder {
      color: #BFCBD9;
      font-size: 12px;
      font-weight: 300;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: #BFCBD9;
      font-size: 12px;
      font-weight: 300;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #BFCBD9;
      font-size: 12px;
      font-weight: 300;
    }
  }

  .el-input__icon {
    color: #D3DCE6;
    font-size: 16px;
  }
}
.schedule-message {
  margin-top: 14px;

  input {
    &::-webkit-input-placeholder {
      color: #BFCBD9;
      font-size: 12px;
      font-weight: 300;
    }
    &::-moz-placeholder {
      color: #BFCBD9;
      font-size: 12px;
      font-weight: 300;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: #BFCBD9;
      font-size: 12px;
      font-weight: 300;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #BFCBD9;
      font-size: 12px;
      font-weight: 300;
    }
  }
}
</style>
