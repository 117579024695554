const isEmpty = require('lodash/isEmpty');
const isUndefined = require('lodash/isUndefined');
const get = require('lodash/get');
const moment = require('moment');

const replaceKeysInStoredFilterObject = (argObj) => {
  argObj.storedFilterObject[argObj.filterType] = argObj.dropDownFilters.map(f => f.id);
  // both operands are lists before the operation
  return [argObj.storedFilterObject];
};

const removeFilter = (argObj) => {
  delete argObj.storedFilterObject[argObj.filterType];
  return isEmpty(argObj.storedFilterObject) ? [] : [argObj.storedFilterObject];
};

const addFilter = (argObj) => replaceKeysInStoredFilterObject({ storedFilterObject: {},
  dropDownFilters: argObj.dropDownFilters,
  filterType: argObj.filterType });

export function loadMetricsFilteringOptions(componentState, filterName) {
  const periodStart = moment(componentState.dateRange[0]).unix();
  const periodEnd = moment(componentState.dateRange[1]).unix();
  if (!(periodStart && periodEnd)) {
    return;
  }
  const params = {
    period_start: periodStart,
    period_end: periodEnd,
  };
  if (componentState.selectedBreakdownOption) {
    params.bucket = componentState.selectedBreakdownOption.value;
  }
  return componentState.$store
    .dispatch('metrics/getMetric', [filterName, params]);
}

export function loadAllTeams(componentState) {
  return componentState.$store
    .dispatch('metrics/getTeams');
}


const defaultFilterMetaData = {
  remoteCall: true,
  optionsLoader: loadMetricsFilteringOptions,
  optionsExtractor: (options) => get(options, 'dataset.0.data_points', []),
  getFormattedName(datapoint) { return datapoint.name },
};

export const formatFilterData = (datapoints, nameFormatter) => datapoints
  .map(dp => ({
    id: dp.id,
    name: nameFormatter(dp),
  }));

export const storedFilterDropDownFilterEmptinessTable = {
  'true,true': (argObj) => [], // eslint-disable-line no-unused-vars
  'false,false': replaceKeysInStoredFilterObject, // both have elements
  'true,false': addFilter, // stored has no elements, dropdown has elements
  'false,true': removeFilter, // stored has elements, dropdown has no elements

};

export const makeParamObjForFilterUpdates = (dropDownFilters, storedFilters, filterType) => ({
  storedFilterObject: storedFilters.length ? storedFilters[0] : {},
  dropDownFilters,
  filterType });

// eslint-disable-next-line
export const chooseFilterUpdatingMethod = (storedFilters, dropDownFilters) => {
  return storedFilterDropDownFilterEmptinessTable[[isEmpty(storedFilters),
    isEmpty(dropDownFilters)].join()];
};

// eslint-disable-next-line arrow-body-style
export const dropDownToSelected = (dropDownFilters, storedFilters, filterType) => {
  // dropDownFilters [{"id":1444,"name":"Cardinal"}] // could be more than one, so this is a list
  // storedFilters is a list with one object  : [{"agents":[1317,5102],"categories":[40]}]
  const updateFiltersClosure = chooseFilterUpdatingMethod(storedFilters, dropDownFilters);
  return updateFiltersClosure(makeParamObjForFilterUpdates(dropDownFilters,
    storedFilters,
    filterType));
};

// This is for setting options for dropdowns in summary page on mount
export const filterEndpointToMetaData = {
  metrics_by_agent: {
    ...defaultFilterMetaData,
    setOptionsStateVariable(componentState, data) { componentState.AgentFilterOptions = data },
    filterLabel: 'Agents Filter',
    getFormattedName(datapoint) { return `${datapoint.first_name} ${datapoint.last_name}` },
  },
  metrics_by_primary_agent: {
    ...defaultFilterMetaData,
    setOptionsStateVariable(componentState, data) {
      componentState.PrimaryAgentFilterOptions = data;
    },
    filterLabel: 'Primary Agents Filter',
    getFormattedName(datapoint) { return datapoint.agents_full_name },
  },
  metrics_by_category: { ...defaultFilterMetaData,
    setOptionsStateVariable(componentState, data) { componentState.CategoryFilterOptions = data },
    filterLabel: 'Categories Filter',
  },
  metrics_by_team: { ...defaultFilterMetaData,
    setOptionsStateVariable(componentState, data) { componentState.TeamFilterOptions = data },
    filterLabel: 'Teams Filter',
  },
  metrics_by_customer_segment: { ...defaultFilterMetaData,
    setOptionsStateVariable(componentState, data) {
      componentState.customerSegmentFilterOptions = data;
    },
    filterLabel: 'Segments Filter',
  },
  metrics_by_tag: { ...defaultFilterMetaData,
    setOptionsStateVariable(componentState, data) {
      componentState.tagFilterOptions = data;
    },
    filterLabel: 'Tags Filter',
  },
  tags_list: { ...defaultFilterMetaData,
    setOptionsStateVariable(componentState, data) {
      componentState.tagListFilterOptions = data;
    },
    filterLabel: 'Tags Filter',
  },
  all_teams: {
    ...defaultFilterMetaData,
    setOptionsStateVariable(componentState, data) {
      componentState.agentsTeamsFilterOptions = data;
    },
    optionsLoader: loadAllTeams,
    optionsExtractor: (response) => get(response, 'data.models', [])
      .map(op => ({ id: op.id, name: op.name })),
    filterLabel: 'Teams Filter',
  },
};


export async function fetchFilterOptions(componentState, endpoint) {
  if (isUndefined(get(filterEndpointToMetaData, endpoint))) {
    return [];
  }
  let datapoints;
  const optionsLoader = filterEndpointToMetaData[endpoint].optionsLoader;
  const optionsExtractor = filterEndpointToMetaData[endpoint].optionsExtractor;
  try {
    const response = await optionsLoader(componentState, endpoint);
    datapoints = optionsExtractor(response);
  } catch (error) {
    componentState.$aiq.notify.error(`Couldn't load ${filterEndpointToMetaData[endpoint].filterLabel}`);
    datapoints = [];
  }
  return datapoints;
}


export async function loadFilterOptions(componentState, endpoint) {
  const metricsFilter = filterEndpointToMetaData[endpoint];
  const datapoints = await fetchFilterOptions(componentState, endpoint);
  const formattedOptions = formatFilterData(datapoints, metricsFilter.getFormattedName);
  metricsFilter.setOptionsStateVariable(componentState, formattedOptions);
}
