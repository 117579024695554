import {
  changeEntityTypeWarning,
  containsClass,
} from '@/libs';

const BOTTOM_BOUNDARY_PIXELS = 170;
const LEFT_BOUNDARY_PIXELS = 550;
const LENGTH_WITH_ENTITY_SOURCE_ITEM = 2;
const THIRD_OPTION_INDEX = 3;

// Helper function to extract selected values
const extractSelected = (options, selections) => {
  let selectedIdx = -1;
  let selectedSubIdx = -1;
  let selectedItemLabel = '';

  if (selections && selections.length === LENGTH_WITH_ENTITY_SOURCE_ITEM) {
    selectedIdx = options.findIndex(item => item.value === selections[0]);

    if (selectedIdx >= 0) {
      selectedSubIdx = options[selectedIdx].children
        .findIndex(item => item.value === selections[1]);
      selectedItemLabel = options[selectedIdx].children[selectedSubIdx].label;
    }
  }

  return {
    selectedIdx,
    selectedSubIdx,
    selectedItemLabel,
  };
};

export default {
  name: 'aiqCustomCascader',
  props: {
    modelValue: {
      type: Array,
      default: [],
    },
    options: {
      type: Array,
      default: [],
    },
    placeholder: String,
  },
  emits: ['create', 'viewchanged', 'input', 'change'],
  data() {
    const {
      selectedIdx,
      selectedSubIdx,
      selectedItemLabel,
    } = extractSelected(this.options, this.modelValue);

    return {
      // state of drop box
      isSelectOpen: false,
      // hovered item index in the first list
      hoveredItemIndex: -1,
      // hovered item index in the second list
      hoveredSubItemIndex: -1,
      // selected item index in the first list
      selectedItemIndex: selectedIdx,
      // selected item index in the second list
      selectedSubItemIndex: selectedSubIdx,
      // selected item label in the second list
      selectedItemLabel,
      // value of input box in the first list if it exists.
      firstInputBox: '',
      // value of input box in the second list if it exists.
      lastInputBox: '',
      // saved value of input box in the second list if it exists.
      lastInputBoxSaved: '',
    };
  },
  watch: {
    modelValue() {
      const {
        selectedIdx,
        selectedSubIdx,
        selectedItemLabel,
      } = extractSelected(this.options, this.modelValue);

      this.selectedItemIndex = selectedIdx;
      this.selectedSubItemIndex = selectedSubIdx;
      this.selectedItemLabel = selectedItemLabel;
    },
    options(newValue) {
      const hoveredItem = newValue[this.hoveredItemIndex];
      if (this.hoveredSubItemIndex > -1 && hoveredItem) {
        if (hoveredItem.hasViewbox) {
          this.lastInputBoxSaved = hoveredItem.viewValues[this.hoveredSubItemIndex];
        }
      }
    },
  },
  computed: {
    dirtyLastInput() {
      return this.lastInputBox !== this.lastInputBoxSaved;
    },
    isItemHovered() {
      return this.hoveredItemIndex >= 0;
    },
    isInputboxRequired() {
      if (!this.isItemHovered) {
        return false;
      }

      return !!(this.options[this.hoveredItemIndex].hasInputbox);
    },
    hoveredChildren() {
      if (this.hoveredItemIndex >= 0) {
        return this.options[this.hoveredItemIndex].children;
      }
      return [];
    },
    selectIcon() {
      return this.isSelectOpen ? 'el-icon-caret-top' : 'el-icon-caret-bottom';
    },
    hasInputbox() {
      if (!this.options[this.hoveredItemIndex]) {
        return false;
      }

      return !!(this.options[this.hoveredItemIndex].hasInputbox);
    },
    hasViewbox() {
      if (!this.options[this.hoveredItemIndex]) {
        return false;
      }

      return !!(this.options[this.hoveredItemIndex].hasViewbox);
    },
  },
  methods: {
    getClass1(index) {
      return {
        'option-hovered': this.hoveredItemIndex === index,
      };
    },
    getClass2(index) {
      return {
        'option-hovered': this.hoveredSubItemIndex === index,
      };
    },
    clean() {
      this.hoveredItemIndex = -1;
      this.hoveredSubItemIndex = -1;
      this.firstInputBox = '';
      this.lastInputBox = '';
      this.lastInputBoxSaved = '';
    },
    onClicked(event) {
      this.clean();
      this.isSelectOpen = !this.isSelectOpen;

      if (this.isSelectOpen) {
        this.$nextTick(() => {
          this.optionsPositionY(event);
        });
      }
    },
    onClose(e) {
      const { classList } = e.target.parentElement;
      const toCheck = [
        'aiq-sub-input',
        'aiq-sub_children',
        'sub-option2',
        'sub-option3',
        'button-cancel',
        'button-save',
      ];

      if (this.isSelectOpen && classList) {
        if (!containsClass(classList, toCheck)) {
          this.onClicked();
        }
      }
    },
    onFirstItemEntered() {
      if (this.firstInputBox) {
        this.$emit('create', this.firstInputBox);
      }

      this.firstInputBox = '';
    },
    onSecondItemCanceled() {
      this.lastInputBox = this.lastInputBoxSaved;
    },
    onSecondItemEntered() {
      if (this.lastInputBox) {
        const hoveredItem = this.options[this.hoveredItemIndex];
        if (hoveredItem) {
          const hoveredSecondItem = hoveredItem.children[this.hoveredSubItemIndex];
          const subValues = hoveredItem.viewValues;
          const { lastInputBox } = this;

          if (hoveredSecondItem
            && lastInputBox !== subValues[this.hoveredSubItemIndex]) {
            const { title, content } = changeEntityTypeWarning;

            this.$aiq.confirm(title, content)
              .then(confirm => {
                const params = {
                  sourceId: hoveredSecondItem.value,
                  regex: lastInputBox,
                };
                if (confirm) {
                  this.$emit('viewchanged', params);
                }
              }, () => {
                this.lastInputBox = this.lastInputBoxSaved;
              });
          }
        }
      }
    },
    hoverItem(set, index, event) {
      this.optionsPositionX(index, event);

      if (set) {
        this.hoveredItemIndex = index;
      } else {
        this.hoveredItemIndex = -1;
      }
    },
    selectChildrenIndex(index) {
      // TODO (Gabe) Do not resave if selecting the same entity type
      const selections = [];
      const generalType = this.options[this.hoveredItemIndex];

      // If is 'sys' type, save entity type from sub-level
      // TODO (Gabe) - Refactor, perhaps with backend changes
      if (generalType) {
        let typeToSave = generalType.value;

        if (generalType.value === 'sys') {
          typeToSave = generalType.children[index].label.substring(1);
        } else {
          typeToSave = generalType.value;
        }

        selections.push(typeToSave);
      }

      if (generalType.children[index].value) {
        selections.push(generalType.children[index].value);
      }

      if (selections.length === LENGTH_WITH_ENTITY_SOURCE_ITEM) {
        const { title, content } = changeEntityTypeWarning;
        this.$aiq.confirm(title, content)
          .then(confirm => {
            if (confirm) {
              // v-model update
              this.$emit('input', selections);
              // another callback
              this.$emit('change', selections);
            }
          }, () => {});
      }

      this.onClicked();
    },
    hoverSubItem(index) {
      this.hoveredSubItemIndex = index;

      const subValues = this.options[this.hoveredItemIndex].viewValues;

      if (subValues) {
        this.lastInputBox = subValues[this.hoveredSubItemIndex];
        this.lastInputBoxSaved = subValues[this.hoveredSubItemIndex];
      }
    },
    optionsPositionY(event) {
      /**
       * Check position of the custom cascader and if there is not
       * enough space for the third sub option list then change
       * the position of the custom cascader to fit available space
       */
      const options = document.getElementById('cascader-options');
      const windowHeight = window.innerHeight;
      const cursorPosY = event ? event.clientY : 0;

      if (options && options.classList && windowHeight - cursorPosY <= BOTTOM_BOUNDARY_PIXELS) {
        options.classList.add('bottom');
      }
    },
    optionsPositionX(index, event) {
      /**
       * Check position of the custom cascader and if there is not
       * enough space for the third sub option list then change
       * the position of the custom cascader to fit available space
       */
      const options = document.getElementById('cascader-options');
      const windowWidth = window.innerWidth;
      const cursorPosX = event.clientX;

      if (options && options.classList) {
        if (index === THIRD_OPTION_INDEX && windowWidth - cursorPosX <= LEFT_BOUNDARY_PIXELS) {
          options.classList.add('left');
        } else if (options.classList.contains('left') && !windowWidth - cursorPosX <= LEFT_BOUNDARY_PIXELS) {
          options.classList.remove('left');
        }
      }
    },
  },
};
