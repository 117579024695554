import cloneDeep from 'lodash/cloneDeep';
import indexToClass from './entityColors';

/** Importing this module as a hack to get webpack to properly bundle all dependencies
 *  for unit tests. It can be any object in @/libs and seemingly will work on any .js
 *  file in the src folder. However, it won't work specifically for *.spec.js files.
 *  Without this line, the store module will be undefined when imported.
 */

export default {
  name: 'intent-entities-selection-item',
  props: {
    index: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    unselectedEntities: {
      type: Array,
      required: true,
    },
  },
  emits: ['update', 'delete'],
  data() {
    return {
      selectedEntity: cloneDeep(this.item).name,
    };
  },
  computed: {
    className() {
      return indexToClass[this.index % Object.keys(indexToClass).length];
    },
  },
  watch: {
    selectedEntity(newEntity) {
      newEntity && this.$emit('update', newEntity, this.index);
    },
  },
  methods: {
    deleteEntity() {
      this.$emit('delete', this.index);
    },
  },
};
