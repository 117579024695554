<template lang="pug">
  .conversation-info-notes
    .conversation-info-notes-header
      h3.inner-header Notes
    .conversation-info-notes-content.scroll
      .conversation-info-notes-item(v-for="note of notesList", :key="note.id")
        .conversation-info-notes-item-text
          span(v-html="noteContentFormatter(note.content)")
          aiq-button.clear__btn(link type="primary"
                                @click="remove(note.id)") clear
        .conversation-info-notes-item-date note added &nbsp;
          aiq-timeago(:since="note.created_at" v-if="note.created_at")
          span(v-else) just now
    aiq-input#new-note(size="small", type="textarea", :autosize="{maxRows: 2}", placeholder="Add notes", v-model="newNote", @keydown.enter.exact.prevent="add")
    .scheduling-content(v-if="FEATURE_FLAGS.SHOW_SCHEDULING")
      .scheduling-content-header
        h3.inner-header Reminders
        aiq-button.button-add-new-item.right-top(size="small" @click="scheduleReminder")
          i.el-icon-plus
      .inline-intro Add, edit, or delete reminders for this conversation.
      .scheduling-content-body
        .schedule-item-container(v-for="schedule in schedules")
          .left-color-block
          .center-content-block
            p Reminder: {{ schedule.title }}
            p.time-block will notify you @ {{ $filters.formatTime(schedule.execute_at) }}
          .right-icon-container-block(@click="onEditSchedule(schedule)")
            i.el-icon-edit

</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import pick from 'lodash/pick';
import { SCHEDULE_TYPE } from '@/constants/analytics';

export default {
  name: 'notesList',
  props: {
    notes: {
      type: Array,
      required: true,
    },
    customer: {
      type: Object,
      required: true,
    },
    schedules: {
      type: Array,
      required: true,
    },
  },
  emits: ['add', 'remove'],
  computed: {
    ...mapState({
      FEATURE_FLAGS: state => state.featureFlags.FEATURE_FLAGS,
    }),
    notesList() {
      return [...this.notes].reverse();
    },
  },
  data() {
    return {
      newNote: '',
    };
  },
  watch: {
    notes() {
      this.scrollToBottom();
    },
  },
  methods: {
    remove(id) {
      this.$emit('remove', id);
    },

    add() {
      if (!this.newNote.trim().length || this.newNote.trim() === '') {
        this.newNote = '';
        return;
      }
      this.$emit('add', this.newNote);
      this.newNote = '';
    },

    format(time) {
      return moment().isSame(moment(time), 'day') ? moment(time).calendar() : moment(time).format('lll');
    },

    scrollToBottom() {
      setTimeout(() => {
        const scrollableContainer = this.$el.querySelector('.scroll');
        scrollableContainer.scrollTop = scrollableContainer.scrollHeight;
      });
    },

    scheduleReminder() {
      this.$store.dispatch('agent/changeDialogVisibility', {
        name: 'schedulesDialog',
        visible: true,
        type: SCHEDULE_TYPE.NOTES,
        data: {
          event_type: 'reminder',
          customer_id: this.customer.id,
          event_data: {
            customer: pick(this.customer, ['id', 'profile']),
          },
        },
      });
    },

    onEditSchedule(schedule) {
      this.$store.dispatch('agent/changeDialogVisibility', {
        name: 'schedulesDialog',
        visible: true,
        data: schedule,
      });
    },

    noteContentFormatter(content) {
      content = this.$filters.sanitize(content);
      content = this.$filters.url(content);
      return content;
    },
  },
};
</script>

<style lang="scss">
@import "./../../../../../styles/aiq-variables.scss";

.conversation-info-notes {
  width: 100%;
  font-weight: 300;
}
.conversation-info-notes-title {
  width: 100%;
  padding: 7px 0 10px;
  color: $aiq-text-conversation-info-notes-title;
  font-size: 14px;
  line-height: 14px;
  font-weight: 300;

  [class^="iq-ico"] {
    margin-right: 5px;
  }
}
.conversation-info-notes-content {
  width: 100%;
  max-height: calc(100vh - 410px);
  margin-bottom: 10px;
}

.conversation-info-notes-item {
  margin-bottom: 10px;
  color: $aiq-text-conversation-info-notes-item;
  font-size: 12px;
  line-height: 12px;

  &:last-child {
    margin-bottom: 0;
  }
  &:hover {

    .md-icon {
      opacity: 1;
    }
  }
}
.conversation-info-notes-item-text {
  display: flex;
  align-items: center;
  white-space: normal;
  word-wrap: break-word;
  max-width: 260px;;

  .clear__btn {
    width: 12px;
    min-width: 12px;
    min-height: 12px;
    margin-left: 5px;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
  }
}
.conversation-info-notes-item-date {
  margin-top: 2px;
  color: $aiq-text-conversation-info-notes-item-dage;
  font-size: 10px;
  line-height: 10px;
}
.md-input-container#new-note {
  .md-count {
   display: none;
  }
}
#new-note {
  margin-bottom: 10px;

  textarea {
    padding: 3px 10px;
    font-size: 12px;
    line-height: 14px;
    resize: none;

    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      border: 1px solid transparent;
      border-radius: 10px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: transparent;
    }
    &:hover {
      border-color: #8391A5;

      &::-webkit-scrollbar-track {
        border-color: $aiq-bgc-scrollbar;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $aiq-bgc-scrollbar;
      }
    }

    &:-ms-input-placeholder{
      color: #BFCBD9;
      font-size: 11px;
      line-height: 16px;
    }
    &::placeholder{
      color: #BFCBD9;
      font-size: 11px;
      line-height: 16px;
    }

    &:focus {
      border-color: #5A91E5;

      &::-webkit-input-placeholder {
        color: transparent;
      }
      &::-moz-placeholder {
        color: transparent;
      }
      &::-ms-placeholder {
        color: transparent;
      }
      &::-ms-input-placeholder {
        color: transparent;
      }
    }
  }
}

.inner-header {
  padding: 10px 0;
  color: #566682;
  font-size: 14px;
  line-height: 14px;
  font-weight: 300;
}

.scheduling-content {
  border-top: 1px solid #BFCBD9;

  .scheduling-content-header {
    position: relative;
  }
}

.schedule-item-container {
  border: 1px dashed #5e718a;
  margin: 5px 0px 5px 0px;
  position: relative;
  min-height: 46px;
  display: block;
  clear: both;
  border-radius: 2px;
  color: #324057;

  .left-color-block {
    float: left;
    width: 4px;
    height: 100%;
    position: absolute;
    background: rgba(70, 112, 179, 1);
  }

  .center-content-block {
    padding: 5px 20px 5px 9px;
    height: calc(100% - 10px);

    .time-block {
      font-size: 10px;
    }
  }

  .right-icon-container-block {
    position: absolute;
    top: 5px;
    right: 10px;
    color: #BFCBD9;
    font-size: 10px;
    cursor: pointer;
  }
}

.right-top {
  position: absolute;
  right: 5px;
  top: 5px;
}

.inline-intro {
    padding-bottom: 0.5rem;
    color: #5e718a;
    font-size: 0.75rem;
    font-weight: 200;
    line-height: 0.9375rem;
}
</style>

