<template lang="pug">
.cors-configuration(v-if="corsConfig")
  .enabled-toggle
    aiq-row.padding-5
      aiq-col(:span="2")
        label Enabled
      aiq-col(:span="22")
        aiq-switch(:model-value="corsConfig.enabled"
                  style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA"
                  @change="onValueChange('enabled', $event)")

  .configuration(v-if="corsConfig.enabled")
    aiq-row.padding-5
      aiq-col(:span="8")
        label Access-Control-Allow-Origin 
      aiq-col(:span="16")
        aiq-keyword-input(:list="corsConfig.configuration.origin"
                          @createItem="addItem('origin', $event)"
                          @deleteItem="deleteItem('origin', $event)"
                          :deletable="true")

    aiq-row.padding-5
      aiq-col(:span="8")
        label Access-Control-Allow-Methods
      aiq-col(:span="16")
        aiq-select(v-model="corsConfig.configuration.methods"
                   size="small"
                   multiple
                   name="methods"
                   :reserve-keyword="false")
          aiq-option(v-for="m in methods"
                     :key="m"
                     :label="m"
                     :value="m")

    aiq-row.padding-5
      aiq-col(:span="8")
        label Access-Control-Request-Headers
      aiq-col(:span="16")
        aiq-keyword-input(:list="corsConfig.configuration.allowed_headers"
                          @createItem="addItem('allowed_headers', $event)"
                          @deleteItem="deleteItem('allowed_headers', $event)"
                          :deletable="true")

    aiq-row.padding-5
      aiq-col(:span="8")
        label Access-Control-Expose-Headers
      aiq-col(:span="16")
        aiq-keyword-input(:list="corsConfig.configuration.exposed_headers"
                          @createItem="addItem('exposed_headers', $event)"
                          @deleteItem="deleteItem('exposed_headers', $event)"
                          :deletable="true")


    aiq-row.padding-5
      aiq-col(:span="8")
        label Access-Control-Allow-Credentials
      aiq-col(:span="16")
        aiq-switch(:model-value="corsConfig.configuration.credentials"
                  style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA"
                  @change="onValueChange('configuration.credentials', $event)")


    aiq-row.padding-5
      aiq-col(:span="8")
        label Access-Control-Max-Age
      aiq-col(:span="16")
        aiq-input(type="number"
                  size="small"
                  :model-value="corsConfig.configuration.max_age"
                  @update:modelValue="onValueChange('configuration.max_age', $event)")
</template>

<script>
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'cors-configuration',
  props: {
    corsConfig: {
      type: Object,
      default: null,
    },
  },
  emits: ['update'],
  computed: {
    methods() {
      return ['GET', 'HEAD', 'PUT', 'PATCH', 'POST', 'DELETE'];
    },
  },
  methods: {
    addItem(name, item) {
      const newConfig = cloneDeep(this.corsConfig);
      newConfig.configuration[name].push(item);
      this.$emit('update', newConfig);
    },
    deleteItem(name, item) {
      const newConfig = cloneDeep(this.corsConfig);
      newConfig.configuration[name] =
          newConfig.configuration[name].filter(i => i !== item);
      this.$emit('update', newConfig);
    },
    onValueChange(path, val) {
      const newConfig = cloneDeep(this.corsConfig);
      if (path.includes('max_age')) {
        val = Number(val);
      }
      set(newConfig, path, val);
      this.$emit('update', newConfig);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../../../../../styles/aiq-variables.scss";
@import "./../../../../../styles/aiq-mixins.scss";

.padding-5 {
  padding: 5px;
}
</style>
