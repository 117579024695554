<template lang="pug">
  popoverContainer(:title="title")
    aiq-input(type="textarea"
              v-model="value"
              @keyup.enter="select"
              @keyup.esc="cancel")
</template>

<script>
import PopoverContainer from './PopoverContainer.vue';

export default {
  name: 'textPopover',
  components: {
    PopoverContainer,
  },
  props: {
    eventEmitter: {
      type: Object,
    },
    param: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: () => 'Input',
    },
    selected: {
      type: Object,
    },
  },
  emits: ['submit'],
  data() {
    return {
      value: this.param,
    };
  },
  mounted() {
    this.$el.querySelector('.el-textarea__inner').focus();
  },
  methods: {
    cancel() {
      this.eventEmitter.emit('cancel');
    },
    select() {
      const trimmed = this.value.trim();
      if (this.value) {
        this.$emit('submit', trimmed);
        this.value = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
