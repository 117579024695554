<template lang="pug">
  side-bar-vue(:baseResource="baseResource" :routeChildren="routeChildren")
</template>

<script>
import { Component, Vue, toNative } from 'vue-facing-decorator';
import SideBarVue from '@/components/layouts/SideBarVue.vue';
import IQToolsRoutes from './IQTools.router';

@Component({
  components: {
    SideBarVue,
  },
})
class IqTools extends Vue {
  baseResource = IQToolsRoutes.path;
  routeChildren = IQToolsRoutes.children;
}
export default toNative(IqTools);
</script>

<style>
</style>
