
const DEFAULT_TEMPLATE_HEADINGS = [
  'Banker First Name',
  'Banker Last Name',
  'Banker Email',
  'BankerMapID',
  'BankerTeam',
  'Customer First Name',
  'Customer Last Name',
  'CustomerMapID',
  'Customer Email',
  'Customer Group',
  'Date Added',
  'Customer Phone',
];

const SMS_CUSTOMER_TEMPLATE_HEADINGS = [
  'Customer First Name',
  'Customer Last Name',
  'Customer Email',
  'Customer Group',
  'Date Added',
  'Customer Phone',
];

const CUSTOMER_TEMPLATE_HEADINGS = [
  'Customer First Name',
  'Customer Last Name',
  'CustomerMapID',
  'Customer Email',
  'Customer Group',
  'Date Added',
  'Customer Phone',
];

const CUSTOMER_NOTES_TEMPLATE_HEADINGS = [
  'CustomerMapID',
  'Note',
];

const OUTBOUND_SMS_TEMPLATE_HEADINGS = [
  'first_name',
  'last_name',
  'phone',
  'email',
];

const WEBCHAT_TEMPLATE_HEADINGS = [
  'ID'
];

const INGESTION_MODES = [
  {
    key: 'default',
    label: 'Default',
    endpoint: '/users',
    headings: DEFAULT_TEMPLATE_HEADINGS,
    isDefault: true,
    overridable: false, // This is partially overridable. For now, make it false until we need.
  },
  {
    key: 'sms-customers',
    label: 'Anonymous SMS Conversations',
    endpoint: '/customers',
    headings: SMS_CUSTOMER_TEMPLATE_HEADINGS,
    isDefault: false,
    channel: 'sms',
    overridable: true,
  },
  {
    key: 'customers',
    label: 'Authenticated Conversations',
    endpoint: '/customers',
    headings: CUSTOMER_TEMPLATE_HEADINGS,
    isDefault: false,
    channel: 'webchat',
    overridable: true,
  },
  {
    key: 'customers_notes',
    label: 'Conversation Notes',
    endpoint: '/customers/notes',
    headings: CUSTOMER_NOTES_TEMPLATE_HEADINGS,
    isDefault: false,
    overridable: false,
  },
  {
    key: 'template-sms-sending',
    label: 'Outbound SMS - template',
    endpoint: '/sms',
    headings: OUTBOUND_SMS_TEMPLATE_HEADINGS,
    isDefault: false,
    overridable: false,
  },
];

export {
  DEFAULT_TEMPLATE_HEADINGS,
  CUSTOMER_NOTES_TEMPLATE_HEADINGS,
  WEBCHAT_TEMPLATE_HEADINGS,
  OUTBOUND_SMS_TEMPLATE_HEADINGS,
  INGESTION_MODES,
};
