export const PROFILE_EDITOR_TYPE = {
  CONCAT_STRING: 'concat_string', // used only internally to concat first & last name
  STRING: 'string',
  STRING_LIST: 'list_string',
  JSON: 'json',
  OBJECT_TABLE: 'object_table',
};

export const HAMBURGER_MENU_SELECTION_OPTIONS = [
  { label: 'Mobile User', value: 'mobile' },
  { label: 'Anonymous Web User', value: 'anonymous' },
  { label: 'Logged in Web User', value: 'external' },
];

export const ONBOARD_SCREERNS = [
  { label: 'Introduction', value: 'introduction' },
  { label: 'Terms and Conditions', value: 't_and_c' },
  { label: 'PreChat Survey Start', value: 'prechat_survey' },
];

export const DEFAULT_SYSTEM_LANGUAGE = {
  code: 'en',
  name: 'English',
};

export const AVAILABLE_LANGUAGES = [
  {
    ...DEFAULT_SYSTEM_LANGUAGE,
    disabled: true,
  },
  {
    code: 'fr',
    name: 'French',
  },
];

export const CHAT_ICON_RESOLUTION = {
  min: { height: 25, width: 25 },
  max: { height: 65, width: 65 },
};

// vue-editor's configuration used for email body
// The options for toolbar in editor can be found in
// https://github.com/davidroyer/vue2-editor/blob/master/src/helpers/default-toolbar.js
export const EMAIL_EDITOR_CONFIG = [
  [{ header: [false, 1, 2, 3, 4, 5, 6] }], // eslint-disable-line
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  [
    { align: '' },
    { align: 'center' },
    { align: 'right' },
    { align: 'justify' },
  ],
  ['blockquote'],
  [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  ['link'],
  ['clean'], // remove formatting button
];

export default {
  PROFILE_EDITOR_TYPE,
  DEFAULT_SYSTEM_LANGUAGE,
  AVAILABLE_LANGUAGES,
  CHAT_ICON_RESOLUTION,
};

