import { TUTORIAL_INCOMPLETE, TUTORIAL_REVIEW } from '@/store/modules/agent';

export const isTutorialVisible = (
  totalLogins,
  tutorialCompletionState,
  maxLogins,
) => {
  if (totalLogins <= maxLogins && tutorialCompletionState === TUTORIAL_INCOMPLETE) {
    return true;
  }
  return tutorialCompletionState === TUTORIAL_REVIEW;
};
