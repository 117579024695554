import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import moment from 'moment';
import PerfectScrollbar from 'perfect-scrollbar';
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';
import { mapState, mapGetters } from 'vuex';
import mitt from 'mitt';
import * as log from 'loglevel';

import ChatPanel from './children/ChatPanel/ChatPanel.vue';
import ConversationsFilter from './children/ConversationsFilterComponent.vue';
import ResultsIndicators from './children/ResultsIndicators.vue';
import CountersPanel from './children/CountersPanelComponent.vue';
import LayoutMain from '@/components/layouts/main.vue';
import ConversationItem from '@/components/ConversationItem/ConversationItem.vue';
import { AGENT_LIMIT, TEAM_LIMIT } from '@/constants/pagination';
import {
  CONVERSATION_LIMIT,
  KEYBOARD_KEYS,
  DEFAULT_POLL_INTERVAL,
  ATTACHMENT_HISTORY_TYPES } from '@/constants';
import { Events, ShortcutController } from '@/libs';
import { IDS } from './conversationConstants';
import conversationShortcuts from './conversationShortcuts';
import SearchListScrollerMixin from '@/libs/mixins/SearchListScroller';
import { adjustUrlArray } from '@/libs';

const TAG = 'CONVERSATION TAB';
const toIsoString = (date) => moment.unix(date).toISOString();
const UNIQUE_VIOLATION_CODE = 409;

export default {
  name: 'Conversations',
  components: {
    ChatPanel,
    CountersPanel,
    ConversationsFilter,
    ResultsIndicators,
    LayoutMain,
    ScaleLoader,
    ConversationItem,
  },
  mixins: [SearchListScrollerMixin],
  data() {
    return {
      clipColor: '#5A88DE',
      clipSize: '32px',
      currentFilter: {},
      selectedTab: 'mine',
      selectedStatusFilters: [],
      globalAlias: '/conversations/',
      highlighted: null,
      isFilterOpened: false,
      limit: CONVERSATION_LIMIT,
      loaded: false,
      loading: false,
      offset: 0,
      rowCount: 0,
      poll: null,
      ps: null,
      scrollState: null,
      selectConversationListener: null,
      selectedSort: 'desc',
      shortcutController: null,
      pendingReq: [],
      shortcutEmitter: mitt(),

      // For SearchListScrollerMixin

      // Is the customer id
      highlightedKey: null,
      // Todo (Gabe): change name to itemSelector
      itemElementTag: 'div.conversation-item',
      itemsName: 'conversations',
      keyName: 'customer_id',
      // Todo (Gabe): change this ref name to default for mixin
      scrollRefName: 'scrollWrapper',
      useParentOffset: false,
    };
  },
  computed: {
    ...mapState({
      agentProfile: state => state.agent.profile,
      teamList: state => state.teams.teams,
      channel: state => state.conversations.channel,
      conversations: state => state.conversations.conversations,
      counters: state => state.conversations.counters,
      fetchLimit: state => state.conversations.limit,
      focused: state => state.conversations.focused,
      filter: state => state.conversations.filter,
      permissions: state => state.agent.permissions,
      scope: state => state.conversations.scope,
      selected: state => state.conversations.selected,
      pastConversations: state => state.conversations.pastConversations,
      isMobileContext: state => state.settings.isMobileContext,
      isConversationsOpen: state => state.conversations.isConversationOpen,
      selectedCustomer: state => state.conversations.selectedCustomer,
    }),

    ...mapGetters({
      isRepairModeEnabled: 'hidden/isRepairMode',
      isRealtimeContext: 'conversations/isRealtimeContext',
    }),
    localName() {
      if (!isEmpty(this.agentProfile.profile.first_name)) {
        return `, ${this.agentProfile.profile.first_name}`;
      }
      return '';
    },
    teams() {
      return this.canView('/users/filters/show_all_teams') ? this.teamList : this.agentProfile.teams;
    },
    infiniteLoadingIdentifier() {
      return {
        selectedTab: this.selectedTab,
        ...this.currentFilter,
      };
    }
  },

  watch: {
    $route(to) {
      if (get(to, 'params.id')) {
        return this.redirectToOneSelectedConversation(to);
      }

      // TODO(jaekwan): Clarify this if it is okay to route mine tab.
      return this.selectTab('mine');
    },
    conversations(newValue) {
      const selected = newValue.find(e => e.id === this.selected.id);
      if (selected && this.selected.unread_message_count > 0) {
        this.$store.dispatch('conversations/readConversation', this.selected.id);
      }
    },
    offset(newValue) {
      if (newValue > this.fetchLimit) {
        log.debug(TAG, `Request Stopping polling due to offset ${newValue} > ${this.fetchLimit}`);
        this.stopPollIfPolling();
      }
    },
    'selected.id': {
      handler(newId, oldId) {
        if (oldId) {
          this.$store.dispatch('conversations/readConversation', oldId);
        }
      },
    },
    selectedStatusFilters: 'updateScopeFilter',
    selectedTab: 'updateScopeTab',
    focused(newVal) {
      if (!newVal) {
        this.selected.id = null;
      }
    },
  },

  created() {
    this.$_IDS = IDS;
  },

  async mounted() {
    this.selectedTab = this.scope;
    if (!this.canView('/conversations')) {
      if (this.canView('/iqtool')) {
        this.$router.replace({ path: '/iqtools' });
        return;
      }
      this.$router.replace({ path: '/error' });
      return;
    }

    this.shortcutController = new ShortcutController({
      rootElem: this.$el,
      rawDefs: conversationShortcuts,
      emitter: this.shortcutEmitter });
    this.shortcutController.attachListener();


    if (this.$refs.scrollWrapper) {
      this.ps = new PerfectScrollbar(this.$refs.scrollWrapper);
    }

    this.addListElemListeners();

    this.$store.dispatch('agents/getAgentsSimpleList', [{ limit: AGENT_LIMIT }]);
    // We need to use await expression because of a dependency from
    // documents/getDocumentsList action on the teams list
    await this.$store.dispatch('teams/getTeamsList', [{ limit: TEAM_LIMIT }]);
    this.$store.dispatch('configs/getTwitterConfiguration');
    this.$store.dispatch('configs/getCustomerSegments');
    this.$store.dispatch('documents/getDocumentsList', [{ teams: this.teams.map(x => x.id) }]);
    this.$store.dispatch('assets/getAssetsList');
    this.$store.dispatch('settings/getSetting', {
      name: 'dashboard_chat_panel_configuration',
      stateName: 'chatPanelConfig',
    });

    this.selectConversationListener = params => {
      this.selectConversation(params);
    };
    Events.on('select.conversation', this.selectConversationListener);

    // TODO (Gabe) - convert this to customers/:customer-id/conversations
    if (get(this.$route, 'params.id')) {
      return this.redirectToOneSelectedConversation(this.$route);
    }

    adjustUrlArray(this.$route.path, this.$route.query);
    const tag_names = get(this.$route, 'query.tag_names', []);
    const customerSegments = get(this.$route, 'query.customer_segments', []);
    const teams = get(this.$route, 'query.teams', []);
    const realtimeFilters = pick(get(this.$route, 'query'),
      ['active', 'queued', 'ai_served', 'agent_served', 'unlocked', 'unresponded']);
    
    for (const [key, value] of Object.entries(realtimeFilters)) {
      realtimeFilters[key] = value === 'true';
    }

    if (tag_names.length || customerSegments.length || teams.length || !isEmpty(realtimeFilters)) {
      this.selectTab('all');
      this.isFilterOpened = true;
      this.currentFilter = {
        ...this.currentFilter,
        ...realtimeFilters,
        query: this.getQueryString(
          this.currentFilter.query,
          tag_names,
          customerSegments,
          teams,
        ),
      };
    }
    const date_range = get(this.$route, 'query.date_range');
    if (date_range) {
      this.selectTab('all');
      this.isFilterOpened = true;
      this.currentFilter = {
        ...this.currentFilter,
        dateRange: {
          start_date: date_range[0] ? toIsoString(Number(date_range[0])) : null,
          end_date: date_range[1] ? toIsoString(Number(date_range[1])) : null,
        },
      };
    }

    this.startPoll();
    this.$nextTick(() => {
      this.loadList().then(() => {
        const val = this.selected ? this.selected.customer_id : null;
        this.setHighlightedKey(val);
      });
    });
  },

  beforeUnmount() {
    if (this.ps) {
      this.ps.destroy();
      this.ps = null;
    }
    this.stopPollIfPolling();
    Events.off('select.conversation', this.selectConversationListener);
    this.shortcutController.removeListener();

    // Extra variables clean up.
    // Ugly enough implementation that it needs to clean up global variable as well.
    this.selectedTab = 'mine';
    this.$store.dispatch('conversations/unfocus');
    this.$store.dispatch('conversations/setScope', this.selectedTab);
  },

  provide() {
    return {
      shortcutEmitter: this.shortcutEmitter,
    };
  },

  methods: {
    addListElemListeners() {
      const scrollWrapper = this.$refs.scrollWrapper;
      if (scrollWrapper) {
        scrollWrapper.addEventListener('ps-y-reach-start', () => {
          this.startPoll();

          if (this.conversations.length >= this.fetchLimit) {
            this.paramsToDefault();
          }
        });

        scrollWrapper.addEventListener('keydown', this.onKeyDown);
      }
    },
    updateScopeFilter() {
      this.updateScope('filter');
    },
    // Create a query string from the input parameters.
    // initValue = ''
    // tags = []
    // segments = ["anonymous"]
    // teams = ["triage team"]
    // => "segment:anonymous teams:"triage team"
    //
    // The return value is suitable for presentation to end users.
    getQueryString(initValue, tags, segments, teams) {
      function _typeArrayToString(a, type) {
        // eslint-disable-next-line
        return a.map(x => { return /\s/g.test(x) ? `${type}:"${x}"` : `${type}:${x}` })
          .join(' ');
      }
      let query = initValue;
      if (tags) {
        const tQuery = _typeArrayToString(tags, 'tag');
        query = query ? `${query} ${tQuery}` : tQuery;
      }
      if (segments) {
        const sQuery = _typeArrayToString(segments, 'segment');
        query = query ? `${query} ${sQuery}` : sQuery;
      }
      if (teams) {
        const teamQuery = _typeArrayToString(teams, 'team');
        query = query ? `${query} ${teamQuery}` : teamQuery;
      }
      return query;
    },
    getQueryFilterParamsFromQueryString(input) {
      if (!input) {
        return { query: null, filter_type: null };
      }
      // Split input on whitespace unless whitespace is within quotes.
      const params = input.match(/(?:[^\s"]+|"[^"]*")+/g);
      const query = [];
      const filter_type = [];
      for (const p of params) {
        if (/:/.test(p)) {
          // eslint-disable-next-line no-magic-numbers
          const [f, q] = p.split(':', 2);
          query.push(q.replace(/^['"]|['"]$/g, ''));
          filter_type.push(f);
        } else {
          query.push(p);
          filter_type.push(undefined);
        }
      }
      return { query, filter_type };
    },
    updateScopeTab() {
      this.updateScope('tab');
    },
    updateScope(changeType) {
      let scope;
      if (changeType === 'tab') {
        scope = this.selectedTab;
        this.$store.dispatch('conversations/setSelectedTab', this.selectedTab);
        // Reset filters when new tab is selected.
        this.selectedStatusFilters = [];
      } else if (this.selectedStatusFilters.length) {
        // We're only allowing setting one filter at a time.
        scope = this.selectedStatusFilters[0];
      } else {
        scope = this.selectedTab;
      }
      this.paramsToDefault();

      if (this.selectedTab !== 'focused') {
        this.$store.dispatch('conversations/unfocus');
        this.$store.dispatch('conversations/setScope', scope);
        this.offset = 0;

        // Do not need to load stats upon tab change
        if (!(changeType === 'tab')) {
          this.loadList(false)
            .then(() => {
              this.scrollToTop();
              this.ps.update();
              this.startPoll();
            });
        }
      }
    },

    filterOpenWatcher(value) {
      this.isFilterOpened = value;
    },

    filterWatcher(filter) {
      this.currentFilter = filter;
      this.paramsToDefault();
      this.loadList(false).then(() => this.ps.update());
    },

    onKeyDown(evt) {
      const { key } = evt;
      evt.preventDefault();

      switch (key) {
        case KEYBOARD_KEYS.ENTER: {
          this.selectHighlightedConversation();
          break;
        }
        default:
      }
    },

    loadList(loadStats = true) {
      const {
        query = '',
        message_content = '',
        agent_id = null,
        team_id = null,
        dateRange = { start_date: null, end_date: null },
        queued = false,
        unresponded = false,
        i_am_primary = false,
        ai_served = false,
        agent_served = false,
        active = false,
        unlocked = false,
      } = this.currentFilter;

      this.loading = true;
      const result = this.getQueryFilterParamsFromQueryString(query);
      const filter = {
        ...(result.query && { query: result.query }),
        ...(message_content && { message_content }),
        ...(agent_id && { agent_id }),
        ...(team_id && { team_id }),
        ...(result.filter_type && { filter_type: result.filter_type }),
        ...(queued && { queued: true }),
        ...(unresponded && { unresponded: true }),
        ...(active && { active: true }),
        ...(unlocked && { unlocked: true }),
        ...(ai_served && { ai_served: true }),
        ...(agent_served && { agent_served: true }),
        ...(i_am_primary && { i_am_primary: true }),

        ...((dateRange.start_date || dateRange.end_date) && dateRange),
      };
      const isFiltered = Object.values(filter).filter(Boolean).length > 0;
      const defaultValues = {
        ...(this.selectedSort && { order: this.selectedSort }),
        ...(isFiltered && { filter }),
        limit: this.limit,
        offset: this.offset,
        scope: this.scope,
        channel: this.channel,
      };

      return this.$store.dispatch('conversations/list', { ...defaultValues })
        .then(data => {
          if ((loadStats || !isFiltered) && ['all', 'team', 'mine'].includes(this.selectedTab)) {
            this.$store.dispatch('conversations/refreshCounters', { channel: this.channel, scope: this.selectedTab });
          }

          if (!data.data.pagination) {
            this.loaded = true;
          } else {
            log.debug(TAG, `load list, current=${this.offset} => now=${this.offset + this.limit}`);
            this.offset += this.limit;
            this.loaded = this.offset / this.limit >= data.data.pagination.pageCount;
            this.rowCount = get(data, 'data.pagination.rowCount', 0); // Save total record count
          }

          // Infinite scroll loaded and this component's loaded mean different things.
          if (this.scrollState) {
            this.loaded ? this.scrollState.complete() : this.scrollState.loaded();
          }

          this.loading = false;

          return data;
        });
    },

    onScrollLoad($state) {
      this.scrollState = $state;

      // If there are small items, it does not need to hit loadList again
      if (this.rowCount < CONVERSATION_LIMIT) {
        return null;
      }

      if (!this.loaded && !this.loading) {
        this.loadList(false)
          .then(() => this.ps.update());
      }
    },

    paramsToDefault() {
      this.offset = 0;
      this.loaded = false;
      this.loading = false;
    },

    scrollToTop() {
      if (this.ps) {
        this.ps.element.scrollTop = 0;
      }
    },

    selectConversation([id, customer]) {
      if (id === this.selected.id && !this.isMobileContext) {
        return;
      }

      this.$store.dispatch('conversations/selectConversation', { id, customer })
        .then(() => {
          this.setHighlightedKey(customer.id);
        });

      const item = this.conversations.find(c => c.id === id);
      if (item && item.unread_message_count > 0) {
        this.$store.dispatch('conversations/readConversation', id);
      }
      this.$store.dispatch('conversations/updateIsConversationOpen', true);
      this.$store.dispatch('conversations/getAttachments', {
        customerId: customer.id,
        mode: ATTACHMENT_HISTORY_TYPES.DOCUMENTS,
      });
    },

    /*
     * Return true if it has to redirect.
     */
    async redirectToOneSelectedConversation(routeInstance) {
      const customerId = get(routeInstance, 'params.id');

      await this.$store.dispatch('conversations/getCustomerById', { customerId });
      const customer = this.selectedCustomer;

      const conversationId = await this.$store.dispatch('conversations/getConversationIdFromCustomer', { customerId });

      this.selectedTab = 'focused';
      this.$store.dispatch('conversations/setScope', 'focused');
      this.$store.dispatch('conversations/focus', customer);
      this.selectConversation([conversationId, customer]);

      return true;
    },

    redirectToConversations() {
      this.$router.push({ path: '/conversations' });
    },

    selectHighlightedConversation() {
      const highlighted = this.conversations[this.highlightedIdx];

      if (!highlighted) { return }

      const { id, customer } = highlighted;
      this.selectConversation([id, customer]);
    },

    selectStatusFilters(filters) {
      this.selectedStatusFilters = filters;
    },

    selectTab(tab) {
      if (this.$route.path !== '/conversations') {
        return this.redirectToConversations();
      }

      this.selectedTab = tab;

      if (this.selectedTab !== 'focused') {
        this.$store.dispatch('conversations/unfocus');
      }
    },

    startPoll() {
      this.stopPollIfPolling();
      this.poll = window.setInterval(async () => {
        const { channel, scope, limit } = this;
        // Do not fetch if scope is not a part of (all, team, mine)
        // when a conversation is selected from different customer tab or
        // from notification.
        log.debug(TAG, `scope: (${scope}), selectedTab: (${this.selectedTab})`);
        if (!['all', 'team', 'mine'].includes(this.selectedTab)) {
          return;
        }

        try {
          await this.$store.dispatch('conversations/list', {
            channel, scope, polling: true, offset: 0, order: 'desc', limit,
          }, { root: true });
          await this.$store.dispatch('conversations/refreshCounters', { channel: 'all', scope: this.selectedTab });
        } catch (err) {
          this.$aiq.notify.error('Unable to update conversations');
        }
      }, DEFAULT_POLL_INTERVAL);  // eslint-disable-line

      log.debug(TAG, `Pooling Started with every ${DEFAULT_POLL_INTERVAL} secs`);
    },

    stopPollIfPolling() {
      if (this.poll) {
        log.debug(TAG, 'Stopping polling for mine and stats');
        window.clearInterval(this.poll);
        this.poll = null;
      }
    },

    async toggleConversationStarred(conversation) {
      if (!this.pendingReq.includes(conversation.id)) {
        this.pendingReq.push(conversation.id);
        try {
          await this.$store.dispatch('conversations/starConversation', [conversation.id, !conversation.isStarred]);
        } catch (err) {
          if (err.status === UNIQUE_VIOLATION_CODE) {
            this.$aiq.notify.error(this.$t('conversation_tab.side_panel.star_conversation.unique_violation_error'));
          } else {
            this.$aiq.notify.error(this.$t('conversation_tab.side_panel.star_conversation.star_error'));
          }
        } finally {
          this.pendingReq = this.pendingReq.filter(id => id !== conversation.id);
        }
      }
    },

    showConversationClosed(conversation) {
      return conversation.status === 'closed';
    },

    /**
     * Hidden functionality to delete customer
     */
    async onRemove(item) {
      try {
        await this.$store.dispatch('hidden/deleteCustomer', get(item, 'customer_id'));
        this.$aiq.notify.success('Deleted');
      } catch (err) {
        this.$aiq.notify.error(err.message);
      }
    },
  },
};
