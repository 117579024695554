<template lang="pug">
  
</template>

<script>
export default {
  name: 'importanceForm',
};
</script>

<style lang="scss">

</style>
