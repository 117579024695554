<template lang="pug">
.cobrowse
    iframe.embedded(v-if="url" ref="cobrowseFrame" :src="url" @load="onIframeLoad")
</template>

<script>
import { mapState } from 'vuex';
import * as log from 'loglevel';
import CobrowseAPI from 'cobrowse-agent-sdk';
import get from 'lodash/get';

const TAG = 'Cobrowse';
  const SESSION_SEARCH_TIMEOUT = 5 * 1000;  // eslint-disable-line

const COBROWSE_SESSION_STATE = {
  NOT_STARTED: 'not_started', // not part of SDK state
  STARTED: 'started',
  PENDING: 'pending',
  AUTHORIZING: 'authorizing',
  ACTIVE: 'active',
  ENDED: 'ended',
  CLOSED: 'closed', // not part of SDK state
};

const COMPONENT_EVENT = {
  INITIATED: 'initiated',
  ACCEPTED: 'accepted',
  CLOSED: 'closed',
  CANCELED: 'canceled',
  TIMEOUT: 'timed_out',
  SESSION: 'session',
};

let cobrowseApi = null;
export default {
  name: 'cobrowse',
  props: {
    remoteIdentity: {
      type: String,
      default: '',
    },
    clientIdentity: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },
  emits: ['session-closed', 'session-accepted', 'stateUpdate'],
  computed: {
    ...mapState({
      agent: state => state.agent.profile,
    }),
  },
  data() {
    return {
      url: '',
      previousSessionState: COBROWSE_SESSION_STATE.NOT_STARTED,
    };
  },
  async mounted() {
    try {
      const [cobrowseConfig, token] = await Promise.all([
        this.$store.dispatch('agent/getCobrowseConfig'),
        this.$store.dispatch('agent/getCobrowseToken', { identity: this.clientIdentity, name: this.name })]);

      if (!cobrowseConfig.enabled) {
        throw new Error('Cobrowse is not enabled. Check Backend config.');
      }

      cobrowseApi = new CobrowseAPI();
      cobrowseApi.token = token;
      const sessions = await cobrowseApi.sessions.list({ state: ['active'] });

      if (sessions.length >= cobrowseConfig.maxSessions) {
        throw new Error('All of the sessions are currently in use. Please try later.');
      }

      this.url = `https://cobrowse.io/connect?token=${token}&filter_user_id=${this.remoteIdentity}&end_action=none`;
      this.previousSessionState = COBROWSE_SESSION_STATE.STARTED;
      this.handleState(COBROWSE_SESSION_STATE.NOT_STARTED, this.previousSessionState, null);
    } catch (err) {
      this.$aiq.notify.error(err.message);
      log.error(err);
      this.$emit('session-closed');
    }
  },
  methods: {
    async onIframeLoad() {
      let sessionStarted = false;

      // attach to iframe (make sure it has loaded!)
      // const frameEl = document.getElementById('cobrowse');
      const frameEl = this.$refs.cobrowseFrame;
      const ctx = await cobrowseApi.attachContext(frameEl);

      setTimeout(() => {
        if (!sessionStarted) {
          log.debug(TAG, 'onIframeLoad() session was not established');
          ctx.destroy();
          this.$emit('session-closed');
          this.$aiq.notify.info('Can\'t find a customer session. The customer may not be connected.');
          this.notifyComponentState(COMPONENT_EVENT.TIMEOUT);
        }
      }, SESSION_SEARCH_TIMEOUT);

      // listen for updates to session
      ctx.on('session.updated', (session) => {
        sessionStarted = true;
        log.debug(TAG, 'onIframeLoad() session was updated', session.state, session.toJSON());

        this.handleState(this.previousSessionState, session.state, session);
        this.previousSessionState = session.state;
        if (session.ended) {
          ctx.destroy();
          this.$emit('session-closed', session);
        }
      });

      // interact with iframe
      await ctx.setTool('laser');
    },

    handleState(oldVal, newVal, session = null) {
      log.debug(TAG, 'handleState():', oldVal, newVal, session);

      if (oldVal === newVal) {
        return;
      }

      if (newVal === COBROWSE_SESSION_STATE.STARTED) {
        this.notifyComponentState(COMPONENT_EVENT.INITIATED, session);
      } else if (newVal === COBROWSE_SESSION_STATE.ENDED) {
        if (oldVal === COBROWSE_SESSION_STATE.ACTIVE) {
          this.$aiq.notify.info('Cobrowse session ended');
          this.notifyComponentState(COMPONENT_EVENT.CLOSED, session);
        } else {
          this.$aiq.notify.info('Cobrowse session was not canceled or denied.');
          this.notifyComponentState(COMPONENT_EVENT.CANCELED, session);
        }
      } else if (newVal === COBROWSE_SESSION_STATE.ACTIVE) {
        if (oldVal === COBROWSE_SESSION_STATE.AUTHORIZING) {
          this.$aiq.notify.info('Cobrowse session started');
          this.$emit('session-accepted', session);
          this.notifyComponentState(COMPONENT_EVENT.ACCEPTED, session);
        }
      } else if (newVal === COBROWSE_SESSION_STATE.AUTHORIZING) {
        this.$aiq.notify.info('Found the customer session and waiting for approval.');
      }
    },

    notifyComponentState(state, session) {
      let msg = '';
      switch (state) {
        case COMPONENT_EVENT.INITIATED:
          msg = 'Cobrowsing request initiated.';
          break;
        case COMPONENT_EVENT.ACCEPTED:
          msg = 'Cobrowsing session started with customer.';
          break;
        case COMPONENT_EVENT.CLOSED:
          msg = 'Cobrowsing session ended.';
          break;
        case COMPONENT_EVENT.CANCELED:
          msg = 'Cobrowsing cancelled due to customer rejection or cancelled request.';
          break;
        case COMPONENT_EVENT.TIMEOUT:
          msg = 'Cobrowsing session not established due to customer disconnection or cancelled request.';
          break;
        default:
          break;
      }

      this.$emit('stateUpdate', state, msg);

      if (state === COMPONENT_EVENT.ACCEPTED && get(session, 'id')) {
        log.debug('Send Session Update', state, get(session, 'id'));
        // Record session ID when it begins
        this.$emit('stateUpdate', COMPONENT_EVENT.SESSION, `Cobrowse Session: ${get(session, 'id')} by ${this.agent.email}`);
      }
    },
  },
};
</script>

<style lang="scss">
@import "./../../../../../styles/aiq-variables.scss";
.cobrowse {
  width: 100%;
  height: 100%;

}

.embedded {
  width: 100%;
  height: 100%;
}
</style>

