import COLORS from './colors';

export default {
  name: 'chartsConfigurationsMixin',
  computed: {
    conversationbyInteractionChartConfig() {
      const baseTStringPath = 'metrics_tab.plaform_tab.summary_tab.conversation_interaction';

      return {
        name: 'conversations_by_interaction',
        title: this.$t(`${baseTStringPath}.title`),
        metric: 'conversations_by_interaction',
        downloadUsingLineLabels: true,
        graphParams: {
          metricCategoryForY: 'percent'
        },
        lines: {
          agentonly: {
            label: 'Agent',
            position: 1,
            color: COLORS.tangerine,
          },
          botonly: {
            label: 'Bot',
            position: 2,
            color: COLORS.steel_blue,
          },
          customeronly: {
            label: this.$t(`${baseTStringPath}.customer_only`),
            position: 3,
            color: COLORS.lavender,
          },
          agentsbots: {
            label: 'Agent + Bot',
            position: 4,
            color: COLORS.bougainvillea,
          },
          botcustomer: {
            label: this.$t(`${baseTStringPath}.bot_and_customer`),
            position: 5,
            color: COLORS.blood_red,
          },
          agentscustomer: {
            label: this.$t(`${baseTStringPath}.agent_and_customer`),
            position: 6,
            color: COLORS.grass,
          },
          agentsbotcustomer: {
            label: this.$t(`${baseTStringPath}.agents_and_bot_and_customer`),
            position: 7,
            color: COLORS.orange,
          },
          nointeraction: {
            label: 'No interaction',
            position: 8,
            color: COLORS.celestial_blue,
          },
        },
        helpText: this.$t(`${baseTStringPath}.help_text`),
        maxTickLabelCount: 8,
        rightPadding: null,
        leftPadding: null,
      };
    }
  }
};