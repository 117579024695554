<template lang="pug">
.app
  router-view(v-if="loaded")
  aiq-dialog(title="Warning", width="400px", v-model="showWarning", :show-close="false", :close-on-click-modal="false")
    p Agent IQ does not support the current browser. For optimal experience please use
      a(href="https://www.google.com/intl/en/chrome/browser/desktop/index.html" target="_blank") Chrome
    template(v-slot:footer)
      span(class="dialog-footer")
        aiq-button(type="primary" @click="showWarning = !showWarning") Got it!
  .network-alert(v-if="hasBadConnection")
    aiq-alert(title="There is a connection problem!"
              type="error"
              :closable="false")
      .alert-body
        .alert-body-msg
          p You are not available for customers and no actions can be completed since you are disconnected from the internet. Please check your network connection and
          a(@click="refreshPage") refresh the page.
        .alert-body-state(v-if="disconnectedTime > 0")
          span Disconnected:
          aiq-timeago(:since="disconnectedTime.toString()", :auto-update="10")
  schedules-dialog(v-if="showScheduleDialog"
                   :showOnlyForm="!!scheduleDialogData"
                   :formData="scheduleDialogData")
</template>

<script>
import axios from 'axios';
import * as log from 'loglevel';
import { mapState, mapActions } from 'vuex';
import {
  requestNotificationPermission,
  socketConnection,
  Events,
} from './libs';
import { STATUS_CODES } from '@/constants';
import { CONNECTION_STATE } from '@/constants/network';
import get from 'lodash/get';
import GetGlobalSource from '@/libs/mixins/GetGlobalSource';

import SchedulesDialog from '@/pages/GlobalDialogs/SchedulesDialog/SchedulesDialog.vue';

/* TODO: VUE3 Check and move to main.js
Vue.config.productionTip = false;
Vue.config.silent = true;
Vue.config.devtools = process.env.DEVELOPER_MODE;
*/

export default {
  name: 'app',
  mixins: [GetGlobalSource],
  components: {
    SchedulesDialog,
  },
  computed: {
    ...mapState({
      aclToken: state => state.agent.aclToken,
      socketState: state => state.socketActions.state,
      socketStore: state => state.socketActions,
      disconnectedTime: state => state.socketActions.disconnectedTime,
      lastReconnectingTime: state => state.socketActions.lastReconnectingTime,
      showScheduleDialog: state => state.agent.globalUiVisibility.schedulesDialog,
      scheduleDialogData: state => state.agent.globalDialogData.schedulesDialog,
    }),
    hasBadConnection() {
      return [
        CONNECTION_STATE.ERROR,
        CONNECTION_STATE.RECONNECTING,
        CONNECTION_STATE.DISCONNECTED,
        CONNECTION_STATE.WARN,
      ].includes(this.socketState);
    },
  },
  watch: {
    // Triggered once acl token with permission is set
    // acl token is acquired by exchanging with login token and used
    // all the api call except /agents/me & /agents/me/acl
    aclToken(value) {
      if (value) {
        // should start socket connection process only after
        // we get acl token
        if (socketConnection.io._readyState !== 'open') {
          socketConnection.setTokenAndConnect(value);
        }

        this.$store.dispatch('agent/refreshAgentData')
          .then(() => {
            this.loaded = true;
          });

        requestNotificationPermission().catch(() => {});
      }
    },
    socketState(value) {
      log.debug(`new socket state: ${value}`);

      if (value === CONNECTION_STATE.READY) {
        this.showConnectionToast('Connected');
      } else if (value === CONNECTION_STATE.DISCONNECTED) {
        this.showConnectionToast('Connection lost');
      } else if (value === CONNECTION_STATE.RECONNECTING) {
        this.showConnectionToast('Trying to reconnect...');
      } else if (value === CONNECTION_STATE.ERROR) {
        const detailMsg = this.socketStore.lastSocketError ? this.socketStore.lastSocketError.message : '';

        this.$store.dispatch('instrumentations/captureError', {
          error: `socket error: ${detailMsg}`,
          data: this.socketStore,
        });
      }
    },
  },
  mounted() {
    this.showUnauthPage();

    window.onblur = () => {
      this.$store.dispatch('agent/changeWindowActivity', false);
    };

    window.onfocus = () => {
      this.$store.dispatch('agent/changeWindowActivity', true);
    };

    this.$store.dispatch('agent/changeIdlePopupActivity', true);

    this.$store.dispatch('agent/bootstrap')
      .then(() => {
        // let login component decide if we need to login
        const auth = get(this.$router.resolve(window.location.pathname), 'meta.auth', true);
        if (auth) {
          this.$router.push('/login');
        }
      });

    Events.on('select.conversation', ([_, customer]) => {
      if (this.$route.path !== '/conversations' && customer) {
        this.$router.push({
          name: 'ConversationByCustomerId',
          params: customer,
        });
      }
    });
  },
  data() {
    return {
      showWarning: false,
      toast: null,
      reconectingBlocker: false,
      loaded: false,
    };
  },
  beforeCreate() {
    axios.interceptors.request.use(
      (config) => {
        // Intercepter should not override if Authorization is already set by store
        if (!config.headers['Authorization'] && this.aclToken) {
          config.headers['Authorization'] = `Bearer ${this.aclToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const currentPath = get(this.$router, 'currentRoute.path', '');

        if (error.response && error.response.status === STATUS_CODES.UNAUTHORIZED && currentPath !== '/login') {
          this.$store.dispatch('agent/unsetCredentials')
            .then(() => {
              if (socketConnection.connected) {
                socketConnection.disconnect();
              }
              this.$router.push('/login');
            });

          const WAIT_IN_MS = 500;
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve({ loaded: true });
            }, WAIT_IN_MS);
          });
        }

        return Promise.reject(error);
      }
    );
  },
  created() {
    this.checkUserAgent();
  },

  updated() {
    this.showUnauthPage();
  },
  methods: {
    ...mapActions({
      getEnvConfigs: 'configs/getEnvConfigs',
    }),

    showConnectionToast(msg) {
      if (this.toast) {
        this.toast.close();
      }
      this.$nextTick(() => {
        this.toast = this.$aiq.notify.info(msg);
      });
    },

    checkUserAgent() {
      const browser = window.navigator.userAgent.toLowerCase();
      if (browser.indexOf('safari') > -1 && browser.indexOf('chrome') === -1 && browser.indexOf('crios') === -1) {
        this.showWarning = !this.showWarning;
      } else if (browser.indexOf('firefox') > -1 || browser.indexOf('opr') > -1 || browser.indexOf('edge') > -1) {
        this.showWarning = !this.showWarning;
      }
    },

    showUnauthPage() {
      !this.$route.meta.auth
      && (this.loaded = true);
    },

    refreshPage() {
      location.reload(); // eslint-disable-line
    },
  },
};
</script>

<style lang="scss">
@import './styles/main.scss';

.network-alert {
  position: absolute;
  top: 30px;
  right: 30px;
  max-width: 400px;
  z-index: 1000;

  .el-alert--error {
    background-color: #fef0f0;
    color: #f56c6c;
    .el-alert__description {
      color: #f56c6c;
    }
    .el-icon-close {
      color: #c0c4cc;
    }
  }

  .alert-body {
    font-size: 12px;

    .alert-body-msg {
      p {
        display: inline;
      }

      a {
        margin-left: 5px;
        cursor: pointer;
        color: #f56c6c;
        text-decoration: underline;
      }
    }

    .alert-body-state {
      span {
        margin-right: 5px;
      }
    }
  }
}
</style>
