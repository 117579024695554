
import get from 'lodash/get';
import { BOT_EMAIL } from '@/constants/agent';
import { ENTITY_PLACEHOLDER_UPDATE_TEXT } from '@/constants/conversation';

export const breakUpEntityMessage = (message, info) => {
  const { newEntities, oldEntities } = info;

  const sortedEntities = newEntities.sort((a, b) => a.start_index - b.start_index);

  let current = 0;
  const end = message.length - 1;
  const formattedMessage = [];

  // Will not re-highlight if the same entity is extracted more than once in a workflow
  sortedEntities.forEach(entity => {
    // Includes creating the beginning non-entity text string, even if it's empty
    const isNewEntity = !oldEntities.find(oldEntity => oldEntity.name === entity.name);

    if (isNewEntity) {
      formattedMessage.push({
        text: message.slice(current, entity.start_index),
      });
      formattedMessage.push({
        entityName: entity.name,
        // Not using resolved_value because capitalization is different
        text: message.slice(entity.start_index, entity.end_index),
      });

      current = entity.end_index;
    }
  });

  if (!formattedMessage[0]) {
    return message;
  }
  const lastEntity = sortedEntities[sortedEntities.length - 1];
  if (lastEntity) {
    // Create the ending non-entity text string, even if it's empty
    formattedMessage.push({
      isEntity: false,
      text: message.slice(lastEntity.end_index, end),
    });
  }

  return formattedMessage;
};

export const updateTaggedEntityIndices = () => {
  // TODO (Gabe) for smart updating of tagged entities
  // const { oldTemplate, oldTaggedEntities, newTemplate } = info;
};

function extractIndices(taggedEntity, taggedEntityIndex, entityIndex, expression) {
  const start = taggedEntity.start_index;
  const end = taggedEntity.end_index;

  const info = {
    entityIndex,
    taggedEntityIndex,
  };
  const indices = {
    [start]: info,
  };

  for (let i = start + 1; i < end; i += 1) {
    // Need to get the index of each broken down word or space

    /**
     * Extra expression check is only necessary now due to tagged_entities
     * not cleared properly.
     */
    if (expression && expression.template.substr(i, 1) === ' ') {
      indices[i] = info;
      indices[i + 1] = info;
    }
  }

  return indices;
}

export const wordIndexToEntityIndexByExpression = (annotation, expressions) => {
  const map = {};
  const entityNameToIndex = {};

  if (annotation.entities) {
    annotation.entities.forEach((entity, i) => {
      entityNameToIndex[entity.name] = i;
    });
  }

  if (annotation.tagged_entities) {
    annotation.tagged_entities.forEach((taggedEntity, i) => {
      const { expression_index } = taggedEntity;
      const entityIndex = entityNameToIndex[taggedEntity.name];

      if (!map[expression_index]) {
        map[expression_index] = {};
      }

      const expression = expressions[expression_index];
      const newMapping = extractIndices(taggedEntity, i, entityIndex, expression);
      map[expression_index] = { ...newMapping, ...map[expression_index] };
    });
  }

  return map;
};


export const replaceSuggestionEntityTags = ({
  suggestions,
  customer,
  primaryAgent,
  lockedByAgent,
  otherConversationAgents,
}) => {
  // TODO(sk): create entity update configs in ai-manager-backend
  const primaryAgentEmail = primaryAgent.email || '';
  let chosenAgent = lockedByAgent;

  if (!chosenAgent) {
    // first remove primary agent id and bot
    // if there's more than one agents remaining, choose first agent.
    chosenAgent = otherConversationAgents
      .filter(agent => ![primaryAgentEmail, BOT_EMAIL].includes(agent.email))[0] || undefined;

    // if there's no chosenAgent, chosenAgent is primaryAgent
    if (!chosenAgent) {
      chosenAgent = primaryAgent;
    }
  }

  // Set middle names to empty as they may not exist in profiles.
  const replaceValues = {
    '@customer_first_name': get(customer, 'profile.first_name', `${ENTITY_PLACEHOLDER_UPDATE_TEXT}_CUSTOMER_FIRST_NAME`),
    '@customer_middle_name': get(customer, 'profile.middle_name', ''),
    '@customer_last_name': get(customer, 'profile.last_name', `${ENTITY_PLACEHOLDER_UPDATE_TEXT}_CUSTOMER_LAST_NAME`),
    '@customer_email': get(customer, 'profile.email', `${ENTITY_PLACEHOLDER_UPDATE_TEXT}_CUSTOMER_EMAIL`),
    '@primaryAgent.first_name': get(primaryAgent, 'profile.first_name', `${ENTITY_PLACEHOLDER_UPDATE_TEXT}_PRIMARY_AGENT_FIRST_NAME`),
    '@primaryAgent.middle_name': get(primaryAgent, 'profile.middle_name', ''),
    '@primaryAgent.last_name': get(primaryAgent, 'profile.last_name', `${ENTITY_PLACEHOLDER_UPDATE_TEXT}_PRIMARY_AGENT_LAST_NAME`),
    '@agent_first_name': get(chosenAgent, 'profile.first_name', `${ENTITY_PLACEHOLDER_UPDATE_TEXT}_AGENT_FIRST_NAME`),
    '@agent_middle_name': get(chosenAgent, 'profile.middle_name', ''),
    '@agent_last_name': get(chosenAgent, 'profile.last_name', `${ENTITY_PLACEHOLDER_UPDATE_TEXT}_AGENT_LAST_NAME`),
  };

  const regExp = RegExp(Object.keys(replaceValues).join('|'), 'gi');
  for (let i = 0; i < suggestions.length; i += 1) {
    suggestions[i].value = suggestions[i].value.replace(regExp, matched => replaceValues[matched]);
  }

  return suggestions;
};
