import SidebarAndPanesWithRouting from '@/components/layouts/SidebarAndPanesWithRouting.vue';
import Summary from './children/Summary.vue';

const rootRoute = {
  path: 'assist',
  name: 'Assist',
  component: SidebarAndPanesWithRouting,
  meta: {
    auth: true,
  },
  props: {
    baseResource: '/metrics/assist',
  },
};

const commonChildMeta = {
  auth: true,
  resource: rootRoute.path,
  parentName: rootRoute.name,
};

const childRoutes = [
  {
    path: 'summary',
    name: 'Metrics.Assist.Summary',
    component: Summary,
    meta: {
      label: 'Summary',
      ...commonChildMeta,
    },
  },
];

rootRoute.props.children = childRoutes;

export default {
  ...rootRoute,
  children: childRoutes,
};
