import axios from 'axios';
import moment from 'moment';
import { getUrl } from '@/config/api.routes';
import { INGESTION_MODES } from '@/constants';

const GET_CONFLIICT_REGEX = /Conflict.*?original:.*?"id":(\d+)/i;

function parsedRecord(item) {
  item.record = JSON.parse(item.record);
  item.updated_at = moment(item.updated_at).format('MMM DD, YYYY hh:mm A');

  if (item.reason) {
    const matchConflict = item.reason.match(GET_CONFLIICT_REGEX);
    if (matchConflict && matchConflict.length) {
      const id = matchConflict[1];
      item.reason = `Conflict ${id}`;
    }
  }

  return item;
}

export default {
  namespaced: true,
  state: {
    records: [],
    mode: INGESTION_MODES.find(m => m.isDefault),
  },
  getters: {
    RECORD_STATE: () => ({
      ALL: 'all',
      SUCCESS: 'success',
      FAILURE: 'failure',
      SKIP: 'skip',
    }),
  },
  mutations: {
    SET_MODE(state, mode) {
      state.mode = mode;
    },
    SET_RECORDS(state, records) {
      state.records = records.map(parsedRecord);
    },
    SET_RECORD(state, record) {
      const parsed = parsedRecord(record);
      const foundIndex = state.records.findIndex(item => item.id === parsed.id);

      if (foundIndex > -1) {
        state.records[foundIndex] = parsed;
      }
      state.records = [...state.records];
    },
  },
  actions: {
    async loadImportedData({ commit }, { state, pagination = { limit: 20, offset: 0 } }) {
      const res = await axios.get(`${getUrl('management.ingestion')}/records`, {
        params: { state, ...pagination },
      });
      commit('SET_RECORDS', res.data.models);
      return res.data;
    },
    async resolveConflict({ commit }, { id, resolution = 'skip' }) {
      const res = await axios.put(`${getUrl('management.ingestion')}/records/${id}/resolution`, {
        resolution,
      });
      commit('SET_RECORD', res.data);
      return res.data;
    },
    async ingestFile({ state }, { data, override = false }) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const form = new FormData();
      form.append('file', data);
      if (state.mode.channel) {
        form.append('channel', state.mode.channel);
      }
      if (state.mode.template_index !== undefined) {
        form.append('template_index', state.mode.template_index);
      }

      let url = `${getUrl('management.ingestion')}${state.mode.endpoint}`;
      if (override) {
        url += '?resolution_mode=override'; // this makes an update request
      }
      const res = await axios.post(url, form, config);
      return res.data;
    },
  },
};
