import get from 'lodash/get';

export const getCustomerName = customer => {
  let name = get(customer, 'fullName', '');
  if (name === '') {
    name = `${get(customer, 'profile.first_name', '')} ${get(customer, 'profile.last_name', '')}`;
    if (name === ' ') {
      name = `Customer ${(customer ? customer.id : '')}`;
    }
  }
  return name;
};

export const getCustomerFirstName = customer => {
  let firstName = get(customer, 'profile.first_name', '');
  if (firstName === '') firstName = `Customer ${(customer ? customer.id : '')}`;
  return firstName;
};
