<template lang="pug">
  div(v-if="controlsVisible"
      class="controls") 
    i(v-if="isUpVisible"
      class="el-icon-arrow-up"
      @click="emitEvent('up')") 
    | {{ value }}
    i(v-if="isDownVisible"
      class="el-icon-arrow-down"
      @click="emitEvent('down')")
</template>

<script>
import debounce from 'lodash/debounce';

export default {
  name: 'customer-tab-segments-priority',
  props: {
    value: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    controlsVisible: {
      type: Boolean,
      default: false,
    },
    isUpVisible: {
      type: Boolean,
      default: false,
    },
    isDownVisible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['up', 'down'],
  methods: {
    emitEvent: debounce(function emitEventWithDebounce(type) {
      !this.disabled && this.$emit(type);
    }, 250), // eslint-disable-line no-magic-numbers
  },
};
</script>

<style lang="scss" scoped>
.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  i {
      cursor: pointer;
      padding: 2px;
  }
}
</style>
