<template lang="pug">
  aiq-tabs.history-tabs(:model-value="activeTab"
                        :key="selectedCustomer.id"
                        type="card"
                        @tab-click="onTabChange")
    aiq-tab-pane(label="Documents"
                :name="attachmentHistoryTypes.DOCUMENTS.key")
      .inline-intro
        | Review the documents associated with this conversation.
      documents(v-if="showDocuments"
                :records="records"
                :doInfiniteScroll="doInfiniteScroll"
                :getSender="getSender"
                :hasHeaderDate="hasHeaderDate"
                :getDate="getDate"
                @onScrollLoad="onScrollLoad")
    aiq-tab-pane(label="Assets"
                :name="attachmentHistoryTypes.MEDIA.key")
      .inline-intro
        |  Review the assets associated with this conversation.
      media(v-if="showMedia"
            :records="records"
            :doInfiniteScroll="doInfiniteScroll"
            :getSender="getSender"
            :hasHeaderDate="hasHeaderDate"
            :getDate="getDate"
            @onScrollLoad="onScrollLoad")

</template>
<script>
import { ATTACHMENT_HISTORY_TYPES } from '@/constants';
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import get from 'lodash/get';
import Documents from './Documents.vue';
import Media from './Media.vue';

const DATE_FORMAT = 'MMMM YYYY';
const LABEL_ME = 'Me';
const LABEL_THIS_MONTH = 'Current Month';

export default {
  name: 'history',
  components: {
    Documents,
    Media,
  },
  props: {
  },
  computed: {
    ...mapState({
      agentProfile: state => state.agent.profile,
      selectedCustomer: state => state.conversations.selectedCustomer,
      activeTab: state => state.conversations.attachmentsHistory.mode.key,
      records: state => state.conversations.attachmentsHistory.records,
      loaded: state => state.conversations.attachmentsHistory.loaded,
    }),
    showDocuments() {
      return this.activeTab === ATTACHMENT_HISTORY_TYPES.DOCUMENTS.key;
    },
    showMedia() {
      return this.activeTab === ATTACHMENT_HISTORY_TYPES.MEDIA.key;
    },
    doInfiniteScroll() {
      return this.records.models.length < this.records.pagination.rowCount
            && this.loaded;
    },
  },
  data() {
    return {
      attachmentHistoryTypes: ATTACHMENT_HISTORY_TYPES,
    };
  },
  methods: {
    ...mapActions({
      getAttachments: 'conversations/getAttachments',
    }),
    getSender(item) {
      return this.agentProfile.id === item.sender_id
        ? LABEL_ME
        : `${get(item, 'sender.first_name', '')} ${get(item, 'sender.last_name ', '')}`;
    },
    hasHeaderDate(index) {
      if (index === 0) return true;
      const current = moment(this.records.models[index].created_at).format(DATE_FORMAT);
      const beforeCurrent = moment(this.records.models[index - 1].created_at).format(DATE_FORMAT);
      return current !== beforeCurrent;
    },
    getDate(date) {
      const current = moment(date).format(DATE_FORMAT);
      return current === moment().format(DATE_FORMAT) ? LABEL_THIS_MONTH : current;
    },
    onTabChange({paneName: key}) {
      if (this.activeTab === key) return;
      this.getAttachments({
        customerId: this.selectedCustomer.id,
        mode: ATTACHMENT_HISTORY_TYPES[key],
      });
    },
    onScrollLoad($state) {
      this.getAttachments({
        offset: this.records.models.length,
        customerId: this.selectedCustomer.id,
        mode: ATTACHMENT_HISTORY_TYPES[this.activeTab],
      }).then(() => {
        if (this.records.models.length >= this.records.pagination.rowCount) $state.complete();
        else $state.loaded();
      });
    },
  },
};
</script>
