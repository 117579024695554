import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import axios from 'axios';
import { getUrl } from '@/config/api.routes';
import { wordIndexToEntityIndexByExpression } from '@/libs';

export default {
  namespaced: true,

  state: {
    id: '',
    name: '',
    actions: [],
    channel_actions: [],
    expressions: [],
    payload: {
      entities: [],
      tagged_entities: [],
    },
  },

  getters: {
    formEntities: state => get(state, 'payload.entities', []),
    formTaggedEntities: state => get(state, 'payload.tagged_entities', []),
    pristine: (state, getters, rootState) => isEqual(state, rootState.intents.selected),
    wordIndexToEntityIndexByExpression: state => state
      && wordIndexToEntityIndexByExpression(state.payload, state.expressions),
  },

  mutations: {
    ADD_ENTITY(state, entity) {
      state.payload.entities.push(entity);
    },
    UPDATE_ENTITY(state, info) {
      const { newEntity, index, updatedTaggedEntities } = info;
      state.payload.entities[index] = newEntity;
      state.payload.tagged_entities = updatedTaggedEntities;
    },
    DELETE_ENTITY(state, info) {
      const { index, updatedTaggedEntities } = info;
      state.payload.entities.splice(index, 1);
      state.payload.tagged_entities = updatedTaggedEntities;
    },

    CREATE_EXPRESSION(state, expression) {
      state.expressions.push({
        template: expression,
      });
    },
    UPDATE_EXPRESSION(state, info) {
      const { expressionIndex, template } = info;

      state.expressions[expressionIndex].template = template;
    },
    DELETE_EXPRESSION(state, indexToDelete) {
      state.expressions.splice(indexToDelete, 1);

      state.payload.tagged_entities.forEach(taggedEntity => {
        if (taggedEntity.expression_index > indexToDelete) {
          taggedEntity.expression_index -= 1;
        }
      });
    },

    UPDATE_ACTIONS(state, actions) {
      state.actions = actions;
    },
    UPDATE_CHANNEL_ACTIONS(state, channelActions) {
      state.channel_actions = cloneDeep(channelActions);
    },
    DELETE_ACTION(state, index) {
      state.actions.splice(index, 1);
    },

    UPDATE_TAGGED_ENTITIES(state, taggedEntities) {
      state.payload.tagged_entities = taggedEntities;
    },
    DELETE_TAGGED_ENTITY(state, taggedEntityIndex) {
      state.payload.tagged_entities.splice(taggedEntityIndex, 1);
    },

    UPDATE_PAYLOAD(state, payload) {
      state.payload = payload;
    },
  },

  actions: {
    annotate({ commit, state }) {
      const params = {
        entities: state.payload.entities,
        expressions: state.expressions,
        tagged_entities: state.payload.tagged_entities,
      };

      return axios.post(getUrl('aimanager.tagger'), params)
        .then(data => {
          const { expressions, ...updatedIntentPayload } = data.data;

          const payload = {
            ...state.payload,
            ...updatedIntentPayload,
          };

          commit('UPDATE_PAYLOAD', payload);
        });
    },
  },
};
