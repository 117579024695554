/**
 * Define communication between service worker & main app
 */
import has from 'lodash/has';
import values from 'lodash/values';

const COMMON_FIELDS = ['customer', 'conversation'];

export const IPC_EVENT = {
  REDIRECT: {
    name: 'redirect',
    validate: (name, data) => {
      for (const field of COMMON_FIELDS) {
        if (!has(data, field)) {
          throw new Error(`${name} event from service worker does not contain ${field}`);
        }
      }
    },
  },
};

export const sendMessage = (client, name, payload) => client.postMessage({
  name, payload, aiqMessage: true });

export const validateMessage = (message) => {
  const { name, payload } = message;
  const eventItem = values(IPC_EVENT).find(ev => ev.name === name);
  if (!eventItem) {
    throw new Error(`${name} event from service is not defined.`);
  }

  eventItem.validate(name, payload);

  return message;
};
