const DEFAULT_LIMIT = 1000;
export const AGENT_LIMIT = DEFAULT_LIMIT;
export const TEAM_LIMIT = DEFAULT_LIMIT;
export const TAG_LIMIT = DEFAULT_LIMIT;
export const CATEGORY_LIMIT = DEFAULT_LIMIT;
export const ASSETS_LIMIT = DEFAULT_LIMIT;
export const DOCS_LIMIT = DEFAULT_LIMIT;

export const PAGINATION_DEFAULT = {
  offset: 0,
  limit: 0,
  rowCount: 0,
};
