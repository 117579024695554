<template lang="pug">
layout-main(:showSidebar="false")
  management-page(title="Realtime"
                  :creatable="false"
                  :searchable="false"
                  :showHeader="false")
    template(v-slot:body)
      .tab-container
        aiq-tabs(v-if="tabs.length"
              :model-value="activeTab"
              @tab-change="onTabChange"
              class="custom-tabs"
              type="card")
          aiq-tab-pane(v-for="tab in tabs"
                    :key="tab.path"
                    :label="tab.label"
                    :name="tab.name")
            router-view(v-if="tab.name === activeTab")
</template>

<script>
import get from 'lodash/get';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import LayoutMain from '@/components/layouts/main.vue';

function gotToFirstValidChild(children, ctx) {
  const firstChild = children.find((child) => ctx.canView(ctx.buildResourcePath(child.path)));
  if (firstChild) {
    ctx.$router.push({ name: firstChild.name });
  } else {
    ctx.$router.push({ name: 'Error' });
  }
}

export default {
  name: 'pulse',
  components: {
    ManagementPage,
    LayoutMain,
  },
  props: {
    baseResource: {
      type: String,
      default: '',
    },
    children: {
      type: Array,
      default: [],
    },
  },
  data() {
    const tabs = this.children
      .filter(({ path }) => path && this.canView(this.buildResourcePath(path)))
      .map(({ path, meta, name }) => ({ path, label: meta.label, name }));

    return {
      activeTab: get(this.$route, 'name', ''),
      tabs,
    };
  },
  watch: {
    $route(to) {
      this.activeTab = to.name;
    },
  },
  methods: {
    onTabChange(name) {
      if (this.activeTab === name) {
        return;
      }
      this.$router.push({ name });
    },
    buildResourcePath(resource) {
      return `${this.baseResource}/${resource}`;
    },
  },
  beforeRouteEnter(to, _, next) {
    next(vm => {
      const { baseResource, children } = vm;
      if (baseResource === to.path) {
        gotToFirstValidChild(children, vm);
        return false;
      }
      return true;
    });
  },
  // We need it when beforeRouteEnter is not called
  // which is when navigating from a child route to a parent route
  beforeRouteUpdate(to, from, next) {
    if (to.name === from.meta.parentName) {
      gotToFirstValidChild(this.children, this);
    }
    next();
  },
};
</script>