<template lang="pug">
  modal-editor.edit-team-modal(:visible="visible"
                                :show-close="false"
                                recordType="Team"
                                :record="record"
                                :buttons="['close', 'delete', 'save']"
                                :afterSave="afterSave"
                                :isRecordValid="isSubmittable"
                                :skipConfirmation="!isRecordDirty"
                                ref="modalEditor")
    template(v-slot:content)
      .card-title-container
        .field-container.is-required
          label Name
          FilteredInput.name_input(size="small"
                                v-model="record.name"
                                placeholder="Add a Team Name"
                                name="name"
                                :max-length="25"
                                )
        .field-container
          label Description
          aiq-input.description_input(size="small"
                                      v-model="record.description"
                                      placeholder="Add a Description"
                                      name="description"
                                      )
        .field-container.full-width
          label Members
          .team-members
            aiq-select(v-model="record.agents"
                      size="small"
                      :suffix-icon="Icon.CaretBottom"
                      multiple
                      value-key="id"
                      name="members"
                      :reserve-keyword="false")
              aiq-option(v-for="item in agents"
                        :key="item.id"
                        :label="item.fullName"
                        :value="item"
                        filterable
                        default-first-option)
        .field-container.full-width
          label Watcher Members
          .team-members
            aiq-select(v-model="record.watcherAgents"
                      size="small"
                      :suffix-icon="Icon.CaretBottom"
                      multiple
                      value-key="id"
                      name="watcherAgents"
                      :reserve-keyword="false")
              aiq-option(v-for="item in watcherAgents"
                        :key="item.id"
                        :label="item.fullName"
                        :value="item"
                        filterable
                        default-first-option)
        .field-container.full-width
          label Keywords
          .team-keywords
          aiq-keyword-input(:list="record.keywords"
                            @createItem="addKeyword"
                            @deleteItem="deleteKeyword"
                            :deletable="true"
                            :itemMaxLength="keywordMaxLength"
                            name="keywords"
                            )
</template>

<script>
import without from 'lodash/without';
import difference from 'lodash/difference';
import { hosts } from '@/config/api.routes';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Editor from '@/components/Editor/Editor.vue';
import ModalEditor from '@/components/ModalEditor/ModalEditor.vue';

export default {
  name: 'teamEditor',
  extends: Editor,
  components: {
    ModalEditor,
  },
  setup () {
    return { v$: useVuelidate() };
  },
  validations () {
    return {
      record: {
        name: { required, $autoDirty: true },
        description: { $autoDirty: true },
        agents: { $autoDirty: true },
        watcherAgents: { $autoDirty: true },
        keywords: { $autoDirty: true },
      },
    };
  },
  computed: {
    agents() {
      return this.mergeInActiveAgents(this.record.agents);
    },
    watcherAgents() {
      return this.mergeInActiveAgents(this.record.watcherAgents);
    },
  },
  data() {
    return {
      keywordMaxLength: process.env.INPUT_PARAMS.keywordMaxLength,
      url: `${hosts.management}files/upload`,
      headers: {
        Authorization: this.$store.getters['agent/authToken'],
      },
      modelPath: 'teams/teamModel',
      selectAction: 'teams/selectTeam',
      fetchAction: 'teams/getTeam',
    };
  },
  methods: {
    async afterSave(newRecord) {
      // Attach/Detach member agents from team
      const existingAgentIds = newRecord.agents.map(e => e.id);
      const newAgentIds = this.record.agents.map(e => e.id);
      // If adding new team all agents should be save if not, only new ones
      const agentIdsToAdd = !this.record.id ?
        newAgentIds :
        difference(newAgentIds, existingAgentIds);
      await this.addAgents(newRecord.id, agentIdsToAdd);
      await this.removeAgents(newRecord.id, difference(existingAgentIds, newAgentIds));

      // Attach/Detach watcher agents from team
      const existingWatcherAgentIds = newRecord.watcherAgents.map(e => e.id);
      const newWatcherAgentIds = this.record.watcherAgents.map(e => e.id);
      // If adding new team all watcher agents should be saved, otherwise only new ones
      const watcherAgentIdsToAdd = !this.record.id ?
        newWatcherAgentIds :
        difference(newWatcherAgentIds, existingWatcherAgentIds);
      await this.addAgents(
        newRecord.id,
        watcherAgentIdsToAdd,
        'watcher',
      );
      await this.removeAgents(
        newRecord.id,
        difference(existingWatcherAgentIds, newWatcherAgentIds),
        'watcher',
      );

      return Promise.resolve(newRecord);
    },

    addAgents(teamId, agentIds, type = 'member') {
      const actions = agentIds.map(id => this.$store.dispatch('teams/joinToTeam', [teamId, id, type]));
      return Promise.all(actions);
    },

    removeAgents(teamId, agentIds, type = 'member') {
      const actions = agentIds.map(id => this.$store.dispatch('teams/leaveTeam', [teamId, id, type]));
      return Promise.all(actions);
    },
    addKeyword(keyword) {
      const existingKeywords = this.record.keywords;
      if (existingKeywords.includes(keyword)) {
        return;
      }
      this.record = {
        ...this.record,
        keywords: [...this.record.keywords, keyword],
      };
    },

    deleteKeyword(keyword) {
      this.record = {
        ...this.record,
        keywords: without(this.record.keywords, keyword),
      };
    },

    mergeInActiveAgents(baseAgents) {
      const agents = this.$store.getters['agents/sortedAgents'];
      if (!baseAgents) {
        return agents;
      }
      const disabledAgents = baseAgents.filter(
        (agent) => !agents.some((a) => a.id === agent.id),
      );
      return agents.concat(disabledAgents);
    },
  },
};
</script>

<style lang="scss" scoped>
.img-uploader-container {
  position: absolute;
  width: 130px;
}
</style>
