import axios from 'axios';
import * as log from 'loglevel';
import get from 'lodash/get';
import set from 'lodash/set';
import values from 'lodash/values';
import { getUrl } from '@/config/api.routes';

const TAG = '[hidden]:';

/*
 * NAME: {
 *    key: name in localstorage,
 *    type: Type that needs to be converted from string,
 *    default: default value used if not set in local storage,
 * }
 */
const LOCAL_STORAGE = {
  REPAIR_MODE: {
    key: 'repair_mode',
    type: Boolean,
    default: false,
    handler: (str) => str === 'true',
  },
};

function _getValueFromLocalStorage(config) {
  const stringValue = localStorage.getItem(config.key);
  if (stringValue === null) {
    localStorage.setItem(config.key, config.default);
    log.info(TAG, `Default - Update localstorage with key: ${config.key}, value: ${config.default}`);
    return _getValueFromLocalStorage(config);
  }

  if (config.handler) {
    return config.handler(stringValue);
  }

  return config.type(stringValue);
}

function getValueFromLocalStorage(config) { // eslint-disable-line 
  const val = _getValueFromLocalStorage(config);
  log.info(TAG, `getValueFromLocalStorage ${config.key}: ${val}`);
  return val;
}

export default {
  namespaced: true,
  state: {
    /**
     * Hidden/experimental features
     */
    repair: {
      enabled: getValueFromLocalStorage(LOCAL_STORAGE.REPAIR_MODE),
    },
  },
  getters: {
    /**
     * if this is on, some of hidden features are enabled such as deleting a customer
     */
    isRepairMode: (state, getters, rootState) => get(state, 'repair.enabled', false)
      && get(rootState.featureFlags, 'FEATURE_FLAGS.REPAIR_MODE_SUPPORT', false),
    changeHeaderForRepairMode: (_, getters, rootState) => getters.isRepairMode
      && get(rootState.featureFlags, 'FEATURE_FLAGS.CHANGE_HEADER_STYLE_FOR_REPAIR_MODE', false),
  },

  mutations: {
    UPDATE_STATE(state, { key, value }) {
      set(state, key, value);

      if (values(LOCAL_STORAGE).map(config => config.key).includes(key)) {
        log.info(`Update localstorage with key: ${key}, value: ${value}`);
        localStorage.setItem(key, value);
      }
    },
  },

  actions: {
    async deleteCustomer(_, id) {
      const customer = (await axios.get(`${getUrl('conversations.customers')}/${id}`)).data;
      const uuid = get(customer, 'identities.uuids.0.uuid');

      log.info('Deleting a customer', id, uuid, customer);
      if (uuid) {
        await axios.delete(`${getUrl('conversations.customers')}/uuid/${uuid}`);
      }
      return;
    },
  },
};
