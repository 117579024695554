import { KEYBOARD_KEYS } from './keyboardKeys';

const keys = KEYBOARD_KEYS;

/**
 * TODO (Gabe) - Link these with existing shortcuts to create single source of truth for
 * shortcut definition
 */

const C_LOWER = { evtKey: keys.C_LOWER, html: 'c' };
const F_LOWER = { evtKey: keys.F_LOWER, html: 'f' };
const L_LOWER = { evtKey: keys.L_LOWER, html: 'l' };
const S_LOWER = { evtKey: keys.S_LOWER, html: 's' };
const BACKSLASH = { evtKey: keys.BACKSLASH, html: '/' };
const UP_ARROW = { evtKey: keys.UP_ARROW, html: '&#9650;' };
const DOWN_ARROW = { evtKey: keys.DOWN_ARROW, html: '&#9660;' };
const ENTER = { evtKey: keys.ENTER, html: '&lt;Enter&gt;' };
const ESCAPE = { evtKey: keys.ESCAPE, html: '&lt;Esc&gt;' };

export const LEFT_LIST = {
  FOCUS_SEARCH_CONVERSATIONS: {
    keys: [F_LOWER],
    description: 'Focus on the search area (Conversation)',
  },
  FOCUS_SEARCH_IQTOOLS: {
    keys: [BACKSLASH],
    description: 'Focus on the search area (IQ Tools)',
  },
  SCROLL_IQTOOLS: {
    keys: [UP_ARROW, DOWN_ARROW],
    keyJoinText: 'or',
    description: 'Go up or down',
  },
  ENTER_IQTOOLS: {
    keys: [ENTER],
    description: 'Open the item',
  },
};

export const INSIDE_CONVERSATION = {
  FOCUS_INPUT: {
    keys: [BACKSLASH],
    description: 'Focus on the input field',
  },
  CLEAR_INPUT: {
    keys: [ESCAPE],
    description: 'While focused on the input field, clear it',
  },
  CLOSE_CONVERSATION: {
    keys: [C_LOWER],
    description: 'Close the conversation',
  },
  LEAVE_CONVERSATION: {
    keys: [L_LOWER],
    description: 'Leave the conversation',
  },
  TOGGLE_STAR_CONVERSATION: {
    keys: [S_LOWER],
    description: 'Star or unstar the conversation',
  },
  SCROLL_MESSAGES: {
    keys: [UP_ARROW, DOWN_ARROW],
    keyJoinText: 'or',
    description: 'Scroll up and down the messages',
  },
};

export default [
  {
    name: 'Left list',
    items: [
      LEFT_LIST.FOCUS_SEARCH_CONVERSATIONS,
      LEFT_LIST.FOCUS_SEARCH_IQTOOLS,
      LEFT_LIST.SCROLL_IQTOOLS,
      LEFT_LIST.ENTER_IQTOOLS,
    ],
  },
  {
    name: 'Inside conversation',
    items: [
      INSIDE_CONVERSATION.FOCUS_INPUT,
      INSIDE_CONVERSATION.CLEAR_INPUT,
      INSIDE_CONVERSATION.CLOSE_CONVERSATION,
      INSIDE_CONVERSATION.LEAVE_CONVERSATION,
      INSIDE_CONVERSATION.TOGGLE_STAR_CONVERSATION,
      INSIDE_CONVERSATION.SCROLL_MESSAGES,
    ],
  },
  {
    name: 'Right Panel',
    items: [],
  },
  {
    name: 'Inside IQtools middle section',
    items: [],
  },
];
