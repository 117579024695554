<template lang="pug">
  .assets-container
    aiq-dialog.dialog-transparent(v-model="preview"
                                  :show-close="false"
                                  :modal-append-to-body="false"
                                  :close-on-click-modal="true")
      aiq-image(v-if="preview" :file="previewFile")
    .assets-list
      div.drag(v-for="(item, index) in items",
          :transfer-data="{item, type: 'asset'}",
          :key="item.id",
          class="asset-item",
          @click="attachAsset(item)")
        i.iq-ico-expand.asset-expand(@click.stop="previewAsset(item)")
        .asset-item_img-preview
          aiq-image(:file="getImageThumbnailPayload(item)" :isGif="isGif(item)")
        .asset-item_title {{item.title}}
</template>

<script>
import get from 'lodash/get';
import GetGlobalSource from '@/libs/mixins/GetGlobalSource';

export default {
  mixins: [GetGlobalSource],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['itemClicked'],
  data() {
    return {
      previewFile: null,
      preview: false,
    };
  },
  methods: {
    attachAsset(asset) {
      this.$emit('itemClicked', {
        type: 'assets',
        item: asset,
      });
    },
    previewAsset(asset) {
      this.previewFile = this.getFilePayload(asset);
      this.preview = this.previewFile != null;
    },
    getFilePayload(asset) {
      return get(asset, 'file.payload', null);
    },
    getImageThumbnailPayload(asset) {
      const filePayload = this.getFilePayload(asset);
      return get(filePayload, 'thumbnail', null);
    },
    isGif(asset){
      return get(asset, 'file.payload.extension') === 'gif';
    }
  }
};
</script>

<style lang="scss" scoped>
  .el-input {
    margin: 8px 0 12px;
  }
  .assets-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .asset-item {
    position: relative;
    width: 134px;
    margin-bottom: 12px;
    border-radius: 4px;
    box-shadow: 0 0 3px 0 rgba(191,203,217,0.50);
    background-color: #fff;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 3px 2px rgba(191,203,217,0.50);

      .asset-expand {
        opacity: 1;
      }
    }
    &:active {
      box-shadow: none;
    }

    &_img-preview {
      width: 139px;
      height: 139px;
      background-position: center center;
      background-size: cover;
    }
    &_title {
      padding: 10px;
      color: #48576A;
      font-size: 11px;
      line-height: 13px;
      font-weight: 300;
    }
  }
  .asset-expand {
    position: absolute;
    top: 6px;
    right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #5993FF;
    cursor: pointer;
    opacity: 0;
  }
  .dialog-transparent {

    img {
      cursor: pointer;
    }
  }
</style>
