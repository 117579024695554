import Metrics from './Metrics.vue';
import Platform from './Platform/Platform.router';
import Customers from './Customers/Customers.router';
import Agents from './Agents/Agents.router';
import Insights from './Insights/Insights.router';
import Assist from './Assist/Assist.router';

const rootRoute = {
  path: '/metrics',
  name: 'Metrics',
  component: Metrics,
  meta: {
    auth: true,
  },
};
const childRoutes =
  [
    Platform,
    Customers,
    Agents,
    Insights,
    Assist,
  ];

export default {
  ...rootRoute,
  children: childRoutes,
};
