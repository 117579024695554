import COLORS from './colors';

const customerByConversationsChartConfig = {
  name: 'customers_by_conversations',
  title: 'Conversation Frequency',
  linesColor: COLORS.blue,
  metric: 'customers_by_conversations',
};

export default customerByConversationsChartConfig;
