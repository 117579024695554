import trimStart from 'lodash/trimStart';
import trimEnd from 'lodash/trimEnd';
import cloneDeep from 'lodash/cloneDeep';
import without from 'lodash/without';
import { h, nextTick } from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { aliasFilter, enforceSpaceAfterComma } from '@/libs';

import EntitySelect from './Popups/EntitySelect.vue';
import WordsetSelect from './Popups/WordsetSelect.vue';
import { KEYBOARD_KEYS } from '@/constants';

const { mapGetters, mapMutations, mapState } = createNamespacedHelpers('intents/formIntent');
const ARG_OFFSET_FOR_INDEX = 2;
const EXPR_OFFSET_WIDTH_DENOM = 3;
const EXPRESSION_LEFT_OFFSET_BOUND = 90;
const NUM_VIEW_TYPES = 3;
const TOOLTIP_LEFT_OFFSET_BOUND = 100;
const WORDSET_CHARS_OFFSET = 3;

const entityColorMap = {
  0: 'entity-orange',
  1: 'entity-green',
  2: 'entity-pink',
  3: 'entity-blue',
};

const ExpressionItem = {
  name: 'expressionItem',
  props: {
    index: {
      required: true,
      type: Number,
    },
    item: {
      required: true,
      type: Object,
    },
  },
  components: {
    EntitySelect,
    WordsetSelect,
  },
  emits: ['update', 'delete'],
  computed: {
    ...mapGetters([
      'formTaggedEntities',
      'formEntities',
      'wordIndexToEntityIndexByExpression',
    ]),
    ...mapState({
      intentId: state => state.id,
    }),
    expression() {
      if (!this.item) return '';

      this.itemId = this.index;
      const stringSkeletonWithIndices = [];
      const stringSkeletonSansIndices = [];

      // This breaks down the expression into words and spaces, including the wordset { | } marker
      this.template.replace(/(\{[^{}]*\})|(\S+)|(.)/g, (e, ...args) => {
        stringSkeletonSansIndices.push(e);
        const i = args.length - ARG_OFFSET_FOR_INDEX;
        let entityIndex;
        let taggedEntityIndex;
        const indices = this.wordIndexToEntityIndexByExpression[this.itemId];
        if (indices && indices[args[i]]) {
          /* eslint-disable prefer-destructuring */
          entityIndex = indices[args[i]].entityIndex;
          taggedEntityIndex = indices[args[i]].taggedEntityIndex;
          /* eslint-enable prefer-destructuring */
        }

        // TODO(Gabe) Handle multi-word entities and extras
        const info = {
          entityIndex,
          substr: e,
          taggedEntityIndex,
          taggerInfo: {
            end_index: args[i] + e.length,
            expression_index: this.itemId,
            start_index: args[i],
          },
        };
        stringSkeletonWithIndices.push(info);
      });
      return { stringSkeletonWithIndices, stringSkeletonSansIndices };
    },
    selectedWordView() {
      const views = ['noPopup', 'entityView', 'wordsetView'];

      return views[this.selectedWordViewCycleIndex];
    },
  },
  data() {
    return {
      selectedWord: null,
      selectedWordIndex: null,
      selectedWordViewCycleIndex: 0,
      preparedWordset: [],
      taggedEntitiesOfExpression: [],
      template: this.item.template,
      newWord: '',
      itemId: -1,
      editFlag: false,
      selectedExpressionItem: {},
      selectedElement: {},
    };
  },
  mounted() {
    const filter = taggedEntity => taggedEntity.expression_index === this.itemId;
    const taggedEntitiesOfExpression = this.formTaggedEntities.filter(filter);
    this.taggedEntitiesOfExpression = cloneDeep(taggedEntitiesOfExpression);
  },
  watch: {
    // TODO (Gabe) For smart tagged entity logic
    intentId() {
      this.editFlag = false;
    },
    'item.template': {
      handler(newTemplate) {
        this.template = newTemplate;
      },
    },
    selectedWordViewCycleIndex(newIndex) {
      if (newIndex === 0) {
        this.selectedWord = null;
        this.selectedWordIndex = null;
        this.selectedElement = {};
      }
    },
  },
  methods: {
    ...mapMutations([
      'DELETE_TAGGED_ENTITY',
    ]),
    addWord(info) {
      const { word, startIndex } = info;
      const indexAdjustment = this.calculateTaggedEntityIndexAdjustment(true, word);
      this.adjustTaggedEntityIndices(indexAdjustment, startIndex);
      this.preparedWordset.push(word);
      this.updateItem();
    },

    adjustTaggedEntityIndices(amount, wordStartIndex) {
      this.taggedEntitiesOfExpression.forEach(taggedEntity => {
        if (taggedEntity.start_index > wordStartIndex) {
          taggedEntity.start_index += amount;
          taggedEntity.end_index += amount;
        }
      });
    },

    calculateTaggedEntityIndexAdjustment(isWordAdd, word) {
      // this.preparedWordset contains words that aren't the alias.
      if (isWordAdd) {
        if (this.preparedWordset.length > 0) {
          // Accounts for addition of pipe character
          return (word.length + 1);
        }
        // Accounts for addition of pipe character + 2 brace characters
        return (word.length + WORDSET_CHARS_OFFSET);
      } if (this.preparedWordset.length > 1) {
        return (word.length + 1) * -1;
      }
      return (word.length + WORDSET_CHARS_OFFSET) * -1;
    },

    constructEntityPopupComponent(h, props) {
      return h('entity-select', {
        on: {
          closeEntityConfig: this.closeConfig,
        },
        props,
      });
    },

    constructWordsetPopupComponent(h, props) {
      return h('wordset-select', {
        props: {
          alias: aliasFilter(this.selectedWord),
          nonAliasWords: this.preparedWordset,
          startIndex: props.startIndex,
          taggedEntityIndex: props.taggedEntityIndex,
        },
        on: {
          addWord: this.addWord,
          closeWordsetConfig: this.closeConfig,
          deleteTaggedEntity: this.deleteTaggedEntity,
          deleteWord: this.deleteWord,
        },
      });
    },

    closeConfig(elem) {
      if (!elem || elem !== this.selectedElement) {
        this.selectedWordViewCycleIndex = 0;
        this.selectedWord = null;
        this.$nextTick(() => {
          this.checkTooltipPosition(this.selectedExpressionItem);
        });
      }
    },

    cycleWordView() {
      this.selectedWordViewCycleIndex = (this.selectedWordViewCycleIndex + 1) % NUM_VIEW_TYPES;
    },

    deleteItem() {
      this.$emit('delete', this.itemId);
    },

    deleteTaggedEntity(taggedEntityIndex) {
      this.DELETE_TAGGED_ENTITY(taggedEntityIndex);
    },

    deleteWord(info) {
      const { word, startIndex } = info;
      const indexAdjustment = this.calculateTaggedEntityIndexAdjustment(false, word);
      this.adjustTaggedEntityIndices(indexAdjustment, startIndex);

      this.preparedWordset = without(this.preparedWordset, word);
      this.updateItem(this.preparedWordset.length === 0, false);
    },

    entitySelectionList(currentEntityIndex) {
      const list = cloneDeep(this.formEntities);
      if (currentEntityIndex >= 0) {
        list.splice(currentEntityIndex, 1);
      }
      return list;
    },

    isEntity(word) {
      return word.startsWith('@');
    },

    isWordset(word) {
      return word.match(/(\{[^{}]*\})/g);
    },

    saveEditedExpression() {
      this.template = enforceSpaceAfterComma(this.template);
      const info = {
        expressionIndex: this.itemId,
        template: this.template,
        taggedEntitiesOfExpression: this.taggedEntitiesOfExpression,
      };
      this.$emit('update', info);
      this.editFlag = false;
    },

    selectWord(word, index) {
      if (word === this.selectedWord) {
        this.cycleWordView();
      } else {
        this.selectedWordViewCycleIndex = 1;
        this.newWord = '';
        this.selectedWord = word;
        this.selectedWordIndex = index;
        this.preparedWordset = trimStart(word, '{');
        this.preparedWordset = trimEnd(this.preparedWordset, '}').split('|').slice(1);
      }
    },

    updateItem(all = false) {
      const baseWord = aliasFilter(this.selectedWord);
      const temp = all ? baseWord : `{${baseWord}|${this.preparedWordset.join('|')}}`;
      const expression = this.expression.stringSkeletonSansIndices;
      expression[expression.indexOf(this.selectedWord)] = temp;
      this.selectedWord = temp;
      this.preparedWordset = all ? [] : this.preparedWordset;

      const info = {
        expressionIndex: this.itemId,
        template: expression.join(''),
        taggedEntitiesOfExpression: this.taggedEntitiesOfExpression,
      };
      this.$emit('update', info);
    },

    checkTooltipPosition(el) {
      const { target: { offsetParent: item = {} } } = el;

      if (item.offsetLeft < TOOLTIP_LEFT_OFFSET_BOUND) {
        item.classList.add('pull-left');
      } else if (item.offsetParent.offsetWidth - item.offsetLeft < TOOLTIP_LEFT_OFFSET_BOUND) {
        item.classList.add('pull-right');
      }
    },
  },
  render() {
    const editButton = () => h(
      'i',
      {
        class: 'el-icon-edit',
        on: {
          click: () => {
            this.editFlag = true;
            nextTick(() => {
              document.getElementById('edit-expression').focus();
            });
          },
        },
      },
    );

    const expressionView = () => {
      const view = [];
      const checkPopupPosition = (el) => {
        const { target: { offsetParent: expression = {} } = {} } = el;
        if (expression.offsetLeft < EXPRESSION_LEFT_OFFSET_BOUND) {
          expression.offsetParent.classList.remove('pull-right');
          expression.offsetParent.classList.add('pull-left');
        } else if ((expression.offsetLeft + (expression.offsetWidth / EXPR_OFFSET_WIDTH_DENOM))
                  > (expression.offsetParent.offsetWidth - EXPRESSION_LEFT_OFFSET_BOUND)
                  && (expression.offsetParent.offsetWidth - EXPRESSION_LEFT_OFFSET_BOUND) > 0) {
          expression.offsetParent.classList.remove('pull-left');
          expression.offsetParent.classList.add('pull-right');
        } else {
          expression.offsetParent.classList.remove('pull-left');
          expression.offsetParent.classList.remove('pull-right');
        }
      };
      this.expression.stringSkeletonWithIndices.forEach(e => {
        let toolTip;
        const word = e.substr;
        // Ignore spaces
        if (!(/(\s)/.test(word)) || this.isWordset(word)) {
          const wordAttr = {
            on: {
              click: (event) => {
                this.selectedExpressionItem = event;
                checkPopupPosition(event);
                const index = e.taggerInfo.start_index;
                this.selectWord(word, index);
                this.selectedElement = event.target;
              },
              mouseenter: (event) => {
                this.checkTooltipPosition(event);
              },
            },
          };
          const wordComponent = [h('mark', wordAttr, aliasFilter(word))];

          const classObj = {
            'underline-blue': this.isWordset(word),
            'expression-item': true,
          };

          if (e.entityIndex >= 0) {
            // Rotate entities through the colors
            classObj[entityColorMap[e.entityIndex % Object.keys(entityColorMap).length]] = true;

            toolTip = h(
              'span',
              {
                class: {
                  'intent-entity-tooltip': true,
                },
              },
              `@${this.formEntities[e.entityIndex].name}`,
            );

            wordComponent.push(toolTip);
          }

          // Include the appropriate popup
          const index = e.taggerInfo.start_index;
          if (index === this.selectedWordIndex) {
            if (this.selectedWordView === 'entityView') {
              const entitySelectionList = this.entitySelectionList(e.entityIndex);
              const info = {
                alias: aliasFilter(word),
                entitySelectionList,
                taggerInfo: e.taggerInfo,
                taggedEntityIndex: e.taggedEntityIndex,
                word,
              };
              wordComponent.push(this.constructEntityPopupComponent(h, info));
            } else if (this.selectedWordView === 'wordsetView') {
              const wordsetProps = {
                taggedEntityIndex: e.taggedEntityIndex,
                startIndex: e.taggerInfo.start_index,
              };
              wordComponent.push(this.constructWordsetPopupComponent(h, wordsetProps));
            }

            if (e.entityIndex >= 0) {
              classObj['is-selected-entity'] = true;
            } else {
              classObj['is-selected-nonentity'] = true;
            }
          }

          // Include the display alias word
          view.push(h(
            'span',
            {
              class: classObj,
            },
            wordComponent,
          ));
        }
      });
      return view;
    };

    const expressionEntity = () => (this.editFlag
      ? h('input', {
        domProps: {
          value: this.template,
        },
        attrs: {
          class: 'edit-expression-input',
          id: 'edit-expression',
        },
        on: {
          keyup: event => {
            const { key } = event;

            // Save on Enter. Cancel on Escape.
            if (key === KEYBOARD_KEYS.ENTER) {
              this.saveEditedExpression();
            }

            if (key === KEYBOARD_KEYS.ENTER || key === KEYBOARD_KEYS.ESCAPE) {
              this.editFlag = false;
            }
          },
          input: event => {
            // TODO(Gabe) Smartly keep tagged entities.
            this.taggedEntitiesOfExpression = [];
            this.template = event.target.value;
          },
        },
      })
      : h('div', { class: 'expression-item-container' }, expressionView()));

    const expressionComponents = [h(
      'div',
      {
        class: 'expression-content',
      },
      [
        h(
          'span',
          {
            class: 'expression-indicator',
          },
        ),
        expressionEntity(),
        !this.editFlag && editButton(),
        h(
          'i',
          {
            class: 'el-icon-close',
            on: {
              click: () => {
                this.deleteItem();
              },
            },
          },
        ),
      ],
    )];

    return h(
      'li',
      {
        class: 'expression-container',
      },
      expressionComponents,
    );
  },
};

export default ExpressionItem;

/* TODO: VUE Check and fix
Vue.filter('alias', aliasFilter);
*/
