<template lang="pug" id="timeline">
extends ./Base.pug
</template>

<script>
import * as d3 from 'd3';
import { timelines } from 'd3-timelines';
import BaseChart from './Base.vue';
import { timeDelta } from '@/libs/formatters';
import moment from 'moment';

const msPerSeconds = 1000;


export default {
  extends: BaseChart,
  name: 'aiqTimelineChart',
  data() {
    const colorScale = d3.scaleOrdinal().range(this.lines.range).domain(this.lines.domain);
    return {
      d3Timelines: timelines()
        .colors(colorScale)
        .colorProperty(this.lines.colorProperty)
        .beginning(this.yScaleMin)
        .ending(this.y2ScaleMax),
      svg: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.renderChart);
    });
  },
  willDestroy() {
    this.$nextTick(() => {
      window.removeEventListener('resize', this.renderChart);
    });
  },
  methods: {
    getChartType() {
      return 'timelineChart';
    },
    renderChart() {
      this.DestroyChart();
      const container = d3.select(this.$el).select('.chart-container');

      container.selectAll('*').remove();

      container.append('text')
        .text(`ID: ${this.report.title}\n`)
        .append('text')
        .text(`date: ${moment.utc(this.yScaleMin * msPerSeconds).format('YYYY-MM-DD hh:mm a')}`);

      this.svg = container.append('svg');

      container.append('text').text(timeDelta(this.yScaleMax - this.yScaleMin));
      this.svg.attr('class', 'timeline-svg')
        .datum(this.report.datum)
        .call(this.d3Timelines);
    },
  },
};
</script>

<style lang="scss">
.timeline {
  .chart-container {
    height: 75px;

    .timeline-svg {
      width: 80%;
      height: 50px;
    }

    .timeline-label {
      display: none;
    }
  }
}
</style>
