import MyPerformance from './MyPerformance.vue';

export default {
  path: '/my/performance',
  name: 'My Performance',
  component: MyPerformance,
  meta: {
    auth: true,
    label: 'MyPerformance',
  },
};
