export const LABELS_TOOLTIPS = {
  CONVERSATIONS_IN_PROGRESS: {
    label: 'In Progress Conversations',
    tip: 'Displays the number of conversations that are in progress at the moment.  This is the sum of all conversations across all segments.',
  },
  QUEUED_CONVERSATIONS: {
    label: 'Queued Conversations',
    tip: 'Displays the number of conversations that are waiting to be passed to an agent. ',
  },
  UNRESPONDED_CONVERSATIONS: {
    label: 'Unresponded Conversations',
    tip: 'Displays the number of conversations that are waiting for a response from an agent. ',
  },
  SEGMENTS: {
    label: 'Segment',
    tip: 'Displays the different segments that are currently chatting.',
  },
  SERVED_BY_AI: {
    label: 'AI Service',
    tip: 'Displays the number of conversations where the customers are interacting with the bot or using self service options.',
  },
  SERVED_BY_BANKERS: {
    label: 'Agent Service',
    tip: 'Displays the number of conversations where customers are being helped by an agent.',
  },
  TEAMS: {
    label: 'Teams',
    tip: 'Displays the Teams that currently have agents logged into the system.',
  },
  AGENTS_PER_TEAM: {
    label: 'Agents per Team',
    tip: 'Displays the number of agents from each team that are logged into the system.',
  },
  TEAM_CAPACITY: {
    label: 'Team Capacity',
    tip: 'Displays the sum of the total capacity for all logged in agents from the corresponding team.',
  },
  TEAM_CONCURRENT_CAPACITY: {
    label: 'Team Concurrent Capacity',
    tip: 'Displays the sum of the total concurrent capacity for all logged in agents from the corresponding team. This represents the total available concurrent capacity for that team (how many new conversations they can handle).',
  },
  LAST_PERIOD: {
    label: 'Last ####',
    tip: 'Displays all the conversations and logins for the last ####.',
  },
  NEW_CONVERSATIONS: {
    label: 'New Conversations',
    tip: 'Displays the number of new conversations that have started within the last ####, shown by segment.',
  },
  MEDIAN_RT: {
    label: 'Median Response Time',
    tip: 'Displays the median number of agent response times for the corresponding segments. Response time is the time between a customer message and the agent\'s response.',
  },
  CUSTOMER_LOGINS: {
    label: 'Log Ins',
    tip: 'Displays the number of customers that have logged into the platform in the last ####, across all segments. ',
  },
};

export function replaceDuration(str, duration) {
  return str.replace(/####/, duration);
}
