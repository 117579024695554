<template lang="pug">
  route-with-sidebar(:baseResource="baseResource", :bars="viewableBars")
</template>

<script>
import pick from 'lodash/pick';
import { Component, Watch, Vue, toNative } from 'vue-facing-decorator';
import RouteWithSidebar from '@/components/layouts/routeWithSidebar.vue';

@Component({
  components: {
    RouteWithSidebar,
  },
  props: {
    baseResource: {
      type: String,
      default: '',
    },
    routeChildren: {
      type: Array,
      default: () => [],
    },
  },
})

class SideBarVue extends Vue {
  viewableBars = [];

  @Watch('$route')
  onRouteChange(to) {
    const barMenus = this.routeChildren.map(child => pick(child, ['path', 'name', 'meta']));
    this.viewableBars = barMenus.filter(({ path }) => this.canView(`${this.baseResource}/${path}`));

    if (to.path === this.baseResource && this.viewableBars.length !== 0) {
      const path = `${this.baseResource}/${this.viewableBars[0].path}`;
      this.$router.push(path);
    }
  }

  mounted() {
    this.onRouteChange(this.$route);
  }
}
export default toNative(SideBarVue);
</script>

<style>
</style>
