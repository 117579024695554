
// Keep an input_type default to avoid needing to populate later for schema
export const DEFAULT_STATEMENT = {
  input_type: 'string',
  operand_entity: '',
  operator: '',
  value: '',
};

export const DIALOG_TYPES = {
  TEXT: 'text_type',
  BUTTON: 'list_type',
  REST: 'rest_type',
};

export const DIALOG_TYPE_NAMES = {
  TEXT: 'Text',
  BUTTON: 'Button',

  ENTITY: 'Entity (from API)',
  // BUTTON_GROUP: 'Button Group (from API)',
};

export const JOIN_OPERATORS = [
  'and',
  'or',
];

export const MODES = {
  ENTITY: 'entity_filling',
  BUTTON: 'button_list',
};

export const NEW_CONDITIONAL_ACTION = {
  join_operator: 'and',
  statements: [DEFAULT_STATEMENT],
  actions: [],
};

export const MODELS = {
  [DIALOG_TYPE_NAMES.TEXT]: {
    dialog_type: 'text_type',
    prompt: '',
    entity: '',
    text_type: {
      alternative_ask: false,
      alternative_ask_prompt: '',
      alternative_ask_limit: 1,
      always_ask: false,
      confirmation: false,
      confirmation_prompt: '',
      success_actions: [],
      failure_actions: [],
      use_conditional_actions: false,
      conditional_actions: [],
      conditional_else_actions: [],
      value_type: 'integer',
    },
  },
  [DIALOG_TYPE_NAMES.BUTTON]: {
    dialog_type: 'list_type',
    prompt: '',
    entity: '',
    save_entity: true,
    list_type: {
      always_ask: true,
      response_values: [],
    },
  },
  [DIALOG_TYPE_NAMES.ENTITY]: {
    dialog_type: 'rest_type',
    save_entity: false,
    rest_type: {
      mode: 'entity_filling',
      domain_name: '',
      endpoint_name: '',
      requestPairs: [],
      responsePairs: [],
      use_conditional_actions: false,
      conditional_actions: [],
      conditional_else_actions: [],
      success_actions: [],
      failure_actions: [],
    },
  },
};

export default {
  DEFAULT_STATEMENT,
  DIALOG_TYPES,
  DIALOG_TYPE_NAMES,
  MODES,
  MODELS,
  NEW_CONDITIONAL_ACTION,
};
