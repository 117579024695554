import moment from 'moment';
import merge from 'lodash/merge';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { CRUDStoreBuilder } from '../StoreBuilder';

const alertMessageModel = () => ({
  name: undefined,
  payload: { message_langkey: undefined },
  alert_type: undefined,
  schedule_type: undefined,
  frequency: undefined,
  effective_date: undefined,
  expiration_date: undefined,
  start_time: undefined,
  end_time: undefined,
  segments: [],
  days: [],
});

export default merge(
  new CRUDStoreBuilder(
    'management.alertMessages',
    'alertMessage',
    [],
    'data',
    true,
  ).createStore(),
  {
    state: {
    },
    getters: {
      alertMessages: state => state.alertMessages,
      alertMessageModel,
    },
    actions: {
      async createOrUpdate({ dispatch }, record) {
        let payload = {
          ...record,
          ...(record.start_time && { start_time: moment(record.start_time).format('HH:mm:ssZ') }),
          ...(record.end_time && { end_time: moment(record.end_time).format('HH:mm:ssZ') }),
        };
        /*
          Removing null properties/values to prevent swagger validation failures
          due to lack of support over these scenarios
        */
        payload = omitBy(payload, isNil);
        const method = payload.id ? 'updateAlertmessage' : 'createAlertmessage';
        await dispatch(method, [{ ...payload }, 'data']);
        await dispatch('getAlertmessagesList');
      },
    },
  },
);
