import get from 'lodash/get';

export function applyReplyMessages({ messages }) {
  let lastReplyMessage = null;

  for (let i = 0; i < messages.length; i += 1) {
    const message_type = get(messages[i], 'payload.message_type');
    if (message_type === 'replies' || message_type === 'carousel') {
      if (lastReplyMessage) {
        // update selection
        messages[i] = { ...messages[i] };
        messages[i].replyitem = lastReplyMessage.payload.reply;
        lastReplyMessage = null;
      }
      messages[i].disabled = true;
    }

    if (message_type === 'reply') {
      lastReplyMessage = messages[i];
    }
  }
  return messages;
}

export function composeMessage({ files, content, originalContent, translation, message_type }) {
  let message = {};

  if (files) {
    // files are coming with message structure so just adds them to attachments
    message.rich_text = {
      attachments: [...files],
      content,
      originalContent,
      translation,
    };
    message.message_type = 'rich_text';
  } else if (message_type) {
    // if the message is already structured, let it go
    message = arguments[0]; // eslint-disable-line
  } else {
    message.content = content;
    message.originalContent = originalContent;
    message.translation = translation;
  }

  return message;
}

export function appendSenderProfile(message, agents) {
  if (!message || !agents || message.sender) {
    return message;
  }

  if (message.sender_type === 'agents' && agents.find) {
    const matchedAgent = agents.find(agent =>
      // eslint-disable-next-line implicit-arrow-linebreak
      parseInt(agent.id, 10) === parseInt(message.sender_id, 10)) || null;
    message.sender = matchedAgent;
  }
  return message;
}
