<template lang="pug">
div.edit-customer
  md-dialog-title Edit Customer
  
  md-dialog-content
  
    form.form-container

      md-input-container
        input(type="text", placeholder="First Name", name="firstName", v-model="customerClone.first_name")
      md-input-container
        input(type="text", placeholder="Last Name", name="lastName", v-model="customerClone.last_name")
      md-input-container
        input(type="tel", placeholder="Phone Number", name="phone", v-model="customerClone.phone")
      md-input-container
        input(type="email", placeholder="Email", name="country", v-model="customerClone.country")
  
  md-dialog-actions
      md-button.default-button(@click="cancel()") Cancel
      md-button.save-button(@click="save()") Save
</template>

<script>
export default {
  name: 'editCustomerForm',
  data() {
    return {
      customerClone: {
        first_name: '',
        last_name: '',
        phone: '',
        country: '',
      },
    };
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  emits: ['save', 'cancel'],
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    save() {
      this.$emit('save', this.customerClone);
    },
  },
  mounted() {
    this.customerClone = Object.assign(this.customerClone, this.customer);
  },
};
</script>

<style lang="scss">
.edit-customer {

    .form-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .md-input-container {
    width: 49%;
    min-height: auto;
    padding: 0;
    margin: 0 0 14px;

    &::after {
      background: none;
    }
    &.md-theme-agentiq {

      &.md-input-focused {
        
        &::after {
          background: none;
        }
      }
    }
    input,
    textarea {
      width: 100%;
      height: 32px;
      min-height: 32px;
      padding: 0 14px;
      margin: 0;
      color: #6a7682;
      font-size: 12px;
      border-radius: 4px;
      background-color: #e6f2fa;

      &::-webkit-input-placeholder {
          color: #6a7682;
          font-size: 12px;
      }
      &::-moz-placeholder {
          color: #6a7682;
          font-size: 12px;
          opacity: 1;
      }
      &:-moz-placeholder {
          color: #6a7682;
          font-size: 12px;
          opacity: 1;
      }
      &:-ms-input-placeholder {
          color: #6a7682;
          font-size: 12px;
      }
    }
    textarea {
      min-height: 100px;
      padding-top: 9px;
      padding-bottom: 9px;
    }
  }
}
</style>
