import download from 'downloadjs';
import moment from 'moment';
import get from 'lodash/get';
import union from 'lodash/union';
import concat from 'lodash/concat';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { doArraysIntersect } from './objectUtils';
import Papa from 'papaparse';

export function getFile(response) {
  const result = document.createElement('a');
  const contentDisposition = response.headers.get('Content-Disposition') || '';
  let filename = contentDisposition.split('filename=')[1];
  filename = filename.replace(/"/g, '');

  return response.blob()
    .then((data) => {
      result.href = window.URL.createObjectURL(data);
      result.target = '_self';
      result.download = filename;
      return result;
    });
}

const typesWithbins = () => Object.values(process.env.METRICS.typesWithBins);

const chooseXformat = (x, metricTypes, downloadWithTime = false) => {
  let format = 'MMM DD YYYY';
  if (doArraysIntersect(metricTypes, typesWithbins())) {
    return x;
  }
  if (downloadWithTime){
    format = `${format} ha`;
  }
  return moment(x).format(format);
};
const chooseHeader = (headers, metricTypes) => {
  let headCol = 'Timestamp';
  if (doArraysIntersect(metricTypes, typesWithbins())) {
    headCol = 'bin';
  }
  return concat([headCol], headers);
};
export function chartJSONToCSV(jsonContents, downloadWithTime = false) {
  const { dataset, relabeledHeaders } = jsonContents;
  const headers = [];
  const metricTypes = [];

  // If you want a customer header to be added in the exported report you can use this.
  if (relabeledHeaders?.length) {
    headers.push(...relabeledHeaders);
  } else {
    // collect headers and metric type
    for (let i = 0, len = dataset.length; i < len; i += 1) {
      const metricType = dataset[i].metric;
      metricTypes.push(metricType);
      for (let j = 0, jlen = dataset[i].data_points[0].ys.length; j < jlen; j += 1) {
        headers.push(`${metricType}_${dataset[i].data_points[0].ys[j].metric_category}`);
      }
    }
  }
  // collect content
  const content = [];
  for (let i = 0, len = dataset.length; i < len; i += 1) {
    const dataPoints = dataset[i].data_points;
    for (let j = 0, jlen = dataPoints.length; j < jlen; j += 1) {
      const point = dataPoints[j];
      if (i === 0) {
        content[j] = [];
        content[j].push(chooseXformat(point.x, metricTypes, downloadWithTime));
      }
      for (let k = 0, klen = point.ys.length; k < klen; k += 1) {
        content[j].push(point.ys[k].value);
      }
    }
  }
  return { headers: chooseHeader(headers, metricTypes), content };
}

export function tableJSONToCSV(jsonContents) {
  const { dataset } = jsonContents;
  let headers = [];
  const content = [];

  // collect header
  for (let i = 0, len = dataset.length; i < len; i += 1) {
    const dataPoints = dataset[i].data_points;
    for (let j = 0, jlen = dataPoints.length; j < jlen; j += 1) {
      const point = dataPoints[j];
      headers = concat(headers, Object.keys(point));
    }
  }
  headers = union(headers);

  // collect content
  for (let i = 0, len = dataset.length; i < len; i += 1) {
    const dataPoints = dataset[i].data_points;
    for (let j = 0, jlen = dataPoints.length; j < jlen; j += 1) {
      const point = dataPoints[j];
      if (i === 0) {
        content[j] = [];
      }
      for (let k = 0, klen = headers.length; k < klen; k += 1) {
        content[j].push(point[headers[k]]);
      }
    }
  }
  return { headers, content };
}

export function convertToString({ headers, content }) {
  const csvString = Papa.unparse({
    fields: headers,
    data: content
  });
  return csvString;
}

function metricJSONToCSV(jsonContents, dataType = 'chart', downloadWithTime = false) {
  if (dataType === 'chart') {
    return convertToString(chartJSONToCSV(jsonContents, downloadWithTime));
  } if (['table', 'tabular_chart_data'].includes(dataType)) {
    return convertToString(tableJSONToCSV(jsonContents));
  }
  return 'Unsupported data type.';
}

const removeMetricCategory = (datapoints) => datapoints.map(row => omit(row, 'metric_category'));

const formatData = (data, dataType, dataSource) => {
  const dataTypeToDataFormat = {
    chart: (inputData) => inputData,
    tabular_chart_data: (inputData) => ({
      dataset: [
        {
          // eslint-disable-next-line max-len
          data_points: !isEmpty(inputData.dataset) ? removeMetricCategory(inputData.dataset[0].data_points) : [],
        },
      ],
    }),
    table: (inputData) => ({
      dataset: [
        {
          data_points: dataSource === 'metrics' ? removeMetricCategory(inputData) : inputData,
        },
      ],
    }),
  };
  return get(dataTypeToDataFormat, dataType, (x) => x)(data);
};

function makeDownloadable(data, dataType, dataSource, downloadWithTime = false) {
  if (!(data && dataType)) {
    return '';
  }
  return metricJSONToCSV(formatData(data, dataType, dataSource), dataType, downloadWithTime);
}

function collectData(data, dataType, dataSource, multiple = false, downloadWithTime = false) {
  if (!(data && dataType)) {
    return '';
  }
  if (multiple) {
    return data.map(d => makeDownloadable(d, dataType, dataSource, downloadWithTime)).join('\n');
  }
  return makeDownloadable(data, dataType, dataSource, downloadWithTime);
}


export function downloadAsFile({
  filename = 'data.csv',
  mimeType = 'text/csv;charset=utf-8;',
  dataType = 'chart',
  dataSource = '',
  data,
  multiple = false,
  downloadWithTime = false,
}) {
  const combinedDataStr = collectData(data, dataType, dataSource, multiple, downloadWithTime);
  return download(combinedDataStr, filename, mimeType);
}

export function exportJsonFile(obj, name) {
  if (!obj || typeof obj !== 'object') {
    throw new Error(`Cannot convert ${obj} into json`);
  }

  return download(JSON.stringify(obj, null, 4), name);  // eslint-disable-line
}
