<template lang="pug">
  div(v-if="false")
</template>

<script>
import * as log from 'loglevel';

const PHONE_TABLET_SIZE = '(min-width: 768px)';
export default {
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      const mq = window.matchMedia(PHONE_TABLET_SIZE);
      const isMobileContext = !mq.matches;
      this.$store.dispatch('settings/setIsMobileContext', isMobileContext);
      log.debug(`WindowResize:handleResize() isMobileContext:${isMobileContext}`);
    },
  },
};
</script>
