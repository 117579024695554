
import merge from 'lodash/merge';

export default {

  SELECT_AGENT(state, agent) {
    state.selectedAgents[agent.id] = agent;
  },

  DESELECT_AGENT(state, agent) {
    delete state.selectedAgents[agent.id];
  },

  DESELECT_ALL_AGENTS(state) {
    state.selectedAgents = {};
  },

  SET_AGENTS(state, agents) {
    for (const agent of agents) {
      state.agents[agent.id] = agent;
    }
  },

  UPDATE_AGENTS(state, agents) {
    for (const agent of agents) {
      const updateAgent = merge(state.agents[agent.id], agent);
      state.agents[updateAgent.id] = updateAgent;
    }
  },

  CLEAR_STATE(state) {
    state.agents = {};
    state.selectedAgents = {};
    state.totalAgents = 0;
  },

  SET_TOTAL_AGENTS(state, rowCount) {
    state.totalAgents = rowCount;
  },
};
