import { OAuth, OpenIdCallback } from '../components/OAuth';

export default {
  path: '/oauth',
  component: OAuth,
  meta: {
    auth: false,
    resource: '/oauth',
  },
  children: [{
    path: ':integration/callback',
    component: OpenIdCallback,
    meta: {
      auth: false,
      permissions: 'all',
    },
    // props: (route) => ({ code: route.query.code }),
  }],
};
