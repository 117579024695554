import axios from 'axios';
import get from 'lodash/get';
import { getUrl } from '@/config/api.routes';
import startCase from 'lodash/startCase';

export default {
  namespaced: true,
  state: {
    access: {
      roles: [],
      metadata: {},
    },
    endpoints: [],
    domains: [],
    selectedDomainId: null,
    selectedEndpointId: null,
    isMobileContext: null,
    contextNavText: null,
    chatPanelConfig: null,
    agentStatuses: null,
  },
  mutations: {
    ADD_NEW_DOMAIN(state, newDomain) {
      state.domains.push(newDomain);
    },

    ADD_NEW_ENDPOINT(state, newEndpoint) {
      state.endpoints.push(newEndpoint);
    },

    DELETE_DOMAIN(state, id) {
      state.domains = state.domains.filter(d => d.id !== id);
    },

    DELETE_ENDPOINT(state, id) {
      state.endpoints = state.endpoints.filter(e => e.id !== id);
    },

    SET(state, { key, val }) {
      state[key] = val;
    },

    SET_ROLES_LIST(state, roles) {
      state.access.roles = roles;
    },

    SET_ROLES_METADATA(state, metadata) {
      state.access.metadata = metadata;
    },

    SET_DOMAINS_ENDPOINTS(state, settings) {
      state.domains = settings.domains;
      state.endpoints = settings.endpoints;
    },

    UPDATE_DOMAIN(state, domain) {
      state.domains = state.domains.map(
        d => (d.id === domain.id ? domain : d),
      );
    },

    UPDATE_ENDPOINT(state, endpoint) {
      state.endpoints = state.endpoints.map(
        e => (e.id === endpoint.id ? endpoint : e),
      );
    },

    SET_MOBILE_CONTEXT(state, value) {
      state.isMobileContext = value;
    },

    SET_CONTEXT_NAV_TEXT(state, value) {
      state.contextNavText = value;
    },
  },
  getters: {
    roleNames: (state) => (
      state.access.metadata.roles?.reduce((roleNames, metadata) => {
        if (!metadata.hidden) {
          const { name, hidden } = metadata;
          const label = metadata.label || startCase(name);
          roleNames.push({
            name,
            label,
            hidden,
          });
        }
        return roleNames;
      }, [])
    ),
    domainModel: () => ({
      name: '',
      description: '',
      auth_payload: { key: '' },
      protocol: 'https',
      host: '',
      auth_type: '',
      headers: {},
    }),
    endpointModel: (state, getters) => name => ({
      webhook_id: get(getters, 'selectedDomain.id', ''),
      name,
      path: '',
      method: '',
      query: {},
      custom_headers: {},
      request_payload_schema: '',
      response_payload_schema: '',
      response_payload_schema_type: 'json_schema',
      use_sample_payload: false,
      sample_payload: '',
    }),
    /**
     * Expands domain by populating its endpoints under the key "children".
     * Then, returns domains in array.
     */
    endpointsByDomain: state => {
      const { domains, endpoints } = state;
      const domainMap = domains.reduce((acc, domain) => {
        acc[domain.id] = {
          ...domain,
          children: [],
        };

        return acc;
      }, {});

      endpoints.forEach(endpoint => {
        if (domainMap[endpoint.webhook_id]) {
          domainMap[endpoint.webhook_id].children.push(endpoint);
        }
      });
      return Object.values(domainMap);
    },
    endpointsMap: state => state.endpoints.reduce((acc, endpoint) => {
      acc[endpoint.name] = endpoint;
      return acc;
    }, {}),
    selectedDomain: (state, getters) => getters.endpointsByDomain
      .find(d => d.id === state.selectedDomainId),
    selectedEndpoint: state => state.endpoints
      .find(e => e.id === state.selectedEndpointId),
    getIsMobileContext: state => state.isMobileContext,
    getContextNavText: state => state.contextNavText,
  },
  actions: {
    /**
     * Get a setting by same. If stateName is provided,
     * it stores into state.
     */
    async getSetting({ commit }, { name, stateName }) {
      const payload = (await axios.get(`${getUrl('settings.base')}/${name}`)).data;
      if (stateName) {
        commit('SET', { key: stateName, val: payload });
      }
      return payload;
    },
    async updateSetting({ commit }, { name, payload, stateName }) {
      const saved = (await axios.put(`${getUrl('settings.base')}/${name}`, payload)).data;

      if (stateName) {
        commit('SET', { key: stateName, val: saved });
      }
      return saved;
    },
    setIsMobileContext({ commit }, value) {
      commit('SET_MOBILE_CONTEXT', value);
    },
    setContextNavText({ commit }, value) {
      commit('SET_CONTEXT_NAV_TEXT', value);
    },
    createDomain({ commit }, params) {
      return axios.post(getUrl('aimanager.webhookDomains'), params)
        .then(({ data }) => {
          commit('ADD_NEW_DOMAIN', data);
          return data;
        });
    },
    createEndpoint({ commit }, params) {
      const path = `${getUrl('aimanager.endpoints')}`;

      return axios.post(path, params)
        .then(({ data }) => {
          commit('ADD_NEW_ENDPOINT', data);
          return data;
        });
    },
    deleteDomain({ commit }, id) {
      const path = `${getUrl('aimanager.webhookDomains')}/${id}`;
      return axios.delete(path).then(() => {
        commit('DELETE_DOMAIN', id);
      });
    },
    deleteEndpoint({ commit }, id) {
      const path = `${getUrl('aimanager.endpoints')}/${id}`;
      return axios.delete(path)
        .then(() => {
          commit('DELETE_ENDPOINT', id);
        });
    },
    getRolesList({ commit }) {
      return axios.get(`${getUrl('settings.access')}/roles`).then(data => {
        commit('SET_ROLES_LIST', get(data, 'data', []));
        return data;
      });
    },
    getAPISettings({ commit }) {
      const requests = [
        axios.get(`${getUrl('aimanager.webhookDomains')}`),
        axios.get(`${getUrl('aimanager.endpoints')}`),
      ];

      return Promise.all(requests).then(([domainsData, endpointsData]) => {
        const domains = domainsData.data.models;
        const endpoints = endpointsData.data.models;

        commit('SET_DOMAINS_ENDPOINTS', { endpoints, domains });
      });
    },

    getRolesMetadata({ commit }) {
      return axios.get(`${getUrl('settings.access')}/metadata`).then(data => {
        commit('SET_ROLES_METADATA', get(data, 'data', []));
        return data;
      });
    },

    selectDomain({ commit }, id) {
      commit('SET', { key: 'selectedDomainId', val: id });
    },

    selectEndpoint({ commit }, id) {
      commit('SET', { key: 'selectedEndpointId', val: id });
    },
    updateDomain({ commit }, params) {
      return axios.put(`${getUrl('aimanager.webhookDomains')}/${params.id}`, params)
        .then(({ data }) => {
          commit('UPDATE_DOMAIN', data);
        });
    },
    updateEndpoint({ commit }, params) {
      const path = `${getUrl('aimanager.endpoints')}/${params.id}`;
      return axios.put(path, params)
        .then(({ data }) => {
          commit('UPDATE_ENDPOINT', data);
        });
    },
    testEndpoint(_, params) {
      const path = `${getUrl('aimanager.apiRequests')}/test`;
      return axios.post(path, params)
        .then(({ data }) => data);
    },
    updateRole(params, roleData) {
      return axios.put(`${getUrl('settings.access')}/roles/${roleData.role}`, roleData.config)
        .then(data => data);
    },
    async exportDomain(_, name) {
      return (await axios.get(`${getUrl('aimanager.exports')}/api?name=${name}`)).data;
    },
    async importDomain(_, payload) {
      return (await axios.put(`${getUrl('aimanager.imports')}/api`, payload)).data;
    },
  },
};
