<template lang="pug">
layout-main
  .content-inner
    .header
      h1.title Reporting
    aiq-table.scrollbar(ref="complianceTable"
                      :data="compliance"
                      :default-sort="sortOrder"
                      v-loading="listPending")
      aiq-table-column(label="Report Name", prop="name", :sortable="true", width="300" label-class-name="is-bordered")
        template(v-slot:default="scope")
          aiq-button(link type="primary" size="small", @click="openPopup(scope.row)") 
            | {{ $t(`reporting_tab.table.${scope.row.name}.title`) }}
      aiq-table-column(label="Description", :sortable="true", label-class-name="is-bordered")
        template(v-slot:default="scope")
          div {{ $t(`reporting_tab.table.${scope.row.name}.description`) }}
  ExportPanel(v-if="isOpen"
              :compliance="selectedCompliance"
              @close="onClosePopup"
              @commitAction="commitAction"
              )


</template>

<script>
import { Component, Vue, toNative } from 'vue-facing-decorator';
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import LayoutMain from '@/components/layouts/main.vue';
import ExportPanel from './children/ExportPanel.vue';

const SEARCH_PAGE_SIZE = 100;

@Component({
  name: 'reporting',
  components: {
    LayoutMain,
    ExportPanel,
  },
  props: {
  },
  computed: {
    ...mapState({
      compliance: state => state.compliance.compliance,
      listPending: state => state.compliance.listPending,
    }),
  },
  methods: {
    ...mapActions({
      getComplianceList: 'compliance/getComplianceList',
      getCompliance: 'compliance/getCompliance',
    }),
  },
})

class CustomersDashboard extends Vue {
  pagination = {
    offset: 0,
    limit: 0,
    rowCount: 0,
  };
  isOpen = false;
  isLoadingAll = false;
  searchPageSize = SEARCH_PAGE_SIZE;
  sortOrder = { prop: 'name', order: 'ascending' };
  selectedCompliance = null;

  mounted() {
    try {
      this.getComplianceList();
    } catch (err) {
      this.$aiq.notify.error('Unable to get reports');
    }
  }

  openPopup(compliance) {
    this.selectedCompliance = compliance;
    this.isOpen = true;
  }

  onClosePopup() {
    this.selectedCompliance = null;
    this.isOpen = false;
  }

  async commitAction(payload) {
    try {
      const response = await this.getCompliance(payload);
      const reportName = this.$t('reporting_tab.report_name', {
        name: this.$t(`reporting_tab.table.${payload.name}.title`),
        from: moment.unix(payload.startDate).format('MMDDYYYY'),
        to: moment.unix(payload.endDate).format('MMDDYYYY'),
        defaultValue: '{{name}} {{from}} to {{to}}',
      });
      const type = response.headers.get('Content-Type') || '';
      const anchor = document.createElement('a');
      anchor.href = URL.createObjectURL(new Blob([response.data], { type }));
      anchor.target = '_blank';
      anchor.download = `${reportName}${payload.fileExtension}`;
      anchor.click();
      this.$aiq.notify.success('Report was generated success');
    } catch (err) {
      this.$aiq.notify.error('Unable to download the report');
    }
    this.isOpen = false;
    this.selectedCompliance = null;
  }
}
export default toNative(CustomersDashboard);
</script>

<style lang="scss" scoped>
@import "./../../styles/aiq-extensions.scss";
.content-inner {
  @extend %settings-content-inner;
  .header {
    margin-bottom: 24px;
  }
}
*:focus {
  border-color: transparent;
  background: unset;
  color: #409eff;
}
</style>
