<template lang="pug">
.docusign
  ManagementPage(title="DocuSign Integration"
                info="Configuration for signing feature"
                :creatable="false"
                :searchable="false")

  .docusigin-config(v-if="this.config !== null")
    aiq-col.padding-10(:span="12")
      shared-auth-config(:name='name'
                         :config="config"
                         title="DocuSign OAuth Configuration"
                         @update="onChange")
    aiq-col.padding-10(v-if="this.config.enabled" :span="12")
      .form-container
        .sub-header--solid DocuSign Signing Configuration
        .form__inner-content
          .form-field-container
            .form-field-label Authentication Domain
            aiq-input(v-model="this.config.docusign.base_auth_domain"
                      placeholder="account.docusign.com"
                      size="small"
                      @change="onSpecificChange('base_auth_domain', $event)")

          .form-field-container
            .form-field-label Landing Page After Signing
            aiq-input(v-model="this.config.docusign.completion_return_url"
                      placeholder="http://chat-service/close"
                      size="small"
                      @change="onSpecificChange('completion_return_url', $event)")

          .form-field-container
            .form-field-label Docusign Admin User ID
            aiq-input(v-model="this.config.docusign.user_id"
                      placeholder="4cacb4e8-d356-47ab-9771-f5e710d96b38"
                      size="small"
                      @change="onSpecificChange('user_id', $event)")

</template>

<script>
import get from 'lodash/get';
import { DOCUMENT_INTEGRATION } from '@/constants/integration';
import SharedAuthConfig from './SharedAuthConfig.vue';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';

export default {
  name: 'adobesign-tab',
  components: {
    SharedAuthConfig,
    ManagementPage,
  },
  computed: {
    name() {
      return DOCUMENT_INTEGRATION.DOCU_SIGN;
    },
    docusignConfig() {
      return get(this.config, 'docusign', {});
    },
  },
  data() {
    return {
      config: null,
    };
  },
  async mounted() {
    if (!this.name) {
      throw new Error(`name attribute should be set to use this component but ${this.name}`);
    }

    this.config = await this.$store.dispatch('integrations/getIntegration', { name: this.name });
  },
  methods: {
    async onUpdate(newConfig) {
      try {
        this.config = await this.$store.dispatch('integrations/updateIntegration', {
          name: this.name,
          payload: newConfig,
        });
        this.$aiq.notify.success('Updated');
      } catch (err) {
        this.$aiq.notify.error(`Failed: ${err.message}`);
      }
    },
    async onChange(subObject) {
      await this.onUpdate({ ...this.config, ...subObject });
    },

    async onEnabled(enabled) {
      await this.onUpdate({ ...this.config, enabled });
    },

    async onSpecificChange(field, value) {
      const docusign = { ...this.config.docusign, [field]: value };
      await this.onUpdate({ ...this.config, docusign });
    },
  },
};
</script>

<style lang="scss" scoped>
.form__inner-content {
  background: #FFFFFF;
  border: 1px solid #E0E6ED;
  display: flex;
  flex-direction: column;
  padding: 18px;
}

.padding-10 {
  padding: 10px;
}
</style>
