import merge from 'lodash/merge';
import axios from 'axios';
import { CRUDStoreBuilder, updateKeywordsActionBuilder } from '../StoreBuilder';
import { getUrl } from '@/config/api.routes';
import { ASSETS } from '@/constants/analytics';
import { get } from 'lodash';

const routingAlias = 'management.assets';

const assetsCrud = new CRUDStoreBuilder(routingAlias, 'asset', [], 'data.models', true).createStore();

export default merge(assetsCrud, {
  state: {
    selected: {},
  },

  getters: {
    assetModel: () => ({
      keywords: [],
    }),
  },

  mutations: {
    SELECT_ASSET(state, item) {
      state.selected = item;
      return item;
    },
  },

  actions: {
    selectAsset({ commit }, item) {
      commit('SELECT_ASSET', item);
      return item;
    },
    // attachAsset ({commit, dispatch}, asset) {
    attachAsset({ rootState, dispatch }, { asset, source }) {
      dispatch(
        'notifications/emitEventToSocket',
        { name: ASSETS.ATTACHED,
          payload: {
            agent: get(rootState, 'agent.profile'),
            asset,
          },
          source,
        },
        { root: true },
      );

      return dispatch('files/attachFile', { file: asset.file.payload, source }, { root: true });
    },
    updateKeywords({ commit }, [asset, keywords]) {
      const { id } = asset;
      return axios.put(`${getUrl(routingAlias)}/${id}`, { ...asset, keywords }).then(data => {
        commit('PUT_ASSET_SUCCESS', data.data);
        return data;
      });
    },
    async getAssetSearchList({ rootState, dispatch }, params) {
      const ret = await axios.get(getUrl('management.assets'), { params });
      dispatch(
        'notifications/emitEventToSocket',
        { name: ASSETS.SEARCHED,
          payload: {
            agent: get(rootState, 'agent.profile'),
            query: get(params, 'query'),
          },
        },
        { root: true },
      );
      return ret.data;
    },
    ...updateKeywordsActionBuilder(routingAlias),
  },
});
