import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { createNamespacedHelpers, mapGetters } from 'vuex';

import ActionItem from '@/pages/IQTools/Partials/ActionItem/ActionItem.vue';
import { JOIN_OPERATORS, DEFAULT_STATEMENT } from '@/constants/dialogConstants';

const { mapState } = createNamespacedHelpers('dialogs');

export default {
  name: 'conditionalActionsItem',
  components: {
    ActionItem,
  },
  props: {
    allowDelete: {
      type: Boolean,
      default: true,
    },
    allowEntitySelect: Boolean,
    entitySelectionList: {
      type: Array,
      default: () => [],
    },
    inputType: String,
    item: {
      type: Object,
      required: true,
    },
    itemNumber: {
      type: Number,
      default: 1,
    },
    operandEntity: String,
    excludeActionName: {
      type: String,
      default: null,
    },
  },
  emits: ['update:item', 'delete'],
  data() {
    return {
      record: cloneDeep(this.item),
    };
  },
  computed: {
    ...mapState(['entityOperatorsByValueType']),
    ...mapGetters({
      valueTypeList: 'dialogs/valueTypeList',
    }),
    canRemoveCondition() {
      return this.item.statements.length > 1;
    },
  },
  created() {
    this.$_joinOperators = JOIN_OPERATORS;
  },
  watch: {
    item: {
      handler: function recordWatcher(item) {
        if (!isEqual(item, this.record)) {
          this.record = cloneDeep(this.item);
        }
      },
      deep: true,
    },
    record: {
      handler: function recordWatcher(record) {
        this.$emit('update:item', record);
      },
      deep: true,
    },
  },
  methods: {
    addCondition() {
      const newCondition = cloneDeep(DEFAULT_STATEMENT);
      if (!this.allowEntitySelect) {
        newCondition.operand_entity = this.operandEntity;
        newCondition.input_type = this.inputType;
      }

      this.record.statements.push(newCondition);
    },
    deleteAction(actionIndex) {
      this.record.actions.splice(actionIndex, 1);
    },
    deleteConditionalActions(itemNumber) {
      this.$emit('delete', itemNumber - 1);
    },
    onAddAction(evt) {
      const list = this.record.actions;
      const idx = evt.newIndex;

      if (typeof list[idx] === 'object' && list[idx].name === this.excludeActionName) {
        list.splice(idx, 1);
      }
    },
    onInputTypeChange(idx) {
      this.record.statements[idx].operator = '';
    },
    removeCondition(index) {
      this.record.statements.splice(index, 1);
    },
  },
};
