import merge from 'lodash/merge';
import axios from 'axios';
import { CRUDStoreBuilder, updateKeywordsActionBuilder } from '../StoreBuilder';
import { alphabetSort } from '@/libs';
import { getUrl } from '../../config/api.routes';

const routingAlias = 'management.teams';

const teamsStore = {
  state: {
    selected: {},
    teams: [],
  },

  getters: {
    teams: state => state.teams,
    sortedTeams: state => state.teams.sort(alphabetSort),
    teamModel: () => ({
      name: '',
      description: '',
      keywords: [],
      agents: [],
    }),
  },

  mutations: {
    SELECT_TEAM(state, item) {
      state.selected = item;
    },

    JOIN_TO_TEAM(state, [team, agent]) {
      state.teams = state.teams.map(e => {
        if (e.id === team) {
          if (!e.agents) {
            e.agents = [];
          }
          e.agents.push(agent);
        }
        return e;
      });
    },

    LEAVE_TEAM(state, [team, agent]) {
      state.teams = state.teams.map(e => {
        if (e.id === team) {
          e.agents = e.agents.filter(j => j.id !== agent);
        }
        return e;
      });
    },
  },

  actions: {
    selectTeam({ commit }, item) {
      commit('SELECT_TEAM', item);
      return item;
    },

    joinToTeam({ commit, rootState }, [team, id = rootState.agent.profile.id, type]) {
      return axios.post(`${getUrl(routingAlias, team)}/agents`, {
        agent: { id },
        type,
      }).then(data => {
        commit('PUT_TEAM_SUCCESS', data.data);
        if (rootState.teams.selected.id === team) {
          commit('SELECT_TEAM', data.data);
        }
        return data;
      });
    },

    leaveTeam({ commit, rootState }, [team, id = rootState.agent.profile.id, type]) {
      return axios.delete(`${getUrl(routingAlias, team)}/agents/${id}/${type}`).then(data => {
        commit('PUT_TEAM_SUCCESS', data.data);
        if (rootState.teams.selected.id === team) {
          commit('SELECT_TEAM', data.data);
        }
        return data;
      });
    },

    // TODO (Gabe) move out of store since it mutate state
    uploadLogo(params, [team, logo]) {
      const logoForm = new FormData();
      logoForm.append('logo', logo);
      return axios.put(`${getUrl(routingAlias, team)}/logo`, logoForm).then(data => data);
    },

    ...updateKeywordsActionBuilder(routingAlias),
  },
};

export default merge(
  new CRUDStoreBuilder(
    routingAlias,
    'team',
    [],
    'data.models',
    true,
  ).createStore(),
  teamsStore,
);
