import inRange from 'lodash/inRange';
import isNaN from 'lodash/isNaN';

function isValidIpV4Address(ip) {
  const IPV4_PARTS = 4;
  const PART_MAX = 255;
  const PART_BASE = 10;
  const parts = ip.split('.');

  if (parts.length > IPV4_PARTS) {
    return false;
  }

  for (const p of parts) {
    const num = parseInt(p, PART_BASE);
    if (isNaN(num) || !inRange(num, 0, PART_MAX)) {
      return false;
    }
  }

  return true;
}

export {
  isValidIpV4Address,
};
