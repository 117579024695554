import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030.min';


// var m1 = moment;
// var m2 = new moment();
// m2.updateLocale(ack);

// debugger;

const hourFormat = 'h[h]';
const dayFormat = 'd[d]';
const minuteFormat = 'm[m]';
const secondFormat = 's[s]';

const buildDurationFormat = (duration) => {
  const formatParts = [];
  if (duration.days()) {
    formatParts.push(dayFormat);
  }
  if (duration.hours()) {
    formatParts.push(hourFormat);
  }
  if (duration.minutes()) {
    formatParts.push(minuteFormat);
  }
  if (duration.seconds()) {
    formatParts.push(secondFormat);
  }

  // default display
  if (!formatParts.length) {
    formatParts.push(secondFormat);
  }

  return formatParts.join(' ');
};

export const durationFormatter = (seconds = 0) => {
  const duration = moment.duration(seconds, 'seconds');
  const format = buildDurationFormat(duration);
  return moment.utc(duration.asMilliseconds()).format(format);
};

export const humanizeDuration = (seconds = 0) => moment.duration(seconds, 'seconds').humanize().replace(/a |an /, '');

export const getTimeZoneListWithAbbr = () => {
  const allTimeZones = moment.tz.names();
  const formattedTimeZones = allTimeZones.map(zone => `${zone} (${moment.tz(zone).zoneAbbr()})`);

  return formattedTimeZones;
};
