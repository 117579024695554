<template lang="pug">
.aiq-container
  aiq-image(:src="logo")
  p Success!
</template>

<script>
import get from 'lodash/get';
import * as log from 'loglevel';
import logo from '@/assets/agent-big-logo.svg';

const TAG = '[OIDC CB]:';

export default {
  data() {
    return {
      logo,
    };
  },
  mounted() {
    // This component gets called with the below form of URL
    // Ex) /oauth/:integration/callback?code=XXXX
    const source = get(this.$route, 'params.integration');
    const code = get(this.$route, 'query.code');
    log.debug(TAG, `source=${source}, code=${code}`);
    if (window.opener) {
      if (source && code) {
        window.opener.postMessage({ source, code });
      } else {
        window.opener.postMessage({ error: `source=${source}, code=${code}` });
      }
      // Do setTimeout that the postMessage has some time to process the event
      setTimeout(() => window.close(), 100); // eslint-disable-line
    }

    log.warn('There is no use case except running this component as a popup');
  },
};
</script>

<style scoped>
.aiq-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}
img {
  width: 100px;
  height: 100px;
}
p {
  width: 100%;
  margin-top: 30px;
  color: #324057;
  font-size: 24px;
  line-height: 29px;
  font-weight: 300;
  text-align: center;
}
</style>

