import upperFirst from 'lodash/upperFirst';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

export function upperFirstString(...args) {
  if (isEmpty(args)) {
    return '';
  }
  const names = args.filter(str => typeof (str) === 'string');
  return upperFirst(names.join(' ').trim());
}

export function upperFirstStrings(...args) {
  if (isEmpty(args)) {
    return '';
  }
  const names = args.filter(str => typeof (str) === 'string');
  return names.map(name => upperFirst(name)).join(' ').trim();
}

export function stringToNameObject(nameString) {
  const ret = {
    first_name: '',
    last_name: '',
  };

  if (!nameString) {
    return ret;
  }

  nameString = nameString.trim();
  const names = nameString.split(' ');

  if (names.lenth === 1) {
    ret.first_name = names[0];
    return ret;
  }

  ret.last_name = names.pop();
  ret.first_name = names.join(' ');
  return ret;
}

// Get name from Customer or Agent
export function getFullNamefromModel(model) {
  return `${get(model, 'profile.first_name', '')} ${get(model, 'profile.last_name', '')}`.trim();
}
