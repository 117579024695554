import axios from 'axios';
import { getUrl } from '@/config/api.routes';

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    geoLocation(_, query) {
      return axios.get(`${getUrl('management.tools')}/geolocation`, { params: query })
        .then(res => res.data);
    },
  },
};
