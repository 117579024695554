import COLORS from './colors';

const activePlatformCustomersChartConfig = {
  name: 'active_customers',
  title: 'Active Customers',
  metric: 'active_customers',
  lines: {
    daily: {
      label: 'Daily Count',
      position: 1,
      color: COLORS.yellow,
    },
  },
  maxTickLabelCount: 8,
  relabeledExportHeaders: ['Daily Count']
};

export default activePlatformCustomersChartConfig;
