import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import { Component, Vue, toNative } from 'vue-facing-decorator';
import { mapGetters, mapState } from 'vuex';
import CorsConfiguration from './CorsConfiguration.vue';
import CloseConversationConfiguration from './CloseConversationConfiguration.vue';
import ConversationEventConfiguration from './ConversationEventConfiguration.vue';
import WebchatLinkConfiguration from './WebchatLinkConfiguration.vue';
import ConversationEscalationConfiguration from './ConversationEscalationConfiguration.vue';


const DEFAULT_ERROR_PAGE_BTN_CONFIG = {
  label: '',
  event: 'close',
  event_message: '',
};

const DEFAULT_ERROR_PAGE_CONFIG = {
  error_key: '',
  type: 'popup',
  popup: {
    title: '',
    body: '',
    buttons: [DEFAULT_ERROR_PAGE_BTN_CONFIG],
  },
};

@Component({
  name: 'webchat-configuration-tab',
  components: {
    CorsConfiguration,
    CloseConversationConfiguration,
    ConversationEventConfiguration,
    WebchatLinkConfiguration,
    ConversationEscalationConfiguration,
  },
  computed: {
    ...mapState({
      showConversationEscalation: state => state.featureFlags.FEATURE_FLAGS.CONVERSATION_ESCALATION,
    }),
    ...mapGetters({
      segments: 'configs/segmentNames',
      workflowNames: 'workflows/workflowNames',
    }),
    crossValidation() {
      if (!this.tokenPolicy) {
        return false;
      }

      return this.tokenPolicy.bioToken.crossValidation;
    },
    saveAccessToken() {
      if (!this.tokenPolicy) {
        return false;
      }

      return this.tokenPolicy.access_token.save_upon_login;
    },
  },
})

class WebchatConfigurationTab extends Vue {
  cspPolicy = null;
  tokenPolicy = null;
  webchatErrorConfig = {
    errors: [],
  };
  webchatCorsConfig = null;
  closeConvConfig = null;
  convEventConfig = null;
  linkConfig = null;
  conversationEscalationConfig = null;

  async mounted() {
    const [
      cspPolicy,
      tokenPolicy,
      webchatErrorConfig,
      webchatCorsConfig,
      closeConvConfig,
      convEventConfig,
      linkConfig,
      conversationEscalationConfig,
    ] = await Promise.all([
      this.$store.dispatch('settings/getSetting', { name: 'csp_policy' }),
      this.$store.dispatch('settings/getSetting', { name: 'token_policy' }),
      this.$store.dispatch('settings/getSetting', { name: 'webchat_error_configuration' }),
      this.$store.dispatch('settings/getSetting', { name: 'webchat_cors_configuration' }),
      this.$store.dispatch('settings/getSetting', { name: 'close_conversation_policy' }),
      this.$store.dispatch('settings/getSetting', { name: 'conversation_event_configuration' }),
      this.$store.dispatch('settings/getSetting', { name: 'webchat_link_configuration' }),
      this.$store.dispatch('settings/getSetting', { name: 'webchat_conversation_escalation_configuration' }),
    ]);

    this.cspPolicy = cspPolicy;
    this.tokenPolicy = tokenPolicy;
    this.webchatErrorConfig = webchatErrorConfig;
    this.webchatCorsConfig = webchatCorsConfig;
    this.closeConvConfig = closeConvConfig;
    this.convEventConfig = convEventConfig;
    this.linkConfig = linkConfig;
    this.conversationEscalationConfig = conversationEscalationConfig;
  }

  addCspItem(name, item) {
    this.cspPolicy.directives[name] = [...this.cspPolicy.directives[name], item];
    return this.updateCspPolicy(this.cspPolicy);
  }

  deleteCspItem(name, item) {
    this.cspPolicy.directives[name] = this.cspPolicy.directives[name].filter(i => i !== item);
    return this.updateCspPolicy(this.cspPolicy);
  }

  async updateCspPolicy(payload) {
    try {
      this.cspPolicy = await this.updatePolicySetting(payload, 'csp_policy');
      this.$aiq.notify.success('Updated');
    } catch (err) {
      this.$aiq.notify.error(err.message);
    }

    return this.cspPolicy;
  }

  async updateTokenPolicy(path, val) {
    try {
      const payload = cloneDeep(this.tokenPolicy);
      set(payload, path, val);
      this.tokenPolicy = await this.updatePolicySetting(payload, 'token_policy');
      this.$aiq.notify.success('Updated');
    } catch (err) {
      this.$aiq.notify.error(err.message);
    }

    return this.tokenPolicy;
  }

  updatePolicySetting(payload, name) {
    return this.$store.dispatch('settings/updateSetting', {
      name,
      payload,
    });
  }

  cspInputValueChange(name, target) {
    this.cspPolicy.directives[name] = target;
    return this.updateCspPolicy(this.cspPolicy);
  }

  async tokenLifetimeInputValueChange(name, value) {
    return this.updateTokenPolicy(`tokenDuration.${name}`, parseInt(value, 10));
  }

  onCrossValidationChange(val) {
    return this.updateTokenPolicy('bioToken.crossValidation', val);
  }

  onSaveAccessTokenChange(val) {
    return this.updateTokenPolicy('access_token.save_upon_login', val);
  }

  onAddButtonPopupConfig(index) {
    const popupConfig = this.webchatErrorConfig.errors[index];
    popupConfig.popup.buttons.push(cloneDeep(DEFAULT_ERROR_PAGE_BTN_CONFIG));
  }

  onDeleteButtonPopupConfig(index, btnIndex) {
    const popupConfig = this.webchatErrorConfig.errors[index];
    popupConfig.popup.buttons = popupConfig.popup.buttons.filter((item, i) => i !== btnIndex);
  }

  onAddPopupConfig() {
    this.webchatErrorConfig.errors.push(cloneDeep(DEFAULT_ERROR_PAGE_CONFIG));
  }

  onDeletePopupConfig(index) {
    this.webchatErrorConfig.errors
      = this.webchatErrorConfig.errors.filter((item, i) => i !== index);
  }

  async onErrorConfigChange() {
    try {
      this.updatePolicySetting(this.webchatErrorConfig, 'webchat_error_configuration');
      this.$aiq.notify.success('Updated');
    } catch (err) {
      this.$aiq.notify.error(err.message);
    }
  }

  async onUpdateCors(newConfig) {
    try {
      this.webchatCorsConfig = await this.$store.dispatch('settings/updateSetting', {
        name: 'webchat_cors_configuration',
        payload: newConfig,
      });
      this.$aiq.notify.success('Updated');
    } catch (err) {
      this.$aiq.notify.error(err.message);
    }
  }

  async onUpdateCloseConvConfig(newConfig) {
    try {
      this.closeConvConfig = await this.$store.dispatch('settings/updateSetting', {
        name: 'close_conversation_policy',
        payload: newConfig,
      });
      this.$aiq.notify.success('Updated');
    } catch (err) {
      this.$aiq.notify.error(err.message);
    }
  }

  async onUpdateConvEventConfig(newConfig) {
    try {
      this.convEventConfig = await this.$store.dispatch('settings/updateSetting', {
        name: 'conversation_event_configuration',
        payload: newConfig,
      });
      this.$aiq.notify.success('Updated');
    } catch (err) {
      this.$aiq.notify.error(err.message);
    }
  }

  async onUpdateLinkConfig(newConfig) {
    try {
      this.linkConfig = await this.$store.dispatch('settings/updateSetting', {
        name: 'webchat_link_configuration',
        payload: newConfig,
      });
      this.$aiq.notify.success('Updated');
    } catch (err) {
      this.$aiq.notify.error(err.message);
    }
  }

  async onUpdateConversationEscalationConfig(newConfig) {
    try {
      this.conversationEscalationConfig = await this.$store.dispatch('settings/updateSetting', {
        name: 'webchat_conversation_escalation_configuration',
        payload: newConfig,
      });
      this.$aiq.notify.success('Updated');
    } catch (err) {
      this.$aiq.notify.error(err.message);
    }
  }
}
export default toNative(WebchatConfigurationTab);
