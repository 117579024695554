import SidebarAndPanesWithRouting from '@/components/layouts/SidebarAndPanesWithRouting.vue';
import featureFlags from '@/store/modules/featureFlags';
import get from 'lodash/get';
import Summary from './children/Summary.vue';
import Feedback from './children/Feedback.vue';
import Conversations from './children/Conversations.vue';
import Onboard from './children/Onboard.vue';

const showConversations = get(featureFlags, 'state.FEATURE_FLAGS.METRICS_CONVERSATIONS_TIMELINE_PAGE', true);

const rootRoute = {
  path: 'customers',
  name: 'Metrics.Customers',
  component: SidebarAndPanesWithRouting,
  meta: {
    label: 'Customers',
    auth: true,
    lang_key: 'metrics_tab.side_bar.customer_tab',
  },
  props: {
    baseResource: '/metrics/customers',
  },
};

const commonChildMeta = {
  auth: true,
  resource: rootRoute.path,
  parentName: rootRoute.name,
};

const childRoutes = [
  {
    path: 'summary',
    name: 'Metrics.Customers.Summary',
    component: Summary,
    meta: {
      label: 'Summary',
      ...commonChildMeta,
    },
  },
  {
    path: 'feedback',
    name: 'Metrics.Customers.Feedback',
    component: Feedback,
    meta: {
      label: 'Feedback',
      ...commonChildMeta,
    },
  },
  ...(showConversations ? [{
    path: 'conversations',
    name: 'Metrics.Customers.Conversations',
    component: Conversations,
    meta: {
      label: 'Conversations',
      ...commonChildMeta,
    },
  }] : []),
  {
    path: 'onboard',
    name: 'Metrics.Customers.Onboard',
    component: Onboard,
    meta: {
      label: 'Onboard',
      ...commonChildMeta,
    },
  },
];

rootRoute.props.children = childRoutes;

export default {
  ...rootRoute,
  children: childRoutes,
};
