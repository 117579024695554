<template lang="pug">
.sidebar-inner
  router-link.sidebar-item(v-for="item in showItems"
                          :key="item.href"
                          :to="item.href"
                          :class="getClass(item)")
    i.iq-ico-item
    | {{ $filters.toUpperFirst(item.title) }}
</template>

<script>
export default {
  name: 'sidebarDefault',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    currentPath() {
      return this.$route.path;
    },
    showItems() {
      return this.items.filter(item => !item.hideItem);
    },
  },
  methods: {
    getClass(item) {
      return {
        'router-link-active': item.href === this.currentPath,
        'sidebar-item_font--small': item.fontSize === 'small',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.md-theme-agentiq {

.sidebar-inner {
    border-radius: 0 4px 0 0;
}


.sidebar-item {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    width: 100%;
    font-size: 12px;
    line-height: 12px;

    &:hover {

      &:not([class*="active"]) {
        background-color: #d6e1f1;
        color: #4671B3;
        text-decoration: none;
      }
      &[class*="active"] {
        color: #fff;
        text-decoration: none;
      }
    }

    &[class*="active"] {
      color: #fff;
    }

    &:not(md-button) {
      color: #4671B3;
      font-weight: 300;
    }

    &.sidebar-item_font--small {
      font-size: 10px;
    };
  }
}
</style>
