<template lang="pug">
  .class-item
    h4.middle-column_content-title Intent Class
    aiq-select(v-model="className"
               size="small")
      aiq-option(v-for="classNameOption in unselectedClassNames"
                  :key="classNameOption"
                  :label="$filters.toUpperFirst(classNameOption)"
                  :value="classNameOption")
</template>

<script>
import upperFirst from 'lodash/upperFirst';
import { CLASS_NAMES } from '../../../constants';

export default {
  name: 'intents-class-name-select',
  props: {
    savedClassName: String,
  },
  emits: ['update'],
  data() {
    return {
      className: upperFirst(this.savedClassName),
    };
  },
  computed: {
    unselectedClassNames() {
      const { savedClassName } = this;
      return CLASS_NAMES.filter(name => name !== savedClassName);
    },
  },
  watch: {
    savedClassName(newClass) {
      this.className = newClass;
    },
    className(selectedName) {
      this.$emit('update', selectedName);
    },
  },
};
</script>

<style lang="scss" scoped>
.class-item {
  font-weight: 300;
}
</style>
