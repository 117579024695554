import axios from 'axios';
import { getUrl } from '@/config/api.routes';

const ROUTING_ALIAS = 'management.translation';

export default {
  namespaced: true,
  state: {
    records: [],
    languages: [],
  },
  getters: {},
  mutations: {
    SET_TRANSLATION(state, params) {
      state.records.push(params);
    },
    SET_LANGUAGES(state, languages) {
      state.languages = languages;
    },
  },
  actions: {
    translate({ commit, state }, params) {
      return axios.get(getUrl(ROUTING_ALIAS), { params })
        .then(({ data }) => {
          const { languages } = state;
          const [translation, { data: { translations: [{ detectedSourceLanguage }] } }] = data;
          const language = languages.find(l => l.code === detectedSourceLanguage);
          commit('SET_TRANSLATION', params);
          return { translation, language };
        });
    },
    languages({ commit }) {
      return axios.get(`${getUrl(ROUTING_ALIAS)}/languages`)
        .then(({ data }) => {
          commit('SET_LANGUAGES', data);
        });
    },
    detect({ state }, params) {
      return axios.get(`${getUrl(ROUTING_ALIAS)}/detect`, { params })
        .then(({ data }) => {
          const { languages } = state;
          let { language } = data;
          language = languages.find(l => l.code === language);
          return language;
        });
    },
  },
};
