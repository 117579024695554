import merge from 'lodash/merge';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import axios from 'axios';
import { CRUDStoreBuilder } from '../StoreBuilder';
import { alphabetSort } from '@/libs';
import { getUrl } from '@/config/api.routes';

const agentsCrud = new CRUDStoreBuilder('management.agents', 'agents', [], 'data.models', true).createStore();

const updateMutation = agentsCrud.mutations.PUT_AGENT_SUCCESS;
agentsCrud.mutations.PUT_AGENT_SUCCESS = (state, item) => {
  updateMutation(state, item);
};

export default merge(agentsCrud, {
  state: {
    selected: {},
    agents: [],
    list: [], // lighter list of agents than agents list
  },

  getters: {
    sortedAgents: state => sortBy(state.agents.sort(alphabetSort.bind(this, 'fullName')),
      agent => get(agent, 'available', '').toLowerCase()),
    sortedList: state => sortBy(state.list.sort(alphabetSort.bind(this, 'fullName')),
      agent => get(agent, 'available', '').toLowerCase()),
    agentsById: state => state.list.reduce((acc, agent) => {
      acc[agent.id] = agent;
      return acc;
    }, {}),
    agentModel: () => ({
      background: {},
      channels: [],
      picture: {},
      profile: {},
      preferences: {},
      roles: [],
      teams: [],
      locks: [],
      totalLocks: 0,
      max_load: process.env.MAX_LOAD_DEFAULT,
      concurrent_capacity: process.env.CONCURRENT_CAPACITY_DEFAULT,
    }),
  },

  mutations: {
    SELECT_AGENT(state, item) {
      state.selected = item;
    },

    SET_LIST(state, items) {
      state.list = items;
    },

    UPDATE_AGENT(state, agent) {
      state.list = state.list.map(e => {
        if (e.id === agent.id) {
          return {
            ...e,
            ...agent,
            isAvailable: agent.available,
          };
        }
        return { ...e };
      });
    },

    CREATE_AGENT(state, [_id, agent]) {
      agent.isAvailable = agent.available;
      state.agents.push(agent);

      return agent;
    },

    DELETE_AGENT(state, [id]) {
      const agentIndex = state.agents.findIndex(e => e.id === id);

      if (agentIndex !== -1) {
        state.agents.splice(agentIndex, 1);
      }
    },

    RESTORE_AGENT(state, [id]) {
      const agent = state.agents.find(e => e.id === id);

      if (agent) {
        set(agent, 'deleted_at', null);
      }
    },

    SET_ROLES(state, { type, roles, agent }) {
      if (!state.agents) return;
      state.agents = state.agents.map(_agent => {
        if (_agent.id === agent.id) {
          _agent[type] = roles ? Array.from(roles) : [];
        }
        return _agent;
      });
    },

    ATTACH_TEAM(state, params) {
      state.agents = state.agents.map(e => {
        if (e.id === params.agentId) {
          if (!e.teams) {
            e.teams = [];
          }
          e.teams.push(params.data);
        }
        return e;
      });
    },

    DETACH_ROLE(state, params) {
      state.agents = state.agents.map((e) => {
        if (e.id === params.agent.id) {
          const i = e[params.type].indexOf(params.role);
          if (i !== -1) {
            e[params.type].splice(i, 1);
          }
        }
        return e;
      });
    },

    DETACH_TEAM(state, params) {
      const teamId = params.roles.id;
      state.agents = state.agents.map(agent => {
        if (agent.id === params.agent.id) {
          let i;
          agent[params.type].forEach((e, index) => {
            if (e.id === teamId) {
              i = index;
            }
          });
          agent[params.type].splice(i, 1);
        }
        return agent;
      });
    },
  },

  actions: {
    selectAgent({ commit }, agent) {
      commit('SELECT_AGENT', agent);
      return agent;
    },
    // This action loads a lighter version of the list of agents
    async getAgentsSimpleList({ commit }, [params]) {
      const data = await axios.get(`${getUrl('management.agents')}/list`, { params });
      const { models } = data.data;
      commit('SET_LIST', models);
      return data;
    },
    deleteAgent({ commit }, id) {
      return axios.delete(`${getUrl('management.agents')}/${id}`)
        .then(data => {
          commit('DELETE_AGENT', [data.data.id, data.data]);
          return data;
        });
    },
    restoreAgent({ commit }, id) {
      return axios.put(`${getUrl('management.agents')}/${id}/restore`)
        .then(data => {
          commit('RESTORE_AGENT', [data.data.id]);
          return data;
        });
    },
    deactivateAgent(_, id) {
      return axios.put(`${getUrl('management.agents')}/${id}/deactivate`)
        .then(data => data);
    },
    updateAgent({ commit, rootState }, [agent]) {
      const me = get(rootState, 'agent.profile', {});
      const id = agent.id === me.id ? 'me' : agent.id;
      return axios.put(`${getUrl('management.agents')}/${id}`, agent)
        .then(data => {
          commit('UPDATE_AGENT', data.data);
          return data;
        });
    },
    createAgent({ commit, getters }, [agent]) {
      return axios.post(`${getUrl('management.agents')}`, agent)
        .then(data => {
          const modeledBody = merge(cloneDeep(getters.agentModel), data.data);
          commit('CREATE_AGENT', [data.data.id, modeledBody]);
          return data;
        });
    },
    setRoles({ commit }, e) {
      const roles = Array.from(e.roles);
      return axios.put(`${getUrl('management.agents')}/${e.agent.id}/roles`, { roles })
        .then(data => {
          commit('SET_ROLES', e);
          return data;
        });
    },
    attachTeam({ commit }, e) {
      const teamId = e.roles.id; // roles is reused here and is actually the team id
      return axios.post(`${getUrl('management.teams')}/${teamId}/agents`, { agent: { id: e.agent.id } })
        .then(data => {
          const { id, name } = data.data;
          commit('ATTACH_TEAM', { agentId: e.agent.id, data: { id, name } });
          return data;
        });
    },
    //  TODO (xrd): can probably remove this code.
    detachRole({ commit }, e) {
      return axios.delete(`${getUrl('management.agents')}/${e.agent.id}/roles/${e.role}`)
        .then(data => {
          commit('DETACH_ROLE', e);
          return data;
        });
    },
    detachTeam({ commit }, e) {
      const { id, type } = e.roles; // roles is reused here and is actually the team id and type
      return axios.delete(`${getUrl('management.teams')}/${id}/agents/${e.agent.id}/${type}`)
        .then(data => {
          commit('DETACH_TEAM', e);
          return data;
        });
    },
  },
});
