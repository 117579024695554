import axios from 'axios';

export default {
  namespaced: true,
  state: {
    config: {},
  },
  mutations: {
    SET_CONFIG(state, config) {
      state.config = config;
    },
  },
  getters: {
    config: state => state.config,
  },
  actions: {
    getConfigurations({ commit }) {
      return axios.get('config.json').then((res) => {
        const { data } = res;
        commit('SET_CONFIG', data);
        return data;
      });
    },
  },
};
