<template lang="pug">
.entities-table
  aiq-table(:data="entities"
            border)
    aiq-table-column(label="Name"
                      prop="name"
                      :sortable="true")
      template(v-slot:default="scope")
        span @{{ scope.row.name }}

    aiq-table-column(label="Type"
                    prop="type"
                    width="200"
                    class-name="cell-input")
      template(v-slot:default="scope")
        span {{ scope.row.type }}

    aiq-table-column(label="Description"
                    prop="description"
                    class-name="cell-input")
      template(v-slot:default="scope")
        span {{scope.row.description}}

    aiq-table-column(label="Example"
                    prop="example"
                    class-name="cell-input")
      template(v-slot:default="scope")
        span {{scope.row.example}}
</template>

<script>
export default {
  props: {
    entities: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
