/**
 * Note: This file is shared with service worker.
 * Please do not include browser specific import
 */
import has from 'lodash/has';
import get from 'lodash/get';
import { getCustomerName } from './customerHelper';

export const NOTIFICATION = {
  MODE_GROUP_BY_NONE: 'GROUP_BY_NONE',
  MODE_GROUP_BY_TYPE: 'GROUP_BY_TYPE',
  MODE_GROUP_BY_CUST: 'GROUP_BY_CUST',
  GENERIC_MESSAGE_TITLE: 'New customer message!',
  GENERIC_ASSIGNMENT_TITLE: 'New customer assignment!',
  NAMED_MESSAGE_TITLE: '{fullName} new message!',
  NAMED_ASSIGNMENT_TITLE: '{fullName} new assignment!',
  GENERIC_BODY: 'Click to chat now with the customer.',
  NAMED_BODY: 'Click to chat now with {firstName}.',
  GROUP_MESSAGE: 'new_message',
  GROUP_ASSIGNMENT: 'new_assignment',
  EVENT_MESSAGE: 'message',
  EVENT_ASSIGNMENT: 'conversations.agents.attach',
};

const PUSH_NOTIFICATION_EVENT = {
  'conversations.agents.attach': (customerName) => {
    const config = {
      title: NOTIFICATION.GENERIC_ASSIGNMENT_TITLE,
      body: NOTIFICATION.GENERIC_BODY,
    };

    if (customerName) {
      config.title = NOTIFICATION.NAMED_ASSIGNMENT_TITLE.replace('{fullName}', customerName);
    }

    return config;
  },
  'customers.messages.create': (customerName) => {
    const config = {
      title: NOTIFICATION.GENERIC_MESSAGE_TITLE,
      body: NOTIFICATION.GENERIC_BODY,
    };

    if (customerName) {
      config.title = NOTIFICATION.NAMED_MESSAGE_TITLE.replace('{fullName}', customerName);
    }

    return config;
  },
};

/**
 * Get notification config using command name
 *
 * @param eventName(string) command name
 * @param data(object) payload of command event,
 *          data contains at least conversation.id and customer.
 */
export const getNotificationConfig = (eventName, data) => {
  if (has(PUSH_NOTIFICATION_EVENT, eventName)) {
    return PUSH_NOTIFICATION_EVENT[eventName](getCustomerName(get(data, 'customer')));
  }

  return null;
};
