<template lang="pug" id="bar">
extends ./Base.pug
</template>

<script>
import BaseChart from './Base.vue';

export default {
  extends: BaseChart,
  name: 'aiqBarChart',
  methods: {
    getChartType() {
      return 'bar';
    },
  },
};
</script>

<style lang="scss">
</style>
