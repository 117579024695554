<template lang="pug">
.profile-editor
  aiq-dialog(:title="title",
             :model-value="visible"
             :show-close="false"
             @closed="")
    slot(name="content")
      .new-field-group
        h2 Add New Field
        .added-field
          aiq-input.field-item(v-model="newFieldName" placeholder="Field Name")
          aiq-input.field-item(v-model="newFieldDefault" placeholder="Default Value")
          aiq-select.field-item(v-model="newFieldType"
                     placeholder="Type")
            aiq-option(v-for="item in types"
                      :key="item"
                      :label="item"
                      :value="item")
          aiq-button.field-item(@click="addItem(newFieldName, newFieldDefault, newFieldType)") Add
      .added-fields
        h2 Profile
        .added-field(v-for="item in localItems")
          aiq-input.field-item(v-model="item.field" :disabled="true")
          aiq-input.field-item(v-model="item.default" :disabled="true")
          aiq-input.field-item(v-model="item.type" :disabled="true")
          aiq-button.field-item(type="danger" :disabled="!item.editable" @click="removeItem(item)") Remove

    template(v-slot:footer)
      aiq-button(@click="$emit('close')") Cancel
      aiq-button(:disabled="!isProfileChanged"
                 type="primary"
                 @click="$emit('save', localItems)") Save

</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

export default {
  name: 'ProfileEditorDialog',
  computed: {
    isProfileChanged() {
      return !isEqual(this.localItems, this.items);
    },
  },
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: [],
    },
    types: {
      type: Array,
      default: [],
    },
  },
  emits: ['error'],
  watch: {
    items(newItem) {
      this.localItems = cloneDeep(newItem);
    },
  },
  data() {
    return {
      newFieldName: '',
      newFieldDefault: '',
      newFieldType: '',
      localItems: cloneDeep(this.items),
    };
  },
  methods: {
    validate(item) {
      if (!item.field) {
        this.$emit('error', 'Field should not be empty.');
        return false;
      }

      if (item.field.includes(' ')) {
        this.$emit('error', 'Field can not be multiple words.');
        return false;
      }

      if (!item.type) {
        this.$emit('error', 'Type should be selected.');
        return false;
      }

      if (this.localItems.find(i => i.field === item.field)) {
        this.$emit('error', `${item.field} already exists.`);
        return false;
      }

      return true;
    },
    addItem(field, defaultVal, type) {
      const newItem = {
        field,
        default: defaultVal,
        type,
        editable: true,
      };

      if (!this.validate(newItem)) {
        return;
      }

      this.localItems.push(newItem);

      this.newFieldName = '';
      this.newFieldDefault = '';
      this.newFieldType = '';
    },
    removeItem(item) {
      this.localItems = this.localItems.filter(i => i.field !== item.field);
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-editor {
  h2 {
    margin: 10px;
  }
}

.added-field {
  display: flex;
  justify-content: space-between;
  .field-item {
    width: 24%;
  }

  margin-top: 10px;
}

</style>
