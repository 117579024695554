const TAGS_DESCRIPTION = `<p>Tags are words you use to filter messages.</p>
<p><strong>Important:</strong> If you archive a tag, contact AIQ to refresh your environment. 
Archived tags will continue to appear until a refresh occurs. A refresh automatically occurs during every release.</p>`;

const NON_DELETABLE_TAGS = ['Positive Sentiment', 'Negative Sentiment', 'Neutral Sentiment'];

export {
  TAGS_DESCRIPTION,
  NON_DELETABLE_TAGS,
};
