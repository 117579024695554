<template lang="pug">
  include ./panel.pug
</template>

<script>
import { mapState } from 'vuex';
import { get, union, concat, isNumber } from 'lodash';
import { mergeArrayOfObjs } from '@/libs';
import * as log from 'loglevel';
import { LABELS_TOOLTIPS, replaceDuration } from '@/constants';

const TAG = 'Pulse';
const DEFAULT_INTEVAL = 3000;

export default {
  name: 'pulsePanel',
  props: {
    label: {
      type: String,
    },
    action: {
      type: String,
      required: true,
    },
    storeKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('pulse', {
      metrics(state) {
        return this.storeKey ? state[this.storeKey] : null;
      },
    }),
    panelLabel() {
      return this.label;
    },
  },
  data() {
    return {
      pollId: null,
      segmentRowType: 'segment',
      teamRowType: 'team',
      labelsAndTips: LABELS_TOOLTIPS,
    };
  },
  async mounted() {
    await this.runPoll();
    this.startPoll();
  },
  beforeUnmount() {
    this.stopPoll();
  },
  methods: {
    async runPoll() {
      try {
        await this.poll();
      } catch (err) {
        this.$aiq.notify.error(`Unable to fetch ${this.storeKey} metrics`);
      }
    },
    startPoll() {
      if (this.pollId) {
        this.stopPoll();
      }
      const interval = get(this.metrics, 'pollIntervalMs', DEFAULT_INTEVAL);
      log.debug(TAG, 'Starts polling', this.storeKey, this.action, interval);
      this.pollId = window.setInterval(this.runPoll, interval);
    },
    stopPoll() {
      if (!this.pollId) {
        return;
      }
      window.clearInterval(this.pollId);
      this.pollId = null;
      log.debug(TAG, 'Stopped polling', this.storeKey, this.action);
    },
    poll() {
      return this.$store.dispatch(`pulse/${this.action}`, {});
    },
    queryData(key) {
      return get(this, 'metrics.data.dataset', []).find((d => d.metric === key));
    },

    getKeysForTabularData(tableData, metricName = '') {
      return Object.keys(tableData).filter(k => !['metric_category', metricName].includes(k));
    },
    formatterBasedOnCategory(data) {
      return this.getFormatterForMetricCategory(get(data, 'metric_category', ''));
    },
    wrapTableData(formattedRows, headColName, formattedHeadValue) {
      return {
        rows: formattedRows,
        header: { name: headColName, value: formattedHeadValue },
      };
    },
    extractMetric(key) {
      const dataForKey = this.queryData(key);
      if (!dataForKey) {
        return;
      }
      const metricValue = dataForKey.data_points[0].ys[0].value;
      const metricCategory = dataForKey.data_points[0].ys[0].metric_category;
      const formatter = this.getFormatterForMetricCategory(metricCategory);
      return formatter(metricValue);
    },

    extractCol(row, colName, defaultValue = '') {
      return get(row, colName, defaultValue);
    },
    getTabularMetricData(key) {
      return get(this.queryData(key), 'data', {});
    },
    colObjects(data, col, row, formatter) {
      return { [col]: formatter(this.extractCol(data, `${row}`)) };
    },
    combineMetricsData(metrics, rowType) {
      const rowKeys = union(...metrics.map(metric => metric.rowKeys));
      const cols = concat(...metrics.map(metric => metric.cols));
      const colsData = concat(...metrics.map(metric => metric.colsData));
      const formatters = concat(...metrics.map(metric => metric.formatter));
      const extractionClosures = concat(...metrics.map(
        metric => metric.extractionClosure,
      ));

      return rowKeys.map(
        row => Object.assign({ [rowType]: row },
          mergeArrayOfObjs(cols.map(
            (col, idx) => extractionClosures[idx](colsData[idx], col, row, formatters[idx]),
          ))),
      );
    },
    getFormatterForMetricCategory(category) {
      switch (category) {
        case 'percent':
          return (v) => `${v}%`;
        case 'timedelta':
          return this.$aiq.formatters.timeDelta;
        case 'interval':
          return this.$aiq.formatters.timeDelta;
        case 'timedeltaWithEmptyDefault':
          return (v) => (!isNumber(v) ? '' : this.$aiq.formatters.timeDelta(v));
        default:
          return (v) => v;
      }
    },
    replaceDuration,
  },
};
</script>

<style lang="scss" scoped>
</style>
