<template lang="pug">
.content-inner
    .header
      h1.title {{ $t('settings_tab.admin_tab.customer_tab.title') }}
    aiq-collapse.scrollbar__collapse(accordion)
      aiq-collapse-item(title="Segments")
        segments
</template>

<script>
import Segments from './Segments/Manage.vue';

export default {
  name: 'customer-tab',
  components: {
    Segments,
  },
};
</script>

<style lang="scss" scoped>
</style>
