import get from 'lodash/get';
import has from 'lodash/has';
import pick from 'lodash/pick';
import keys from 'lodash/keys';
import entries from 'lodash/entries';
import isEqual from 'lodash/isEqual';
import * as log from 'loglevel';
import axios from 'axios';
import { getUrl } from '@/config/api.routes';

/**
 * FIXME: This configuration is pretty hairy that some of
 *  configuration goes to ai-manager and some needs to go into
 *  base-api. And Ai_mode is sent to the both services.
 *  Ideally, All of these info should go to base-api
 *  and base-api hands over to AI on request.
 */
const triggerConfiguration = {
  // common
  authenticatedCustomerCreation: {
    type: 'object',
    settingName: 'authenticated_customer_creation_workflow_name',
    storedLocation: 'base-api',
  },
  listDialogAskAlternative: {
    type: 'bool',
    settingName: 'list_dialog_ask_alternative',
    storedLocation: 'ai-manager',
  },
  listDialogAlternativePrompt: {
    type: 'string',
    settingName: 'list_dialog_alternative_prompt',
    storedLocation: 'ai-manager',
  },
  channelInitWorkflow: {
    type: 'object',
    settingName: 'channel_init_workflow',
    storedLocation: 'base-api',
  },

  // non-dispatch
  initWorkflow: {
    type: 'object',
    settingName: 'init_workflow_name',
    storedLocation: 'base-api',
  },
  changeAgent: {
    type: 'object',
    settingName: 'change_agent_workflow_name',
    storedLocation: 'base-api',
  },
  afterSelectingAgent: {
    type: 'object',
    settingName: 'after_select_agent_workflow_name',
    storedLocation: 'base-api',
  },
  aiEscalation: {
    type: 'object',
    settingName: 'default',
    storedLocation: 'ai-manager',
  },

  // dispatch
  intentMatchingInDispatchMode: {
    type: 'bool',
    settingName: 'allow_intent_matching_in_dispatch_mode',
    storedLocation: 'ai-manager',
  },
  dispatchInitWorkflow: {
    type: 'object',
    settingName: 'dispatch_init_workflow_name',
    storedLocation: 'base-api',
  },
  dispatchChangeAgent: {
    type: 'object',
    settingName: 'dispatch_change_agent_workflow_name',
    storedLocation: 'base-api',
  },
  dispatchAfterSelectingAgent: {
    type: 'object',
    settingName: 'dispatch_after_select_agent_workflow_name',
    storedLocation: 'base-api',
  },
  dispatchWaitCustomerMessage: {
    type: 'object',
    settingName: 'dispatch_wait_customer_message_workflow',
    storedLocation: 'ai-manager',
  },
  dispatchFallback: {
    type: 'object',
    settingName: 'dispatch_fallback_workflow',
    storedLocation: 'ai-manager',
  },

  dispatchOnlyMode: {
    type: 'bool',
    settingName: 'dispatch_only_ai_mode',
    storedLocation: 'ai-manager',
  },

  // Note: One off setting that is not set by UI but replicated by dispatchOnlyMode
  // Take a look at mutation SET_DISPATCH_ONLY_MODE
  aiMode: {
    type: 'string',
    settingName: 'ai_mode',
    storedLocation: 'base-api',
  },

  dispatchModeConfig: {
    type: 'object',
    settingName: 'dispatch_mode_config',
    storedLocation: 'base-api',
  },
};

export default {
  namespaced: true,

  state: {
    aiManagerConfigurationId: null,
    form: {
      // common
      authenticatedCustomerCreation: null,

      dispatchOnlyMode: false,
      aiMode: null, // replica of dispatchOnlyMode but it needs to go to baseapi as well

      listDialogAskAlternative: false,
      listDialogAlternativePrompt: null,
      channelInitWorkflow: null,

      // non-dispatch
      initWorkflow: null,
      changeAgent: null,
      afterSelectingAgent: null,
      aiEscalation: null,

      // dispatch
      intentMatchingInDispatchMode: false,
      dispatchModeConfig: { bypass_workflow: { enabled: false } },
      dispatchInitWorkflow: null,
      dispatchChangeAgent: null,
      dispatchAfterSelectingAgent: null,
      dispatchWaitCustomerMessage: null,
      dispatchFallback: null,
    },
  },

  getters: {
    baseApiTriggerConfig: () => entries(triggerConfiguration)
      .reduce((recur, [key, value]) => {
        if (value.storedLocation === 'base-api') {
          recur[key] = value;
        }
        return recur;
      }, {}),

    baseApiConfigToSettingName: (state, getters) => {
      const map = {};
      for (const [name, value] of entries(getters.baseApiTriggerConfig)) {
        map[name] = value.settingName;
      }
      return map;
    },

    baseApiSettingNameToConfig: (state, getters) => {
      const map = {};
      for (const [name, value] of entries(getters.baseApiTriggerConfig)) {
        map[value.settingName] = name;
      }
      return map;
    },

    aiManagerTriggerConfig: () => entries(triggerConfiguration)
      .reduce((recur, [key, value]) => {
        if (value.storedLocation === 'ai-manager') {
          recur[key] = value;
        }
        return recur;
      }, {}),

    aiManagerConfigToSettingName: (state, getters) => {
      const map = {};
      for (const [name, value] of entries(getters.aiManagerTriggerConfig)) {
        map[name] = value.settingName;
      }
      return map;
    },
  },

  mutations: {
    SET_CONFIGURATION_ID(state, id) {
      state.aiManagerConfigurationId = id;
    },
    SET_DISPATCH_ONLY_MODE(state, dispatchOnlyMode) {
      state.form.dispatchOnlyMode = dispatchOnlyMode;
      state.form.aiMode = dispatchOnlyMode ? 'dispatch' : 'normal';
      log.debug('SET_DISPATCH_ONLY_MODE', state.form.dispatchOnlyMode, state.form.aiMode);
    },
    SET_TRIGGER(state, params) {
      state.form = { ...state.form, ...params };
    },
  },

  actions: {
    async load({ commit, state, getters, rootGetters }) {
      const form = {};

      const aiManagerRes = await axios.get(`${getUrl('aimanager.systemTriggers')}`);
      commit('SET_CONFIGURATION_ID', aiManagerRes.data.id);
      commit('SET_DISPATCH_ONLY_MODE', aiManagerRes.data.ending_scenarios.dispatch_only_ai_mode);
      for (const [name, value] of entries(getters.aiManagerConfigToSettingName)) {
        // for dispatchOnlyMode, it is already saved with SET_DISPATCH_ONLY_MODE
        if (name !== 'dispatchOnlyMode') {
          const objType = get(getters.aiManagerTriggerConfig, `${name}.type`, 'object');
          if (objType === 'object') {
            form[name] = get(aiManagerRes.data.ending_scenarios, `${value}.name`, null);
          } else {
            form[name] = get(aiManagerRes.data.ending_scenarios, `${value}`, null);
          }
        }
      }

      const settings = entries(getters.baseApiTriggerConfig).map(([_, value]) => value.settingName);
      const baseApiReses = (await Promise.all(
        settings.map(name => axios.get(`${getUrl('settings.base')}/${name}`)),
      )).map(res => res.data);

      settings.forEach((setting, index) => {
        if (getters.baseApiSettingNameToConfig[setting] === 'dispatchModeConfig') {
          // Typical setting loads
          form[getters.baseApiSettingNameToConfig[setting]] = baseApiReses[index];
        } else {
          form[getters.baseApiSettingNameToConfig[setting]] = get(baseApiReses[index], 'content');
        }
      });

      // field: { id: workflow_id }
      for (const [field, key] of entries(form)) {
        if (has(rootGetters['workflows/nameToIdMap'], key)) {
          form[field] = { id: rootGetters['workflows/nameToIdMap'][key] };
        }
      }

      commit('SET_TRIGGER', form);
      return state.form;
    },

    async update({ commit, state, getters, rootGetters }, params) {
      const updated = {};
      const onlyBaseApiUpdates = [];
      let shouldUpdateAiManager = false;
      let shouldUpdateBaseApi = false;

      // duplicate this to save in base api as well.
      if (!isEqual(params.dispatchOnlyMode, state.form.dispatchOnlyMode)) {
        params.aiMode = params.dispatchOnlyMode ? 'dispatch' : 'normal';
      }

      for (const [key, value] of entries(params)) {
        if (!isEqual(value, state.form[key])) {
          updated[key] = value;
          shouldUpdateAiManager = shouldUpdateAiManager || has(getters.aiManagerTriggerConfig, key);
          shouldUpdateBaseApi = shouldUpdateBaseApi || has(getters.baseApiTriggerConfig, key);

          if (has(getters.baseApiTriggerConfig, key)) {
            onlyBaseApiUpdates.push(key);
          }
        }
      }

      // update ai-manager if required
      // ai manager save settings as a chunk
      if (shouldUpdateAiManager) {
        const toBeUpdated = pick({ ...state.form, ...updated },
          keys(getters.aiManagerConfigToSettingName));

        log.debug('ai-manager update params', toBeUpdated);
        const endingScenarios = {};
        for (const [key, value] of entries(toBeUpdated)) {
          const objType = get(getters.aiManagerTriggerConfig, `${key}.type`, 'object');
          if (objType === 'object' && has(value, 'id')) {
            value.name = get(rootGetters['workflows/idToNameMap'], get(value, 'id'));
            value.unit_type = 'workflow';
          }
          endingScenarios[getters.aiManagerConfigToSettingName[key]] = value;
        }
        const AiEscalationAiManagerParams = {
          id: state.aiManagerConfigurationId,
          ending_scenarios: endingScenarios,
        };

        log.debug('ai-manager update', AiEscalationAiManagerParams);
        await axios.put(getUrl('aimanager.systemTriggers'), AiEscalationAiManagerParams);
      }

      // base-api save settings as independent settings
      if (shouldUpdateBaseApi) {
        log.debug('base-api update params', onlyBaseApiUpdates);
        log.debug('SETTINGS:', getters.baseApiConfigToSettingName);
        const pathAndPayloadPairs = onlyBaseApiUpdates.map(name => {
          // unique cases that is not workflow
          if (name === 'aiMode') {
            return [
              getters.baseApiConfigToSettingName[name],
              { content: get(updated, name) },
            ];
          }

          // a typical setting update.
          if (name === 'dispatchModeConfig') {
            return [
              getters.baseApiConfigToSettingName[name],
              get(updated, name),
            ];
          }

          return [
            getters.baseApiConfigToSettingName[name],
            { content: get(rootGetters['workflows/idToNameMap'], get(updated[name], 'id'), '') },
          ];
        });

        log.debug('base-api update', pathAndPayloadPairs);
        await Promise.all(
          pathAndPayloadPairs.map(([path, payload]) => axios.put(`${getUrl('settings.base')}/${path}`, payload)),
        );
      }

      commit('SET_TRIGGER', params);
      return params;
    },
  },
};
