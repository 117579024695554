import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

// Dashboard Localization
//
// Doc: https://agentiq.atlassian.net/wiki/spaces/ENGINEERIN/pages/256180228/Dashboard+Localization
// Ref: https://www.i18next.com/overview/getting-started
export const initialize = (Vue) => {
  const option = {
    debug: false,
    fallbackLng: 'en',
    ns: ['translation', 'configurable'],
    defaultNS: 'configurable', // always look for a string in configurable first
    fallbackNS: 'translation', // always fallback to default string
    supportedLngs: ['en'], // should be changed once we have more language support
    load: 'languageOnly',
    nonExplicitSupportedLngs: true,
    backend: {
      // lngs and namespaces will contains only one element in our case
      // as we dont use multiloading adapter.
      //
      // ref: https://github.com/i18next/i18next-http-backend
      loadPath: (lngs, namespaces) => {
        if (namespaces.includes('configurable')) {
          // Use remote domain for local development
          // Otherwise, it would search configurable.json from local device.
          // The file is stored in S3 under /public/dashboard-img/locales in asset bucket
          if (process.env.NODE_ENV === 'development') {
            const remoteDomain = `https://dashboard.${process.env.ENVIRONMENT}.agentiq.co`;
            return `${remoteDomain}/public/dashboard-img/locales/${lngs[0]}/${namespaces[0]}.json`;
          }
          return `/public/dashboard-img/locales/${lngs[0]}/${namespaces[0]}.json`;
        }
        return `/locales/${lngs[0]}/${namespaces[0]}.json`;
      },
    },
  };

  return i18next
    .use(LanguageDetector)
    .use(Backend)
    .init(option)
    .then(() => {
      Vue.use(I18NextVue, {
        i18next,
        rerenderOn: ['initialized', 'languageChanged', 'loaded'],
      });
      return i18next.resolvedLanguage;
    });
};
