const masterRoleKey = 'superadmin';
const uncategorizedKey = 'backend';

const uncategorizedCategory = {
  label: 'Backend(*superadmin)',
  index: Infinity,
  superadmin: true,
};

export {
  masterRoleKey,
  uncategorizedKey,
  uncategorizedCategory,
};
