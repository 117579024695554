import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import get from 'lodash/get';
import template from 'lodash/template';

// todo: to be used in loading config in store//
// const { config } = this.$store;

export const hosts = {
  aimanager: process.env.AIMANAGER_HOST,
  management: process.env.MANAGEMENT_HOST,
  channel: process.env.CHANNEL_HOST,
};

const templateGenerator = (endpoint, host = hosts.aimanager) => template(`${host}${endpoint}<% if(params) {%>/<%- params %><%}%>`);

const services = {
  aimanager: {
    actions: templateGenerator('actions'),
    endpoints: templateGenerator('endpoints'),
    entities: templateGenerator('entities'),
    entity_sources: templateGenerator('entity_sources'),
    intents: templateGenerator('intents'),
    dialogs: templateGenerator('dialogs'),
    tagger: templateGenerator('tagger'),
    operators: templateGenerator('operators'),
    workflows: templateGenerator('workflows'),
    dialogBuildingKit: templateGenerator('building-kit'),
    systemTriggers: templateGenerator('configurations'),
    webhookDomains: templateGenerator('webhook_domains'),
    apiRequests: templateGenerator('api_requests'),
    crms: templateGenerator('crms'),
    exports: templateGenerator('exports'),
    imports: templateGenerator('imports'),
  },
  formTrigger: templateGenerator('conversations', hosts.management),
  postChatSurveyTrigger: templateGenerator('commands/conversations.form.postchat', hosts.management),
  postSystemMessage: templateGenerator('commands/conversations.messages.system', hosts.management),
  teamsList: templateGenerator('commands/teams.list', hosts.management),
  management: {
    agents: templateGenerator('agents', hosts.management),
    assets: templateGenerator('assets', hosts.management),
    categories: templateGenerator('categories', hosts.management),
    customers: templateGenerator('customers', hosts.management),
    documents: templateGenerator('documents', hosts.management),
    documentSearch: templateGenerator('documents/search', hosts.management),
    tags: templateGenerator('tags', hosts.management),
    teams: templateGenerator('teams', hosts.management),
    integrations: templateGenerator('integrations', hosts.management),
    ingestion: templateGenerator('ingestion', hosts.management),
    compliance: templateGenerator('compliance', hosts.management),
    translation: templateGenerator('translation', hosts.management),
    tools: templateGenerator('tools', hosts.management),
    alertMessages: templateGenerator('notifications/alerts', hosts.management),
    quickResponses: templateGenerator('quickresponses', hosts.management),
  },
  metrics: templateGenerator('metrics', hosts.management),
  dashboard: templateGenerator('dashboard', hosts.management),
  bulk: templateGenerator('bulk', hosts.management),
  pulse: {
    instant: templateGenerator('realtime/instant', hosts.management),
    timeslice: templateGenerator('realtime/timeslice', hosts.management),
    agents: templateGenerator('agents', hosts.management),
  },
  conversations: {
    conversations: templateGenerator('conversations', hosts.management),
    customers: templateGenerator('customers', hosts.management),
  },
  settings: {
    access: templateGenerator('acl', hosts.management),
    integrations: templateGenerator('integration/keys', hosts.management),
    base: templateGenerator('settings', hosts.management),
    customerSegments: templateGenerator('customer_segments', hosts.management),
  },
  channel: hosts.channel,
  push: templateGenerator('proxy/push', hosts.management),
  files: {
    base: templateGenerator('files', hosts.management),
  },
};

export const getUrl = (alias, params = undefined) => {
  const urlTemplate = get(services, alias);
  if (isString(urlTemplate)) {
    return urlTemplate;
  }

  if (!isFunction(urlTemplate)) {
    throw new Error('Incorrect url alias');
  }
  return isFunction(urlTemplate) ? urlTemplate({ params }) : hosts.management;
};
