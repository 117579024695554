const ALLOWED_FILE_EXTENSIONS = {
  CSV: '.csv',
  TSV: '.tsv',
  XLS: '.xls',
  // PDF: '.pdf', TODO: In PDF format when too many columns are added the file gets corrupted
};

const DASHBOARD_FILE_ROUTE_PATH = `${window.location.origin}/files/view`;

export {
  ALLOWED_FILE_EXTENSIONS,
  DASHBOARD_FILE_ROUTE_PATH,
};
