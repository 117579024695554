<template lang="pug">
  layout-main(:sidebar-items="null" :show-sidebar="false" :hideHeader="hideHeader")
    .error-container
      h1.error-title You don’t have permission to access this page
</template>

<script>
import LayoutMain from '../../components/layouts/main.vue';

export default {
  components: {
    LayoutMain,
  },

  // Enable hiding the header containing the navbar
  // Currently used for the widget feature
  props: {
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fff;
}
</style>
