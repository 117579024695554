import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/messaging';
import * as log from 'loglevel';

import get from 'lodash/get';
import urlJoin from 'url-join';
import { getUrl } from '@/config/api.routes';
import { IPC_EVENT, validateMessage } from '@/libs/ipc';

import { Events } from '@/libs';

const TAG = '[PUSH STORE]:';

export default {
  namespaced: true,
  state: {
    service: 'firebase',
    initialized: false,
    config: {
      ...process.env.PUSH_NOTIFICATION_CONFIG.config,
    },
    vapidKey: process.env.PUSH_NOTIFICATION_CONFIG.vapidKey,
  },

  getters: {
    attachedFiles: state => state.attachedFiles,
  },

  mutations: {
    UPDATE_INIT(state, initialized) {
      state.initialized = initialized;
    },
  },

  actions: {
    async init({ commit, state, rootState, dispatch }) {
      const agentId = get(rootState, 'agent.profile.id');
      if (state.initialized || !agentId) {
        log.debug(`push store, init: ${state.initialized} agentId: ${agentId}`);
        return;
      }

      try {
        if (!state.config.apiKey || !state.config.projectId || !state.vapidKey) {
          // throw to fallback to legacy chrome notification.
          throw new Error(`Unable to use web push ${JSON.stringify(state)}`);
        }

        firebase.initializeApp(state.config);
        const messaging = firebase.messaging();
        try {
          await messaging.requestPermission();
          log.debug('Notification is enabled.');
        } catch (err) {
          log.warn('Notification is disabled.', err);
          // return here so that we dont go to fallback push.
          return;
        }

        const token = await messaging.getToken({ vapidKey: state.vapidKey });
        log.debug(`token: ${token}`);

        await axios.post(urlJoin(getUrl('push'), '/registration'), {
          device_key: token,
          id: agentId,
        });

        messaging.onMessage((payload) => {
          log.debug('Push notification on foreground', payload);
        });

        // Message from service worker.
        navigator.serviceWorker.addEventListener('message', (event) => {
          log.debug(TAG, 'message event:', event.data);

          // if there is no name in event.data, it is not from our service worker
          if (!get(event.data, 'aiqMessage', false)) {
            log.debug(TAG, 'not aiqMessage');
            return;
          }

          const { name, payload } = validateMessage(event.data);
          switch (name) {
            case IPC_EVENT.REDIRECT.name:
              Events.emit('select.conversation', [
                payload.conversation.id,
                payload.customer,
              ]);
              break;
            default:
              // nothing to do with unrecognized event
          }
        });
      } catch (err) {
        log.warn('Firebase bootstrapping failed.', err.message);
        // fallback to relying on socket notification
        await dispatch(
          'featureFlags/updateFlag',
          { name: 'USE_PUSH_NOTIFICATION', value: false },
          { root: true },
        );
      }

      commit('UPDATE_INIT', true);
    },
  },
};
