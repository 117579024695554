<template lang="pug">
.root
  .tutorial
    .imageWrapper
      .inner
        .hotspot(v-for="hotspot in hotspots" :class="{ [hotspot.class] : true }" :style="getHotspotStyle(hotspot)")
          .text.hidden {{ hotspot.class }}
        aiq-image(:src="tutorial")
  .skip
    aiq-button(@click="skipTutorial") Skip tutorial
</template>

<script>
import { mapState } from 'vuex';
import tutorial from '@/assets/tutorial.png';
import { loadUserGuiding } from '@/libs/userguiding';
import { AGENT_ONBOARDING } from '@/constants/analytics';

const hotspots = [
  { height: 30, width: 180, left: 2, top: 174, class: 'customer' },
  { height: 164, width: 224, left: 776, top: 118, class: 'knowledge_base' },
  { height: 66, width: 60, left: 194, top: 430, class: 'documents' },
  { height: 30, width: 30, left: 748, top: 393, class: 'suggestions' },
];


export default {
  data() {
    return {
      tutorial,
      hotspots,
    };
  },
  computed: {
    ...mapState({
      config: state => state.configs.config,
      agent: state => state.agent.profile,
    }),
  },
  mounted() {
    if (this.config && this.config.user_guiding) {
      const config = this.config.user_guiding;
      loadUserGuiding({ document, window, skipTutorial: this.skipTutorial, config });
    } else {
      this.skipTutorial();
    }
  },
  methods: {
    skipTutorial(event, error) {
      if (event && !error) {
        const payload = {
          agent: this.agent,
          userguiding: this.config.user_guiding,
        };
        if (event.completed) {
          this.$store.dispatch('notifications/emitEventToSocket', {
            name: AGENT_ONBOARDING.COMPLETED,
            payload,
          });
        } else {
          this.$store.dispatch('notifications/emitEventToSocket', {
            name: AGENT_ONBOARDING.SKIPPED,
            payload,
          });
        }
      }

      this.$store.dispatch('agent/skipTutorial');
    },
    getHotspotStyle(h) {
      return `
      height: ${h.height}px;
      width: ${h.width}px;
      top: ${h.top}px;
      left: ${h.left}px;
      z-index: 1000;
      `;
    },
  },
};
</script>

<style>
/* Remove hidden to display the hotspot text */
.hidden {
  display: none;
}

.tutorial {
  display: flex;
  width: 100%;
  height: 100%;
}

.hotspot {
  position: absolute;
}

.imageWrapper {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
}

.hotspot .text {
  position: absolute;
  bottom: -25px;
  text-align: center;
}

.inner {
  position: relative;
  height: 534px;
  width: 1000px;
}

.tutorial img {
  height: 534px;
  width: 1000px;
}

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.skip {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 4em;
}
</style>
