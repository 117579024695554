<template lang="pug">
  .entity-select(v-click-away="closeEntityPopup")
    .entity-select_header
        h4.entity-select_title Entities
        aiq-button.button-clear(size="small" @click="removeAnnotation") Clear

    .entity-select_content
      .entity-select_item(v-for="entity in entitySelectionList"
                          @click="annotateEntity"
                          :key="entity.name")
        |{{entity.name}}

</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';

const { mapState } = createNamespacedHelpers('intents');
const { mapMutations } = createNamespacedHelpers('intents/formIntent');

export default {
  name: 'intents-entity-box',
  props: {
    alias: String,
    entitySelectionList: {
      type: Array,
      default() {
        return [];
      },
    },
    taggedEntityIndex: Number,
    taggerInfo: {
      type: Object,
      required: true,
    },
    word: {
      type: String,
      required: true,
    },
  },
  emits: ['closeEntityConfig'],
  data() {
    return {
      aliasWordEndIndex: this.taggerInfo.end_index,
      entityName: '',
    };
  },
  computed: {
    ...mapState([
      'formIntent',
    ]),
  },
  methods: {
    ...mapMutations([
      'UPDATE_EXPRESSION',
      'UPDATE_TAGGED_ENTITIES',
    ]),
    annotateEntity(e) {
      if (this.word !== this.alias) {
        this.updateExpression();
      }

      const taggerInfo = cloneDeep(this.taggerInfo);
      const name = e.target.textContent.trim();

      taggerInfo.name = name;
      taggerInfo.end_index = this.aliasWordEndIndex;

      if (this.word !== this.alias) {
        taggerInfo.resolved_value = this.alias;
      } else {
        taggerInfo.resolved_value = this.word;
      }

      const taggedEntities = cloneDeep(this.formIntent.payload.tagged_entities);

      if (this.taggedEntityIndex) {
        taggedEntities.splice(this.taggedEntityIndex, 1);
      }

      taggedEntities.push(taggerInfo);

      this.closeEntityPopup();
      return this.UPDATE_TAGGED_ENTITIES(taggedEntities);
    },
    closeEntityPopup(e) {
      this.$emit('closeEntityConfig', e && e.target);
    },
    removeAnnotation() {
      if (this.taggedEntityIndex >= 0) {
        const taggedEntities = this.formIntent.payload.tagged_entities;
        taggedEntities.splice(this.taggedEntityIndex, 1);

        this.closeEntityPopup();
        return this.UPDATE_TAGGED_ENTITIES(taggedEntities);
      }
      this.closeEntityPopup();
    },
    updateExpression() {
      const taggedEntities = clone(this.formIntent.payload.tagged_entities);
      const wordStartIndex = this.taggerInfo.start_index;
      const wordEndIndex = this.taggerInfo.end_index;
      const wordLength = wordEndIndex - this.taggerInfo.start_index;
      const differenceInWordAndAliasLength = wordLength - this.alias.length;
      const thisExpressionIndex = this.taggerInfo.expression_index;

      this.aliasWordEndIndex = wordStartIndex + this.alias.length;

      taggedEntities.forEach(taggedEntity => {
        if (taggedEntity.expression_id === thisExpressionIndex
            && taggedEntity.start_index > wordStartIndex) {
          taggedEntity.start_index -= differenceInWordAndAliasLength;
          taggedEntity.end_index -= differenceInWordAndAliasLength;
        }
      });

      this.formIntent.expressions.forEach((expression, index) => {
        if (index === thisExpressionIndex) {
          const beginning = expression.template.substring(0, wordStartIndex);
          const ending = expression.template.substring(wordEndIndex);
          const updatedTemplate = beginning + this.alias + ending;

          const updatedExpression = {
            expressionIndex: thisExpressionIndex,
            template: updatedTemplate,
          };
          this.UPDATE_EXPRESSION(updatedExpression);
        }
      });
    },
  },
  watch: {
    entityName() {
      this.$emit('closeEntityConfig');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/aiq-variables.scss";

.entity-select {
  position: absolute;
  bottom: 22px;
  left: 50%;
  width: 180px;
  padding: 0 18px;
  margin-left: -90px;
  border: 1px solid #BFCBD9;
  border-radius: 2px;
  background-color: #fff;
  z-index: 10;
}
.entity-select_header {
  display: flex;
  align-items: center;
  height: 30px;
  box-shadow: inset 0 -1px 0 0 #ECF2FB;
}
.entity-select_title {
  color: #4470B5;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}
.entity-select_content {
  min-height: 30px;
}
.entity-select_item {
  display: flex;
  align-items: center;
  min-height: 30px;
  padding: 0 18px;
  margin: 0 -18px;
  color: #324057;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;

  &:hover {
    background-color: #ECF2FB;
  }
}
.button-clear,
.button-close {
  padding-top: 1px;
  padding-bottom: 1px;
  color: #8492A6;
  font-size: 11px;
  line-height: 13px;
  font-weight: 300;

  &:focus {
    border-color: #c4c4c4;
  }
  &:hover {
    color: #5993FF;
    border-color: #20a0ff;
  }
  &:active {
    color: #4C7DD9;
  }
}
.button-clear {
  margin-left: auto;
}
</style>
