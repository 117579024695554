import axios from 'axios';
import get from 'lodash/get';
import { getUrl } from '@/config/api.routes';
import urlJoin from 'url-join';

export default {
  namespaced: true,
  state: {
    crm: {
      salesforce: {
        connected: false,
        batch_enabled: false,
        realtime_enabled: false,
        name: '',
        config: {
          oauth2: {
            clientId: '',
            clientSecret: '',
            redirectUri: '',
          },
        },
      },
    },
    aiqObjects: {
      models: [],
    },
    crmObjects: {
      models: [],
    },
    objectMappings: {
      models: [],
    },
  },
  getters: {
  },
  mutations: {
    SET_CRM(state, config) {
      if (!config.name) {
        throw new Error('crm config does not contain name!');
      }

      state.crm[config.name] = config;
    },

    SET_AIQ_OBJECTS(state, objects) {
      state.aiqObjects = objects;
    },

    SET_CRM_OBJECTS(state, objects) {
      state.crmObjects = objects;
    },

    SET_OBJECT_MAPPINGS(state, objects) {
      state.objectMappings = objects;
    },

    ADD_OBJECT_MAPPING(state, object) {
      state.objectMappings.models.push(object);
      state.objectMappings = { ...state.objectMappings };
    },

    UPDATE_OBJECT_MAPPING(state, object) {
      const index = state.objectMappings.models
        .findIndex(mapping => mapping.id === object.id);
      if (index >= 0) {
        state.objectMappings.models[index] = object;
      }
      state.objectMappings = { ...state.objectMappings };
    },

    DELETE_OBJECT_MAPPING(state, object) {
      state.objectMappings.models = state.objectMappings.models
        .filter(mapping => mapping.id !== object.id);
      state.objectMappings = { ...state.objectMappings };
    },
  },
  actions: {
    async loadCrm({ commit }, name) {
      const res = await axios.get(`${getUrl('aimanager.crms')}/${name}`);
      commit('SET_CRM', get(res, 'data'));
      return get(res, 'data');
    },
    async saveCrm({ commit }, { name, payload }) {
      const res = await axios.put(`${getUrl('aimanager.crms')}/${name}`, payload);
      commit('SET_CRM', get(res, 'data'));
      return get(res, 'data');
    },
    async loadAiqObjects({ commit }, name) {
      const res = await axios.get(`${getUrl('aimanager.crms')}/${name}/aiq_objects`);
      commit('SET_AIQ_OBJECTS', get(res, 'data'));
      return get(res, 'data');
    },
    async loadCrmObjects({ commit }, name) {
      const res = await axios.get(`${getUrl('aimanager.crms')}/${name}/crm_objects`);
      commit('SET_CRM_OBJECTS', get(res, 'data'));
      return get(res, 'data');
    },
    async loadObjectMappings({ commit }, name) {
      const res = await axios.get(`${getUrl('aimanager.crms')}/${name}/mappings`);
      commit('SET_OBJECT_MAPPINGS', get(res, 'data'));
      return get(res, 'data');
    },
    async updateObjectMapping({ commit }, { name, mapping }) {
      const res = await axios.put(`${getUrl('aimanager.crms')}/${name}/mappings/${mapping.id}`, mapping);
      commit('UPDATE_OBJECT_MAPPING', get(res, 'data'));
      return get(res, 'data');
    },
    async createObjectMapping({ commit }, { name, mapping }) {
      const res = await axios.post(`${getUrl('aimanager.crms')}/${name}/mappings`, mapping);
      commit('ADD_OBJECT_MAPPING', get(res, 'data'));
      return get(res, 'data');
    },
    async deleteObjectMapping({ commit }, { name, mapping }) {
      await axios.delete(`${getUrl('aimanager.crms')}/${name}/mappings/${mapping.id}`);
      commit('DELETE_OBJECT_MAPPING', mapping);
    },
    async authorizeOAuth2Code(_, { name, code }) {
      return axios.post(`${getUrl('aimanager.crms')}/${name}/authorize`, { code });
    },
    async tokenExchange(_, { name, query }) {
      return axios.get(urlJoin(getUrl('channel'), `/docsign/${name}/authorize`), { params: query });
    },
    async getIntegration(_, { name }) {
      if (!name) {
        throw new Error(`getIntegration needs name to fetch but it got ${name}`);
      }

      return (await axios.get(`${getUrl('management.integrations')}/${name}/value`)).data;
    },
    async updateIntegration(_, { name, payload }) {
      if (!name || !payload) {
        throw new Error(`updateIntegration needs name(${name}) & payload(${payload})`);
      }
      return (await axios.put(`${getUrl('management.integrations')}/${name}/value`, payload)).data;
    },

    async checkDocumentIntegrationConnection(_, integrationName) {
      try {
        // This would return userInfo if token is valid
        await axios.get(urlJoin(getUrl('channel'), `/docsign/${integrationName}/connected`));
        return true;
      } catch (err) {
        return false;
      }
    },

    async getTemplates(_, integrationName) {
      return (await axios.get(urlJoin(getUrl('channel'), `/docsign/${integrationName}/templates`))).data;
    },

    async getTemplateDetails(_, { integrationName, templateId }) {
      return (await axios.get(urlJoin(getUrl('channel'), `/docsign/${integrationName}/templates/${templateId}`))).data;
    },

    async sendTemplate(_, { integrationName, template, recipients, emailSubject, emailBlurb }) {
      return (await axios.post(
        urlJoin(getUrl('channel'), `/docsign/${integrationName}/templates/send`),
        { template, recipients, emailSubject, emailBlurb },
      )).data;
    },

    async getEnvelope(_, { integrationName, envelopeId }) {
      return (await axios.get(urlJoin(getUrl('channel'), `/docsign/${integrationName}/envelopes/${envelopeId}`))).data;
    },

    async getEnvelopes(_, { integrationName, query }) {
      return (await axios.get(urlJoin(getUrl('channel'), `/docsign/${integrationName}/envelopes`), { params: query })).data;
    },

    async getSignUrl(_, { integrationName, envelopeId, payload }) {
      return (await axios.post(
        urlJoin(getUrl('channel'), `/docsign/${integrationName}/envelopes/${envelopeId}/signurl`),
        payload,
      )).data;
    },

    async updateEnvelopeRecipientsAndSend(_, { integrationName, envelopeId, recipients }) {
      return (await axios.put(
        urlJoin(getUrl('channel'), `/docsign/${integrationName}/envelopes/${envelopeId}/recipients`),
        { recipients },
      )).data;
    },
  },
};
