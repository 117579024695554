import { Component, Vue, toNative } from 'vue-facing-decorator';
import { createNamespacedHelpers, mapActions, mapState } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import { dialogConstants } from '@/constants';
import actionItem from '../Partials/ActionItem/ActionItem.vue';
import buildingKitAiTesting from '../Partials/BuildingKitAiTesting/BuildingKitAiTesting.vue';
import middlePaneHeader from '../Partials/MiddlePaneHeader/MiddlePaneHeader.vue';
import EditableInputRow from './EditableInputRow.vue';
import ActionContainer from '../Partials/ActionContainer/ActionContainer.vue';
import Priority from '@/pages/Settings/Agents/children/Customer/Segments/Priority.vue';
import { arePairsValid } from '@/libs/dialogHelpers';
import { SelectPanel, EntityDialog } from '@/components';
import {
  EntitySuggestionPopupsMixin,
  iqtoolsErrorHandler,
  makeDependencyAlertMsg,
  removeActionIfExist,
  removeDuplicateNameAction,
  confirmUnsavedWarning,
} from '@/libs';

const {
  DIALOG_TYPES, DIALOG_TYPE_NAMES, MODELS, MODES,
} = dialogConstants;
const { mapGetters } = createNamespacedHelpers('entities');
const { mapMutations } = createNamespacedHelpers('dialogs');
const LIMIT = 30;

@Component({
  name: 'dialogs',
  mixins: [EntitySuggestionPopupsMixin],
  components: {
    actionItem,
    buildingKitAiTesting,
    EditableInputRow,
    EntityDialog,
    // todo - set up and enable
    // ButtonGroupDialog,
    SelectPanel,
    ActionContainer,
    middlePaneHeader,
    Priority,
  },
  computed: {
    ...mapGetters({
      entitySelectionList: 'selectionList',
    }),
    ...mapState({
      loaded: state => state.dialogs.dialogsLoaded,
      selectedSavedDialog: state => state.dialogs.selected,
    }),

    entityListForConditionalAction() {
      const entities = get(this, 'selected.payload.rest_type.responsePairs', []);
      return entities.map(pair => pair[0]);
    },
    usesConditionalActions() {
      const { dialogType } = this;
      const payload = get(this, 'selected.payload', {});

      return this.conditionalDialogsActiveFlag && payload[dialogType].use_conditional_actions;
    },
    fixedEntity() {
      return get(this, 'selected.payload.entity', '');
    },
    dialogType() {
      return get(this, 'selected.payload.dialog_type', '');
    },
    validity() {
      const params = this.selected.payload[this.dialogType];
      let msg;

      switch (this.dialogType) {
        case DIALOG_TYPES.TEXT:
          if (!this.selected.payload.entity) {
            msg = 'Please make sure an entity is specified.';
            return {
              result: false,
              msg,
            };
          }
          break;
        case DIALOG_TYPES.REST:
          if (!params.endpoint_name) {
            msg = 'Please make sure an endpoint is specified.';
  
            return {
              result: false,
              msg,
            };
          }

          if (params.mode === MODES.ENTITY) {
            if (!(arePairsValid(params.requestPairs) &&
              arePairsValid(params.responsePairs))) {
              msg = 'Please make sure request and responses are specified.';
  
              return {
                result: false,
                msg,
              };
            }
          }
          break;
        default:
          return { result: true };
      }

      return { result: true };
    },
    items() {
      return this.$store.getters['dialogs/list'];
    },
    middleColumnsClassObj() {
      return {
        pristine: this.pristine,
      };
    },
    pristine() {
      const isPristine = isEqual(this.selected, this.selectedSavedDialog);
      // todo - don't call mutation in a getter
      this.SET_IS_SELECTED_PRISTINE(isPristine);
      return isPristine;
    },
    showAlternativeAsk() {
      return get(this.selected, 'payload.text_type.alternative_ask');
    },
    showAlternatePromptBox() {
      /**
       * Need to check for the selected because of the watcher on it that will
       * execute before this.selected is defined
       */
      if (get(this, 'selected.payload.text_type')) {
        const textType = this.selected.payload.text_type;
        return textType.confirmation && textType.always_ask;
      }

      return false;
    },
    showSuccessFailureActions() {
      const { dialogTypeName, dialogTypes } = this;

      if (dialogTypeName === dialogTypes.TEXT ||
        dialogTypeName === dialogTypes.ENTITY) {
        return true;
      }

      return false;
    },
    successActionTitle() {
      return this.usesConditionalActions ? 'Conditional Success Actions' : 'Success Actions';
    },
    useUniversalInputType() {
      return this.dialogType === DIALOG_TYPES.TEXT;
    },
  },
  watch: {
    inputText(newValue) {
      this.selected.payload.text_type.confirmation_prompt = newValue;
    },
    'selected.payload.entity': {
      handler(entity) {
        if (entity) {
          this.insertEntitiesIntoTextTypeActions();
        }
      },
    },
    showAlternatePromptBox(newValue, oldValue) {
      this.clearReplacePromptSetting(newValue, oldValue);
    },
    '$route': {
      handler(to) {
        this.onRouteChanged(to);
      },
    },
  },
  methods: {
    ...mapActions({
      getAPISettings: 'settings/getAPISettings',
      getEntityOperators: 'dialogs/getEntityOperators',
    }),
    ...mapMutations([
      'SET_IS_SELECTED_PRISTINE',
    ]),
  },
})

// Locate state level changes and dispatch
class Dialogs extends Vue {
  // left panel state
  search = '';
  limit = LIMIT;
  offset = 0;

  // temp dialog storage
  selectedIndex = -1;
  selected = null;
  isSelectedItemModified = false;
  dialogTypeName = '';
  newButtonName = '';
  newDialog = '';
  identifier = 0;

  // dialog name edit flag
  showNewButtonInput = true;
  buttonIndex = -1;

  conditionalOperators = {};
  conditionalDialogsActiveFlag = process.env.FEATURE_FLAGS.CONDITIONAL_DIALOGS;

  dialogTypeNames = Object.values(DIALOG_TYPE_NAMES);
  dialogTypes = DIALOG_TYPE_NAMES;

  clearReplacePromptSetting(newValue, oldValue) {
    if (this.selected.payload.text_type) {
      if (!newValue && oldValue) {
        this.selected.payload.text_type.confirmation = false;
        this.inputText = '';
      } else {
        this.$nextTick(() => {
          this.$refs.spInputBox.focus();
        });
      }
    }
  }

  onRouteChanged(to) {
    this.resetPage();
    const dialogName = get(to, 'hash', '').replace('#', '');
    if (dialogName) {
      this.searchDialogs(dialogName).then(() => {
        this.selectDialog(0);
      });
    } else {
      this.loadDialogs().then(() => {
        const id = parseInt(this.$route.params.id, 10);
        const itemIndex = !id ? 0 : this.items.findIndex(item => item.id === id);
        this.selectDialog(itemIndex);
      });
    }
  }
  resetPage() {
    this.limit = LIMIT;
    this.offset = 0;
    this.selectedIndex = 0;
  }

  /*
   * Hook when dialog type is changed from UI.
   */
  onDialogTypeChanged(value) {
    this.selected.payload = MODELS[value] ? cloneDeep(MODELS[value]) :
      cloneDeep(MODELS(this.dialogTypes.TEXT));
  }

  /*
   * Triggered when this.selected.payload.list_type.response_values changed
   */
  onAddButton() {
    this.showNewButtonInput = true;
  }

  /*
   * Triggered when this.selected.payload.list_type.response_values changed
   */
  onRemoveButton(index) {
    const list = this.selected.payload.list_type.response_values;

    // active button list handling
    if (list.length === 1) {
      this.buttonIndex = -1;
    } else if (index <= this.buttonIndex && index !== 0) {
      this.buttonIndex -= 1;
    }

    this.selected.payload.list_type.response_values.splice(index, 1);
  }

  /*
   * Triggered when this.selected.payload.list_type.response_values changed
   */
  onButtonNameEdited(item) {
    const { content, index } = item;
    this.selected.payload.list_type.response_values[index].value = content;
  }

  /*
   * Triggered when the order icon clicked
   */
  onButtonOrder(index, isUp) {
    const list = cloneDeep(this.selected.payload.list_type.response_values);
    const insertIndex = isUp ? index - 1 : index + 1;
    const item = list[index];
    list[index] = list[insertIndex];
    list[insertIndex] = item;
    this.selected.payload.list_type.response_values = list;
  }

  /*
   * Triggered when cancel button is clicked
   */
  onEditCancel() {
    this.selectDialog(this.selectedIndex, true);
  }

  /*
   * Triggered when save button is clicked.
   */
  onEditSave() {
    if (!this.pristine) {
      // TODO(Gabe) Refactor using vee-validate

      return Promise.resolve(this.checkValidity())
        .then(this.preparePayload())
        .then(this.clean())
        .then(() => {
          this.$store.dispatch('dialogs/updateDialog', this.selected)
            .then(() => {
              this.selected = cloneDeep(this.items[this.selectedIndex]);
              this.$aiq.notify.success(`${this.selected.name} has been saved.`);
            });
        });
    }
  }

  checkValidity() {
    if (!this.validity.result) {
      const generalMsg = 'Problem saving dialog.';
      const msg = this.validity.msg || '';
      const combined = `${generalMsg} ${msg}`;
      this.$aiq.notify.error(combined);
      throw new Error(combined);
    }
  }

  preparePayload() {
    switch (this.dialogType) {
      case DIALOG_TYPES.TEXT:
        this.insertEntitiesIntoTextTypeActions();

        break;
      default:
    }
  }

  // Set the entity for conditional actions for text type dialogs
  insertEntitiesIntoTextTypeActions() {
    const { entity } = this.selected.payload;
    const conditionalActions = get(this, 'selected.payload.text_type.conditional_actions', []);

    conditionalActions.forEach(({ statements }) => {
      statements.forEach(statement => {
        statement.operand_entity = entity;
      });
    });
  }

  clean() {
    if (this.usesConditionalActions) {
      const cActions = this.selected.payload[this.dialogType].conditional_actions || [];
      cActions.forEach(cActionsItem => {
        const validStatements = cActionsItem.statements.filter(statement => statement.operator
            && statement.value
            && statement.input_type
            && statement.operand_entity);

        cActionsItem.statements = validStatements;
      });
    }
  }

  /*
   * Vue hook when this component is mounted
   */
  mounted() {
    this.getAPISettings();

    this.$store.dispatch('entities/getEntitiesList', [
      { q: '', limit: 1000, offset: 0 },
      true,
    ]);

    this.getEntityOperators();
    return this.onRouteChanged(this.$route);
  }

  /*
   * Vue hook just before drawing.
   *
   * Set the first dialog if it has a list.
   */
  createDialog(name) {
    this.$store.dispatch('dialogs/createDialog', [{
      name,
    }, 'data'])
      .then(() => {
        this.$aiq.notify.success(
          `${name} has been added to dialogs.`,
        );

        const index = this.items.findIndex(dialog => dialog.name === name);

        this.selectDialog(index);
      }, err => {
        iqtoolsErrorHandler(this, err, 'dialog');
      });
  }

  deleteDialog(id) {
    const { name } = this.items.find(e => e.id === id);
    this.$aiq.confirm(
      'Delete Dialog',
      `Are you sure you want to delete <b>${name}</b>?`,
    ).then(
      () => {
        this.$store.dispatch('dialogs/deleteDialog', id)
          .then(() => {
            this.$aiq.notify.success(`${name} has been deleted from dialogs.`);
            if (id === this.selected.id) {
              this.selectDialog(this.selectedIndex, true);
            }
          })
          .catch(res => {
            const { title, content } = makeDependencyAlertMsg('Dialog', res.response, {$t: this.$t});
            this.$aiq.confirm(title, content);
          });
      },
      () => {},
    );
  }

  loadDialogs(searchTerms) {
    return this.$store.dispatch('dialogs/getDialogsList',
      [
        { q: searchTerms, limit: this.limit, offset: this.offset },
        this.offset === 0,
      ])
      .then(data => {
        this.$store.dispatch('dialogs/markDialogsAsLoaded', data.data.pagination.rowCount < (this.offset + this.limit));
        this.offset += this.limit;
        return data;
      });
  }

  // TODO (Gabe) - create mixin for search and scroll load
  onScrollLoad($state, searchTerms) {
    // this.loaded refers to all data loaded
    if (!this.loaded) {
      this.loadDialogs(searchTerms)
        .then(data => {
          if (this.selectedIndex === -1 && this.items.length > 0) {
            this.selectDialog(0);
          }
          const dataItems = get(data, 'data.data', []);
          if (!dataItems[0] || this.loaded) {
            $state.complete();
          } else {
            $state.loaded();
          }
        });
    } else {
      $state.complete();
    }
  }

  saveDialogName(newName) {
    if (this.selected.name === newName) {
      return;
    }

    const dialog = cloneDeep(this.selected);
    dialog.name = newName;

    this.$store.dispatch('dialogs/updateDialog', dialog)
      .then(data => this.selectDialog(this.selectedIndex)
        .then(() => {
          this.$aiq.notify.success(`${get(data, 'data.name', newName)} has been saved.`);
          return data;
        })).catch(err => iqtoolsErrorHandler(this, err, 'dialog'));
  }

  /**
   * Event hook when copy icon is clicked.
   */
  onCopyDialog() {
    this.$store.dispatch('dialogs/copyDialog', this.selected)
      .then(created => {
        this.$aiq.notify.success(
          `${created.name} has been added to dialogs.`,
        );

        const index = this.items.findIndex(dialog => dialog.name === created.name);

        this.selectDialog(index);
      }, err => {
        iqtoolsErrorHandler(this, err, 'dialog');
      });
  }

  saveNewButton() {
    if (!this.newButtonName) return;

    const buttons = this.selected.payload.list_type.response_values;
    const alreadyExists = buttons.findIndex(button => button.value === this.newButtonName) >= 0;

    if (alreadyExists) {
      this.$aiq.notify.error('Button name already exists. Please choose another name.');
    } else {
      this.selected.payload.list_type.response_values.push({
        value: this.newButtonName,
        actions: [],
      });

      if (this.buttonIndex === -1) {
        this.buttonIndex = 0;
      }

      this.newButtonName = '';
      this.showNewButtonInput = false;
    }
  }

  searchDialogs(searchTerms) {
    this.search = searchTerms;
    this.identifier ++;
    this.$store.dispatch('dialogs/markDialogsAsLoaded', false);
    this.offset = 0;
    return this.loadDialogs(searchTerms);
  }

  selectDialogById(id) {
    const index = this.items.findIndex(dialog => dialog.id === id);
    this.selectDialog(index);
  }

  // TODO (Gabe) - Remove and use selectDialogById
  selectDialog(index, disableSaveWarning) {
    if (!this.pristine && this.selected && this.selectedSavedDialog && !disableSaveWarning) {
      const { title, content } = confirmUnsavedWarning;
      return this.$aiq.confirm(title, content)
        .then(() => this._selectDialog(index))
        .catch(() => {});
    }
    return this._selectDialog(index);
  }

  _selectDialog(index) {
    if (!this.items.length) {
      this.selectedIndex = -1;
      this.selected = null;
      return;
    }

    const routedId = parseInt(this.$route.params.id, 10);
    let selectedItem;
    if (index < 0) {
      this.selectedIndex = 0;
      if (routedId) {
        selectedItem = { id: routedId };
      } else {
        this.selectedIndex = 0;
        selectedItem = this.items[this.selectedIndex];
      }
    } else if (index >= this.items.length) {
      this.selectedIndex = this.items.length - 1;
      selectedItem = this.items[this.selectedIndex];
    } else {
      this.selectedIndex = index;
      selectedItem = this.items[this.selectedIndex];
    }

    this.buttonIndex = -1;
    return this.$store.dispatch('dialogs/selectDialog', selectedItem.id)
      .then(() => {
        if (routedId) {
          this.selectedIndex = this.items.findIndex(item => item.id === routedId);
        }
        this.selected = cloneDeep(this.items[this.selectedIndex]);

        let subType;
        // TODO (Gabe) Use fancy regex and uppercasing
        switch (this.dialogType) {
          case DIALOG_TYPES.TEXT:
            this.inputText = get(this, 'selected.payload.text_type.confirmation_prompt', '');
            this.dialogTypeName = DIALOG_TYPE_NAMES.TEXT;
            break;
          case DIALOG_TYPES.BUTTON:
            if (this.buttonIndex === -1
              && this.selected.payload.list_type.response_values.length > 0) {
              this.buttonIndex = 0;
              this.dialogTypeName = DIALOG_TYPE_NAMES.BUTTON;
            }
            this.dialogTypeName = DIALOG_TYPE_NAMES.BUTTON;
            break;
          case DIALOG_TYPES.REST:
            subType = this.selected.payload.rest_type.mode;

            if (subType === MODES.ENTITY) {
              this.dialogTypeName = DIALOG_TYPE_NAMES.ENTITY;
            } else if (subType === MODES.BUTTON) {
              // Todo
              this.dialogTypeName = DIALOG_TYPE_NAMES.BUTTON_GROUP;
            } else {
              throw new Error('no API-based subtype matched');
            }
            break;
          // This condition will be phased out soon
          case 'condition_type':
            this.dialogTypeName = 'Condition';
            break;
          default:
        }
      },
      () => {
        this.$aiq.notify.error('Dialog not found');
      });
  }

  onButtonIndexSelected(e) {
    this.buttonIndex = parseInt(e.currentTarget.getAttribute('index'), 10);
  }

  showButton(index) {
    return this.buttonIndex === index;
  }

  onDeleteFailureAction(idx) {
    const actions = this.selected.payload[this.dialogType].failure_actions;
    removeActionIfExist(actions, idx);
  }

  onDeleteAction(idx) {
    const { actions } = this.selected.payload.list_type.response_values[this.buttonIndex];
    removeActionIfExist(actions, idx);
  }

  onAddActions(event, actions) {
    removeDuplicateNameAction(
      actions,
      event.newIndex,
      this.selected,
    );
  }

  onAddFailureActions(event) {
    this.onAddActions(event, this.selected.payload[this.dialogType].failure_actions);
  }

  onAddListTypeActions(event) {
    this.onAddActions(event,
      this.selected.payload.list_type.response_values[this.buttonIndex].actions);
  }

  onActionItemClick(item) {
    this.$store.dispatch('routing/routeIqtoolActionItem', item);
  }
}

export default toNative(Dialogs);
