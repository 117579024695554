import COLORS from './colors';

const customerByMessagesChartConfig = {
  name: 'customers_by_messages',
  title: 'Message Frequency',
  metric: 'customers_by_messages',
  linesColor: COLORS.orange,
};

export default customerByMessagesChartConfig;
