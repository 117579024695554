<template lang="pug">
  .suggest-select.scroll-small(v-if="shouldShow"
                                  v-click-away="onClickOutside"
                                  :style="styleObj")
    .suggest-select_content(v-if="items[0]")
      .suggest-select_item(v-for="item in items"
                          @click="onClick"
                          :key="item.name")
        |@{{item.name}}
    .suggest-select_content(v-else)
      .suggest-select_item {{ noneFoundMessage }}
</template>

<script>

export default {
  name: 'suggestion-popup',
  props: {
    shouldShow: Boolean,
    onClickOutside: Function,
    styleObj: {
      type: Object,
      default() {
        return {
          top: 0,
          left: 0,
        };
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    noneFoundMessage: {
      type: String,
      default: 'No Matches Found',
    },
    onClick: Function,
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/aiq-variables.scss";
.suggest-select {
  position: absolute;
  width: $aiq-width-suggestion-popup;
  max-height: 188px;
  margin-top: 2px;
  border: 1px solid #BFCBD9;
  border-radius: 2px;
  background-color: #fff;
  z-index: 10;
}
.suggest-select_content {
  min-height: 30px;
  padding: 4px 0;
}
.suggest-select_item {
  height: 30px;
  padding: 0 18px;
  color: #324057;
  font-size: 12px;
  line-height: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    background-color: #ECF2FB;
  }
}
</style>
