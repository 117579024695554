import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import uniqBy from 'lodash/uniqBy';
import axios from 'axios';
import { CRUDStoreBuilder } from '../StoreBuilder';
import { getUrl } from '@/config/api.routes';
import { makeActionsToUnits, fillEmptyAction } from '@/libs';

const workflowCrud = new CRUDStoreBuilder('aimanager.workflows', 'workflow', [], 'data.data').createStore();

const deleteWorkflow = workflowCrud.mutations.DELETE_WORKFLOW_SUCCESS;
workflowCrud.mutations.DELETE_WORKFLOW_SUCCESS = (state, id) => {
  deleteWorkflow(state, id);
  state.selected = state.workflows[0] || null;
};

// Remove Selected global state
const workflows = {
  state: {
    selected: null,
  },

  getters: {
    selected: state => state.selected,
    workflowNames: state => state.workflows.map(workflow => workflow.name),
    nameToIdMap: (state) => {
      if (!state.workflows) {
        return {};
      }

      return state.workflows.reduce((recur, item) => {
        recur[item.name] = item.id;
        return recur;
      }, {});
    },
    idToNameMap: (state) => {
      if (!state.workflows) {
        return {};
      }

      return state.workflows.reduce((recur, item) => {
        recur[item.id] = item.name;
        return recur;
      }, {});
    },
  },

  mutations: {
    SELECT_WORKFLOW(state, item) {
      fillEmptyAction(state.workflows, item);
      state.workflows = uniqBy([...state.workflows, item], 'id');
      state.selected = item;
    },
  },

  actions: {
    updateWorkflow({ commit }, { payload, force = false }) {
      const workflow = cloneDeep(payload);
      makeActionsToUnits(workflow.payload.states);
      makeActionsToUnits(workflow.payload.success_actions);
      makeActionsToUnits(workflow.payload.failure_actions);
      makeActionsToUnits(workflow.payload.conditional_else_states);

      workflow.payload.conditional_states.forEach(condition => {
        makeActionsToUnits(condition.actions);
      });

      const params = {
        entity_check: (!force).toString(),
      };

      return axios.put(getUrl('aimanager.workflows', workflow.id), workflow, { params }).then(data => {
        commit('PUT_WORKFLOW_SUCCESS', data.data);
        return data.data;
      });
    },

    createWorkflow({ commit }, workflow) {
      return axios.post(getUrl('aimanager.workflows'), workflow).then(data => {
        commit('POST_WORKFLOW_SUCCESS', data.data);
        return data;
      });
    },

    applyChanges({ commit }) {
      commit('APPLY_CHANGES');
    },

    selectWorkflow({ commit }, workflow) {
      return axios.get(getUrl('aimanager.workflows', workflow.id)).then(data => {
        commit('SELECT_WORKFLOW', data.data);
        return data;
      });
    },
    copyWorkflow({ commit }, originalWorkflow) {
      return axios.post(getUrl('aimanager.workflows', `${originalWorkflow.id}/copy`))
        .then(data => {
          commit('POST_WORKFLOW_SUCCESS', data.data);
          return data.data;
        });
    },
  },
};

export default merge(workflowCrud, workflows);
