<template lang="pug">
div
  aiq-row.row(v-for="item in conversationData" :key="item.label" type="flex")
    aiq-col.center(v-if="!item.value" :sm="24")
      label {{item.label}}
    template(v-else)
      aiq-col(:sm="6")
        label {{item.label}}
      aiq-col(:sm="18")
        span.value(v-if="item.type === 'string'") {{item.value}}
        message(v-else-if="item.type === 'message'" :message="item.value")
        .value(v-else :class="item.type")
          aiq-tag(v-for="subValue in item.value" :key="subValue") {{subValue}}
</template>

<script>
import GetGlobalSource from '@/libs/mixins/GetGlobalSource';

export default {
  name: 'TooltipBody',
  props: {
    conversationData: {
      type: Array,
      default: [],
    },
  },
  // Need to add GetGlobalSource mixin because it's a dependency in message in aiq-components
  mixins: [GetGlobalSource],
};
</script>