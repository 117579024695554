
import merge from 'lodash/merge';
import get from 'lodash/get';

export default {
  SELECT_CUSTOMER(state, customer) {
    state.selectedCustomers[customer.id] = customer;
  },

  DESELECT_CUSTOMER(state, customer) {
    delete state.selectedCustomers[customer.id];
  },

  DESELECT_ALL_CUSTOMERS(state) {
    state.selectedCustomers = {};
  },

  SET_CUSTOMERS(state, customers) {
    for (const customer of customers) {
      customer.fullName = `${get(customer, 'profile.first_name', '')} ${get(customer, 'profile.last_name', '')}`;
      if (customer.fullName === ' ') {
        customer.fullName = '';
      }
      state.customers[customer.id] = customer;
    }
  },

  UPDATE_CUSTOMERS(state, customers) {
    for (const customer of customers) {
      const updateCustomer = merge(state.customers[customer.id], customer);
      state.customers[updateCustomer.id] = updateCustomer;
    }
  },

  UPDATE_PRIMARY_AGENT(state, customers) {
    for (const customer of customers) {
      const updateCustomer = state.customers[customer.id];
      if (updateCustomer) {
        updateCustomer.primaryAgent = customer.primaryAgent;
        state.customers[customer.id] = updateCustomer;
      }
    }
  },

  CLEAR_STATE(state) {
    state.customers = {};
    state.selectedCustomers = {};
    state.totalCustomers = 0;
  },

  SET_TOTAL_CUSTOMERS(state, rowCount) {
    state.totalCustomers = rowCount;
  },
};
