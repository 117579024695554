<template lang="pug" id="bar">
extends ./Base.pug
</template>

<script>
import c3 from 'c3';
import * as d3 from 'd3';
import moment from 'moment';
import * as log from 'loglevel';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import BaseChart from './Base.vue';

const hoursPerDay = 24;
const daysPerWeek = 7;
const tooltipBoxOffset = 10;
const tooltipBoxDefaultDimensionLength = 70;
const TAG = 'Heatmap Thresholds';

export default {
  extends: BaseChart,
  name: 'aiqHeatMap',
  props: {
    height: {
      type: Number,
      // todo: automatically set height
      default: () => 310, // eslint-disable-line
    },
    colors: {
      type: Array,
      default: () => ['#FFF2DB', '#FFE9C2', '#FFDB9C', '#FFCD75', '#FFBE50'],
    },
    showYGridLines: {
      type: Boolean,
      default: () => false,
    },
    yScaleMax: {
      type: Number,
      default: () => 0,
    },

    yScaleMin: {
      type: Number,
      default: () => 6, // eslint-disable-line
    },
    xTickValues: {
      type: Array,
      default: () => [...Array(hoursPerDay).keys()],
    },
    yTickValues: {
      type: Array,
      default: () => [...Array(daysPerWeek).keys()],
    },
    formatXTick: {
      type: Function,
      default: v => moment()
        .startOf('day')
        .add(v, 'hour')
        .format('ha'),
    },
    formatYTick: {
      type: Function,
      default: v => moment()
        .isoWeekday(v)
        .format('ddd'),
    },
    transitionDuration: {
      type: Number,
      default: () => c3.chart.internal.fn.getDefaultConfig().transition_duration,
    },
    displayValues: {
      type: String,
      default: () => 'max',
      validator: value => ['all', 'max', 'min', 'none'].includes(value),
    },
    margin: {
      type: Object,
      default: () => ({
        top: 20,
        right: 0,
        bottom: 0,
        left: 68,
        axis: 6,
      }),
    },
    tooltipsConfig: {
      type: Array,
      default: () => [],
    },
    tooltipsClassname: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      sizeObserver: null,
      contentRectWidth: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      // If the heatmap is called in a tab that is gets the data
      // from an ancestor but the tab itself is inactive.
      // In this case, this.$el.clientWidth is 0 and the ResizeObserver
      // is the only way to detect when the tab becomes active again.
      // It also does the same job as the window resize listener.
      this.sizeObserver = new ResizeObserver((entries) => {
        this.contentRectWidth = entries[0].contentRect.width;
      });
      this.sizeObserver.observe(this.$el);
    });
  },
  willDestroy() {
    this.$nextTick(() => {
      this.sizeObserver.unobserve();
    });
  },
  methods: {
    getChartType() {
      return 'heatMap';
    },
    renderLegend() {
      const colorScale = this.getScale();
      const [metricKey] = Object.keys(this.lines);

      log.debug(TAG, `for ${metricKey}`, this.colors.map(color => ({
        values: colorScale.invertExtent(color),
        color,
      })));

      d3
        .select(this.$el.querySelector('.legend .select-lines'))
        .html(null)
        .selectAll('div')
        .data(this.colors)
        .enter()
        .append('div')
        .attr('class', 'legend-item')
        .each(function renderLegendBucket(color) {
          const threshold = colorScale.invertExtent(color);

          d3
            .select(this)
            .append('span')
            .style('background-color', color)
            .style('border-color', color)
            .attr('class', 'legend-item-icon');
          d3
            .select(this)
            .append('span')
            .attr('class', 'legend-item-label')
            .text(`≥ ${Math.round(threshold[0])}`);
        });
    },

    getScale() {
      const [metricKey] = Object.keys(this.lines);
      const metric = this.lines[metricKey];
      const { valueKey } = metric;
      const dataPoints = this.report.dataset.find(d => d.metric === metricKey)
        .data_points;
      const chartDataPoints = dataPoints.map(d => d[valueKey]);
      const chartDataPointsMax = Math.ceil(d3.max(chartDataPoints));
      const chartDomain = chartDataPointsMax ? chartDataPoints : [0, 1];

      return d3
        .scaleQuantize()
        .domain(d3.extent(chartDomain))
        .range(this.colors);
    },

    renderChart() {
      this.DestroyChart();
      const { dataset } = this.report;
      if (!dataset) {
        return;
      }
      const [metricKey] = Object.keys(this.lines);
      const metric = this.lines[metricKey];
      const { valueKey, xKey, yKey } = metric;
      const dataPoints = this.report.dataset.find(d => d.metric === metricKey)
        .data_points;
      const max = d3.max(dataPoints.map(d => d[valueKey]));
      const colorScale = this.getScale();

      const xTickValuesCount = this.xTickValues.length;
      const yTickValuesCount = this.yTickValues.length;
      const width = this.$el.clientWidth;
      const height = this.height - this.margin.bottom;
      const cellWidth = Math.floor(
        Math.abs(width - this.margin.left - this.margin.right) / xTickValuesCount,
      );
      const cellHeight = Math.floor(Math.abs(height - this.margin.top - this.margin.bottom)
          / yTickValuesCount);
      const svg = d3
        .select(this.$el)
        .select('.chart-container')
        .attr('class', 'chart-container heat-graph')
        .html(null)
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .append('g')
        .attr('transform', `translate(${this.margin.left},${this.margin.top})`);

      // y axis
      this.renderYAxis(svg, cellHeight);
      
      // x axis
      svg
        .selectAll('.x-axis-label')
        .data(this.xTickValues)
        .enter()
        // render every other axis labels
        .filter(d => !(d % 2)) // eslint-disable-line
        .append('text')
        .text(this.formatXTick)
        .attr('x', d => d * cellWidth)
        .attr('y', 0)
        .style('text-anchor', 'middle')
        .attr(
          'transform',
          `translate(${cellWidth / 2}, ${-Math.abs(this.margin.axis)})`, // eslint-disable-line
        );

      // cells
      const heatMapCells = svg
        .selectAll('.cell')
        .data(dataPoints)
        .enter()
        .append('g')
        .attr('x', d => d[xKey] * cellWidth)
        .attr('y', d => d[yKey] * cellHeight)
        .attr('class', 'cell')
        .style('stroke', 'white')
        .style('stroke-width', 1)
        .attr('width', cellWidth)
        .attr('height', cellHeight);

      heatMapCells
        .append('rect')
        .attr('x', d => d[xKey] * cellWidth)
        .attr('y', d => d[yKey] * cellHeight)
        .attr('class', 'cell')
        .attr('width', cellWidth)
        .attr('height', cellHeight)
        .style('fill', this.colors[0])
        .on('mouseover', (event, d) => {
          const x = this.formatXTick(d[xKey]);
          const y = this.formatYTick(d[yKey]);
          const v = d[valueKey];

          if (!isNil(v)) {
            tooltip
              .select('.tooltip-header')
              .text(`${y} ${x}`);

            if (this.tooltipsConfig.length) {
              this.tooltipsConfig.forEach((tooltipItem, i) => {
                const { label, valueKey: tooltipValueKey, callFormatter } = tooltipItem;
                const value = callFormatter(d[tooltipValueKey]);
                tooltip
                  .select(`.tooltip_item_${i}`)
                  .text(`${label}: ${value}`);
              });
            } else {
              tooltip
                .select('.tooltip-body')
                .text(`${v}`);
            }

            const width = get(tooltip, '_groups.0.0.clientWidth', tooltipBoxDefaultDimensionLength);
            const chartBounds = this.$el.getBoundingClientRect();
            const chartMiddle = Math.floor((chartBounds.left + chartBounds.right) / 2); // eslint-disable-line

            if (event.pageX < chartMiddle) {
              tooltip.style('left', `${event.pageX + tooltipBoxOffset}px`);
            } else {
              tooltip.style('left', `${event.pageX - width}px`);
            }            

            const height = get(tooltip, '_groups.0.0.clientHeight', tooltipBoxDefaultDimensionLength);
            const verticalDistance = height + tooltipBoxOffset;

            tooltip
              .style('top', `${(event.pageY - verticalDistance)}px`)
              .style('visibility', 'visible');
          }
        })
        .on('mouseout', () => {
          tooltip.style('visibility', 'hidden');
        });

      heatMapCells
        .selectAll('rect')
        .transition()
        .duration(this.transitionDuration)
        .style('fill', d => colorScale(d[valueKey]) || this.colors[0]);

      heatMapCells
        .append('text')
        .attr('class', c3.chart.internal.fn.CLASS.chartText)
        .attr('x', d => (d[xKey] * cellWidth) + (cellWidth * 0.5)) // eslint-disable-line
        .attr('y', d => (d[yKey] * cellHeight) + (cellHeight * 0.5)) // eslint-disable-line
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'central')
        .style('stroke-width', 0)
        .text(d => {
          let label;
          const value = d[valueKey];
          switch (this.displayValues) {
            case 'none':
              break;
            case 'max':
              label = value === max ? value : label;
              break;
            case 'min':
              label = value === 0 ? value : label;
              break;
            default:
              label = value;
              break;
          }
          return label;
        });

      const containerClass = this.tooltipsClassname ? `.${this.tooltipsClassname}` : '';
      const tooltip = d3.select(`${containerClass} .heat-graph`)
        .append('div')
        .attr('class', 'tooltip')
        .style('visibility', 'hidden');

      tooltip
        .append('div')
        .attr('class', 'tooltip-header')
        .style('background-color', this.colors[this.colors.length - 1]);

      tooltip
        .append('div')
        .attr('class', 'tooltip-body');

      this.tooltipsConfig.forEach((_, i) => {
        tooltip
          .select('.tooltip-body')
          .append('div')
          .attr('class', `tooltip_item_${i}`);
      });

      this.renderLegend();
    },

    renderYAxis(svg, cellHeight) {
      const yAxisLabelWidth = Math.abs(this.margin.left - 20); // eslint-disable-line
      const containerClass = this.tooltipsClassname ? `.${this.tooltipsClassname}` : '';
      const tooltip = d3.select(`${containerClass} .heat-graph`)
        .append('div')
        .attr('class', 'y-axis-tooltip')
        .style('visibility', 'hidden');

      const yAxisTickContainer = svg
        .selectAll('.y-axis-label')
        .data(this.yTickValues)
        .enter()
        .append('foreignObject')
        .attr('height', cellHeight)
        .attr('width', yAxisLabelWidth)
        .attr('x', 0)
        .attr('y', d => d * cellHeight)
        .attr(
          'transform',
          `translate(${-yAxisLabelWidth}, 0)`,
        )
        .append('xhtml:body')
        .attr('class', 'y-axis-tick-container')
        .on('mouseover', (event, d) => {
          const isTruncated = event.srcElement.className === 'y-axis-tick truncated';
          let fullLabel, truncatedLabel;
          
          if (isTruncated) {
            fullLabel = event.srcElement.nextElementSibling;
            truncatedLabel = event.srcElement;
          } else {
            fullLabel = event.srcElement.querySelector('.y-axis-tick.full');
            truncatedLabel = event.srcElement.querySelector('.y-axis-tick.truncated');
          }

          if (fullLabel && truncatedLabel && fullLabel.clientWidth >= yAxisLabelWidth) {
            const { top, left } = truncatedLabel.getBoundingClientRect();
            const horizontalOffset = 10;
            const verticalOffset = 40;
            
            tooltip
              .text(this.formatYTick(d))
              .style('left', `${left - horizontalOffset}px`)
              .style('top', `${top - verticalOffset}px`)
              .style('visibility', 'visible');
          }
        })
        .on('mouseleave', () => {
          tooltip.style('visibility', 'hidden');
        });

      yAxisTickContainer
        .append('div')
        .attr('class', 'y-axis-tick truncated')
        .text(this.formatYTick);
      
      yAxisTickContainer
        .append('div')
        .attr('class', 'y-axis-tick full') 
        .text(this.formatYTick);      
    },
  },
  watch: {
    contentRectWidth(newValue) {
      if (newValue) {
        this.renderChart();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.y-axis-tick-container) {
  display: flex;
  align-items: center;
  position: relative;

  .y-axis-tick {
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    white-space: nowrap;
    background: #fff;

    &.truncated {
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: default;
    }

    &.full {
      visibility: hidden;
      position: absolute;
      overflow: visible;
    }
  }
}
</style>

<style lang="scss">
.tooltip {
  position: fixed;
  border: 1px solid #CBCBCB;
  border-radius: 4px;
  font-size: 12px;

  .tooltip-header {
    color: #FFF;
    padding: 4px 8px;
    border-radius: 4px 4px 0 0;
  }

  .tooltip-body {
    padding: 4px 8px;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    color: #28292C;
  }

}

.y-axis-tooltip {
  position: fixed;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  background: #303133;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.tooltipDiv{
  width:60px;
  height: 20px;
  background-color: blue;
  opacity: 0.9;
  z-index: 100;
}
</style>
