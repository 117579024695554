<template lang="pug">
  .item-new
    .item-new_name
      FilteredInput#new-item-with-keywords.input-new-item(
        size="small",
        :max-length="25"
        v-model="itemRecord.name",
        placeholder="New Item",
        @keyup.enter="addItem")
    .item-new_keywords
      aiq-keyword-input(:list="itemRecord.keywords"
                        ref="keywordInput"
                        @createItem="addKeyword"
                        @deleteItem="deleteKeyword"
                        :deletable="true"
                        :itemMaxLength="keywordMaxLength")
</template>

<script>
import uniq from 'lodash/uniq';

export default {
  name: 'itemWithKeywordNew',
  props: {
    item: Object,
    onSubmit: Function,
  },
  emits: ['onSubmit'],
  data() {
    return {
      namePlaceholder: 'New Item',
      keywordMaxLength: process.env.INPUT_PARAMS.keywordMaxLength,
      itemRecord: { ...this.item },
    };
  },
  methods: {
    addItem() {
      if (!this.itemRecord.name) {
        return;
      }
      this.$emit('onSubmit', { ...this.itemRecord });
    },

    addKeyword(keyword) {
      const keywords = uniq([...this.itemRecord.keywords, keyword]);
      this.itemRecord.keywords = keywords;
      this.addItem();
    },

    deleteKeyword(keyword) {
      const keywords = this.itemRecord.keywords.filter(k => k !== keyword);
      this.itemRecord.keywords = keywords;
    },
  },
  watch: {
    item(item) {
      this.itemRecord = { ...item };
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/aiq-variables.scss";
  .item-new {
    display: flex;
    border-bottom: 1px solid #E0E6ED;

    > div {
      display: flex;
      align-items: center;
      min-height: 40px;
      padding-right: 10px;
      padding-left: 10px;
    }

    &:hover {
      background-color: $aiq-bdc-button-connection;

      .item-actions {
        opacity: 1;
      }
    }
  }
  .item-new_name {
    width: 250px;
  }
  .item-new_keywords {
    flex: 1 0 0;

    .keyword-input {
      border: 0;
      flex-grow: 1;
    }
  }
  .item-new_actions {
    width: 80px;
  }
</style>
