<template lang="pug">
  .pairs-container
    .pairs__content
      .pair-container(v-for="(pair, index) in pairs")
        .pair-select-item(:class="classObjLeft")
          aiq-input(v-if="shouldUseRawInputBoxOn('left')"
                    v-model="pair.content[0]"
                    size="small"
                    :placeholder="placeholderLeft"
                    @change="onSelect($event, index, 0)")
          aiq-select(v-else
                    no-data-text="None available"
                    size="small"
                    :model-value="pair.content[0]"
                    :placeholder="placeholderLeft"
                    @change="onSelect($event, index, 0)")
            aiq-option(v-for="item in availableOptionsLeft",
                      :key="item",
                      :value="item")
        span =
        .pair-select-item(:class="classObjRight")
          aiq-input(v-if="shouldUseRawInputBoxOn('right')"
                    v-model="pair.content[1]"
                    size="small"
                    :placeholder="placeholderRight"
                    @change="onSelect($event, index, 1)")
          aiq-select(v-else
                    no-data-text="None available"
                    size="small"
                    :model-value="pair.content[1]"
                    :placeholder="placeholderRight"
                    @change="onSelect($event, index, 1)")
            aiq-option(v-for="item in availableOptionsRight",
                      :key="item",
                      :value="item")
        aiq-button.pairs-delete_btn(size="small"
                  :disabled="pair.deleteDisabled"
                  @click="deletePair(index)")
          i.el-icon-close
    aiq-button.pairs-add_btn(size="small"
                            :disabled="isAddDisabled"
                            @click="addPair")
      i.el-icon-plus
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import * as log from 'loglevel';

const TAG = '[selectBoxPairs]';

const COLUMN = {
  LEFT: 'left',
  RIGHT: 'right',
  BOTH: 'both',
  NONE: 'none',
};

export default {
  name: 'selectBoxPairs',
  props: {
    basePairs: {
      type: Array,
      default: () => [],
    },
    optionsLeft: {
      type: Array,
      default: () => [],
    },
    optionsRight: {
      type: Array,
      default: () => [],
    },
    classObjLeft: {
      type: Object,
      default: () => ({}),
    },
    classObjRight: {
      type: Object,
      default: () => ({}),
    },
    placeholderLeft: {
      type: String,
      default: '',
    },
    placeholderRight: {
      type: String,
      default: '',
    },
    addableBasedOn: {
      type: String,
      default: COLUMN.BOTH,
    },
    useRawInputOn: {
      type: String,
      default: COLUMN.NONE,
    },
  },
  emits: ['update'],
  data() {
    return {
      pairs: cloneDeep(this.basePairs),
    };
  },
  watch: {
    basePairs(newVal) {
      if (newVal) {
        this.pairs = cloneDeep(newVal);
      }
    },
  },
  computed: {

    // creates arrays of selected values, one for left side and one for right side
    selected() {
      return this.pairs.reduce((acc, pair) => {
        acc.left.push(pair.content[0]);
        acc.right.push(pair.content[1]);

        return acc;
      }, { left: [], right: [] });
    },
    availableOptionsLeft() {
      return difference(this.optionsLeft, this.selected.left);
    },
    availableOptionsRight() {
      return difference(this.optionsRight, this.selected.right);
    },
    isAddDisabled() {
      return !this.arePairsValid || this.noAvailableOptions;
    },
    arePairsValid() {
      // Checks for existing of each item in each pair
      for (const pair of this.pairs) {
        const [left, right] = pair.content;
        if (!left || !right) {
          return false;
        }
      }

      return true;
    },
    noAvailableOptions() {
      const { availableOptionsLeft, availableOptionsRight } = this;
      if (this.addableBasedOn === COLUMN.LEFT) {
        return availableOptionsLeft.length === 0;
      }

      if (this.addableBasedOn === COLUMN.RIGHT) {
        return availableOptionsRight.length === 0;
      }

      if (this.addableBasedOn === COLUMN.BOTH) {
        return availableOptionsLeft.length === 0 || availableOptionsRight.length === 0;
      }
      return true;
    },
  },
  methods: {
    shouldUseRawInputBoxOn(column) {
      if (this.useRawInputOn === COLUMN.BOTH) {
        return true;
      }

      return column === this.useRawInputOn;
    },
    addPair() {
      const pairs = [...this.pairs, { content: ['', ''] }];
      this.$emit('update', pairs);
    },
    deletePair(index) {
      const pairs = this.pairs.slice(0, index).concat(this.pairs.slice(index + 1));
      this.$emit('update', pairs);
    },
    onSelect(selection, pairsIndex, pairIndex) {
      log.debug(TAG, 'onSelect', selection, pairsIndex, pairIndex);
      this.pairs[pairsIndex].content[pairIndex] = selection;
      this.$emit('update', this.pairs);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/aiq-mixins.scss";
@import "../../styles/aiq-variables.scss";

.pairs-container {
  .pair-container {
    @include space-between-h(4px);

    display: flex;
    line-height: 30px;

    .pair-select-item {
      flex: 1 50px;
    }
  }
  // todo - move to common styles
  button {
    background-color: transparent;
    border: 0;
    color: $aiq-fill-button-default;
    display: flex;
    padding: 0;

    &:hover {
      background-color: transparent;
      color: $aiq-fill-button-default;
    }
  }

  .pairs-add_btn {
    font-size: 14px;
    margin-top: 12px;
  }

  .pairs-delete_btn {
    margin-left: 8px;
  }
}

</style>
