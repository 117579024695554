import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { mapGetters } from 'vuex';

import {
  NEW_CONDITIONAL_ACTION,
} from '@/constants/dialogConstants';
import ConditionalActionsItem from './ConditionalActionsItem.vue';

export default {
  name: 'conditionalActions',
  components: {
    ConditionalActionsItem,
  },
  props: {
    conditionalActions: {
      type: Array,
      required: true,
    },
    useUniversalInputType: {
      type: Boolean,
      default: false,
    },
    entitySelectionList: {
      type: Array,
      default: [],
    },
    operandEntity: {
      type: String,
      default: '',
    },
    excludeActionName: {
      type: String,
      default: '',
    },
  },
  emits: ['update'],
  computed: {
    ...mapGetters({
      valueTypeList: 'dialogs/valueTypeList',
    }),
    shouldDisableAddButton() {
      const len = this.conditionalActions.length;
      const lastItem = this.conditionalActions[len - 1];

      return !get(lastItem, 'statements[0].operator');
    },
    universalValueType() {
      return get(this, 'conditionalActions[0].input_type', 'string');
    },
  },
  methods: {
    addConditionalActions() {
      const record = cloneDeep(this.conditionalActions);
      const newActions = cloneDeep(NEW_CONDITIONAL_ACTION);

      record.push(newActions);

      this.$emit('update', record);
      this.$nextTick(() => {
        this.$el.scrollIntoView(false);
      });
    },
    clearExistingConditions() {
      const record = cloneDeep(this.conditionalActions);
      record.forEach(conditionalAction => {
        conditionalAction.statements.forEach(statement => {
          statement.operator = null;
        });
      });
      this.$emit('update', record);
    },
    deleteConditionalActions(conditionalActionsIndex) {
      const record = cloneDeep(this.conditionalActions);
      record.splice(conditionalActionsIndex, 1);
      this.$emit('update', record);
    },
    updateParams(payload) {
      const { conditionIndex, value, conditionalActionsIndex } = payload;
      const record = cloneDeep(this.conditionalActions);
      record[conditionalActionsIndex].statements[conditionIndex].value = value;
      this.$emit('update', record);
    },
    onValueTypeChange(valueType) {
      const record = cloneDeep(this.conditionalActions);

      this.conditionalActions.forEach(cAction => {
        cAction.statements.forEach(statement => {
          statement.input_type = valueType;
        });
      });

      this.$emit('update', record);

      this.clearExistingConditions();
    },
    updateConditionalActions(index, conditionalAction) {
      const conditionalActions = cloneDeep(this.conditionalActions);

      conditionalActions[index] = conditionalAction;
      this.$emit('update', conditionalActions);
    },
  },
};
