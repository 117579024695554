import { createNamespacedHelpers } from 'vuex';
import { Component, Watch, Vue, toNative } from 'vue-facing-decorator';
import cloneDeep from 'lodash/cloneDeep';

import isEqual from 'lodash/isEqual';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';

const { mapActions, mapState } = createNamespacedHelpers('configs');

const INFO = `Connect and integrate with Zendesk. <br><br>For more information and to
  get an access token, please refer to the Zendesk API docs <a target="_blank"
  href="https://developer.zendesk.com/rest_api/docs/core/introduction#api-token">here</a>.`;

@Component({
  name: 'ZendeskTab',
  components: {
    ManagementPage,
  },
  computed: {
    ...mapState({
      savedForm: state => state.integrations.zendesk.form,
    }),
    pristine() {
      return isEqual(this.localForm, this.savedForm);
    },
    hasEmptyField() {
      return !this.localForm.username || !this.localForm.accessToken;
    },
  },
  methods: {
    ...mapActions([
      'loadZendesk',
      'updateZendesk',
    ]),
  },
})
class ZendeskTab extends Vue {
  localForm = {
    username: null,
    accessToken: null,
  };

  created() {
    this.$_info = INFO;
  }

  @Watch('savedForm')
  syncSavedForm(savedForm) {
    this.localForm = cloneDeep(savedForm);
  }

  load() {
    this.loadZendesk();
  }

  resetForm() {
    this.localForm = cloneDeep(this.savedForm);
  }

  saveForm() {
    return this.updateZendesk(this.localForm)
      .then(() => {
        this.$aiq.notify.success('Zendesk credentials saved.');
      }).catch(() => {
        this.$aiq.notify.error('Error saving Zendesk credentials.');
      });
  }
}
export default toNative(ZendeskTab);
