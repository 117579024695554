import { EntitySuggestionPopupsMixin } from '@/libs';

export default {
  name: 'actionInput',
  mixins: [EntitySuggestionPopupsMixin],
  data() {
    return {
      inputText: '',
    };
  },
  emits: ['create'],
  mounted() {
    this.$refs.spInputBox.focus();
  },
  methods: {
    createAction() {
      if (this.inputText === '') return;

      const action = {
        name: 'conversations.messages.create',
        payload: {
          message: {
            payload: {
              content: this.inputText,
            },
          },
        },
      };

      this.$emit('create', action);
      this.inputText = '';
    },
  },
};
