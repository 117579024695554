<template>
<svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   id="svg1498"
   version="1.1"
   viewBox="0 0 8.3369398 5.6199989"
   height="5.6199989mm"
   width="8.3369398mm">
  <g
     transform="translate(-108.93274,-128.72571)"
     id="layer1">
    <g
       style="opacity:1;stop-opacity:1"
       id="g1510"
       transform="matrix(0.35277777,0,0,0.35277777,108.93274,128.72571)">
      <path
         transform="scale(0.75000002)"
         d="M 0.5 0 C 0.22400001 0 3.7007434e-17 0.22400001 0 0.5 L 0 16.5 C 0 16.776 0.22400001 17 0.5 17 L 7 17 L 7 20.740234 C 7 21.193568 7.5512813 21.411365 7.8632812 21.082031 L 11.712891 17 L 15.732422 17 A 8.1137293 8.8922581 0 0 1 15.634766 16.890625 A 8.1137293 8.8922581 0 0 1 15.474609 16.703125 A 8.1137293 8.8922581 0 0 1 15.320312 16.511719 A 8.1137293 8.8922581 0 0 1 15.171875 16.314453 A 8.1137293 8.8922581 0 0 1 15.027344 16.111328 A 8.1137293 8.8922581 0 0 1 14.951172 16 L 11.5 16 C 11.364 16 11.230052 16.057583 11.136719 16.15625 L 8 19.478516 L 8 16.5 C 8 16.224 7.776 16 7.5 16 L 1 16 L 1 1 L 24 1 L 24 2.2851562 A 8.1137293 8.8922581 0 0 1 24.023438 2.2929688 A 8.1137293 8.8922581 0 0 1 24.246094 2.375 A 8.1137293 8.8922581 0 0 1 24.464844 2.4667969 A 8.1137293 8.8922581 0 0 1 24.679688 2.5625 A 8.1137293 8.8922581 0 0 1 24.894531 2.6679688 A 8.1137293 8.8922581 0 0 1 25 2.7226562 L 25 0.5 C 25 0.22400001 24.776 3.7007434e-17 24.5 0 L 0.5 0 z "
         style="fill:#FFFFFF;fill-opacity:1;fill-rule:evenodd;stroke:none;stroke-width:1.33333"
         id="path3495" />
      <path
         id="path1516"
         style="fill:#FFFFFF;fill-opacity:1;fill-rule:evenodd;stroke:none"
         d="m 8.9685017,3.7135065 c 0.206997,-0.00126 0.375,-0.168 0.375,-0.375 0,-0.207 -0.168003,-0.3762605 -0.375,-0.375 L 2.9754537,3 c -0.2069962,0.00126 -0.375,0.168 -0.375,0.375 0,0.207 0.1680038,0.3762605 0.375,0.375 z M 8.9754537,6.75 c 0.207,0 0.375,-0.168 0.375,-0.375 0,-0.207 -0.168,-0.375 -0.375,-0.375 h -6 c -0.207,0 -0.375,0.168 -0.375,0.375 0,0.207 0.168,0.375 0.375,0.375 z m -0.04909,2.9635065 c 0.206996,-0.00127 0.375,-0.168 0.375,-0.375 0,-0.207 -0.168004,-0.3762694 -0.375,-0.375 L 2.9754537,9 c -0.2069961,0.00127 -0.375,0.168 -0.375,0.375 0,0.207 0.1680039,0.3762694 0.375,0.375 z" />
      <g
         transform="translate(-37.837071,11.527761)"
         id="g3396">
        <g
           id="g2307">
          <circle
             r="6.4538636"
             cy="-3.2235885"
             cx="54.615353"
             id="path2267"
             style="fill:none;stroke:#FFFFFF;stroke-width:0.80025;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
          <path
             id="path1514-9"
             style="opacity:1;fill:#FFFFFF;fill-opacity:1;fill-rule:evenodd;stroke:none;stroke-width:2.02723;stop-opacity:1"
             d="m 53.868773,-0.18731288 c 0,0.419635 0.340574,0.76020998 0.76021,0.76020998 0.419635,0 0.760211,-0.34057498 0.760211,-0.76020998 V -3.6721133 c 0,-0.4196357 -0.340576,-0.7602095 -0.760211,-0.7602095 -0.419636,0 -0.76021,0.3405738 -0.76021,0.7602095 z m 0.76021,-5.32146672 c 0.419635,0 0.760211,-0.3405738 0.760211,-0.7602094 0,-0.4196357 -0.340576,-0.7602096 -0.760211,-0.7602096 -0.419636,0 -0.76021,0.3405739 -0.76021,0.7602096 0,0.4196356 0.340574,0.7602094 0.76021,0.7602094 z" />
        </g>
      </g>
      <g
         style="opacity:1;stop-opacity:1"
         id="g3391"
         transform="translate(-37.855432,-4.4360723)">
        <g
           id="g3384">
          <circle
             r="6.4538636"
             cy="12.748218"
             cx="54.615353"
             id="path2267-3"
             style="opacity:1;fill:#ffb380;stroke:#FFFFFF;stroke-width:0.80025;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;stop-opacity:1" />
        </g>
        <g
           style="stroke-width:1.85137;stroke-miterlimit:4;stroke-dasharray:none"
           transform="matrix(0.7089326,0,0,0.7089326,25.985637,22.330497)"
           id="g2993">
          <path
             style="fill:none;stroke:#FFFFFF;stroke-width:1.85137;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
             d="m 36.988341,-16.954891 6.79183,6.876804"
             id="path2974" />
          <path
             id="path2974-4"
             d="m 36.945854,-10.120574 6.876804,-6.79183"
             style="opacity:1;fill:none;stroke:#FFFFFF;stroke-width:1.85137;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;stop-opacity:1" />
        </g>
      </g>
    </g>
  </g>
</svg>
</template>
