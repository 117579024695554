const urlMatcher = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/g;
const linkMatcher = /(?:>)(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})(?:<)/g;

const fileNameParser = text => {
  const splitted = text.split('.');

  if (splitted.length === 1) {
    splitted.push('');
    return splitted;
  }

  if (splitted.length > 1) {
    const fileExt = splitted.pop();
    const fileName = splitted.join('.');
    return [fileName, `.${fileExt}`];
  }

  return ['', ''];
};

export {
  fileNameParser,
  linkMatcher,
  urlMatcher,
};
