<template lang="pug">
    .title
        h3(class="left") {{$t('metrics_tab.agents_tab.platform_tab.comms_table_title')}}
        div(class="right")
            aiq-input(v-model="searchString" class="search-form" size="small" :placeholder="$t('metrics_tab.agents_tab.platform_tab.search_placeholder')")
            aiq-button.export-chart(:disabled="loading || !metricList.length" @click="exportChart")
                i.iq-ico-export
    aiq-table(:data="metricList" v-loading="loading")
      aiq-table-column(label="Conversation ID", :sortable="true", prop="conversation_id" label-class-name="is-bordered")
      aiq-table-column(label="Agent", :sortable="true", prop="agent" label-class-name="is-bordered")
        template(v-slot:default="scope")
          span {{ getAgentName(scope.row.profile) }} &nbsp;
      aiq-table-column(label="Session Type", :sortable="true", prop="session_type" label-class-name="is-bordered")
      aiq-table-column(label="Start Time", :sortable="true", prop="session_created_at" label-class-name="is-bordered")
        template(v-slot:default="scope")
          span {{ formatTime(scope.row.session_created_at) }}&nbsp;
      aiq-table-column(label="End Time", :sortable="true", prop="ended_at" label-class-name="is-bordered")
        template(v-slot:default="scope")
          span {{ formatTime(scope.row.ended_at) }}&nbsp;
      aiq-table-column(label="Duration", :sortable="true", prop="duration" label-class-name="is-bordered")
        template(v-slot:default="scope")
          span {{ $filters.duration(scope.row.duration) }}&nbsp;
</template>

<script>
import moment from 'moment';
import { debounce, get, isEmpty } from 'lodash';
import { downloadAsFile } from '@/libs';
import { dstShift } from '@/pages/Metrics/configs/dateRange';

const DEBOUNCE_VALUE = 1000;
export default {

  name: 'PlatformCommunicationsTable',
  props: {
    dateRangeTable: {
      required: true,
      type: Array,
    },
    breakdown: {
      required: true,
      type: String,
    },
    selectedFilterValue: {
      type: Array,
      required: true
    },
  },
  mounted(){
    this.fetchTableData();
  },
  data(){
    return {
      loading: false,
      searchString: '',
      metricList: [],
    };
  },
  watch: {
    dateRangeTable: {
      handler: debounce(function onDateChange(newValue, _oldValue = []) {
        const [start, end] = newValue;
        if (!(start && end)) {
          return;
        }
        this.fetchTableData();
      }, DEBOUNCE_VALUE),
      deep: true
    },
    selectedFilterValue: {
      handler: function onFilterChange(newValue, _oldValue) {
        if (!isEmpty(newValue)) {
          this.fetchTableData();
        }
      },
      deep: true
    },
    breakdown: {
      handler: function onBreakdownChange(newValue, _oldValue) {
        if (!isEmpty(newValue)) {
          this.fetchTableData();
        }
      },
    },
    searchString : debounce(function onDateChange() {
      this.fetchTableData();
    }, DEBOUNCE_VALUE)
  },
  methods:{
    exportChart(){
      downloadAsFile({
        data: this.metricList.map(data => ({
          agent: this.getAgentName(data.profile),
          conversation_id: data.conversation_id,
          session_type: data.session_type,
          start_time: this.formatTime(data.session_created_at),
          end_time: this.formatTime(data.ended_at),
          duration: this.$filters.duration(data.duration),
        })),
        dataType: 'table',
        fileName: 'platform_communication',
        fileType: 'csv',
      });
      this.$aiq.notify.success(this.$t('metrics_tab.agents_tab.platform_tab.download_success'));
    },
    getAgentName(agent){
      return `${agent.first_name} ${agent.last_name}`;
    },
    formatTime(date){
      if (!date){
        return '';
      }
      return moment(date).format('MMM DD, YYYY hh:mm A');
    },
    fetchTableData() {
      this.loading = true;
      const params = {
        period_start: dstShift(this.dateRangeTable[0]),
        period_end: dstShift(this.dateRangeTable[1]),
        filters: this.selectedFilterValue,
        bucket: this.breakdown,
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };


      if (!isEmpty(this.searchString)){
        params.query = this.searchString;
      }

      this.$store.dispatch('metrics/getPlatformCommsList',
        ['communication_sessions',params]).then(data=>{
        this.metricList = get(data,'dataset[0].data_points',[]);
      }).catch((_err)=>{
        this.$aiq.notify.error(this.$t('metrics_tab.agents_tab.platform_tab.errro_fetching'));
      }).finally(()=>{
        this.loading = false;
      });


    }
  }
};
</script>
<style scoped lang="scss">
.title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .left{
        float: left;
    }
    .right {
        float: right;
        display: flex;
        align-items: center;
        .search-form {
            width: 200px;
            margin-right: 10px;
     }
    }

}
:deep(.el-button) {
  padding: 5px 15px;
}
</style>
