export const randomString = () =>
  Math.random().toString(36).slice(2);   // eslint-disable-line

const DEFAULT_MAX_LENGTH = 25;
const DEFAULT_SUFFIX = '...';

export const limitedString = (
  str,
  maxLength = DEFAULT_MAX_LENGTH,
  suffix = DEFAULT_SUFFIX,
) => {
  if (str.length <= maxLength) {
    return str;
  }

  const truncatedLength = maxLength - suffix.length;
  return str.slice(0, truncatedLength) + suffix;
};
