import merge from 'lodash/merge';
import axios from 'axios';
import { getUrl } from '@/config/api.routes';
import { CRUDStoreBuilder, updateKeywordsActionBuilder } from '../StoreBuilder';

const routingAlias = 'management.categories';

export default merge(new CRUDStoreBuilder(routingAlias, 'category', [], 'data.models', true).createStore(), {
  state: {
    selected: {},
  },

  getters: {
    categories: state => state.categories,
    categoryModel: () => ({
      name: '',
      keywords: [],
    }),
  },

  actions: {
    createCategory({ commit }, [item]) {
      return axios.post(getUrl(routingAlias), item).then(data => {
        commit('POST_CATEGORY_SUCCESS', data.data);
        return data;
      });
    },

    deleteCategory({ commit }, category) {
      const { id } = category;
      return axios.put(`${getUrl(routingAlias)}/${id}`, { ...category, archived: true }).then(data => {
        commit('DELETE_CATEGORY_SUCCESS', id);
        return data;
      });
    },

    ...updateKeywordsActionBuilder(routingAlias),
  },
});
