<template lang="pug">
aiq-table(:data="value")
  aiq-table-column(v-for="item of columns" :label="item" :prop="item" :key="item")
</template>

<script>
import get from 'lodash/get';
import keys from 'lodash/keys';

export default {
  props: {
    value: {
      type: Array,
      default: [],
    },
  },
  computed: {
    columns() {
      // Assuming that the array has homogeneous flat objects
      return keys(get(this.value, '0', {}));
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
