import { FILES } from '@/constants/analytics';
import get from 'lodash/get';


export default {
  namespaced: true,

  state: {
    attachedFiles: [],
  },

  getters: {
    attachedFiles: state => state.attachedFiles,
  },

  mutations: {
    ATTACH_FILE(state, file) {
      if (!state.attachedFiles.find(item => item.url === file.url)) {
        state.attachedFiles = [...state.attachedFiles, file];
      }
    },
    CLEAR_ATTACHED_FILES(state) {
      state.attachedFiles = [];
    },
    REMOVE_ATTACHED_FILE(state, file) {
      state.attachedFiles.splice(state.attachedFiles.indexOf(file), 1);
    },
  },

  actions: {
    attachFile({ commit, rootState, dispatch }, { file, source = 'chat' }) {
      commit('ATTACH_FILE', file);

      dispatch(
        'notifications/emitEventToSocket',
        { name: FILES.ATTACHED,
          payload: {
            url: file.url,
            agent: get(rootState, 'agent.profile'),
            source,
          },
        },
        { root: true },
      );
      dispatch('assets/clearAttachedAssets', null, { root: true });
    },
    clearAttachedFiles({ commit }) {
      commit('CLEAR_ATTACHED_FILES');
    },
    removeAttachedFile({ commit }, file) {
      commit('REMOVE_ATTACHED_FILE', file);
    },
  },
};
