export const IDS = {
  CONV_SEARCH: 'conv-search',
  CONV_SCROLL: 'conv-list-scroll',
  MSGS_SCROLL: 'conv-messages-scroll',
  CHAT_INPUT: 'chat-input',
};

export const CUSTOM_EVTS = {
  STAR: 'star',
  CLOSE: 'close',
  LEAVE: 'leave',
};
