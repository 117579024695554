import Integrations from './Integrations.vue';
import {
  AuthenticationTab,
  SalesforceTab,
  DocuSignTab,
  AdobeSignTab,
} from './Tabs';

const rootRoute = {
  path: 'integrations',
  name: 'Integrations',
  component: Integrations,
  meta: {
    auth: true,
    resource: '/settings/integrations',
    parentName: 'Settings',
  },
};

export default {
  ...rootRoute,
  children: [
    {
      path: 'authentication',
      name: 'Authentication',
      component: AuthenticationTab,
      meta: {
        auth: true,
        parentName: 'Settings',
      },
    },
    {
      path: 'salesforce',
      name: 'Salesforce',
      component: SalesforceTab,
      meta: {
        auth: true,
        parentName: 'Settings',
      },
      children: [
        {
          path: 'oauth2/auth',
          name: 'SalesforceAuthRequest',
          meta: {
            auth: true,
            resource: '/settings/integrations/salesforce',
            parentName: 'Settings',
          },
        },
        {
          path: 'oauth2/callback',
          name: 'SalesforceAuthCallback',
          meta: {
            auth: true,
            resource: '/settings/integrations/salesforce',
            parentName: 'Settings',
          },
        },
      ],
    },
    {
      path: 'docusign',
      name: 'DocuSign',
      component: DocuSignTab,
      meta: {
        auth: true,
        parentName: 'Settings',
      },
    },
    {
      path: 'adobesign',
      name: 'AdobeSign',
      component: AdobeSignTab,
      meta: {
        auth: true,
        parentName: 'Settings',
      },
    },
  ],
};
