import axios from 'axios';
import get from 'lodash/get';
import { getUrl } from '@/config/api.routes';

export default {
  getAgents({ commit }, { params, update }) {
    if (update) {
      commit('CLEAR_STATE');
    }
    return axios.get(getUrl('dashboard', 'agents'), { params })
      .then((data) => {
        const agents = data.data.models;
        commit('SET_AGENTS', agents);
        commit('SET_TOTAL_AGENTS', get(data, 'data.pagination.rowCount', 0));
        return { agents, pagination: data.data.pagination };
      });
  },

  clearAgents({ commit }) {
    commit('CLEAR_STATE');
  },

  selectAgent({ commit }, agent) {
    commit('SELECT_AGENT', agent);
  },

  deselectAgent({ commit }, agent) {
    commit('DESELECT_AGENT', agent);
  },

  selectAgents({ commit }, agents) {
    commit('DESELECT_ALL_AGENTS');
    for (const agent of agents) {
      commit('SELECT_AGENT', agent);
    }
  },

  deselectAllAgents({ commit }) {
    commit('DESELECT_ALL_AGENTS');
  },

  setStatusForSelectedAgents(_, { agents, status }) {
    return axios.put(getUrl('dashboard', 'agents/status'), {
      payload: {
        agents,
        status,
      },
    });
  },

  sendBatchNotifications(_, { agents, message }) {
    return axios.post(getUrl('dashboard', 'agents/notifications'), {
      agents,
      message_body: message,
    });
  },
};
