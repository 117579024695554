const DEFAULT_X_POS = 10;
const DEFAULT_Y_POS = 10;

export default (containerSel, dragItemSel, xPos = DEFAULT_X_POS, yPos = DEFAULT_Y_POS) => {
  const dragItem = document.querySelector(dragItemSel);
  const container = document.querySelector(containerSel);

  let active = false;
  let currentX;
  let currentY;
  let initialX;
  let initialY;
  let xOffset = 0;
  let yOffset = 0;

  function dragStart(e) {
    if (e.type === 'touchstart') {
      initialX = e.touches[0].pageX - xOffset;
      initialY = e.touches[0].pageY - yOffset;
    } else {
      initialX = e.pageX - xOffset;
      initialY = e.pageY - yOffset;
    }

    if (dragItem.contains(e.target)) {
      active = true;
    }
  }

  function dragEnd() {
    initialX = currentX;
    initialY = currentY;

    active = false;
  }

  function drag(e) {
    if (active) {
      e.preventDefault();

      let pageX = e.pageX;
      let pageY = e.pageY;
      if (e.type === 'touchmove') {
        pageX = e.touches[0].pageX;
        pageY = e.touches[0].pageY;
      }

      currentX = Math.min(
        Math.max(0, pageX - initialX),
        window.innerWidth - dragItem.clientWidth,
      );
      currentY = Math.min(
        Math.max(0, pageY - initialY),
        window.innerHeight - dragItem.clientHeight - (DEFAULT_Y_POS * DEFAULT_Y_POS),
      );

      setTranslate(currentX, currentY);
    }
  }

  function setTranslate(x, y) {
    xOffset = x;
    yOffset = y;
    dragItem.style.left = `${x}px`;
    dragItem.style.top = `${y}px`;
    dragItem.style.right = 'auto';
    dragItem.style.position = 'absolute';
  }

  function listeners(isRegistering) {
    const type = isRegistering ? 'add' : 'remove';

    container[`${type}EventListener`]('touchstart', dragStart, false);
    container[`${type}EventListener`]('touchend', dragEnd, false);
    container[`${type}EventListener`]('touchmove', drag, false);

    container[`${type}EventListener`]('mousedown', dragStart, false);
    container[`${type}EventListener`]('mouseup', dragEnd, false);
    container[`${type}EventListener`]('mousemove', drag, false);
  }

  listeners(true);
  setTranslate(xPos, yPos, dragItem);

  return {
    setTranslate,
    listeners,
  };
};
