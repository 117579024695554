import get from 'lodash/get';
import { audioNotify, Events, notify, socketConnection } from '@/libs';
import { AGENT_EVENT } from '@/constants/analytics';
import * as log from 'loglevel';

function sendNotification(conversation, title, message, tag, includeAudio, clickToClear) {
  notify(title, message, tag, clickToClear)
    .then(notification => {
      if (includeAudio) {
        audioNotify();
      }
      notification.onclick = () => {
        const params = [
          conversation.id,
          conversation.customer,
        ];
        Events.emit('select.conversation', params);
        notification.close();
        window.focus();
      };
    })
    .catch(err => {
      log.info(`Notification permission blocked by user or incognito (${err}).`);
    });
}

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Send browser notification.
     *
     * The payload structure is expected to have
     * @param payload Refer the below.
     * {
     *  title(required): string
     *  body(required): string
     *  tag(required): string
     *  event(required): string
     *  conversation(required): object
     *  agents(required): array of agent
     *  message(optional): object
     * }
     */
    sendBrowserNotification({ commit, rootState, dispatch }, payload) {
      const { title, body, tag, agents, message, conversation, includeAudio, clickToClear, isMessageEvent } = payload; // eslint-disable-line max-len
      const agent = get(rootState, 'agent.profile');
      const windowActive = isMessageEvent && get(rootState, 'agent.windowActive', false);
      if (!title || !body || !agents || !agent) {
        log.warn('sendBrowserNotification(): invalid payload');
        log.debug(`payload: title:${title} body:${body} tag:${tag} agents:${agents} agent:${agent}`);
        return;
      }

      // DO not send notification
      // when our app is active & a message event,
      // or when agent is not in room.
      if (windowActive || !agents.find(a => a.id === agent.id)) {
        log.info('sendBrowserNotification(): return because window active or agent not in room, ' +
          `win:${windowActive} agent:${agent.id} in-room:${agents.map(a => a.id)}`);
        return;
      }

      if (message) {
        const lockedBy = get(conversation, 'locked_by', null);
        const lockedBySomeoneElse = lockedBy && (lockedBy.id !== agent.id);
        // Do not send when conversation
        // is locked to another agent.
        if (lockedBySomeoneElse) {
          log.info(`sendBrowserNotification(): return because conversation locked by someone else, locked by id: ${lockedBy.id}`);
          return;
        }
      }

      const convId = get(conversation, 'id');
      const customerId = get(conversation, 'customer_id');
      // For now set true on every message.
      commit('conversations/UPDATE_NEW_CONVERSATION_INDICATOR', true, { root: true });
      if (convId && customerId) {
        sendNotification({ id: convId, customer: { id: customerId } },
          title, body, tag, includeAudio, clickToClear);

        // Send to analytics
        dispatch('notifications/emitEventToSocket', {
          name: AGENT_EVENT.BROWSER_NOTIFICATION,
          payload: {
            ...payload,
            agent,
            userAgent: navigator.userAgent,
          },
        }, { root: true });
      }
    },

    // single place to capture socket emit event
    emitEventToSocket(dumny, { name, payload }) {
      socketConnection.emit(name, payload);
    },
  },
};
