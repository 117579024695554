<template lang="pug">
div
  aiq-dialog.modal-editor(:title="getTitle",
                          v-model="modalShown"
                          :show-close="false"
                          @closed="navigateAway"
                          :close-on-click-modal="true"
                          :close-on-press-escape="true"
                          :before-close="handleBeforeClose")
    slot(name="content")

    template(v-if="useDefaultFooter"
            v-slot:footer)
      p.required-notice * Required
      span.spinner
        font-awesome-icon(v-if="isBusy"
                              size="lg"
                              icon="spinner"
                              spin)

      slot(name="delete-button")
      aiq-button.button-delete(v-if="!$slots['delete-button'] && canDeleteRecord"
                                type="danger"
                                :plain="true"
                                :disabled="isBusy"
                                @click="deleteRecord") Delete
      aiq-button(@click="closeEditor") {{ cancelButtonText }}
      aiq-button(v-if="showSaveButton && !showNextButton"
                :disabled="!canSaveRecord || isBusy"
                type="primary"
                @click="saveRecord") {{ saveButtonText }}
      aiq-button(v-if="showNextButton"
                 :disabled="!isProfileValid"
                 type="primary"
                 @click="nextPage") {{ nextButtonText }}

</template>

<script>
import * as log from 'loglevel';
import get from 'lodash/get';
import { extractErrorMessage, confirmBeforeDialogClose } from '@/libs';
import { STATUS_CODES } from '@/constants';

const TAG = 'ModalEditor';

export default {
  name: 'modalEditor',
  computed: {
    getName() {
      return this.recordTypeName || this.recordType;
    },
    getTitle() {
      if (this.title) {
        return this.title;
      }

      let action = 'New';
      if (this.isEditMode) {
        if (this.disabled) {
          action = 'View';
        } else {
          action = 'Edit';
        }
      }

      return `${action} ${this.getName}`;
    },
    canDeleteRecord() {
      return this.canDeleteItem && this.buttons.includes('delete') && this.record.id;
    },
    isCreateMode() {
      return !this.record.id;
    },
    isEditMode() {
      return Boolean(this.record.id);
    },
    canSaveRecord() {
      return this.isRecordValid && this.buttons.includes('save');
    },
  },
  watch: {
    record(value) {
      // We only want to set this once when the record is set.
      // Not all data is available on modal create.
      if (!this.originalRecord) {
        this.originalRecord = { ...value };
      }
    },
    visible(value) {
      this.modalShown = value;
    },
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: () => ({}),
    },
    recordType: {
      type: String,
    },
    recordTypeName: String,
    isProfileValid: {
      type: Boolean,
      default: false,
    },
    isRecordValid: {
      type: Boolean,
      default: () => false,
    },
    resource: {
      type: String,
    },
    buttons: {
      type: Array,
      default: ['close'],
    },
    preparePayload: {
      type: Function,
      default: (record) => record,
    },
    onError: {
      type: Function,
      default: null,
    },
    afterSave: {
      type: Function,
      default: () => (newRecord) => newRecord,
    },
    afterClose: {
      type: Function,
      default: () => () => {},
    },
    title: {
      type: String,
    },
    showSaveButton: {
      type: Boolean,
      default: true,
    },
    showNextButton: {
      type: Boolean,
      default: false,
    },
    saveButtonText: {
      type: String,
      default: 'Save',
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },
    nextButtonText: {
      type: String,
      default: 'Next',
    },
    useDefaultFooter: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canDeleteItem: {
      type: Boolean,
      default: true,
    },
    skipConfirmation: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      modalShown: false,
      isBusy: false,
    };
  },
  emits: ['close', 'next', 'afterDelete'],
  methods: {
    closeEditor() {
      this.modalShown = false;
      this.$emit('close');
    },
    navigateAway() {
      // editor is contained by a route
      const id = get(this, '$route.params.id');
      if (id && this.$route.matched.length > 1) {
        // get the parent path
        const currentPath = this.$route.path;
        const parentPath = currentPath.substring(0, currentPath.lastIndexOf('/'));
        // get parant name if exist
        const name = get(this.$route, 'meta.parentName');
        // move to the parent
        this.$router.push(name ? {name} : parentPath);
      }
      this.afterClose();
    },
    buildAction(record = this.record, recordType = this.recordType, method) {
      const namespace = `${recordType.toLowerCase()}s`;

      if (!method) {
        method = record.id ? 'update' : 'create';
      }

      const action = `${method}${recordType}`;
      return `${namespace}/${action}`;
    },
    nextPage() {
      this.$emit('next');
    },
    saveRecord() {
      this.isBusy = true;
      const action = this.buildAction(this.record, this.recordType);
      const payload = this.preparePayload(this.record);
      log.debug(TAG, 'saving record:', action, payload);
      this.$store.dispatch(action, [payload, 'data'])
        .then((response) => {
          const savedRecord = { ...this.record, ...response.data };
          this.afterSave(savedRecord, this.originalRecord);
        })
        .then(() => {
          this.$aiq.notify.success(
            `${this.getName} has been ${this.record.id ? 'updated' : 'created'}.`,
          );
          this.closeEditor();
        })
        .catch(err => {
          const shouldDisplayError = this.onError && this.onError(err);
          !shouldDisplayError && this.displayErrorMsg(err);
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    displayErrorMsg(err) {
      const message = extractErrorMessage(err);
      switch (err.status) {
        case STATUS_CODES.BAD_REQUEST:
        case STATUS_CODES.NOT_ACCEPTABLE:
        case STATUS_CODES.UNSUPPORTED_MEDIA_TYPE:
          this.$aiq.notify.error(message || 'Unsupported media type.');
          break;
        default:
          this.$aiq.notify.error(message || `${this.record.id ? 'Update' : 'Create'} failed.`);
          break;
      }
    },
    deleteRecord() {
      this.$aiq.confirm(
        'Delete?',
        `Are you sure you want to delete this ${this.getName}?`,
      ).then(
        () => {
          this.isBusy = true;
          const action = this.buildAction(this.record, this.recordType, 'delete');
          return this.$store.dispatch(action, this.record.id)
            .then(() => {
              this.$aiq.notify.success(`${this.getName} has been deleted.`);
              this.$emit('afterDelete', this.record);
              this.closeEditor();
            })
            .catch(err => {
              this.$aiq.notify.error(extractErrorMessage(err) || 'Delete failed.');
            })
            .finally(() => {
              this.isBusy = false;
            });
        },
        () => { },
      );
    },
    handleBeforeClose() {
      if (this.skipConfirmation) {
        this.closeEditor();
        return;
      }

      confirmBeforeDialogClose(this, this.closeEditor);
    },
  },
};
</script>


<style lang="scss">
@import "../../styles/media-queries.scss";
@import "../../styles/aiq-mixins.scss";

$small-button-padding-top: 10px;
.modal-editor.el-dialog {
  margin-top: 5vh !important;
  min-width: 520px;
  @include mobile {
    min-width: 90%;
  }

  .field-container.img-uploader-container {
    position: absolute;
    width: 130px;
  }

  .field-container.top-right {
    margin-left: calc(50% + 4px);
  }

  .el-dialog__body {
    padding-bottom: 0;

    .el-input,
    .el-input-number,
    .el-select,
    .el-textarea {
      margin-top: $small-button-padding-top;
      margin-bottom: 14px;
    }

    .el-input-number {
      display: inherit;
    }

    .el-tabs__content {
      padding: 0 4px 0 0;
    }

    .field-container {
      flex: 1;

      &-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .field-container ~ .field-container {
          margin-left: 16px;
        }
      }

      &.full-width {
        width: 100%;
      }
      label {
        color: #475669;
        font-size: 14px;
        font-weight: 100;

      }
      .is-disabled {

        .el-input__inner {
          color: #475669;
        }
      }
      &.is-required {

        label {

          &::before {
            margin-right: 5px;
            content: "*";
            color: #FF0000;
          }
        }
      }
    }
  }
  .el-dialog__footer {

    p {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 8px;
      color: #FF0000;
      font-size: 14px;
      font-weight: 100;
    }
  }
  .img-uploader-container {
    margin-bottom: 10px;
    color: #475669;
    font-size: 14px;
    font-weight: 100;

    label {
      display: block;
      margin-bottom: 10px;
      color: #475669;
      font-size: 14px;
      font-weight: 100;
      text-align: left;
    }
  }
  .img-uploader {
    width: 129px;
    height: 101px;
    border-radius: 4px;
    border: 1px dashed #BFCBD9;
    background-color: #F9FAFC;
    overflow: hidden;

    &:hover {
      border-color: #20a0ff;
    }

    .el-upload {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
  .img-uploader-add {
    position: relative;
    width: 100%;
    height: 100%;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 28px;
      height: 2px;
      content: '';
      border-radius: 2px;
      transform: translate(-50%, -50%);
      background-color: #BFCBD9;
    }
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 28px;
      height: 2px;
      content: '';
      border-radius: 2px;
      transform: translate(-50%, -50%) rotate(90deg);
      background-color: #BFCBD9;
    }
  }
  .img-uploader-preview {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
  }
}
</style>

<style lang="scss" scoped>
  .spinner {
    padding-right: 0.5em;
  }

// .required-notice {
//   color: #BFCBD9;
// }
</style>
