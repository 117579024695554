import has from 'lodash/has';
import * as log from 'loglevel';

export default {
  namespaced: true,
  /*
   * We have flags statically but it can be fetched remotely
   * through actions in future
   */
  state: {
    FEATURE_FLAGS: {
      ...process.env.FEATURE_FLAGS,
    },
  },
  mutations: {
    UPDATE_FLAG(state, { name, value }) {
      log.info('UPDATE_FLAG', name, value, has(state.FEATURE_FLAGS, name));
      if (has(state.FEATURE_FLAGS, name)) {
        state.FEATURE_FLAGS[name] = value;
        log.warn(`feature flag ${name} is now ${value}`);
      }
    },
  },
  actions: {
    // Update feature flags dynamically
    updateFlag({ commit }, { name, value }) {
      commit('UPDATE_FLAG', { name, value });
    },
  },
};
