<template lang="pug">
router-view
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>

</style>
