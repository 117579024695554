<template lang="pug">
.manage-template
  .header
    h1.title {{ $t('settings_tab.admin_tab.message_template.title') }}
  aiq-collapse.scrollbar__collapse(accordion)
    aiq-collapse-item(:title="$t('settings_tab.admin_tab.message_template.message_variable')")
     template-variable(@update="SaveTemplate" @delete="RemoveTemplate" :variables="messageTemplate.template_variables")
    aiq-collapse-item(:title="$t('settings_tab.admin_tab.message_template.title')")
     message-template(:templates="messageTemplate.message_templates || []" @update="SaveSmsAndWebChatTemplate")
     
</template>

<script>
import TemplateVariable from './TemplateVariable.vue';
import MessageTemplate from './MessageTempate.vue';
import { mapState } from 'vuex';

const localForm = { sms_templates: [], template_variables: [], web_chat_templates: [] };

export default {
  name: 'manage-template',
  components: {
    TemplateVariable,
    MessageTemplate,
  },
  computed: {
    ...mapState({
      messageTemplate: state => state.configs.messageTemplate || localForm,
    })
  },
  async mounted() {
    await this.$store.dispatch('configs/getMessageTemplateConfig');
  },
  methods: {

    async onSave(message = this.$t('settings_tab.admin_tab.message_template.saved')){
      try {
        await this.$store.dispatch('configs/updateMessageTemplateConfig', this.messageTemplate);
        this.$aiq.notify.success(message);
      } catch (err){
        this.$aiq.notify.error(err.message);
      }
    },

    RemoveTemplate(name){
      this.messageTemplate.template_variables = this.messageTemplate.template_variables.filter((template) => template.name !== name);
      this.onSave(this.$t('settings_tab.admin_tab.message_template.message_var_deleted'));
    },
    SaveSmsAndWebChatTemplate(payload, message) {
      this.messageTemplate.message_templates = payload;
      this.onSave(message);
    },
    SaveTemplate(payload) {
      this.messageTemplate.template_variables = payload;
      return this.onSave(this.$t('settings_tab.admin_tab.message_template.message_var_saved'));
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../styles/aiq-mixins.scss";
@import "../../../../../styles/aiq-extensions.scss";

.scrollbar__collapse {
  @include scrollable(calc(100vh - 204px));
}
</style>