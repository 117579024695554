<template lang="pug">
  .items-list_item
    .item-name(@click="!editMode && toggleEditMode(true)")
      span.item-name_text(v-if="!editMode", :title="item.name")| {{ item.name }}
      FilteredInput(v-else
                type="text", 
                size="small",
                :max-length="25"
                v-model="tempItem.name", 
                ref="input", 
                @keydown.enter="save", 
                @blur="toggleEditMode(false)")
    .item-keywords
      aiq-keyword-input(:list="item.keywords"
                        @createItem="addKeyword"
                        @deleteItem="deleteKeyword"
                        :deletable="!isSystemKeyword"
                        :itemMaxLength="isSystemKeyword ? 0 : keywordMaxLength")
    .item-actions(v-if="!isSystemKeyword")
      aiq-button(link type="primary" @click="deleteItem") Archive

</template>

<script>
export default {
  name: 'itemWithKeywordListItem',
  data() {
    return {
      editMode: false,
      tempItem: { ...this.item },
      keywordMaxLength: process.env.INPUT_PARAMS.keywordMaxLength,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isSystemKeyword: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    'save',
    'deleteKeyword',
    'addKeyword',
    'delete',
  ],
  methods: {
    toggleEditMode(value = !this.editMode) {
      if (this.isSystemKeyword) return;

      this.editMode = value;
      if (value) {
        this.tempItem = { ...this.item };
      }

      this.$nextTick(() => {
        if (!this.editMode || !this.$refs.input) {
          return;
        }
        this.$refs.input.$el.querySelector('INPUT').focus();
      });
    },

    save() {
      this.$emit('save', this.tempItem);
      this.toggleEditMode(false);
    },

    cancel() {
      this.toggleEditMode(false);
      this.tempItem = { ...this.item };
    },

    deleteKeyword(keyword) {
      this.$emit('deleteKeyword', [this.item, keyword]);
    },

    addKeyword(keyword) {
      this.$emit('addKeyword', [this.item, keyword]);
    },

    deleteItem() {
      this.$emit('delete', this.item);
    },
  },
};
</script>

<style lang="scss" scoped>
  .items-list_item {
    display: flex;
    border-bottom: 1px solid #E0E6ED;

    > div {
      display: flex;
      align-items: center;
      min-height: 40px;
      padding-right: 10px;
      padding-left: 10px;
    }

    &:hover {
      background-color: #ECF3FF;

      .item-actions {
        opacity: 1;
      }
    }
  }
  .item-name {
    width: 250px;
    color: #324057;
    font-size: 12px;
    line-height: 14px;
    font-weight: 300;

    &_text {
      display: inline-block;
      width: 100%;
      height: 22px;
      padding: 3px 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
      border: 1px solid transparent;
      overflow: hidden;

      &:hover {
        border-color: #BFCBD9;
        border-radius: 4px;
        background-color: #fff;
        cursor: text;
      }
    }
  }

  .item-keywords {
    flex: 1 0 0%;

    .keyword-input {
      width: 100%;
      border: 0;
    }
  }

  .item-actions {
    width: 80px;
    justify-content: center;
    opacity: 0;
  }
</style>
