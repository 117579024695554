<template lang="pug">
.close-conversation-configuration(v-if="policyConfig")
  .sub-container(v-if="false")
    .sub-container-header {{ $t('settings_tab.channels_tab.webchat_configuration.close_coversation.nightly.title') }}
    .sub-container-body
      .config-item(v-for="configItem of nightlyConfigs")
        aiq-row.padding-bottom-5
          aiq-col(:span="6")
            label {{ $t(`settings_tab.channels_tab.webchat_configuration.close_coversation.config.name`) }}
          aiq-col(:span="18")
            p {{ $t(`settings_tab.channels_tab.webchat_configuration.close_coversation.nightly.${configItem.type}.title`) }}

        aiq-row.padding-bottom-5
          aiq-col(:span="6")
            label {{ $t(`settings_tab.channels_tab.webchat_configuration.close_coversation.config.description`) }}
          aiq-col(:span="18")
            p {{ $t(`settings_tab.channels_tab.webchat_configuration.close_coversation.nightly.${configItem.type}.description`) }}

        aiq-row.padding-bottom-5
          aiq-col(:span="6")
            label {{ $t(`settings_tab.channels_tab.webchat_configuration.close_coversation.config.enabled`) }}
          aiq-col(:span="18")
            aiq-switch(:model-value="configItem.enabled"
                      active-color="#80C28B"
                      inactive-color="#C0CCDA"
                      disabled
                      @change="onValueChange('nightly', configItem.type, 'enabled', $event)")

        aiq-row.padding-bottom-5
          aiq-col(:span="6")
            label {{ $t(`settings_tab.channels_tab.webchat_configuration.close_coversation.nightly.${configItem.type}.age_seconds_label`) }}
          aiq-col(:span="18")
            aiq-input(:model-value="getMinute(configItem.age_seconds)"
                      type="number"
                      disabled
                      @update:modelValue="onValueChange('nightly', configItem.type, 'age_seconds', $event)")

        aiq-row.padding-bottom-5
          aiq-col(:span="6")
            label {{ $t(`settings_tab.channels_tab.webchat_configuration.close_coversation.config.segments`) }}
          aiq-col(:span="18")
            aiq-select(v-model="configItem.segments"
                      size="small"
                      multiple
                      disabled
                      @change="onValueChange('nightly', configItem.type, 'segments', $event)"
                      name="segments"
                      :reserve-keyword="false")
              aiq-option(v-for="segment in segments"
                        :key="segment"
                        :label="segment"
                        :value="segment")

  .sub-container
    .sub-container-header(v-if="false") Other Conversation Closing Configurations
    .sub-container-body
      .config-item(v-for="configItem of minutelyConfigs")
        aiq-row.padding-bottom-5
          aiq-col(:span="6")
            label {{ $t(`settings_tab.channels_tab.webchat_configuration.close_coversation.config.name`) }}
          aiq-col(:span="18")
            p {{ $t(`settings_tab.channels_tab.webchat_configuration.close_coversation.minutely.${configItem.type}.title`) }}

        aiq-row.padding-bottom-5
          aiq-col(:span="6")
            label {{ $t(`settings_tab.channels_tab.webchat_configuration.close_coversation.config.description`) }}
          aiq-col(:span="18")
            p {{ $t(`settings_tab.channels_tab.webchat_configuration.close_coversation.minutely.${configItem.type}.description`) }}

        aiq-row.padding-bottom-5
          aiq-col(:span="6")
            label {{ $t(`settings_tab.channels_tab.webchat_configuration.close_coversation.config.enabled`) }}
          aiq-col(:span="18")
            aiq-switch(:model-value="configItem.enabled"
                      active-color="#80C28B"
                      inactive-color="#C0CCDA"
                      @change="onValueChange('minutely', configItem.type, 'enabled', $event)")

        aiq-row.padding-bottom-5
          aiq-col(:span="6")
            label {{ $t(`settings_tab.channels_tab.webchat_configuration.close_coversation.minutely.${configItem.type}.age_seconds_label`) }}
          aiq-col(:span="18")
            aiq-input(:model-value="getMinute(configItem.age_seconds)"
                      type="number"
                      @update:modelValue="onValueChange('minutely', configItem.type, 'age_seconds', $event)")

        aiq-row.padding-bottom-5
          aiq-col(:span="6")
            label {{ $t(`settings_tab.channels_tab.webchat_configuration.close_coversation.config.segments`) }}
          aiq-col(:span="18")
            aiq-select(v-model="configItem.segments"
                      size="small"
                      multiple
                      @change="onValueChange('minutely', configItem.type, 'segments', $event)"
                      name="segments"
                      :reserve-keyword="false")
              aiq-option(v-for="segment in segments"
                        :key="segment"
                        :label="segment"
                        :value="segment")


</template>

<script>
import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import * as log from 'loglevel';

  const ONE_MINUTE = 60; // eslint-disable-line

export default {
  name: 'CloseConversationConfiguration',
  props: {
    policyConfig: {
      type: Object,
      default: null,
    },
    segments: {
      type: Array,
      default: [],
    },
  },
  computed: {
    nightlyConfigs() {
      return get(this.policyConfig, 'nightly', []);
    },

    minutelyConfigs() {
      return get(this.policyConfig, 'minutely', []);
    },
  },
  methods: {
    onValueChange(bucket, type, path, val) {
      const newConfig = cloneDeep(this.policyConfig);
      const innerConfig = newConfig[bucket].find(item => item.type === type);

      //  age_seconds from aiq-input(type="number") still returns string
      if (path === 'age_seconds') {
        val = Number(val) * ONE_MINUTE;
      }

      if (!innerConfig) {
        log.warn(`Unable to find item to update with ${bucket} ${type} ${path} ${val} ${newConfig}`);
        return;
      }

      set(innerConfig, path, val);
      this.$emit('update', newConfig);
    },
    getMinute(secs) {
      return Math.round(secs / ONE_MINUTE);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../../../../styles/aiq-variables.scss";
@import "./../../../../../styles/aiq-mixins.scss";

.config-item {
  border: 1px solid #E0E6ED;
  padding: 10px;
  margin-bottom: 10px;
}

.sub-container-header {
  color: #475669;
  letter-spacing: 0;
  font-family: "museo sans", "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 300;
}

.padding-bottom-5 {
  padding-bottom: 10px;
}
</style>
