<template lang="pug">
  .feedback-form-wrapper
    .feedback-form
      .form-legend {{ title }}
      .feedback-form-input-container
          input(placeholder="Name*")
      .feedback-form-input-container
          input(placeholder="Email*")
      .feedback-form-input-container.feedback-form-textarea-container
          .aiq-message(contenteditable="true"
                      placeholder="Content")
      .feedback-form-hours {{ liveHoursText }}
      .feedback-form-actions
          button.send-email(type="submit") Send Email
          .feedback-form-copyright Powered by Agent IQ
</template>

<script>
export default {
  name: 'feedback-form',
  props: {
    liveHoursText: {
      type: String,
    },
    title: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/aiq-variables.scss";

// using mixins because extensions don't seem to be working in these scoped instances
@mixin placeholder__text() {
  color: #BFCBD9;
  font-family: $aiq-font-family;
  font-size: 14px;
  font-weight: 100;
}

input,
textarea {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
}

[contenteditable=true]:empty:before {
  color: #BFCBD9;
  content: attr(placeholder);
  display: block;
  font-family: museo sans;
  font-size: 14px;
  font-weight: 100;
}

.feedback-form-wrapper {
  background-color: #FFFFFF;
  border: 1px solid #D3DCE6;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  min-height: 405px;
  position: relative;
  width: 351px;
}

.feedback-form {
  position: relative;
  top: 0;
  border-radius: 7px;
  background: #fff;
  z-index: 10;
}

.form-legend {
  background-color: #4B6180;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 300;
  height: 66px;
  letter-spacing: 0px;
  line-height: 18px;
  margin-bottom: 15px;
  padding: 15px 16px;
  position: relative;
  text-align: left;
}

.feedback-form-input-container {
    display: flex;
    align-items: center;
    height: 42px;
    margin: 10px 15px;
    border-radius: 4px;
    background-color: #f2f6f9;
    transition: box-shadow .15s;
    input {
        width: 100%;
        height: 18px;
        max-height: 18px;
        padding: 0 10px;
        font-size: 15px;
        line-height: 18px;
        border: 0;
        outline: 0;
        background-color: transparent;
        overflow: hidden;
        box-sizing: border-box;
        &::-webkit-input-placeholder {
          @include placeholder__text();
          opacity: 1;
        }
        &::-moz-placeholder {
          @include placeholder__text();
          opacity: 1;
        }
        &:-ms-input-placeholder {
          @include placeholder__text();
          opacity: 1;
        }
        &:-moz-placeholder {
          @include placeholder__text();
        }
    }
    div {
        width: 100%;
        height: 120px;
        padding: 10px 10px 0;
        font-size: 15px;
        line-height: 18px;
        word-break: break-all;
        border: 0;
        background-color: transparent;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        outline: 0;
        box-sizing: border-box;
        &::-webkit-scrollbar {
            width: 4px;
        }
        &:hover {
            &::-webkit-scrollbar-track {
                background-color: rgba(85, 85, 85, .1);
            }
            &::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, .2);
            }
        }
        &::-webkit-scrollbar-track {
            background-color: transparent;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: transparent
        }
    }

    .placeholder {
        color: #757575;
    }
}
.feedback-form-textarea-container {
    height: 120px;
}
.feedback-form-hours {
  color: #67737F;
    font-size: 12px;
    font-weight: 100;
    letter-spacing: 0px;
    line-height: 14px;
    padding: 0 15px;
}

.send-email {
    float: right;
    min-width: 102px;
    height: 36px;
    padding: 0;
    margin: 10px 10px 5px 0;
    color: #fff;
    font-size: 14px;
    line-height: 36px;
    border: 0;
    border-radius: 4px;
    background-color: #5993FF;
    outline: 0;
    cursor: pointer;
}

.send-email[disabled] {
    color: #a0a0a0;
    font-size: 14px;
    background-color: #ccc;
}

.close-form {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 10px;
    height: 10px;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    background-color: transparent;
    cursor: pointer;
}

.feedback-form-copyright {
    padding-right: 10px;
    color: #ccc;
    font-size: 10px;
    text-align: right;
    clear: both;
}
</style>
