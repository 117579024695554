<template lang="pug">
  modal-editor.edit-team-modal(:visible="visible"
                                :show-close="false"
                                recordType="Quickresponse"
                                :recordTypeName="$t('settings_tab.admin_tab.quick_response_tab.record_name')"
                                :record="record"
                                :buttons="['close', 'delete', 'save']"
                                :preparePayload="preparePayload"
                                :isRecordValid="isSubmittable"
                                :skipConfirmation="!isRecordDirty"
                                ref="modalEditor")
    template(v-slot:content v-if="visible")
      .card-title-container
        .field-container.is-required
          label {{ $t('settings_tab.admin_tab.quick_response_tab.fields.title.title') }}
          aiq-input.title_input(size="small"
                                v-model="record.title"
                                :placeholder="$t('settings_tab.admin_tab.quick_response_tab.fields.title.placeholder') "
                                name="title"
                                )
        .field-container.is-required
          label {{ $t('settings_tab.admin_tab.quick_response_tab.fields.content.title') }}
          aiq-input.description_input(size="small"
                                      v-model="record.content"
                                      type="textarea"
                                      :placeholder="$t('settings_tab.admin_tab.quick_response_tab.fields.content.placeholder') "
                                      name="description"
                                      )
        aiq-tooltip(effect="dark", placement="left", :content="$t('settings_tab.admin_tab.quick_response_tab.fields.teams.tooltip')", :show-after="500")
          .field-container.full-width
            label {{ $t('settings_tab.admin_tab.quick_response_tab.fields.teams.title') }}
            .team
              aiq-select(v-model="record.teams"
                        size="small"
                        :suffix-icon="Icon.CaretBottom"
                        multiple
                        value-key="id"
                        name="teams"
                        :reserve-keyword="false")
                aiq-option(v-for="item in teamOptions"
                          :key="item.id"
                          :label="item.name"
                          :value="item")
        aiq-tooltip(effect="dark", placement="left", :content="$t('settings_tab.admin_tab.quick_response_tab.fields.pin.tooltip')", :show-after="500")
          .field-container.full-width
            label {{ $t('settings_tab.admin_tab.quick_response_tab.fields.pin.title') }}
            aiq-switch.pin-control(v-model="record.is_pinned")
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import get from 'lodash/get';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Editor from '@/components/Editor/Editor.vue';

export default {
  name: 'QuickResponseEditor',
  extends: Editor,
  setup () {
    return { v$: useVuelidate() };
  },
  validations () {
    return {
      record: {
        title: { required, $autoDirty: true },
        content: { required, $autoDirty: true },
        teams: { $autoDirty: true},
        is_pinned: { $autoDirty: true },
      },
    };
  },
  computed: {
    ...mapState({
      agentProfile: state => state.agent.profile,
      selectedDocument: state => state.documents.selected,
    }),
    ...mapGetters({
      sortedTeams: 'teams/sortedTeams',
    }),
    teamOptions() {
      return this.canView('/users/filters/show_all_teams') ? this.sortedTeams : this.agentProfile.teams;
    },
  },
  data() {
    return {
      modelPath: 'quickresponses/quickresponseModel',
      selectAction: 'quickresponses/selectQuickresponse',
      fetchAction: 'quickresponses/getQuickresponse',
    };
  },
  methods: {
    preparePayload(record) {
      return {
        ...record,
        teams: get(record, 'teams', []).map(x => x.id),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.pin-control {
  text-align: center;
  width: 40%;
}
</style>
