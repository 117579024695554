import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import trim from 'lodash/trim';

import { KEYBOARD_KEYS } from '@/constants';

import { h } from 'vue';

export default {
  name: 'intents-wordset-select',
  props: {
    alias: {
      type: String,
      required: true,
    },
    nonAliasWords: {
      type: Array,
      default() {
        return [];
      },
    },
    startIndex: {
      type: Number,
      required: true,
    },
    taggedEntityIndex: Number,
  },
  emits: ['addWord', 'deleteWord', 'closeWordsetConfig', 'deleteTaggedEntity'],
  data() {
    return {
      newWord: '',
    };
  },
  methods: {
    addWord() {
      const temporalWord = trim(this.newWord);
      const isNewWord = this.alias === temporalWord || this.nonAliasWords.includes(temporalWord);
      if (!(isNewWord || isEmpty(temporalWord))) {
        const info = {
          word: this.newWord,
          startIndex: this.startIndex,
        };
        this.$emit('addWord', info);
        this.taggedEntityIndex >= 0 && this.$emit('deleteTaggedEntity', this.taggedEntityIndex);
      }
      this.newWord = '';
    },

    closeWordsetConfig() {
      this.$emit('closeWordsetConfig');
    },

    deleteWord(word) {
      const info = {
        word,
        startIndex: this.startIndex,
      };
      this.$emit('deleteWord', info);
    },
  },
  render() {
    return h(
      'div',
      {
        class: 'wordset-container',
        directives: [{
          name: 'on-clickaway',
          value: this.closeWordsetConfig,
        }],
      },
      [
        h(
          'div',
          {
            class: 'wordset-header',
          },
          [
            h('h4', { class: 'wordset-title' }, 'Wordset'),
          ],
        ),
        h('aiq-tag', null, this.alias),
        [...map(this.nonAliasWords, e => h(
          'aiq-tag',
          {
            props: {
              closable: true,
              closeTransition: true,
            },
            on: {
              close: () => {
                this.deleteWord(e);
              },
            },
          },
          [e],
        ))],
        h('div',
          {
            class: 'wordset-input-container',
          },
          [
            h('input', {
              domProps: {
                placeholder: 'Enter Synonym',
                value: this.newWord,
              },
              on: {
                input: (event) => {
                  this.newWord = event.target.value;
                  // TODO(Gabe) Evaluate if needed
                  // this.$emit("input", event.target.value);
                },
                keyup: e => {
                  if (e.key !== KEYBOARD_KEYS.ENTER) return;
                  this.addWord();
                },
              },
            }),
          ]),
      ],
    );
  },
};
