<template lang="pug">
  .documents-container
    .documents-list(ref="documents")
      div.drag(v-for="item in items"
          :transfer-data="{item, type: 'document'}"
          :key="item.id"
          class="document-item"
          @click="appendDocument(item)")
        document(:document="item")
</template>

<script>
import Document from './Document/Document.vue';

export default {
  components: {
    Document,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['itemClicked'],
  methods: {
    appendDocument(document) {
      this.$emit('itemClicked', {
        type: 'documents',
        item: document,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .el-input {
    margin: 8px 0 12px;
  }
  .documents-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .document-item {
    position: relative;
    margin-bottom: 12px;
    border-radius: 4px;
    box-shadow: 0 0 3px 0 #bfcbd9;
    background-color: #fff;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 3px 2px #bfcbd9;
    }
    &:active {
      box-shadow: none;
    }

    &_title {
      padding: 10px;
      color: #48576A;
      font-size: 11px;
      line-height: 13px;
      font-weight: 300;
    }
  }
</style>
