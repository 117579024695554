import get from 'lodash/get';
import last from 'lodash/last';

import axios from 'axios';
import {
  breakUpEntityMessage,
  cleanAiState,
  displayDialogState,
  displayWorkflowStatus,
  addMessageItem,
  getLastMessageItem,
  getRepliesItem,
} from '@/libs';
import {
  getUrl,
  hosts,
} from '@/config/api.routes';

const baseUrl = hosts.aimanager.replace(/\/+$/, '');
let messageId = 0;

/**
 * generate component state
 * and simulate ai_state depending on the mode
 */
function generateState(context = null, mode = null) {
  let item;
  const result = {
    shouldEndUponWorkflow: false,
    conversationEnded: false,
    items: [],
    entities: [],
    ai_state: {
      Debug: true,
    },
    chat_state: {
      conversation_id: (new Date()).getTime(),
    },
  };
  switch (mode) {
    case 'intents':
      Object.assign(result.chat_state, {
        mode: 'intents',
      });
      break;
    case 'dialogs':
      item = context.rootState.dialogs.selected;
      Object.assign(result.ai_state, {
        dialog_id: item.id,
      });
      Object.assign(result.chat_state, {
        mode: 'dialog',
        id: item.id,
        prompt: item.prompt,
      });
      break;
    case 'workflows':
      item = context.rootState.workflows.selected;
      Object.assign(result.ai_state, {
        workflow_id: item.id,
      });
      Object.assign(result.chat_state, {
        mode: 'workflow',
        id: item.id,
        prompt: item.detect,
      });
      break;
    default:
  }
  return result;
}

/**
 * command parsing
 */
function simulateCommand(context, command) {
  if (get(command, 'payload.message.payload.message_type', null) === 'replies') {
    addMessageItem({
      context, senderType: 'agent', message: command.payload.message.payload, messageType: 'reply',
    });
  } else if (command.name === 'conversations.messages.create') {
    if (!get(command, 'payload.message.payload.content')) {
      const message = `message command is expected to have payload.message.payload.content: ${JSON.stringify(command)}`;
      addMessageItem({
        context, senderType: 'debug', message,
      });
      return;
    }
    // this validates the action_type of command
    if (command.action_type.toUpperCase() === 'SUGGEST') {
      addMessageItem({
        context, senderType: 'agent', message: '',
      });
    } else {
      addMessageItem({
        context, senderType: 'agent', message: command.payload.message.payload, messageType: 'rich_text',
      });
    }
  } else if (command.action_type === 'COMMAND' && get(command, 'payload.custom_name')) {
    const message = `AI-engine is running command ${command.payload.custom_name}`;
    addMessageItem({
      context, senderType: 'debug', message,
    });
  } else if (command.name === 'actions.run') {
    // TODO (Gabe) Set up a command display handler for different function types
  } else if (command.name.match(/conversation.*message/i)) {
    const message = `command misspelled: ${command.name}`;
    addMessageItem({
      context, senderType: 'debug', message,
    });
  } else {
    const message = `command: ${command.name} ${JSON.stringify(command.payload)}`;
    addMessageItem({
      context, senderType: 'debug', message,
    });
  }
}

function isAgentIqEmailAddress(email) {
  const AGENTIQ_EMAIL_SUFFIX = '@agentiq.com';
  if (!email) {
    return false;
  }

  return email.toLowerCase().endsWith(AGENTIQ_EMAIL_SUFFIX);
}

export const mutations = {
  addItem(state, item) {
    // TODO (Gabe) temporary adding list, will refactor after schemas set
    if (['replies', 'agent', 'customer', 'debug', 'system', 'suggestion'].indexOf(item.sender_type) >= 0) {
      // (GABE) temporary check until convert to single aiq-component message
      if (get(item, 'payload.message_type') !== 'replies') {
        item.bubbleClass = `${item.sender_type}-bubble`;
      }
    }

    state.items.push(item);
  },
  addItems(state, items) {
    state.items = items.map((item, index) => {
      if (get(item, 'payload.message_type') !== 'replies') {
        item.bubbleClass = `${item.sender_type}-bubble`;
      }

      if (get(item, 'payload.message_type') === 'reply') {
        let repliesToUpdate = null;
        for (let i = index; i >= 0; i--) { // eslint-disable-line
          if (get(items[i], 'payload.message_type') === 'replies') {
            repliesToUpdate = items[i];
            break;
          }
        }

        if (repliesToUpdate) {
          const reply = repliesToUpdate.payload.replies.attachments
            .find(r => r.reply.content === item.payload.reply.value);
          repliesToUpdate.replyitem = reply.reply;
        }
      }
      return item;
    });
  },
  breakUpEntityMessage(state, info) {
    const inputWithExtractedEntities = last(state.items);
    if (get(inputWithExtractedEntities, 'message')) {
      const message = breakUpEntityMessage(inputWithExtractedEntities.message, info);
      inputWithExtractedEntities.message = message;
    }
  },
  SELECT_AND_UPDATE_REPLIES_SUCCESS(state, reply) {
    const repliesToUpdate = getRepliesItem(state.items);
    repliesToUpdate['replyitem'] = reply.reply;
  },
  SELECT_AND_UPDATE_REPLIES_FAIL(state) {
    const repliesToUpdate = getRepliesItem(state.items);
    repliesToUpdate['replyitem'] = null;
  },
  setAiState(state, aiState) {
    state.ai_state = aiState;
  },
  setEnabledIfLastMessageIsReplyable(state) {
    const message = getLastMessageItem(state.items);
    const messageType = get(message, 'payload.message_type');
    if (['replies', 'carousel'].includes(messageType)) {
      message.disabled = false;
    }
  },
  setShouldEndUponWorkflowEnd(state, val) {
    state.shouldEndUponWorkflow = val;
  },
  setConversationEnd(state, val) {
    state.conversationEnded = val;
  },
};

function generateTracerRequest(ai_state, convId, msgId, payload) {
  return {
    context: {
      ai_state,
      entities: [],
      message_history: [],
    },
    events: [
      {
        conversation: {
          id: convId + 1,
        },
        customer: {},
        message: {
          conversation_id: convId + 1,
          payload,
          sender_type: 'customer',
          sender_id: 1,
          id: msgId + 1,
        },
      },
    ],
  };
}

export default {
  namespaced: true,
  state: generateState(),
  mutations,

  actions: {
    async _handleTracerResponse(context, response) {
      let replies;
      const { data } = response;
      const aiState = data.ai_state;
      const notFoundMessage = 'No intents matched';

      if (aiState) {
        const currentWorkflowId = (aiState.current_workflow_stack)
          ? aiState.current_workflow_stack.slice(-1)[0] : null;
        const dialogId = aiState.dialog_id;

        let workflowName;
        let dialogName;

        if (currentWorkflowId) {
          const workflows = get(context.rootState, 'workflows.workflows', []);
          const workflow = workflows.find(w => w.id === currentWorkflowId);

          if (!workflow) {
            await axios.get(getUrl('aimanager.workflows', currentWorkflowId)).then(data => {
              workflowName = data.data.name;
            });
          } else {
            workflowName = workflow.name;
          }
        }

        if (dialogId) {
          const dialogs = get(context.rootState, 'dialogs.dialogs', []);
          const dialog = dialogs.find(d => d.id === aiState.dialog_id);

          if (!dialog) {
            await axios.get(getUrl('aimanager.dialogs', dialogId)).then(data => {
              dialogName = data.data.name;
            });
          } else {
            dialogName = dialog.name;
          }
        }

        if (aiState.entities[0]) {
          // entities will be cleared at the end
          const info = {
            newEntities: aiState.entities,
            oldEntities: context.state.ai_state.entities || [],
          };
          context.commit('breakUpEntityMessage', info);
        }

        const suggestions = [];
        const responses = [];

        const { current_workflow_stack } = context.state.ai_state;
        const contextCurrentWorkflowId = (current_workflow_stack)
          ? current_workflow_stack.slice(-1)[0] : null;
        const isDifferentWorkflow = currentWorkflowId !== contextCurrentWorkflowId;
        if (currentWorkflowId && isDifferentWorkflow) {
          const debugMessage = `AI-engine started workflow ${workflowName || currentWorkflowId}`;
          addMessageItem({
            context, senderType: 'debug', message: debugMessage,
          });
        }

        // TODO: update Items
        const isDifferentDialog = aiState.dialog_id !== context.state.ai_state.dialog_id;
        if (aiState.dialog_id && isDifferentDialog) {
          const debugMessage = `AI-engine started dialog ${dialogName || aiState.dialog_id}`;
          addMessageItem({
            context, senderType: 'debug', message: debugMessage,
          });
        }

        if (data.commands) {
          data.commands.forEach(command => {
            if (command.action_type === 'SUGGEST') {
              const suggestMessage = command.payload.message;

              if (suggestMessage && suggestMessage.payload && suggestMessage.payload.content) {
                suggestions.push(command.payload.message.payload.content);
              }
            } else if (get(command, 'payload.message.payload.message_type') === 'replies') {
              replies = command;
            } else {
              responses.push(command);
            }
          });
        }

        responses.forEach(command => simulateCommand(context, command));

        // TODO (GABE) These messages need to go right after action above, which
        // can be in the middle of the above responses array
        const dialogState = aiState.dialog_state;
        const workflowStatus = aiState.workflow_status;

        displayDialogState({ context, dialogState, dialogName });
        displayWorkflowStatus({
          context, dialogState, workflowStatus, workflowName,
        });

        let isIntentNewlyMatched = false;
        if (data.debug) {
          let displayMessage = notFoundMessage;
          const isIntentMatchType = data.debug.MatchType
            && data.debug.MatchType !== 'NONE'
            && data.debug.MatchType !== 'DIALOG'
            && data.debug.MatchType !== 'WORKFLOW';

          if (isIntentMatchType) {
            displayMessage = `intent match to '${data.debug.TopIntent}' with score: ${data.debug.Score}.`;
            isIntentNewlyMatched = true;
          }

          const inDialogOrWorkflow = aiState.current_workflow_stack.length > 0
            || aiState.dialog_id;
          if (!(inDialogOrWorkflow && !isIntentNewlyMatched)) {
            addMessageItem({
              context, senderType: 'debug', message: displayMessage,
            });
          }
        }

        if (suggestions.length > 0) {
          const suggestionMessage = {
            message: 'suggestion items',
            suggestions,
            preText: responses.length > 0 ? 'Here are other' : 'Here are',
          };
          addMessageItem({
            context, senderType: 'suggestion', message: suggestionMessage,
          });
        } else if (isIntentNewlyMatched) {
          const suggestionMessage = {
            message: 'AI-Engine has no suggestions',
            suggestions,
          };
          addMessageItem({
            context, senderType: 'debug', message: suggestionMessage,
          });
        }
      } else {
        addMessageItem({
          context, senderType: 'debug', message: notFoundMessage,
        });
      }

      if (replies) {
        simulateCommand(context, replies);
      }

      context.commit('setEnabledIfLastMessageIsReplyable');

      // update Ai State after all processing
      const newAiState = cleanAiState(aiState);
      context.commit('setAiState', newAiState);

      if (context.state.shouldEndUponWorkflow) {
        const remainingWorkflowStack = get(aiState, 'current_workflow_stack', []);
        context.commit('setConversationEnd', remainingWorkflowStack.length === 0);
      }
    },

    sendWorkflow(context, workflow) {
      const payload = { flow: { name: workflow, unit_type: 'workflow' } };
      const conversationId = context.state.chat_state.conversation_id;
      context.commit('setShouldEndUponWorkflowEnd', true);
      const reqData =
        generateTracerRequest(context.state.ai_state, conversationId, messageId, payload);
      return axios.post(`${baseUrl}/tracer`, reqData)
        .then(response => context.dispatch('_handleTracerResponse', response));
    },

    async setWorkflowExample(context, { id }) {
      const { messages } = (await axios.get(`${baseUrl}/workflows/${id}/example`)).data;
      context.commit('addItems', messages);
      context.commit('setConversationEnd', true);
    },

    sendMessage(context, message) {
      if (!message.hideMessage) {
        addMessageItem({
          context, senderType: 'customer', message, messageType: 'rich_text',
        });
      }

      const conversationId = context.state.chat_state.conversation_id;

      let payload;
      if (message.content && message.content.message_type === 'reply') {
        payload = message.content;
        context.commit('SELECT_AND_UPDATE_REPLIES_SUCCESS', payload);
      } else {
        payload = { content: message.content };
      }

      const reqData =
        generateTracerRequest(context.state.ai_state, conversationId, messageId, payload);
      messageId += 1;
      // Add show logs if we are an agentiq employee
      const emailAddress = get(context.rootState, 'agent.profile.email', undefined);
      if (isAgentIqEmailAddress(emailAddress)) {
        reqData.show_logs = true;
      }

      return axios.post(`${baseUrl}/tracer`, reqData)
        .then(response => context.dispatch('_handleTracerResponse', response));
    },

    reset(context, mode) {
      Object.assign(context.state, generateState(context, mode));
    },

    prompt(context, mode) {
      context.dispatch('reset', mode);
      const message = `starting ${JSON.stringify(context.state.chat_state)}`;
      addMessageItem({
        context, senderType: 'debug', message,
      });
      if (context.state.chat_state.prompt) {
        addMessageItem({
          context, senderType: 'agent', message: context.state.chat_state.prompt,
        });
      }
    },
  },
};
