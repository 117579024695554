import { Component, Watch, Vue, toNative } from 'vue-facing-decorator';
import trim from 'lodash/trim';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import { extractErrorMessage } from '@/libs';
import itemWithKeywordListItem from './ItemWithKeywordListItem.vue';
import itemWithKeywordNew from './ItemWithKeywordNew.vue';
import { PAGINATION_DEFAULT } from '@/constants/pagination';

const CHECK_SCROLLBAR_TIMEOUT_MS = 500;
const SEARCH_PAGE_SIZE = 1000;
const UNIQUE_CONSTRAINT_CODE = 406;
const NO_KEYWORD_CODE = 422;

const prepareItem = item => ({
  ...item,
  name: trim(item.name),
});

@Component({
  components: {
    ManagementPage,
    itemWithKeywordListItem,
    itemWithKeywordNew,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    storeNamespace: {
      type: String,
      default: '/settings/',
    },
    isSystemKeyword: {
      type: Function,
      required: false,
      default: (_) => false,
    },
    minimumAllowedKewords: {
      type: Number,
      required: false,
      default: (_) => 1,
    },
  },
  computed: {
    selected() {
      return this.$store.state[this.titleLowerCased].selected;
    },
    loaded() {
      return this.$store.state[this.titleLowerCased][`${this.titleLowerCased}Loaded`];
    },
    items() {
      return this.$store.getters[`${this.titleLowerCased}/${this.titleLowerCased}`];
    },
    modelPath() {
      return `${this.titleLowerCased}/${this.type}Model`;
    },
    getPath() {
      return `${this.titleLowerCased}/get${this.title}List`;
    },
    createPath() {
      return `${this.titleLowerCased}/create${this.typeText}`;
    },
    deletePath() {
      return `${this.titleLowerCased}/delete${this.typeText}`;
    },
    updatePath() {
      return `${this.titleLowerCased}/update${this.typeText}`;
    },
    typeText() {
      return this.type.charAt(0).toUpperCase() + this.type.slice(1);
    },
    titleLowerCased() {
      return this.title.toLowerCase();
    },
  },
})
class KeywordsManagement extends Vue {
  addItemInput = false;
  searchPageSize = SEARCH_PAGE_SIZE;
  scrollbar = true;
  scrollbarTimeout = null;
  tempItem = {};
  pagination = PAGINATION_DEFAULT;

  @Watch('type')
  fetchData() {
    this.addItemInput = false;
    this.loadList({});
  }

  mounted() {
    this.pageResource = `${this.storeNamespace}${this.title.toLowerCase()}`;
    this.tempItem = { ...this.$store.getters[this.modelPath] };
    this.checkScrollbar();
  }

  updated() {
    this.checkScrollbar();
  }

  beforeUnmount() {
    clearTimeout(this.scrollbarTimeout);
  }

  async addItem(newItem) {
    const preparedItem = prepareItem(newItem);
    try {
      await this.$store.dispatch(this.createPath, [preparedItem]);
      this.tempItem = { ...this.$store.getters[this.modelPath] };
      this.addItemInput = false;
      this.$aiq.notify.success(this.$t('settings_tab.keyword_management.add_success_msg', { name: preparedItem.name, item: this.titleLowerCased }));
    } catch (error) {
      const message = extractErrorMessage(error) || this.$t('settings_tab.keyword_management.add_failure_msg', { name: preparedItem.name, item: this.titleLowerCased });
      switch (error.status) {
        case UNIQUE_CONSTRAINT_CODE:
          this.$aiq.notify.error(error.response.data.error);
          break;
        case NO_KEYWORD_CODE:
          this.$aiq.notify.error(this.$t('settings_tab.keyword_management.no_keyword_error'));
          break;
        default:
          this.$aiq.notify.error(message);
      }
    }
  }

  addKeywordToItem([item, keyword]) {
    const keywords = uniq([...item.keywords, keyword]);
    this.updateKeywords(item, keywords)
      .then(() => {
        this.$aiq.notify.success(`${keyword} has been added to ${item.name}.`);
      })
      .catch(() => this.$aiq.notify.error(`Error adding ${keyword} to ${item.name}.`));
  }

  /**
   * Check if the scrollbar is visible and if it is visible
   * change actions title column width to be same as the actions column
   */
  checkScrollbar() {
    this.scrollbarTimeout = setTimeout(() => {
      if (!this.$refs['list-content']) {
        return;
      }

      const { offsetHeight: height = {}, scrollHeight: scroll = {} } = this.$refs['list-content'];
      this.scrollbar = height === scroll;
    }, CHECK_SCROLLBAR_TIMEOUT_MS);
  }

  deleteItem(item) {
    const { name } = item;
    this.$aiq.confirm(
      'Archive?',
      `Are you sure you want to archive <b>${name}</b>? You will need to contact support to unarchive.`,
    ).then(() => {
      this.$store.dispatch(this.deletePath, item)
        .then(() => {
          this.$aiq.notify.success(`${name} has been archived.`);
        });
    }).catch((err)=>{
      if (err != 'cancel') {
        this.$aiq.notify.error('Error archiving.', err);
      }
    });
  }

  deleteKeywordFromItem([item, keyword]) {
    const keywords = item.keywords.filter(k => k !== keyword);

    if (keywords.length < this.minimumAllowedKewords) {
      return this.$aiq.notify.error(`${this.typeText} must have at least ${this.minimumAllowedKewords} keyword.`);
    }

    this.updateKeywords(item, keywords)
      .then(() => {
        this.$aiq.notify.success(`${keyword} has been deleted from ${item.name}.`);
      })
      .catch(() => this.$aiq.notify.error(`Error deleting ${keyword} from ${item.name}.`));
  }

  async loadList({ searchTerm = '', searchOffset = 0 }) {
    const params = {
      limit: this.searchPageSize,
      query: searchTerm,
      offset: searchOffset,
    };

    const response = await this.$store.dispatch(this.getPath, [
      params,
      searchOffset === 0,
    ]);
    this.pagination = get(response, 'data.pagination', PAGINATION_DEFAULT);
    return response;
  }

  updateItem(item = this.selected) {
    this.$store.dispatch(this.updatePath, [prepareItem(item), 'data'])
      .then(() => {
        this.$aiq.notify.success(`${this.typeText} has been updated`);
      })
      .catch((error) => this.$aiq.notify.error(extractErrorMessage(error) || `Creation failed. ${item.name} may already exist.`));
  }

  toggleCreateItem(value = !this.addItemInput) {
    this.addItemInput = value;
    if (value) {
      this.$nextTick(() => {
        document.querySelector('#new-item-with-keywords').focus();
      });
    }

    this.checkScrollbar();
  }

  updateKeywords(item, keywords) {
    return this.$store.dispatch(`${this.titleLowerCased}/updateKeywords`, [item, keywords, this.type]);
  }
}
export default toNative(KeywordsManagement);
