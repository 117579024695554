<template lang="pug">
  .pairs-container
    .pairs__content
      .pair-container(v-for="(pair, index) in pairs"
                      :key="index")
        .pair-select-item(:style="styleLeft")
          aiq-input(:model-value="pair[0]"
                    size="small"
                    :placeholder="placeholderLeft"
                    @update:modelValue="onChange($event, index, 0)"
                    :disabled="disableLeft")
        span :
        .pair-select-item(:style="styleRight")
          aiq-input(:model-value="pair[1]"
                    size="small"
                    :placeholder="placeholderRight"
                    @update:modelValue="onChange($event, index, 1)"
                    :disabled="disableRight")
        aiq-button.pairs-delete_btn(v-if="!disableDeletion"
                                    size="small"
                                    @click="deletePair(index)")
          i.el-icon-close
    aiq-button.pairs-add_btn(v-if="!disableAddition"
                             size="small"
                             @click="addPair")
      i.el-icon-plus
</template>


<script>
const NEW_VALUES = ['', ''];

export default {
  name: 'inputBoxPairs',
  props: {
    pairs: {
      type: Array,
      default: () => [],
    },
    disableLeft: {
      type: Boolean,
      default: false,
    },
    disableRight: {
      type: Boolean,
      default: false,
    },
    disableDeletion: {
      type: Boolean,
      default: false,
    },
    disableAddition: {
      type: Boolean,
      default: false,
    },
    styleLeft: Object,
    styleRight: Object,
    placeholderLeft: {
      type: String,
      default: '',
    },
    placeholderRight: {
      type: String,
      default: '',
    },
  },
  emits: ['update'],
  methods: {
    addPair() {
      const pairs = [...this.pairs, [...NEW_VALUES]];
      this.$emit('update', pairs);
    },
    deletePair(index) {
      const pairs = this.pairs.slice(0, index)
        .concat(this.pairs.slice(index + 1));
      this.$emit('update', pairs);
    },
    onChange(val, idxItem, idxSide) {
      const pairs = [...this.pairs];
      pairs[idxItem][idxSide] = val;
      this.$emit('update', pairs);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/aiq-mixins.scss";
@import "../../styles/aiq-variables.scss";

.pairs-container {
  .pairs__content {
    @include space-between-v(10px);

    .pair-container {
      @include space-between-h(10px);

      display: flex;
      line-height: 30px;
    }
    // TODO (Gabe) - move to common styles

    .pairs-delete_btn {
      margin-left: 10px;
    }
  }

  button {
    background-color: transparent;
    border: 0;
    color: $aiq-fill-button-default;
    display: flex;
    padding: 0;

    &:hover {
      background-color: transparent;
      color: $aiq-fill-button-default;
    }
  }

  .pairs-add_btn {
    font-size: 14px;
    margin-top: 12px;
  }
}

</style>
