<template>
<svg width="18px" height="14px" viewBox="0 0 18 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 62 (91390) - https://sketch.com -->
    <title>Group 16</title>
    <desc>Created with Sketch.</desc>
    <g id="mockup" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="onboarding-icon" transform="translate(-1219.000000, -832.000000)">
            <g id="Group-16" transform="translate(1219.000000, 832.000000)">
                <path d="M9.17429428,4.97865601 C9.51986201,4.54861617 10.0814952,4.55030508 10.4257057,4.97865601 L13.0464991,8.2400878 C13.7387885,9.10160352 13.4041422,9.8 12.3026083,9.8 L7.2973917,9.8 C6.19426273,9.8 5.86082945,9.1020789 6.55350088,8.2400878 L9.17429428,4.97865601 Z" id="arrow" fill="#828F97" transform="translate(9.800000, 7.000000) scale(1, -1) rotate(90.000000) translate(-9.800000, -7.000000) "></path>
                <path d="M16,13.5 L2,13.5 C1.58578644,13.5 1.21078644,13.3321068 0.939339828,13.0606602 C0.667893219,12.7892136 0.5,12.4142136 0.5,12 L0.5,2 C0.5,1.58578644 0.667893219,1.21078644 0.939339828,0.939339828 C1.21078644,0.667893219 1.58578644,0.5 2,0.5 L16,0.5 C16.4142136,0.5 16.7892136,0.667893219 17.0606602,0.939339828 C17.3321068,1.21078644 17.5,1.58578644 17.5,2 L17.5,12 C17.5,12.4142136 17.3321068,12.7892136 17.0606602,13.0606602 C16.7892136,13.3321068 16.4142136,13.5 16,13.5 Z" id="Rectangle" stroke="#828F97"></path>
            </g>
        </g>
    </g>
</svg>
</template>
