<template lang="pug">
.saml-config
  aiq-row(:gutter="20")
    .saml-auth-config
      aiq-col(:span="12")
        .form-container
          .sub-header--solid SP Configuration
          .form__inner-content
            .saml-form-field-container
              .form-field-label EntityID 
              aiq-input(v-model="samlConfig.sp.entityID"
                        placeholder="Unique ID"
                        size="small")

            .saml-form-field-container
              .form-field-label Auth Request Signed
              aiq-switch(v-model="samlConfig.sp.authnRequestsSigned"
                          style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA")

            .saml-form-field-container
              .form-field-label Assertion Signed
              aiq-switch(v-model="samlConfig.sp.wantAssertionsSigned"
                          style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA")

            .saml-form-field-container
              .form-field-label Message Signed
              aiq-switch(v-model="samlConfig.sp.wantMessageSigned"
                          style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA")

            .saml-form-field-container
              .form-field-label Logout Response Signed
              aiq-switch(v-model="samlConfig.sp.wantLogoutResponseSigned"
                          style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA")

            .saml-form-field-container
              .form-field-label Logout Request Signed
              aiq-switch(v-model="samlConfig.sp.wantLogoutRequestSigned"
                          style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA")

            .saml-form-field-container
              .form-field-label ACS Support List
              SamlBindingEditor(v-model="samlConfig.sp.assertionConsumerService")


            .saml-form-field-container
              .form-field-label SLO Support List
              SamlBindingEditor(v-model="samlConfig.sp.singleLogoutService")

            .saml-form-field-container
              .form-field-label NameID Formats
              aiq-keyword-input(:list="samlConfig.sp.nameIDFormat"
                                @createItem="addItem('allowed_headers', $event)"
                                @deleteItem="deleteItem('allowed_headers', $event)"
                                :deletable="true")
      aiq-col(:span="12")
        .form-container
          .sub-header--solid IDP Configuration
          .form__inner-content
            .saml-form-field-container
              .form-field-label Assertion Signed
              aiq-switch(v-model="samlConfig.idp.isAssertionEncrypted"
                          style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA")

            .saml-form-field-container
              .form-field-label Message Signing Order
              aiq-select(v-model="samlConfig.idp.messageSigningOrder"
                        size="small")
                aiq-option(v-for="option in signingOptions"
                          :key="option.value"
                          :label="option.name"
                          :value="option.value")

            .saml-form-field-container(v-show="samlConfig.idp.metadata === ''")
              .form-field-label Metadata URL
              aiq-input(v-model="samlConfig.idp.metadataUrl"
                        size="small")
            .saml-form-field-container(v-show="samlConfig.idp.metadataUrl === ''")
              .form-field-label Metadata XML
              aiq-input(v-model="samlConfig.idp.metadata"
                        type="textarea"
                        size="small")

        .form-container
          .sub-header--solid IDP Configuration
          .form__inner-content
            .saml-form-field-container
              .form-field-label Use External ID
              aiq-switch(v-model="samlConfig.configuration.use_external_id"
                          style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA")

            .saml-form-field-container(v-if="samlConfig.configuration.use_external_id")
              .form-field-label External ID Field Path
              aiq-input(v-model="samlConfig.configuration.external_id_field" size="small")

            .saml-form-field-container
              .form-field-label First Name Field
              aiq-input(v-model="samlConfig.configuration.attrs.first_name_field" size="small")

            .saml-form-field-container
              .form-field-label Middle Name Field
              aiq-input(v-model="samlConfig.configuration.attrs.middle_name_field" size="small")

            .saml-form-field-container
              .form-field-label Last Name Field
              aiq-input(v-model="samlConfig.configuration.attrs.last_name_field" size="small")

            .saml-form-field-container
              .form-field-label Email Field
              aiq-input(v-model="samlConfig.configuration.attrs.email_field" size="small")
    .saml-auth-config-footer
      .saml-auth-config-footer-bttn
        aiq-button(@click="onSamlConfigCancel" :disabled="!canSaveSamlConfig") Cancel
        aiq-button(@click="onSamlConfigSave" type="primary" :disabled="!canSaveSamlConfig") Save

  aiq-row.padding-top-10
    aiq-col(:span="24")
      .form-container
        .sub-header--solid Custom Field Mapping
        .form__inner-content
          .saml-form-field-container
            .form-field-label Javascript
            //- TODO: We have want to have Javascript editor here in future
            aiq-input(v-model="fieldMappingConfig.custom_script"
                      type="textarea")

          .saml-auth-config-footer
            .saml-auth-config-footer-bttn
              aiq-button(@click="onFieldMappingConfigCancel" :disabled="!canSaveFieldMappingConfig") Cancel
              aiq-button.margin-left-10(@click="onFieldMappoingConfigSave" type="primary" :disabled="!canSaveFieldMappingConfig") Save
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import SamlBindingEditor from './SamlBindingEditor';

export default {
  name: 'SamlConfiguration',
  components: {
    SamlBindingEditor,
  },
  data() {
    return {
      samlConfig: {
        configuration: {
          use_external_id: false,
          external_id_field: 'nameID',
          attrs: {
            first_name_field: 'firstName',
            middle_name_field: 'middleName',
            last_name_field: 'lastName',
            email_field: 'email'
          }
        },
        sp: {
          entityID: '',
          authnRequestsSigned: false,
          wantAssertionsSigned: false,
          wantMessageSigned: true,
          wantLogoutResponseSigned: true,
          wantLogoutRequestSigned: true,
          assertionConsumerService: [],
          singleLogoutService: [],
          nameIDFormat: [],
        },
        idp: {
          isAssertionEncrypted: true,
          messageSigningOrder: 'encrypt-then-sign',
          metadataUrl: '',
          metadata: ''
        },
      },
      fieldMappingConfig: {
        custom_script: '',
      },

      originalSamlConfig: null,
      originalFieldMappingConfig: null,
    };
  },
  computed: {
    signingOptions: function () {
      return [
        {
          name: 'Encrypt then sign',
          value: 'encrypt-then-sign',
        },
        {
          name: 'Sign then Encrypt',
          value: 'sign-then-encrypt',
        },
      ];
    },
    canSaveSamlConfig: function () {
      return !isEqual(this.samlConfig, this.originalSamlConfig);
    },
    canSaveFieldMappingConfig: function () {
      return !isEqual(this.fieldMappingConfig, this.originalFieldMappingConfig);
    },
  },

  async mounted() {
    try {
      const [samlConfig, fieldMappingConfig] = await Promise.all([
        this.$store.dispatch('settings/getSetting', { name: 'saml_configuration' }),
        this.$store.dispatch('settings/getSetting', { name: 'auth_field_mapping_configuration' })
      ]);
      this.samlConfig = samlConfig;
      this.fieldMappingConfig = fieldMappingConfig;
      this.originalSamlConfig = cloneDeep(samlConfig);
      this.originalFieldMappingConfig = cloneDeep(fieldMappingConfig);
    } catch (err) {
      this.$aiq.notify.error(`Failed: ${err.message}`);
    }
  },
  methods: {
    onSamlConfigCancel() {
      this.samlConfig = this.originalSamlConfig;
    },

    onSamlConfigSave() {
      this.originalSamlConfig = this.onSave('saml_configuration', this.samlConfig);
    },
    onFieldMappingConfigCancel() {
      this.samlConfig = this.originalSamlConfig;
    },

    onFieldMappoingConfigSave() {
      this.originalFieldMappingConfig = this.onSave('auth_field_mapping_configuration', this.fieldMappingConfig);
    },

    async onSave(name, payload) {
      try {
        await this.$store.dispatch('settings/updateSetting', { name, payload });
        this.$aiq.notify.success('Updated');
        return payload;
      } catch (err) {
        this.$aiq.notify.error(`Failed: ${err.message}`);
      }
      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./../../../../../styles/aiq-variables.scss";
  .form__inner-content {
    background: #FFFFFF;
    border: 1px solid #E0E6ED;
    display: flex;
    flex-direction: column;
    padding: 18px;

    * {
      margin: 5px 0px;
    }

    .saml-form-field-container > .form-field-label {
      display: inline-block;
      width: 115px;

      color: #475669;
      letter-spacing: 0;
      font-family: $aiq-font-family;
      font-size: 14px;
      font-weight: 300;
    }

    .saml-form-field-container > .form-field-label-long {
      width: 100%;
      font-size: 14px;
      font-weight: 300;
    }
  }

  .sub-header--solid {
    position: relative;
  }

  .saml-auth-config-footer {
    width: 100%;

    .saml-auth-config-footer-bttn {
      display: flex;
      justify-content: flex-end;
    }
  }
  .padding-top-10 {
    padding-top: 10px;
  }
  .margin-left-10 {
    margin-left: 10px;
  }
</style>
