import moment from 'moment';

const DATE_RANGE = 7;
const WEEK_RANGE = 2;
const DAY_RANGE = 6;

const DAY_COUNT_60 = 60;
const DAY_COUNT_90 = 90;

const getLastQuarterRange = () => {
  const currentMonth = moment().month(); // Get current month (0-11)

  // Constants for month numbers
  const JANUARY = 0;
  const MARCH = 2;
  const APRIL = 3;
  const JUNE = 5;
  const JULY = 6;
  const SEPTEMBER = 8;
  const OCTOBER = 9;
  const DECEMBER = 11;

  let startMonth, endMonth;

  if (currentMonth >= OCTOBER) { // October to December
    startMonth = JULY;
    endMonth = SEPTEMBER;
  } else if (currentMonth >= JULY) { // July to September
    startMonth = APRIL;
    endMonth = JUNE;
  } else if (currentMonth >= APRIL) { // April to June
    startMonth = JANUARY;
    endMonth = MARCH;
  } else { // January to March
    startMonth = OCTOBER;
    endMonth = DECEMBER;
  }

  return {
    startTime: () => moment().month(startMonth).startOf('month'),
    endTime: () => moment().month(endMonth).endOf('month'),
  };
};

const dateLastRanges = (day, optionCallback) => ({
  label: `Last ${day} Days`,
  startTime: () => moment()
    .subtract(day, 'days')
    .startOf('day'),
  endTime: () => moment()
    .subtract(1, 'day')
    .endOf('day'),
  optionCallback,
});

const dateRangeOptions = (optionCallback) => [
  {
    label: 'Last Week',
    startTime: () => moment()
      .subtract(1, 'week')
      .startOf('day'),
    endTime: () => moment()
      .subtract(1, 'day')
      .endOf('day'),
    optionCallback,
  },
  {
    label: 'Last Month',
    startTime: () => moment()
      .subtract(1, 'month')
      .startOf('day'),
    endTime: () => moment()
      .subtract(1, 'day')
      .endOf('day'),
  },
  dateLastRanges(DAY_COUNT_60, optionCallback),
  dateLastRanges(DAY_COUNT_90, optionCallback),
];

const lastAvailableMonthAndQuarterRange = [
  {
    label: 'Last Month',
    startTime: () => moment()
      .subtract(1, 'month')
      .startOf('month'),
    endTime: () => moment()
      .subtract(1, 'month')
      .endOf('month'),
  },
  {
    label: 'Last Quarter',
    ...getLastQuarterRange(),
  },
];

const defaultDateRange = () => [
  moment()
    .subtract(1, 'week')
    .startOf('day'),
  moment()
    .subtract(1, 'day')
    .endOf('day'),
];

const lastWeekRange = [
  moment()
    .subtract(DATE_RANGE + 1, 'day')
    .startOf('day'),
  moment().subtract(1, 'day').endOf('day'),
];
export const getStartEndMomentDates = (dateEpoch, deltaIncrement,
  deltaType = 'days') => ([
  deltaIncrement ? moment(dateEpoch) : moment(dateEpoch).startOf(deltaType),
  deltaIncrement ? moment(dateEpoch).add(deltaIncrement,
    deltaType) : moment(dateEpoch).endOf(deltaType),
]);


const breakdownOptions = [
  // {
  //   value: 'hour',
  //   label: 'Hourly',
  //   xAxisFormat: value => moment(value).format('MMM DD ha'),
  //   dateRangeWhenLineClicked: value => getStartEndMomentDates(value, 0,
  //     'hours'),
  //
  // },
  // Hourly bucket should be hidden  till ETL support is added
  {
    value: 'day',
    label: 'Daily',
    isDefault: true,
    xAxisFormat: value => moment(value).format('MMM DD'),
    dateRangeWhenLineClicked: value => getStartEndMomentDates(value, 0, 'days'),
  },
  {
    value: 'week',
    label: 'Weekly',
    xAxisFormat: value => {
      const dateRange = getStartEndMomentDates(value, DAY_RANGE);
      const format = 'MMM DD';
      return `${dateRange[0].format(format)} - ${dateRange[1].format(format)}`;
    },
    dateRangeWhenLineClicked: value => getStartEndMomentDates(value, 1, 'weeks'),
  },
  {
    value: 'month',
    label: 'Monthly',
    xAxisFormat: value => moment(value).format('MMM'),
    dateRangeWhenLineClicked: value => getStartEndMomentDates(value, 1, 'months'),


  },
];

const selectedBreakdownOption = () => breakdownOptions.find(
  ({ isDefault }) => isDefault,
);

const filterBreakdownOptions = (dateRange, allBreakdownOptions = breakdownOptions) => {
  const [startDate, endDate] = dateRange;
  /* eslint-disable no-magic-numbers */
  if (moment(endDate).diff(startDate, 'months') >= 2) {
    return allBreakdownOptions;
  }

  /* eslint-disable no-magic-numbers */
  if (moment(endDate).diff(startDate, 'weeks') >= 2) {
    return allBreakdownOptions.filter(({ value }) => value !== 'month');
  }

  return allBreakdownOptions.filter(({ value }) => value === 'hour' || value === 'day');
};


const createPickerDateRangeOption = option => ({
  text: option.label,
  value() {
    if (option.optionCallback) {
      option.optionCallback(option);
    }
    if (option.startTime && option.endTime) {
      const range = [option.startTime(), option.endTime()];
      return range;
    }
  },
});

// When passed to date-pickers, will disable the selection of dates from today onward
const todayOnwards = (time) => time.getTime() > moment().subtract(1, 'day').endOf('day');

// Sets the time of the end date to 11:59:59 pm
const setToEndOfDay = (newDateRange) => {
  const [startDate, endDate] = newDateRange;
  return [startDate, moment(endDate).endOf('day').toDate()];
};

// Used to fix an edge case in range date-pickers in which the user changes the date manually then clicks outside
// https://agentiq.atlassian.net/browse/AG-16561
// Instead of returning a date string, the date pickers return an object that the moment() function cannot handle
// So, this function turns the dates into a format that moment can handle.
// Please don't change it unless you are sure that the your change doesn't regress the edge case.
const getNonReactiveDateRange = (reactiveDateRange) => {
  const [startDate, endDate] = reactiveDateRange;
  return [new Date(startDate.toString()), new Date(endDate.toString())];
};

const getDiffDays = (dateRange) => {
  const [startDate, endDate] = getNonReactiveDateRange(dateRange);
  const startMoment = moment(startDate).startOf('day');
  const endMoment = moment(endDate).endOf('day');
  const diffInMilliseconds = endMoment.diff(startMoment);
  const diffInDays = moment.duration(diffInMilliseconds).asDays();
  return Math.round(diffInDays);
};

const isWarningRange = (dateRange) => (getDiffDays(dateRange) > DAY_COUNT_90);

const datePickerDefaultOptions = (optionCallback) => ({
  shortcuts: dateRangeOptions(optionCallback).map(createPickerDateRangeOption),
  disabledDate: todayOnwards,
});

const getDefaultDateRange = () => {
  const defaultOption = {
    label: 'Last 30 Days',
    startTime: () => moment()
      .subtract(30, 'days')
      .startOf('day'),
    endTime: () => moment()
      .subtract(1, 'day')
      .endOf('day'),
  };
  return [defaultOption.startTime(), defaultOption.endTime()];
};

const dstShift = (d) => {
  if (moment(d).isDST()){
    return moment(d).add(1, 'hours').unix();
  }
  return moment(d).unix();
};

const dstDateRangeUtc = (dateRange) => {
  const [period_start, period_end] = dateRange;
  return [dstShift(period_start), dstShift(period_end)];
};

export {
  getDefaultDateRange,
  dateRangeOptions,
  getDiffDays,
  isWarningRange,
  datePickerDefaultOptions,
  DATE_RANGE,
  WEEK_RANGE,
  DAY_RANGE,
  lastAvailableMonthAndQuarterRange,
  lastWeekRange,
  breakdownOptions,
  selectedBreakdownOption,
  createPickerDateRangeOption,
  defaultDateRange,
  todayOnwards,
  setToEndOfDay,
  filterBreakdownOptions,
  getNonReactiveDateRange,
  getLastQuarterRange,
  dstShift,
  dstDateRangeUtc,
};
