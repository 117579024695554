<template lang="pug">
.device-info
  h3.customer-info-basic_title Device Info
    .customer-info-basic_field(v-for="info in details")
      label {{info.name}}:
      .noneditable {{ info.value }}

</template>

<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import parser from 'ua-parser-js';
import { isValidIpV4Address } from '../../libs';

export default {
  watch: {
    async ipAddress(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.geolocations = await this.updateGeoLocation(newValue);
      } else if (!newValue) {
        this.geolocations = [];
      }
    },
  },
  data() {
    return {
      geolocations: [],
    };
  },
  async mounted() {
    this.geolocations = await this.updateGeoLocation(this.ipAddress);
  },
  methods: {
    updateGeoLocation(ipStr) {
      if (isEmpty(ipStr)) {
        return [];
      }

      // run geo location query
      // The value could be multiple ip addresses with ', '
      // so we break down and get all the location info.
      const ips = ipStr.split(', ');
      // Currently we only support IPv4 addresses
      return Promise.all(ips.filter(ip => isValidIpV4Address(ip)).map(ip => this.$store.dispatch('tools/geoLocation', { ip })));
    },
  },
  computed: {
    lastCustomerMessage() {
      // Should reverse search
      return [...this.messages].reverse() // create new array reference due to reverse() operation
        .find(m => m.sender_type && m.sender_type === 'customers' && !isEmpty(m.metadata));
    },
    userAgent() {
      if (!this.lastCustomerMessage) {
        return null;
      }
      return parser(get(this.lastCustomerMessage, 'metadata.user_agent', ''));
    },
    ipAddress() {
      if (!this.lastCustomerMessage) {
        return '';
      }
      return get(this.lastCustomerMessage, 'metadata.customer_origin', '');
    },
    parsedLocation() {
      if (isEmpty(this.geolocations)) {
        return '';
      }

      return this.geolocations.map(lo => [lo.city, lo.region, lo.country].join(', ')).join(' / ');
    },
    browser() {
      if (!this.userAgent) {
        return '';
      }
      return values(this.userAgent.browser || {}).join(' ');
    },
    device() {
      if (!this.userAgent) {
        return '';
      }
      return values(this.userAgent.device || {}).join(' ');
    },
    details() {
      return [
        {
          name: 'IP',
          value: this.ipAddress,
        },
        {
          name: 'Location',
          value: this.parsedLocation,
        },
        {
          name: 'Browser',
          value: this.browser,
        },
        {
          name: 'Device',
          value: this.device,
        },
      ];
    },
  },
  props: {
    messages: {
      type: Array,
      default: [],
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-info-basic_title {
  color: #48576A;
  font-size: 12px;
  line-height: 12px;
  font-weight: 300;
}

.customer-info-basic_field {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 8px;

  .noneditable {
    font-size: 11px;
    font-weight: 300;
  }

  > label {
    width: 97px;
    color: #324057;
    font-size: 11px;
    line-height: 13px;
    font-weight: 300;
  }
}
</style>
