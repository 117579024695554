import { mapGetters } from 'vuex';
import moment from 'moment';
import get from 'lodash/get';
import difference from 'lodash/difference';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import SegmentSelect from '@/components/SegmentsSelect/SegmentsSelect.vue';
import { dropDownToSelected, filterEndpointToMetaData, fetchFilterOptions, formatFilterData } from '../../../helpers/filterHelper';
import {
  datePickerDefaultOptions,
  defaultDateRange,
  breakdownOptions,
  setToEndOfDay,
  filterBreakdownOptions,
  getNonReactiveDateRange,
} from '../../../configs/dateRange';
import { limitedString } from '@/libs/stringUtils';

const defaultArrayConfig = [];

export default {
  name: 'selectableFiltersMixin',
  components: {
    SegmentSelect,
  },
  data() {
    return {
      selectedFilters: [],
      dateRange: defaultDateRange(),
      // dateRange holds the date selected by the user.
      // if the date range is greater than 90 days, we present the user with a modal 
      // informing them that selecting a large date range may affect load time.
      // if the user clicks ok - the requests is initiated
      // if the user clicks cancels - the date range is reverted to the previous date range.
      previousDateRange: defaultDateRange(),
      selectedBreakdownOption: breakdownOptions.find(
        ({ isDefault }) => isDefault,
      ),
      segmentAlias: '',
      defaultFilterTypesShown: defaultArrayConfig,
      defaultAgentFilterOptions: defaultArrayConfig,
      defaultPrimaryAgentFilterOptions: defaultArrayConfig,
      defaultCategoryFilterOptions: defaultArrayConfig,
      defaultTagFilterOptions: defaultArrayConfig,
      defaultAgentsTeamsFilterOptions: defaultArrayConfig,
      defaultTeamFilterOptions: defaultArrayConfig,
      AgentFilterOptions: defaultArrayConfig,
      CategoryFilterOptions: defaultArrayConfig,
      customerSegmentFilterOptions: defaultArrayConfig,
      tagFilterOptions: defaultArrayConfig,
      agentsTeamsFilterOptions: defaultArrayConfig,
      TeamFilterOptions: defaultArrayConfig,
      agentsFilter: false,
      agentsTeamsFilter: false,
      teamsFilter: false,
      categoriesFilter: false,
      customerSegmentsFilter: false,
      tagsFilter: false,
      primaryAgentsFilter: false,
      FilterTypeOptions: [
        // label is the display text on UI
        // name is used to find configuration
        { id: 1, name: 'Agents', label: 'Agents' },
        { id: 2, name: 'Agents Teams', label: 'Agents By Teams' },
        { id: 3, name: 'Teams', label: 'Teams' },
        { id: 4, name: 'Categories', label: 'Categories' },
        { id: 5, name: 'Customer Segments', label: 'Segments' },
        { id: 6, name: 'Tags', label: 'Tags' },
        { id: 7, name: 'Primary Agents', label: 'Primary Agents' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      myTeams: 'agent/teams',
    }),
    datePickerOptions() {
      return datePickerDefaultOptions();
    },
    dateRangeUtc() {
      return this.previousDateRange.map(date => moment(date).unix());
    },
    filteredBreakdownOptions() {
      return filterBreakdownOptions(this.dateRange, breakdownOptions);
    },
  },
  methods: {
    optionLabel(option) {
      return limitedString(option.name);
    },
    onFilterTypeChange(chosenFilterTypeOptions) {
      const unchosenFilterTypeOptions = difference(this.FilterTypeOptions, chosenFilterTypeOptions);
      this.showFilters(chosenFilterTypeOptions, true);
      this.showFilters(unchosenFilterTypeOptions, false);
      this.clearFilters(unchosenFilterTypeOptions);
    },
    setSelectedFilters(filterName, chosenFilters) {
      this.selectedFilters = dropDownToSelected(chosenFilters, this.selectedFilters, filterName);
    },
    onAgentFilterChange(chosenFilters) {
      this.setSelectedFilters('agents', chosenFilters);
    },
    onPrimaryAgentFilterChange(chosenFilters) {
      this.setSelectedFilters('primary_agents', chosenFilters);
    },
    onCategoryFilterChange(chosenFilters) {
      this.setSelectedFilters('categories', chosenFilters);
    },
    onTeamFilterChange(chosenFilters) {
      this.setSelectedFilters('teams', chosenFilters);
    },
    onCustomerSegmentFilterChange(chosenFilters) {
      this.setSelectedFilters('customer_segments', chosenFilters);
    },
    onTagFilterChange(chosenFilters) {
      this.setSelectedFilters('tags', chosenFilters);
    },
    onAgentsTeamsFilterChange(chosenFilters) {
      this.setSelectedFilters('agents_teams', chosenFilters);
    },
    clearFilters(filterTypeOptions) {
      // eslint-disable-next-line array-callback-return
      filterTypeOptions.map(filterOption => {
        this.setSelectedFilters(snakeCase(filterOption.name), []);
      });
    },
    showFilters(filterTypeOptions, shouldShow) {
      // eslint-disable-next-line array-callback-return
      filterTypeOptions.map(filterOption => {
        this[camelCase(`${filterOption.name} Filter`)] = shouldShow;
      });
    },
    async loadAllFilterOptions() {
      for (const endpoint in filterEndpointToMetaData) {
        if ({}.hasOwnProperty.call(filterEndpointToMetaData, endpoint)) {
        /* eslint-disable no-await-in-loop */
          let datapoints;
          const metaData = filterEndpointToMetaData[endpoint];
          if (metaData.remoteCall) {
            datapoints = await fetchFilterOptions(this, endpoint);
          } else {
            datapoints = get(this, metaData.getterName, []);
          }
          const formattedOptionData = formatFilterData(datapoints, metaData.getFormattedName);
          metaData.setOptionsStateVariable(this, formattedOptionData);
        }
      }
    },
    handleDatePickerChange(newDateRange) {
      this.dateRange = setToEndOfDay(getNonReactiveDateRange(newDateRange));
      this.previousDateRange = setToEndOfDay(getNonReactiveDateRange(newDateRange));
    },
    async onSegmentHover(segment) {
      if (segment) {
        const segmentDetails = await this.$store.dispatch('configs/getCustomerSegmentDetails', { id: segment.id });
        this.segmentAlias = get(segmentDetails, 'payload.alias', '');
      } else {
        this.segmentAlias = '';
      }
    },
  },
};
