<template lang="pug">
.realtime-metrics-panel-container
  .realtime-metrics-panel
    aiq-row.paneled.grow.condensed(:gutter="16" display="flex")
      aiq-col(:span="24" style="height:75%;")
        aiq-card
          .metric-title
            aiq-tooltip(
              :content="replaceDuration(labelsAndTips['NEW_CONVERSATIONS'].tip, sliceDuration)"
              effect="dark" 
              placement="bottom"
              :show-after="500")
              span {{ labelsAndTips['NEW_CONVERSATIONS'].label }}
          .metric.grow
            .metric-value.medium.grow {{ totalConversations }}
          .metric-value.grow
            .convo-table
              aiq-table.realtime-conversations-table(
                    :data="conversationsTableData"
                    ref="conversations")
                aiq-table-column(label="Segment", :min-width="2")
                  template(v-slot:default="scope")
                    span {{ extractCol(scope.row, segmentRowType) }} &nbsp;
                aiq-table-column(label="Conversations", :min-width="2")
                  template(v-slot:default="scope")
                    span {{ extractCol(scope.row, conversations.cols[0])  }} &nbsp;
                aiq-table-column(:min-width="3")
                  template(v-slot:header)
                    aiq-tooltip(effect="dark"
                                placement="bottom"
                                :content="labelsAndTips['MEDIAN_RT'].tip"
                                :show-after="500")
                      span {{ labelsAndTips["MEDIAN_RT"].label }}
                  template(v-slot:default="scope")
                    span {{ extractCol(scope.row, medianFRT.cols[0])  }} &nbsp;

      aiq-col(:span="24" style="height:25%;")
        aiq-card
          .metric.grow
            .metric-title
              aiq-tooltip(
                :content="replaceDuration(labelsAndTips['CUSTOMER_LOGINS'].tip, sliceDuration)"
                effect="dark" 
                placement="bottom", :show-after="500")
                span {{ labelsAndTips['CUSTOMER_LOGINS'].label }}
            .metric-value.small.grow {{ extractMetric("customer_logins") }}

</template>

<script>
import { humanizeDuration } from '@/libs';
import RealTimePanel from '../common/panel.vue';

const msPerSeconds = 1000;

export default {
  extends: RealTimePanel,
  name: 'timeslicePanel',
  props: {
    action: {
      type: String,
      default: 'getTimesliceMetrics',
    },
    storeKey: {
      type: String,
      default: 'timeslice',
    },
  },
  computed: {
    panelLabel() {
      return 'LAST_PERIOD';
    },
    conversationData() {
      return this.getTabularMetricData('new_conversations_per_segment');
    },
    FRTData() {
      return this.getTabularMetricData('median_response_time_per_segment');
    },
    totalConversations() {
      return this.extractCol(this.conversationData, 'new_conversations', 0);
    },
    conversations() {
      return {
        label: 'conversations',
        cols: ['conversations'],
        colsData: this.conversationData,
        extractionClosure: this.colObjects,
        formatter: this.formatterBasedOnCategory(this.conversationData),
        rowKeys: this.getKeysForTabularData(this.conversationData, 'new_conversations'),
      };
    },
    medianFRT() {
      return {
        label: 'FRT',
        cols: ['FRT'],
        colsData: this.FRTData,
        extractionClosure: this.colObjects,
        formatter: this.formatterBasedOnCategory(this.FRTData),
        rowKeys: this.getKeysForTabularData(this.FRTData, 'median_response_time'),
      };
    },
    conversationsTableData() {
      return this.combineMetricsData([
        this.conversations,
        this.medianFRT],
      this.segmentRowType);
    },
    sliceDuration() {
      return humanizeDuration(this.metrics.periodMs / msPerSeconds);
    },
  },
};
</script>

<style lang="scss" scoped>

  @import "../../../styles/aiq-mixins.scss";

  .metric-title.grow {
    display: flex;
  }

  .realtime-metrics-panel .metric.inline.grow {
    height: 100%;
  }
    .convo-table {
      .el-table--fit {
      height: calc(50vh - 144px);
    }
  }
</style>

<style lang="scss">
@import "../../../styles/aiq-mixins.scss";
@import "../../../styles/aiq-variables.scss";

.realtime-conversations-table {
  .el-table__header-wrapper {
    th {
        background-color: white;
        border-right: none; 
        border-bottom: none;
    }
  }
  .el-table__body-wrapper {
    @include scrollable(calc(42vh - 176px));
    td {
      border-right: none; 
      border-bottom: none; 
    }
  }
}

.realtime-metrics-panel-container {
  height: calc(100vh - $aiq-height-pulse-headers);
  .el-row.paneled {
    .el-col {
      height: 100%;

      .el-card {
        height: 100%;

        .el-card__body {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
      }
    }

    .el-col:nth-child(2) {
      padding-top: 16px;
    }
  }
}

.realtime-metrics-panel {
  height: 100%;
  display: flex;
  flex-direction: column;

  .el-card .el-card__body {
    padding: 16px;
  }

  h2 {
    text-transform: capitalize;
    border-bottom: 2px solid #4872b1;
  }

  .grow {
    flex-grow: 1;
    flex-basis: 0;
  }
  .centralize{
      align-items: center;
      align-content: center;
      justify-content: center;
  }
  .metric {
    display: flex;
    flex-direction: column;

    &.inline {
      flex-direction: row;
      height: 36px;

      > * {
        justify-content: center;
        flex-direction: column;
      }

      .metric-title {
        width: 142px;
      }

      .metric-value {
        flex-grow: 1;
        justify-content: flex-start;
      }
    }

    .metric-title {
      color: #324057;
      font-size: 16px;
    }

    .metric-value {
      color: #4872b1;
      display: flex;

      &.grow {
        align-items: center;
        justify-content: center;
      }

      &.small {
        font-size: 28px;
      }

      &.medium {
        font-size: 50px;
      }

      &.large {
        font-size: 110px;
      }
    }
  }
}
</style>