import COLORS from './colors';

const cobrowseVolumeConfig = {
  name: 'cobrowse_volume',
  title: 'Communication',
  metric: 'cobrowse_volume',
  lines: {
    cobrowse_volume: {
      label: 'Co-browse sessions',
      position: 1,
      color: COLORS.green,
    },
    video_volume: {
      label: 'Video sessions',
      position: 2,
      color: COLORS.steel_blue,
    },
  },
  helpText: 'This graph displays communication sessions. A co-browse session counts when it includes an accept and close, and a video session counts when it includes a join and hangup.',
  rightPadding: null,
  leftPadding: null,
  maxTickLabelCount: 8
};

export default cobrowseVolumeConfig;
