<template lang="pug">
.docu-sign
  .tab-nav(v-if="!showAgreementDetailPage")
    .envelop-list(v-loading="agreementsPage === null")
      .list-filters
        aiq-input.filter-item(v-model="searchText" placeholder="Search Text"  @change="getAgreements" size="small")
        aiq-date-picker.filter-item(v-if="!disableDateRange"
                                    v-model="selectedDateRange"
                                    type="daterange"
                                    @change="getAgreements")
      .table-content
        aiq-table.table-content-table(:data="agreements" @row-click="onEnvelopeRowClick")
          aiq-table-column(label="Title")
            template(v-slot:default="scope")
              span {{ noName(scope.row.name) }}
          aiq-table-column(label="Status")
            template(v-slot:default="scope")
              span {{ $filters.toUpperFirst(scope.row.status) }}
          aiq-table-column(label="Created Date")
            template(v-slot:default="scope")
              span {{ $filters.formatTime(scope.row.createdDate) }}
    .button-group
      aiq-button(@click="onSendCancel") Cancel

  .tab-nav(v-if="showAgreementDetailPage")
    h1.title Share Signing Url
    .body
      .envelope-info
        .form-field-container
          aiq-row
            aiq-col(:span="4")
              .form-field-label Agreement ID
            aiq-col(:span="18")
              aiq-input(v-model="agreementId" placeholder="Agreement ID" size="small" disabled)

      .envelope-info(v-if="agreementDetails")
        .envelope-sub-details
          h3.title Documents
          // aiq-tag(v-for="document in agreementDetails.envelopeDocuments" :key="document.documentId") {{ document.name }}
        .envelope-sub-details(v-if="selectedRecipient")
          h3.title Recipients
          .recipient-details(v-for="recipient in allRecipents")
            .active-recipient(v-if="recipient === selectedRecipient")
                aiq-tag Signer to share via message
            .form-field-container
              aiq-row
                aiq-col(:span="4")
                  .form-field-label Email
                aiq-col(:span="20")
                  aiq-input(:model-value="recipient.memberInfos[0].email" size="small" type="email" disabled)

            .form-field-container
              aiq-row
                aiq-col(:span="4")
                  .form-field-label Name
                aiq-col(:span="20")
                  aiq-input(:model-value="recipient.memberInfos[0].name" size="small" disabled)

            .form-field-container
              aiq-row
                aiq-col(:span="4")
                  .form-field-label Role
                aiq-col(:span="20")
                  aiq-input(:model-value="recipient.role" size="small" disabled)

    .button-group
      aiq-button(@click="reset") Cancel
      aiq-button(@click="onSendSignMessage" type="primary" :disabled="!selectedRecipient") Send
</template>

<script>
import * as log from 'loglevel';
import get from 'lodash/get';
import moment from 'moment';
import { Component, Vue, toNative } from 'vue-facing-decorator';
import { getNonReactiveDateRange } from '@/pages/Metrics/configs/dateRange';

const TAG = '[AdobeSignMain]';

@Component({
  name: 'AdobeSignMain',
  props: {
    customer: {
      type: Object,
      default: {},
    },
  },
  emits: ['close', 'sign-message'],
  computed: {
    customerEmail() {
      return get(this.customer, 'profile.email', '');
    },
    allRecipents() {
      return this.recipients;
    },
    customerUUID() {
      return get(this.customer, 'identities.uuids.0.uuid', null);
    },
    agreements() {
      return get(this.agreementsPage, 'agreementAssetsResults.agreementAssetsResultList', []);
    },
  },
})
class AdobeSignMain extends Vue {
  /* Envelop Detail Page Data */
  showAgreementDetailPage = false;
  agreementId = null;
  agreementDetails = null;
  recipients = null;
  selectedRecipient = null;

  /* Envelop List Page Data */
  agreementsPage = null;
  searchText = '';
  disableDateRange = true; // for now, date filtering is disabled
  selectedDateRange = [moment().subtract(1, 'months').toISOString(), moment().toISOString()];

  noName(value) {
    return value === '' ? '[No Name]' : value;
  }

  async mounted() {
    try {
      // populate search text with email
      this.searchText = get(this.customer, 'profile.email', '');

      await this.getAgreements();
    } catch (err) {
      this.$aiq.notify.error(err.message);
    }
  }

  async getAgreements() {
    const search = this.searchText;
    const [startDate, endDate] = getNonReactiveDateRange(this.selectedDateRange);
    const start_date = moment(startDate).toISOString();
    const end_date = moment(endDate).toISOString();

    this.agreementsPage = await this.$store.dispatch('integrations/getEnvelopes', {
      integrationName: 'adobesign',
      query: {
        search,
        start_date,
        end_date,
      },
    });
  }

  async onFetchEnvelope() {
    try {
      const response = await this.$store.dispatch('integrations/getEnvelope', {
        integrationName: 'adobesign',
        envelopeId: this.agreementId,
      });

      this.emailSubject = get(response, 'message', '');
      this.agreementDetails = response;
      this.recipients = get(response, 'participantSetsInfo', []);

      // Support only signer as it is unclear if we need to support other recipients
      if (this.recipients.length !== 0) {
        this.selectedRecipient =
          // Note: Not sure why memberInfos contains multiple entries. For now, it refers
          //       the only first entry.
          this.recipients.find(member => member.memberInfos[0].email === this.customerEmail);
      }
    } catch (err) {
      this.$aiq.notify.error(err.message);
    }
  }

  async onEnvelopeRowClick(item) {
    this.showAgreementDetailPage = true;
    this.agreementId = item.id;

    await this.onFetchEnvelope();
  }

  reset() {
    this.emailSubject = '';
    this.emailBlurb = '';
    this.recipientsToSend = [];
    this.templateDetails = null;
    this.showTemplateDetails = false;

    this.agreementId = null;
    this.agreementDetails = null;
    this.selectedRecipient = null;
    this.showAgreementDetailPage = false;
  }

  onSendCancel() {
    this.reset();
    this.$emit('close');
  }

  async onSendSignMessage() {
    try {
      const recipient = {
        id: get(this.selectedRecipient, 'id'),
        email: get(this.selectedRecipient, 'memberInfos.0.email'),
      };

      const signUrl = (await this.$store.dispatch('integrations/getSignUrl', {
        integrationName: 'adobesign',
        envelopeId: this.agreementId,
        payload: { recipient },
      })).url;

      log.debug(TAG, 'Generated URl', signUrl);

      // Update to parent component to create a url
      this.$emit('sign-message', {
        title: this.emailSubject,
        integration: 'adobesign',
        payload: {
          document_reference_id: this.agreementId,
          recipient: {
            ...recipient,
            signUrl,
          },
        },
      });
    } catch (err) {
      this.$aiq.notify.error(`Failed: ${err.message}`);
    }
  }
}
export default toNative(AdobeSignMain);
</script>

<style lang="scss" scoped>
@import "../../../../../../styles/aiq-variables.scss";

.body {
  margin-top: 10px;
  margin-bottom: 10px;
}

.envelope-info {
  padding: 10px;
}

.form-field-container {
  margin-top: 10px;
  .form-field-label {
    display: inline-block;
    width: 115px;
    color: #475669;
    letter-spacing: 0;
    font-family: $aiq-font-family;
    font-size: 14px;
    font-weight: 300;
    margin-top: 5px;
  }
  .get-envelop-btn {
    margin-left: 10px;
  }
}
.template-description {
  margin-bottom: 10px;
}

.template-sub-group {
  margin-top: 10px;
}

.recipient {
  border: gray solid 1px;
  padding: 5px;
  margin-top: 10px;
  h3 {
    margin-bottom: 10px;
  }
}

.button-group {
  display: flex;
  justify-content: flex-end;
  margin: 10px;
}

.selectable {
  cursor: pointer;
}

.table-content {
  height: 50vh;
}

.table-content-table {
  height: 100%;
  overflow-y: scroll;
}

.envelope-sub-details {
  margin-top: 10px;
}

.list-filters {
  display: flex;
  justify-content: flex-end;
  margin: 10px;

  .filter-item {
    width: 40%;
    margin-left: 10px;
  }
}

.recipient-details {
  padding: 5px 10px 10px 10px;
  margin: 5px;
  border: 1px solid grey;
  border-radius: 25px;
}

.active-recipient {
  display: flex;
  justify-content: flex-end;
}
</style>
