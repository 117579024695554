<template lang="pug">
.success-actions-container
  .conditional-success-actions(v-if="useConditionalActions")
    conditional-actions(:conditional-actions="tempConditionalActions"
                        :exclude-action-name="excludeActionName"
                        :use-universal-value-type="useUniversalInputType"
                        :entity-selection-list="entitySelectionList"
                        :operand-entity="fixedEntity"
                        @click="$emit('click', $event)"
                        @update="$emit('update:conditionalActions', $event)")
    .conditional-else-actions-container
      h6 Else (optional)
      draggable(tag="ul"
                class="conditional-else-actions"
                :list="tempConditionalElseActions"
                itemKey="id"
                :group="{name:'unit'}")
        template(v-slot:item="{element, index}")
          action-item(
                    :key="index"
                    :item="element"
                    @delete="onDeleteSuccessAction($event, 'conditional_else_actions')"
                    @click="$emit('click', $event)"
                    :own-id="index"
                    :show-delete="true")
  draggable(v-else
            tag="ul",
            class="middle-column_content-list half-height-list scroll",
            :list="tempSuccessActions"
            itemKey="id"
            :group="{name:'unit'}")
    template(v-slot:item="{element, index}")
      action-item(
                :key="index",
                :item="element",
                @delete="onDeleteSuccessAction($event, 'success_actions')",
                @click="$emit('click', $event)"
                :own-id="index",
                :show-delete="true")
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import camelCase from 'lodash/camelCase';

import ConditionalActions from '../ConditionalActions/ConditionalActions.vue';
import ActionItem from '../ActionItem/ActionItem.vue';
import { NEW_CONDITIONAL_ACTION } from '@/constants/dialogConstants';

export default {
  name: 'actionContainer',
  components: {
    ActionItem,
    ConditionalActions,
  },
  props: {
    conditionalActions: {
      type: Array,
      default: () => [],
    },
    conditionalElseActions: {
      type: Array,
    },
    successActions: {
      type: Array,
    },
    useConditionalActions: Boolean,
    entitySelectionList: {
      type: Array,
      default: [],
    },
    fixedEntity: {
      type: String,
      default: null,
    },
    useUniversalInputType: {
      type: Boolean,
      default: false,
    },
    excludeActionName: {
      type: String,
      default: '',
    },
  },
  emits: ['update:conditionalActions', 'update:conditionalElseActions', 'update:successActions', 'click'],
  data() {
    return {
      /**
       * Using draggable with :list mutates the data so we need to make a copy.
       */
      tempConditionalElseActions: cloneDeep(this.conditionalElseActions),
      tempSuccessActions: cloneDeep(this.successActions),
    };
  },
  computed: {
    tempConditionalActions() {
      if (this.conditionalActions.length === 0) {
        return [NEW_CONDITIONAL_ACTION];
      }

      return this.conditionalActions;
    },
  },
  watch: {
    conditionalElseActions: {
      handler(conditionalElseActions) {
        this.tempConditionalElseActions = cloneDeep(conditionalElseActions);
      },
      deep: true,
    },
    tempConditionalElseActions: {
      handler(conditionalElseActions) {
        if (!isEqual(this.conditionalElseActions, conditionalElseActions)) {
          this.$emit('update:conditionalElseActions', conditionalElseActions);
        }
      },
      deep: true,
    },
    successActions: {
      handler(successActions) {
        this.tempSuccessActions = cloneDeep(successActions);
      },
      deep: true,
    },
    tempSuccessActions: {
      handler(successActions) {
        if (!isEqual(this.successActions, successActions)) {
          this.$emit('update:successActions', successActions);
        }
      },
      deep: true,
    },
  },
  methods: {
    onDeleteSuccessAction(idx, actionType) {
      const actions = cloneDeep(actionType === 'conditional_else_actions'
        ? this.tempConditionalElseActions : this.tempSuccessActions);
      actions.splice(idx, 1);
      this.$emit(`update:${camelCase(actionType)}`, actions);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../../../styles/aiq-mixins.scss";

.success-actions-container {
  border-bottom: 1px solid #BFCBD9;
  margin-bottom: 20px;
  overflow: auto;
  height: 100%;

  .conditional-success-actions {
    .conditional-else-actions-container {
      background-color: #F2F6FF;
      border-radius: 4px;
      margin-bottom: 4px;
    }

    .conditional-else-actions {
      min-height: 38px;
    }
  }
  font-size: 12px;
  font-weight: 300;
}
</style>
