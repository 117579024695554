<template lang="pug">
.container
  segments
  teams   
</template>

<script>
import Segments from './children/Segments/Segments.vue';
import Teams from './children/Teams.vue';

export default {
  name: 'pulseNow',
  components: {
    Segments,
    Teams,
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-button--primary:focus) {
  border-color: transparent;
  background: unset;
  color: #0d8930;
}
</style>