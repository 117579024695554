import axios from 'axios';
import get from 'lodash/get';
import set from 'lodash/set';
import { getUrl } from '@/config/api.routes';
import { composeMessage } from '@/libs';


function getCustomersMessagePayload(customers, message, channel, files, expiration_date) {
  if (message) {
    message = {
      payload: {
        channel: channel || 'webchat',
        ...(expiration_date && { expiration_date }),
        ...composeMessage({ content: message, files }),
      },
    };
  } else {
    message = undefined;
  }
  return {
    payload: {
      customers,
      message,
    },
  };
}

function trimCustomers(customers) {
  return customers.map(c => ({ id: c.id }));
}

export default {
  getCustomers({ commit, rootGetters }, { params, update }) {
    if (update) {
      commit('CLEAR_STATE');
    }

    params.date_type = params.date_type || 'joined_date';

    // set this agent's view scope.
    const views = rootGetters['agent/views'];
    set(params, 'views', views);

    return axios.get(getUrl('dashboard', 'customers'), { params })
      .then((data) => {
        const customers = data.data.models;
        commit('SET_CUSTOMERS', customers);
        commit('SET_TOTAL_CUSTOMERS', get(data, 'data.pagination.rowCount', 0));
        return { customers, pagination: data.data.pagination };
      });
  },

  clearCustomers({ commit }) {
    commit('CLEAR_STATE');
  },

  selectCustomer({ commit }, customer) {
    commit('SELECT_CUSTOMER', customer);
  },

  deselectCustomer({ commit }, customer) {
    commit('DESELECT_CUSTOMER', customer);
  },

  selectCustomers({ commit }, customers) {
    commit('DESELECT_ALL_CUSTOMERS');
    for (const customer of customers) {
      commit('SELECT_CUSTOMER', customer);
    }
  },

  deselectAllCustomers({ commit }) {
    commit('DESELECT_ALL_CUSTOMERS');
  },

  removePrimaryAgentFromSelectedCustomers({ commit }, { customers, message }) {
    return axios.delete(getUrl('dashboard', 'customers/primary_agent'), {
      data: getCustomersMessagePayload(customers, message),
    })
      .then((data) => {
        const customersResponse = data.data.customers;
        commit('UPDATE_PRIMARY_AGENT', customersResponse);
        return customersResponse;
      });
  },

  async updatePrimaryAgentFromSelectedCustomers(_, { customers, message, agent, mode, files }) {
    customers = trimCustomers(customers);
    const requestPayload = getCustomersMessagePayload(customers, message, null, files);
    requestPayload.payload.agent = agent;
    requestPayload.payload.mode = mode;

    const res = await axios.put(getUrl('dashboard', 'customers/primary_agent'), requestPayload);
    return res.data;
  },

  async updateCustomerSegments(_, { mode, customers, segments }) {
    customers = trimCustomers(customers);
    const res = await axios.post(getUrl('bulk', 'customers/segments'), { mode, customers, segments });
    return res.data;
  },

  async updateCustomerAssigedAgents(_, { mode, customers, agents }) {
    customers = trimCustomers(customers);
    const res = await axios.post(getUrl('bulk', 'customers/agents'), { mode, customers, agents });
    return res.data;
  },

  async sendMessageToSelectedCustomers(_,
    { customers, message, channel, files, expirationMessageDate }) {
    customers = trimCustomers(customers);
    const res = await axios.post(
      getUrl('dashboard', 'customers/message'),
      getCustomersMessagePayload(customers, message, channel, files, expirationMessageDate),
    );
    return res.data;
  },

  async sendSurveyToSelectedCustomers(_, { customers, message, surveyType }) {
    customers = trimCustomers(customers);
    const requestPayload = getCustomersMessagePayload(customers, message);
    requestPayload.payload.survey_type = surveyType.toLowerCase();

    const res = await axios.post(getUrl('dashboard', 'customers/survey'), requestPayload);
    return res.data;
  },

  async closeConversationToSelectedCustomers(_, { customers }) {
    customers = trimCustomers(customers);
    const requestPayload = getCustomersMessagePayload(customers);

    const res = await axios.post(getUrl('dashboard', 'customers/close_conversation'), requestPayload);
    return res.data;
  },
};
