import COLORS from './colors';

const customerByLoginsChartConfig = {
  name: 'customers_by_login',
  title: 'Login Frequency',
  linesColor: COLORS.red,
  metric: 'customers_by_login',
};

export default customerByLoginsChartConfig;
