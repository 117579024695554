<template>
<!-- Created with Inkscape (http://www.inkscape.org/) -->

<svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   viewBox="0 0 210 297"
   version="1.1"
   id="svg3838"
   inkscape:version="0.92.3 (2405546, 2018-03-11)"
   sodipodi:docname="document2.svg">
  <g
     inkscape:label="Layer 1"
     inkscape:groupmode="layer"
     id="layer1">
    <g
       style="fill:none;fill-rule:evenodd;stroke:none;stroke-width:1"
       id="-Symbol"
       transform="matrix(7.8532827,0,0,8.0048392,23.604961,28.382768)">
      <g
         style="stroke:#89aaea"
         id="Document-card"
         transform="translate(-12,-12)">
        <g
           id="Group-Copy">
          <g
             id="document-icon"
             transform="translate(12,12)">
            <g
               id="Group-2">
              <path
                 style="fill-rule:nonzero"
                 inkscape:connector-curvature="0"
                 d="M 8.2071068,0.5 0.93933983,7.767767 C 0.65803526,8.0490715 0.5,8.4306024 0.5,8.8284271 V 28 c 0,0.828427 0.6715729,1.5 1.5,1.5 h 18 c 0.828427,0 1.5,-0.671573 1.5,-1.5 V 2 C 21.5,1.1715729 20.828427,0.5 20,0.5 Z"
                 id="Rectangle" />
              <path
                 style="fill-rule:nonzero;stroke-linecap:square"
                 inkscape:connector-curvature="0"
                 d="m 5.5,11.5 h 11"
                 id="Line" />
              <path
                 style="fill-rule:nonzero;stroke-linecap:square"
                 inkscape:connector-curvature="0"
                 d="m 5.5,16.5 h 11"
                 id="Line-Copy-2" />
              <path
                 style="fill-rule:nonzero;stroke-linecap:square"
                 inkscape:connector-curvature="0"
                 d="m 5.5,21.5 h 11"
                 id="Line-Copy-3" />
              <path
                 style="fill-rule:nonzero"
                 inkscape:connector-curvature="0"
                 d="M 7.5,1.2071068 1.2071068,7.5 H 7.5 Z"
                 id="Rectangle-Copy" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
</template>
