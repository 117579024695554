import { getNextCycledPosition } from '@/libs';
import { KEYBOARD_KEYS } from '@/constants';

const SCROLL_PADDING_FACTOR = 2;

export default {
  data() {
    return {
      highlightedKey: null,
      itemElementTag: 'li',
      itemsName: 'items',
      keyName: 'id',
      scrollRefName: 'scrollWrapper',
      useParentOffset: false,
    };
  },
  computed: {
    highlightedIdx() {
      const { itemsName, highlightedKey, keyName } = this;

      if (!itemsName || !this.highlightedKey) { return -1 }

      return this[itemsName]
        .findIndex(item => item[keyName] === highlightedKey);
    },
  },
  mounted() {
    const scrollWrapper = this.$refs[this.scrollRefName];
    if (scrollWrapper) {
      scrollWrapper.addEventListener('keydown', this._onKeyDown);
    }
  },
  beforeUnmount() {
    const scrollWrapper = this.$refs[this.scrollRefName];
    if (scrollWrapper) {
      scrollWrapper.removeEventListener('keydown', this._onKeyDown);
    }
  },
  methods: {
    setHighlightedKey(val) {
      this.highlightedKey = val;
    },

    _moveHighlightUp() {
      this._moveHighlight(-1);
    },
    _moveHighlightDown() {
      this._moveHighlight(1);
    },
    _moveHighlight(idxOffset) {
      const items = this[this.itemsName];

      if (!items.length) { return }

      const params = {
        currentIdx: this.highlightedIdx,
        idxOffset,
        length: items.length,
      };
      // TODO (Gabe) - Add timeout when at bottom to give time to paginate
      this.highlightedKey = items[getNextCycledPosition(params)][this.keyName];
      this._scrollIfNeeded();
    },
    _getListItems() {
      return this.$refs[this.scrollRefName].querySelectorAll(this.itemElementTag);
    },
    _onKeyDown(evt) {
      const { key } = evt;
      evt.preventDefault();

      switch (key) {
        case KEYBOARD_KEYS.DOWN_ARROW:
          this._moveHighlightDown();
          break;
        case KEYBOARD_KEYS.UP_ARROW:
          this._moveHighlightUp();
          break;
        default:
      }
    },
    _scrollIfNeeded() {
      const listItems = this._getListItems();
      const item = listItems[this.highlightedIdx];
      const scrollPadding = item.clientHeight / SCROLL_PADDING_FACTOR;
      const parent = item.parentElement;
      const itemStart = this.useParentOffset ? parent.offsetTop : 0;
      const itemScrollPos = item.offsetTop - itemStart;
      const parentScrollPos = parent.scrollTop;
      const parentHeight = parent.clientHeight;
      const endOfParentScrollPos = parentScrollPos + parentHeight;

      if (itemScrollPos - scrollPadding < parentScrollPos) {
        // item is past the top
        item.scrollIntoView();
      } else if (itemScrollPos + scrollPadding > endOfParentScrollPos) {
        // item is past the bottom
        item.scrollIntoView(false);
      }
    },
  },
};
