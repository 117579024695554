/*
 * In this mixin, we define the Element Plus Icons.
 *
 * 1. Element Plus Icons
 *    https://element-plus.org/en-US/component/icon.html#icon-collection
 *   
 * 2. Usage Example
 *    If we want the clear icon of aiq-select to CircleCloseFilled(default: CircleClose),
 *    define clear-icon to Icon.CircleCloseFilled.
 *      aiq-select(...
 *                 :clear-icon="Icon.CircleCloseFilled"
 *                 ...
 */
import * as Icon from '@element-plus/icons-vue';

export default {
  data() {
    return {
      Icon,
    };
  },
};
