export const AGENT_ONBOARDING = {
  AUTO_OPEN: 'analytics.dashboard.onboarding.auto_open',
  MANUAL_OPEN: 'analytics.dashboard.onboarding.manual_open',
  SKIPPED: 'analytics.dashboard.onboarding.skipped',
  COMPLETED: 'analytics.dashboard.onboarding.completed',
};

export const INACTIVITY_TIMEOUT = {
  UPDATED: 'analytics.dashboard.inactivity.updated',
  LOGGEDOUT: 'analytics.dashboard.inactivity.loggedout',
};

export const AGENT_SUGGESTION = {
  CLICK: 'conversations.suggest.click',
  SEND: 'conversations.suggest.send',
  SHOW: 'conversations.suggest.show',
  EDIT: 'conversations.suggest.edit',
};

const NOTIFICATION = 'analytics.dashboard.notifications';

export const NOTIFICATIONS = {
  BELL_CLICKED: `${NOTIFICATION}.bell_clicked`,
  NOTIFICATION_CLICKED: `${NOTIFICATION}.notification_cliked`,
  NOTIFICATION_DELETE: `${NOTIFICATION}.notification_delete`,
  READ_ALL: `${NOTIFICATION}.read_all`,
};

export const SCHEDULE_TYPE = {
  NOTES: 'fromNotes',
  CALENDAR: 'fromCalendar',
  NOTIFICATIONS: 'fromNotifications',
};

export const MEDIA_TYPE = {
  HISTORY: 'fromHistory',
  KNOWLEDGE_BASE: 'fromKnowledgeBase',
};

export const SIDE_PANEL_PREFIX = 'analytics.dashboard.conversation_right_panel';

export const NOTES = {
  REMOVED: `${SIDE_PANEL_PREFIX}.note.removed`,
  ADDED: `${SIDE_PANEL_PREFIX}.note.added`,
};
export const REMINDERS = {
  REMOVED: `${SIDE_PANEL_PREFIX}.reminder.removed`,
  ADDED: `${SIDE_PANEL_PREFIX}.reminder.added`,
  UPDATED: `${SIDE_PANEL_PREFIX}.reminder.updated`,
  VISIBILITY_TOGGLED: `${SIDE_PANEL_PREFIX}.reminder.visibility_toggled`,
  NOTIFICATION_CLICKED: `${SIDE_PANEL_PREFIX}.reminder.notification_clicked`,
};
export const QUICK_RESPONSE = {
  REMOVED: `${SIDE_PANEL_PREFIX}.quick_response.removed`,
  CREATED: `${SIDE_PANEL_PREFIX}.quick_response.created`,
  ATTACHED: `${SIDE_PANEL_PREFIX}.quick_response.attached`,
  UPDATED: `${SIDE_PANEL_PREFIX}.quick_response.updated`,
  PINNED: `${SIDE_PANEL_PREFIX}.quick_response.pinned`,
  UNPINNED: `${SIDE_PANEL_PREFIX}.quick_response.unpinned`,
};

export const CUSTOMER_INFO = {
  UPDATED: `${SIDE_PANEL_PREFIX}.customer_info.updated`,
};

export const TEAMS = {
  UPDATED: `${SIDE_PANEL_PREFIX}.team.updated`,
};

export const CATEGORIES = {
  UPDATED: `${SIDE_PANEL_PREFIX}.categories.updated`,
};
export const TAGS = {
  UPDATED: `${SIDE_PANEL_PREFIX}.tags.updated`,
};

export const KNOWLEDGEBASE = {
  TAB_CHANGED: `${SIDE_PANEL_PREFIX}.knowlegde_base.tab_changed`,
};

export const ASSETS = {
  SEARCHED: `${SIDE_PANEL_PREFIX}.assets.searched`,
  ATTACHED: `${SIDE_PANEL_PREFIX}.assets.attached`,
  DETACHED: `${SIDE_PANEL_PREFIX}.assets.detached`,
};
export const FILES = {
  ATTACHED: `${SIDE_PANEL_PREFIX}.files.attached`,
};

export const DOCUMENTS = {
  SEARCHED: `${SIDE_PANEL_PREFIX}.documents.searched`,
  ATTACHED: `${SIDE_PANEL_PREFIX}.documents.attached`,
  DETACHED: `${SIDE_PANEL_PREFIX}.documents.detached`,
};

export const AGENT_DOCUMENT = {
  CLICK: 'conversations.kb.documents.click',
  SEND: 'conversations.kb.documents.send',
  SHOW: 'conversations.kb.documents.show',
};

export const AGENT_ASSET = {
  CLICK: 'conversations.kb.assets.click',
  SEND: 'conversations.kb.assets.send',
  SHOW: 'conversations.kb.assets.show',
};

export const AGENT_EVENT = {
  BROWSER_NOTIFICATION: 'conversations.notification.send',
};
