<template lang="pug">
  .side-panel.building-kit
    aiq-collapse(accordion, v-model="activeName").inside-panel
      aiq-collapse-item(title="AI Testing", name="1")
        ai-testing(:focused="isAiTestingFocused")
      aiq-collapse-item(title="Building Kit", name="2", class="building-kit-container")
        building-kit(:focused="isBuildingKitFocused"
                    :show-header="false",
                    :tabs="buildingTabs")
</template>

<script>
import aiTesting from '@/components/AiTesting/AiTesting.vue';
import buildingKit from './BuildingKit/BuildingKit.vue';

import { defaultBuildingKitTabs } from '@/libs';

export default {
  name: 'buildingKitAiTesting',
  components: {
    buildingKit,
    aiTesting,
  },
  data() {
    return {
      buildingTabs: [],
      activeName: '2',
    };
  },
  computed: {
    isAiTestingFocused() {
      return this.activeName === '1';
    },
    isBuildingKitFocused() {
      return this.activeName === '2';
    },
  },
  mounted() {
    this.buildingTabs = defaultBuildingKitTabs.filter(e => e.key !== 'intent');
  },
};
</script>

<style lang="scss" scoped>
.inside-panel {
  width: 306.766px;
}
</style>
