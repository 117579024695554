<template lang="pug">
  management-page.scrollable(title="MetricsTags"
                :creatable="false"
                :searchable="true"
                v-loading="loading && validTagUsage"
                :showHeader="false")
    template(v-slot:body)
      .chart-toolbar
        .dropdown-filters
          .dropdown-filter
            segment-select(:customer-segment-filter-options="CustomerSegmentFilterOptions"
                      :segment-alias="segmentAlias"
                      is-select-multiple
                      is-select-collapse-tags
                      @change="onSegmentFilterChange"
                      @on-segment-hover="onSegmentHover")
          .dropdown-filter
            aiq-select(placeholder="Filter by Tags"
                                      v-model="chosenTagFilterOptions"
                                      multiple
                                      :reserve-keyword="false"
                                      collapse-tags
                                      @change="onTagFilterChange($event)"
                                      value-key='id'
                                      size="small")
              aiq-option(v-for="option in tagListFilterOptions"
                                  :key="option.id"
                                  :label="optionLabel(option)"
                                  :value="option")
          .dropdown-filter
            aiq-select(placeholder="Filter by Agents of Teams"
                                         v-model="chosenAgentTeamFilterOptions"
                                          multiple
                                          :reserve-keyword="false"
                                          collapse-tags
                                          @change="onAgentsTeamsFilterChange($event)"
                                          value-key='id'
                                          size="small")
              aiq-option(v-for="option in agentsTeamsFilterOptions"
                                      :key="option.id"
                                      :label="optionLabel(option)"
                                      :value="option")
        .flex-spacer
          aiq-button-group.breakdown-options
            aiq-button(v-for="option in filteredBreakdownOptions"
                      :key="option.value"
                      size="small"
                      :class="{'selected': selectedBreakdownOption.value === option.value}"
                      @click="setBreakdown(option.value)"
                      ) {{ option.label}}
        .time-range-filter
          aiq-date-picker(v-model="dateRange"
                          type="daterange"
                          @change="handleDatePickerChange($event)"
                          placeholder="Select date range",
                          popper-class="metrics-time-range__date-picker-popup"
                          :shortcuts="datePickerOptions.shortcuts"
                          :disabled-date="datePickerOptions.disabledDate"
                          :teleported="false"
                          format="YYYY/MM/DD"
                          :clearable="false")
      .chart-grid
        aiq-row(:gutter="16")
          aiq-col(:span="12")
              overview-chart(
                          :dateRange="dateRangeUtc"
                          :filters="selectedFilters"
                          :chartConfig="tagsChartConfig"
                          :breakdown="selectedBreakdownOption.value"
                          visualization="aiq-donut-chart")
          aiq-col(:span="12")
              overview-chart(
                          :dateRange="dateRangeUtc"
                          :filters="selectedFilters"
                          :chartConfig="tagsProgressChartConfig"
                          :breakdown="selectedBreakdownOption.value"
                          :formatXTick="selectedBreakdownOption.xAxisFormat"
                          :formatYTick="intCountFormatter"
                          @onChartStatus='onChartStatus($event)'
                          @onChartDataChange='onChartDataChange($event)'
                          yLabel="Count"
                          deriveLinesFromResponse)
      .tags-table-toolbar
        .flex-spacer
        aiq-button.export(@click="exportTable")
          i.iq-ico-export
      aiq-col(:span="24")
        tags-table(:dateRange="dateRangeUtc"
                   ref="TagsTable"
                   :filters="selectedFilters"
                   :namedFilters="namedFilters"
                  )
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import OverviewChart from '@/components/Chart.vue';
import SegmentSelect from '@/components/SegmentsSelect/SegmentsSelect.vue';
import TagsTable from './TagsTable.vue';
import tagsChartConfig from '../../../configs/tagsDistribution';
import tagsProgressChartConfig from '../../../configs/tagsProgress';
import {
  datePickerDefaultOptions,
  selectedBreakdownOption,
  setToEndOfDay,
  defaultDateRange,
  filterBreakdownOptions,
  getNonReactiveDateRange,
  dstDateRangeUtc,
} from '../../../configs/dateRange';
import { intTickFormatter } from '../../../helpers/formatters';
import { loadFilterOptions } from '../../../helpers/filterHelper';
import PlatformFiltersMixin from '../../../Platform/children/selectableFilters/SelectableFilters';

export default {
  pageResource: '/metrics/insights',
  components: {
    TagsTable,
    ManagementPage,
    OverviewChart,
    SegmentSelect,
  },
  computed: {
    ...mapGetters({
      CustomerSegmentFilterOptions: 'configs/segments',
    }),
    datePickerOptions() {
      return datePickerDefaultOptions();
    },
    dateRangeUtc() {
      return dstDateRangeUtc(this.dateRange);
    },
    namedFilters() {
      return {
        customer_segments: this.chosenCustomerSegmentFilterOptions.map(x => x.name),
        teams: this.chosenAgentTeamFilterOptions.map(x => x.name),
      };
    },
    // TODO: Create a mixin that has this computed value,
    // along with other common properties and functions in metrics
    filteredBreakdownOptions() {
      return filterBreakdownOptions(this.dateRange);
    },
  },
  watch: {
    dateRange: debounce(async function onDateRangeChange(newValue, oldValue) {
      if (newValue !== oldValue) {
        await loadFilterOptions(this, this.customerSegmentsEndpoint);
        await loadFilterOptions(this, this.tagsEndpoint);
      }
    }, 1),
    filteredBreakdownOptions(newOptions) {
      if (!newOptions.find(o => o.value === this.selectedBreakdownOption.value)) {
        this.selectedBreakdownOption = newOptions.find(o => o.isDefault);
      }
    },
  },
  mixins: [PlatformFiltersMixin],
  data() {
    return {
      query: null,
      selectedBreakdownOption: selectedBreakdownOption(),
      durationFormatter: this.$aiq.formatters.timeDelta,
      dateRange: defaultDateRange(),
      tagsChartConfig,
      tagsProgressChartConfig,
      intCountFormatter: intTickFormatter,
      customerSegmentsEndpoint: 'metrics_by_customer_segment',
      tagsEndpoint: 'tags_list',
      teamsEndpoint: 'all_teams',
      tagListFilterOptions: [],
      selectedFilters: [],
      chosenCustomerSegmentFilterOptions: [],
      chosenTagFilterOptions: [],
      chosenAgentTeamFilterOptions: [],
      loading: false,
      validTagUsage: false,
      segmentAlias: '',
    };
  },
  methods: {
    exportTable() {
      this.$refs.TagsTable.exportTable();
    },
    setBreakdown(value) {
      this.selectedBreakdownOption = this.filteredBreakdownOptions.find(
        option => option.value === value,
      );
    },
    handleDatePickerChange(newDateRange) {
      this.dateRange = setToEndOfDay(getNonReactiveDateRange(newDateRange));
    },
    onChartStatus(action) {
      if (action === 'onrefresh') {
        this.loading = true;
      } else if (action === 'onrendered') {
        this.loading = false;
      }
    },
    onChartDataChange(data) {
      this.validTagUsage = !isEmpty(data.dataset);
    },
    onSegmentHover(segment) {
      this.segmentAlias = get(segment, 'alias', '');
    },
    onSegmentFilterChange(segments) {
      this.chosenCustomerSegmentFilterOptions = segments;
      this.onCustomerSegmentFilterChange(segments);
    }
  },
  async mounted() {
    this.$store.dispatch('configs/getCustomerSegments');
    await loadFilterOptions(this, this.tagsEndpoint);
    await loadFilterOptions(this, this.teamsEndpoint);
  },
};
</script>

<style lang="scss" scoped>
.scrollable {
  overflow-y: auto;
}

.tags-table-toolbar {
    display: flex;
    margin-bottom: 2px;
    .flex-spacer {
    flex-grow: 1;
  }
}
.chart-grid {
  margin-bottom: 12px;
  .el-col {
    margin-bottom: 16px;
  }
}

.el-card:not(:first-of-type) {
  margin-top: 24px;
}

.chart-toolbar {
  display: flex;
  margin-bottom: 20px;
  .dropdown-filters {
    display: flex;
    align-items: flex-start;
    width: 60%;

    .dropdown-filter {
      margin-right: 12px;
      width: 25em;
      :deep(.el-select) {
        width: 75%;
      }
    }
  }
  .flex-spacer {
    flex-grow: 1;
    .el-button-group .el-button {
      padding: 9px 9px;
    }
  }

   .search {
    max-width: 246px;
  }

  .time-range-filter {
    position: relative;

    margin-left: 16px;
    .el-date-editor.el-input__inner {
      height: 32px;
    }
  }

  .export-command {
    display: flex;
    height: 30px;
    width: 40px;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    padding: 0;
  }

  .el-button.export {
      height: 30px;
      width: 40px;
      padding: 0;
      margin-left: 16px;
  }
}
</style>
