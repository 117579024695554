import { mapActions, mapGetters } from 'vuex';
import { Component, Watch, Vue, toNative } from 'vue-facing-decorator';

import EntityCustomSourceList from './EntityCustomSourceList.vue';
import EntityDefaultSource from './EntityDefaultSource.vue';
import ConfiguredEntities from './EntityList.vue';
import PreconfiguredEntities from './PreconfiguredEntities/PreconfiguredEntities.vue';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';

const PRECONFIGURED_DESCRIPTION = 'System entities that are not configurable.';
@Component({
  name: 'entities',
  components: {
    ConfiguredEntities,
    EntityCustomSourceList,
    EntityDefaultSource,
    ManagementPage,
    PreconfiguredEntities,
  },
  computed: {
    ...mapGetters({
      preconfiguredEntitiesFunc: 'entities/preconfiguredEntities',
    }),
    preconfiguredEntities() {
      return this.preconfiguredEntitiesFunc(this.preconSearchTerms);
    },
  },
  methods: {
    ...mapActions({
      getEntitiesList: 'entities/getEntitiesList',
      getEntitysourcesList: 'entitySources/getEntitysourcesList',
    }),
  },
})

class Entities extends Vue {
  // Default page
  pageIndex = 1;

  preconfiguredDescription = PRECONFIGURED_DESCRIPTION;
  preconSearchTerms = '';

  created() {
    const params = [
      { q: '', limit: 1000, offset: 0 },
      true,
    ];

    this.getEntitysourcesList(params);
    this.getEntitiesList(params);
  }

  @Watch('pageIndex')
  pageIndexWatcher() {
    this.preconSearchTerms = '';
  }

  onMenuSelected(pageIndex) {
    this.pageIndex = parseInt(pageIndex, 10);
  }

  onFetchData({ searchTerm }) {
    this.preconSearchTerms = searchTerm;
  }
}
export default toNative(Entities);
