<template lang="pug">
    management-page(title=""
                  @fetchData="loadList"
                  :creatable="false"
                  :searchable="false")
      template(v-slot:body)
        aiq-table.summary-scrollable(:data="items"
                            ref="summaryTable"
                            v-loading="loading"
                            )
          slot
</template>

<script>
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import isEmpty from 'lodash/isEmpty';

export default {
  name: 'agentSummaryTable',
  components: {
    ManagementPage,
  },
  props: {
    report: {
      type: String,
    },
    dateRange: {
      required: true,
      type: Array,
    },
    breakdown: {
      type: String,
    },
    items: {
      type: Array,
    },
    selectable: {
      type: Boolean,
      default: () => true,
    },
    selectedFilters: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onDataChange'],

  watch: {
    report(value) {
      if (!value) {
        return;
      }
      this.loadMetric();
    },
    selectedFilters() {
      this.loadMetric();
    },
    breakdown(value, oldValue) {
      if (!oldValue || value === oldValue) {
        return;
      }
      this.loadMetric();
    },
    dateRange(value, oldValue = []) {
      const [nStart, nEnd] = value;
      if (nStart && nEnd && nStart == nEnd) {
        return;
      }
      const [start, end] = oldValue;
      if (!start && end) {
        return;
      }
      this.loadMetric();
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    loadList() {
      this.loadMetric();
    },
    loadMetric() {
      // This table is receiving DST adjusted
      // dates in dateRange prop already
      const periodStart = this.dateRange[0];
      const periodEnd = this.dateRange[1];
      if (!(periodStart && periodEnd)) {
        return;
      }
      const params = {
        period_start: periodStart,
        period_end: periodEnd,
      };
      if (this.breakdown) {
        params.bucket = this.breakdown;
      }
      if (!isEmpty(this.selectedFilters)) {
        params.filters = this.selectedFilters;
      }
      return this.$store
        .dispatch('metrics/getMetric', [this.report, params])
        .then(data => {
          this.loading = false;
          this.$emit('onDataChange', data);
        })
        .catch(() => {
          this.$emit('onDataChange', null);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/aiq-mixins.scss";

.sumary-table {
  .content-inner {
    padding: 100px 0 0 0;
  }
}
</style>

<style lang="scss">
@import "../../styles/aiq-mixins.scss";
.summary-scrollable {
  $table-row-height: 40px; //todo extract row height from aiq-components styling
  $max-rows-count: 10;

  .el-table__body-wrapper {
    @include scrollable($table-row-height * $max-rows-count);
  }
}
</style>
