/**
 * this has been phased out to conversations module. Remove
 * when stable
 */

import axios from 'axios';
import get from 'lodash/get';
import { getUrl } from '../../config/api.routes';

import { applyReplyMessages, appendSenderProfile } from '@/libs';

export default {
  namespaced: true,
  state: {
    messages: [],
    typing: false,
    typerAccount: {},
  },
  mutations: {
    LOAD_MESSAGE_LIST(state, [list, update]) {
      let preparedList = applyReplyMessages({
        messages: list,
      });
      preparedList = preparedList.reverse();
      if (update) {
        state.messages = preparedList;
      } else {
        state.messages.unshift(...preparedList);
      }
    },

    ADD_MESSAGE(state, message) {
      let { messages } = state;

      appendSenderProfile(message, this.state.agents);

      if (get(message, 'payload.message_type') === 'reply') {
        for (let i = messages.length - 1; i >= 0; i -= 1) {
          if (messages[i].id === message.payload.reply.postback.reply_to) {
            messages[i].replyitem = message.payload.reply;
            break;
          }
        }
      } else if (messages.find(e => e.id === message.id)) {
        messages = messages.map(e => (e.id === message.id ? message : e));
      } else {
        messages.push(message);
      }
    },

    CLEAR_LIST(state) {
      state.messages = [];
    },

    TYPING_STATUS(state, [value]) {
      state.typing = value;
    },

    UPDATE_MESSAGES_SENDER(state, sender) {
      state.messages = state.messages.map(e => {
        if (e && e.sender_id === sender.id) {
          e.sender = sender;
        }

        return e;
      });
    },
  },
  actions: {
    getMessagesList(
      { commit },
      [id, params, update = true, dataKey = 'data.models'],
    ) {
      if (update) {
        commit('CLEAR_LIST');
        commit('TYPING_STATUS', [false]);
      }
      return axios
        .get(`${getUrl('conversations.conversations', id)}/messages`, {
          params,
        })
        .then(data => {
          commit('LOAD_MESSAGE_LIST', [get(data, dataKey, []), update]);
          return data;
        });
    },

    sendSystemMessage(_, { conversation, action, content }) {
      return axios
        .post(getUrl('postSystemMessage'), { conversation, action, content });
    },

    sendMessage({ commit }, [id, content, attachments = []]) {
      let payload;
      if (attachments && attachments.length) {
        payload = {
          message_type: 'rich_text',
          rich_text: {
            content,
            attachments,
          },
        };
      } else {
        payload = {
          content,
        };
      }
      return axios
        .post(`${getUrl('conversations.conversations', id)}/messages`, payload)
        .then(data => {
          commit('ADD_MESSAGE', data.data);
          return data;
        });
    },

    scheduleMessage(params, [id, content, schedule]) {
      return axios
        .post(`${getUrl('conversations.conversations', id)}/schedule`, {
          content,
          schedule,
        })
        .then(data => data);
    },
  },
};
