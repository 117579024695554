import { mapState, mapMutations, mapGetters } from 'vuex';
import get from 'lodash/get';
import set from 'lodash/set';
import has from 'lodash/has';
import cloneDeep from 'lodash/cloneDeep';
import * as log from 'loglevel';

import SearchContainer from './SearchContainer.vue';
import AssetsList from './Assets/Assets.vue';
import DocumentsList from './Documents/Documents.vue';
import { KB_MODES, KB_MODE_TYPES } from '@/constants';
import { AGENT_ASSET, AGENT_DOCUMENT, MEDIA_TYPE } from '@/constants/analytics';

export default {
  components: {
    AssetsList,
    DocumentsList,
    SearchContainer,
  },
  props: {
    isCollapseSelected: Boolean,
  },
  computed: {
    ...mapState({
      agentProfile: state => state.agent.profile,
      teamList: state => state.teams.teams,
      selectedConversation: state => state.conversations.selected,
      assets: state => state.assets.assets,
      documents: state => state.documents.documents,
      mode: state => state.conversations.KBMode.name,
      kbItems: state => state.conversations.KBMode.items,
      kbConfig: state => state.conversations.KBMode,
      kbType: state => state.conversations.KBMode.type,
    }),
    ...mapGetters({ hasAppendedDocs: 'documents/hasAppendedDocs' }),
    teams() {
      return this.canView('/users/filters/show_all_teams') ? this.teamList : this.agentProfile.teams;
    },
    searchActionPayload() {
      if (this.activeTab === KB_MODE_TYPES.DOCUMENTS) {
        return {
          name: `${this.activeTab}/getDocumentSearchList`,
          params: { teams: this.teams.map(x => x.id) },
        };
      }
      return {
        name: `${this.activeTab}/getAssetSearchList`,
      };
    },
    /**
     * Visible items in KB
     *
     * The items get changed based on the listing mode and tab selection.
     * - default assets/documents
     * - suggested assets/documets
     * - searched assets/documets
     */
    items() {
      let items = this.defaultItems;
      if (this.mode === KB_MODES.SEARCH) {
        items = this.searchedItems;
      } else if (this.mode === KB_MODES.SELECTED) {
        if (this.activeTab === KB_MODE_TYPES.ASSETS) {
          items = this.suggestedItems.map(i => i.payload);
        } else {
          items = this.suggestedItems;
        }
      }
      return items;
    },

    // Searched result from backend is mixed with doc schema and ES
    // here we are trying to make it consistent
    searchedItems() {
      log.debug('searchedResultModels changed', this[`${this.activeTab}SearchedResultModels`]);
      if (this.activeTab === KB_MODE_TYPES.ASSETS) {
        return this.assetsSearchedResultModels.map(asset => ({
          ...asset,
          payload: asset,
        }));
      }
      return this.documentsSearchedResultModels.map(m => {
        const item = cloneDeep(m);
        if (!has(item, 'payload._source.name')) {
          set(item, 'payload._source.name', get(item, 'name', ''));
        }
        if (!has(item, 'payload._source.url')) {
          set(item, 'payload._source.url', get(item, 'file.payload.url', ''));
        }
        if (!has(item, 'payload.highlight')) {
          set(item, ['payload', 'highlight', 'attachment.content'], [get(item, 'description', '')]);
        }
        return item;
      });
    },
    suggestedItems() {
      log.debug('KB items changed', this.kbItems);
      return this.kbItems;
    },
    defaultItems() {
      return (this.activeTab === KB_MODE_TYPES.ASSETS) ? this.defaultAssets : this.defaultDocuments;
    },
    defaultAssets() {
      return this.assets.map(asset => ({
        ...asset,
        payload: asset,
      }));
    },
    defaultDocuments() {
      // The schema follows suggested document.
      return this.documents.map(doc => (
        {
          ...doc,
          payload: {
            _source: {
              name: doc.name,
              url: doc.file.payload.url,

            },
            highlight: {
              'attachment.content': [doc.description],
            },
          },
        }
      ));
    },
  },
  data() {
    return {
      assetsSearchValue: '',
      documentsSearchValue: '',
      showDocSearch: process.env.FEATURE_FLAGS.DOC_SEARCH,
      assetsSearchedResultModels: [],
      documentsSearchedResultModels: [],
      activeTab: KB_MODE_TYPES.ASSETS,
      KB_MODE_TYPES,
    };
  },
  watch: {
    mode(newVal) {
      if (newVal === KB_MODES.SELECTED) {
        this.sendSuggestionShow();
      }
    },
    activeTab() {
      this.setKBMode({ name: this[`${this.activeTab}SearchValue`] ? KB_MODES.SEARCH : KB_MODES.DEFAULT, type: this.activeTab });
    },
    kbType() {
      if (this.activeTab != this.kbType) {
        this.activeTab = this.kbType;
      }
      if (this.mode === KB_MODES.SELECTED) {
        this.sendSuggestionShow();
      }
    },
  },
  beforeUnmount() {
    this.resetKb();
  },
  mounted() {
    this.activeTab = this.kbType;
  },
  methods: {
    ...mapMutations({
      setKBMode: 'conversations/SET_KB_MODE',
      resetKb: 'conversations/RESET_KB',
    }),
    onItemClicked({ type, item }) {
      // FIXME: item schema is different when the item is just clicked from
      // KB and when it is from suggested list.
      const payload = {
        ...item,
        conversation_id: this.selectedConversation.id,
      };

      if (type === KB_MODE_TYPES.DOCUMENTS) {
        if (!this.hasAppendedDocs) {
          this.$store.dispatch('documents/appendDocument', {
            document: item,
            source: MEDIA_TYPE.KNOWLEDGE_BASE,
          });
        } else {
          this.appendDocumentFailure();
        }
      } else {
        this.$store.dispatch('assets/attachAsset', {
          asset: item,
          source: MEDIA_TYPE.KNOWLEDGE_BASE,
        });
      }

      const eventName = (type === KB_MODE_TYPES.DOCUMENTS)
        ? AGENT_DOCUMENT.CLICK : AGENT_ASSET.CLICK;
      this.$store.dispatch('notifications/emitEventToSocket', { name: eventName, payload });
    },

    appendDocumentFailure() {
      this.$aiq.notify.info('Only one document per message is supported');
    },

    sendSuggestionShow() {
      // Only emit show event if element actually has height (not collapsed)
      if (this.mode === KB_MODES.SELECTED) {
        const itemsToEmit = this.suggestedItems.map(item => {
          if (this.activeTab === KB_MODE_TYPES.ASSETS) {
            return {
              id: get(item, 'asset_id'),
              title: get(item, 'payload.title'),
            };
          }

          return {
            id: get(item, 'document_id'),
            url: get(item, 'payload._source.url'),
          };
        });

        const emitObj = {
          conversation_id: this.selectedConversation.id,
          [this.activeTab]: itemsToEmit,
        };
        const eventName = (this.activeTab === KB_MODE_TYPES.ASSETS)
          ? AGENT_ASSET.SHOW : AGENT_DOCUMENT.SHOW;
        this.$store.dispatch('notifications/emitEventToSocket', { name: eventName, payload: emitObj });
      }
    },
    async onSearch(term) {
      log.debug(`onSearch(${term})`);
      try {
        this[`${this.activeTab}SearchValue`] = term;
        if (term) {
          // TODO: store pagination for scrolling
          //       { models, pagination }
          const { models } = await this.$store.dispatch(this.searchActionPayload.name, {
            ...this.searchActionPayload.params,
            query: term,
          });
          this[`${this.activeTab}SearchedResultModels`] = models;
          this.setKBMode({ name: KB_MODES.SEARCH });
        } else {
          this.setKBMode({ name: KB_MODES.DEFAULT });
        }
      } catch (err) {
        log.error(err);
        this.$aiq.notify.error(`Unable to search ${this.activeTab}`);
      }
    },
    async onBack() {
      this.setKBMode({ name: KB_MODES.DEFAULT });
    },
  },
};
