<template lang="pug">
  .message-input-inner
    items-popover(v-if="chatMode === $_chatModes.COMMAND_SELECT"
                  title="Commands"
                  nameKey="chatName"
                  :highlighted="highlighted"
                  :list="items"
                  @select="select")
    template(v-else-if="chatMode === $_chatModes.PARAM_SELECT")
      text-popover(v-if="commandMatched.paramsType === 'textInput'"
                  :event-emitter="chatEventEmitter"
                  :title="commandMatched.prompt"
                  :param="commandParams"
                  @submit="select")
      items-popover(v-else
                    :title="commandMatched.prompt"
                    @select="select"
                    :highlighted="highlighted"
                    :list="items"
                    @event="onItemEvent")
    chat-input(ref="chatInput"
              placeholder="Type your message here..."
              :selected-result-text="selectedResultText"
              :message-permission="messagePermission"
              @resetChatInput="resetChatInput"
              @updateInput="updateInput"
              @systemKeys="keyListener")
      template(v-slot:buttons)
        slot(name="buttons")        

</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash/get';

import ChatInput from './ChatInput.vue';
import ItemsPopover from './ItemsPopover.vue';
import TextPopover from './TextPopover.vue';
import {
  allCommandsBySystemName,
  getCommandState,
} from '@/libs';
import { KEYBOARD_KEYS } from '@/constants';
import { CMD_ARG_TYPE } from '@/constants/iqtool';


const CHAT_INPUT_EXPIRY_TIME = 1000 * 60 * 60 * 24; // eslint-disable-line

const chatModes = {
  MESSAGE: 'MESSAGE',
  SELECTED: 'SELECTED',
  COMMAND_SELECT: 'COMMAND_SELECT',
  PARAM_SELECT: 'PARAM_SELECT',
};

const paramCommandSelectResult = commandName => `${commandName}&nbsp;`;

const doesParamsTypeRequireParam = paramsType => paramsType === CMD_ARG_TYPE.MULTI_SELECT
    || paramsType === CMD_ARG_TYPE.TEXT_INPUT;


export default {
  name: 'chatInputContainer',
  inject: ['chatEventEmitter'],
  components: {
    ChatInput,
    ItemsPopover,
    TextPopover,
  },
  props: {
    cacheKey: {
      type: String,
      default: '',
    },
    messagePermission: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ['messageEntered', 'update:message'],
  data() {
    return {
      selectItemHighlightIndex: 0,
      highlightedIndex: -1,
      highlightedParam: null,
      caretPosition: -1,
      selectedResultTextMsg: '',
    };
  },
  mounted() {
    if (this.cacheKey) {
      this.resetCachedText();
    }
  },
  computed: {
    ...mapGetters({
      allChatCommands: 'configs/chatCommands',
      chatEnabledCommandsLookup: 'configs/chatEnabledCommandsLookup',
      selectedConversation: 'conversations/selected',
    }),

    selectedResultText: {
      get() {
        // Unable to return directly from sessionStorage due to this function is not
        // getting called every call. Ideally, the below statement is a better than
        // getting from this.selectedResultTextMsg.
        // 'return sessionStorage.getItem(this.cacheKey);'
        return this.selectedResultTextMsg;
      },
      set(newValue) {
        if (this.cacheKey) {
          if (newValue.length) {
            sessionStorage.setItem(this.cacheKey, JSON.stringify({
              val: newValue,
              expiry: (new Date()).getTime() + CHAT_INPUT_EXPIRY_TIME,
            }));
          } else {
            sessionStorage.removeItem(this.cacheKey);
          }
        }

        this.selectedResultTextMsg = newValue;
      },
    },

    chatMode() {
      if (this.selectedResultText.startsWith('/')) {
        if (this.caretPosition <= this.commandEndIndex) {
          return chatModes.COMMAND_SELECT;
        }

        if (this.requiresCommandParameters) {
          return chatModes.PARAM_SELECT;
        }

        return chatModes.SELECTED;
      }

      return chatModes.MESSAGE;
    },

    commandQueryInfo() {
      return getCommandState(this.selectedResultText);
    },

    commandMatched() {
      const commandNameQuery = this.commandQueryInfo.commandNameQuery;
      const systemName = this.chatEnabledCommandsLookup[commandNameQuery];
      if (!systemName) {
        return null;
      }

      return allCommandsBySystemName[systemName];
    },
    // List of applicable Commands or Params from query
    items() {
      if (this.chatMode === 'COMMAND_SELECT') {
        const query = this.commandNameQuery.toLowerCase();
        return this.allChatCommands.filter(c => c.chatName?.includes(query));
      } if (this.chatMode === 'PARAM_SELECT') {
        const param = this.commandQueryInfo.param;
        return this.commandMatched.getParamsList(this.$store).filter(p => {
          const name = get(p, 'name', '');
          return name.toString().includes(param);
        });
      }

      return [];
    },
    // Command query info parsed from text input
    commandEndIndex() {
      return this.commandQueryInfo.commandEndIndex;
    },
    commandNameQuery() {
      return this.commandQueryInfo.commandNameQuery;
    },
    requiresCommandParameters() {
      if (!this.commandMatched) {
        return false;
      }

      return doesParamsTypeRequireParam(this.commandMatched.paramsType);
    },
    highlighted() {
      if (this.highlightedIndex < 0) {
        return null;
      }

      return this.items[this.highlightedIndex];
    },
    commandParams() {
      return this.commandQueryInfo.param;
    },
    validQuery() {
      const [commandMatched] = this;
      if (commandMatched && commandMatched.paramsType) {
        return Boolean(this.param);
      }

      return false;
    },
  },
  watch: {
    cacheKey() {
      this.resetCachedText();
      this.chatEventEmitter.emit('clearFiles');
    },
    chatMode(newMode) {
      /**
         *  Although we have selected a command, we reset that state because we want to select
         *  a param
         *
         */
      if (newMode === chatModes.SELECTED &&
          this.selectedResultText === paramCommandSelectResult(this.commandNameQuery)) {
        this.$nextTick(() => {
          this.requestTextChange('');
        });
      }

      this.highlightedIndex = -1;
    },
    selectItemHighlightIndex() {
      this.selectItemHighlightIndex = 0;
    },
  },
  created() {
    this.$_chatModes = chatModes;
  },
  methods: {
    resetCachedText() {
      let text = '';
      const cachedItem = sessionStorage.getItem(this.cacheKey);
      if (cachedItem) {
        const { val, expiry } = JSON.parse(cachedItem);
        if ((new Date()).getTime() > expiry) {
          sessionStorage.removeItem(this.cacheKey);
        } else {
          text = val;
        }
      }
      this.requestTextChange(text);
    },
    keyListener({ key, target }) {
      if (!this.commandNameQuery) {
        if (key === KEYBOARD_KEYS.ENTER) {
          this.$emit('messageEntered', target.textContent || target.innerHTML);
        }
      } else {
        switch (key) {
          case KEYBOARD_KEYS.ENTER:
            if (this.highlighted) {
              this.select(this.highlighted);
              this.highlightedIndex = -1;
            } else {
              try {
                this.commandMatched.run(this.$store, this.commandParams)
                  .then(data => {
                    if (data && data.response) {
                      this.$aiq.notify.info(data.response);
                    }
                    this.resetChatInput();
                    this.chatEventEmitter.emit('clearInput');
                  });
              } catch (error) {
                // TODO - Add more detailed error handling
                this.$aiq.notify.error('Unable to send command. Please check your query.');
              }
            }
            break;
          case KEYBOARD_KEYS.DOWN_ARROW:
            if (this.highlightedIndex === -1) {
              this.highlightedIndex = 0;
            } else {
              this.highlightedIndex = (this.highlightedIndex + 1) % this.items.length;
            }
            break;
          case KEYBOARD_KEYS.UP_ARROW:
            if (this.highlightedIndex <= 0) {
              this.highlightedIndex = this.items.length - 1;
            } else {
              this.highlightedIndex -= 1;
            }
            break;
          case KEYBOARD_KEYS.TAB:
            if (this.highlighted) {
              this.select(this.highlighted);
            }
            break;
          default:
        }
      }
    },
    resetChatInput() {
      this.requestTextChange('');
    },
    select(item) {
      let textInput = '';
      if (this.chatMode === chatModes.COMMAND_SELECT) {
        textInput = item.chatName;

        if (doesParamsTypeRequireParam(item.paramsType)) {
          textInput = paramCommandSelectResult(item.chatName);
        }
      } else if (this.chatMode === chatModes.PARAM_SELECT) {
        const commandNameQuery = this.commandMatched.chatName;
        let param = item;

        if (this.commandMatched.paramsType !== 'textInput') {
          param = item.name;
        }
        textInput = `${commandNameQuery} ${param}`;
      }
      this.$nextTick(() => {
        this.requestTextChange(textInput);
      });
    },
    setSelectedText(text) {
      this.selectedResultText = text;
      this.textInput = text;
      const cleanedText = text.replace(/&nbsp;/, ' ');
      this.caretPosition = cleanedText.length;
    },
    updateInput({ textInput }) {
      this.$emit('update:message', textInput);
      this.setSelectedText(textInput);
    },
    onItemEvent({ name, item }) {
      this.chatEventEmitter.emit(name, item);
    },

    requestTextChange(newValue) {
      // Note: The text should be updated via ChatInput.vue unlike
      //    Typical component that updates via props due to using
      //    contenteditable functionality.
      this.chatEventEmitter.emit('setText', newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../../../../../styles/media-queries.scss";
.message-input-inner {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  padding: 16px 0 16px 16px;
  box-sizing: border-box;
    @include mobile {
    padding-right: 60px;
  }
  &.showPlusButton {
    padding-left: 50px;
  }
}
</style>
