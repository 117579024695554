// TODO (Gabe) Pull example from endpoint

export const ASK_RESOLVE_EXAMPLE = {
  // Not sure about this structure
  payload: {
    message_type: 'replies',
    created_at: '2017-03-19T20:34:20.541Z',
    updated_at: '2017-03-19T20:34:20.541Z',
    replies: {
      content: 'Did we answer your question?',
      attachments: [
        {
          message_type: 'reply',
          reply: {
            content: 'Yes',
            postback: {
              payload: '{"value": "yes", "index": 0}',
              reply_to: 133,
            },
          },
        },
        {
          message_type: 'reply',
          reply: {
            content: 'No',
            postback: {
              payload: '{"value": "no", "index": 1}',
              reply_to: 133,
            },
          },
        },
      ],
    },
  },
};

export const POST_CHAT_DESCRIPTION = 'Configure what happens when a customer or agent ends a conversation.';
