<template>
<svg viewBox="0 0 40 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 62 (91390) - https://sketch.com -->
    <desc>Created with Sketch.</desc>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Calendar" transform="translate(-7.000000, -3.000000)" stroke="white">
            <g transform="translate(7.000000, 3.000000)">
                <rect id="Rectangle" stroke-width="2" x="1" y="8.5" width="38" height="38" rx="4"></rect>
                <path d="M39,18.5 L1,18.5 L1,11.5 C1,10.6715729 1.33578644,9.92157288 1.87867966,9.37867966 C2.42157288,8.83578644 3.17157288,8.5 4,8.5 L36,8.5 C36.8284271,8.5 37.5784271,8.83578644 38.1213203,9.37867966 C38.6642136,9.92157288 39,10.6715729 39,11.5 L39,18.5 Z" id="Rectangle-Copy-2" stroke-width="2"></path>
                <path d="M11.5,2 L11.5,7.5 L9.5,7.5 L9.5,2 C9.5,1.86192881 9.55596441,1.73692881 9.64644661,1.64644661 L11,1.5 C11.1380712,1.5 11.2630712,1.55596441 11.3535534,1.64644661 C11.4440356,1.73692881 11.5,1.86192881 11.5,2 Z" id="Rectangle-Copy" stroke-width="3"></path>
                <path d="M30.5,2 L30.5,7.5 L28.5,7.5 L28.5,2 C28.5,1.86192881 28.5559644,1.73692881 28.6464466,1.64644661 L30,1.5 C30.1380712,1.5 30.2630712,1.55596441 30.3535534,1.64644661 C30.4440356,1.73692881 30.5,1.86192881 30.5,2 Z" id="Rectangle-Copy" stroke-width="3"></path>
            </g>
        </g>
    </g>
</svg>
</template>
