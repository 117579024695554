/**
 * Middle container header
 *
 * @props header The label of title
 * @props title Title text
 * @props showCopy boolean to indicate the visibility of copy button.
 *
 * @event save Title change event triggered by manually editing text.
 * @event copyclick Button click event triggered cy pressing copy button.
 */
import { Component, Watch, Vue, toNative } from 'vue-facing-decorator';

@Component({
  props: {
    header: {
      type: String,
      default: 'Header',
    },
    title: {
      type: String,
      default: 'Title',
    },
    showCopy: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['save', 'copy'],
})

class MiddlePaneHeader extends Vue {
  // flag to go either editing mode or viewing mode
  editMode = false;
  // temporary editing text store
  editingText = '';

  @Watch('title')
  init() {
    this.editMode = false;
    this.editingText = '';
  }

  mounted() {
    this.init();
  }

  onSave() {
    if (!this.editMode) {
      return;
    }

    this.editMode = false;
    if (this.editingText !== this.title) {
      // Let parent know new text is typed
      this.$emit('save', this.editingText);
    }
  }

  onEdit() {
    if (this.editMode) {
      return;
    }

    // Update text with original text
    this.editingText = this.title;
    this.editMode = true;

    this.$nextTick(() => {
      this.$refs.editBox.focus();
    });
  }

  onCopy() {
    this.$emit('copy');
  }
}
export default toNative(MiddlePaneHeader);
