import CategoryBarPerLineChart from './charts/CategoryBar.vue';
import CategoryBarChartNoLines from './charts/CategoryBarWithoutLines.vue';
import BarChart from './charts/Bar.vue';
import Chart from './charts/Base.vue';
import LineChart from './charts/Line.vue';
import HeatMap from './charts/HeatMap.vue';
import FunnelChart from './charts/Funnel.vue';
import DonutChart from './charts/Donut.vue';
import TimelineChart from './charts/Timeline.vue';

const components = [
  BarChart,
  Chart,
  LineChart,
  HeatMap,
  FunnelChart,
  CategoryBarPerLineChart,
  CategoryBarChartNoLines,
  DonutChart,
  TimelineChart,
];

function install(Vue) {
  if (install.installed) {
    return;
  }
  components.forEach(component => {
    if (!component.name) {
      return;
    }
    Vue.component(component.name, component);
  });
}

export default { install };
