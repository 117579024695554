import pick from 'lodash/pick';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import extend from 'lodash/extend';
import intersection from 'lodash/intersection';


export const pickAllOrNoFields = (obj, fields) => {
  if (isEmpty(obj)) {
    throw new Error('an object needs to be specified for pickFields to choose fields from');
  }
  if (isEmpty(fields) || fields.some(f => !get(obj, f))) {
    return obj;
  }
  return pick(obj, fields);
};

export const mergeArrayOfObjs = (x) => reduce(x, extend);

export const doArraysIntersect = (a, b) => !isEmpty(intersection(a, b));
