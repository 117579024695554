<template lang="pug">
.shared-auth-config
  .form-container
    .sub-header--solid Enabled
      aiq-switch.margin-left-10(:model-value="config.enabled" @change="onEnabled"
                                style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA")
  o-auth-configuration(v-if="config.enabled" :config="config.oauth" @change="onChange" :title="title")
</template>

<script>
import OAuthConfiguration from '@/components/OAuth/OAuthConfiguration.vue';

export default {
  name: 'shared-auth-config',
  components: {
    OAuthConfiguration,
  },
  props: {
    name: {
      type: String,
      default: '',
    },

    config: {
      type: Object,
      default: null,
    },

    title: {
      type: String,
      default: '',
    },
  },
  emits: ['update'],

  methods: {
    onChange(value) {
      this.$emit('update', { oauth: value });
    },

    onEnabled(value) {
      this.$emit('update', { enabled: value });
    },
  },
};
</script>

<style lang="scss" scoped>
.margin-left-10 {
  margin-left: 10px;
}
</style>
