import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import merge from 'lodash/merge';
import { dialogConstants } from '@/constants';
import { makeActionsToUnits } from '@/libs';

// Selectively creates copy of dialog
export const convertDialogsForUI = dialogs => dialogs.map(dialog => {
  const dialogType = dialog.payload.dialog_type;
  let params;
  let responsePairs;
  let requestPairs;

  /**
     * Convert request and response mappings to array form:
     * Adds responsePairs and requestPairs keys
     * Removes response_mapping and request_mapping keys
     */
  if (dialogType === 'rest_type') {
    const restDialog = cloneDeep(dialog);
    params = restDialog.payload[dialogType];

    if (params.mode === dialogConstants.MODES.ENTITY) {
      const { response_mapping, request_mapping, ...otherParams } = params;
      responsePairs = response_mapping ? Object.entries(response_mapping) : [];
      requestPairs = request_mapping ? Object.entries(request_mapping) : [];
      requestPairs.forEach(pair => pair.reverse());

      restDialog.payload[dialogType] = {
        ...otherParams,
        responsePairs,
        requestPairs,
      };

      return restDialog;
    }
  }
  return dialog;
});

/**
 * Convert request and response mappings to object form:
 * Removes responsePairs and requestPairs keys
 * Adds response_mapping and request_mapping keys
 */
export const convertRestDialogFromUI = dialog => {
  const dialogType = dialog.payload.dialog_type;
  const params = dialog.payload[dialogType];

  if (params.mode === dialogConstants.MODES.ENTITY) {
    const { requestPairs, responsePairs, ...otherParams } = params;

    const request_mapping = {};
    const response_mapping = {};

    requestPairs.forEach(pair => {
      request_mapping[pair[1]] = pair[0];
    });
    responsePairs.forEach(pair => {
      response_mapping[pair[0]] = pair[1];
    });

    dialog.payload[dialogType] = {
      ...otherParams,
      request_mapping,
      response_mapping,
    };

    return dialog;
  }

  return dialog;
};

export const arePairsValid = (pairs = []) => {
  for (const pair of pairs) {
    const [left, right] = pair;
    if (!left || !right) {
      return false;
    }
  }

  return true;
};

export const makeActionsToUnitsForDialog = dialogParams => {
  makeActionsToUnits(dialogParams.success_actions);
  makeActionsToUnits(dialogParams.failure_actions);
  makeActionsToUnits(dialogParams.conditional_else_actions);

  dialogParams.conditional_actions.forEach(condition => {
    makeActionsToUnits(condition.actions);
  });
};

export const fillAddedFields = dialog => {
  const type = get(dialog, 'payload.dialog_type');
  const targetSchema = Object.values(dialogConstants.MODELS)
    .find(d => d.dialog_type === type);

  if (!type || !targetSchema) {
    return dialog;
  }

  dialog.payload = merge(cloneDeep(targetSchema), dialog.payload);
  return dialog;
};

export const confirmBeforeDialogClose = (ctx = {}, okCb = () => {}, cancelCb = () => {}) => {
  ctx.$aiq.confirm(
    ctx.$t('modal_editor.before_close_confirm.title'),
    ctx.$t('modal_editor.before_close_confirm.message'),
  ).then(() => {
    okCb();
  }).catch(() => {
    cancelCb();
  });
};
