<template lang="pug">
  .content-inner.channels
    aiq-tabs(type="border-card")
      aiq-tab-pane(label="Webchat UI")
        webchat-tab(:webform="webform"
                    @update="updateWebchat")
      aiq-tab-pane(label="Webchat Configuration")
        webchat-configuration-tab
      aiq-tab-pane(label="Theme")
        theme-tab
      aiq-tab-pane(label="Tools")
        tools-tab
</template>

<script>
import { mapState } from 'vuex';
import { WebchatTab, WebchatConfigurationTab, ThemeTab, ToolsTab } from './Tabs';

export default {
  name: 'channels',
  components: {
    WebchatTab,
    WebchatConfigurationTab,
    ThemeTab,
    ToolsTab,
  },
  computed: {
    ...mapState({
      webform: state => state.configs.channels.webchat.webform,
    }),
  },
  mounted() {
    this.$store.dispatch('configs/getWebchatWebform');
  },
  methods: {
    updateWebchat(webform) {
      this.$store.dispatch('configs/setWebchatWebform', webform)
        .then(() => {
          this.$aiq.notify.success('Webform configuration has been saved.');
        }).catch(() => {
          this.$aiq.notify.error('Unable to save webform.');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .el-tab-pane .content-inner{
    padding: 0
  }
</style>

<style lang="scss">
.content-inner.channels {
  .el-tabs__content {
    overflow-y: auto;
    height: calc(100vh - 118px);
  }
}
</style>
