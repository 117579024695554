<template lang="pug">
  .timerange
    input-range(v-model="hours",
              @change="changeHours",
              :readonly="readonly")
    input-range(v-model="minutes",
              @change="changeMinutes",
              :readonly="readonly")
    input-range(v-model="seconds",
              v-if="showSeconds",
              @change="changeSeconds",
              :readonly="readonly")
    md-button-toggle
      md-button.button-toggle(:class="{'md-toggle': isPM}", @click="changeType()") {{ isPM ? "PM" : "AM" }}
</template>

<script>
import inputRange from './InputRange.vue';

const AM_PM_MAX_HOUR = 12;
const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;

export default {
  name: 'timeRange',
  components: {
    inputRange,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    showSeconds: {
      type: Boolean,
      default: () => false,
    },
    showSeparator: {
      type: Boolean,
      default: () => false,
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    step: {
      type: Number,
      default: () => 1,
    },
    secondStep: {
      type: Number,
      default: () => 1,
    },
  },
  emits: ['input'],
  watch: {
    value() {
      this.prepareDate();
    },
  },
  data() {
    return {
      temporalDate: null,
      isPM: false,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  },
  methods: {
    changeHours(value) {
      if (value <= 0 || value > AM_PM_MAX_HOUR) {
        this.isPM = !this.isPM;
        value = value <= 0 ? AM_PM_MAX_HOUR : 1;
      }
      this.hours = value;
      this.temporalDate.setHours(this.isPM ? this.hours + AM_PM_MAX_HOUR : this.hours);
      this.emitChanges();
    },

    changeMinutes(value) {
      if (value <= 0 || value >= MINUTES_IN_HOUR) {
        if (value <= 0) {
          this.hours -= 1;
        } else {
          this.hours += 1;
        }
        this.changeHours(this.hours);
        value = value <= 0 ? (MINUTES_IN_HOUR - this.step) : 1;
      }
      this.minutes = value;
      this.temporalDate.setMinutes(this.minutes);
      this.emitChanges();
    },

    changeSeconds(value) {
      if (value <= 0 || value >= SECONDS_IN_MINUTE) {
        if (value <= 0) {
          this.minutes -= 1;
        } else {
          this.minutes += 1;
        }
        this.changeMinutes(this.minutes);
        value = value <= 0 ? (SECONDS_IN_MINUTE - this.secondStep) : 1;
      }
      this.seconds = value;
      this.temporalDate.setSeconds(this.seconds);
      this.emitChanges();
    },

    changeType() {
      this.isPM = !this.isPM;
      this.isPM && (this.temporalDate.setHours(this.hours + AM_PM_MAX_HOUR));
      this.emitChanges();
    },

    emitChanges() {
      this.$emit('input', this.temporalDate.toISOString());
    },

    prepareDate() {
      this.temporalDate = new Date(this.value);
      let hours = this.temporalDate.getHours();
      if (hours > AM_PM_MAX_HOUR) {
        hours %= AM_PM_MAX_HOUR;
        this.isPM = true;
      } else if (hours > 0) {
        this.isPM = false;
      }
      this.hours = hours === 0 ? AM_PM_MAX_HOUR : hours;
      this.minutes = this.temporalDate.getMinutes();
      this.seconds = this.temporalDate.getSeconds();
    },
  },
  mounted() {
    this.prepareDate();
  },
};
</script>

<style lang="scss" scoped>
.timerange {
  display: flex;
  width: 50%;
}
.md-theme-agentiq {

  .md-button-toggle {
    align-items: center;

    .button-toggle {
      min-width: inherit;
      min-height: 32px;
      padding: 0 15px;
      margin-left: 5px;
      color: #525b66;
      font-size: 14px;
      line-height: 32px;
      font-weight: 300;
      border-radius: 4px;
      text-transform: none;
      background-color: #f0f5f9;
    }
  }
  .button-toggle:hover:not([disabled]):not(.md-raised) {
    background-color: #f0f5f9;
  }
}
</style>
