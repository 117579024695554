<template lang='pug'>
.json-linter
  aiq-input(:model-value="modelValue"
            @update:modelValue="$emit('update:modelValue', $event)"
            @change="$emit('change', $event)"
            @keyup="$emit('keyup', $event)"
            autosize
            :placeholder='placeholder'
            size="small"
            type="textarea")
  .error-text.text-danger(v-if="modelValue && jsonerror") {{ jsonerror }}
  .success-text.text-success(v-if="!jsonerror") Valid JSON ✔
</template>

<script>
export default {
  el: 'json-linter',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  computed: {
    jsonerror() {
      try {
        // try to parse
        JSON.parse(this.modelValue);
      } catch (e) {
        return JSON.stringify(e.message);
      }

      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.text-danger {
  color: #F56C6C
}

.text-success {
  color: #67C23A
}

</style>
