import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import toPlainObject from 'lodash/toPlainObject';

import formIntent from './intents/formIntent';
import { getUrl } from '@/config/api.routes';
import { makeActionsToUnits } from '@/libs';

export default {
  namespaced: true,

  state: {
    items: [],

    selected: null,

    intentsLoaded: false,

    apply: {},
  },

  modules: {
    formIntent,
  },

  getters: {
    selected: state => state.selected,
  },

  mutations: {
    CLEAR_INTENTS(state) {
      state.items = [];
      state.intentsLoaded = false;
    },

    GET_LIST_SUCCESS(state, data) {
      if (data.updated) {
        state.items = data.list;
      } else {
        state.items.push(...data.list);
      }
    },

    GET_SUCCESS(state, intent) {
      state.items = uniqBy([...state.items, intent], 'id');
      state.selected = intent;
      state.formIntent = cloneDeep(intent);
    },

    UPDATE_INTENT_SUCCESS(state, intent) {
      state.items = state.items.map(e => {
        if (e.id === intent.id) {
          e = intent;
        }

        return e;
      });
    },

    CREATE_INTENT_SUCCESS(state, intent) {
      state.items.unshift(intent);
    },

    DELETE_INTENT_SUCCESS(state, id) {
      state.items = state.items.filter(e => e.id !== id);

      if (id === state.selected.id) {
        const newSelectedIntent = state.items[0] || null;
        state.selected = newSelectedIntent;
        state.formIntent = cloneDeep(newSelectedIntent);
      }
    },

    MARK_AS_LOADED(state, value) {
      state.intentsLoaded = value;
    },

    CLEAR_SELECTED(state) {
      state.selected = null;
    },

    UP_INTENT(state, name) {
      const tempIntent = state.items.find(e => e.name === name);
      state.items = [tempIntent, ...state.items.filter(e => e.name !== name)];
      state.selected = tempIntent;
    },

    RESET_FORM(state, newFormIntent) {
      state.formIntent = newFormIntent;
    },
  },

  actions: {
    getAll({ commit }, params = null) {
      return axios.get(getUrl('aimanager.intents'), { params })
        .then(data => {
          const intents = data.data.data;
          commit('GET_LIST_SUCCESS', {
            list: intents,
            updated: (params.offset === 0),
          });
          return data;
        });
    },

    markAsLoaded({ commit }, value) {
      commit('MARK_AS_LOADED', value);
    },

    getIntent({ commit }, id) {
      return axios.get(getUrl('aimanager.intents', id)).then(data => {
        commit('GET_SUCCESS', data);
        return data;
      });
    },

    updateIntent({ commit, state }, intentFromState) {
      if (!intentFromState) {
        intentFromState = state.intents.formIntent;
      }

      const intent = cloneDeep(toPlainObject(intentFromState));

      makeActionsToUnits(intent.actions);
      return axios.put(getUrl('aimanager.intents', intent.id), intent).then(data => {
        if (!data.data.errors) {
          commit('UPDATE_INTENT_SUCCESS', data.data);
        }
        return data;
      });
    },

    upIntent({ commit }, name) {
      commit('UP_INTENT', name);
    },

    addIntent({ commit }, intent) {
      return axios.post(getUrl('aimanager.intents'), intent).then(data => {
        commit('CREATE_INTENT_SUCCESS', data.data);
        return data;
      });
    },

    deleteIntent({ commit }, id) {
      return axios.delete(getUrl('aimanager.intents', id)).then(data => {
        commit('DELETE_INTENT_SUCCESS', id);
        return data;
      });
    },

    selectIntent({ commit }, id) {
      return axios.get(getUrl('aimanager.intents', id)).then(data => {
        commit('GET_SUCCESS', data.data);
        return data;
      });
    },

    resetForm({ commit, getters }) {
      commit('RESET_FORM', cloneDeep(getters.selected));
    },
  },
};
