<template lang="pug">
  side-bar-vue(:baseResource="baseResource" :routeChildren="routeChildren")
</template>

<script>
import { Component, Vue, toNative } from 'vue-facing-decorator';
import SideBarVue from '@/components/layouts/SideBarVue.vue';
import SettingRoutes from './Settings.router';

@Component({
  components: {
    SideBarVue,
  },
})
class Settings extends Vue {
  baseResource = SettingRoutes.path;
  routeChildren = SettingRoutes.children;
}
export default toNative(Settings);
</script>

<style lang="scss">
  .content-inner {
    .el-tabs--border-card {
      border-bottom-width: 0px;
      box-shadow: none;
    }
  }
</style>
