<template lang="pug">
  side-bar-vue(:baseResource="baseResource" :routeChildren="routeChildren")
</template>

<script>
import SideBarVue from '@/components/layouts/SideBarVue.vue';
import MetricsRoutes from './Metrics.router';

export default {
  components: {
    SideBarVue,
  },
  data() {
    return {
      baseResource: MetricsRoutes.path,
    };
  },
  computed: {
    routeChildren() {
      const route = MetricsRoutes.children.filter(({ path, children }) => {
        const filteredChildren = children.filter(child => this.canView(`${this.baseResource}/${path}/${child.path}`));
        return filteredChildren.length;
      });
      return route;
    },
  },
};
</script>

<style>
</style>
