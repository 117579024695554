<template lang="pug">
  modal-editor.edit-document-modal(:visible="visible"
                                :show-close="false"
                                recordType="Document"
                                :record="record"
                                :buttons="['close', 'delete', 'save']"
                                :afterSave="afterSave"
                                :preparePayload="preparePayload"
                                :isRecordValid="isSubmittable"
                                :skipConfirmation="!isRecordDirty"
                                ref="modalEditor")
    template(v-slot:content)
      .field-container.is-required
        .document-title-header
          label Document
          aiq-checkbox(:model-value="isPublic" id="public" @change="onPublicCheckChange" :disabled="!isNewDocDialog") Public
          aiq-tooltip.help-icon(placement="right")
            template(v-slot:content)
              div(v-html="'Check if the image can be shared publicly'")
            i.iq-ico-explanation(class="tooltip-help-icon")

        .provide-type-container
          aiq-upload(ref="upload"
                    :action="'placeholder-url'"
                    accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    :auto-upload="false"
                    :on-change="onUploadChange"
                    :show-file-list="false")
            template(v-slot:trigger)
              aiq-button(type="primary" size="small") Upload
          span or
          span Paste URL
          aiq-input.provide-type_input(v-model="record.url"
                                      name="url"
                                      size="small")
        .provide-type-warning_text
          | Accepts searchable documents (pdf, spreadsheets, word, powerpoint, html, etc).
        .provide-type-warning_text
          | File must not exceed 500kb.
      .field-container.field-title.is-required
        label Title
        .title-container
          aiq-input(v-model="record.title"
                    name="title"
                    size="small")
            template(v-slot:suffix)
              .file-type {{record.fileExt}}
      .field-container.full-width
        label Description
        aiq-input.description_input(type="textarea"
                                    placeholder="..."
                                    v-model="record.description"
                                    name="description")
      .field-container.full-width
        label Teams
        .select-box-container
          aiq-select(v-model="record.teams"
                    value-key="id"
                    size="small"
                    multiple
                    :reserve-keyword="false")
            aiq-option(v-for="item in teamOptions", :key="item.id", :label="item.name", :value="item")

</template>

<script>
import get from 'lodash/get';
import { mapState, mapGetters } from 'vuex';
import { hosts } from '@/config/api.routes';
import Editor from '@/components/Editor/Editor.vue';
import ModalEditor from '@/components/ModalEditor/ModalEditor.vue';
import { fileNameParser } from '@/libs';
import { TEAM_LIMIT } from '@/constants/pagination';
import { IMAGE_ACL_TYPES } from '@/constants';
import isEmpty from 'lodash/isEmpty';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  name: 'documentEditor',
  extends: Editor,
  components: {
    ModalEditor,
  },
  setup () {
    return { v$: useVuelidate() };
  },
  validations () {
    return {
      record: {
        file: { $autoDirty: true },
        title: { required, $autoDirty: true},
        description: { $autoDirty: true },
        teams: { $autoDirty: true },
      },
    };
  },
  computed: {
    ...mapState({
      agentProfile: state => state.agent.profile,
      selectedDocument: state => state.documents.selected,
    }),
    ...mapGetters({
      sortedTeams: 'teams/sortedTeams',
    }),
    isNewDocDialog() {
      return !this.record.id;
    },
    isPublic() {
      if (!this.isNewDocDialog) {
        return get(this.record, 'acl', 'private') === IMAGE_ACL_TYPES.PUBLIC;
      }
      return this.publicCheck;
    },
    teamOptions() {
      return this.canView('/users/filters/show_all_teams') ? this.sortedTeams : this.agentProfile.teams;
    },

    isRecordDirty() {
      return this.v$.$anyDirty;
    },

    isRecordValid() {
      return !this.v$.$invalid;
    },
  },
  data() {
    return {
      fetchAction: 'documents/getDocument',
      modelPath: 'documents/documentModel',
      selectAction: 'documents/selectDocument',
      publicCheck: false,
    };
  },
  watch: {
    'record.url': function urlWatcher(url) {
      if (url) {
        this.record.file = null;
      }
    },
  },
  methods: {
    async resolveRecord() {
      await this.$store.dispatch('teams/getTeamsList', [{ limit: TEAM_LIMIT }]);
      const { id } = this.$route.params;
      const teamIds = this.teamOptions.map(x => x.id);
      return (id === 'new')
        ? Promise.resolve(this.record)
        : this.$store.dispatch(this.fetchAction, [id, teamIds]).then(response => response.data);
    },
    afterSave(savedDocument) {
      // modified it just to get rid of the console error,
      // but there seems to be no need to call this fetchAction here.
      const teamIds = this.teamOptions.map(x => x.id);
      this.$store.dispatch(this.fetchAction, [savedDocument.id, teamIds]);
    },
    getRequestMethod() {
      return `${hosts.management}documents`;
    },
    onUploadChange(data) {
      this.record.url = '';
      this.record.file = data;

      const [title, fileExt] = fileNameParser(data.name);

      this.record.title = title;
      this.record.fileExt = fileExt;
    },
    preparePayload(record) {
      const formData = new FormData();
      formData.append('title', record.title);
      formData.append('description', record.description);
      if (record.teams) {
        record.teams.forEach((t) => {
          formData.append('teams[]', t.id);
        });
      }
  
      formData.append('permissions', !isEmpty(record.teams));

      if (record.url && record.url !== this.selectedDocument.url) {
        formData.append('url', record.url);
      } else if (record.file) {
        formData.append('file', record.file.raw);

        if (this.publicCheck) {
          formData.append('acl', IMAGE_ACL_TYPES.PUBLIC);
        }
      }
      const preparedRecord = { formData };

      if (record.id) {
        preparedRecord.id = record.id;
      }

      return preparedRecord;
    },
    closeEditor() {
      this.visible = false;
    },

    onPublicCheckChange(value) {
      this.publicCheck = value;
    },
  },
};
</script>

<style lang="scss">
.edit-document-modal {
  .el-dialog__body {
    padding: 20px 15px 0 15px;

    .field-title {
      margin: 14px 0;
    }

    .provide-type-container {
      display: flex;
      align-items: center;

      > span {
        white-space: nowrap;
        font-weight: 100;
        margin: 0 5px;
      }

      .provide-type_input {
        margin-left: 5px;
        margin-bottom: 10px
      }

      div {
        .el-button {
          height: 30px;
          margin-right: 5px;

          span {
            font-size: 14px;
          }
        }
      }
    }

    .provide-type-warning_text {
      font-size: 12px;
      font-weight: 100;
    }

    .title-container {
      align-items: center;
      display: flex;

      .el-input {
        display: flex;

        .el-input__inner {
          border-radius: 4px 0px 0px 4px;
        }

        .file-type {
          background-color: #eef1f6;
          border: 1px solid #bfcbd9;
          border-left-width: 0;
          border-radius: 0px 4px 4px 0px;
          font-size: 12px;
          font-weight: 100;
          height: 30px;
          line-height: 25px;
          padding-left: 10px;
          text-align: left;
          width: 54px;
        }
      }
    }

    .description_input {
      textarea {
        font-size: 12px;
        font-weight: 300;
      }
    }
  }

    .el-dialog__footer {
      padding-top: 1px;
    }
}
</style>

<style lang="scss" scoped>
.document-title-header {
  display: flex;
  width: 200px;
}

.is-required {
  .el-checkbox::before {
    margin-left: 10px;
    content: "" !important;
    color: #FF0000;
  }
}

.tooltip-help-icon {
  margin-left: 5px;
  margin-top: 3px;
}

.help-icon {
  margin-left: 5px;
}
</style>
