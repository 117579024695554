<template lang="pug">
layout-main(:showSidebar="false")
  aiq-dialog(v-model="showFiltersDialog"
            title="Filters"
            class="filters-modal-container"
            show-close
            close-on-click-modal
            close-on-press-escape
            ref="filtersPanel")
    aiq-row(:gutter="20")
      aiq-col(:sm="12")
        .label {{ $t('user_tab.table.filters.last_login_time') }}
        aiq-date-picker(v-model="dateRange"
                  @change="handleDatePickerChange($event)"
                  type="daterange"
                  placeholder="Select date range",
                  start-placeholder="Start date",
                  end-placeholder="End date"
                  class="agents-dashboard-date-picker"
                  popper-class="date-picker-popup"
                  :default-time="defaultTime"
                  :disabled-date="disabledDate"
                  format="YYYY/MM/DD"
                  :clearable="true")
      aiq-col(:sm="12")
        .label {{ $t('user_tab.table.filters.roles') }}
        aiq-select(placeholder="Select"
                  v-model="roleSelectedOptions"
                  size="small"
                  collapse-tags
                  multiple
                  :reserve-keyword="false")
          aiq-option(v-for="option in roles", :key="option.name", :label="option.label", :value="option.name")
    aiq-row(:gutter="20")
      aiq-col(:sm="12")
        .label {{ $t('user_tab.table.filters.current_status') }}
        aiq-select(placeholder="Select"
                    v-model="availabilitySelectedOptions"
                    size="small"
                    collapse-tags
                    multiple
                    :reserve-keyword="false")
            aiq-option(v-for="option in availabilityOptions", :key="option.field", :label="option.label" :value="option.field")
              agent-status(:available="option.field" :show-label="true")
      aiq-col(:sm="12")
        .label {{ $t('user_tab.table.filters.teams') }}
        aiq-select(placeholder="Select"
                    v-model="teamSelectedOptions"
                    value-key="id"
                    size="small"
                    collapse-tags
                    multiple
                    :reserve-keyword="false")
          aiq-option(v-for="item in teamOptions", :key="item.id", :label="item.name", :value="item")
    template(v-slot:footer)
      .dialog-footer
        aiq-button(@click="clearSearch") Clear
        aiq-button(type="primary" @click="applySearch") Apply
      
  managementPageWithActions.scrollable(title="Agents"
                classPrefix="agents"
                @fetchData="loadList"
                @chooseAction="chooseAction"
                @loadAll="loadAll",
                @stopDataLoading="stopLoadAll",
                :isDataLoading="isLoadingAll",
                :chosenAction="chosenAction"
                :loadWithQuery="$route.query.query"
                :searchPageSize="searchPageSize"
                :actionOptions="actionOptionsKeys"
                :showLoadAll="true"
                :showTotals="true"
                :filteredSearch="true"
                :pagination="pagination"
                :creatable="false"
                :openFilters="isFilterOpen"
                :helpText="helpText"
                :actionsErrorText="actionsErrorText"
                :hasRowSelections="hasRowSelections"
                ref="managementPage")
    template(v-slot:actions)
      aiq-button(link type="primary"
               class="filter-button"
               @click="showFiltersDialog = true") Filters
        span(v-if="filtersCount") ({{ filtersCount }})
        fa-icon(:icon="['fas', 'filter']")

    template(v-slot:editor)
      actions-panel(v-if="actionFormShown"
                  v-bind="{...actionCardParams}"
                  @commitAction="commitAction"
                  @closeActionPanel="closeActionPanel")
      router-view(v-bind="{...childViewProps}")

    template(v-slot:body v-if="isMobileContext")
      aiq-table.agents-scrollbar(
              :data="agents"
              style="width: 100%"
              :default-sort="sortOrder"
              v-loading="actionInProgress"
              @selection-change="onSelect")
        aiq-table-column(type="selection",
                         width="48px",
                         label-class-name="is-bordered")
        aiq-table-column(type="expand")
          template(v-slot:default="item")
            p Role:  
              span(v-for="role in getRolesSafe(item.row).filter(role => role!=='webchat')") {{ $filters.toUpperFirst(role) }} &nbsp;
            p Teams:
              .tag-list
                aiq-tag(v-for="team in item.row.teams" :key="team.name") {{ $filters.toUpperFirst(team.name) }} &nbsp;
            p Availability: 
              agent-status(:available="item.row.available" :show-label="true")
            p.customers-span Primary Customers:
              aiq-button(link type="primary" size="small", @click="filterToCustomersDashboardWithAgent(item.row, 'primary', 'primary_customers_served')") {{ item.row.primary_customers_served }} &nbsp;
            p.cutomers-serverd-span Active Non-Primary Customers:
              aiq-button(link type="primary" size="small", @click="filterToCustomersDashboardWithAgent(item.row, 'active_non_primary_customers')") {{ item.row.active_non_primary_customers }} &nbsp;
            p.cutomers-serverd-span Previously Served Customers:
              aiq-button(link type="primary" size="small", @click="filterToCustomersDashboardWithAgent(item.row, 'customers_served')") {{ item.row.customers_served }} &nbsp;
            aiq-button(link type="primary" size="small", @click="openAgentDetail(item.row)") Detail
        aiq-table-column(label="Name", :sortable="true", :sort-method="onSortName", label-class-name="is-bordered")
          template(v-slot:default="scope")
            span {{ $filters.toUpperFirst(scope.row.fullName) }} &nbsp;
        aiq-table-column(prop="last_login_time", label="Last Login Time", :sortable="true", :sort-method="onSortLastLoginTime", label-class-name="is-bordered")
          template(v-slot:default="scope")
            span {{ getFormattedLastLoginTime(scope.row) }} &nbsp;

    template(v-slot:body v-if="!isMobileContext")
      aiq-table.agents-scrollbar(
              :data="agents"
              :default-sort="sortOrder"
              v-loading="actionInProgress"
              @selection-change="onSelect")
        aiq-table-column(type="selection",
                         width="48px",
                         label-class-name="is-bordered")
        aiq-table-column(label="Name", :sortable="true", :sort-method="onSortName", label-class-name="is-bordered")
          template(v-slot:default="scope")
            span {{ $filters.toUpperFirst(scope.row.fullName) }} &nbsp;
        aiq-table-column(label="Roles", :sortable="true", :sort-method="onSortRole", label-class-name="is-bordered")
          template(v-slot:default="scope")
            span(v-for="role in getRolesSafe(scope.row).filter(role => role!=='webchat')") {{ $filters.toUpperFirst(role) }} &nbsp;
        aiq-table-column(label="Teams", label-class-name="is-bordered")
          template(v-slot:default="scope")
            .tag-list
              aiq-tag(v-for="team in scope.row.teams" :key="team.name") {{ $filters.toUpperFirst(team.name) }} &nbsp;
        aiq-table-column(prop="last_login_time", label="Last Login Time", :sortable="true", :sort-method="onSortLastLoginTime", label-class-name="is-bordered")
          template(v-slot:default="scope")
            span {{ getFormattedLastLoginTime(scope.row) }} &nbsp;
        aiq-table-column(label="Current Status", :sortable="true", :sort-method="onSortAvailable", label-class-name="is-bordered")
          template(v-slot:default="scope")
            agent-status(:available="scope.row.available" :show-label="true")
        aiq-table-column(:label="$t('user_tab.table.column_label.primary_customers')",
                         :sortable="true"
                         :sort-method="onSortCustomersServedPrimary"
                         label-class-name="is-bordered break-word d-flex")
          template(v-slot:default="scope")
            aiq-button(link type="primary" size="small", @click="filterToCustomersDashboardWithAgent(scope.row, 'primary', 'primary_customers_served')") {{ scope.row.primary_customers_served }} &nbsp;
        aiq-table-column(:label="$t('user_tab.table.column_label.active_non_primary_customers')", 
                        :sortable="true", 
                        :sort-method="onSortActiveNonPrimaryCustomers", 
                        label-class-name="is-bordered break-word d-flex")
          template(v-slot:header)
            span {{ $t("user_tab.table.column_label.active_non_primary_customers") }} &nbsp;
            aiq-tooltip(effect="dark"
                        placement="top"
                        popper-class="explanation-tooltip"
                        content="These are current assignments where the agent is not the primary agent."
                        :show-after="1")
              i.iq-ico-explanation
          template(v-slot:default="scope")
            aiq-button(link type="primary" size="small", @click="filterToCustomersDashboardWithAgent(scope.row, 'active_non_primary_customers')") {{ scope.row.active_non_primary_customers }} &nbsp;
        aiq-table-column(:label="$t('user_tab.table.column_label.previously_served_customers')", 
                        :sortable="true", 
                        :sort-method="onSortCustomersServed", 
                        label-class-name="is-bordered break-word d-flex")
          template(v-slot:header)
            span {{ $t("user_tab.table.column_label.previously_served_customers") }} &nbsp;
            aiq-tooltip(effect="dark"
                        placement="top"
                        popper-class="explanation-tooltip"
                        content="These are previous assignments for the agent. These are not active assignments."
                        :show-after="1")
              i.iq-ico-explanation
          template(v-slot:default="scope")
            aiq-button(link type="primary" size="small", @click="filterToCustomersDashboardWithAgent(scope.row, 'customers_served')") {{ scope.row.customers_served }} &nbsp;
        aiq-table-column(width="71px", label-class-name="is-bordered")
          template(v-slot:default="scope")
            aiq-button(link type="primary" size="small", @click="openAgentDetail(scope.row)") Detail
</template>

<script>
import { Component, Vue, toNative } from 'vue-facing-decorator';
import moment from 'moment';
import get from 'lodash/get';
import has from 'lodash/has';
import set from 'lodash/set';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { mapActions, mapGetters, mapState } from 'vuex';
import { AGENT_STATUS, QUERY_MAPPING } from '@/constants';
import { PAGINATION_DEFAULT, TEAM_LIMIT } from '@/constants/pagination';
import ManagementPageWithActions from '@/components/ManagementPage/ManagementPageWithActions.vue';
import LayoutMain from '@/components/layouts/main.vue';
import AgentStatus from '@/components/AgentStatus.vue';
import ActionsPanel from '@/components/ActionsPanel.vue';
import { filterUsersActionsByPermissions } from '../../libs/permissionsHelper';
import { availabilitySort } from '@/libs/shared';
import {
  simplifyQueryParams,
  expandQueryParams,
  getAsyncOptionContainers,
} from '@/libs';
import { getNonReactiveDateRange } from '@/pages/Metrics/configs/dateRange';

const SEARCH_PAGE_SIZE = 100;
const FILTER_VARS = [
  'dateRange',
  'roleSelectedOptions',
  'availabilitySelectedOptions',
  'teamSelectedOptions',
];

@Component({
  name: 'usersdashboard',
  components: {
    ManagementPageWithActions,
    LayoutMain,
    AgentStatus,
    ActionsPanel,
  },
  watch: {
    areFilterOptionsLoaded(newValue) {
      if (newValue) {
        const stateQuery = expandQueryParams(this);
        if (!isEmpty(stateQuery)) {
          Object.entries(stateQuery).forEach(([queryParam, queryVal]) => {
            if (this[queryParam]) set(this, queryParam, queryVal);
            else { set(this.$refs, ['managementPage', queryParam], queryVal) }
          });
        }
        this.applySearch();
      }
    },
  },
  computed: {
    ...mapState({
      permissions: state => state.agent.permissions,
      FEATURE_FLAGS: state => state.featureFlags.FEATURE_FLAGS,
      isMobileContext: state => state.settings.isMobileContext,
      agentProfile: state => state.agent.profile,

    }),
    ...mapGetters({
      agents: 'agentsDashboard/agents',
      selectedAgents: 'agentsDashboard/getSelectedAgents',
      sortedTeams: 'teams/sortedTeams',
      roles: 'settings/roleNames',
    }),
    actionCardParams() {
      const actionObject = this.actionOptions[this.chosenAction] || {};
      return {
        actionValue: this.chosenAction,
        items: this.selectedAgents,
        showMessage: actionObject.showMessage,
        messageText: actionObject.messageDefaultText,
      };
    },
    teamOptions() {
      return this.canView('/users/filters/show_all_teams') ? this.sortedTeams : this.agentProfile.teams;
    },
    helpText() { return this.$t('user_tab.actions.description_tooltip') },
    actionsErrorText() {
      const stringPath = 'user_tab.actions.error_tooltip.';
      return {
        title: this.$t(`${stringPath}title`),
        description: this.$t(`${stringPath}description`),
      };
    },
    defaultTime() {
      // Docs: https://element-plus.org/en-US/component/datetime-picker.html#default-time-value-for-start-date-and-end-date
      // Per element plus docs, default time property do not care about date information it only use time from the date ranges defined bellow
      const currentDate = new Date();
      return [new Date(currentDate.getFullYear(), 1, 1, 0, 0, 0), new Date(currentDate.getFullYear(), 2, 1, 23, 59, 59)]; // eslint-disable-line no-magic-numbers
    },
    hasRowSelections() {
      return !!Object.keys(this.selectedAgents).length;
    },
    areFilterOptionsLoaded() {
      return getAsyncOptionContainers(this.$route.path).reduce(
        (accumulator, currentValue) => accumulator && !isEmpty(this[currentValue]),
        true,
      );
    },
    availabilityOptions() {
      return Object.values(AGENT_STATUS);
    },
    actionOptions() {
      const keys = filterUsersActionsByPermissions(this.actionNotFilteredOptions, this.permissions);
      const options = pick(this.actionNotFilteredOptions, keys);
      if (!this.FEATURE_FLAGS.ENABLE_NOTIFICATION) {
        delete options['Send Notification'];
      }
      return options;
    },
    actionOptionsKeys() {
      return Object.keys(this.actionOptions);
    },
  },
  methods: {
    ...mapActions({
      getAgents: 'agentsDashboard/getAgents',
      selectAgents: 'agentsDashboard/selectAgents',
      deselectAllAgents: 'agentsDashboard/deselectAllAgents',
      setStatusForSelectedAgents: 'agentsDashboard/setStatusForSelectedAgents',
      sendBatchNotifications: 'agentsDashboard/sendBatchNotifications',
      getTeamsList: 'teams/getTeamsList',
    }),
    getRolesSafe(agent) {
      return get(agent, 'roles', []);
    },
    getFormattedLastLoginTime(agent) {
      if (agent.last_login_time) {
        return moment(agent.last_login_time).format('MMM DD, YYYY hh:mm A');
      }
      return '';
    },
    disabledDate(time) {
      return time.getTime() > moment().endOf('day');
    },
    async openAgentDetail(agent) {
      // Getting agent data from API fetching action
      // because dashboard agents' data aren't fully detailed
      const fullAgent = await this.$store.dispatch('agents/getAgents', [agent.id]);
      const record = {
        ...this.$store.getters['agents/agentModel'],
        ...fullAgent.data,
      };
      // no need for await because agents/selectAction is not an async operation
      this.$store.dispatch('agents/selectAgent', record);
      this.childViewProps = {
        cancelButtonText: 'Close',
        id: record.id,
        showSaveButton: false,
        disabled: true,
      };
      this.$router.push({
        name: 'UsersDashboardUserDetail',
        params: { id: record.id },
      });
    },

    // Usage:
    //   filterToCustomersDashboardWithAgent(item.row, 'primary', 'primary_customers_served')
    //   filterToCustomersDashboardWithAgent(item.row, 'active_non_primary_customers')
    filterToCustomersDashboardWithAgent(agent, filterType, valueField = filterType) {
      // FYI: agent[valueField] is equal with get(agent, valueField)
      if (agent[valueField] <= 0) {
        // Will skip invalid data selection.
        return;
      }
      this.$router.push({
        name: 'Customers',
        query: {
          'agent-id': agent.id,
          'agent-name': agent.fullName,
          'filter-type': filterType,
        },
      });
    },

    closeActionPanel() {
      this.chosenAction = null;
      this.actionFormShown = false;
    },

    onSortName(a, b) {
      return get(a, 'fullName', '') <= get(b, 'fullName', '') ? 1 : -1;
    },

    onSortLastLoginTime(a, b) {
      return get(a, 'last_login_time', '') <= get(b, 'last_login_time', '') ? 1 : -1;
    },

    onSortActiveNonPrimaryCustomers(a, b) {
      return get(a, 'active_non_primary_customers', 0) <= get(b, 'active_non_primary_customers', 0) ? 1 : -1;
    },

    onSortCustomersServed(a, b) {
      return get(a, 'customers_served', 0) <= get(b, 'customers_served', 0) ? 1 : -1;
    },

    onSortCustomersServedPrimary(a, b) {
      return get(a, 'primary_customers_served', 0) <= get(b, 'primary_customers_served', 0) ? 1 : -1;
    },

    onSortAvailable(a, b) {
      return availabilitySort(a.available, b.available);
    },

    onSortRole(a, b) {
      const roleA = get(a, 'roles', []).sort().join().toLowerCase();
      const roleB = get(b, 'roles', []).sort().join().toLowerCase();
      return roleA <= roleB ? 1 : -1;
    },

    onSelect(selection = []) {
      if (selection.length > 0) {
        this.selectAgents(selection);
      } else {
        this.deselectAllAgents();
      }
    },

    clearSearch() {
      FILTER_VARS.forEach((key) => {
        this[key] = [];
      });
      this.applySearch();
    },

    applySearch() {
      this.actionInProgress = true;
      this.$refs.managementPage.resetAndFetchData();
      this.showFiltersDialog = false;
      this.filtersCount = FILTER_VARS.filter(key => !isEmpty(this[key])).length;
    },

    handleDatePickerChange(newDateRange) {
      this.dateRange = getNonReactiveDateRange(newDateRange);
    },
  },
  mounted() {
    this.getTeamsList([{ limit: TEAM_LIMIT }]);
    this.$store.dispatch('settings/getRolesMetadata');
  },
})

class AgentsDashboard extends Vue {
  chosenAction = null;
  pagination = PAGINATION_DEFAULT;
  filtersCount = 0;
  sortOrder = { prop: 'last_login_time', order: 'ascending' };
  params = {};
  childViewProps = {};
  isFilterOpen = false;
  actionInProgress = true;
  showFiltersDialog = false;
  dateRange = [];
  roleSelectedOptions = [];
  availabilitySelectedOptions = [];
  teamSelectedOptions = [];
  isLoadingAll = false;
  actionFormShown = false;

  pageResource = '/agentsdashboard';
  searchPageSize = SEARCH_PAGE_SIZE;
  actionNotFilteredOptions = {
    'Mark Away': {
      commitActionFunction: this.setStatusAwayForAgents,
      messageDefaultText: '',
      showMessage: false,
    },
    'Send Notification': {
      commitActionFunction: this.sendNotificationForAgents,
      messageDefaultText: '',
      showMessage: true,
    },
  };

  async loadList({ searchTerm, searchOffset }) {
    this.params = {
      limit: this.searchPageSize,
      query: searchTerm,
      offset: searchOffset,
      ...(!isEmpty(this.teamSelectedOptions) &&
        { teams: this.teamSelectedOptions.map(x => x.id) }),
      ...(!isEmpty(this.roleSelectedOptions) &&
        { roles: this.roleSelectedOptions }),
      ...(!isEmpty(this.availabilitySelectedOptions) &&
        { availabilities: this.availabilitySelectedOptions }),
    };

    if (this.dateRange && this.dateRange.length === 2) { // eslint-disable-line no-magic-numbers
      this.params.period_start = moment(this.dateRange[0]).unix() || null;
      this.params.period_end = moment(this.dateRange[1]).unix() || null;
    }

    const validQuery = simplifyQueryParams(
      FILTER_VARS.reduce((accumulator, currentValue) => ({
        ...accumulator,
        [currentValue]: this[currentValue],
      }),
      { query: get(this.$refs, ['managementPage', 'query'], '') }),
      this.$route.path,
    );
    const currentUrlQuery = this.$route.query;
    if (!isEqual(currentUrlQuery, validQuery) && has(QUERY_MAPPING, this.$route.path)) {
      this.$router.push({ query: validQuery });
    }

    return this.loadAgents(this.params);
  }

  async loadAgents(params) {
    const { agents, pagination } = await this.getAgents({
      params,
      update: get(params, 'offset') === 0,
    });
    this.actionInProgress = false;
    this.pagination = pagination;
    return agents;
  }

  async loadAll() {
    this.isLoadingAll = true;
    while (this.isLoadingAll && this.agents.length < this.pagination.rowCount) {
      this.params.offset = this.pagination.offset + this.searchPageSize;
      await this.loadAgents(this.params); // eslint-disable-line no-await-in-loop
    }
    this.stopLoadAll();
  }

  async stopLoadAll() {
    this.isLoadingAll = false;
  }

  setStatusAwayForAgents({ agents }) {
    return this.setStatusForSelectedAgents({
      agents,
      status: AGENT_STATUS.Away.field,
    });
  }

  sendNotificationForAgents({ agents, message }) {
    return this.sendBatchNotifications({
      agents,
      message,
    });
  }

  chooseAction(action) {
    if (this.actionOptions[action]) {
      this.chosenAction = action;
      this.actionFormShown = true;
    }
  }

  async commitAction({ actionValue, items, message }) {
    this.chosenAction = null;
    this.actionInProgress = true;

    try {
      const res = await this.actionOptions[actionValue].commitActionFunction({
        agents: Object.values(items).map(agent => agent.id),
        message,
      });
      if (get(res, 'data.id')) {
        // the action will be running asynchronously.
        // and the given id is a job id
        this.$aiq.notify.info('The request accepted. You will be notified once the job is completed.');
      } else {
        // The number of agents is small and the request is completed
        this.$aiq.notify.success('Successful');
      }
    } catch (err) {
      this.$aiq.error.error('Failed');
    }

    this.actionInProgress = false;
    this.deselectAllAgents();
    this.loadAgents(this.params);
  }
}
export default toNative(AgentsDashboard);
</script>

<style lang="scss" scoped>
  @import "../../styles/aiq-mixins.scss";
  @import "../../styles/media-queries.scss";

  @include mobile {
    .status-span{
      padding-left: 5px;
    }
  }

  .el-table--fit{
    height: calc(100% - 115px);
  }

  .status-indicator {
    &::before {
      margin-top: 1px;
    }
  }

  .customers-span button, .cutomers-serverd-span button {
    padding-left: 6px;
  }

  :deep(.d-flex > div) {
    display: flex;
    word-break: keep-all;
    align-items: center;
  }

  :deep(.el-table) {
    .caret-wrapper{
      margin-top:0px;
    }
    th > .cell {
      padding-top:5px;
      padding-bottom:5px;
    }
  }

  :deep(.el-date-editor .el-range__close-icon) {
    right:8px;
  }

  .tag-list > span:not(:last-of-type) {
    margin-bottom:0
  }

  .el-button--primary:focus {
    background: transparent;
    border-color: transparent;
    color: #5993ff;

    &:hover {
      color:#a0cfff;
    }
  }
</style>

<style lang="scss">
@import "../../styles/aiq-mixins.scss";
  .agents-scrollbar {
    .el-table__body-wrapper {
      @include scrollable(calc(100vh - 305px));
    }
  }
  * > .break-word {
    word-break: break-word !important;
  }
  .filters-modal-container {
    .el-dialog__header {
      background-color: #69f;
      min-height: unset;
      padding: 8px;
    }
    .el-icon-close:hover {
      color: white;
    }
    .el-col {
      margin-bottom: 10px;
    }
    .date-range-filter {
      .el-range-editor {
        font-size: 11px;
      }
    }
    .label {
      margin-bottom: 5px;
    }
    .agents-dashboard-date-picker {
      border: 1px solid #BFCBD9;
    }
  }
  .filter-button {
    margin-left: 5px;
    span, svg {
      margin-left: 5px;
    }
  }
</style>
