import moment from 'moment';

const formatDuration = duration => {
  const formatParts = [];
  if (duration.days()) {
    formatParts.push(`${duration.days()}d`);
  }
  if (duration.hours()) {
    formatParts.push(`${duration.hours()}h`);
  }
  if (duration.minutes()) {
    formatParts.push(`${duration.minutes()}m`);
  }
  if (duration.seconds()) {
    formatParts.push(`${duration.seconds()}s`);
  }

  // default display
  if (!formatParts.length) {
    formatParts.push(`${duration.seconds()}s`);
  }

  return formatParts.join(' ');
};

export const timeDelta = (seconds = 0) => {
  const duration = moment.duration(seconds, 'seconds');
  return formatDuration(duration);
};

export const convertVersionToNumber = (str) => {
  // validate 'v 0.3'
  if (!/^(v )(\d+)(.\d+)?$/.test(str)) {
    return 0.0;
  }
  const versionStr = str.split(' ')[1];
  const version = Number(versionStr);
  return Number.isNaN(version) ? 0.0 : version;
};

export default { timeDelta, convertVersionToNumber };
