<template lang="pug">
management-page.scrollable(title="Feedback"
                @fetchData="pullFeedback"
                :creatable="false"
                :searchable="false"
                :showHeader="false"
                :pagination="pagination"
                :resetOffsetToDefaultOnMount="!extendStateData"
                :searchPageSize="searchPageSize")
  template(v-slot:body)
    .feedback
      .chart-configuration
        .members-filter
        aiq-button-group.breakdown-options
          aiq-button(v-for="option in filteredBreakdownOptions"
                     :key="option.value"
                     size="small"
                     :class="{'selected': selectedBreakdownOption.value === option.value}"
                     @click="setBreakdown(option.value)"
                     ) {{ option.label}}
        .chart-toolbar
          aiq-row(:gutter="16")
            .dropdown-filters
              .dropdown-filter
                segment-select(:customer-segment-filter-options="customerSegmentFilterOptions"
                      :segment-alias="segmentAlias"
                      is-select-multiple
                      is-select-collapse-tags
                      @change="onCustomerSegmentFilterChange"
                      @on-segment-hover="onSegmentHover")
        .breakdown
        .time-range-filter
          aiq-date-picker(v-model="dateRange"
                          @change="handleDatePickerChange($event)"
                          type="daterange"
                          placeholder="Select date range",
                          popper-class="metrics-time-range__date-picker-popup"
                          :shortcuts="datePickerOptions.shortcuts"
                          :disabled-date="datePickerOptions.disabledDate"
                          :teleported="false"
                          format="YYYY/MM/DD"
                          :clearable="false")
      .chart-grid
        aiq-row(v-if="initialLoaded && surveyType === 'csat'" :gutter="16")
          aiq-col(:span="24")
            overview-chart(v-if="configs.postchat_survey"
                        :dateRange="dateRangeUtc"
                        :filters="selectedFilters"
                        :breakdown="selectedBreakdownOption.value"
                        :chartConfig="csatOverviewChartConfig"
                        :formatXTick="selectedBreakdownOption.xAxisFormat"
                        :formatYTick="intCountFormatter"
                        yLabel="count"
                        grouped
                        :useDebounceForLoadMetrics="true"
                        )

        aiq-row(v-if="initialLoaded && surveyType === 'nps'" :gutter="16")
          aiq-col(:span="24")
            overview-chart(v-if="configs.postchat_survey"
                          :dateRange="dateRangeUtc"
                          :breakdown="selectedBreakdownOption.value"
                          :filters="selectedFilters"
                          :chartConfig="npsOverviewChartConfig"
                          :formatXTick="selectedBreakdownOption.xAxisFormat"
                          :formatYTick="percentFormatter"
                          yLabel="Rating percent / count"
                          :y2ScaleMin=-100
                          :y2ScaleMax=100
                          grouped
                          :useDebounceForLoadMetrics="true"
                          )
      .feedback-table
        .feedback-table-toolbar
          .flex-spacer
          FilteredInput.search(v-model="searchTerm" size="small" placeholder="Search name, segment, or primary agent")
          aiq-select(@change="applyScoreFilter"
                     v-model="scoreObjects"
                     filterable
                     clearable
                     :suffix-icon="Icon.CaretBottom"
                     size="small"
                     placeholder="Score"
                     multiple
                     :reserve-keyword="false")
            aiq-option(v-for="option in scoreFilterOptions"
                       :key="option.value"
                       :label="option.name"
                       :value="option")
          aiq-button.export(@click="exportTable")
            i.iq-ico-export


    aiq-table.feedback-scrollbar(:data="feedbackItems"
                        ref="customersTable"
                        :default-sort="sortOrder"
                        v-loading="loading")


      aiq-table-column(label="Name", :min-width="8", :sortable="true", :sort-method="onSortCustomerName", label-class-name="is-bordered normal-word-break")
        template(v-slot:default="scope")
          span {{ getCustomerName(scope.row) }} &nbsp;
      aiq-table-column(label="Joined Date", :min-width="5", :sortable="true", :sort-method="onSortJoinedDate", label-class-name="is-bordered normal-word-break")
        template(v-slot:default="scope")
          span {{ getFormattedJoinedDate(scope.row) }} &nbsp;
      aiq-table-column(prop="last_chat_time", :min-width="7", label="Last Chat Time", :sortable="true", :sort-method="onSortLastChatTime", label-class-name="is-bordered normal-word-break")
        template(v-slot:default="scope")
          span {{ getFormattedLastChatTime(scope.row) }} &nbsp;
      aiq-table-column(label="Feedback Time",:min-width="7", :sortable="true", :sort-method="onSortFeedbackTime", label-class-name="is-bordered normal-word-break")
        template(v-slot:default="scope")
          aiq-button(link type="primary" size="small", @click="goToFeedbackConversation(scope.row)") {{ getFormattedFeedbackTime(scope.row) }}
      aiq-table-column(label="Comments",:min-width="13",  :sortable="false", label-class-name="is-bordered normal-word-break")
        template(v-slot:default="scope")
          span {{ getFormattedComments(scope.row) }} &nbsp;
      aiq-table-column(label="Score", :min-width="5", :sortable="true", :sort-method="onSortScore", label-class-name="is-bordered normal-word-break")
        template(v-slot:default="scope")
          span {{ getFormattedCSATscore(scope.row) }} &nbsp;
      aiq-table-column(v-if="surveyType === 'csat'", label="Description", :min-width="5", :sortable="false", label-class-name="is-bordered normal-word-break")
        template(v-slot:default="scope")
          span {{ getFormattedCSATDescription(scope.row) }} &nbsp;
      aiq-table-column(label="Segment", :min-width="7", :sortable="true", :sort-method="onSortCustomerSegment", label-class-name="is-bordered normal-word-break")
        template(v-slot:default="scope")
          span {{ getFormattedCustomerSegment(scope.row) }} &nbsp;
      aiq-table-column(label="Primary Agent", :min-width="8",  :sortable="true", :sort-method="onSortPrimaryAgent", label-class-name="is-bordered normal-word-break")
        template(v-slot:default="scope")
          aiq-button(link type="primary" size="small", @click="canView('/users') && filterToPrimaryAgent(scope.row)") {{ getPrimaryAgentName(scope.row) }}
      aiq-table-column(:label="$t('metrics_tab.customer_tab.summary_tab.feedback.sending_agent')", :min-width="8",  :sortable="true", :sort-method="onSortSenderAgent", label-class-name="is-bordered normal-word-break")
        template(v-slot:default="scope")
          span {{ getSenderAgentName(scope.row) }} &nbsp;

</template>


<style lang="scss">
@import "../../../../styles/aiq-variables.scss";
</style>

<script>
import get from 'lodash/get';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import debounce from 'lodash/debounce';
import moment from 'moment';

import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import FilteredInput from '@/components/FilteredInput.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import LayoutMain from '@/components/layouts/main.vue';
import OverviewChart from '@/components/Chart.vue';
import SegmentSelect from '@/components/SegmentsSelect/SegmentsSelect.vue';
import { downloadAsFile } from '@/libs';
import { upperFirstStrings } from '@/libs/profile';
import { PAGINATION_DEFAULT } from '@/constants/pagination';
import { dropDownToSelected } from '../../helpers/filterHelper';
import {
  setToEndOfDay,
  defaultDateRange,
  datePickerDefaultOptions,
  filterBreakdownOptions,
  getNonReactiveDateRange,
  dstDateRangeUtc
} from '../../configs/dateRange';

const SEARCH_PAGE_SIZE = 100;
const DAY_RANGE = 6;

const COLORS = {
  red: '#FF6384',
  orange: '#FFBE50',
  yellow: '#FFEC71',
  grey: '#D7D9DE',
  blue: '#4671B3',
  green: '#4BC0C0',
};
const csatOverviewChartConfig = {
  name: 'postchat_survey',
  title: 'CSAT',
  metric: 'csat_score',
  lines: {
    very_unsatisfied: {
      label: 'Very Unsatisfied',
      position: 1,
      color: COLORS.red,
    },
    unsatisfied: {
      label: 'Unsatisfied',
      position: 2,
      color: COLORS.orange,
    },
    neutral: {
      label: 'Neutral',
      position: 3,
      color: COLORS.yellow,
    },
    satisfied: {
      label: 'Satisfied',
      position: 4,
      color: COLORS.grey,
    },
    very_satisfied: {
      label: 'Very Satisfied',
      position: 5,
      color: COLORS.green,
    },
    csat_score: {
      label: 'CSAT score',
      position: 6,
      axis: 'y2',
      type: 'spline',
    },
  },
  graphParams: {
    metricCategoryForY: 'feedback',
  },
  helpText: 'metrics_tab.plaform_tab.summary_tab.feedback.help'
};

const npsOverviewChartConfig = {
  name: 'postchat_survey',
  title: 'NPS',
  metric: 'nps_score',
  lines: {
    nps_detractors: {
      label: 'Detractor',
      position: 1,
      color: COLORS.red,
    },
    nps_passive: {
      label: 'Passive',
      position: 2,
      color: COLORS.yellow,
    },
    nps_promoters: {
      label: 'Promotor',
      position: 3,
      color: COLORS.green,
    },
    net_promoter_score: {
      label: 'Net Promoter Score',
      position: 4,
      axis: 'y2',
      y2Category: 'feedback',
      type: 'spline',
    },
  },
  graphParams: {
    metricCategoryForY: 'feedback_percent',
  },
  helpText: 'metrics_tab.plaform_tab.summary_tab.feedback.help'
};

const breakdownOptions = [
  {
    value: 'day',
    label: 'Daily',
    isDefault: true,
    xAxisFormat: value => moment(value).format('MMM DD'),
  },
  {
    value: 'week',
    label: 'Weekly',
    xAxisFormat: value => {
      const start = moment(value);
      const end = moment(value).add(DAY_RANGE, 'days');
      const format = 'MMM DD';
      return `${start.format(format)} - ${end.format(format)}`;
    },
  },
  {
    value: 'month',
    label: 'Monthly',
    xAxisFormat: value => moment(value).format('MMM'),
  },
];

export default {
  name: 'Feedback',
  components: {
    ManagementPage,
    LayoutMain,
    OverviewChart,
    FilteredInput,
    SegmentSelect,
  },
  props: {
    query: {
      type: String,
      default: '',
    },
    filterTypeProp: String,
    filterByAgentProp: Object,
  },
  data() {
    return {
      csatOverviewChartConfig,
      npsOverviewChartConfig,
      selectedFilters: [],
      intCountFormatter: v => (Math.floor(parseFloat(v)) === parseFloat(v) ? v : null),
      durationFormatter: this.$aiq.formatters.timeDelta,
      dateRange: defaultDateRange(),
      selectedBreakdownOption: breakdownOptions.find(
        ({ isDefault }) => isDefault,
      ),
      pagination: PAGINATION_DEFAULT,
      filterType: this.filterTypeProp,
      filterByAgent: this.filterByAgentProp,
      sortOrder: { prop: this.datePickerOption || 'last_chat_time', order: 'ascending' },
      searchTerm: '',
      filterScores: null,
      initialLoaded: false,
      percentFormatter: v => `${v}%`,
      loading: true,
      segmentAlias: '',
      extendStateData: false,
      searchPageSize: SEARCH_PAGE_SIZE,
      scoreObjects: [],
    };
  },
  computed: {
    ...mapState({
      feedbackItems: state => state.metrics.survey.feedbackItems,
      surveyType: state => state.metrics.survey.surveyType,
    }),
    ...mapGetters({
      configs: 'configs/graphsConfigs',
      customerSegmentFilterOptions: 'configs/segments',
    }),
    dateRangeUtc() {
      return dstDateRangeUtc(this.dateRange);
    },
    datePickerOptions() {
      return datePickerDefaultOptions();
    },
    scoreFilterOptions() {
      let scoreRange = [];
      if (this.surveyType === 'csat') {
        // Range 1 ~ 5
        scoreRange = [...Array(5).keys()].map(i => i + 1);   // eslint-disable-line
      } else if (this.surveyType === 'nps') {
        // Range 0 ~ 10
        scoreRange = [...Array(11).keys()]; // eslint-disable-line
      }
      return scoreRange.map(index => ({ value: index, name: String(index) }));
    },
    // TODO: Create a mixin that has this computed value,
    // along with other common properties and functions in metrics
    filteredBreakdownOptions() {
      return filterBreakdownOptions(this.dateRange, breakdownOptions);
    },
  },
  watch: {
    dateRange: debounce(function onDateRangeChange(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.loadList({ searchOffset: 0 });
      }
    }, 1),

    filterScores() {
      this.loadList();
    },

    searchTerm() {
      this.loadList();
    },

    filteredBreakdownOptions(newOptions) {
      if (!newOptions.find(o => o.value === this.selectedBreakdownOption.value)) {
        this.selectedBreakdownOption = newOptions.find(o => o.isDefault);
      }
    },
  },
  methods: {
    ...mapActions({
      getFeedbackList: 'metrics/getFeedbackList',
      getFeedbackType: 'metrics/getFeedbackType',
    }),
    setSelectedFilters(filterName, chosenFilters) {
      this.selectedFilters = dropDownToSelected(chosenFilters,
        this.selectedFilters,
        filterName);
    },
    setSelectedFiltersToNames(filterType) {
      const filterValuesPath = `0.${filterType}`;
      const selectedFilterOptionsIdxs = get(this.selectedFilters, filterValuesPath, [])
        .map(selectedId => findIndex(this.customerSegmentFilterOptions,
          (option) => option.id === selectedId));
      const selectedFilterNames = selectedFilterOptionsIdxs
        .map(cid => get(this.customerSegmentFilterOptions[cid],
          'name',
          ''));
      set(this.selectedFilters,
        filterValuesPath, selectedFilterNames);
    },
    onCustomerSegmentFilterChange(chosenFilters) {
      const chosenIdNames = chosenFilters
        .map(chosenValue => ({
          id: chosenValue.id,
          name: chosenValue.name }));
      this.setSelectedFilters('customer_segment_names', chosenIdNames);
      this.setSelectedFiltersToNames('customer_segment_names');
      this.loadList();
    },
    setBreakdown(value) {
      this.selectedBreakdownOption = this.filteredBreakdownOptions.find(
        option => option.value === value,
      );
    },
    getFormattedCustomerSegment(feedback) {
      let customerSegment = get(feedback, 'customer_segments', []);

      if (Array.isArray(customerSegment)) {
        customerSegment = customerSegment.join(', ');
      } else if (typeof customerSegment === 'string') {
        customerSegment = customerSegment.replace(/[{}]+/g, '').replace(',', ', ');
      }

      return customerSegment;
    },

    getFormattedJoinedDate(feedback) {
      return moment(feedback.join_date).format('YYYY-MM-DD');
    },

    getFormattedLastChatTime(feedback) {
      const lastChatTime = get(feedback, 'last_chat_details.last_chat_time');
      if (!lastChatTime) {
        return lastChatTime;
      }
      return moment(lastChatTime).format('MMM DD, YYYY hh:mm A');
    },
    getFormattedComments(feedback) {
      const comments = get(feedback, 'content.comments', '');
      if (!comments) {
        return '';
      }
      return comments;
    },
    getFormattedCSATscore(feedback) {
      const csatScore = get(feedback, 'value', '');
      if (csatScore === '') {
        return '';
      }
      return csatScore;
    },
    getColMinWidth() {
      return '95';
    },
    getFormattedFeedbackTime(feedback) {
      const feedbackTime = get(feedback, 'time_stamp', '');
      if (!feedbackTime) {
        return feedbackTime;
      }
      return moment(feedbackTime).format('MMM DD, YYYY hh:mm A');
    },
    getFormattedCSATDescription(feedback) {
      const csatDescription = get(feedback, 'content.description', '');
      if (!csatDescription) {
        return '';
      }
      return csatDescription;
    },
    goToLastConversation(feedback) {
      const lastConversationCustomerId = get(feedback, 'customer_id');
      if (!lastConversationCustomerId) {
        return;
      }
      this.$router.push({
        name: 'ConversationByCustomerId',
        params: { id: lastConversationCustomerId },
      });
    },

    goToFeedbackConversation(feedback) {
      // Currently there is no way to get conversation id of this feedback.
      // Therefore we are redirecting to recent conversation.
      this.goToLastConversation(feedback);
    },

    getPrimaryAgentName(feedback) {
      return upperFirstStrings(
        get(feedback, 'primary_agent_profile.first_name', ''),
        get(feedback, 'primary_agent_profile.last_name', ''),
      );
    },

    getSenderAgentName(feedback) {
      return upperFirstStrings(
        get(feedback, 'sender_agent_profile.first_name', ''),
        get(feedback, 'sender_agent_profile.last_name', ''),
      );
    },

    getCustomerName(feedback) {
      const name = upperFirstStrings(
        get(feedback, 'customer_profile.first_name', ''),
        get(feedback, 'customer_profile.last_name', ''),
      );
      return name !== '' ? name : `Customer ${get(feedback, 'customer_id')}`;
    },

    filterToPrimaryAgent(feedback) {
      const primaryAgentName = this.getPrimaryAgentName(feedback);
      if (primaryAgentName) {
        this.$router.push({
          name: 'Users',
          query: {
            query: primaryAgentName,
          },
        });
      }
    },

    onSortCustomerName(a, b) {
      return this.getCustomerName(a) <= this.getCustomerName(b) ? 1 : -1;
    },

    onSortJoinedDate(a, b) {
      return a.join_date <= b.join_date ? 1 : -1;
    },

    onSortScore(a, b) {
      const score1 = get(a, ['content', 'value']);
      const score2 = get(b, ['content', 'value']);
      return score1 < score2 ? 1 : -1;
    },
    onSortFeedbackTime(a, b) {
      const leftFeedbackTime = get(a, ['time_stamp']);
      const rightFeedbackTime = get(b, ['time_stamp']);

      return leftFeedbackTime < rightFeedbackTime ? 1 : -1;
    },
    onSortLastChatTime(a, b) {
      const sortOrder = this.$refs.customersTable.store.states.sortOrder;

      const leftLastChatTime = get(a, 'last_chat_details.last_chat_time');
      const rightLastChatTime = get(b, 'last_chat_details.last_chat_time');

      // Always keep undefined at the bottom
      if (!leftLastChatTime) {
        return sortOrder === 'ascending' ? 1 : -1;
      }

      if (!rightLastChatTime) {
        return sortOrder === 'ascending' ? -1 : 1;
      }

      return leftLastChatTime <= rightLastChatTime ? 1 : -1;
    },

    onSortPrimaryAgent(a, b) {
      return this.getPrimaryAgentName(a) <= this.getPrimaryAgentName(b) ? 1 : -1;
    },

    onSortSenderAgent(a, b) {
      return this.getSenderAgentName(a) <= this.getSenderAgentName(b) ? 1 : -1;
    },

    onSortCustomerSegment(a, b) {
      return a.customer_segment <= b.customer_segment ? 1 : -1;
    },

    exportTable() {
      const data_points = [];
      for (const c of this.feedbackItems) {
        data_points.push(
          {
            Name: this.getCustomerName(c) || '',
            'Joined Date': this.getFormattedJoinedDate(c) || '',
            'Last Chat Time': this.getFormattedLastChatTime(c) || '',
            'Feedback Time': this.getFormattedFeedbackTime(c) || '',
            Comments: this.getFormattedComments(c) || '',
            Score: this.getFormattedCSATscore(c) || '',
            Description: this.getFormattedCSATDescription(c) || '',
            'Customer Segment': this.getFormattedCustomerSegment(c) || '',
            'Primary Agent': this.getPrimaryAgentName(c) || '',
            'Sender Agent': this.getSenderAgentName(c) || '',
          },
        );
      }
      downloadAsFile({
        dataType: 'table',
        data: data_points,
      });
    },
    handleDatePickerChange(newDateRange) {
      this.dateRange = setToEndOfDay(getNonReactiveDateRange(newDateRange));
    },
    async loadList(ManagementParams) {
      const params = {
        limit: this.searchPageSize,
        offset: get(ManagementParams, 'searchOffset', 0),
        query: this.searchTerm,
      };

      this.extendStateData = false;
      let hasFiltering = false;
      if (this.dateRange && this.dateRange.length === 2) { // eslint-disable-line no-magic-numbers
        params.period_start = moment(this.dateRange[0]).unix() || null;
        params.period_end = moment(this.dateRange[1]).unix() || null;
      }

      if (this.filterScores) {
        hasFiltering = true;
        params.feedback_scores = this.filterScores.join(',');
      }

      const segmentNames = get(this.selectedFilters, '0.customer_segment_names', []);

      if (!isEmpty(segmentNames)) {
        hasFiltering = true;
        params.customer_segment_names = segmentNames;
      }

      if ((!hasFiltering && isEmpty(this.searchTerm)) && params.offset !== 0) {
        this.extendStateData = true;
      }
      const extend_state = this.extendStateData;
      const response = await this.getFeedbackList({ params, extend_state });
      this.loading = false;
      this.pagination = get(response, 'pagination', PAGINATION_DEFAULT);
    },
    pullFeedback(ManagementParams) {
      debounce(async () => {
        await this.loadList(ManagementParams);
        // eslint-disable-next-line no-magic-numbers
      }, 250)();
    },
    applyScoreFilter(scoreObjects) {
      this.filterScores = scoreObjects.map(scoreObject => scoreObject.value);
    },
    onSegmentHover(segment) {
      this.segmentAlias = get(segment, 'alias', '');
    },
  },
  async mounted() {
    await this.getFeedbackType();
    this.initialLoaded = true;
    this.$store.dispatch('configs/getCustomerSegments');
    await this.loadList();
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../../styles/aiq-mixins.scss";
  .scrollable {
      overflow-y: auto;
  }
  .el-table--fit{
    height: calc(100% - 100px);
  }

  .chart-configuration {
      display: flex;
      margin-bottom: 24px;
      .time-range-filter {
        position: relative;
        justify-content: flex-end;
      }
      .breakdown {
        display: flex;
        align-items: center;
        flex-grow: 1;
      }
      .members-filter {
        display: flex;
        align-items: center;
      }
      .chart-toolbar {
       display: flex;
       margin-bottom: 20px;
       margin-left: 30px;
       .dropdown-filters {
          display: flex;
          align-items: flex-start;
          width: 60%;
          .dropdown-filter {
            margin-right: 12px;
            width: 40em;
            height: 30px;
            :deep(.el-select) {
                width:  75%;
            }
          }
       }
       .flex-spacer {
         flex-grow: 1;
      }
      }
  }
  .status-indicator {
    &::before {
      margin-top: 1px;
    }
  }
  .right-aligned {
    float: right;
  }

  .chart-grid {
    margin: 24px 0;
  }

  .feedback {
    width: 100%;
    height: 100%;
  }

  .feedback-table{
    margin: 10px 0px 10px 0px;
  }

  .feedback-table-toolbar {
    display: flex;

    .flex-spacer {
      flex-grow: 1;
    }

    .search {
      max-width: 246px;
    }

    .el-select {
      width: 100px;
      margin-left: 16px;
    }
  }
</style>

<style lang="scss">
@import "../../../../styles/aiq-mixins.scss";

.normal-word-break{
  .cell{
    word-break: normal !important;
  }
}
.feedback-table {
  .feedback-table-toolbar {
    .el-button.export {
      height: 30px;
      width: 40px;
      padding: 0;
      margin-left: 16px;

      &:after {
        clear: both;
      }
    }
  }
}


</style>
