
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import has from 'lodash/has';
import { BUILDING_KIT_ITEM_TYPES } from '@/constants';

export const getCommandType = commandName => {
  const commandTypes = {
    'conversations.messages.create': 'response',
  };
  return commandTypes[commandName] || 'command';
};

const getRealCommandContent = command => command.name;

export const CommandToAction = commandRaw => {
  const command = cloneDeep(commandRaw);

  if (!command.name) return null;

  if (!command.type) {
    if (command.unit_type && command.unit_type !== 'action') {
      command.type = command.unit_type;
    } else if (command.name.indexOf('dialogs.run') >= 0) {
      command.type = 'dialog';
    } else if (command.name.indexOf('workflows.run') >= 0) {
      command.type = 'workflow';
    } else {
      command.type = getCommandType(command.name);
    }
  }

  switch (command.type) {
    case 'response':
      command.content = get(command, 'payload.message.payload.content', command.name);
      break;
    case 'dialog':
    case 'workflow':
      command.content = get(command, 'payload.custom_name', command.name);
      break;
    case 'intent':
      command.content = command.name;
      break;
    default:
      // TODO(gabe): Commands are currently caught here. Will do better filtering
      // and coordinating with data structure from AI-Manager-Backend.
      command.content = get(command, 'payload.custom_name', command.name);
  }

  has(command, 'alias') && (command.content = getRealCommandContent(command));
  return command;
};

// TODO (Gabe) Change this file name for all building kit related helpers
export const mapBKItemsByType = (BKItems) => {
  const bkItemTypes = cloneDeep(BUILDING_KIT_ITEM_TYPES);
  const itemsByType = {
    ...bkItemTypes,
    all: [],
  };

  for (const item of BKItems) {
    if (['workflow', 'intent', 'dialog'].includes(item.type)) {
      item.item.type = item.type;
    }

    const { type } = CommandToAction(item.item);

    if (bkItemTypes[type]) {
      if (!itemsByType[type]) {
        itemsByType[type] = [item.item];
      } else {
        itemsByType[type].push(item.item);
      }

      itemsByType.all.push(item.item);
    }
  }

  return itemsByType;
};
