
import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    customers: {},
    selectedCustomers: {},
    totalCustomers: 0,
  },
  getters: {
    customers: state => Object.values(state.customers),
    getSelectedCustomers: state => state.selectedCustomers,
    hasFinishedLoading: state => state.totalCustomers <= Object.keys(state.customers).length,
  },
  mutations,
  actions,
};
