<template lang="pug">
div
  aiq-dialog.edit-agent-status(:model-value="modelValue"
            :title="title"
            :close-on-click-modal="true"
            :close-on-press-escape="true"
            @close="close"
            :before-close="handleBeforeClose"
            destroy-on-close)
    .form
      .field-container.is-required
        label 
          span {{ $t('settings_tab.admin_tab.status_tab.fields.status.form_label') }}
          aiq-tooltip(effect="dark"
                    placement="right"
                    :content="$t('settings_tab.admin_tab.status_tab.fields.status.tooltip')"
                    :show-after="500")
            fa-icon.info-icon(:icon="['fas', 'circle-question']")
        aiq-select(v-model="form.status"
                    size="small"
                    :suffix-icon="Icon.CaretBottom"
                    value-key="status"
                    name="status"
                    :reserve-keyword="false"
                    :disabled="isBusy || editMode"
                    :placeholder="$t('settings_tab.admin_tab.status_tab.fields.status.placeholder')")
            aiq-option(v-for="s in statuses"
                      :key="s.status"
                      :label="s.label"
                      :value="s.status")
          
    .field-container.is-required(:class="{'is-error': v$.form.sub_status.$errors.length}")
      label 
        span {{ $t('settings_tab.admin_tab.status_tab.fields.sub_status.label') }}
        aiq-tooltip(effect="dark"
                placement="right"
                :content="$t('settings_tab.admin_tab.status_tab.fields.sub_status.tooltip')"
                :show-after="500")
          fa-icon.info-icon(:icon="['fas', 'circle-question']")
      aiq-input(size="small"
                v-model="form.sub_status"
                :placeholder="$t('settings_tab.admin_tab.status_tab.fields.sub_status.placeholder') "
                name="sub_status"
                :disabled="isBusy")
      span.error-msg(v-for="err in v$.form.sub_status.$errors") {{ err.$message }}
          
    .field-container.one-line
      label
        span {{ $t('settings_tab.admin_tab.status_tab.fields.selectable.label') }}
        aiq-tooltip(effect="dark"
                placement="right"
                :content="$t('settings_tab.admin_tab.status_tab.fields.selectable.tooltip')"
                :show-after="500")
          fa-icon.info-icon(:icon="['fas', 'circle-question']")
      aiq-switch.selectable-switch(v-model="form.selectable" :disabled="isBusy")

    template(v-slot:footer)
      p.required-notice * Required
      span.spinner
        font-awesome-icon(v-if="isBusy"
                              size="lg"
                              icon="spinner"
                              spin)

      aiq-button.button-delete(v-if="editMode"
                                type="danger"
                                :disabled="isBusy"
                                @click="deleteRecord") Delete
      aiq-button(@click="close" :disabled="isBusy") Cancel
      aiq-button(:disabled="!isSubmittable || isBusy"
                type="primary"
                @click="saveRecord") Save    
</template>

<script>
import { mapState } from 'vuex';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import snakeCase from 'lodash/snakeCase';
import { useVuelidate } from '@vuelidate/core';
import { required, maxLength, helpers } from '@vuelidate/validators';

import { confirmBeforeDialogClose } from '@/libs';

const SUB_STATUS_MAX_LENGTH = 15;
const FORM_ACTIONS = {
  UPDATE: 'update',
  CREATE: 'create',
};

export default {
  setup () {
    return { v$: useVuelidate() };
  },
  validations () {
    return {
      form: {
        status: { required, $autoDirty: true },
        sub_status: {
          required,
          $autoDirty: true,
          maxLengthValue: helpers.withMessage(this.$t('settings_tab.admin_tab.status_tab.fields.sub_status.error_msgs.max_length', { maxLength: SUB_STATUS_MAX_LENGTH }), maxLength(SUB_STATUS_MAX_LENGTH)),
        },
        selectable: { $autoDirty: true },
      },
    };
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: null,
    },
  },
  data() {
    const form = this.getInitialForm();
    
    return {
      form,
      isBusy: false,
    };
  },
  computed: {
    ...mapState({
      statuses: state => state.settings.agentStatuses.statuses,
    }),
    action() {
      return get(this.record, 'path') ? FORM_ACTIONS.UPDATE : FORM_ACTIONS.CREATE;
    },
    title() {
      const prefix = this.action === FORM_ACTIONS.CREATE ? 'New' : 'Update';
      return `${prefix} Agent Status`;
    },
    editMode() {
      return this.action === FORM_ACTIONS.UPDATE;
    },
    isRecordDirty() {
      if (this.v$) {
        return this.v$.$anyDirty;
      }
      return true;
    },
    isRecordValid() {
      if (this.v$) {
        return !this.v$.$invalid;
      }
      return true;
    },
    isSubmittable() {
      return (this.isRecordDirty && this.isRecordValid);
    },
  },
  emits: ['reloadList', 'closeEditor'],
  methods: {
    getInitialForm() {
      return {
        status: '',
        sub_status: '',
        selectable: false,
      };
    },
    close() {
      this.$emit('closeEditor');
      this.clearForm();
    },
    async saveRecord() {
      const baseTranslationString = 'settings_tab.admin_tab.status_tab';
      try {
        this.isBusy = true;
        const updatedStatuses = cloneDeep(this.statuses);
        let isDuplicateSubStatus = false;
        
        if (this.action === FORM_ACTIONS.CREATE) {
          const status = updatedStatuses.find(s => s.status === this.form.status);
          const subStatus = {
            editable: true,
            label: this.form.sub_status,
            selectable: this.form.selectable,
            status: snakeCase(this.form.sub_status),
          };
          isDuplicateSubStatus = status.subStatuses.some(s => s.status === subStatus.status || s.label.toLowerCase() === subStatus.label.toLowerCase());
          status.subStatuses.push(subStatus);
        } else {
          const status = updatedStatuses.find(s => s.status === this.record.path.status);
          const subStatus = status.subStatuses.find(s => s.status === this.record.path.subStatus);
          isDuplicateSubStatus = status.subStatuses.some(s => s.label.toLowerCase() === this.form.sub_status.toLowerCase());
          subStatus.label = this.form.sub_status;
          subStatus.selectable = this.form.selectable;
        }

        if (isDuplicateSubStatus) {
          throw new Error(this.$t(`${baseTranslationString}.duplicate_sub_status_error`), { cause: 'duplicate_sub_status' });
        }

        await this.$store.dispatch('settings/updateSetting', {
          name: 'agent_status',
          payload: { statuses: updatedStatuses },
          stateName: 'agentStatuses'
        });

        this.$aiq.notify.success(this.$t(`${baseTranslationString}.${this.action === FORM_ACTIONS.CREATE ? 'create_success_msg' : 'update_success_msg'}`));
        this.$emit('reloadList');
        this.close();
      } catch (err) {
        if (err.cause === 'duplicate_sub_status') {
          this.$aiq.notify.error(err.message);
        } else {
          this.$aiq.notify.error(this.$t(`${baseTranslationString}.${this.action === FORM_ACTIONS.CREATE ? 'create_error_msg' : 'update_error_msg'}`));
        }
      } finally {
        this.isBusy = false;
      }
    },
    async deleteRecord() {
      const baseTranslationString = 'settings_tab.admin_tab.status_tab';
      try {
        const updatedStatuses = cloneDeep(this.statuses);
        const status = updatedStatuses.find(s => s.status === this.record.path.status);
        const subStatusIdx = status.subStatuses.findIndex(s => s.status === this.record.path.subStatus);
        status.subStatuses.splice(subStatusIdx, 1);

        await this.$store.dispatch('settings/updateSetting', {
          name: 'agent_status',
          payload: { statuses: updatedStatuses },
          stateName: 'agentStatuses'
        });

        this.$aiq.notify.success(this.$t(`${baseTranslationString}.delete_error_msg`));
        this.$emit('reloadList');
        this.close();
      } catch (err) {
        this.$aiq.notify.error(this.$t(`${baseTranslationString}.delete_success_msg`));
      } finally {
        this.isBusy = false;
      }
    },
    handleBeforeClose() {
      if (!this.isRecordDirty) {
        this.close();
        return;
      }

      confirmBeforeDialogClose(this, this.close);
    },
    clearForm() {
      this.form = this.getInitialForm();
      this.$nextTick(() => {
        if (this.v$) {
          this.v$.$reset();
        }
      });
    }
  },
  watch: {
    record(agentStatus) {
      if (agentStatus) {
        this.form = {
          status: { label: agentStatus.statusLabel, status: agentStatus.path.status },
          sub_status: agentStatus.subStatusLabel,
          selectable: agentStatus.selectable,
        };
      }
    }
  },
};
</script>

<style lang="scss">
@import "@/styles/media-queries.scss";
@import "@/styles/aiq-mixins.scss";

$small-button-padding-top: 10px;

.edit-agent-status.el-dialog {
  margin-top: 5vh !important;
  min-width: 520px;

  @include mobile {
    min-width: 90%;
  }

  .el-dialog__body {
    padding-bottom: 0;

    .el-input,
    .el-input-number,
    .el-select,
    .el-textarea {
      margin-top: $small-button-padding-top;
      margin-bottom: 14px;
    }

    .el-input-number {
      display: inherit;
    }

    .el-tabs__content {
      padding: 0 4px 0 0;
    }

    .field-container {
      flex: 1;


      &.one-line {
        display: flex;

        .selectable-switch {
          margin-left: 50px;
        }
      }

      &.is-error {
        .el-input {
          margin-bottom: 3px;
          
          :deep(input.el-input__inner) {
            border-color: red !important;
          }
        }

        .error-msg {
          color: red;
          font-size: var(--el-font-size-extra-small);
          font-weight: 100;
          margin-bottom: 11px;
        }
      }

      .is-disabled {

        .el-input__inner {
          color: #475669;
        }
      }

      &.is-required {

        label {

          &::before {
            margin-right: 5px;
            content: "*";
            color: #FF0000;
          }
        }
      }

      label {
        display: flex;
        align-items: center;
        color: #475669;
        font-size: 14px;
        font-weight: 100;

        .info-icon {
          margin-left: 5px;
        }
      }
    }
  }

  .el-dialog__footer {

    p {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 8px;
      color: #FF0000;
      font-size: 14px;
      font-weight: 100;
    }
  }

  .spinner {
    padding-right: 0.5em;
  }
}



</style>
