<template lang="pug">
.back-button-container
  aiq-button(link type="primary"
            @click="$emit('click')")
    .back-btn__inner-content
      i.iq-ico-arrow
      .main-column_header-title {{ text }}
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.back-button-container {
  align-items: center;
  display: flex;

  .el-button {
    font-size: 14px;
    margin-right: 8px;
    padding: 0;
  }

  .el-button--text {
    color: #8492A6;
  }

  .text__btn {
    margin-bottom: 0px;
  }

  .back-btn__inner-content {
    display: flex;

    .main-column_header-title {
      line-height: 22px;
      padding: 0;
    }
  }

  i {
    display: flex;
    &::before {
      height: 22px;
      width: 22px;
    }
  }
}
</style>
