const hasUnsavedIntent = ({ store, path }) => {
  const isFromIntents = path === '/iqtools/intents' || path === '/iqtools';
  const isIntentSelected = store.getters['intents/selected'];
  const isDirtyForm = !store.getters['intents/formIntent/pristine'];

  return isFromIntents && isIntentSelected && isDirtyForm;
};

const hasUnsavedDialog = ({ store, path }) => {
  const isFromDialogs = path === '/iqtools/dialogs';
  const isDialogSelected = store.getters['dialogs/selected'];
  const isDirtyForm = !store.state.dialogs.selectedIsPristine;

  return isDialogSelected && isFromDialogs && isDirtyForm;
};

export const confirmUnsavedWarning = {
  title: 'Leave Page?',
  content: 'You have unsaved changes. Are you sure you want to leave without saving?',
};

export const ifShowPopup = ({ store, from, next }, confirm) => {
  const { path } = from;
  const info = { store, path };

  if (hasUnsavedIntent(info) || hasUnsavedDialog(info)) {
    const { title, content } = confirmUnsavedWarning;
    return confirm(title, content).then(
      selection => {
        if (selection.dismiss) {
          next(false);
        } else {
          next();
        }
      },
      () => {},
    );
  }
  next();
};

export const defaultRoute = '/error';
export const topRoutes = [
  '/conversations',
  '/iqtools',
  '/pulse',
  '/metrics',
  '/customers',
  '/users',
  '/settings',
  '/reporting',
];
