import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import * as log from 'loglevel';
import { mapActions, mapState } from 'vuex';
import { Component, Watch, Vue, toNative } from 'vue-facing-decorator';

import { buildingKitAiTesting } from '../Partials';
import workflowSelector from '@/components/WorkflowSelector/WorkflowSelector.vue';

const DEFAULT_AI_ESCALATION_INTERVAL_MIN = 5;
@Component({
  name: 'systemTriggers',
  components: {
    buildingKitAiTesting,
    workflowSelector,
  },
  computed: {
    ...mapState({
      savedForm: state => state.systemTriggers.form,
      workflows: state => state.workflows.workflows,
    }),
    isAiEscalationIntervalActive: {
      get() {
        return this.localForm.aiEscalation.timerEnabled;
      },
      set(newCheckState) {
        if (newCheckState) {
          this.localForm.aiEscalation.timerEnabled = true;
          this.localForm.aiEscalation.timer = DEFAULT_AI_ESCALATION_INTERVAL_MIN;
        } else {
          this.localForm.aiEscalation.timerEnabled = false;
          this.localForm.aiEscalation.periodic_workflow = null;
          this.localForm.aiEscalation.timer = null;
        }
      }
    },
    pristine() {
      return isEqual(this.localForm, this.savedForm);
    },
  },
  methods: {
    ...mapActions({
      loadForm: 'systemTriggers/load',
      updateForm: 'systemTriggers/update',
    }),
  },
})
class SystemTriggers extends Vue {
  localForm = {
    aiEscalation: {},
    checkConversationIdleByAI: {},
    initWorkflow: {},
    dispatchInitWorkflow: {},
    afterSelectingAgent: {},
    dispatchAfterSelectingAgent: {},
    dispatchWaitCustomerMessage: {},
    dispatchFallback: {},
    changeAgent: {},
    dispatchChangeAgent: {},
    authenticatedCustomerCreation: {},
    dispatchOnlyMode: false,
    intentMatchingInDispatchMode: false,
    dispatchModeConfig: { bypass_workflow: { enabled: false } },
    listDialogAlternativePrompt: '',
    listDialogAskAlternative: true,
    channelInitWorkflow: {},
  };

  @Watch('savedForm', { deep: true })
  syncWithSavedForm(newValue) {
    this.localForm = cloneDeep(newValue);
  }

  @Watch('localForm.dispatchOnlyMode', { deep: true })
  onDispatchModeChanged(newValue) {
    if (!newValue) {
      // dispatch mode is turned off so it should turn off other dependent values
      this.localForm.dispatchModeConfig.bypass_workflow.enabled = false;
      this.localForm.intentMatchingInDispatchMode = false;
    }
  }

  created() {
    // TODO (Gabe) Consider loading form then setting localForm synchronously
    this.loadForm();
    this.$store.dispatch('workflows/getWorkflowsList', [{}, true]);
  }

  resetForm() {
    this.localForm = cloneDeep(this.savedForm);
  }

  saveForm() {
    return this.updateForm(this.localForm)
      .then(() => {
        this.$aiq.notify.success('System Triggers have been saved.');
      }).catch(err => {
        log.error(err);
        this.$aiq.notify.error('Unable to save System Triggers.');
      });
  }

  setLocalForm(key, value) {
    if (key) {
      set(this.localForm, key, { id: value });
    }
  }

  getLocalForm(key) {
    return get(this.localForm, `${key}.id`, null);
  }
}
export default toNative(SystemTriggers);
