import { Component, Vue, toNative } from 'vue-facing-decorator';
import { mapState } from 'vuex';

const filterSearch = (list, search) => list.filter(source => source.name.includes(search)
    || source.description.includes(search) || source.example.includes(search));

@Component({
  name: 'entityDefaultSource',
  computed: {
    ...mapState({
      sources: state => state.entitySources.entitySources,
    }),
    categoryList() {
      return this.types.map(type => {
        const filter = source => source.type.startsWith(type.filter);
        return {
          name: type.name,
          items: filterSearch(this.sources.filter(filter), type.search),
        };
      });
    },
  },
})

class EntityDefaultSource extends Vue {
  types = [
    { name: 'System', filter: 'sys', search: '' },
    { name: 'Product', filter: 'product', search: '' },
    { name: 'Customer', filter: 'customer', search: '' },
    { name: 'Preconfigured', filter: 'preconfigured', search: '' },
  ]

  mounted() {
    this.loadEntitySources();
  }

  loadEntitySources() {
    return this.$store.dispatch('entitySources/getEntitysourcesList', [
      { q: '', limit: 1000, offset: 0 },
      true,
    ]);
  }
}
export default toNative(EntityDefaultSource);
