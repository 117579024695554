<template lang="pug">
.middle-column_header.endpoint-config-view
  h3.middle-column_header-title
    template(v-if="!editFlag")
      | {{ modelValue.name }}
    .edit-action-name-container(v-if="editFlag")
      aiq-input(ref="edit"
                :maxlength="50"
                :model-value="modelValue.name"
                @update:modelValue="onUpdateField('name', $event)"
                @blur="editFlag = false"
                @keyup.enter="editFlag = false")
    aiq-button.edit__btn(v-if="!editFlag" @click="goIntoEditMode")
      i.el-icon-edit

  .endpoint-config
    .form-field.method-endpoint-container
      .method-config-container
        h5 Method
        aiq-select(:model-value="$filters.capitalize(modelValue.method)"
                  @update:modelValue="onUpdateField('method', $event)"
                  size="small"
                  placeholder="Select request method")
          aiq-option(v-for="method in $_methods"
                    :key="method"
                    :label="method"
                    :value="method")
      .endpoint-config-container
        h5 Endpoint
        aiq-input(:model-value="modelValue.path"
                  @update:modelValue="onUpdateField('path', $event)")

    .form-field
      h5 Headers
      input-box-pairs(:pairs="custom_headersList"
                      :style-left="headerStyleLeft"
                      :style-right="headerStyleRight"
                      @update="onPairsUpdate('custom_headers', $event)")
    .form-field
      h5 Query
      input-box-pairs(:pairs="queryList"
                      :style-left="headerStyleLeft"
                      :style-right="headerStyleRight"
                      @update="onPairsUpdate('query', $event)")
    .form-field
      .json-schema__header
        h5 Request Payload Schema
        aiq-tooltip(placement="right")
          template(v-slot:content) {{requestPayloadToolip}}
          i.iq-ico-explanation
        .payload-format-select
          aiq-select(:model-value="modelValue.request_payload_schema_type"
                    @update:modelValue="onUpdateField('request_payload_schema_type', $event)"
                    size="small"
                    placeholder="Select request payload format")
            aiq-option(v-for="format in $_payloadFormats"
                      :key="format.name"
                      :label="format.label"
                      :value="format.value")
      json-linter(v-if="request_payload_schema_type === 'json_schema'" :modelvalue="request_payload_schema"
                  :placeholder='requestPayloadPlaceholder'
                  @update:modelValue="onUpdateField('request_payload_schema', $event)")
      aiq-input(v-else type="textarea"
                :placeholder='requestPayloadPlaceholder'
                @update:modelValue="onUpdateField('request_payload_schema', $event)"
                :model-value="modelValue.request_payload_schema")
    .form-field
      .json-schema__header
        h5 Response Schema
        aiq-tooltip(placement="right")
          template(v-slot:content) Enter in JSON format
          i.iq-ico-explanation
      json-linter(:model-value="modelValue.response_payload_schema"
                  @update:modelValue="onUpdateField('response_payload_schema', $event)")

    .form-field
      .json-schema__header
        h5 Use Sample Payload
        aiq-checkbox(:model-value="modelValue.use_sample_payload"
                     @update:modelValue="onUpdateField('use_sample_payload', $event)")
      .sample-json(v-if="modelValue.use_sample_payload")
        .json-schema__header
          h5 Sample Payload
          aiq-tooltip(placement="right")
            template(v-slot:content) Enter in JSON format
            i.iq-ico-explanation
        json-linter(:model-value="modelValue.sample_payload"
                    @update:modelValue="onUpdateField('sample_payload', $event)")



    .save-buttons-container
        aiq-button(@click="onShowTestDialog" type="primary") Test

        aiq-button(:disabled="isPristine"
                  @click="$emit('cancel')") Cancel
        aiq-button(:disabled="isPristine"
                  type="primary"
                  @click="$emit('save')") Save

  div
    aiq-dialog.modal-editor(title="Test Api",
                            :model-value="testDialogShown"
                            :show-close="false")

      .form-field
        .json-schema__header
          h5 Method: {{ $filters.capitalize(method) }}
        .json-schema__header
          h5.path-label Path:
          aiq-input(v-model="testPath")

      .form-field
        h5 Queries
        input-box-pairs(:pairs="testQueryList"
                        :disableLeft="true"
                        :disableAddition="true"
                        :disableDeletion="true"
                        :style-left="headerStyleLeft"
                        :style-right="headerStyleRight"
                        @update="onTestPairsUpdate")
      .form-field(v-if="fullHeaders.length !== 0")
        .json-schema__header
          h5 Headers
        .header-vals(v-for="keyVal in fullHeaders")
          .header-row {{ keyVal[0] }}: {{ keyVal[1] }}
        .json-schema__header
          h5 Url: {{ fullUrl }}

      .form-field(v-if="method !== 'get'")
        .json-schema__header
          h5 Request Payload
        json-linter(:model-value="testPayload"
                    @update:modelValue="testPayload = $event")

      .form-field
        .test-result
          h5 Response
          aiq-input(type="textarea" :disabled="true" :autosize="{ minRows: 2, maxRows: 20 }" placeholder="Please input" :model-value="testResultJson")

      template(v-slot:footer v-loading="inUploadingProgress")
        .dialog-footer
          aiq-button(@click="onCloseTestDialog" :disabled="inUploadingProgress") Cancel
          aiq-button(type="primary" @click="onSendTest" :disabled="inUploadingProgress") Send

</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { InputBoxPairs, JsonLinter } from '@/components';
import { pairsListToObject } from '@/libs';

const HEADER_STYLE_LEFT = { width: '200px' };
const HEADER_STYLE_RIGHT = { 'flex-grow': 1 };
const JSON_INDENT_SPACE = 2;

// TODO (Gabe) - Parse JSON string schemas and display in JSON format
// TODO (Gabe) - Set up response and request hints

export default {
  name: 'endpointConfig',
  components: {
    InputBoxPairs,
    JsonLinter,
  },
  props: {
    modelValue: {
      type: Object,
      default: {},
    },
    isPristine: Boolean,
    emitter: Object,
    testResult: {
      type: Object,
      default: null,
    },
    parentUrl: {
      type: String,
      default: '',
    },
    parentHeaders: {
      type: Object,
      default: {},
    },
  },
  emits: ['update:modelValue', 'test', 'clean', 'cancel', 'save'],
  data() {
    return {
      editFlag: false,
      testDialogShown: false,
      testPayload: '',
      testQueryList: [],
      testPath: '',
    };
  },
  computed: {
    custom_headersList() {
      return Object.entries(this.modelValue.custom_headers);
    },
    queryList() {
      return Object.entries(this.modelValue.query);
    },
    requestPayloadToolip() {
      return this.request_payload_schema_type === 'json_schema' ?
        'Add Json schema that should be sent along with this request' :
        'Add Text with {{variable}} that should be sent along with this request';
    },

    requestPayloadPlaceholder() {
      return this.request_payload_schema_type === 'json_schema' ?
        '{ "type": "object", "properties": {"customer_id": { "type": "string" } } }' :
        '{ "customer_id": {{user_id}} }';
    },

    testResultJson() {
      return this.testResult ? JSON.stringify(this.testResult, null, JSON_INDENT_SPACE) : '';
    },

    fullUrl() {
      let queryString = '';

      if (this.testQueryList.length !== 0) {
        const queryUnits = [];
        for (const [key, value] of this.testQueryList) {
          queryUnits.push(`${key.trim()}=${value.trim()}`);
        }
        queryString = `?${queryUnits.join('&')}`;
      }

      return this.parentUrl + this.testPath + queryString;
    },

    fullHeaders() {
      return Object.entries(Object.assign({}, this.parentHeaders, this.modelValue.custom_headers));
    },
  },
  created() {
    this.headerStyleLeft = HEADER_STYLE_LEFT;
    this.headerStyleRight = HEADER_STYLE_RIGHT;
    this.$_methods = ['get', 'put', 'post', 'del'];
    this.$_payloadFormats = [{
      value: 'json_schema',
      label: 'JSON Schema',
    }, {
      value: 'text',
      label: 'Text',
    }];
  },
  methods: {
    goIntoEditMode() {
      this.editFlag = true;
      this.$nextTick(() => {
        this.$refs.edit.focus();
      });
    },
    onPairsUpdate(type, pairs) {
      const newPairs = pairsListToObject(pairs);
      this.onUpdateField(type, newPairs);
    },
    queryListToQueryObject(queryList) {
      return queryList.reduce((recur, item) => {
        recur[item[0]] = item[1];
        return recur;
      }, {});
    },
    onTestPairsUpdate(pairs) {
      this.testQueryList = pairs;
    },
    onSendTest() {
      try {
        const payload = this.testPayload ? JSON.parse(this.testPayload) : undefined;
        this.$emit('test', {
          endpoint: {
            name: this.modelValue.name,
            method: this.modelValue.method,
            path: this.testPath,
            query: this.queryListToQueryObject(this.testQueryList),
            custom_headers: this.modelValue.custom_headers,
            use_sample_payload: this.modelValue.use_sample_payload,
            sample_payload: this.modelValue.sample_payload,
          },
          payload,
        });
      } catch (err) {
        this.$aiq.notify.error('Please check json format.');
      }
    },
    onShowTestDialog() {
      this.testDialogShown = true;
      this.testPath = cloneDeep(this.modelValue.path);
      this.testQueryList = cloneDeep(this.queryList);
    },
    onCloseTestDialog() {
      this.testDialogShown = false;
      this.$emit('clean');
    },
    onUpdateField(name, value) {
      this.$emit('update:modelValue', { ...this.modelValue, [name]: value });
    },
  },
};
</script>

<style lang="scss">
.middle-column_header.endpoint-config-view {
  .el-input__inner {
    height: 30px;
  }

  .method-endpoint-container {
    .method-config-container {
      .el-select {
        width: 140px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import "../../../../styles/aiq-mixins.scss";
@import "../../../../styles/aiq-extensions.scss";

.endpoint-config {
  @include space-between-v(16px);
  overflow-y: scroll;
  height: calc(100vh - 150px);

  background-color: #fff;
  padding: 32px 32px 24px 32px;

  .button-add-new-item {
    padding: 0;
  }
}

h5 {
  @extend %settings__subheader;
}

.method-endpoint-container {
  display: flex;

  .method-config-container {
    margin-right: 24px;
  }

  .endpoint-config-container {
    flex-grow: 1;
  }
}

.json-schema__header {
  @include space-between-h(8px);
  display: flex;

  i {
    margin-top: 3px;
  }
}

.schema__inner-content {
  border: 1px solid #bfcbd9;
  border-radius: 4px;
  min-height: 30px;
}

.edit__btn {
  padding: 0;
  margin-left: 10px;
  border: 0;
  color: #fff;
  background-color: transparent;
}

.help-icon {
  margin-left: 8px;
}

.save-buttons-container {
  align-self: flex-end;
}

.header-vals {
  margin-left: 20px;
}

.path-label {
  padding-top: 5px;
}

.payload-format-select {
  width: 30%;
  margin-bottom: 5px;
}
</style>
