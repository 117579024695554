import { Component, Watch, Vue, toNative } from 'vue-facing-decorator';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';

import { createNamespacedHelpers } from 'vuex';
import { CommandToAction, EntitySuggestionPopupsMixin } from '@/libs';
import aiqCommandView from './AIQCommandView/AIQCommandView.vue';
import aiqCommandBuilder from '../BuildingKitAiTesting/BuildingKit/Commands/AIQCommandBuilder/AIQCommandBuilder.vue';

const { mapState } = createNamespacedHelpers('buildingKit');
const { mapGetters } = createNamespacedHelpers('entities');

@Component({
  name: 'actionItem',
  mixins: [EntitySuggestionPopupsMixin],
  props: {
    actionType: {
      type: String,
    },
    editable: {
      type: Boolean,
      default: () => false,
    },
    item: {
      type: Object,
      required: true,
    },
    ownId: {
      type: Number,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    showNumbering: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ['delete', 'deleteResponse', 'update', 'click'],
  computed: {
    // TODO: remove this dependency to make this component light and pluggable.
    ...mapState(['commands']),
    ...mapGetters({
      entityNames: 'names',
    }),
    actionItemContent() {
      return CommandToAction(this.item).content;
    },
    actionItemType() {
      return CommandToAction(this.item).type;
    },
    actionProps() {
      let icon = 'iq-ico-building-kit-command';

      switch (this.preparedItem.type) {
        case 'response':
          icon = 'iq-ico-building-kit-response';
          break;
        case 'intent':
          icon = 'iq-ico-building-kit-intent';
          break;
        case 'dialog':
          icon = 'iq-ico-building-kit-dialog';
          break;
        case 'workflow':
          icon = 'iq-ico-building-kit-workflow';
          break;
        default:
      }

      return {
        icon,
      };
    },
    index() {
      return this.ownId + 1;
    },
    isCollapsible() {
      // currently, command is only collapsible
      return this.preparedItem.type === 'command';
    },
    inEditMode() {
      const { editResponseFlag, showDelete, preparedItem } = this;
      return editResponseFlag && !showDelete && preparedItem.type === 'response';
    },
    preparedItem() {
      let content = this.inputText;
      let type = this.actionItemType;
      const regex = /\B@[0-9a-zA-Z_]+(\.[0-9a-zA-Z_]+)*/g;
      const theAtCharStringIfExists = content.match(regex);

      if (type === 'response' && theAtCharStringIfExists) {
        content = this.formatEntityWords(theAtCharStringIfExists, content);
      }

      this.actionType && (type = this.actionType);

      return {
        content,
        type,
      };
    },
    showEditButton() {
      const { editResponseFlag, showDelete, preparedItem } = this;
      return !editResponseFlag && !showDelete && preparedItem.type === 'response';
    },
  },
  components: {
    aiqCommandBuilder,
    aiqCommandView,
  },
})

class ActionItem extends Vue {
  editResponseFlag = false;

  // collapsible needs a list
  collapsibleItems = [];

  @Watch('item')
  onItemChanged(newItem) {
    this.inputText = CommandToAction(newItem).content;
    this.tempInputText = this.inputText;
  }

  cancelEdit() {
    this.tempInputText = this.inputText;
    this.editResponseFlag = false;
  }

  collapseBuilder() {
    this.collapsibleItems = [];
  }

  deleteAction() {
    // TODO (Gabe) - refactor this component to avoid this
    if (this.showDelete) {
      this.$emit('delete', this.ownId);
    } else {
      this.$emit('deleteResponse', get(this.item, 'id'));
      this.editResponseFlag = false;
    }
  }

  editResponse() {
    this.editResponseFlag = true;
  }

  formatEntityWords(possibleEntityWords, content) {
    const uniqueWords = [...new Set(possibleEntityWords)];

    for (const word of uniqueWords) {
      const withAtCharStrippedOut = word.slice(1);
      if (this.entityNames.includes(withAtCharStrippedOut)) {
        const re = new RegExp(word, 'g');
        content = content.replace(re, `<span class="selected-blue-default"><mark>${word}</mark></span>`);
      }
    }

    return content;
  }

  saveResponse() {
    this.editResponseFlag = false;
    this.updateAction();
  }

  updateAction() {
    const updatedItem = cloneDeep(this.item);

    if (get(updatedItem, 'payload.message.payload.content')) {
      updatedItem.payload.message.payload.content = this.tempInputText;
      this.$emit('update', get(this.item, 'id', this.ownId), updatedItem);
    } else {
      this.$aiq.notify.error('Failed to update action.');
    }
  }

  onClick() {
    return this.$emit('click', cloneDeep(this.item));
  }

  mounted() {
    this.inputText = CommandToAction(this.item).content;
    this.tempInputText = CommandToAction(this.item).content;
  }
}

export default toNative(ActionItem);
