/* eslint-disable no-undef, prefer-const, no-undef, one-var, func-names */

const loadUserGuidingJs = (document, window, containerID) => new Promise(resolve => {
  (function (u, s, e, r, g) {
    u[r] = u[r] || [];
    u[r].push({
      'ug.start': new Date().getTime(),
      event: 'embed.js',
    });
    let f = s.getElementsByTagName(e)[0],
        j = s.createElement(e);
    j.async = true;
    j.src = `https://static.userguiding.com/media/user-guiding-${g}-embedded.js`;
    const ref = f.parentNode.insertBefore(j, f);
    resolve(ref);
  }(window, document, 'script', 'userGuidingLayer', containerID));
});

export const userGuidingConfigIsValid = (config = {}) => {
  const {
    start_guide: startGuide,
    end_guide: endGuide,
    container_id: containerID,
  } = config;

  return startGuide && endGuide && containerID;
};

export const loadUserGuiding = ({
  document,
  window,
  skipTutorial,
  config,
  _loadUserGuidingJs = loadUserGuidingJs, // permit us to mock in tests, zero-config in production
} = {}) => {
  if (!config || !document || !window) {
    skipTutorial && skipTutorial();
    return;
  }

  const {
    start_guide: startGuide,
    end_guide: endGuide,
    container_id: containerID,
  } = config;

  if (startGuide && endGuide && containerID) {
    _loadUserGuidingJs(document, window, containerID)
      .then(scriptTagRef => {
        scriptTagRef.onload = () => {
          window.userGuiding.previewGuide(startGuide);
          window.userGuidingLayer.push({
            event: 'onPreviewEnd',
            fn: (event) => {
              if (event.completed && event.guideId === endGuide) {
                skipTutorial && skipTutorial(event);
              } else if (!event.completed) {
                skipTutorial && skipTutorial(event);
              }
            },
          });
        };
        scriptTagRef.onerror = (error) => {
          skipTutorial && skipTutorial(null, error);
        };
      })
      .catch(error => {
        skipTutorial && skipTutorial(null, error);
      });
  } else {
    skipTutorial && skipTutorial(null, new Error('No configuration'));
  }
};
