import get from 'lodash/get';
import Agents from './Agents/Agents.vue';
import ManageAgents from './Agents/children/Manage/Manage.vue';
import AgentEditor from './Agents/children/Manage/Edit.vue';
import ManageRouting from './Agents/children/Routing/Routing.vue';
import ManageAccess from './Agents/children/Access/Access.vue';
import Dashboard from './Agents/children/Dashboard/Dashboard.vue';
import Customer from './Agents/children/Customer/Customer.vue';
import Notification from './Agents/children/Notification/Notification.vue';
import QuickResponses from './Agents/children/QuickResponses/Manage.vue';
import QuickResponseEditor from './Agents/children/QuickResponses/Edit.vue';
import Status from './Agents/children/Status/Manage.vue';
import Apis from './Apis/ApiManagement.vue';
import Assets from './Assets/Manage.vue';
import AssetEditor from './Assets/Edit.vue';
import Channels from './Channels/Channels.vue';
import Documents from './Documents/Manage.vue';
import DocumentEditor from './Documents/Edit.vue';
import KeywordsManagement from '@/components/KeywordsManagement/KeywordsManagement.vue';
import Settings from './Settings.vue';
import MangeTemplates from './Agents/children/MessageTemplate/ManageTemplate.vue';
import Teams from './Teams/Manage.vue';
import TeamEditor from './Teams/Edit.vue';
import Import from './Import/Import.vue';
import store from '@/store';
import featureFlags from '@/store/modules/featureFlags';
import i18next from 'i18next';

import IntegrationRouter from './Integrations/Integrations.router';
import { TAGS_DESCRIPTION, NON_DELETABLE_TAGS } from '@/constants/tag';

const showAgentStatus = get(featureFlags, 'state.FEATURE_FLAGS.SETTINGS_AGENT_STATUS', true);
const showMessageTemplat = get(featureFlags, 'state.FEATURE_FLAGS.SHOW_MESSAGE_TEMPLATES', true);

const CATEGORIES_DESCRIPTION = function description() {
  return `<p>${i18next.t('settings_tab.categories_tab.categories_service_specialty_description')}</p>
  <p>${i18next.t('settings_tab.categories_tab.categories_keyword_description')}</p>`;
};

const baseResource = '/settings/agents';
const buildResourcePath = (resource) => `${baseResource}/${resource}`;

const rootRoute = {
  path: '/settings',
  name: 'Settings',
  component: Settings,
  meta: {
    auth: true,
  },
};
export default {
  ...rootRoute,
  children: [
    {
      path: 'agents',
      name: 'Settings.Agents',
      props: true,
      component: Agents,
      meta: {
        auth: true,
        resource: buildResourcePath('manage'),
        parentName: rootRoute.name,
        lang_key: 'settings_tab.admin_tab.title',
      },
      children: [
        {
          path: 'manage',
          name: 'Settings.Agents.Manage',
          component: ManageAgents,
          props: true,
          meta: {
            auth: true,
            resource: buildResourcePath('manage'),
            parentName: rootRoute.name,
          },
        },
        {
          path: 'manage/:id',
          name: 'Settings.Agents.Manage.Edit',
          component: AgentEditor,
          props: _ => (store.getters['routing/props']),
          meta: {
            auth: true,
            isEditor: true,
            resource: buildResourcePath('manage/:id'),
            parentName: 'Settings.Agents.Manage',
          },
        },
        {
          path: 'routing',
          name: 'Settings.Agents.Routing',
          component: ManageRouting,
          props: true,
          meta: {
            auth: true,
            resource: buildResourcePath('routing'),
          },
        },
        {
          path: 'access',
          name: 'Settings.Agents.Access',
          component: ManageAccess,
          props: true,
          meta: {
            auth: true,
            resource: buildResourcePath('access'),
          },
        },
        {
          path: 'dashboard',
          name: 'Settings.Agents.Dashboard',
          component: Dashboard,
          props: true,
          meta: {
            auth: true,
            resource: buildResourcePath('dashboard'),
          },
        },
        {
          path: 'customer',
          name: 'Settings.Agents.Customer',
          component: Customer,
          props: true,
          meta: {
            auth: true,
            resource: buildResourcePath('customer'),
          },
        },
        {
          path: 'notification',
          name: 'Settings.Agents.Notification',
          component: Notification,
          props: true,
          meta: {
            auth: true,
            resource: buildResourcePath('notification'),
          },
        },
        {
          path: 'quick_responses',
          name: 'Settings.Agents.QuickResponse',
          component: QuickResponses,
          props: true,
          meta: {
            auth: true,
            resource: buildResourcePath('quick_responses'),
          },
        },
        ...(showMessageTemplat ? [{
          path: 'message_template',
          name: 'Settings.Agents.Templates',
          component: MangeTemplates,
          props: true,
          meta: {
            auth: true,
            resource: buildResourcePath('message_template'),
          },
        }] : []),
        {
          path: 'quick_responses/:id',
          name: 'Settings.Agents.QuickResponse.Edit',
          component: QuickResponseEditor,
          props: true,
          meta: {
            auth: true,
            isEditor: true,
            resource: buildResourcePath('quick_responses/:id'),
            parentName: 'Settings.Agents.QuickResponse',
          },
        },
        ...(showAgentStatus ? [{
          path: 'status',
          name: 'Settings.Agents.Status',
          component: Status,
          props: true,
          meta: {
            auth: true,
            resource: buildResourcePath('status'),
          },
        }] : []),
      ],
    },
    {
      path: 'teams',
      name: 'Teams',
      component: Teams,
      meta: {
        auth: true,
        resource: '/settings/teams',
        parentName: rootRoute.name,
      },
    },
    {
      path: 'teams/:id',
      component: TeamEditor,
      meta: {
        auth: true,
        resource: '/settings/teams/:id',
        parentName: 'Teams',
      },
    },
    {
      path: 'channels',
      name: 'Channels',
      component: Channels,
      meta: {
        auth: true,
        parentName: rootRoute.name,
      },
    },
    {
      path: 'tags',
      name: 'Tags',
      component: KeywordsManagement,
      props: {
        type: 'tag',
        title: 'Tags',
        description: TAGS_DESCRIPTION,
        minimumAllowedKewords: 0,
        isSystemKeyword: (item) => NON_DELETABLE_TAGS.includes(item.name),
      },
      meta: {
        auth: true,
        parentName: rootRoute.name,
      },
    },
    {
      path: 'categories',
      name: 'Categories',
      component: KeywordsManagement,
      props: {
        type: 'category',
        title: 'Categories',
        description: CATEGORIES_DESCRIPTION,
      },
      meta: {
        auth: true,
        parentName: rootRoute.name,
      },
    },
    {
      path: 'assets',
      name: 'Assets',
      component: Assets,
      meta: {
        auth: true,
        parentName: rootRoute.name,
      },
    },
    {
      path: 'assets/:id',
      component: AssetEditor,
      meta: {
        auth: true,
        resource: '/settings/assets/:id',
        parentName: 'Assets',
      },
    },
    {
      path: 'documents',
      name: 'Documents',
      component: Documents,
      meta: {
        auth: true,
        parentName: rootRoute.name,
      },
    },
    {
      path: 'documents/:id',
      component: DocumentEditor,
      meta: {
        auth: true,
        resource: '/settings/documents/:id',
        parentName: 'Documents',
      },
    },
    IntegrationRouter,
    {
      path: 'apis',
      name: 'APIs',
      component: Apis,
      meta: {
        auth: true,
        parentName: rootRoute.name,
      },
    },
    {
      path: 'import',
      name: 'Import',
      component: Import,
      meta: {
        auth: true,
        resource: '/settings/import',
        parentName: rootRoute.name,
      },
    },
  ],
};
