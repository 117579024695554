<template lang="pug">
  .tags-summary-table
    summary-table.scrollable(:dateRange="dateRange"
                  report="conversations_per_tag"
                  :selectedFilters="filters"
                  breakdown="day"
                  :selectable="false"
                  :items="items"
                  @onDataChange="onDataChange")
        slot
          aiq-table-column(label="Tag", prop="tag_name" width="120" label-class-name="is-bordered")
            template(v-slot:default="scope")
              aiq-button(link type="primary" size="small", @click="goToConversation(scope.row)") {{ scope.row.tag_name }}
          aiq-table-column(label="Conversations", prop="conversations_per_tag"  width="" :sortable="true" label-class-name="is-bordered")
          aiq-table-column(label="Percent", prop="conversations_per_tag_percent" :sortable="true"  width="250" label-class-name="is-bordered" :formatter="formatPercentage")

</template>

<script>
import get from 'lodash/get';
import SummaryTable from '../../../SummaryTable.vue';
import { downloadAsFile } from '@/libs';

export default {
  name: 'summaryMetricsSummaryTable',
  components: {
    SummaryTable,
  },
  props: {
    dateRange: {
      required: true,
      type: Array,
    },
    query: {
      required: false,
      type: String,
    },
    filters: {
      type: Array,
    },
    namedFilters: {
      type: Object,
    },
  },
  data() {
    return {
      chartData: null,
      items: [],
      selection: [],
      named: this.namedFilters,
    };
  },
  methods: {
    onDataChange(data = {}) {
      this.items = get(data, 'dataset.0.data_points', []);
    },
    exportTable() {
      downloadAsFile({
        dataType: 'table',
        dataSource: 'metrics',
        data: this.items,
      });
    },
    goToConversation(item) {
      const customer_segments = get(this.namedFilters, 'customer_segments');
      const teams = get(this.namedFilters, 'teams');

      if (this.canView('/conversations')) {
        this.$router.push({
          name: 'ConversationByCustomerId',
          query: {
            tag_names: item.tag_name,
            date_range: this.dateRange,
            customer_segments,
            teams,
          },
        });
      }
    },
    formatPercentage(row) {
      return `${row.conversations_per_tag_percent}%`;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-inner {
  padding: 0;
}

.el-tag {
  margin: 0 4px 1px 0;
}
</style>

<style lang="scss">
@import "../../../../../styles/aiq-mixins.scss";

.tags-summary-table {
  .scrollable .el-table__body-wrapper {
    @include scrollable(calc(100vh - 257px));
  }
}
</style>
