<template>
<svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 62 (91390) - https://sketch.com -->
    <title>cobrowsing</title>
    <desc>Created with Sketch.</desc>
    <g id="convo-list-update-&amp;-mid-section-update" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linejoin="round">
        <g id="Artboard" transform="translate(-143.000000, -56.000000)" stroke="#5993FF">
            <g id="cobrowsing" transform="translate(144.000000, 57.000000)">
                <path d="M12.8647686,5.81059689 L16.4521777,13.7826172 C16.542833,13.9840733 16.4530111,14.2208764 16.251555,14.3115316 C16.1288486,14.3667495 15.9866015,14.3565724 15.8730059,14.2844482 L12.7144033,12.2789862 C12.583542,12.1958997 12.416458,12.1958997 12.2855967,12.2789862 L9.12699413,14.2844482 C8.94049578,14.4028598 8.69331739,14.3476646 8.57490575,14.1611663 C8.50278151,14.0475706 8.49260438,13.9053236 8.54782227,13.7826172 L12.1352314,5.81059689 C12.2258867,5.60914076 12.4626897,5.5193189 12.6641459,5.60997416 C12.7532876,5.65008793 12.8246548,5.72145519 12.8647686,5.81059689 Z" id="Triangle" transform="translate(12.500000, 10.000000) rotate(-45.000000) translate(-12.500000, -10.000000) "></path>
                <path d="M12,5.4 C11.3333333,4.06666667 10.3333333,3.4 9,3.4 C8,3.4 7.35,3.75 6.85,4.25 C6.35,4.75 6,5.4 6,6.4 C6,7.73333333 6.66666667,8.73333333 8,9.4" id="Path-3" stroke-width="1.3" stroke-linecap="round" stroke-dasharray="0,2.3"></path>
                <path d="M15,6 L15,2 C15,0.8954305 14.1045695,-2.02906125e-16 13,0 L2,0 C0.8954305,2.02906125e-16 -1.3527075e-16,0.8954305 0,2 L0,11 C1.3527075e-16,12.1045695 0.8954305,13 2,13 L9.16666667,13 L9.16666667,13" id="Path" stroke-linecap="round"></path>
            </g>
        </g>
    </g>
</svg>
</template>
