import SidebarAndPanesWithRouting from '@/components/layouts/SidebarAndPanesWithRouting.vue';
import Availability from './children/Availability.vue';
import Performance from './children/Performance.vue';

const rootRoute = {
  path: 'agents',
  name: 'Agents',
  component: SidebarAndPanesWithRouting,
  meta: {
    auth: true,
  },
  props: {
    baseResource: '/metrics/agents',
  },
};

const commonChildMeta = {
  auth: true,
  resource: rootRoute.path,
  parentName: rootRoute.name,
};

const showPerformanceMetrics = process.env.FEATURE_FLAGS.PERFORMANCE_METRICS_PAGE;

const childRoutes = [
  ...(showPerformanceMetrics ?
    [{
      path: 'performance',
      name: 'Metrics.Agents.Performance',
      component: Performance,
      meta: {
        label: 'Performance',
        ...commonChildMeta,
      },
    }] : []),
  {
    path: 'availability',
    name: 'Metrics.Agents.Availability',
    component: Availability,
    meta: {
      label: 'Availability',
      ...commonChildMeta,
    },
  },
];

rootRoute.props.children = childRoutes;

export default {
  ...rootRoute,
  children: childRoutes,
};
