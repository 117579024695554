<template lang="pug">
.responsive_table_view
  .normal_size_view(v-show='isNormal')
    aiq-table.scrollable(ref="responsiveTable" :data='data' :row-class-name="getRowClassName" @sort-change="onSortChange")
      aiq-table-column(
        v-for='p in columns',
        :prop='p.name',
        :label='p.label',
        :key='p.key',
        :min-width='p.min',
        :sortable='p.sort ? true : false',
        :sort-method='p.sort ? p.sort(p.name) : undefined',
        :type="p.type"
        label-class-name='is-bordered'
      )
        template(v-slot:header)
          aiq-tooltip(v-if='p.tooltip'
                      effect='dark'
                      placement='top'
                      :show-after='500'
                      :content='p.tooltip')
            span {{ p.label }}
          span(v-else) {{ p.label }}
        template(v-slot:default='scope')
          span {{ p.get(scope.row) }} &nbsp;

  .small_size_view(v-show='!isNormal')
    aiq-table.scrollable(:data='data', :show-header='false')
      aiq-table-column
        template(v-slot:default='props')
          p {{ columns[0].get(props.row) }}

      aiq-table-column(type='expand')
        template(v-slot:default='props')
          aiq-table.scrollable(
            :data='columns.slice(1, columns.length)',
            :show-header='false'
          )
            aiq-table-column(width='300')
              template(v-slot:default='scope')
                b {{ scope.row.label }}
            aiq-table-column
              template(v-slot:default='scope')
                p {{ scope.row.get(props.row) }}
</template>

<script>
export default {
  name: 'ResponsiveTable',
  props: {
    data: {
      required: true,
      type: Array,
    },
    columns: {
      required: true,
      type: Array,
    },
    isNormal: {
      required: true,
      type: Boolean,
    },
    defaultSort: {
      type: Object,
    },
    rowsCount: {
      type: Number,
      default: 0,
    },
  },
  emits: ['set-export-table-sort'],
  mounted() {
    this.setDefaultSort(this.defaultSort);
  },
  methods: {
    setDefaultSort(defaultSort) {
      this.$nextTick(() => {
        this.$refs.responsiveTable.clearSort();
        this.$refs.responsiveTable.sort(defaultSort.prop, defaultSort.order);
      });
    },
    getRowClassName({ rowIndex }) {
      if (this.rowsCount && rowIndex >= this.rowsCount) {
        return 'hide-row';
      }
    },
    onSortChange({ prop, order }) {
      this.$emit('set-export-table-sort', { prop, order });
    },
  },
  watch: {
    defaultSort(newVal) {
      this.setDefaultSort(newVal);
    },
  },
};
</script>

<style lang="scss">
.responsive_table_view {
  div.cell {
    font-size: 14px;
  }
  tr.el-table__row.hide-row {
    display: none;
  }
}
</style>
