/**
 * Convert digit path to :number
 *
 * ex) /users/34343 to /users/:number
 */
function normalizePath(path) {
  return path.replace(/\/\d+($|\/)/, '/:number/').replace(/\/$/, '');
}

const isItIncludePermission = (store, alias, permission) => Array.of
  .apply(null, store[normalizePath(alias)])
  .includes(permission);

export const canView = (store, alias) => isItIncludePermission(store, `[dashboard]${alias}`, 'view');

export const canEdit = (store, alias) => isItIncludePermission(store, `[dashboard]${alias}`, 'edit');

/**
 * canCreate and canDelete refers to backend endpoints so it
 * does not need [dashbard] prefix.
 */
export const canCreate = (store, alias) => isItIncludePermission(store, `${alias}`, 'create');

export const canDelete = (store, alias) => isItIncludePermission(store, `${alias}`, 'delete');
