<template lang="pug">
  popoverContainer(:title="title")
    ul.commands-list.scroll(@mouseenter="clearExistingHighlighted")
      li.commands-list-item(v-for="item in list",
                            :class="{'active': !itemHovered && highlighted && highlighted.id === item.id}",
                            :key="item.id",
                            :id="`item` + item.id",
                            @click="select(item)")
        aiq-col(:span="18")
          .command-name {{ item[nameKey] }}
          .command-description(v-if="item.description") {{ item.description }}
        aiq-col(:span="6")
          .cmd-btn-group
            aiq-button(v-if="item.button" @click.stop="onBtnClick(item)" size="small") {{ item.button.label }}
</template>

<script>
import PopoverContainer from './PopoverContainer.vue';

export default {
  name: 'itemsPopover',
  components: {
    PopoverContainer,
  },
  data() {
    return {
      itemHovered: false,
    };
  },
  props: {
    title: {
      type: String,
      default: () => 'Commands',
    },
    highlighted: {
      type: Object,
    },
    list: {
      type: Array,
      default: () => [],
    },
    nameKey: {
      type: String,
      default: 'name',
    },
  },
  emits: ['select', 'event'],
  watch: {
    highlighted(value) {
      if (value && this.$el.querySelector) {
        this.$el.querySelector(`#item${value.id}`).scrollIntoView({ behavior: 'smooth' });
      }
      this.itemHovered = false;
    },
  },
  methods: {
    clearExistingHighlighted() {
      this.itemHovered = true;
    },
    select(item = this.highlighted) {
      // if (this.opened) {
      this.$emit('select', item);
      // }
    },

    onBtnClick(item) {
      this.$emit('event', {
        name: item.button.event,
        item,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "./../../../../../../styles/aiq-variables.scss";
  .commands-list {
    max-height: 60vh;
    padding: 0;
    margin: 0;
  }
  .commands-list-item {
    height: 45px;
    padding: 0 10px;
    margin: 0;
    font-size: 12px;
    line-height: 14px;

    &.active {
      color: #fff;
      background-color: $aiq-bgc-message-command-item-active;

      .command-description {
        color: #fff;
      }
    }
    &:hover {
      color: #fff;
      background-color: $aiq-bgc-message-command-item-active;
      cursor: pointer;

      .command-description {
        color: #fff;
      }
    }
  }
  .command-name {
    padding-top: 8px;
    font-weight: 500;
  }
  .command-prompt {
    margin-top: -1px;
    color: $aiq-bgz-message-command-item-description;
    font-weight: 300;
  }
  .command-description {
    margin-top: -1px;
    color: $aiq-bgz-message-command-item-description;
    font-weight: 300;
  }
  .cmd-btn-group {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 8px;

    .el-button {
      border: 1px solid #BFCBD9;
      border-color: #c4c4c4;
      background-color: white;
    }
  }
</style>
