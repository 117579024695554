import axios from 'axios';
import { getUrl } from '@/config/api.routes';

const ROUTING_ALIAS = 'management.compliance';

export default {
  namespaced: true,
  state: {
    compliance: [],
    complianceLoaded: false,
    listPending: false,
    readPending: false,
  },
  getters: {},
  mutations: {
    GET_COMPLIANCE_LIST(state) {
      state.listPending = true;
    },
    GET_COMPLIANCE_LIST_SUCCESS(state, items) {
      state.compliance = items;
      state.listPending = false;
    },
    COMPLIANCE_LOADED(state, value) {
      state.complianceLoaded = value;
    },
    GET_COMPLIANCE(state) {
      state.readPending = true;
    },
    GET_COMPLIANCE_SUCCESS(state) {
      state.readPending = false;
    },
  },
  actions: {
    getComplianceList({ commit }) {
      commit('GET_COMPLIANCE_LIST');
      return axios.get(getUrl(ROUTING_ALIAS))
        .then(res => {
          const items = res.data;
          if (items.length === 0) {
            commit('COMPLIANCE_LOADED', true);
          }
          commit('GET_COMPLIANCE_LIST_SUCCESS', items);
          return items;
        });
    },
    markComplianceAsLoaded({ commit }, value) {
      commit('COMPLIANCE_LOADED', value);
    },
    getCompliance({ commit }, params) {
      commit('GET_COMPLIANCE');
      params = { ...params, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
      return axios.get(`${getUrl(ROUTING_ALIAS)}/report`, { responseType: 'blob', params });
    },
  },
};
