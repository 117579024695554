import get from 'lodash/get';
import has from 'lodash/has';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { QUERY_MAPPING } from '@/constants';

const expandValues = (simpleValues, complexValues, propertyName) => {
  const complexValuesObj = complexValues.reduce(
    (accumulator, currentValue) => ({ ...accumulator, [currentValue[propertyName]]: currentValue }),
    {},
  );
  if (Array.isArray(simpleValues)) {
    return simpleValues.map(value => complexValuesObj[value]);
  }
  return [complexValuesObj[simpleValues]];
};

export const sanitizeQueryParams = (queryParams, path) => {
  const validQueryParams = get(QUERY_MAPPING, `${path}.filters`, {});
  const cleanQueryParams = {};
  for (const validKey in validQueryParams) {
    if (has(queryParams, validKey)) {
      cleanQueryParams[validKey] = queryParams[validKey];
    }
  }
  return cleanQueryParams;
};

export const simplifyQueryParams = (queryParams, path) => {
  const validQueryParams = get(QUERY_MAPPING, `${path}.filters`, {});
  const simpleQueryParams = {};
  for (const paramKey in queryParams) {
    if (validQueryParams[paramKey] && queryParams[paramKey] && !isEmpty(queryParams[paramKey])) {
      simpleQueryParams[paramKey] =
        Array.isArray(queryParams[paramKey]) ?
          queryParams[paramKey].map(
            val => {
              if (isObject(val)) {
                return val instanceof Date ? // Date value is an object
                  moment(val).toISOString(true) : // Dates are formatted to ISO 8601 for readability
                  val[validQueryParams[paramKey].objPropertyName];
              }
              return val;
            },
          ) :
          queryParams[paramKey];
    }
  }
  return simpleQueryParams;
};

export const expandQueryParams = (ctx) => {
  const path = get(ctx, '$route.path');
  const queryParams = get(ctx, '$route.query', {});
  const validQueryParams = get(QUERY_MAPPING, `${path}.filters`, {});
  const expandedQueryParams = {};
  for (const [queryKey, queryValue] of Object.entries(queryParams)) {
    const queryParamDetails = validQueryParams[queryKey];
    expandedQueryParams[queryKey] =
      isObject(queryParamDetails) &&
      queryParamDetails.isAsync &&
      !queryParamDetails.simplePropertyValue ?
        expandValues(
          queryValue,
          ctx[queryParamDetails.optionsContainer],
          queryParamDetails.objPropertyName,
        ) :
        queryValue;
  }
  return expandedQueryParams;
};

export const getAsyncOptionContainers = (path) => {
  const validQueryParams = get(QUERY_MAPPING, `${path}.filters`, {});
  return Object.values(validQueryParams)
    .filter(paramValue => isObject(paramValue) && paramValue.isAsync)
    .map(paramValue => paramValue.optionsContainer);
};

export const adjustUrlArray = (path, query) => {
  const validQueryParams = get(QUERY_MAPPING, `${path}.filters`, {});
  for (const [queryKey, queryValue] of Object.entries(query)) {
    if (has(validQueryParams, `${queryKey}.optionsContainer`) && !Array.isArray(queryValue)) {
      query[queryKey] = [queryValue];
    }
  }
};
