import COLORS from './colors';

const conversationsTimelines = {
  name: 'conversations_timeline',
  title: 'Conversations',
  metric: 'conversations_timeline',
  lines: {
    domain: ['customers', 'agents'],
    range: [COLORS.red, COLORS.medium_turquoise],
    colorProperty: 'actorType',
  },
  // TODO: need to import the ID from the backend
  agentBotId: '2',
};

export default conversationsTimelines;
