import { createNamespacedHelpers, mapState } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import actionItem from '@/pages/IQTools/Partials/ActionItem/ActionItem.vue';
import { appendUnitType } from '@/libs';

const { mapMutations } = createNamespacedHelpers('intents/formIntent');

export default {
  name: 'intent-actions',
  components: {
    actionItem,
  },
  computed: {
    ...mapState({
      formIntent: state => state.intents.formIntent,
    }),
    formActionList() {
      return this.formIntent.actions;
    },
  },
  methods: {
    ...mapMutations([
      'UPDATE_ACTIONS',
      'DELETE_ACTION',
    ]),
    deleteAction(index) {
      this.DELETE_ACTION(index);
    },
    updateActions(evt) {
      const actions = cloneDeep(this.formActionList);

      if (evt.added) {
        // Putting in array to conform to appendUnitType
        const addedAction = actions[evt.added.newIndex];
        const action = [addedAction];
        appendUnitType(action);
      }

      this.UPDATE_ACTIONS(actions);
    },
  },
};
