<template lang="pug">
.category
  h2.category-title {{ category.title }}
  .category-body
    aiq-tooltip(v-for="metric in category.metrics" :key="metric.title" placement="top" :content="metric.tooltip" :disabled="!metric.tooltip")
      aiq-card(@click="handleClick(metric)"
              :class="[category.metrics.length === 1 && 'single', 'metric', selectedTitle === metric.title && 'is-selected']")
        .title {{ metric.title }}
        .value {{ metric.value }}
        .duration {{ metric.duration }}
</template>


<script>
import get from 'lodash/get';

export default {
  name: 'PerformanceCategory',
  props: {
    category: {
      type: Object,
      required: true,
    },
    selectedMetric: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['change-table'],
  methods: {
    handleClick(metric) {
      this.$emit('change-table', this.category, metric);
    },
  },
  computed: {
    selectedTitle() {
      return get(this.selectedMetric, 'title');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/styles/aiq-variables.scss";

.category {
  box-shadow: 2px 2px 2px #d9d9d9;
  background: #f2f2f2;
  border-radius: 2px;
  padding: 30px;

  .category-title {
    text-align: center;
    font-weight: bold;
    color: $aiq-bgc-header;
  }

  .category-body {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .metric {
      height: 150px;
      width: 300px;
      border-radius: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      transition: 0.4s;
      cursor: pointer;

      &:hover:not(.is-selected) {
        background-color: #d9d9d9;
      }

      .title {
        font-weight: bold;
        margin-bottom: 5px;
      }

      .value {
        font-size: 24px;
        color: $aiq-bgc-header;
      }

      .duration {
        font-size: 12px;
        font-weight: 200;
        margin-top: 10px;
      }
    }

    .is-selected {
      background: $aiq-bgc-header;
      color: white;
      .value {
        color: white;
        text-decoration: underline;
      }
    }

    .single {
      margin: 0 auto;
    }
  }
}
</style>
