import Dialogs from './Dialogs/Dialogs.vue';
import Entities from './Entities/Entities.vue';
import Intents from './Intents/Intents.vue';
import IQTools from './IQTools.vue';
import SystemTriggers from './SystemTriggers/SystemTriggers.vue';
import Workflows from './Workflows/Workflows.vue';

const rootRoute = {
  path: '/iqtools',
  name: 'IQ Tools',
  component: IQTools,
  meta: {
    auth: true,
  },
};

export default {
  ...rootRoute,
  children: [
    {
      path: 'intents',
      name: 'Intents',
      component: Intents,
      meta: {
        auth: true,
        resource: rootRoute.path,
        parentName: rootRoute.name,
      },
      children: [
        {
          path: ':id',
          component: Intents,
          meta: {
            auth: true,
            resource: rootRoute.path,
            parentName: rootRoute.name,
          },
        },
      ],
    },
    {
      path: 'entities',
      name: 'Entities',
      component: Entities,
      meta: {
        auth: true,
        resource: rootRoute.path,
        parentName: rootRoute.name,
      },
    },
    {
      path: 'dialogs',
      name: 'Dialogs',
      component: Dialogs,
      meta: {
        auth: true,
        resource: rootRoute.path,
        parentName: rootRoute.name,
      },
      children: [
        {
          path: ':id',
          component: Dialogs,
          meta: {
            auth: true,
            resource: rootRoute.path,
            parentName: rootRoute.name,
          },
        },
      ],
    },
    {
      path: 'workflows',
      name: 'Workflows',
      component: Workflows,
      meta: {
        auth: true,
        resource: rootRoute.path,
        parentName: rootRoute.name,
      },
      children: [
        {
          path: ':id',
          component: Workflows,
          meta: {
            auth: true,
            resource: rootRoute.path,
            parentName: rootRoute.name,
          },
        },
      ],
    },
    {
      path: 'system-triggers',
      name: 'System Triggers',
      component: SystemTriggers,
      meta: {
        auth: true,
        parentName: rootRoute.name,
      },
    },
  ],
};
