<template lang="pug">
.text-center.section
  v-calendar.custom-calendar(
      :masks="masks"
      :attributes="attributes"
      disable-page-swipe
      expanded
      :initial-page="page"
      @did-move="onPageUpdate")
    template(v-slot:day-content="{ day, attributes }")
      .day-container
        .day-date
          span.day-label(:class="todayClass(day)")  {{ day.day }}
        .job-container
          p.day-job(v-for="attr in attributes" 
                    :class="attr.customData.class"
                    @click="onClick(attr.customData.job)") {{ attr.customData.job.title }}
</template>

<script>
import moment from 'moment';

function determineClass(item) {
  switch (item.event_type) {
    case 'reminder':
      return 'job-item reminder';
    default:
      return 'job-item';
  }
}

const TODAY_DATE = new Date();

export default {
  props: {
    schedules: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Object,
      default: () => undefined,
    },
  },
  emits: ['change', 'item-click'],
  computed: {
    attributes() {
      return this.schedules.map(job => ({
        key: job.id,
        customData: {
          job,
          class: determineClass(job),
        },
        dates: moment(job.execute_at).toDate(),
      }));
    },
  },
  methods: {
    onPageUpdate(data) {
      this.$emit('change', data[0]);
    },
    onClick(data) {
      this.$emit('item-click', data);
    },
    todayClass(day) {
      if (day.day === TODAY_DATE.getDate()
        && day.month === (TODAY_DATE.getMonth() + 1) && day.year === TODAY_DATE.getFullYear()) {
        return 'today';
      }
      return '';
    },
  },
  data() {
    return {
      masks: {
        weekdays: 'WWW',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  display: none;
}

.job-container {
  padding-top: 3px;
  flex-grow: 1;
  overflow: auto;
}

.job-item {
  font-size: .75rem;
  border-radius: .125rem;
  line-height: 1.25;
  color: #fff;
  padding: 5px;
  cursor: pointer;
  background-color: blue;
}

.reminder {
  background-color: #e53e3e;
}

.day-container {
  padding-top: 5px;
  flex-direction: column;
  display: flex;
  height: 100%;
}

.day-label {
  padding: 2px;
}

.today {
  border-radius: 10px;
  background: #4470B5;
  color: white;
}

.day-job{
  margin-top: 3px;
}


</style>

<style lang="scss">
.custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;

  border-radius: 0;
  width: 100%;

  & .vc-header {
    padding: 10px 0;
    height: 48px;
    margin-top: unset;
    .vc-title {
      background-color: transparent;
    }
    .vc-arrow {
      background-color: transparent;
      margin: 0px 10px 0px 10px;
    }
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
}
</style>
