// unit should be one of action, dialog, or workflow
export const identifyUnitType = item => {
  if (item.type) {
    if (item.type === 'dialog' || item.type === 'workflow') {
      return item.type;
    }
    return 'action';
  }
  if (Object.prototype.hasOwnProperty.call(item.payload.hasOwnProperty, 'dialog_type')) {
    return 'dialog';
  } if (Object.prototype.hasOwnProperty.call(item.payload.hasOwnProperty, 'states')) {
    return 'workflow';
  }
  return 'action';
};

/*
 * append unit_type field to list of actions
 *
 * @param list of actions
 * @return list of actions with unit_type
 */
export const appendUnitType = actions => {
  actions.forEach(action => {
    if (!Object.prototype.hasOwnProperty.call(action, 'unit_type')) {
      action.unit_type = identifyUnitType(action);
    }
  });

  return actions;
};

export const removeActionIfExist = (list, idx) => {
  if (idx >= 0 && list.length > idx) {
    list.splice(idx, 1);
  }

  return idx;
};

export const fillEmptyAction = (list, action) => {
  for (const item of list) {
    if (item.id === action.id) {
      item.payload = action.payload;
      break;
    }
  }
};

export const removeDuplicateNameAction = (list, idx, action) => {
  if (typeof list[idx] === 'object' && list[idx].name === action.name) {
    list.splice(idx, 1);
  }
};

const UNIT_FIELDS = ['id', 'name', 'payload', 'unit_type'];

/**
 * Trim extra action fields off in action list.
 * This function mutate its value and returns original reference.
 *
 * @param list of actions
 * @return list of trimmed actions
 */
export const makeActionsToUnits = (actions) => {
  if (!actions || !(actions instanceof Array)) {
    return actions;
  }
  // add unit_type field
  appendUnitType(actions);

  actions.forEach(action => {
    for (const key of Object.keys(action)) {
      if (UNIT_FIELDS.indexOf(key) < 0) {
        delete action[key];
      }
    }
  });

  return actions;
};
