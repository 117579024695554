<template lang="pug">
  .content-inner.settings-integrations
    aiq-tabs(v-if="integrationsAvailable" v-model="activeName" type="border-card")
      aiq-tab-pane(v-if="canView('/settings/integrations/authentication')"
                  label="Authentication" name="authentication")
        authentication-tab
      aiq-tab-pane(v-if="canView('/settings/integrations/salesforce')"
                  label="Salesforce" name="salesforce")
        salesforce-tab
      aiq-tab-pane(v-if="canView('/settings/integrations/docusign')"
                   label="DocuSign" name="docusign")
        docu-sign-tab
      aiq-tab-pane(v-if="canView('/settings/integrations/adobesign')"
                   label="AdobeSign" name="adobesign")
        adobe-sign-tab
    .content-empty-container(v-else)
      span.content-empty-item
        | No integrations available.
</template>

<script>
import { Component, Vue, toNative } from 'vue-facing-decorator';
import { AuthenticationTab, SalesforceTab, DocuSignTab, AdobeSignTab } from './Tabs';

// the order that is displayed in tab view
const INTEGRATIONS = ['authentication', 'salesforce', 'docusign', 'adobesign'];

@Component({
  name: 'integrations',
  components: {
    AuthenticationTab,
    SalesforceTab,
    DocuSignTab,
    AdobeSignTab,
  },
})
class Integrations extends Vue {
  integrationsAvailable = false;
  activeName = INTEGRATIONS[0];

  created() {
    for (const integration of INTEGRATIONS) {
      if (this.canView(`/settings/integrations/${integration}`)) {
        this.integrationsAvailable = true;
        break;
      }
    }
  }

  mounted() {
    const path = this.$route.path;
    for (const name of INTEGRATIONS) {
      if (path.includes(name)) {
        this.activeName = name;
      }
    }
  }
}
export default toNative(Integrations);
</script>

<style lang="scss">
.settings-integrations.content-inner {
  .el-tab-pane .content-inner{
    padding: 0;
  }
  .el-tabs__content {
    overflow-y: auto;
    height: calc(100vh - 118px);
  }
}
</style>



