<template lang="pug" id="categoryBar">
extends ./Base.pug
</template>

<script>
import concat from 'lodash/concat';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import BaseChart from './Base.vue';

export default {
  extends: BaseChart,
  name: 'aiqCategoryBarPerLineChart',
  props: {
    xLabel: {
      type: String,
      default: () => '',
    },
    xLabelPosition: {
      type: String,
      default: 'outer-right',
    },
  },
  methods: {
    getShowLegend() {
      return false;
    },
    getChartType() {
      return 'CategoryBar';
    },
    dataValuesByLine() {
      const { dataset } = this.report;
      if (isEmpty(dataset)) {
        return [];
      }
      return dataset
        .filter(ds => this.lines[ds.metric]);
    },
    xCategories() {
      return this.dataValuesByLine().map(ds => this.lines[ds.metric].categoryLabel);
    },
    yValues() {
      // each line has dataset,
      // compute sum of ys of each datapoint and return
      // total for each line
      return this.dataValuesByLine().map((ds) => (get(ds, 'data_points') ?
        ds.data_points.reduce((acc, val) => acc + get(val, 'ys.0.value'), 0) : 0));
    },
    toolTipConfig() {
      const graph = this;
      // saving to use inside another object
      return {
        format: {
          title(x, id) { return `${graph.xCategories()[id]}` },
          // eslint-disable-next-line no-unused-vars
          name(name, ratio, id, index) { return 'Count' },
        },
      };
    },
    dataConfig() {
      return {
        columns: [
          concat(['data1'], this.yValues()),
        ],
        types: {
          data1: 'bar',
        },
        colors: {
          data1: this.linesColor,
        },
      };
    },
    addxLabel(config) {
      if (!isEmpty(this.xLabel)) {
        Object.assign(config, { label: { text: this.xLabel,
          position: this.xLabelPosition } });
      }
      return config;
    },
    xAxisConfig() {
      return this.addxLabel({
        type: 'category',
        categories: this.xCategories(),
        height: 35,
      });
    },
  },
};
</script>

<style lang="scss">
</style>
