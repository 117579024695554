import get from 'lodash/get';
import { dialogStates } from '../constants/aiStates';

const DIALOG_STATE_INIT = 1;
const DIALOG_STATE_ASK_ALTERNATIVE = 4;
const DIALOG_STATE_SUCCESS = 5;
const DIALOG_STATE_FAILURE = 6;
const WORKFLOW_STATUS_SUCCESS = 1;
const WORKFLOW_STATUS_MAIN = 2;
const WORKFLOW_STATUS_END = 3;
const WORKFLOW_STATUS_FAILURE = 4;
/** For now cleans workflows and dialogs based off of state/status.
 *  Note: May cause problems if running dialogs in dialogs or
 *  workflows inside workflows. Entities are cleared upon end of workflow
 *  or when both workflows and dialogs are inactive
 */
export const cleanAiState = aiState => {
  const newAiState = { ...aiState };
  const dialogState = newAiState.dialog_state;

  function cleanDialogs() {
    const { FAILURE, SUCCESS } = dialogStates;
    if (!dialogState
        || dialogState === FAILURE
        || dialogState === SUCCESS) {
      newAiState.dialog_id = null;
      newAiState.dialog_state = null;
    }
  }

  cleanDialogs();

  return newAiState;
};

export const addMessageItem = ({
  context,
  senderType,
  message,
  messageType = 'legacy',
}) => {
  const messageObject = {
    sender_type: senderType,
    sender: {
      id: '',
      profile: {
        first_name: '',
        last_name: '',
      },
    },
  };

  if (messageType === 'rich_text') {
    messageObject.payload = {
      rich_text: message,
      message_type: messageType,
    };
  } else if (messageType === 'reply') {
    messageObject.payload = message;
  } else {
    messageObject.message = message;
  }

  context.commit('addItem', messageObject);
};

export const displayDialogState = ({ context, dialogName, dialogState }) => {
  if (dialogState === DIALOG_STATE_SUCCESS || dialogState === DIALOG_STATE_FAILURE) {
    const message = `Dialog ${dialogName || ''} completed`;
    addMessageItem({
      context, senderType: 'debug', message,
    });
  } else if (dialogState >= DIALOG_STATE_INIT && dialogState <= DIALOG_STATE_ASK_ALTERNATIVE) {
    const message = `Dialog ${dialogName || ''} is running`;
    addMessageItem({
      context, senderType: 'debug', message,
    });
  }
};

export const displayWorkflowStatus = ({
  context,
  dialogState,
  workflowName,
  workflowStatus,
}) => {
  if ([
    WORKFLOW_STATUS_SUCCESS,
    WORKFLOW_STATUS_END,
    WORKFLOW_STATUS_FAILURE,
  ].includes(workflowStatus)) {
    const message = `Workflow ${workflowName || ''} completed`;
    addMessageItem({
      context, senderType: 'debug', message,
    });
  } else if (workflowStatus === WORKFLOW_STATUS_MAIN
             && (!dialogState
              || (dialogState < DIALOG_STATE_INIT
               && dialogState > DIALOG_STATE_ASK_ALTERNATIVE))) {
    // Only display a workflow as running if there isn't a dialog being run.
    const message = `Workflow ${workflowName || ''} is running`;
    addMessageItem({
      context, senderType: 'debug', message,
    });
  }
};

export const getLastMessageItem = items => {
  for (let i = items.length - 1; i >= 0; i -= 1) {
    if (items[i].sender_type === 'agent') {
      return items[i];
    }
  }

  return null;
};

export const getRepliesItem = items => items.find(item => {
  const messageType = get(item, 'payload.message_type');
  return ['carousel', 'replies'].includes(messageType);
});
