<template lang="pug">
div
  aiq-row.row(v-for="dataItem in summaryData" :key="dataItem.label" type="flex")
    aiq-col.center(v-if="!dataItem.value" :sm="24")
        label {{dataItem.label}}
    template(v-else)
      aiq-col(:sm="12")
        label {{dataItem.label}}
      aiq-col(:sm="12")
        ul(v-if="dataItem.type === 'list'")
          li.value(v-for="agent in dataItem.value" :key="agent") {{agent}}
        message(v-else-if="dataItem.type === 'message'"
                class="tooltip-body-convo-message"
                :message="dataItem.value")
        span.value(v-else) {{dataItem.value}}
</template>

<script>
import GetGlobalSource from '@/libs/mixins/GetGlobalSource';

export default {
  name: 'TooltipBody',
  props: {
    summaryData: {
      type: Array,
      default: [],
    },
  },
  // Need to add GetGlobalSource mixin because it's a dependency in message in aiq-components
  mixins: [GetGlobalSource],
};
</script>

<style lang="scss" scoped>
:deep(.tooltip-body-convo-message.message-list_item > .message-replies-wrapper > .el-button) {
  white-space: break-spaces;
}
</style>
