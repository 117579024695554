<template lang="pug">
.profile-editor
  aiq-dialog(:title="title",
             :model-value="visible"
             :show-close="false"
             @closed="")
    slot(name="content")
      entity-dialog(v-if="visible"
                    v-model:domain-name="tempApiInfo.domain_name"
                    v-model:endpoint-name="tempApiInfo.endpoint_name"
                    v-model:request-pairs="tempApiInfo.requestPairs"
                    v-model:response-pairs="tempApiInfo.responsePairs"
                    addable-for-request-based-on="left"
                    addable-for-response-based-on="right"
                    rawbox-for-request-on="right"
                    rawbox-for-response-on="left"
                    :use-entity-icon="false"
                    :multiple-responses="false"
                    :disableCheckBoxes="true")


    template(v-slot:footer)
      aiq-button(@click="$emit('close')") Cancel
      aiq-button(:disabled="!isUpdated"
                 type="primary"
                 @click="onSave") Save

</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { EntityDialog } from '@/components';

export default {
  name: 'SyncApiDialog',
  components: {
    EntityDialog,
  },
  computed: {
    isUpdated() {
      return !isEqual(this.apiInfo, this.tempApiInfo);
    },
  },
  props: {
    title: {
      type: String,
      default: 'Api Editor',
    },
    mapping: {
      type: Object,
    },
    apiInfo: {
      type: Object,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['save', 'close'],
  watch: {
    visible(newVal) {
      if (newVal) {
        this.tempApiInfo = cloneDeep(this.apiInfo);
      }
    },
  },
  data() {
    return {
      tempApiInfo: cloneDeep(this.apiInfo),
    };
  },
  methods: {
    onSave() {
      this.$emit('save', this.mapping, this.tempApiInfo);
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-editor {
  h2 {
    margin: 10px;
  }
}

.added-field {
  display: flex;
  justify-content: space-between;
  .field-item {
    width: 24%;
  }

  margin-top: 10px;
}

</style>
