import get from 'lodash/get';
import { IMAGE_ACL_TYPES } from '@/constants';
import axios from 'axios';
import { getUrl } from '@/config/api.routes';

export default {
  provide() {
    return {
      getSignedSource: this.getSignedSource,
      getFileUrl: this.getFileUrl,
    };
  },

  methods: {
    async getSignedSource(bucket, key) {
      // Need to fetch data directly because this function is passed to aiq-components
      // and it shouldn't depend on the store dependency
      const data = await axios.post(`${getUrl('files.base')}/presigned`, { bucket, key });
      return get(data, 'data.url', '');
    },
    async getFileUrl(filePayload) {
      const acl = get(filePayload, 'acl', IMAGE_ACL_TYPES.PUBLIC);

      let url = null;
      if (filePayload) {
        if (acl === IMAGE_ACL_TYPES.PRIVATE) {
          url = await this.getSignedSource(get(filePayload, 'bucket'), get(filePayload, 'key'));
        } else {
          url = get(filePayload, 'url');
        }
      }
      return url;
    },
  },
};
