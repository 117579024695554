/**
 * Contains integration instances
 */

import merge from 'lodash/merge';
import pick from 'lodash/pick';
import * as log from 'loglevel';
import * as Sentry from '@sentry/vue';

export default {
  namespaced: true,
  state: {
    sentry: {
      initialized: false,
      instance: null,
      config: {
        dsn: process.env.SENTRY_URL,
        environment: process.env.ENVIRONMENT,
        // Send only 10% of errors
        sampleTrace: 0.1, // https://docs.sentry.io/platforms/javascript/guides/vue/
      },
    },
  },
  mutations: {
    INIT_SENTRY(state, instance, config = {}) {
      config = merge(state.sentry.config, config);
      log.debug('sentry config:', config);

      if (config.dsn) {
        /* TODO: VUE3 Check if this is working */
        state.sentry.instance = Sentry.init({
          app: instance,
          dsn: config.dsn,
          environment: process.env.NODE_ENV === 'production' ? config.environment : 'localhost',
          logErrors: true,
        });
        state.sentry.initialized = true;
      }
    },
  },
  actions: {
    init({ state, commit }, instance) {
      log.debug('Action(integration): init');
      if (state.sentry.initialized) {
        // should initialized only once.
        return;
      }

      // integration pieces go here.
      commit('INIT_SENTRY', instance);
    },
    setUserContext({ state }, agent) {
      log.debug('Set user to Sentry with', agent);
      if (state.sentry.initialized) {
        Sentry.setUser(pick(agent, ['id', 'email']));
      }
    },
    captureError({ state }, { error, data }) {
      if (state.sentry.initialized) {
        if ((typeof error) === 'string') {
          error = new Error(error);
        }

        if (!(error instanceof Error)) {
          throw new Error(`error should be either string or Error but ${typeof error}`);
        }
        log.debug('Sending sentry error', error, data);

        // TODO: context is not getting attached.  This functionality
        //       may require sentry to be above 6.0.
        // https://docs.sentry.io/platforms/javascript/guides/vue/enriching-events/context/
        Sentry.captureException(error, { contexts: data });
      }
    },
    captureMesssage({ state }, message) {
      if (state.sentry.initialized) {
        Sentry.captureMessage(message);
      }
    },
  },
};
