import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import axios from 'axios';
import { CRUDStoreBuilder } from '../StoreBuilder';
import { getUrl } from '../../config/api.routes';
import { makeActionsToUnits, fillEmptyAction } from '@/libs';
import {
  convertDialogsForUI,
  convertRestDialogFromUI,
  makeActionsToUnitsForDialog,
  fillAddedFields,
} from '@/libs/dialogHelpers';
import { DIALOG_TYPES } from '@/constants/dialogConstants';

const dialogCrud = new CRUDStoreBuilder(
  'aimanager.dialogs',
  'dialog',
  [],
  'data.data',
).createStore();

const dialogDelete = dialogCrud.mutations.DELETE_DIALOG_SUCCESS;

dialogCrud.mutations.DELETE_DIALOG_SUCCESS = (state, id) => {
  dialogDelete(state, id);
  state.selected = state.dialogs[0] || null;
};

export default merge(dialogCrud, {
  state: {
    entityOperatorsByValueType: {},
    // Be sure to update selected as needed if updated dialogs are fetched
    selected: null,
    selectedIsPristine: true,
  },

  getters: {
    selected: state => state.selected,
    // Filter out condition type dialogs for now
    list: state => {
      const filtered = state
        .dialogs.filter(d => d.payload.dialog_type !== 'condition_type');
      return convertDialogsForUI(filtered);
    },
    valueTypeList: state => Object.keys(state.entityOperatorsByValueType),
  },

  mutations: {
    SELECT_DIALOG(state, item) {
      fillAddedFields(item);
      fillEmptyAction(state.dialogs, item);
      // todo - Really need to get rid of this extra selected copy that we need to sync
      state.selected = convertDialogsForUI([item])[0];
    },
    SET_ENTITY_OPERATORS(state, operators) {
      state.entityOperatorsByValueType = operators;
    },
    SET_IS_SELECTED_PRISTINE(state, isPristine) {
      state.selectedIsPristine = isPristine;
    },
  },

  actions: {
    getEntityOperators({ commit }) {
      return axios.get(getUrl('aimanager.operators')).then(res => {
        commit('SET_ENTITY_OPERATORS', res.data);
      });
    },
    selectDialog({ commit }, id) {
      return axios.get(getUrl('aimanager.dialogs', id)).then(data => {
        commit('SELECT_DIALOG', data.data);
        return data;
      });
    },
    updateDialog({ commit }, dialogFromState) {
      const dialog = cloneDeep(dialogFromState);
      const dialogType = dialog.payload.dialog_type;
      const params = dialog.payload[dialogType];

      if (dialogType === DIALOG_TYPES.TEXT) {
        makeActionsToUnitsForDialog(params);
      } else if (dialogType === DIALOG_TYPES.REST) {
        convertRestDialogFromUI(dialog);
        makeActionsToUnitsForDialog(params);
      } else {
        dialog.payload.list_type.response_values.forEach(values => {
          makeActionsToUnits(values.actions);
        });
      }

      return axios.put(getUrl('aimanager.dialogs', dialog.id), dialog).then(data => {
        commit('PUT_DIALOG_SUCCESS', data.data);
        commit('SELECT_DIALOG', data.data);
        return data;
      });
    },
    copyDialog({ commit }, originalDialog) {
      return axios.post(getUrl('aimanager.dialogs', `${originalDialog.id}/copy`))
        .then(data => {
          commit('POST_DIALOG_SUCCESS', data.data);
          return data.data;
        });
    },
  },
});
