import axios from 'axios';
import merge from 'lodash/merge';

import { CRUDStoreBuilder } from '../../StoreBuilder';
import { getUrl } from '../../../config/api.routes';

const entityCRUD = new CRUDStoreBuilder(
  'aimanager.entities',
  'entity',
  [],
  'data.data',
  true,
).createStore();

entityCRUD.actions.updateEntity = ({ commit }, { name, item }) => axios.put(getUrl('aimanager.entities', name), item).then(data => {
  commit('PUT_ENTITY_SUCCESS', data.data);

  return data;
});

entityCRUD.actions.createEntity = ({ commit }, params) => axios.post(getUrl('aimanager.entities'), params)
  .then(data => {
    const { id, ...payload } = data.data;
    commit('POST_ENTITY_SUCCESS', payload);
    return data;
  });

entityCRUD.mutations.PUT_ENTITY_SUCCESS = (state, item) => {
  state.entities = state.entities.map(entity => (entity.name === item.name ? item : entity));
};

entityCRUD.mutations.POST_ENTITY_SUCCESS = (state, item) => {
  state.entities.unshift(item);
};

export default merge(entityCRUD, {
  namespaced: true,

  getters: {
    names: state => state.entities.map(entity => entity.name),

    // Preconfigured entities from search filter
    preconfiguredEntities: (state, getters) => term => getters
      .searchFilteredEntities(term)
      .filter(entity => entity.type.startsWith('preconfigured')),

    // Non Preconfigured entities
    configuredEntities: (state, getters) => term => getters
      .searchFilteredEntities(term)
      .filter(entity => !entity.type.startsWith('preconfigured')),

    searchFilteredEntities: state => term => state.entities
      .filter(entity => {
        const { name, description, example } = entity;
        const toSearch = [name, description, example].join(' ');

        return term ? toSearch.trim().includes(term) : true;
      }).sort((a, b) => {
        const A = a.name.toUpperCase();
        const B = b.name.toUpperCase();
        if (A === B) { return 0 }
        return A < B ? -1 : 1;
      }),

    selectionList: state => state.entities.filter(entity => (entity.type !== 'customer' && entity.type !== 'product')
          || entity.name === 'product').sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    }),
  },

  mutations: {
  },

  actions: {
  },
});
