<template lang="pug">
transition(name="slide-bottom")
  .suggestions-container(v-if="opened")
    .suggestions-header
      .iq-ico-suggested-response-grey
      | Suggested Responses
    .suggestions-list.scroll
      template(v-for="suggestion in suggestions")
        .suggestions-item(v-if="suggestion.value && suggestion.value.trim()",
          :key="suggestion.id",
          :class="{'active': suggestion.id === selected }",
          @click="select(suggestion)")
            .iq-ico-suggested-item
            | {{ suggestion.value }}
</template>

<script>
const SUGGESTION_COUNT = 3;

export default {
  name: 'suggestionsPopover',
  props: {
    list: {
      type: Array,
      required: true,
    },
    selected: {
      type: Number,
    },
    opened: {
      type: Boolean,
      default: () => true,
    },
  },
  emits: ['select'],
  computed: {
    suggestions() {
      return this.list.slice(0, SUGGESTION_COUNT);
    },
  },
  methods: {
    select(item) {
      this.$emit('select', item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../styles/aiq-variables.scss";
.suggestions-container {
  position: relative;
  bottom: 0;
  width: calc(100% - 32px);
  padding: 4px;
  margin: 0 auto;
  border-radius: 4px 4px 0 0;
  background-color: $aig-bgc-suggested-response-box;
}
.suggestions-header {
  display: flex;
  align-items: center;
  padding: 6px 0;
  color: $aig-text-suggested-response-box;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;

  [class^="iq-ico"] {
    margin-left: 8px;
    margin-right: 10px;
  }
}
.suggestions-list {
  max-height: 159px;
  padding: 0;
  margin: 0;
  overflow-y: auto;

  &.scroll {
    &::-webkit-scrollbar-track {
      background-color: rgba(85, 85, 85, .1);
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0,0,0,.2);
    }
  }
}
.suggestions-item {
  position: relative;
  width: 100%;
  padding: 10px 10px 10px 30px;
  margin: 0 0 4px;
  color: $aig-text-suggested-response-box;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid #fff;
  background-color: #fff;
  cursor: pointer;
  box-sizing: border-box;
  [class^="iq-ico"] {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    border-color: $aig-bgc-suggested-response-item-active;
    .iq-ico-suggested-item {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='15px' height='15px'%3E%3Cpath fill-rule='evenodd' fill='%235A88DE' d='M7.500,15.000 C3.365,15.000 0.000,11.636 0.000,7.500 C0.000,3.364 3.365,0.000 7.500,0.000 C11.636,0.000 15.000,3.364 15.000,7.500 C15.000,11.636 11.636,15.000 7.500,15.000 ZM7.500,1.154 C4.001,1.154 1.154,4.001 1.154,7.500 C1.154,10.999 4.001,13.846 7.500,13.846 C10.999,13.846 13.846,10.999 13.846,7.500 C13.846,4.001 10.999,1.154 7.500,1.154 ZM6.754,10.216 C6.642,10.328 6.494,10.385 6.346,10.385 C6.198,10.385 6.051,10.328 5.938,10.216 L3.631,7.908 C3.405,7.683 3.405,7.318 3.631,7.092 C3.856,6.867 4.221,6.867 4.446,7.092 L6.346,8.992 L10.554,4.784 C10.779,4.559 11.144,4.559 11.370,4.785 C11.595,5.010 11.595,5.375 11.370,5.600 L6.754,10.216 Z'%3E%3C/path%3E%3C/svg%3E");
      }
    }
  }
  &.active {
    color: #fff;
    border-color: $aig-bgc-suggested-response-item-active;
    background-color: $aig-bgc-suggested-response-item-active;
    .iq-ico-suggested-item {

      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='15px' height='15px'%3E%3Cpath fill-rule='evenodd' fill='rgb(255, 255, 255)' d='M7.500,15.000 C3.365,15.000 0.000,11.636 0.000,7.500 C0.000,3.364 3.365,0.000 7.500,0.000 C11.636,0.000 15.000,3.364 15.000,7.500 C15.000,11.636 11.636,15.000 7.500,15.000 ZM7.500,1.154 C4.001,1.154 1.154,4.001 1.154,7.500 C1.154,10.999 4.001,13.846 7.500,13.846 C10.999,13.846 13.846,10.999 13.846,7.500 C13.846,4.001 10.999,1.154 7.500,1.154 ZM6.754,10.216 C6.642,10.328 6.494,10.385 6.346,10.385 C6.198,10.385 6.051,10.328 5.938,10.216 L3.631,7.908 C3.405,7.683 3.405,7.318 3.631,7.092 C3.856,6.867 4.221,6.867 4.446,7.092 L6.346,8.992 L10.554,4.784 C10.779,4.559 11.144,4.559 11.370,4.785 C11.595,5.010 11.595,5.375 11.370,5.600 L6.754,10.216 Z'%3E%3C/path%3E%3C/svg%3E");
      }
    }
  }
}
.slide-bottom-enter-active, .slide-bottom-leave-active {
  transition: bottom .25s;
}
.slide-bottom-enter, .slide-bottom-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  bottom: -500px;
}
</style>
