const selectionsChartConfig = {
  name: 'selection_distribution',
  title: 'selection distribution',
  helpText: 'Displays percentage of tag selections for a question',
  exportType: 'tabular_chart_data',
  graphParams: {
    dataNameField: 'name',
    dataValueField: 'value',
    colColors: {},
  },
  showLegend: false,
};

export default selectionsChartConfig;
