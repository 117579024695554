import CustomersDashboard from './CustomersDashboard.vue';

export default {
  path: '/customers',
  name: 'Customers',
  component: CustomersDashboard,
  props: true,
  meta: {
    auth: true,
    basePath: '/customers',
  },
};