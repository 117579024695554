import capitalize from './capitalize';
import duration from './duration';
import highlight from './highlight';
import wordset from './wordset';
import url from './url';
import toUpperFirst from './util';
import formatTime from './formatTime';

// Todo (Gabe) - move rest of filters into this file
const mask = (val) => (val ? '*'.repeat(val.length) : '');

export default (app) => {
  app.config.globalProperties.$filters = {
    ...app.config.globalProperties.$filters,
    duration,
    wordset,
    highlight,
    url,
    toUpperFirst,
    capitalize,
    mask,
    formatTime,
  };
};
