<template lang="pug">
  .quick-response
    h3.quick-response_title

    search-and-create-box(:new-box-placeholder="$t('conversation_tab.side_panel.quick_reponses_accordion.new_quick_response')"
                          :search-box-placeholder="$t('conversation_tab.side_panel.quick_reponses_accordion.search') "
                          :active-toggle-name="$t('conversation_tab.side_panel.quick_reponses_accordion.pin')"
                          :inactive-toggle-name="$t('conversation_tab.side_panel.quick_reponses_accordion.unpin')"
                          :add-tooltip="$t('conversation_tab.side_panel.quick_reponses_accordion.add_quick_response_tooltip')"
                          create-box-type="textarea"
                          toggle-icon="thumbtack"
                          :advanced="true"
                          :rows="3"
                          @search="onSearchChange"
                          @create="createResponse")

    aiq-collapse.quick-response(v-model="selectedCollapse")
      aiq-collapse-item(v-if="canView('/conversations/right_panel/quick_responses/mine')"
                        name="mine"
                        :title="`${$t('conversation_tab.side_panel.quick_reponses_accordion.mine')} (${myQuickResponsesPagination.rowCount})`")
        .quick-response_list
          quick-response-item(v-for="item in myQuickResponses",
                            :response="item",
                            :key="item.index",
                            @editMode="editModeChanged",
                            @select="selectResponse"
                            @pin="pinResponse"
                            @unpin="unpinResponse"
                            @update="updateResponse",
                            @remove="deleteResponse")
        infinite-loading(ref="myInfiniteLoading"
                        v-if="doMyInfiniteScroll"
                        class="text-center"
                        :distance="10"
                        @infinite="onMyScrollLoad")
      aiq-collapse-item(v-if="canView('/conversations/right_panel/quick_responses/team')"
                        name="team"
                        :title="`${$t('conversation_tab.side_panel.quick_reponses_accordion.team')} (${teamQuickResponsesPagination.rowCount})`")
        .quick-response_list
          aiq-select(v-model="selectedTeam" size="small" value-key="id" name="teams" :placeholder="$t('conversation_tab.side_panel.quick_reponses_accordion.select_team')" @change="loadTeamQuickResponses()" clearable)
            aiq-option(v-for="item in agentProfile.teams" :key="item.id" :label="item.name" :value="item")
          quick-response-item(v-for="item in teamQuickResponses",
                            :response="item",
                            :key="item.index"
                            :read-only="true"
                            @add-to-mine="addToMineResponses"
                            @select="selectResponse")
        infinite-loading(ref="teamInfiniteLoading"
                        v-if="doTeamInfiniteScroll"
                        class="text-center"
                        :distance="10"
                        @infinite="onTeamScrollLoad")

</template>

<script>
import { mapState } from 'vuex';
import get from 'lodash/get';
import QuickResponseItem from './QuickResponseItem.vue';
import SearchAndCreateBox from '@/components/SearchAndCreateBox/SearchAndCreateBox.vue';
import { QUICK_RESPONSE } from '@/constants/analytics';
import { PAGINATION_DEFAULT } from '@/constants/pagination';

const SEARCH_PAGE_SIZE = 10;

export default {
  components: {
    QuickResponseItem,
    SearchAndCreateBox,
  },

  emits: [
    'selectResponse',
  ],

  async mounted() {
    await Promise.all([this.loadMyQuickResponses(), this.loadTeamQuickResponses()]);
  },

  data() {
    return {
      editingId: 0,
      searchQuery: '',
      selectedTeam: null,
      selectedCollapse: ['mine'],
      myQuickResponsesPagination: { ...PAGINATION_DEFAULT },
      teamQuickResponsesPagination: { ...PAGINATION_DEFAULT },
    };
  },

  computed: {
    ...mapState({
      agentProfile: state => state.agent.profile,
      myQuickResponses: state => state.conversations.quickResponses,
      teamQuickResponses: state => state.quickresponses.quickResponses,
    }),
    doMyInfiniteScroll() {
      return this.myQuickResponses.length < this.myQuickResponsesPagination.rowCount;
    },
    doTeamInfiniteScroll() {
      return this.teamQuickResponses.length < this.teamQuickResponsesPagination.rowCount;
    },
  },

  methods: {
    deleteConfirm() {
      return new Promise(resolve => {
        this.$aiq.confirm(
          this.$t('conversation_tab.side_panel.quick_reponses_accordion.delete_confirm.title'),
          this.$t('conversation_tab.side_panel.quick_reponses_accordion.delete_confirm.message'), {
            cancelButtonText: this.$t('conversation_tab.side_panel.quick_reponses_accordion.delete_confirm.cancel_button'),
            confirmButtonText: this.$t('conversation_tab.side_panel.quick_reponses_accordion.delete_confirm.confirm_button'),
          },
        )
          .then(() => resolve(true), () => resolve(false));
      });
    },

    selectResponse(value, id) {
      this.$store.dispatch(
        'notifications/emitEventToSocket',
        { name: QUICK_RESPONSE.ATTACHED,
          payload: { agent: this.$store.getters['agent/profile'] },
        },
      );
      this.editingId !== id && this.$emit('selectResponse', value);
    },

    editModeChanged([id, value]) {
      this.editingId = value ? id : -1;
    },

    async onSearchChange(query) {
      try {
        this.selectedCollapse = ['mine', 'team'];
        this.searchQuery = query;
        await Promise.all([this.loadMyQuickResponses(), this.loadTeamQuickResponses()]);
      } catch (ex) {
        this.$aiq.notify.error(this.$t('conversation_tab.side_panel.quick_reponses_accordion.unable_search'));
      }
    },

    async onMyScrollLoad($state) {
      await this.loadMyQuickResponses(this.myQuickResponses.length);
      if (this.myQuickResponses.length >= this.myQuickResponsesPagination.rowCount) {
        $state.complete();
        return;
      }
      $state.loaded();
    },

    async onTeamScrollLoad($state) {
      await this.loadTeamQuickResponses(this.teamQuickResponses.length);
      if (this.teamQuickResponses.length >= this.teamQuickResponsesPagination.rowCount) {
        $state.complete();
        return;
      }
      $state.loaded();
    },

    async loadMyQuickResponses(offset) {
      const params = {
        offset,
        limit: SEARCH_PAGE_SIZE,
        query: this.searchQuery,
      };
      const response = await this.$store.dispatch('conversations/getQuickResponses', params);
      this.myQuickResponsesPagination = get(response, 'data.pagination', PAGINATION_DEFAULT);
      return response;
    },

    async loadTeamQuickResponses(offset) {
      const params = {
        offset,
        limit: SEARCH_PAGE_SIZE,
        usage_types: ['all', 'teams'],
        team_id: get(this.selectedTeam, 'id'),
        agent_id: this.agentProfile.id,
        query: this.searchQuery,
      };
      const response = await this.$store.dispatch('quickresponses/getQuickresponsesList', [
        params,
        get({ offset }, 'offset', 0) === 0,
      ]);
      this.teamQuickResponsesPagination = get(response, 'data.pagination', PAGINATION_DEFAULT);
      return response;
    },

    async deleteResponse(id) {
      try {
        this.editingId = id;
        const response = await this.deleteConfirm();
        if (response) {
          await this.$store.dispatch('conversations/deleteQuickResponse', id);
          await this.loadMyQuickResponses();
          this.$aiq.notify.success(this.$t('conversation_tab.side_panel.quick_reponses_accordion.deleted_success'));
        }
      } catch (ex) {
        this.$aiq.notify.error(this.$t('conversation_tab.side_panel.quick_reponses_accordion.unable_delete'));
      }
    },

    async updateResponse(id, content) {
      try {
        await this.$store.dispatch('conversations/updateQuickResponse', [id, content]);
        await this.loadMyQuickResponses();
        this.$aiq.notify.success(this.$t('conversation_tab.side_panel.quick_reponses_accordion.updated_success'));
      } catch (ex) {
        this.$aiq.notify.error(this.$t('conversation_tab.side_panel.quick_reponses_accordion.unable_update'));
      }
    },

    async createResponse({ name, toggle }) {
      try {
        await this.$store.dispatch('conversations/createQuickResponse', { content: name, is_pinned: toggle });
        await this.loadMyQuickResponses();
        this.$aiq.notify.success(this.$t('conversation_tab.side_panel.quick_reponses_accordion.added_success'));
      } catch (ex) {
        this.$aiq.notify.error(this.$t('conversation_tab.side_panel.quick_reponses_accordion.unable_add'));
      }
    },

    async addToMineResponses({ title, content, is_pinned }) {
      try {
        const result = await this.$store.dispatch('conversations/createQuickResponse', { title, content, is_pinned });
        if (result) {
          await this.loadMyQuickResponses();
          this.$aiq.notify.success(this.$t('conversation_tab.side_panel.quick_reponses_accordion.added_to_mine_success'));
        } else {
          this.$aiq.notify.error(this.$t('conversation_tab.side_panel.quick_reponses_accordion.already_added'));
        }
      } catch (ex) {
        this.$aiq.notify.error(this.$t('conversation_tab.side_panel.quick_reponses_accordion.unable_add_to_mine'));
      }
    },

    async pinResponse(id) {
      try {
        await this.$store.dispatch('conversations/pinQuickResponse', id);
        await this.loadMyQuickResponses();
        this.$aiq.notify.success(this.$t('conversation_tab.side_panel.quick_reponses_accordion.pinned_success'));
      } catch (ex) {
        this.$aiq.notify.error(this.$t('conversation_tab.side_panel.quick_reponses_accordion.unable_pin'));
      }
    },

    async unpinResponse(id) {
      try {
        await this.$store.dispatch('conversations/unpinQuickResponse', id);
        await this.loadMyQuickResponses();
        this.$aiq.notify.success(this.$t('conversation_tab.side_panel.quick_reponses_accordion.unpinned_success'));
      } catch (ex) {
        this.$aiq.notify.error(this.$t('conversation_tab.side_panel.quick_reponses_accordion.unable_unpin'));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .quick-response_list > .el-select {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .quick-response_search-container {
    display: flex;
    flex-wrap: wrap;
  }
  .button-add-new-item {
    flex: 0 0 0%;
    padding: 0 6px;
    color: #8492A6;
    font-size: 14px;
    border: 0;
    background-color: transparent;
  }
  .quick-response_search {
    flex: 1 0 0%;
  }
  .new-quick-response {
    width: 100%;
    margin-top: 8px;
  }
  .el-collapse.quick-response {
    margin-top: 10px;
    border: unset;
    :deep {
      .el-collapse-item__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 5px;
        background-color: unset;
        font-weight: 700;
      }
      .el-collapse-item__wrap {
        border: unset;
        margin-bottom: 10px;
        .el-collapse-item__content {
          max-height: 420px;
          overflow-y: auto;
          height: inherit;
          padding: 0px 5px 0px 5px;
        }
      }

    }
  }
  .text-center {
    text-align: center;
    margin-top: 5px;
  }
</style>
