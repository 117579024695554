<template lang="pug">
.agent-status
  aiq-status-indicator(:status="available" :show-all-states="true" :placement="placement")
  span.status-label(v-if="showLabel") {{ label }}
</template>

<script>
import { AGENT_STATUS, OUT_OF_OFFICE_STATUS } from '@/constants';

export default {
  name: 'agentStatus',
  props: {
    available: {
      type: String,
      default: AGENT_STATUS.Away.field,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'bottom',
    },
  },
  computed: {
    status() {
      return Object.values({ ...AGENT_STATUS, 'Away/Out Of Office': OUT_OF_OFFICE_STATUS })
        .find(status => status.field === this.available);
    },
    label() {
      return this.status.label;
    },
  },
};
</script>

<style lang="scss" scoped>
.agent-status {
  display: inline;
  padding: 0px;
  margin: 0px;
}

.status-label {
  padding: 5px;
  display: unset !important;
}
</style>
