import SidebarAndPanesWithRouting from '@/components/layouts/SidebarAndPanesWithRouting.vue';
import featureFlags from '@/store/modules/featureFlags';
import get from 'lodash/get';
import Selections from './children/Selections.vue';
import Tags from './children/Tags/Tags.vue';
import Topics from './children/Topics/Topics.vue';

const showSelections = get(featureFlags, 'state.FEATURE_FLAGS.METRICS_SELECTIONS_PAGE', true);

const rootRoute = {
  path: 'insights',
  name: 'Insights',
  component: SidebarAndPanesWithRouting,
  meta: {
    auth: true,
  },
  props: {
    baseResource: '/metrics/insights',
  },
};

const commonChildMeta = {
  auth: true,
  resource: rootRoute.path,
  parentName: rootRoute.name,
};

const childRoutes = [
  ...(showSelections ? [{
    path: 'selections',
    name: 'Metrics.Insights.Selections',
    component: Selections,
    meta: {
      label: 'Selections',
      ...commonChildMeta,
    },
  }] : []),
  {
    path: 'tags',
    name: 'Metrics.Insights.Tags',
    component: Tags,
    meta: {
      label: 'Tags',
      ...commonChildMeta,
    },
  },
  {
    path: 'topics',
    name: 'Metrics.Insights.Topics',
    component: Topics,
    meta: {
      label: 'Topics',
      ...commonChildMeta,
    },
  },
];

rootRoute.props.children = childRoutes;

export default {
  ...rootRoute,
  children: childRoutes,
};
