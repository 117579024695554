import intersection from 'lodash/intersection';
import Auth0Lock from 'auth0-lock';
import * as log from 'loglevel';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/debounceTime';
import { requestNotificationPermission, sanitizeQueryParams } from '@/libs';
import { defaultRoute, topRoutes } from '@/libs/routerHelper';
import { mapState } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { STATUS_CODES } from '@/constants';
import isNil from 'lodash/isNil';

const path = require('path');

const DEBOUNCE_TIME_MS = 1000;

// Workaround for Auth0 Action custom error message, migrated from deprecated rules
// This is only an issue for embedded lock login, not new universal login
// Determines action trigger based on error description, code is shared so unreliable
const WHITE_LIST_ERROR = {
  DESC: 'action_ip_allowlist_deny',
  MSG: `Access denied from this IP address
  Please ensure you are connected to the corporate network`,
};

// TODO for use of config.json in the future
// const { config } = this.$store;

export default {
  name: 'loginPage',
  computed: {
    ...mapState({
      loginToken: state => state.agent.token,
      permissions: state => state.agent.permissions,
    }),

    /**
     * Return a list of dashboard permissions without [dashbard] prefix
     */
    dashboardPermissions() {
      return Object.keys(this.permissions)
        .filter(p => p.startsWith('[dashboard]'))
        .map(p => p.replace('[dashboard]', ''));
    },
  },
  methods: {
    async postProcessing() {
      try {
        await this.$store.dispatch('agent/initPermissions');
        await this.$store.dispatch('configs/getEnvConfigs');

        let redirectPath = this.getInitialRoute(this.dashboardPermissions);
        let redirectQuery;

        if (!isNil(get(this.$route, 'redirectedFrom.name'))) {
          const url = get(this.$route, 'redirectedFrom.path');
          const query = get(this.$route, 'redirectedFrom.query');
          // Check if the route is allow or an exceptional route for file rediection
          if (this.canView(url) || url.startsWith('/files/view')) {
            const cleanQueryParams = sanitizeQueryParams(query, url);
            redirectPath = url;
            if (!isEmpty(cleanQueryParams)) {
              redirectQuery = cleanQueryParams;
            }
          }
        }

        log.debug('redirect to ', redirectPath);
        this.$router.push({ path: redirectPath, query: redirectQuery });
        await this.$store.dispatch('agent/startInactivityTimeout');
      } catch (err) {
        log.debug(`Failed to exchange token ${err}. Try to login with auth0.`);
        this.showLogin();
      }
    },

    async showLogin() {
      log.debug('auth: start login flow');

      await this.$store.dispatch('agent/stopInactivityTimeout');
      const connection = new URL(window.location.href).searchParams.get('connection');
      const options = {
        _enableIdPInitiatedLogin: true,
        auth: {
          // redirect must be false to enter popup login modes
          redirect: !!(window.location.hash),
          autoParseHash: true,
          params: {
            scope: 'openid email',
          },
          connection,
          defaultEnterpiseConnection: connection,
          sso: true,
          defaultADUsernameFromEmailPrefix: true,
        },
        rememberLastLogin: true,
        theme: {
          logo: path.resolve(__dirname, 'favicon.svg'),
        },
        languageDictionary: {
          title: 'Agent IQ',
        },
      };
      log.debug(`Auth0 options: ${JSON.stringify(options)}`);
      const auth = new Auth0Lock(
        // TODO for use of config.json in the future
        process.env.AUTH0.clientID,
        process.env.AUTH0.domain,
        options,
      );

      // Need to change error text manually because auth0 does not support custom error message
      auth.on('authorization_error', (error) => {
        log.debug('Auth0 authorization error occured ', error);
        if (error.description === WHITE_LIST_ERROR.DESC) {
          log.debug('The Auth0 IP allowlist action caused the error.');
          document.querySelector('.auth0-global-message.auth0-global-message-error > span > span').innerText = WHITE_LIST_ERROR.MSG;
        }
      });

      auth.on('authenticated', (authResult) => {
        log.debug('auth: login successful');
        auth.getUserInfo(authResult.accessToken, (error, profile) => {
          if (error) {
            return;
          }
          this.$store.dispatch('agent/setCredentials', [authResult.accessToken, authResult.idToken])
            .then(() => {
              log.debug('auth: id token & access token is set');
              const auth0Connection = get(profile, 'identities.0.connection');
              this.$store.commit('agent/SET_CONNECTION', auth0Connection);
              auth.hide();
              requestNotificationPermission()
                .catch(err => log.warn('Notification Request:', err));
              // We ignore the router path AG-5118
              return this.postProcessing();
            }).catch(err => {
              // document it later
              Observable.create(observer => {
                observer.next(auth.hide());
              })
                .debounceTime(DEBOUNCE_TIME_MS)
                .subscribe(() => {
                  err.status === STATUS_CODES.NOT_FOUND && auth.show({
                    flashMessage: {
                      type: 'error',
                      text: 'You have not signed up in the system',
                    },
                  });
                });
            });
        });
      });

      auth.show({
        allowedConnections: connection ? [connection] : undefined,
      });
    },

    getInitialRoute(possibleRoutes) {
      // Return first accessible route in current top routes.
      // top routes contain the list of main routes that are available in the dashboard
      // overrideTopRoutes is used for the widget feature which has only a subset of them
      const currentTopRoutes = isEmpty(this.overrideTopRoutes) ? topRoutes : this.overrideTopRoutes;
      const accessibleRoutes = intersection(currentTopRoutes, possibleRoutes);
      if (accessibleRoutes.length) {
        return accessibleRoutes[0];
      }
      return defaultRoute;
    },
  },
  mounted() {
    if (!process.env.AUTH0 || process.env.NODE_ENV === 'test') {
      return;
    }

    if (this.loginToken) {
      log.debug('auth: skip login flow. we have a token previously used.');
      return this.postProcessing();
    }

    this.showLogin();
  },
  props: {
    overrideTopRoutes: {
      type: Array,
      default: [],
    },
  },
};
