import { createNamespacedHelpers } from 'vuex';
import { Component, Watch, Vue, toNative } from 'vue-facing-decorator';
import set from 'lodash/set';
import upperFirst from 'lodash/upperFirst';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import OAuth from '@/libs/mixins/OAuth';
import ObjectMappingItem from './ObjectMappingItem.vue';
import FieldMappingDialog from './FieldMappingDialog.vue';

const { mapActions, mapState } = createNamespacedHelpers('integrations');

const INFO = 'Configure Salesforce connection.';

const HUNDRED_MS = 100; // eslint-disable-line
const ONE_MINUTE = 60 * 1000; // eslint-disable-line
const REDIRECT_URL = '/settings/integrations/salesforce/oauth2/callback';
const SALESFORCE_OAUTH_URL = 'https://login.salesforce.com/services/oauth2/authorize';
const POPUP_WIDTH = 400; // eslint-disable-line
const POPUP_HEIGHT = 500; // eslint-disable-line
const CRM_NAME = 'salesforce';
const SUPPORTING_OPERATIONS = [
  {
    label: 'Realtime Push',
    value: 'single_push',
  },
  {
    label: 'Batch Job: Pull',
    value: 'batch_pull',
  },
]; // will also added single_pull, batch_push

@Component({
  name: 'SalesforceTab',
  mixins: [OAuth],
  components: {
    ManagementPage,
    ObjectMappingItem,
    FieldMappingDialog,
  },
  computed: {
    ...mapState({
      savedConfig: state => state.crm.salesforce,
      mappings: state => state.objectMappings.models,
      aiqObjects: state => state.aiqObjects.models,
      crmObjects: state => state.crmObjects.models,
    }),
    selectedAiqObject() {
      return this.aiqObjects.find(item => item.id === this.localMapping.aiq_object_id) || null;
    },
    selectedCrmObject() {
      return this.crmObjects.find(item => item.id === this.localMapping.crm_object_id) || null;
    },
    isConfigSame() {
      return isEqual(this.localConfig, this.savedConfig);
    },
    oauth2Queries() {
      const query = {
        response_type: 'code',
        client_id: this.localConfig.config.oauth2.clientId,
        redirect_uri: this.localConfig.config.oauth2.redirectUri,
        scope: 'api refresh_token',
      };
      return query;
    },
    operations() {
      return SUPPORTING_OPERATIONS;
    },
    hostDomain() {
      return `${location.protocol}//${location.host}`; // eslint-disable-line
    },
  },
  methods: {
    ...mapActions([
      'loadCrm',
      'saveCrm',
      'loadAiqObjects',
      'loadCrmObjects',
      'loadObjectMappings',
      'authorizeOAuth2Code',
      'updateObjectMapping',
      'createObjectMapping',
      'deleteObjectMapping',
    ]),
  },
})

class SalesforceTab extends Vue {
  localMapping = {
    crm_name: CRM_NAME,
    aiq_object_id: -1,
    crm_object_id: -1,
    sync_type: SUPPORTING_OPERATIONS[0].value,
    field_mappings: [],
  };

  displayFieldMapping = false;
  isConnecting = false;

  localConfig = {
    name: CRM_NAME,
    connected: false,
    batch_enabled: false,
    realtime_enabled: false,
    config: {
      oauth2: {
        clientId: '',
        clientSecret: '',
        redirectUri: this.hostDomain + REDIRECT_URL,
      },
    },
  };

  created() {
    this.$_info = INFO;
  }

  mounted() {
    this.localConfig = {
      name: CRM_NAME,
      connected: false,
      batch_enabled: false,
      realtime_enabled: false,
      config: {
        oauth2: {
          clientId: '',
          clientSecret: '',
          redirectUri: this.hostDomain + REDIRECT_URL,
        },
      },
    };
    // This component is opened with different route.
    if (this.$route.name === 'SalesforceAuthCallback') {
      const params = window.location.search;
      if (window.opener) {
        const q = queryString.parse(params);
        window.opener.postMessage({ source: CRM_NAME, code: q.code });
        window.close();
      }
      return;
    }
  }

  @Watch('savedConfig')
  syncSavedConfig(savedConfig) {
    this.localConfig = cloneDeep(savedConfig);
    // make sure it always use local dashboard url.
    set(this.localConfig, 'config.oauth2.redirectUri', this.hostDomain + REDIRECT_URL);
  }

  @Watch('aiqObjects')
  updateDefaultAiqObjectSelection(objects) {
    if (objects.length > 0) {
      this.localMapping.aiq_object_id = objects[0].id;
    }
  }

  @Watch('crmObjects')
  updateDefaultCrmObjectSelection(objects) {
    if (objects.length > 0) {
      this.localMapping.crm_object_id = objects[0].id;
    }
  }

  async saveAndConnect() {
    try {
      this.isConnecting = true;
      // save first.
      await this.saveCrm({ name: CRM_NAME, payload: this.localConfig });

      // upon return it is expected to have code url and redirectUri
      const code = await this.openOauth2Popup(
        this.oauth2Url(SALESFORCE_OAUTH_URL, this.oauth2Queries),
        CRM_NAME,
      );

      // Register code to get tokens
      await this.authorizeOAuth2Code({ name: CRM_NAME, code });

      // reload connection status
      this.loadCrm(CRM_NAME);

      this.$aiq.notify.success('Connected.');
    } catch (err) {
      this.$aiq.notify.error(err.message);
    }

    this.isConnecting = false;
  }

  load() {
    Promise.all([
      this.loadCrm(CRM_NAME),
      this.loadAiqObjects(CRM_NAME),
      this.loadCrmObjects(CRM_NAME),
      this.loadObjectMappings(CRM_NAME),
    ]).catch(err => this.$aiq.notify.error(err.message));
  }

  async disconnect() {
    try {
      this.localConfig.connected = false;
      await this.saveCrm({ name: CRM_NAME, payload: this.localConfig });
      this.$aiq.notify.success('Disconnected.');
    } catch (err) {
      this.$aiq.notify.error(err.message);
    }
  }

  async onToggle(name) {
    if (typeof this.localConfig[name] !== 'boolean') {
      return;
    }

    this.localConfig[name] = !this.localConfig[name];
    await this.saveCrm({ name: CRM_NAME, payload: this.localConfig });
  }

  onAddNewFieldMapping(item) {
    this.localMapping.field_mappings.push(item);
  }

  onRemoveFieldMapping({ index }) {
    this.localMapping.field_mappings.splice(index, 1);
  }

  async onAddNewObjectMapping() {
    try {
      if (this.localMapping.id) {
        await this.updateObjectMapping({ name: CRM_NAME, mapping: cloneDeep(this.localMapping) });
      } else {
        await this.createObjectMapping({ name: CRM_NAME, mapping: cloneDeep(this.localMapping) });
      }
      this.$aiq.notify.success('Saved');
      this.closeDialog();
    } catch (err) {
      this.$aiq.notify.error(err.message);
    }
    this.cleanLocalMapping();
  }

  isMappingIndexValid(index) {
    if (index >= 0 && index < this.mappings.length) {
      return true;
    }
    return false;
  }

  onEditObjectMapping(index) {
    if (!this.isMappingIndexValid(index)) {
      return;
    }

    this.localMapping = cloneDeep(this.mappings[index]);
    this.displayFieldMapping = true;
  }

  async onToggleObjectMapping(index) {
    if (!this.isMappingIndexValid(index)) {
      return;
    }

    try {
      const enabled = !this.mappings[index].enabled;
      const mapping = { ...cloneDeep(this.mappings[index]), enabled };
      await this.updateObjectMapping({ name: CRM_NAME, mapping });
      this.$aiq.notify.success('Updated');
    } catch (err) {
      this.$aiq.notify.error(err.message);
    }
  }

  async onRemoveObjectMapping(index) {
    if (!this.isMappingIndexValid(index)) {
      return;
    }

    try {
      await this.deleteObjectMapping({ name: CRM_NAME, mapping: this.mappings[index] });
      this.$aiq.notify.success('Deleted');
    } catch (err) {
      this.$aiq.notify.error(err.message);
    }
  }

  closeDialog() {
    this.displayFieldMapping = false;
    this.cleanLocalMapping();
  }

  cleanLocalMapping() {
    this.localMapping = {
      crm_name: CRM_NAME,
      aiq_object_id: -1,
      crm_object_id: -1,
      sync_type: SUPPORTING_OPERATIONS[0].value,
      field_mappings: [],
    };

    if (this.aiqObjects.length > 0) {
      this.localMapping.aiq_object_id = this.aiqObjects[0].id;
    }

    if (this.crmObjects.length > 0) {
      this.localMapping.crm_object_id = this.crmObjects[0].id;
    }
  }

/* eslint-disable */
  convertCamelCase(text) {
    return upperFirst(text);
  }

}
export default toNative(SalesforceTab);
