<template lang="pug">
  layout-main(:sidebar-items="items")
    router-view(:key="$route.path")
</template>

<script>
import get from 'lodash/get';
import has from 'lodash/has';
import LayoutMain from '@/components/layouts/main.vue';

export default {
  components: {
    LayoutMain,
  },
  props: {
    baseResource: {
      type: String,
    },
    bars: {
      type: Array,
      default: [],
    },
  },
  computed: {
    items() {
      const sidebars = [];
      this.bars.forEach(({ path, name, meta }) => {
        const fullPath = this.buildHref(path);
        if (this.canView(fullPath)) {
          sidebars.push({
            href: fullPath,
            title: has(meta, 'lang_key') ? this.$t(get(meta, 'lang_key'), name) : get(meta, 'label', name),
          });
        }
      });
      return sidebars;
    },
  },
  methods: {
    buildHref(resource = '') {
      return `${this.baseResource}/${resource}`;
    },
  },
};
</script>

<style>
</style>
