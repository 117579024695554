<template lang="pug">
</template>

<script>
import get from 'lodash/get';
import { Component, Vue, toNative } from 'vue-facing-decorator';
import GetGlobalSource from '@/libs/mixins/GetGlobalSource';

@Component({
  mixins: [GetGlobalSource],
  name: 'view-file',
})
class ViewFilePanel extends Vue {
  async mounted() {
    // request presigned request
    const fileKey = get(this.$route, 'params.key');
    if (fileKey) {
      window.location.href = await this.getSignedSource(undefined, fileKey);
    } else {
      // back to dashboard root on exception
      this.$router.push({ path: '/' });
    }
  }
}
export default toNative(ViewFilePanel);
</script>

<style lang="scss" scoped>
</style>
