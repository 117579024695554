import { createNamespacedHelpers } from 'vuex';
import { Component, Watch, Vue, toNative } from 'vue-facing-decorator';
import cloneDeep from 'lodash/cloneDeep';

import isEqual from 'lodash/isEqual';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';

const { mapActions, mapState } = createNamespacedHelpers('configs');

const INFO = `Connect and integrate with Slack. <br><br>For more information and to
  get a webhook, please refer to the Slack docs <a target="_blank"
  href="https://get.slack.help/hc/en-us/articles/115005265063-Incoming-WebHooks-for-Slack">here</a>.`;

@Component({
  name: 'SlackTab',
  components: {
    ManagementPage,
  },
  computed: {
    ...mapState({
      savedForm: state => state.integrations.slack.form,
    }),
    pristine() {
      return isEqual(this.localForm, this.savedForm);
    },
  },
  methods: {
    ...mapActions([
      'loadSlack',
      'updateSlack',
    ]),
  },
})
class SlackTab extends Vue {
  localForm = { webhook: null };

  created() {
    this.$_info = INFO;
  }

  @Watch('savedForm')
  syncSavedForm(savedForm) {
    this.localForm = cloneDeep(savedForm);
  }

  load() {
    this.loadSlack();
  }

  resetForm() {
    this.localForm = cloneDeep(this.savedForm);
  }

  saveForm() {
    return this.updateSlack(this.localForm)
      .then(() => {
        this.$aiq.notify.success('Slack credentials saved.');
      }).catch(() => {
        this.$aiq.notify.error('Error saving Slack credentials.');
      });
  }
}
export default toNative(SlackTab);
