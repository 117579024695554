import {
  alphabetSort,
} from './shared';

import allCommandsBySystemName from './commandDefinitions';

export const processCommands = commandConfigs => {
  const obj = {
    chatCommands: [],
    iqtoolsCommands: [],
    iqtoolsEnabledCommandsLookup: {},
    chatEnabledCommandsLookup: {},
  };

  const processByCommandType = ({ type, config }) => {
    const command = allCommandsBySystemName[config.system_name];
    if (command && !config[type].hidden) {
      obj[`${type}Commands`].push(command);
      obj[`${type}EnabledCommandsLookup`][command[`${type}Name`]] = config.system_name;
    }
  };

  commandConfigs.forEach(config => {
    processByCommandType({ type: 'chat', config });
    processByCommandType({ type: 'iqtools', config });
  });

  obj.chatCommands.sort(alphabetSort.bind(this, 'chatName'));
  obj.iqtoolsCommands.sort(alphabetSort.bind(this, 'iqtoolsName'));
  return obj;
};

const matchCommand = text => {
  text = text.replace(/&nbsp;/, ' ');
  return (text && text.match(/\w+/g)) ? text.match(/\w+/g) : '';
};

export const isCommand = text => !!(text && text.match(/^([/]([\w]+)?)/g));

export const isPublicItem = e => !e.name.startsWith('aiq_');

export const getCommandState = q => {
  if (!isCommand(q)) {
    return {
      // Presence of commandNameQuery indicates whether q is a command query
      commandNameQuery: '',
      param: '',
      commandEndIndex: -1,
    };
  }
  q = q.replace(/&nbsp;/, ' ');
  const [commandNameQuery, ...params] = q.split(' ');
  const param = params.join(' ');
  const commandEndIndex = commandNameQuery.length;

  return {
    commandNameQuery,
    commandEndIndex,
    param,
  };
};

export const isParam = () => true;

export const getCommandName = text => {
  if (!text) {
    return '';
  }

  const parsedText = matchCommand(text);
  return parsedText.length ? parsedText[0] : '';
};

export const getParamsValue = text => {
  const parsedText = matchCommand(text);
  return parsedText.length > 1 ? parsedText.slice(1).join(' ') : '';
};
