<template lang="pug">
transition(name="slide-bottom")
  .commands-container
    .commands-header {{ title }}
    slot
</template>

<script>
export default {
  name: 'popoverContainer',
  props: {
    opened: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => 'Commands',
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "./../../../../../../styles/aiq-variables.scss";

  .commands-container {
    position: absolute;
    left: 16px;
    bottom: 58px;
    width: calc(100% - 110px);
    color: $aiq-text-message-command;
    font-size: 14px;
    border: 1px solid #b6b6b6;
    border-radius: 4px;
    box-shadow: 0 0 2px 0 $aiq-shadow-message-command-container;
    background-color: #fff;
    z-index: 10;
  }
  .commands-header {
    padding: 7px 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 14px;
    border-bottom: 1px solid #ddd;
    border-radius: 5px 5px 0 0;
    background-color: $aiq-bgc-message-command-headr;
  }

  .slide-bottom-enter-active, .slide-bottom-leave-active {
    transition: bottom .25s;
  }
  .slide-bottom-enter, .slide-bottom-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    bottom: -500px;
  }
</style>
