/* eslint-disable no-magic-numbers */
export const median = (array) => {
  const length = array.length;

  if (length === 0) {
    return 0;
  }
  array.sort((a, b) => b - a);
  if (length % 2 === 0) {
    return (array[length / 2] + array[length / 2 - 1]) / 2;
  }
  return array[Math.floor(length / 2)];
};
