import {
  canCreate, canDelete, canView, canEdit,
} from '.';

export default {
  methods: {
    canView(alias) {
      return canView(this.$store.getters['agent/permissions'], alias);
    },

    canCreate(alias) {
      return canCreate(this.$store.getters['agent/permissions'], alias);
    },

    canDelete(alias) {
      return canDelete(this.$store.getters['agent/permissions'], alias);
    },

    canEdit(alias) {
      return canEdit(this.$store.getters['agent/permissions'], alias);
    },
  },
};
