export const CONNECTION_STATE = {
  NOT_INITIATED: 'not_initiated',
  CONNECTING: 'connecting',
  READY: 'connected_and_authenticated',
  WARN: 'connected_and_unauthorized',
  CONNECTED: 'connected',
  RECONNECTING: 'reconnecting',
  DISCONNECTED: 'disconnected',
  ERROR: 'error',
};

export default {
  CONNECTION_STATE,
};
