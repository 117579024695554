import { KEYBOARD_KEYS, SYSTEM_KEYS } from '@/constants/keyboardKeys';

// Provides move direction for navigation
const navigationConfig = {
  [KEYBOARD_KEYS.LEFT_ARROW]: -1,
  [KEYBOARD_KEYS.RIGHT_ARROW]: 1,
};

const definitionsDefault = {
  // { keyboard-Key: (string) synthetic event name }
  custom: {},
  // { keyboard-Key: (string) id of element to focus on }
  focus: {},
  // array of focused element ids
  navigation: [],
};

const systemKeysPressed = evt => Object.values(SYSTEM_KEYS)
  .filter(key => evt[key]);

export class ShortcutController {
  constructor({ rootElem, rawDefs, emitter }) {
    this.rootElem = rootElem;
    this.definitions = { ...definitionsDefault, ...rawDefs };
    // Event emitter for child components to listen to
    this.emitter = emitter;
  }

  _focusOnElem(elemIdToFocus, evt) {
    const elem = this.rootElem.querySelector(`#${elemIdToFocus}`);
    if (elem) {
      evt.preventDefault();
      elem.focus();
    }
  }

  _handleShortcut(evt) {
    const { key } = evt;

    // do not trigger shortcut when system keys pressed
    if (systemKeysPressed(evt).length) { return }

    if (this.definitions.custom[key]) {
      this.emitter.emit(this.definitions.custom[key]);
    } else if (this.definitions.focus[key]) {
      this._focusOnElem(this.definitions.focus[key], evt);
    } else if (navigationConfig[key]) {
      this._navigate(evt);
    }
  }

  _navigate(evt) {
    const { key, target } = evt;
    const direction = navigationConfig[key];
    const navStates = this.definitions.navigation;
    const currentIdx = navStates.findIndex(state => state === target.id);

    if (currentIdx < 0) { return }

    const nextIdx = currentIdx + direction;
    if (nextIdx >= 0 && nextIdx < navStates.length) {
      this._focusOnElem(navStates[nextIdx], evt);
    }
  }

  _onKeyDownCb(evt) {
    const { target } = evt;
    const { contentEditable } = target;

    // Ignore events from input elements
    const type = target.nodeName;
    if (!['INPUT', 'TEXTAREA'].includes(type) && contentEditable !== 'true') {
      this._handleShortcut(evt);
    }
  }

  // Method for parent component to listen to key event
  attachListener() {
    this.rootElem.addEventListener('keydown', this._onKeyDownCb.bind(this));
  }
  // Method for parent component to remove listener for key event
  removeListener() {
    this.rootElem.removeEventListener('keydown', this._onKeyDownCb.bind(this));
  }
}
