import replace from 'lodash/replace';
import { createNamespacedHelpers } from 'vuex';

import expressionItem from '../ExpressionItem.vue';
import { enforceSpaceAfterComma } from '@/libs';

const { mapMutations, mapActions, mapGetters } = createNamespacedHelpers('intents/formIntent');
const { mapState } = createNamespacedHelpers('intents');

export default {
  name: 'intent-expressions-list',
  components: {
    expressionItem,
  },
  data() {
    return {
      newExpression: '',
      showNewExpressionForm: false,
    };
  },
  computed: {
    ...mapState([
      'formIntent',
    ]),
    ...mapGetters([
      'formTaggedEntities',
      'pristine',
    ]),
    iconName() {
      return this.showNewExpressionForm ? 'el-icon-minus' : 'el-icon-plus';
    },
  },
  watch: {
    'formIntent.id': function watchFormIntent() {
      this.newExpression = '';
      this.showNewExpressionForm = false;
    },
    pristine(isPristine) {
      if (isPristine) {
        this.showNewExpressionForm = false;
      }
    },
  },
  methods: {
    ...mapMutations([
      'CREATE_EXPRESSION',
      'UPDATE_EXPRESSION',
      'DELETE_EXPRESSION',
      'UPDATE_TAGGED_ENTITIES',
    ]),
    ...mapActions({
      annotateWithEntities: 'annotate',
    }),
    annotate() {
      return this.annotateWithEntities()
        .then(() => {
          this.$aiq.notify.success('Entity annotation completed.');
        })
        .catch(() => {
          this.$aiq.notify.error('Entity annotation failed.');
        });
    },

    toggleNewExpressionInputForm() {
      this.showNewExpressionForm = !this.showNewExpressionForm;
      this.$nextTick(() => {
        if (this.$refs.newExpressionInputForm) {
          this.$refs.newExpressionInputForm.focus();
        }
      });
    },

    createExpression() {
      if (this.newExpression === '') return;

      this.newExpression = replace(this.newExpression, /(@\w+)/g, e => e.toLowerCase().replace(/([^a-z_0-9@]+)/g, ''));
      this.newExpression = enforceSpaceAfterComma(this.newExpression);
      this.CREATE_EXPRESSION(this.newExpression);
      this.newExpression = '';
    },

    deleteExpression(index) {
      // TODO (Gabe) convert to index
      this.DELETE_EXPRESSION(index);
    },

    updateExpression(info) {
      const { taggedEntitiesOfExpression, ...expressionInfo } = info;

      // if taggedEntities is truthy, tagged entities may be deleted.
      if (taggedEntitiesOfExpression) {
        const filter = taggedEntity => taggedEntity.expression_index !== info.expressionIndex;
        const otherTaggedEntities = this.formTaggedEntities.filter(filter);
        const updatedTaggedEntities = [...otherTaggedEntities, ...taggedEntitiesOfExpression];
        this.UPDATE_TAGGED_ENTITIES(updatedTaggedEntities);
      }
      this.UPDATE_EXPRESSION(expressionInfo);
    },
  },
};
