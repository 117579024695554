
// keyboard event properties. Shift key is omitted
export const SYSTEM_KEYS = {
  ALT: 'altKey',
  CTRL: 'ctrlKey',
  // Meta is the Command key for Macs
  META: 'metaKey',
};

/**
 * Keyboard Key Events:
 * https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key
 */
export const KEYBOARD_KEYS = {
  ...SYSTEM_KEYS,

  BACKSPACE: 'Backspace',
  BACKSLASH: '/',
  TAB: 'Tab',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  LEFT_ARROW: 'ArrowLeft',
  UP_ARROW: 'ArrowUp',
  RIGHT_ARROW: 'ArrowRight',
  DOWN_ARROW: 'ArrowDown',
  DELETE: 'Delete',
  QUESTION_MARK: '?',

  // Alphabet
  C_LOWER: 'c',
  F_LOWER: 'f',
  L_LOWER: 'l',
  S_LOWER: 's',
};
