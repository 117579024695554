<template>
<svg width="752pt" height="752pt" version="1.1" viewBox="0 0 752 752" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m544.14 419.31c0-28.223-9.7109-55.844-27.375-77.988v-147.36c0-9.1875-7.4727-16.664-16.664-16.664-9.1875 0-16.664 7.4766-16.664 16.664v118.06c-19.547-11.887-41.723-18.125-64.699-18.125h-85.465c-22.977 0-45.152 6.2383-64.699 18.125v-118.06c0-9.1875-7.4766-16.664-16.664-16.664s-16.664 7.4766-16.664 16.664v147.35c-17.668 22.148-27.375 49.77-27.375 77.988 0 67.383 53.426 122.5 120.14 125.27v30.129h95.992v-30.129c66.711-2.7656 120.14-57.883 120.14-125.26zm-205.61 144.87v-19.469h74.941v19.469zm80.203-29.992h-85.465c-63.344 0-114.88-51.531-114.88-114.88 0-26.422 9.2969-52.27 26.176-72.777l1.1992-1.4609v-151.11c0-3.3867 2.7539-6.1406 6.1367-6.1406 3.3867 0 6.1406 2.7539 6.1406 6.1406v138.14l8.3398-6.0078c19.664-14.176 42.793-21.668 66.883-21.668h85.465c24.09 0 47.215 7.4922 66.883 21.668l8.3398 6.0078 0.003906-138.14c0-3.3867 2.7539-6.1406 6.1406-6.1406 3.3828 0 6.1367 2.7539 6.1367 6.1406v151.11l1.1992 1.4609c16.879 20.508 26.176 46.355 26.176 72.777 0.003906 63.344-51.531 114.88-114.88 114.88z"/>
  <path d="m271.11 461.75h209.78v-113.65h-209.78zm10.523-103.12h188.73v92.598l-188.73 0.003907z"/>
  <path d="m318.95 422.99h36.121v-36.125h-36.121zm10.523-25.602h15.074v15.078h-15.074z"/>
  <path d="m396.93 422.99h36.121v-36.125h-36.121zm10.527-25.602h15.074v15.078h-15.074z"/>
 </g>
</svg>
</template>
