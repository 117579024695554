<template>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g id="XMLID_5_">
<path id="XMLID_9_" style="fill:#333333;" d="M256,69.7V0l-93.2,93.2l93.2,93.2v-69.7c77.2,0,139.3,62.1,139.3,139.3
c0,23.5-5.9,45.3-15.9,65.5L413,355c17.6-28.5,28.5-63,28.5-99C442.3,152.8,359.2,69.7,256,69.7z M256,395.3
c-77.2,0-139.3-62.1-139.3-139.3c0-23.5,5.9-45.3,15.9-65.5L99,157c-17.6,28.5-28.5,63-28.5,99c0,103.2,83.1,186.3,186.3,186.3V512
l93.2-93.2l-94-93.2V395.3z"/>
</g>
</svg>
</template>
