<template lang="pug">
  .api-card(@click="$emit('select', item.id)"
            @mouseover="hovered = true"
            @mouseout="hovered = false")
    .api-card-header
      aiq-button(link type="primary" @click.stop.prevent="$emit('delete')")
        i.el-icon-close.button-delete
    .api-card__content
      .api-card-name
        span {{item.name || ''}}
      .api-card-description {{item.description || ''}}
    .api-card-footer
      aiq-button(@click.stop.prevent="$emit('export')" size="small") Export
</template>

<script>
export default {
  name: 'apiCard',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hovered: false,
    };
  },
  methods: {
  },
};
</script>

<style lang="scss">
.api-card {
  .el-button--list-item:hover {
    background-color: white;    
  }
}
</style>

<style lang="scss" scoped>
@import "../../../../styles/aiq-extensions.scss";
@import "../../../../styles/aiq-variables.scss";

.api-card {
  @extend %api-card;

  display: inline-block;

  > div {
    font-weight: 300;
  }

  .button-delete {
    font-size: 14px;
    color: $aiq-icon-primary;
  }

  .api-card__content {
    padding: 0 32px 32px 32px;
    height: calc(100% - 70px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .api-card-name {
    font-size: 20px;
    padding-bottom: 7px;
  }

  .api-card-description {
    font-size: 18px;
    padding-top: 7px;
  }
  .api-card-footer {
    height: 35px;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    padding: 0px 10px 10px 0px;
    gap: 10px;
  }
  .api-card-header {
    height: 35px;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    padding: 10px 0px 10px 0px;
  }
}
</style>
