import * as log from 'loglevel';
import get from 'lodash/get';
import queryString from 'query-string';

const POPUP_WIDTH = 400; // eslint-disable-line
const POPUP_HEIGHT = 500; // eslint-disable-line
const HUNDRED_MS = 100; // eslint-disable-line
const ONE_MINUTE = 1000 * 60; // eslint-disable-line

const TAG = '[OAuth(mixin)]:';

export default {
  methods: {
    oauth2Url(authUrl, queryparams = {}) {
      return `${authUrl}?${queryString.stringify(queryparams)}`;
    },

    createCodeVerifier() {
      return crypto.randomBytes(60).toString('hex').slice(0, 128); // eslint-disable-line
    },

    createCodeChallenge(codeVerifier) {
      return crypto.createHash('sha256')
        .update(Buffer.from(codeVerifier)).digest('base64')
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_');
    },

    _openCenterPopup(url, title, w, h) {
      // ugly enough but seems the best compatible approach
      // https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen
      const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
      const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

      let width;
      let height;
      if (window.innerWidth) {
        width = window.innerWidth;
      } else {
        width = document.documentElement.clientWidth || window.screen.width;
      }
      if (window.innerHeight) {
        height = window.innerHeight;
      } else {
        height = document.documentElement.clientHeight || window.screen.height;
      }

      const systemZoom = width / window.screen.availWidth;
      const left = (width - w) / 2 / systemZoom + dualScreenLeft; // eslint-disable-line
      const top = (height - h) / 2 / systemZoom + dualScreenTop; // eslint-disable-line

      const child = window.open(url, title,
        `
        scrollbars=yes,
        width=${w / systemZoom}, 
        height=${h / systemZoom}, 
        top=${top}, 
        left=${left}
        `);

      if (window.focus) child.focus();
      return child;
    },

    /**
     * Post a authorization code to the window that opened the current window.
     */
    postCodeToOpenerAndClose(routeName, integration) {
      const params = window.location.search;
      if (window.opener) {
        const q = queryString.parse(params);
        window.opener.postMessage({ integration, code: q.code });
      }
      window.close();
      return;
    },

    /**
     * A function that returns authorization code after authentication with a popup
     *
     * @param url(string): complete authorize url with expected query params
     * @param integration(string): a unique name of integration
     *
     * @return authorization code(string)
     */
    openOauth2Popup(url, integration) {
      const startTime = Date.now();

      return new Promise((resolve, reject) => {
        const child = this._openCenterPopup(url, 'OAuth2', POPUP_WIDTH, POPUP_HEIGHT);
        let done = false;
        let code;
        const receiveMessage = (ev) => {
          log.debug(TAG, 'Got message from pop up', ev);
          if (get(ev, 'data.source') !== integration) {
            return;
          }
          code = get(ev, 'data.code');
          done = true;
        };

        const cleanIntervalAndMessage = (interval) => {
          window.removeEventListener('message', receiveMessage);
          clearInterval(interval);
        };

        const interval = setInterval(() => {
          const passTime = Date.now() - startTime;

          if (done) {
            cleanIntervalAndMessage(interval);
            resolve(code);
            return;
          }

          if (child && child.closed) {
            cleanIntervalAndMessage(interval);
            reject(new Error('Authentication is not successful'));
            return;
          }

          if (passTime > ONE_MINUTE) {
            cleanIntervalAndMessage(interval);
            reject(new Error('Timed out'));
            return;
          }
        }, HUNDRED_MS);

        window.addEventListener('message', receiveMessage, false);
      });
    },
  },
};
