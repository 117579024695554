import Error from './Error.vue';

export default {
  component: Error,
  path: '/error',
  name: 'Error',
  meta: {
    auth: true,
    permissioned: false,
  },
};
