
import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    agents: {},
    selectedAgents: {},
    totalAgents: 0,
  },
  getters: {
    agents: state => Object.values(state.agents),
    getSelectedAgents: state => state.selectedAgents,
    hasFinishedLoading: state => state.totalAgents <= Object.keys(state.agents).length,
  },
  mutations,
  actions,
};
