<template lang="pug">
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import ModalEditor from '@/components/ModalEditor/ModalEditor.vue';
import { STATUS_CODES } from '@/constants';
import * as log from 'loglevel';

const TAG = 'EDITOR';

// todo refactor this to integrate better with ModalEditor
export default {
  components: {
    ModalEditor,
  },
  name: 'editor',
  computed: {
    isRecordDirty() {
      if (this.v$) {
        return this.v$.$anyDirty;
      }
      return true;
    },
    isRecordValid() {
      if (this.v$) {
        return !this.v$.$invalid;
      }
      return true;
    },
    isSubmittable() {
      return (this.isRecordDirty && this.isRecordValid);
    },
  },
  data() {
    return {
      record: null,
      visible: false,
      modelPath: null,
      selectAction: null,
      fetchAction: 'agents/getAgents',
      childFormNames: [],
      fields: {},
    };
  },
  created() {
    this.record = cloneDeep(this.$store.getters[this.modelPath]);
  },
  mounted() {
    return this.resolveRecord()
      .then(record => {
        if (this.selectAction) {
          const r = this.$store.dispatch(this.selectAction, record);
          if (r) {
            return r;
          }
        }
        return record;
      })
      .then(record => {
        const r = cloneDeep(record);
        this.record = { ...r };
        this.visible = true;

        this.$nextTick(() => {
          // Note: Pretty bad place to resetting validate but due to the current component design,
          // and this is only place to reset validator.
          // this.v$ is set in child component such as below files
          //
          // - src/pages/Settings/Documents/Edit.vue
          // - src/pages/Settings/Assets/Edit.vue
          // - src/pages/Settings/Teams/Edit.vue
          // - src/pages/Settings/Agents/children/QuickResponses/Edit.vue
          if (this.v$) {
            this.v$.$reset();
          }
        });
      })
      .catch(err => {
        log.warn(TAG, err);
        this.visible = false;
        if ([
          STATUS_CODES.INTERNAL_ERROR,
          STATUS_CODES.NOT_ACCEPTABLE,
          STATUS_CODES.NOT_FOUND,
        ].includes(err.status)) {
          if (this.$refs.modalEditor) {
            this.$aiq.notify.error(`${this.$route.params.id} not found.`);
            this.$refs.modalEditor.navigateAway();
          }
        }
      });
  },
  methods: {
    getRecordProp(prop, defaultValue) {
      return get(this.record, prop, defaultValue);
    },
    resolveRecord() {
      const { id } = this.$route.params;
      log.debug(TAG, `resolveRecord of agent ${id}`);
      return (id === 'new')
        ? Promise.resolve(this.record)
        : this.$store.dispatch(this.fetchAction, [id]).then(response => response.data);
    },
    getChildForms() {
      return this.childFormNames
        .map(name => this.$refs[name])
        .filter(Boolean); // filter out unrendered forms
    },
  },
};
</script>

<style lang="scss" scoped>
.img-uploader-container {
  position: absolute;
  width: 130px;
}
</style>
