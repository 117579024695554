<template lang="pug">
  .summary-table
    summary-table.topics-scrollable(:dateRange="dateRange"
                  report="topics"
                  breakdown="hour"
                  :items="filteredItems"
                  :selectable="false"
                  @onDataChange="onDataChange")
      slot
        aiq-table-column(label="Topic", prop="name"  width="120" :sortable="true" label-class-name="is-bordered")
        aiq-table-column(label="Keywords", width="" label-class-name="is-bordered")
          template(v-slot:default="scope")
            div.el-tag-container
              aiq-tooltip(v-for="keyword, index in scope.row.keywords"
                          :key="keyword"
                          :content="scope.row.examples[index]"
                          effect="dark"
                          placement="top")
                aiq-tag {{ keyword }} &nbsp;
        aiq-table-column(label="Frequency", prop="frequency" width="180" :sortable="true" label-class-name="is-bordered")
</template>

<script>
import get from 'lodash/get';
import SummaryTable from '../../../SummaryTable.vue';
import { downloadAsFile } from '@/libs';

export default {
  name: 'summaryMetricsSummaryTable',
  components: {
    SummaryTable,
  },
  props: {
    dateRange: {
      required: true,
      type: Array,
    },
    query: {
      required: false,
      type: String,
    },
  },
  emits: ['selectionChange'],
  computed: {
    filteredItems() {
      if (!this.query) {
        return this.items;
      }

      const nameKeywordFormatter = (d) => {
        if (!d.name && !d.keywords.length) {
          return '';
        }
        // Using '  ' as delimiter to distinguish from normally used whitespace.
        return `${d.name}  ${d.keywords.join('  ')}`;
      };
      const filter = d => nameKeywordFormatter(d)
        .toLowerCase()
        .includes(this.query.toLowerCase());
      return this.items.filter(filter);
    },
  },
  data() {
    return {
      chartData: null,
      items: [],
      selection: [],
    };
  },
  methods: {
    emitSelection() {
      this.$emit('selectionChange', this.selection);
    },
    onDataChange(data = {}) {
      this.items = get(data, 'dataset.0.data_points', []);
    },
    exportTable() {
      downloadAsFile({
        dataType: 'table',
        data: this.items,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../styles/aiq-mixins.scss";
.summary-table {
  height: 100%;
}
.content-inner {
  padding: 0;
}

.el-tag {
  margin: 0 4px 1px 0;
}
</style>

<style lang="scss">
@import "../../../../../styles/aiq-mixins.scss";

.topics-scrollable .el-table__body-wrapper {
  @include scrollable(calc(100vh - 257px));
}
</style>
