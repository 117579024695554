import { createNamespacedHelpers, mapState } from 'vuex';

import entityItem from './EntityItem/EntityItem.vue';

const { mapGetters, mapMutations } = createNamespacedHelpers('intents/formIntent');

const defaultNewEntity = { name: '' };

export default {
  name: 'intent-entities-selection-list',
  components: {
    entityItem,
  },
  data() {
    return {
      newEntity: { ...defaultNewEntity },
      showNewEntitiesSelectionBox: false,
    };
  },
  watch: {
    'formIntent.id': function watchFormIntentId() {
      this.newEntity = { ...defaultNewEntity };
      this.showNewEntitiesSelectionBox = false;
    },
    newEntity(newEntity) {
      if (newEntity === null) {
        this.newEntity = { ...defaultNewEntity };
      } else if (newEntity && newEntity.name !== defaultNewEntity.name) {
        this.ADD_ENTITY(newEntity);
        this.newEntity = null;
        this.showNewEntitiesSelectionBox = false;
      }
    },
  },
  computed: {
    ...mapState({
      formIntent: state => state.intents.formIntent,
    }),
    ...mapGetters([
      'formEntities',
    ]),
    iconName() {
      return this.showNewEntitiesSelectionBox ? 'el-icon-minus' : 'el-icon-plus';
    },
    unselectedEntities() {
      return this.$store.getters['entities/selectionList'].filter(entity => {
        let isSelected = false;

        this.formEntities.forEach(formEntity => {
          if (entity.name === formEntity.name) {
            isSelected = true;
          }
        });

        return !isSelected;
      });
    },
  },
  mounted() {
    this.$store.dispatch('entities/getEntitiesList', []);
  },
  methods: {
    ...mapMutations([
      'ADD_ENTITY',
      'UPDATE_ENTITY',
      'DELETE_ENTITY',
    ]),
    toggleEntitySelectionBox() {
      this.showNewEntitiesSelectionBox = !this.showNewEntitiesSelectionBox;
    },
    deleteEntity(index) {
      const updatedTaggedEntities = this.removeOldTaggedEntities(index);
      const info = {
        index,
        updatedTaggedEntities,
      };
      return this.DELETE_ENTITY(info);
    },
    removeOldTaggedEntities(entityIndex) {
      const oldEntityName = this.formEntities[entityIndex].name;
      const oldTaggedEntities = this.formIntent.payload.tagged_entities;
      const updatedTaggedEntities = oldTaggedEntities.filter(ent => ent.name !== oldEntityName);

      return updatedTaggedEntities;
    },
    updateEntity(newEntity, index) {
      const updatedTaggedEntities = this.removeOldTaggedEntities(index);
      const info = {
        newEntity,
        index,
        updatedTaggedEntities,
      };

      return this.UPDATE_ENTITY(info);
    },
  },
};
