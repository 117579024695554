<template lang="pug">
  management-page.scrollable(title="Topics"
                :creatable="false"
                :searchable="true"
                :showHeader="false")
    template(v-slot:body)
      .chart-configuration
        .flex-spacer
        FilteredInput.search(size="small" v-model="query" placeholder="Search")
        .time-range-filter
          aiq-date-picker(v-model="dateRange"
                          @change="handleDatePickerChange($event)"
                          type="daterange"
                          placeholder="Select date range",
                          popper-class="metrics-time-range__date-picker-popup"
                          :shortcuts="datePickerOptions.shortcuts"
                          :disabled-date="datePickerOptions.disabledDate"
                          :teleported="false"
                          format="YYYY/MM/DD"
                          :clearable="false")
        aiq-button.export(@click="exportTable")
          i.iq-ico-export
      .chart-grid
          aiq-col(:span="24")
            topics-summary-table(:dateRange="dateRangeUtc"
                                 :query="searchQuery"
                                 ref="TopicsSummaryTable"
                                @selectionChange="onSummaryTableSelectionChange")
</template>

<script>
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import OverviewChart from '@/components/Chart.vue';
import TopicsSummaryTable from './TopicsSummaryTable.vue';
import { datePickerDefaultOptions, dstDateRangeUtc, setToEndOfDay, defaultDateRange, getNonReactiveDateRange, lastAvailableMonthAndQuarterRange, createPickerDateRangeOption } from '../../../configs/dateRange';


export default {
  pageResource: '/metrics/insights',
  components: {
    TopicsSummaryTable,
    ManagementPage,
    OverviewChart,
  },
  computed: {
    datePickerOptions() {
      return {
        ...datePickerDefaultOptions(),
        shortcuts: lastAvailableMonthAndQuarterRange.map(createPickerDateRangeOption),
      };
    },
    dateRangeUtc() {
      return dstDateRangeUtc(this.dateRange);
    },
    searchQuery() {
      return this.query;
    },
  },

  data() {
    return {
      query: null,
      percentFormatter: v => `${v}%`,
      durationFormatter: this.$aiq.formatters.timeDelta,
      dateRange: defaultDateRange(),
    };
  },
  methods: {
    exportTable() {
      this.$refs.TopicsSummaryTable.exportTable();
    },
    onSummaryTableSelectionChange() {
    },
    handleDatePickerChange(newDateRange) {
      this.dateRange = setToEndOfDay(getNonReactiveDateRange(newDateRange));
    },
  },
};
</script>

<style lang="scss" scoped>

.scrollable {
  height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;
}

.chart-grid {
  flex: 1;

  .el-col {
    margin-bottom: 16px;
    height: 100%;

    :deep(.el-table--fit) {
      height: 100%;
    }
  }
}

.el-card:not(:first-of-type) {
  margin-top: 24px;
}

.chart-configuration {
  display: flex;
  margin-bottom: 12px;

  .flex-spacer {
    flex-grow: 1;
  }

   .search {
    max-width: 246px;
  }

  .time-range-filter {
    position: relative;
    margin-left: 16px;
  }

  .export-command {
    display: flex;
    height: 30px;
    width: 40px;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    padding: 0;
  }

  .el-button.export {
      height: 30px;
      width: 40px;
      padding: 0;
      margin-left: 16px;
  }
}
</style>
