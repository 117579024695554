<template lang="pug">
.inner-content-text {{label}}
  aiq-select.main-workflow__select.select--with-icon(:model-value="selectedWorkflow"
                                                      placeholder="workflowPlaceholderText"
                                                      prefix-icon="iq-ico-building-kit-workflow"
                                                      no-data-text="No Workflows Available"
                                                      @change="$emit('selected', $event)"
                                                      size="small"
                                                      clearable)
    template(v-slot:prefix)
      i.iq-ico-building-kit-workflow.select__icon--prefix
    aiq-option(v-for="workflow in workflows"
            :key="workflow.id"
            :label="workflow.name"
            :value="workflow.id")
    .select-box__option
        i.iq-ico-building-kit-workflow
        .inner-content-text.select-box__text {{ workflow }}
</template>

<script>

export default {
  props: {
    label: {
      type: String,
      default: 'Workflow',
    },
    workflowPlaceholderText: {
      type: String,
      default: 'Select Workflow',
    },
    selectedWorkflow: {
      type: Number,
    },
    workflows: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/aiq-variables.scss";

  .inner-content-text {
    margin-bottom: 16px;
  }

  .system-trigger-container {
    margin: 24px 0 16px 0;

    .main-workflow-container {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .main-workflow__select {
        margin-left: 10px;
        width: 302px;
      }
    }

    .system-workflow-container {
      display: flex;
      align-items: center;

      .system-workflow__select {
        margin-left: 10px;
        width: 302px;
      }
    }

    .system-triggers__checkbox {
      padding: 11px 0;
    }

    .check-interval-container {
      align-items: center;
      display: flex;
      justify-content: flex-start;

      > * {
        margin-left: 10px;
      }

      > :first-child {
        margin-left: 24.5px;
      }

      .check-interval-text--a, .check-interval-text--c {
        min-width: 68px;
      }

      .check-interval-text--b {
        min-width: 71px;
      }

      .check-interval-text--c {
        margin-left: 0;
      }
    }
  }
</style>
              
