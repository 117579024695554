import COLORS from './colors';

export default {
  name: 'conversationsActivityMixin',
  computed: {
    conversationActivityChartConfig() {
      const baseTStringPath = 'metrics_tab.plaform_tab.summary_tab.conversation_activity';
      return {
        name: 'conversations',
        title: 'Conversation Activity',
        metric: 'conversations',
        downloadUsingLineLabels: true,
        lines: {
          user_initiated: {
            label: 'Agent Initiated',
            position: 1,
            color: COLORS.yellow,
          },
          customer_initiated: {
            label: this.$t(`${baseTStringPath}.customer_initiated`),
            position: 2,
            color: COLORS.red,
          },
          bot_initiated: {
            label: 'Bot Initiated',
            position: 3,
            color: COLORS.orange,
          },
          total: {
            label: 'Total Conversations',
            position: 4,
            color: COLORS.blue,
            type: 'spline',
          },
        },
        helpText:
          'Displays all conversation activity, which when totaled, equals total conversations.',
        maxTickLabelCount: 6,
        rightPadding: null,
        leftPadding: null,
        relabeledExportHeaders: ['user_initiated_count', 'user_initiated_percent', this.$t(`${baseTStringPath}.customer_initiated_count`), this.$t(`${baseTStringPath}.customer_initiated_percent`), 'bot_initiated_count', 'bot_initiated_percent', 'total_count', 'total_percent']
      };
    }
  }
};