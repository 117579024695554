<template lang="pug">
.shortcut-menu
  .shortcut-group(v-for="group in $_shortcutMenu")
    .shortcut-group__header {{ group.name }}
    .shortcut-group__content
      .shortcut-item(v-for="shortcut in group.items")
        .shortcut-keys
          template(v-for="(key, idx) in shortcut.keys")
            span.shortcut-key__text(v-html="$filters.sanitize(key.html)")
            span.shortcut-key__join(v-if="idx !== shortcut.keys.length - 1")
              | {{ shortcut.keyJoinText }}
        span :
        span.shortcut-description {{ shortcut.description }}
</template>

<script>

import { SHORTCUT_MENU } from '@/constants';

export default {
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  created() {
    this.$_shortcutMenu = SHORTCUT_MENU;
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/aiq-extensions.scss";
@import "../../styles/aiq-mixins.scss";

.shortcut-menu {
  display: flex;
  flex-wrap: wrap;

  .shortcut-group {
    .shortcut-group__header {
      font-weight: 700;
    }

    .shortcut-group__content {
      @include space-between-v(10px);
      margin-top: 10px;
      margin-bottom: 20px;
      width: 390px;

      .shortcut-item {
        display: flex;

        .shortcut-keys {
          @include space-between-h(2px);
          display: flex;
          justify-content: flex-end;
          padding-right: 2px;
          width: 100px;

          .shortcut-key__text {
            color: $aiq-text-shortcut-key;
          }
        }

        .shortcut-description {
          padding-left: 4px;
        }
      }
    }
  }
}

</style>
