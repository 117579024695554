import { mapState, createNamespacedHelpers } from 'vuex';
import { Component, Watch, Vue, toNative } from 'vue-facing-decorator';

const { mapMutations } = createNamespacedHelpers('aiEngine');

@Component({
  name: 'AiTesting',
  props: {
    focused: Boolean,
    showClear: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: 'chat', // chat or workflow
    },
    showDebug: {
      type: Boolean,
      default: true,
    },
    showSuggestion: {
      type: Boolean,
      default: true,
    },
    disableInputbox: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      items: state => state.aiEngine.items,
      dialogs: state => state.dialogs.dialogs,
      workflows: state => state.workflows.workflows,
    }),
    displayItems() {
      return this.items.filter(item => this.shouldDisplay(item));
    },
  },
  methods: {
    ...mapMutations([
      'SELECT_AND_UPDATE_REPLIES_SUCCESS',
      'SELECT_AND_UPDATE_REPLIES_FAIL',
    ]),
  },
})

class AiTesting extends Vue {
  message = '';

  @Watch('focused')
  focusedChange(newFocused) {
    if (newFocused) { this.focusOnRespondInput() }
  }

  shouldDisplay(item) {
    if (!item.message && !item.payload) {
      return false;
    }

    if (!this.showSuggestion && item.sender_type === 'suggestion') {
      return false;
    }

    if (!this.showDebug && item.bubbleClass === 'debug-bubble') {
      return false;
    }

    return true;
  }

  created() {
    this.$_toggleSuggestions = e => e.target.parentElement.lastChild.classList.toggle('show');
  }

  // mounted() {
  // TODO (Gabe) Make sure to load commands into the store
  // }

  sendReply(content) {
    const messageInfo = {
      isIqTools1: false,
      hideMessage: true,
      content,
    };

    this.SELECT_AND_UPDATE_REPLIES_SUCCESS(content);

    this.$store.dispatch('aiEngine/sendMessage', messageInfo).then(() => {
      this.scrollToEnd();
    }).catch(() => {
      this.SELECT_AND_UPDATE_REPLIES_FAIL(content);
    });
  }

  sendMessage() {
    const messageInfo = {
      isIqTools1: false,
      content: this.message,
    };

    this.$store.dispatch('aiEngine/sendMessage', messageInfo).then(() => {
      this.scrollToEnd();
    });
    this.message = '';
  }

  clear() {
    this.$store.dispatch('aiEngine/reset', this.mode);
  }

  scrollToEnd() {
    const container = this.$el.querySelector('.scroll');
    container.scrollTop = container.scrollHeight;
  }

  // TODO (Gabe) - set up as mixin
  focusOnRespondInput() {
    this.$refs.defaultinput.focus();
  }
}
export default toNative(AiTesting);
