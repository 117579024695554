import axios from 'axios';
import pluralize from 'pluralize';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import merge from 'lodash/merge';
import uniqBy from 'lodash/uniqBy';
import {
  getUrl,
} from '../config/api.routes';

const initMutation = (template) => {
  template = template.toUpperCase();
  return {
    start: `${template}`,
    success: `${template}_SUCCESS`,
    error: `${template}_ERROR`,
  };
};

class AbstractStoreBuilder {
  createStore(namespaced = true) {
    return {
      namespaced,
      actions: this.createActions(),
      mutations: this.createMutations(),
      state: this.createState(),
    };
  }
}

export const updateKeywordsActionBuilder = (routingAlias) => ({
  updateKeywords: ({
    commit,
  }, [item, keywords, type = 'CATEGORY']) => {
    const {
      id,
    } = item;
    return axios.put(`${getUrl(routingAlias)}/${id}`, {
      ...item,
      keywords,
    }).then(data => {
      commit(`PUT_${type.toUpperCase()}_SUCCESS`, data.data);
      return data;
    });
  },
});

export class CRUDStoreBuilder extends AbstractStoreBuilder {
  constructor(routingAlias, _storeName, storeDefaultValue, dataKey, pending = false) {
    super();
    this.routingAlias = routingAlias;
    this._storeName = _storeName;
    this.storeDefaultValue = storeDefaultValue;
    this.dataKey = dataKey;
    this.pending = pending;
    this.mutationsList = {
      list: merge(initMutation(`GET_${this.storeName}_LIST`), {
        loaded: `${this.storeName}_LOADED`,
      }),
      read: initMutation(`GET_${this._storeName}`),
      create: initMutation(`POST_${this._storeName}`),
      update: initMutation(`PUT_${this._storeName}`),
      delete: initMutation(`DELETE_${this._storeName}`),
    };
  }

  get storeName() {
    return Array.isArray(this.storeDefaultValue) ? pluralize(this._storeName) : this._storeName;
  }

  get _storeCapitalize() {
    return capitalize(this._storeName);
  }

  get _storeTitle() {
    return this._storeName.toUpperCase();
  }

  /**
   * TODO: refactor this shit
   */
  createActions() {
    const actions = {};
    actions[`get${pluralize(this._storeCapitalize)}List`] = ({
      commit,
    }, config = []) => {
      const [params = {}, isNew = true, dataKey = this.dataKey] = config;
      if (this.pending) {
        commit(this.mutationsList.list.start);
      }
      return axios.get(getUrl(this.routingAlias), {
        params,
      }).then(data => {
        const gettedData = get(data, dataKey, []);
        if (gettedData.length === 0) {
          commit(this.mutationsList.list.loaded, true);
        }
        commit(this.mutationsList.list.success, {
          items: gettedData,
          isNew,
        });
        return data;
      });
    };
    actions[`mark${pluralize(this._storeCapitalize)}AsLoaded`] = ({
      commit,
    }, value) => {
      commit(this.mutationsList.list.loaded, value);
    };
    actions[`get${this._storeCapitalize}`] = ({
      commit,
    }, params = []) => {
      // todo allow specification of data key
      const [id, dataKey = 'data'] = params;
      if (this.pending) {
        commit(this.mutationsList.read.start);
      }
      return axios.get(getUrl(this.routingAlias, id))
        .then(data => {
          commit(this.mutationsList.read.success, get(data, dataKey));
          return data;
        });
    };
    actions[`create${this._storeCapitalize}`] = ({
      commit,
    }, params = []) => {
      const [item, dataKey = this.dataKey] = params;
      if (this.pending) {
        commit(this.mutationsList.create.start);
      }
      return axios.post(getUrl(this.routingAlias), item)
        .then(data => {
          commit(this.mutationsList.create.success, get(data, dataKey));
          return data;
        });
    };
    actions[`update${this._storeCapitalize}`] = ({
      commit,
    }, params = []) => {
      const [item, dataKey = this.dataKey] = params;
      if (this.pending) {
        commit(this.mutationsList.update.start);
      }

      return axios.put(getUrl(this.routingAlias, item.id), item)
        .then(data => {
          commit(this.mutationsList.update.success, get(data, dataKey));
          return data;
        });
    };
    actions[`delete${this._storeCapitalize}`] = ({
      commit,
    }, id) => {
      if (this.pending) {
        commit(this.mutationsList.delete.start);
      }
      return axios.delete(getUrl(this.routingAlias, id))
        .then(data => {
          commit(this.mutationsList.delete.success, id);
          return data;
        });
    };
    return actions;
  }

  /**
   * TODO: refactor this shit
   */
  createMutations() {
    const mutations = {};
    if (this.pending) {
      ['create', 'update', 'delete', 'list', 'read'].forEach(e => {
        mutations[this.mutationsList[e].start] = (state) => {
          state[`${e}Pending`] = true;
        };
      });
    }
    mutations[this.mutationsList.list.success] = (state, data) => {
      let items;

      if (this.pending) {
        state.listPending = false;
      }

      if (data.isNew) {
        items = data.items;
      } else {
        if (data.items.length === 0) {
          state[`${this.storeName}Loaded`] = true;
          return;
        }

        items = uniqBy(state[this.storeName].concat(data.items), 'id');
      }

      state[this.storeName] = items;
    };
    mutations[this.mutationsList.list.loaded] = (state, value) => {
      state[`${this.storeName}Loaded`] = value;
    };
    mutations[this.mutationsList.create.success] = (state, item) => {
      if (this.pending) {
        state.createPending = false;
      }
      state[this.storeName].unshift(item);
    };
    mutations[this.mutationsList.update.success] = (state, item) => {
      if (this.pending) {
        state.updatePending = false;
      }
      state[this.storeName] = state[this.storeName]
        .map(e => (e.id === item.id ? item : e));
    };
    mutations[this.mutationsList.delete.success] = (state, id) => {
      if (this.pending) {
        state.deletePending = false;
      }
      state[this.storeName] = state[this.storeName].filter(e => e.id !== id);
    };

    mutations[this.mutationsList.read.success] = (state, data) => {
      if (this.pending) {
        state.readPending = false;
      }
      state[this.storeName] = uniqBy([{ ...data }, ...state[this.storeName]], 'id');
    };
    return mutations;
  }

  createState() {
    const state = {};
    state[this.storeName] = this.storeDefaultValue;
    state[`${this.storeName}Loaded`] = false;
    if (this.pending) {
      ['create', 'update', 'delete', 'list', 'read']
        .map(e => {
          state[`${e}Pending`] = false;
          return e;
        });
    }
    return state;
  }
}
