import { createStore } from 'vuex';
import createMutationsSharer from 'vuex-shared-mutations';
import LogRocket from 'logrocket';
import createPlugin from 'logrocket-vuex';

import * as modules from './modules';

const store = createStore({
  plugins: [
    createMutationsSharer({
      predicate: ['agent/CHANGE_USER_ACTIVITY', 'agent/CHANGE_IDLE_POPUP_STATUS'],
    }),
    createPlugin(LogRocket),
  ],
  modules,
});

if (module.hot) {
  module.hot.accept([
    './modules',
  ], () => {
    store.hotUpdate({
      modules,
    });
  });
}

export default store;
