import { createNamespacedHelpers } from 'vuex';
import get from 'lodash/get';

import { SuggestionPopup } from '@/components';

const { mapState } = createNamespacedHelpers('entities');

const CHAR_TO_PIXEL_RATIO = 7;
const SUGGESTION_POPUP_OFFSET_Y = 2;
const DEFAULT_INPUT_SIZE = {
  width: 217,
  height: 30,
};
const DEFAULT_SUGGESTION_POPUP_WIDTH = 180;

// TODO (Gabe) Add suggestion-popup component to this mixin
export default {
  components: {
    SuggestionPopup,
  },
  data() {
    return {
      inputText: '',
      tempInputText: '',
      cursorPosition: 0,
      inputStartIdxForEntityName: -1,
      styleObj: {
        top: 0,
        left: 0,
      },
      noneFoundMessage: 'No Entities Found',
      clickedDuringEntityPopup: false,
    };
  },

  computed: {
    ...mapState([
      'entities',
    ]),

    entityQuery() {
      const substring = this.inputText.substring(0, this.cursorPosition);
      const match = /@([\d\w.]+)$/.exec(substring);

      if (match) {
        this.inputStartIdxForEntityName = match.index;
        return match[1];
      }
      this.inputStartIdxForEntityName = this.cursorPosition - 1;
      return null;
    },

    entityMatches() {
      if (this.isPrevAtChar) {
        return this.entities;
      }
      return this.entities.filter(e => e.name.toLowerCase().indexOf(this.entityQuery) > -1);
    },

    hasInput() {
      return get(this, '$refs.spInputBox.$el.children[0].tagName') === 'INPUT';
    },

    hasTextarea() {
      return get(this, '$refs.spInputBox.$el.children[0].tagName') === 'TEXTAREA';
    },

    inputBoxPixels() {
      if (this.hasInput || this.hasTextarea) {
        const { clientWidth, clientHeight } = this.$refs.spInputBox.$el.children[0];
        return {
          width: clientWidth,
          height: clientHeight,
        };
      }

      return DEFAULT_INPUT_SIZE;
    },

    isPrevAtChar() {
      if (this.cursorPosition > 0) {
        return this.inputText[this.cursorPosition - 1] === '@';
      }
      return false;
    },

    popupPixelWidth() {
      return get(this, 'suggestionPopup.offsetWidth', DEFAULT_SUGGESTION_POPUP_WIDTH);
    },

    shouldShowEntitySelect() {
      return !this.clickedDuringEntityPopup
        && !!(this.entityQuery || this.isPrevAtChar || this.entityMatches[0]);
    },

    transitionPointByCursor() {
      return Math.floor((this.inputBoxPixels.width - this.popupPixelWidth) / CHAR_TO_PIXEL_RATIO);
    },
  },

  methods: {
    getPosition() {
      const inputRect = this.$refs.spInputBox.$el.getBoundingClientRect();

      return {
        x: inputRect.left,
        y: inputRect.top,
      };
    },

    onClickOutsideEntitySelect() {
      document.querySelector('body').removeChild(this.$refs.suggestionPopup.$el);
    },

    onEntityClick(e) {
      const entityName = e.target.textContent.trim();
      const beginning = this.inputText.slice(0, this.inputStartIdxForEntityName);
      let endIndex = this.inputText.length;
      for (let i = this.cursorPosition; i < this.inputText.length; i += 1) {
        if (this.inputText[i] === ' ') {
          endIndex = i;
          break;
        }
      }
      const end = this.inputText.slice(endIndex);

      this.inputText = beginning + entityName + end;
      this.clickedDuringEntityPopup = true;
      this.$refs.spInputBox.$el.children[0].select();
    },

    onKeyPressUp(e) {
      this.setCursorPosition(e);
    },

    setCursorPosition(e) {
      this.cursorPosition = e.target.selectionStart;

      const elPosition = this.getPosition(e.target.offsetParent);
      const positionPixels = this.cursorPosition * CHAR_TO_PIXEL_RATIO;
      const topPos = elPosition.y + this.inputBoxPixels.height + SUGGESTION_POPUP_OFFSET_Y;
      this.styleObj.top = `${topPos}px`;

      if (get(this, '$refs.suggestionPopup.$el')) {
        document.querySelector('body').appendChild(this.$refs.suggestionPopup.$el);
      }

      let leftPos;
      if (this.hasTextarea) {
        /**
         * This sets a statically positioned popup for textarea elements.
         * Not doing so will be much more complicated (TODO Gabe):
         * https://stackoverflow.com/questions/9185630/find-out-the-line-row-number-of-the-cursor-in-a-textarea
         */
        leftPos = elPosition.x;
      } else if (this.cursorPosition <= this.transitionPointByCursor) {
        leftPos = positionPixels + elPosition.x;
      } else {
        const transitionPointByPixel = this.inputBoxPixels.width - this.popupPixelWidth;
        leftPos = transitionPointByPixel + elPosition.x;
      }
      this.styleObj.left = `${leftPos}px`;
      this.clickedDuringEntityPopup = false;
    },
  },
};
