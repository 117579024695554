<template lang="pug">
.grid
  aiq-row.categories-row(:gutter="20")
    aiq-col(:span="16")
      performance-category(:category="categories.communication" v-bind="$attrs" :selected-metric="selectedMetric")
    aiq-col(:span="8")
      performance-category(:category="categories.responsiveness" v-bind="$attrs" :selected-metric="selectedMetric")
  aiq-row.categories-row
    aiq-col(:span="24")
      performance-category(:category="categories.relationship" v-bind="$attrs" :selected-metric="selectedMetric")
</template>


<script>
import PerformanceCategory from './PerformanceCategory.vue';

export default {
  name: 'PerformanceGrid',
  components: {
    PerformanceCategory,
  },
  props: {
    categories: {
      type: Object,
      required: true,
    },
    selectedMetric: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.categories-row {
  margin-bottom: 20px;
}
</style>
