<template lang="pug">
  aiq-dialog(:model-value="!!message" 
            :show-close="false"
            :open-delay="500"
            title="Delete Message:" 
            width="420" 
            class="delete-message-confirm-dialog"
            align-center)
    .delete-message-confirm
      p {{ $t('conversation_tab.chat_panel.delete_message_confirm.confirm') }}
      p {{ $t('conversation_tab.chat_panel.delete_message_confirm.warning') }}
      message(v-if="message" :message="message")

    template(v-slot:footer)
      .dialog-footer
        aiq-button(@click="$emit('cancel')") {{ $t('global.cancel') }}
        aiq-button(:disabled="!message" 
                  type="danger"
                  @click="$emit('confirm', message)") {{ $t('global.delete') }}
</template>

<script>
export default {
  name: 'DeleteMessageConfirm',
  props: {
    message: {
      type: Object,
    }
  },
  emits: ['confirm', 'cancel']
};
</script>

<style lang="scss">
.delete-message-confirm-dialog {
  position: relative;
  left: unset;
  transform: unset;
  .message-list_item {
    margin-top: 15px;
  }
  .message-item {
    justify-content: center
  }
  .el-dropdown {
    min-width: 80%
  }
}
</style>
