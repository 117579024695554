export const workflowStatuses = {
  NONE: 0,
  SUCCESS: 1,
  MAIN: 2,
  END: 3,
  FAILURE: 4,
};

export const dialogStates = {
  TEST: -1,
  NONE: 0,
  INIT: 1,
  CONFIRM: 2,
  ASK_ENTITY: 3,
  ASK_ALTERNATIVE: 4,
  SUCCESS: 5,
  FAILURE: 6,
};
