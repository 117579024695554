import COLORS from './colors';

const activeCustomersChartConfig = {
  name: 'active_customers',
  title: 'Active Customers',
  metric: 'active_customers',
  lines: {
    daily: {
      label: 'Daily Count',
      position: 1,
      color: COLORS.yellow,
    },
    weekly: {
      label: 'Weekly Count',
      position: 2,
      color: COLORS.red,
    },
    monthly: {
      label: 'Monthly Count',
      position: 3,
      color: COLORS.blue,
    },
  },
  helpText: 'metrics_tab.plaform_tab.summary_tab.active_customers.help',
  maxTickLabelCount: 8
};

export default activeCustomersChartConfig;
