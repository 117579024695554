import get from 'lodash/get';
import { getUrl } from '@/config/api.routes';

// TODO WIP util for extracting error message from response
// Backend is currently migrating validation to swagger, which will
// let us remove mose of this logic
export const extractErrorMessage = (response) => {
  let message;
  const bodyError = get(response, 'data.error');
  if (bodyError && typeof bodyError === 'string') {
    message = bodyError;
  }

  if (!message) {
    const bodyValues = Object.values(get(response, 'data', {}));
    message = bodyValues.length ? bodyValues.join('. ') : null;
    if (message === Object().toString()) {
      message = null;
    }
  }

  return message;
};

export const mapformToTriggerEndpoint = (formName, store) => {
  const conversationId = store.getters['conversations/selected'].id;
  return `${getUrl('formTrigger')}/${conversationId}/surveys/${formName.toLowerCase()}`;
};
