<template lang="pug">
.conversation-info-details-item
  aiq-tooltip(effect="dark",
              :content="tooltipText",
              placement="left")
    h3 {{ title }}
  .conversation-meta-list-container.scroll
    aiq-tag(v-for="item of selectedList", type="info" :key="item.id", :closable="true", :close-transition="true", @close="remove(item.id)") {{ item.name }}
  aiq-select(size="small"
              popper-class="conversation-meta-select"
              :placeholder="placeholderText"
              multiple
              :reserve-keyword="false"
              filterable
              v-model="selectData"
              @change="change"
              :suffix-icon="Icon.CaretBottom"
              :allow-create="allowCreate"
              default-first-option)
    aiq-option(v-for="option of list", :key="option.id", :value="option.id", :label="option.name")
</template>

<script>
export default {
  name: 'conversationMetaList',
  data() {
    return {
      selectData: [],
    };
  },
  props: {
    allowCreate: {
      type: Boolean,
      default: () => false,
    },
    selectedList: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      required: true,
      default: () => [],
    },
    title: {
      type: String,
    },
    placeholderText: {
      type: String,
    },
    tooltipText: {
      type: String,
    },
  },
  emits: ['add', 'remove', 'create'],
  methods: {
    remove(id) {
      this.$emit('remove', id);
    },

    add(items) {
      this.$emit('add', items);
    },

    create(item) {
      this.$emit('create', item);
    },

    change(data) {
      if (!data.length) return;
      const item = data[0];
      this.selectData = [];

      if (typeof item === 'string') {
        this.create(item);
      } else {
        this.add([...this.selectedList, { id: item }]);
      }
    },

    clear() {},
  },
};
</script>

<style lang="scss" scoped>
@import "./../../../../../styles/aiq-variables.scss";

.conversation-info-details-item {
  border-top: 1px solid $aiq-bdc-conversation-info-item;
}
h3 {
  padding: 10px 0;
  color: #566682;
  font-size: 14px;
  line-height: 14px;
  font-weight: 300;
}
.conversation-meta-list-container {
  max-height: 78px;
}
.el-tag {
  margin: 0 4px 1px 0;
}
.el-select {
  margin: 6px 0 10px;
}
</style>
