<template lang="pug">
.oauth-configuration
  .form-container
    .sub-header--solid {{ title }}
    .form__inner-content
      .form-field-container
        .form-field-label Authorization Endpoint
        aiq-input(v-model="config.authorization_endpoint"
                  placeholder="http://idp.com/auth"
                  size="small"
                  @change="onChange('authorization_endpoint', $event)")
      .form-field-container
        .form-field-label Token Endpoint
        aiq-input(v-model="config.token_endpoint"
                  placeholder="http://idp.com/auth/token"
                  size="small"
                  @change="onChange('token_endpoint', $event)")
      .form-field-container
        .form-field-label UserInfo Endpoint
        aiq-input(v-model="config.userinfo_endpoint"
                  placeholder="http://idp.com/auth/userinfo"
                  size="small"
                  @change="onChange('userinfo_endpoint', $event)")
      .form-field-container
        .form-field-label Client ID
        aiq-input(v-model="config.client_id"
                  placeholder="Client id"
                  size="small"
                  @change="onChange('client_id', $event)")
      .form-field-container
        .form-field-label Use PKCE
        aiq-switch(v-model="config.use_pkce", style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA" @change="onChange('use_pkce', $event)")
      .form-field-container(v-if="config.use_pkce")
        .form-field-label Code Challenge Method
        aiq-input(v-model="config.code_challenge_method"
                  placeholder="S256"
                  size="small"
                  @change="onChange('code_challenge_method', $event)")
      .form-field-container(v-if="!config.use_pkce")
        .form-field-label Client Secret
        .secret-input
          aiq-input(v-if="isSecretVisble" v-model="config.client_secret"
                    placeholder="Client secret"
                    size="small"
                    @change="onChange('client_secret', $event)")
          aiq-input(v-if="!isSecretVisble" v-model="config.client_secret"
                    placeholder="Client secret"
                    size="small" type="password"
                    @change="onChange('client_secret', $event)")
          span.show-password(@click="toggleSecretVisble")
            i(class="el-icon-view")
      .form-field-container
        .form-field-label Grant Type
        aiq-input(v-model="config.grant_type"
                  placeholder="authorization_code"
                  size="small"
                  @change="onChange('grant_type', $event)")
      .form-field-container
        .form-field-label Response Type
        aiq-input(v-model="config.response_type"
                  placeholder="code"
                  size="small"
                  @change="onChange('response_type', $event)")
      .form-field-container
        .form-field-label Scope
        aiq-input(v-model="config.scope"
                  placeholder="openid email profile"
                  size="small"
                  @change="onChange('scope', $event)")
      .form-field-container
        .form-field-label Redirect Url
        aiq-input(v-model="config.redirect_uri"
                  placeholder="http://chat-service/auth/callback"
                  size="small"
                  @change="onChange('redirect_uri', $event)")

</template>
<script>
export default {
  name: 'o-auth-configiration',
  props: {
    config: {
      type: Object,
      default: {
        authorization_endpoint: '',
        token_endpoint: '',
        userinfo_endpoint: '',
        use_pkce: false,
        code_challenge_method: '',
        client_id: '',
        client_secret: '',
        scope: '',
        grant_type: '',
        response_type: '',
        redirect_uri: '',
      },
    },
    title: {
      title: String,
      default: '',
    },
  },
  emits: ['change'],
  data() {
    return {
      isSecretVisble: false,
    };
  },
  methods: {
    toggleSecretVisble() {
      this.isSecretVisble = !this.isSecretVisble;
    },
    onChange(field, value) {
      this.$emit('change', { ...this.config, [field]: value });
    },
  },
};
</script>

<style lang="scss" scoped>
.form__inner-content {
  background: #FFFFFF;
  border: 1px solid #E0E6ED;
  display: flex;
  flex-direction: column;
  padding: 18px;
}

</style>
