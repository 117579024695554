import ViewFilePanel from './ViewFilePanel.vue';

export default {
  // accept all the path behind files/view
  path: '/files/view/:key(.+)*',
  name: 'ViewFile',
  component: ViewFilePanel,
  props: false,
  meta: {
    auth: true,
    basePath: '/files',
    permissioned: false,
  },
};
