<template lang="pug">
  .content-inner
    .field-container
      label Out of Office
      aiq-switch(active-text="",
                 inactive-text="",
                 style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA"
                 name="busy_status.status"
                 @change="onChange('status', $event)",
                 :disabled="disabled"
                 :model-value="outStatus")

    .field-container.is-required
      label Out of Office Message
      aiq-input(:model-value="explanation",
                size="small",
                type="textarea"
                name="busy_status.vacation.message_to_customer"
                :disabled="disabled || !outStatus"
                @input="onChange('message', $event)")

    aiq-row.field-container
      aiq-col(:span="12").sub-container
        label Inactivity Timeout
        aiq-switch(@change="toggleInactivity"
                  style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA"
                  name="inactivity.enabled"
                  :disabled="disabled"
                  :model-value="inactivityConfig.enabled")
      aiq-col(v-if="inactivityConfig.enabled" :span="12")
        label After (minutes) 
        aiq-input(v-model="localDuration"
                  size="small"
                  name="inactivity.duration"
                  placeholder="minutes"
                  :disabled="disabled"
                  @change="onInactivityDurationChange")

    aiq-row.field-container
      aiq-col(:span="12").sub-container
        label Hide Closed Conversations
        aiq-switch(
                  style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA"
                  :model-value="hideClosedConversations"
                  @update:modelValue="onHideClosedConversationsChange",
                  :disabled="disabled"
                  name="hide_closed_conversations")


</template>

<script>
import get from 'lodash/get';
import set from 'lodash/set';
import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';

const requiredWhenOOO = (value, siblings) => {
  return siblings.outStatus ? helpers.req(value) : true;
};

export default {
  setup () {
    return { v$: useVuelidate() };
  },
  validations () {
    return {
      outStatus: { $autoDirty: true },
      explanation: { $autoDirty: true, requiredWhenOOO } ,
      localDuration: { $autoDirty: true },
      inactivityConfig: {
        enabled: { $autoDirty: true },
      },
      record: {
        preferences: {
          hide_closed_conversations: { $autoDirty: true },
        },
      },
    };
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ['change'],
  computed: {
    outStatus() {
      return get(this.record, 'busy_status.status', '') === 'vacation';
    },

    explanation() {
      return get(this.record, 'busy_status.vacation.message_to_customer', '');
    },

    inactivityConfig() {
      return get(this.record, 'inactivity', {});
    },

    hideClosedConversations() {
      return get(this.record, 'preferences.hide_closed_conversations', false);
    },
  },
  data() {
    return {
      localDuration: get(this.record, 'inactivity.duration', 0),
    };
  },
  methods: {
    onChange(field, value) {
      const change = get(this.record, 'busy_status', {});
      if (field === 'status') {
        set(this.record, 'busy_status.status', value ? 'vacation' : '');
      } else if (field === 'message') {
        set(this.record, 'busy_status.vacation.message_to_customer', value);
      }
      this.$emit('change', change);
    },

    updateInactivityConfig(key, val) {
      set(this.record, `inactivity.${key}`, val);
      this.$emit('change', { key, val });
    },

    toggleInactivity() {
      this.updateInactivityConfig('enabled', !this.inactivityConfig.enabled);
    },

    onInactivityDurationChange(value) {
      try {
        const duration = parseInt(value, 10);
        if (Number.isNaN(duration)) {
          throw new Error(`${value} is not a number`);
        }
        this.localDuration = duration;
        this.updateInactivityConfig('duration', duration);
      } catch (err) {
        this.$aiq.notify.error(err.message);
      }
    },

    onHideClosedConversationsChange(value) {
      set(this.record, 'preferences.hide_closed_conversations', value);
      this.$emit('change', { key: 'hide_closed_conversations', value });
    },
  },
};
</script>

<style lang="scss" scoped>
.field-container {
  margin: 10px 5px;

  .el-switch {
    margin-left: 30px;
  }

  .sub-container {
    margin-top: 10px;
    margin-bottom: 14px;
  }

  .el-input {
    width: 50%;
    margin: 0px 0px 0px 30px;
  }
}
</style>
