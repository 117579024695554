<template lang="pug">
.content-empty-container
  span.content-empty-item
    i.iq-ico-arrow
    | Select or create an item.
</template>

<script>
export default {
  name: 'contentEmpty',
};
</script>

<style lang="scss" scoped>
</style>
