import get from 'lodash/get';
import has from 'lodash/has';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import {
  Events, NOTIFICATION, dispatchBrowserNotification,
} from '@/libs';
import { CONNECTION_STATE } from '@/constants/network';

export default {
  state: {
    connect: false,
    authenticated: false,
    statsTimeoutEntity: null,
    state: CONNECTION_STATE.NOT_INITIATED,
    reconnectAttempt: 0,
    disconnectedTime: -1,
    connectedTime: -1,
    lastReconnectingTime: -1,
    lastSocketError: null,
  },

  mutations: {
    SOCKET_connect(state) {
      state.connect = true;
      state.connectedTime = Date.now();
      state.state = CONNECTION_STATE.READY;
    },

    SOCKET_disconnect(state) {
      state.connect = false;
      state.authenticated = false;
      state.disconnectedTime = Date.now();
      state.state = CONNECTION_STATE.DISCONNECTED;
    },

    SOCKET_reconnecting(state) {
      state.state = CONNECTION_STATE.RECONNECTING;
      state.lastReconnectingTime = Date.now();
    },

    SOCKET_reconnect_error(state, error) {
      state.state = CONNECTION_STATE.ERROR;
      state.lastSocketError = error;
    },

    SOCKET_reconnect_failed(state) {
      state.state = CONNECTION_STATE.ERROR;
    },

    SOCKET_connect_error(state, error) {
      state.state = CONNECTION_STATE.ERROR;
      state.lastSocketError = error;
    },

    SOCKET_connect_timeout(state) {
      state.state = CONNECTION_STATE.ERROR;
    },

    SET_TIMEOUT_ENTITY(state, entity) {
      if (state.statsTimeoutEntity) {
        clearTimeout(state.statsTimeoutEntity);
      }

      state.statsTimeoutEntity = entity;
    },
  },
  actions: {
    'socket_message': ({ commit, rootState, dispatch }, message) => {
      // TODO (Gabe) - Handle potential case of chat panel opened while new conversation created
      if (rootState.conversations.selected.customer_id === message.conversation.customer_id) {
        commit('conversations/ADD_MESSAGE', message, { root: true });
        Events.emit('new.message');

        if (!isEqual(rootState.conversations.selected.ai_state, message.conversation.ai_state)) {
          const updatedConversation = {
            ...rootState.conversations.selected,
            ai_state: message.conversation.ai_state,
          };
          commit('conversations/UPDATE_SELECTED_CONVERSATION', [updatedConversation, rootState.agent.profile]);
        }
      }
      const isCustomerMsg = get(message, 'sender_type', null) === 'customers';

      const shouldUseSocketNotification =
        !rootState.featureFlags.FEATURE_FLAGS.USE_PUSH_NOTIFICATION;
      if (isCustomerMsg && shouldUseSocketNotification) {
        const notificationMode = rootState.featureFlags.FEATURE_FLAGS.NOTIFICATION_MODE;
        const notificationEvent = NOTIFICATION.EVENT_MESSAGE;
        const notificationIncludeAudio = rootState.featureFlags.FEATURE_FLAGS.NOTIFICATION_INCLUDE_AUDIO; // eslint-disable-line max-len
        const notificationClickToClear = rootState.featureFlags.FEATURE_FLAGS.CLICK_TO_CLEAR_NOTIFICATION; // eslint-disable-line max-len
        dispatchBrowserNotification(dispatch, message,
          { notificationMode,
            notificationEvent,
            notificationIncludeAudio,
            notificationClickToClear });
      }
    },

    'socket_conversations.messages.update': ({ commit, rootState }, message) => {
      if (rootState.conversations.selected.customer_id === message.conversation.customer_id) {
        commit('conversations/REPLACE_MESSAGE', message, { root: true });
      }
    },

    'socket_conversations.lock': ({ commit, rootState }, { conversation }) => {
      commit('conversations/UPDATE_LOCK_STATUS', [conversation, rootState.agent.profile], { root: true });
    },

    'socket_conversations.unlock': ({ commit, rootState }, { conversation }) => {
      commit('conversations/UPDATE_LOCK_STATUS', [conversation, rootState.agent.profile], { root: true });
      commit('agent/UNLOCK_CONVERSATION', conversation.id, { root: true });
    },

    'socket_conversations.takeover': ({ commit }, { conversation }) => {
      commit('agent/UNLOCK_CONVERSATION', conversation.id, { root: true });
    },

    'socket_start-typing': ({ commit, rootState }, e) => {
      const account = has(e, 'customer') ? get(e, 'customer', {}) : get(e, 'agent');
      const selectedId = get(rootState.conversations, 'selected.id', -1);
      const conversationId = get(e, 'conversation.id');
      const profileId = get(rootState.agent, 'profile.id', -1);
      const accountId = get(account, 'id');

      if (selectedId === conversationId && profileId !== accountId) {
        const params = [true, account, has(e, 'customer') ? 'customer' : 'agent'];
        commit('messages/TYPING_STATUS', params, { root: true });
      }
    },

    'socket_stop-typing': ({ commit, rootState }, e) => {
      const selectedId = get(rootState.conversations, 'selected.id', -1);
      const conversationId = get(e, 'conversation.id');
      const profileId = get(rootState.agent, 'profile.id', -1);
      const agentId = get(e, 'agent.id');

      if (selectedId === conversationId && profileId !== agentId) {
        commit('messages/TYPING_STATUS', [false, null, null], { root: true });
      }
    },

    'socket_conversations.suggest': ({ commit }, e) => {
      const conversation_id = get(e, 'conversation.id', -1);
      const suggestions = get(e, 'conversation.suggestions', []);

      commit('conversations/UPDATE_SUGGESTIONS_LIST', [conversation_id, suggestions], { root: true });
    },

    'socket_conversations.teams.attach': ({ commit, rootState }, ev) => {
      const e = get(ev, 'conversation');
      const selectedConversation = rootState.conversations.selected;
      if (selectedConversation.id === e.id) {
        const updatedTeams = uniqBy(selectedConversation.teams.concat(e.teams), 'id');
        commit('conversations/UPDATE_TEAMS', [e.id, updatedTeams]);
      }
    },

    'socket_conversations.teams.detach': ({ commit, rootState }, ev) => {
      const e = get(ev, 'conversation');
      const selectedConversation = rootState.conversations.selected;
      if (selectedConversation.id !== e.id) {
        return;
      }
      commit('conversations/UPDATE_TEAMS', [e.id, e.teams]);
    },

    'socket_conversations.categories.attach': ({ commit }, e) => {
      const { categories, conversation } = e;
      const updatedCategories = uniqBy(conversation.categories.concat(categories), 'id');
      commit('conversations/UPDATE_CATEGORIES', [conversation.id, updatedCategories]);
    },
    'socket_conversations.categories.detach': ({ commit }, e) => {
      const { categories, conversation } = e;
      const idsToRemove = categories.map(category => category.id);
      const updatedCategories = conversation.categories.filter(
        category => !idsToRemove.includes(category.id),
      );
      commit('conversations/UPDATE_CATEGORIES', [conversation.id, updatedCategories]);
    },

    'socket_conversations.tags.attach': ({ commit, rootState }, e) => {
      const selectedConversation = rootState.conversations.selected;
      if (selectedConversation.id === e.conversation.id) {
        const updatedTags = uniqBy(selectedConversation.tags.concat(e.tags), 'id');
        commit('conversations/UPDATE_TAGS', [e.conversation.id, updatedTags]);
      }
    },

    'socket_conversations.tags.detach': ({ commit, rootState }, e) => {
      const selectedConversation = rootState.conversations.selected;
      if (selectedConversation.id !== e.conversation.id) {
        return;
      }
      const idsToRemove = e.tags.map(tag => tag.id);
      const updatedTags = selectedConversation.tags.filter(tag => !idsToRemove.includes(tag.id));
      commit('conversations/UPDATE_TAGS', [e.conversation.id, updatedTags]);
    },

    'socket_agents.updated': ({ commit, rootState }, { agent }) => {
      const rootAgentId = get(rootState.agent, 'profile.id', -1);
      if (rootAgentId === agent.id) {
        commit('agent/UPDATE_AGENT_PROFILE', agent, { root: true });
      }

      commit('agents/UPDATE_AGENT', agent, { root: true });
    },

    'socket_customers.updated': ({ commit }, { customer }) => {
      commit('conversations/UPDATE_CUSTOMER', [customer]);
    },

    'socket_conversations.agents.attach': ({ commit, rootState, dispatch }, e) => {
      commit('conversations/UPDATE_AGENTS',
        [e.conversation.id,
          e.conversation.agents,
          e.conversation.customer_id]);

      const updatedAgents = get(e, 'agents', []);
      const currentAgentId = get(rootState, 'agent.profile.id', -1);
      const shouldUseSocketNotification =
        !rootState.featureFlags.FEATURE_FLAGS.USE_PUSH_NOTIFICATION;
      // Notify only if the current agent is added
      if (updatedAgents.includes(currentAgentId) && shouldUseSocketNotification) {
        const notificationMode = rootState.featureFlags.FEATURE_FLAGS.NOTIFICATION_MODE;
        const notificationEvent = NOTIFICATION.EVENT_ASSIGNMENT;
        const notificationIncludeAudio = rootState.featureFlags.FEATURE_FLAGS.NOTIFICATION_INCLUDE_AUDIO; // eslint-disable-line max-len
        const notificationClickToClear = rootState.featureFlags.FEATURE_FLAGS.CLICK_TO_CLEAR_NOTIFICATION; // eslint-disable-line max-len
        dispatchBrowserNotification(dispatch, e,
          { notificationMode,
            notificationEvent,
            notificationIncludeAudio,
            notificationClickToClear });
      }
    },
    'socket_customers.agents.attach': ({ commit, rootState }, { customer, agent }) => {
      const agentObj = rootState.agents.list.find(a => a.id === agent);

      commit('conversations/UPDATE_CUSTOMER_PRIMARY_AGENT', [customer, agentObj]);
    },
    'socket_conversations.agents.detach': ({ commit }, e) => {
      commit('conversations/UPDATE_AGENTS',
        [e.conversation.id,
          e.conversation.agents,
          e.conversation.customer_id]);
    },
    'socket_conversations.assets.attach': ({ commit, rootState, dispatch }, e) => {
      const selectedConversation = rootState.conversations.selected;
      if (selectedConversation.id === e.conversation.id) {
        commit('conversations/SET_SUGGEST_ITEM_HISTORY', [e.conversation, e.assets, 'assets']);

        // todo optimize by first searching loaded assets
        let { assets } = e;
        assets = assets.map(asset => asset.payload);
        return Promise.all(assets.map(asset => dispatch('assets/getAsset', [asset.id])))
          .then(([...data]) => {
            const fetchedAssets = data.map(({ body }) => body);
            commit('conversations/UPDATE_ASSETS', [e.conversation.id, fetchedAssets]);
          });
      }
    },

    'socket_conversations.documents.attach': ({ commit, rootState }, e) => {
      const selectedConversation = rootState.conversations.selected;
      if (selectedConversation.id === e.conversation.id) {
        commit('conversations/SET_SUGGEST_ITEM_HISTORY', [e.conversation, e.documents, 'documents']);
      }
    },

    'socket_conversations.close': ({ commit }, { conversation }) => {
      commit('conversations/CLOSE_CONVERSATION', conversation);
    },

    'socket_customers.messages.obfuscate': ({ commit }, e) => {
      commit('conversations/REPLACE_MESSAGE', e, { root: true });
    },
    'socket_conversations.messages.delete': ({ commit, rootState, dispatch }, e) => {
      commit('conversations/REPLACE_MESSAGE', e, { root: true });
      if (has(e, 'payload.rich_text') && rootState.conversations.selectedCustomer) {
        dispatch('conversations/getAttachments', {
          customerId: rootState.conversations.selectedCustomer.id,
          mode: rootState.conversations.attachmentsHistory.mode,
        });
      }
    },
    // read event should let conversation store know customer read messages
    'socket_customers.conversations.event': ({ dispatch }, e) => {
      if (get(e, 'event.payload.event.action') === 'messages.mark.read'
        && get(e, 'event.sender_type') === 'customers') {
        dispatch('conversations/markConversationReadByCustomer', e);
      }
    },
  },
};
