<template lang="pug">
  div
    .customer-info-basic
      h3(v-if="includeDetails").customer-info-basic_title Basic Info
      .customer-info-basic_field(v-for="mapping in profileMappingWithPrimaryAgent")
        label {{mapping.label}}:
        .string-list-type(v-if="isMappingTypeArray(mapping)")
          .non-editable(v-for="value in getProfileValue(mapping)")
            .noneditable {{ value }}
        .string-list-type(v-else-if="isMappingTypeJson(mapping)")
          aiq-button(size="small" @click="showJson(mapping)" :disabled="disable") Show Data
          aiq-dialog(
            title="Structured Data"
            :model-value="showJsonViwer"
            @close="closeJsonViwer")
            JsonViewer(:value="jsonViwerData"
              :expand-depth=5
              boxed
              sort)
            template(v-slot:footer)
              aiq-button(@click="closeJsonViwer") Close
        .string-list-type(v-else-if="isMappingTypeObjectTable(mapping)")
          aiq-button(size="small" @click="showObjectTable(mapping)" :disabled="disable") Show Data
          aiq-dialog(
            title="List"
            :model-value="showTableViewer"
            @close="closeTableViwer" width="80%")
            template(v-slot:body)
              object-table(:value="tableData")
            template(v-slot:footer)
              aiq-button(@click="closeTableViwer") Close
        .string-type(v-else)
          .editable(v-if="!isFieldNotEditable(mapping) && includeDetails")
            aiq-input(v-model="fieldValues[mapping.label]"
                      size="small"
                      @focus="updateLocalStr(getProfileValue(mapping))"
                      @change="updateLocalStr"
                      @blur="updateFieldIfChanged(mapping)"
                      @keyup.enter="blur")

          .non-editable(v-else)
            a(v-if="isExternalID(mapping) && hasCustomerLink" :href="customerRecordLink" target="_blank" rel="noopener noreferrer") {{ customerRecord }}
            .noneditable(v-else) {{ getProfileValue(mapping) }}

        .syncable(v-if="isFieldSyncable(mapping)")
          SyncIcon.sync(v-if="!isSyncing(mapping)" @click="onSync(mapping)")
          i.el-icon-loading(v-else @click="onSync(mapping)")

    br
    .customer-info-basic(v-if="includeDetails")
      h3.customer-info-basic_title Details
      .customer-info-basic_field(v-for="info in details")
        label {{returnLocalizedLabel(info.name)}}:
        .noneditable {{ info.resolved_value }}
</template>

<script>
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { PROFILE_EDITOR_TYPE } from '@/constants/settings';
import SyncIcon from '@/assets/svg/sync.vue';
import JsonViewer from 'vue3-json-viewer';
import ObjectTable from './ObjectTable.vue';


const PRIMARY_AGENT_LABEL = 'Primary Agent';
const CUSTOMER_SEGMENT_FIELD = 'customer_segments';
const CUSTOMER_SEGMENT_FIELD_LEGACY = 'customer_segment';
const PRIMARY_AGENT_ITEM_ORDER = 3;

export default {
  components: {
    SyncIcon,
    JsonViewer,
    ObjectTable,
  },
  data() {
    return {
      localString: '',
      syncingMapping: null,
      showJsonViwer: false,
      showTableViewer: false,
      jsonViwerData: null,
      tableData: null,
      fieldValues: {},
    };
  },
  props: {
    customerRecord: {
      type: String,
      default: '',
    },
    customerRecordLink: {
      type: String,
      default: '',
    },
    primaryAgent: {
      type: String,
    },
    entities: {
      type: Array,
      default: () => [],
    },

    profileMapping: {
      type: Array,
      default: [],
    },
    profile: {
      type: Object,
      default: {},
    },
    customerSegment: {
      type: String,
      default: '',
    },
    customer: {
      type: Object,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    includeDetails: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['updateField'],
  computed: {
    hasCustomerLink() {
      return Boolean(this.customerRecordLink);
    },

    profileMappingWithPrimaryAgent() {
      // Primary agent is a one-off property that comes from non profile.
      // Here it just tweak adding primary agent into list as 4th element
      // and hide its handling to parent.
      const changedMapping = cloneDeep(this.profileMapping);
      changedMapping.splice(PRIMARY_AGENT_ITEM_ORDER, 0, {
        type: 'local',
        label: PRIMARY_AGENT_LABEL,
        editable: false,
      });
      return changedMapping;
    },

    details() {
      const filteredEntities = [];
      const seen = {};

      this.entities.forEach(entity => {
        // Skip entities that have sub categories like customer.first_name
        // or product.brand_name for now.
        if (/[[A-Za-z_]+(\.[A-Za-z_]+)+/.test(entity.name)) {
          return;
        }

        // Skip duplicate entities
        if (seen[entity.name]) {
          return;
        }

        seen[entity.name] = true;

        // Create display name that has first letter upper case
        const displayName = entity.name.split('_').map(word => word.charAt(0).toUpperCase() + word.substr(1));

        filteredEntities.push({
          name: displayName.join(' '),
          resolved_value: entity.resolved_value,
        });
      });

      return filteredEntities;
    },
  },
  watch: {
    details() {
      this.updateFieldValues();
    },
  },
  mounted() {
    this.updateFieldValues();
  },
  methods: {
    isMappingTypeArray(mapping) {
      return get(mapping, 'type', PROFILE_EDITOR_TYPE.STRING) === PROFILE_EDITOR_TYPE.STRING_LIST;
    },

    isMappingTypeJson(mapping) {
      return get(mapping, 'type', PROFILE_EDITOR_TYPE.STRING) === PROFILE_EDITOR_TYPE.JSON;
    },

    isMappingTypeObjectTable(mapping) {
      return get(mapping, 'type', PROFILE_EDITOR_TYPE.STRING) === PROFILE_EDITOR_TYPE.OBJECT_TABLE;
    },

    blur(evt) {
      evt.target.blur();
    },

    isFieldNotEditable(mapping) {
      return !mapping.editable;
    },

    isFieldSyncable(mapping) {
      return mapping.syncable;
    },

    updateFieldIfChanged(mapping) {
      if (this.getProfileValue(mapping) !== this.localString) {
        this.$emit('updateField', { mapping, value: this.localString.trim() });
      }
      this.localString = '';
    },

    updateFieldValues() {
      this.fieldValues = {};
      for (const m of this.profileMappingWithPrimaryAgent) {
        if (m.label) {
          this.fieldValues[m.label] = this.getProfileValue(m);
        }
      }
    },

    getProfileValue(mapping) {
      const val = this.testgetProfileValue(mapping);
      return val;
    },

    testgetProfileValue(mapping) {
      const type = get(mapping, 'type', PROFILE_EDITOR_TYPE.STRING);
      if (type === 'local' && mapping.label === PRIMARY_AGENT_LABEL) {
        return this.primaryAgent;
      }
      if (mapping.field === CUSTOMER_SEGMENT_FIELD || mapping.field === CUSTOMER_SEGMENT_FIELD_LEGACY) {
        return this.customerSegment;
      }

      if (type === PROFILE_EDITOR_TYPE.CONCAT_STRING) {
        const strs = [];

        for (const subMapping of get(mapping, 'fields', [])) {
          strs.push(get(this.profile, subMapping.field, ''));
        }

        return strs.join(' ').trim();
      }

      return get(this.profile, mapping.field, '');
    },

    isExternalID(mapping) {
      // Not great way but external id has extra functionality to link achor
      return get(mapping, 'field', '') === 'external_id';
    },

    updateLocalStr(value) {
      this.localString = value;
    },

    async onSync(mapping) {
      this.syncingMapping = mapping;
      try {
        // do backend sync!
        const value = get(await this.$store.dispatch('conversations/sendSyncRequest',
          { args: { customer: this.customer }, mapping }), 'value');

        if (value === undefined) {
          throw new Error('Api returned nothing.');
        }

        this.$emit('updateField', { mapping, value });
        this.$aiq.notify.success('Successful');
      } catch (err) {
        this.$aiq.notify.error(`Failed: ${err.message}`);
      }
      this.syncingMapping = null;
    },

    isSyncing(mapping) {
      return isEqual(mapping, this.syncingMapping);
    },

    showJson(mapping) {
      if (mapping.field) {
        this.jsonViwerData = this.profile[mapping.field];
      }

      if (!this.jsonViwerData) {
        this.$aiq.notify.info('Press the refresh button first');
        return;
      }

      this.showJsonViwer = true;
    },

    showObjectTable(mapping) {
      if (mapping.field) {
        this.tableData = this.profile[mapping.field];
      }

      if (!this.tableData) {
        this.$aiq.notify.info('Press the refresh button first');
        return;
      }

      this.showTableViewer = true;
    },

    closeTableViwer() {
      this.showTableViewer = false;
      this.tableData = null;
    },

    closeJsonViwer() {
      this.showJsonViwer = false;
      this.jsonViwerData = null;
    },
    returnLocalizedLabel(label){
      return this.$t(`conversation_tab.side_panel.customer_info_accordion.section.detail.${label.replaceAll(' ','_').toLowerCase()}`, label);
    }
  },
};
</script>

<style lang="scss" scoped>
  .customer-info-basic {
    padding-bottom: 8px;
    border-bottom: 1px solid #BFCBD9;
  }
  .customer-info-basic_title {
    color: #48576A;
    font-size: 12px;
    line-height: 12px;
    font-weight: 300;
  }
  .customer-info-basic_field {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 8px;

    .editable {
      width: 180px;
    }
    .noneditable {
      font-size: 11px;
      font-weight: 300;
    }

    > .el-input {
      width: 210px;
    }

    > a {
      font-size: 11px;
    }

    > label {
      width: 97px;
      color: #324057;
      font-size: 11px;
      line-height: 13px;
      font-weight: 300;
    }
  }

  .non-editable {
    padding-left: 5px;
  }

  .string-type {
  }

  .syncable {
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
  }

  .sync {
    margin-top: 3px;
    width: 16px;
    height: 16px;
  }
</style>
