import axios from 'axios';
import merge from 'lodash/merge';

import { CRUDStoreBuilder } from '../StoreBuilder';
import { getUrl } from '../../config/api.routes';

const entitySourceCRUD = new CRUDStoreBuilder(
  'aimanager.entity_sources',
  'entitySource',
  [],
  'data.data',
).createStore();

entitySourceCRUD.actions.updateEntitysource = ({ commit }, { id, item }) => axios.put(getUrl('aimanager.entity_sources', id), item)
  .then(data => {
    commit('PUT_ENTITYSOURCE_SUCCESS', data.data);
    return data;
  });

entitySourceCRUD.actions.createEntitysource = ({ commit }, params) => axios.post(getUrl('aimanager.entity_sources'), params)
  .then(data => {
    commit('POST_ENTITYSOURCE_SUCCESS', data.data);
    return data;
  });

entitySourceCRUD.mutations.POST_ENTITYSOURCE_SUCCESS = (state, item) => {
  state.entitySources.unshift(item);
};

entitySourceCRUD.mutations.PUT_ENTITYSOURCE_SUCCESS = (state, item) => {
  state.entitySources = state.entitySources.map(source => (source.id === item.id ? item : source));
};

export default merge(entitySourceCRUD, {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {},
});
