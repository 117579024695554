<template lang="pug">
layout-main(:showSidebar="false" :hideHeader="hideHeader")
  managementPageWithActions.scrollable(:title="$t('customer_tab.title')"
                classPrefix="customer-info"
                @fetchData="loadList"
                @chooseAction="chooseAction"
                @removePreFilter="removePreFilter"
                @loadAll="loadAll",
                @downloadData="downloadData"
                @stopDataLoading="stopLoadAll",
                @toggleOpen="toggleOpen"
                :isDataLoading="isLoadingAll || isLoading",
                :preFilterText="preFilterText"
                :chosenAction="chosenAction"
                :loadWithQuery="$route.query.query"
                :searchPageSize="searchPageSize"
                :showDatePicker="true"
                :datePickerOptions="Object.keys(datePickerOptions)"
                :disabledDatePickerRange="disabledDatePickerRange"
                :customerAgentFilter="true"
                :customerAgentOptions="Object.keys(customerAgentOptions)"
                :customerChannelFilter="showChannelFilter"
                :customerChannelOptions="channelOptions"
                :customerSegmentFilter="true"
                :customerSegmentOptions="segments"
                :customerProfileFilter="!!profileSearchableFields.length"
                :customerProfileOptions="profileSearchableFields"
                :actionOptions="aclPermittedActions"
                :showTotals="true"
                :showLoadAll="true"
                :filteredSearch="false"
                :showDownloadButton="false"
                :pagination="pagination"
                :creatable="false"
                :helpText="helpText"
                :actionsErrorText="actionsErrorText"
                :hasRowSelections="hasRowSelections"
                :openFilters="isFilterOpen"
                ref="managementPage")

    template(v-slot:editor)
      actions-panel(v-if="actionFormShown"
                  v-bind="{...actionCardParams}"
                  @commitAction="commitAction"
                  @closeActionPanel="closeActionPanel")

    template(v-slot:body v-if="isMobileContext")
      aiq-table.customers-scrollbar(:data="customers"
                        style="width: 100%"
                        ref="customersTable"
                        :default-sort="defaultSortOrder"
                        v-loading="actionInProgress"
                        @selection-change="onSelect")
        aiq-table-column(type="selection",
                        width="48px",
                        label-class-name="is-bordered")
        aiq-table-column(type="expand")
          template(v-slot:default="item")
            p Email: {{ getCustomerEmail(item.row) }} &nbsp;
            p Joined Date: {{ getFormattedJoinedDate(item.row) }} &nbsp;
            p Customer Segment: {{ getFormattedCustomerSegment(item.row) }} &nbsp;
            p Primary Agent:
              aiq-button(link type="primary" size="small", @click="canView('/users') && filterToAgent(item.row.primary_agent_details)") {{ getPrimaryAgentName(item.row) }}
            p Assigned Agents:
              .tag-list
                aiq-button(v-for="agent in item.row.assigned_agents_details"
                        text
                        size="small",
                        class="agent-button"
                        :key="agent.id"
                        @click="canView('/users') && filterToAgent(agent)")
                  aiq-tag {{ getAssignedAgentName(agent) }}
        aiq-table-column(label="Name", :sortable="true", :sort-method="onSortName", label-class-name="is-bordered")
          template(v-slot:default="item")
            span {{ $filters.toUpperFirst(getCustomerName(item.row)) }} &nbsp;
        aiq-table-column(prop="last_chat_time"
                         label="Last Chat Time"
                         :sortable="true"
                         :sort-method="onSortByTime('last_chat_time')"
                         label-class-name="is-bordered")
          template(v-slot:default="item")
            aiq-button.date-button(link type="primary" size="small", @click="goToLastConversation(item.row)") {{ getFormattedLastChatTime(item.row) }}

    template(v-slot:body v-if="!isMobileContext")
      aiq-table.customers-scrollbar(:data="customers"
                      ref="customersTable"
                      :default-sort="defaultSortOrder"
                      v-loading="actionInProgress"
                      @selection-change="onSelect")
        aiq-table-column(type="selection",
                        width="48px",
                        label-class-name="is-bordered")
        aiq-table-column(:label="$t('customer_tab.table.columns.name')"
                         :sortable="true"
                         :sort-method="onSortName"
                         label-class-name="is-bordered")
          template(v-slot:default="scope")
            tooltip-info(:item="scope.row")
              template(v-slot:customerInfoItem) {{ $filters.toUpperFirst(getCustomerName(scope.row)) }} &nbsp;
        aiq-table-column(:label="$t('customer_tab.table.columns.email')"
                         :sortable="true"
                         :sort-method="onSortEmail"
                         label-class-name="is-bordered")
          template(v-slot:default="scope")
            span {{ getCustomerEmail(scope.row) }} &nbsp;
        aiq-table-column(prop="joined_date"
                         :label="$t('customer_tab.table.columns.joined_date')"
                         :sortable="true"
                         :sort-method="onSortJoinedDate"
                         label-class-name="is-bordered")
          template(v-slot:default="scope")
            span {{ getFormattedJoinedDate(scope.row) }} &nbsp;
        aiq-table-column(prop="last_chat_time"
                         :label="$t('customer_tab.table.columns.last_chat_time')"
                         :sortable="true"
                         :sort-method="onSortByTime('last_chat_time')"
                         label-class-name="is-bordered")
          template(v-slot:default="scope")
            aiq-button(link type="primary" size="small", @click="goToLastConversation(scope.row)") {{ getFormattedLastChatTime(scope.row) }}
        aiq-table-column(:label="$t('customer_tab.table.columns.segment')", :sortable="true", :sort-method="onSortCustomerSegment", label-class-name="is-bordered")
          template(v-slot:default="scope")
            span {{ getFormattedCustomerSegment(scope.row) }} &nbsp;
        aiq-table-column(:label="$t('customer_tab.table.columns.primary_agent')"
                         :sortable="true"
                         :sort-method="onSortPrimaryAgent"
                         label-class-name="is-bordered")
          template(v-slot:default="scope")
            aiq-button(link type="primary" size="small", class="agent-button", @click="canView('/users') && filterToAgent(scope.row.primary_agent_details)") {{ getPrimaryAgentName(scope.row) }}
        aiq-table-column(:label="$t('customer_tab.table.columns.assigned_agents')", :sortable="false", label-class-name="is-bordered")
          template(v-slot:default="scope")
            .tag-list
              aiq-button(v-for="agent in scope.row.assigned_agents_details"
                       text
                       size="small",
                       class="agent-button"
                       :key="agent.id"
                       @click="canView('/users') && filterToAgent(agent)")
                aiq-tooltip(:content="getAssignedAgentName(agent)")
                  aiq-tag {{ getAssignedAgentName(agent) }}

        aiq-table-column(:label="$t('customer_tab.table.columns.last_feedback_sent_at')"
                         :sortable="true"
                         :sort-method="onSortByTime('customer_feedback_details.last_feedback_sent_at')"
                         label-class-name="is-bordered")
          template(v-slot:default="scope")
            span {{ getFormattedFeedbackTime(scope.row, 'customer_feedback_details.last_feedback_sent_at') }} &nbsp;

        aiq-table-column(:label="$t('customer_tab.table.columns.last_feedback_completed_at')"
                         :sortable="true"
                         :sort-method="onSortByTime('customer_feedback_details.last_feedback_completed_at')"
                         label-class-name="is-bordered")
          template(v-slot:default="scope")
            span {{ getFormattedFeedbackTime(scope.row, 'customer_feedback_details.last_feedback_completed_at') }} &nbsp;


</template>

<script>
import { Component, Vue, toNative } from 'vue-facing-decorator';
import get from 'lodash/get';
import merge from 'lodash/merge';
import difference from 'lodash/difference';
import upperFirst from 'lodash/upperFirst';
import moment from 'moment';
import { mapActions, mapGetters, mapState } from 'vuex';

import ManagementPageWithActions from '@/components/ManagementPage/ManagementPageWithActions.vue';
import LayoutMain from '@/components/layouts/main.vue';
import ActionsPanel from '@/components/ActionsPanel.vue';
import { filterActionsByPermissions } from '../../libs/permissionsHelper';
import { formatCustomerSegmentsForDisplay } from '../../libs/customerSegments';
import { NON_DELETABLE_SEGMENTS } from '@/constants/segment';
import { CHANNELS } from '@/constants';
import { downloadAsFile, getLocalDateFormat } from '@/libs';
import TooltipInfo from './components/TooltipInfo.vue';

const SEARCH_PAGE_SIZE = 100;

const SEND_SURVEY_ACTION = 'Request Feedback';
const UPDATE_PRIMARY_AGENT = 'Update Primary Agent';
const UPDATE_CUSTOMER_SEGMENTS = 'Update Segments';
const SEND_MESSAGE = 'Send Message';
const UPDATE_ASSIGNED_AGENTS = 'Update Assigned Agents';
const CLOSE_CONVERSATION = 'Close Conversation';
const SPECIAl_CHAR = /[!#$%^&*()=[\]{};:"\\|,<>?`]+/;


const ACTION_OPTIONS = {
  [UPDATE_PRIMARY_AGENT]: {},
  [SEND_MESSAGE]: {},
  [SEND_SURVEY_ACTION]: {},
  [UPDATE_CUSTOMER_SEGMENTS]: {},
  [UPDATE_ASSIGNED_AGENTS]: {},
  [CLOSE_CONVERSATION]: {},
};

@Component({
  name: 'customers',
  components: {
    ManagementPageWithActions,
    LayoutMain,
    ActionsPanel,
    TooltipInfo,
  },
  props: {
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['fetchData'],
  watch: {
    filterByAgent() {
      this.$emit('fetchData');
    },
  },
  computed: {
    ...mapState({
      permissions: state => state.agent.permissions,
      agents: state => state.agents.agents,
      isMobileContext: state => state.settings.isMobileContext,
      channelOptions: state => state.conversations.channels,
      profileFields: state => state.configs.config.side_panel.profile_mapping,
      showChannelFilter: state => state.featureFlags.FEATURE_FLAGS.SHOW_CUSTOMER_CHANNEL_FILTER,
      showSmsChannel: s => s.featureFlags.FEATURE_FLAGS.ALLOW_SMS_CHANNEL_CUSTOMER_TAB_ACTIONS,
    }),
    ...mapGetters({
      customersWithoutFullname: 'customersDashboard/customers',
      selectedCustomers: 'customersDashboard/getSelectedCustomers',
      segments: 'configs/segmentNames',
      watchableTeamIds: 'agent/watchableTeamIds',
    }),
    customers() {
      return this.customersWithoutFullname
        .map(c => {
          c.fullName = c.fullName === ''
            ? this.$t('customer_tab.table.default_customer_name', { customer_id: c.id })
            : c.fullName;
          return c;
        });
    },
    profileSearchableFields() {
      // Searchable fields are those which can be used in customer query filters. They are profile
      // customizable bc no customizable are include by default in customer query filters
      return this.profileFields.filter(field => field.type === 'string' && field.customizable);
    },
    aclPermittedActions() {
      return filterActionsByPermissions(this.actionOptions, this.permissions);
    },
    actionCardParams() {
      let params = {
        actionValue: this.chosenAction,
        items: this.selectedCustomers,
        showMessage: true,
      };

      if (this.chosenAction === SEND_SURVEY_ACTION) {
        const optionsValues = (this.localPostChatSurvey && this.localPostChatSurvey.survey_type) ?
          [this.localPostChatSurvey.survey_type.toUpperCase()] : ['CSAT', 'NPS'];
        params = merge(params, {
          showOptions: true,
          showMessage: false,
          requireOption: true,
          optionsValues,
        });
      }

      if (this.chosenAction === UPDATE_PRIMARY_AGENT) {
        params = merge(params, {
          optionsLabelText: 'New Primary Agent:',
          showOptions: true,
          optionsValues: this.agents.map(a => a.fullName),
          allowEmptyOption: true,
          requireOption: false,
          showAttachmentButton: this.canView('/conversations/inputbox/upload'),
        });
      }

      if (this.chosenAction === SEND_MESSAGE) {
        let channels = this.getChannels(this.showSmsChannel ? [CHANNELS.SMS] : []);
        if (
          !this.showSmsChannel ||
            Object.values(this.selectedCustomers).some(c => !c.profile.phone)
        ) {
          channels = channels.filter(c => c !== CHANNELS.SMS);
        }
        params = merge(params, {
          optionsLabelText: 'Channel:',
          showOptions: true,
          optionsValues: channels,
          allowEmptyOption: false,
          requireOption: true,
          requireMessage: true,
          showAttachmentButton: this.canView('/conversations/inputbox/upload'),
        });
      }

      if (this.chosenAction === UPDATE_CUSTOMER_SEGMENTS) {
        const optionsValues = ['attach', 'detach'];
        const multiOptionsValues = difference(this.segments, NON_DELETABLE_SEGMENTS);
        params = merge(params, {
          optionsLabelText: 'Mode:',
          showOptions: true,
          multiOptionsLabelText: 'Segments:',
          showMultiOptions: true,
          requireMultiOptions: true,
          optionsValues,
          multiOptionsValues,
          showMessage: false,
          requireOption: true,
        });
      }

      if (this.chosenAction === UPDATE_ASSIGNED_AGENTS) {
        const optionsValues = ['attach', 'detach'];
        const multiOptionsValues = this.agents.map(a => a.fullName);
        params = merge(params, {
          optionsLabelText: 'Mode:',
          showOptions: true,
          multiOptionsLabelText: 'Agents:',
          showMultiOptions: true,
          requireMultiOptions: true,
          optionsValues,
          multiOptionsValues,
          showMessage: false,
          requireOption: true,
        });
      }

      if (this.chosenAction === CLOSE_CONVERSATION) {
        params = merge(params, {
          showMessage: false,
        });
      }

      return params;
    },
    preFilterText() {
      if (this.filterByAgent) {
        return `Filter Agent: ${this.filterByAgent.fullName}`;
      }
      return '';
    },
    helpText() { return this.$t('customer_tab.actions.description_tooltip') },
    actionsErrorText() {
      const stringPath = 'customer_tab.actions.error_tooltip.';
      return {
        title: this.$t(`${stringPath}title`),
        description: this.$t(`${stringPath}description`),
      };
    },
    hasRowSelections() {
      return !!Object.keys(this.selectedCustomers).length;
    },
  },
  methods: {
    ...mapActions({
      getCustomers: 'customersDashboard/getCustomers',
      updateCustomerSegmentsAction: 'customersDashboard/updateCustomerSegments',
      updateCustomerAssignedAgentsAction: 'customersDashboard/updateCustomerAssigedAgents',
      updatePrimaryAgentFromSelectedCustomers: 'customersDashboard/updatePrimaryAgentFromSelectedCustomers',
      removePrimaryAgentFromSelectedCustomers: 'customersDashboard/removePrimaryAgentFromSelectedCustomers',
      selectCustomers: 'customersDashboard/selectCustomers',
      deselectAllCustomers: 'customersDashboard/deselectAllCustomers',
      sendMessageToSelectedCustomers: 'customersDashboard/sendMessageToSelectedCustomers',
      sendSurveyToSelectedCustomers: 'customersDashboard/sendSurveyToSelectedCustomers',
      closeConversationToSelectedCustomers: 'customersDashboard/closeConversationToSelectedCustomers',
    }),

    getCustomerName(customer) {
      return customer.fullName;
    },

    getCustomerEmail(customer) {
      return get(customer, 'profile.email', '');
    },

    getFormattedCustomerSegment(customer) {
      return formatCustomerSegmentsForDisplay(get(customer, 'customer_segments', []));
    },
    getFormattedJoinedDate(customer) {
      return moment(customer.created_at).format(getLocalDateFormat().replaceAll('/', '-'));
    },


    getFormattedLastChatTime(customer) {
      const lastChatTime = get(customer, 'last_chat_time');
      if (!lastChatTime) {
        return 'Lynq';
      }
      return moment(lastChatTime).format(getLocalDateFormat('LLL'));
    },
    getFormattedFeedbackTime(customer, path) {
      const time = get(customer, path);
      if (!time) {
        return '';
      }
      return moment(time).format(getLocalDateFormat('LLL'));
    },
    goToLastConversation(customer) {
      const lastConversationCustomerId = customer.id;
      if (!lastConversationCustomerId) {
        return;
      }
      this.$router.push({
        name: 'ConversationByCustomerId',
        params: { id: lastConversationCustomerId },
      });
    },

    getPrimaryAgentName(customer) {
      return upperFirst(get(customer, 'primary_agent_details.full_name', ''));
    },

    getAssignedAgentName(agent) {
      return upperFirst(get(agent, 'full_name', ''));
    },

    filterToAgent(agent) {
      const primaryAgentName = get(agent, 'full_name', '');
      if (primaryAgentName) {
        this.$router.push({
          name: 'Users',
          query: {
            query: primaryAgentName,
          },
        });
      }
    },

    closeActionPanel() {
      this.chosenAction = null;
      this.actionFormShown = false;
    },

    onSortName(a, b) {
      return get(a, 'fullName', '').toLowerCase().replace(/\s+/g, '') <= get(b, 'fullName', '').toLowerCase().replace(/\s+/g, '') ? 1 : -1;
    },
    onSortEmail(a, b) {
      return this.getCustomerEmail(a).toLowerCase() <= this.getCustomerEmail(b).toLowerCase() ? 1 : -1;
    },

    onSortJoinedDate(a, b) {
      return get(a, 'created_at', '') <= get(b, 'created_at', '') ? 1 : -1;
    },

    onSortByTime(path) {
      return (a, b) => {
        const leftTime = get(a, path);
        const rightTime = get(b, path);

        //on downword sort, undefined values should be top
        //on upward sort, undefined should be bottom
        if (!leftTime) {
          return 1;
        }

        if (!rightTime) {
          return -1;
        }

        return leftTime <= rightTime ? 1 : -1;
      };
    },

    onSortPrimaryAgent(a, b) {
      return this.getPrimaryAgentName(a) <= this.getPrimaryAgentName(b) ? 1 : -1;
    },

    onSortCustomerSegment(a, b) {
      return get(a, 'profile.customer_segment', '') <= get(b, 'profile.customer_segment', '') ? 1 : -1;
    },

    onSelect(selection = []) {
      if (selection.length > 0) {
        this.selectCustomers(selection);
      } else {
        this.deselectAllCustomers();
      }
    },

    getChannels(requiredChannels) {
      // Including webchat manually as the first array item to make it default selected
      return Array.from(new Set(['webchat', ...this.channelOptions, ...requiredChannels]));
    },
  },
})

class CustomersDashboard extends Vue {
  actionInProgress = true;
  chosenAction = null;
  pagination = {
    offset: 0,
    limit: 0,
    rowCount: 0,
  };
  params = {};
  isFilterOpen = false;
  actionFormShown = false;
  pageResource = '/customersdashboard';
  searchPageSize = SEARCH_PAGE_SIZE;
  datePickerOptions = {
    'Last Chat Time': 'last_chat_time',
    'Joined Date': 'joined_date',
    'Last Feedback Request': 'last_feedback_sent_at',
    'Last Feedback': 'last_feedback_completed_at',
  };
  disabledDatePickerRange = (time) => time.getTime() > moment().endOf('day');
  customerAgentOptions = {
    'Primary Selected': 'primary_selected',
    'Primary Not Selected': 'primary_not_selected',
    'Has Phone Number': 'has_phone_number',
    'No Messages Sent': 'no_messages_sent',
    Queued: 'queued',
    'Not Queued': 'not_queued',
    Unresponded: 'unresponded',
  };

  isLoadingAll = false;
  isLoading = false;

  actionOptions = {
    ...ACTION_OPTIONS,
    [UPDATE_PRIMARY_AGENT]: {
      commitActionFunction: this.updatePrimaryAgentFromCustomers,
    },
    [SEND_MESSAGE]: {
      commitActionFunction: this.sendMessageToCustomers,
    },
    [SEND_SURVEY_ACTION]: {
      commitActionFunction: this.sendSurveyToCustomers,
    },
    [UPDATE_CUSTOMER_SEGMENTS]: {
      commitActionFunction: this.updateCustomerSegments,
    },
    [UPDATE_ASSIGNED_AGENTS]: {
      commitActionFunction: this.updateAssignedAgents,
    },
    [CLOSE_CONVERSATION]: {
      commitActionFunction: this.closeConversationToCustomers,
    },
  };
  filterType = '';
  filterByAgent = null;
  defaultSortOrder = { prop: 'last_chat_time', order: 'ascending' };

  mounted() {
    this.filterType = this.$route.query['filter-type'];
    this.filterByAgent = this.$route.query['agent-id'] ?
      { id: this.$route.query['agent-id'], fullName: this.$route.query['agent-name'] } :
      null;
    this.$store.dispatch('configs/getPostChatSurvey').then(response => {
      this.localPostChatSurvey = response;
    });
    this.$store.dispatch('agents/getAgentsList', [{
      limit: 999999,
    }]);
    this.$store.dispatch('configs/getCustomerSegments');
    this.$store.dispatch('conversations/getChannelsList');
  }

  async loadList(params) {
    const {
      searchTerm,
      searchOffset,
      dateRange,
      datePickerOption,
      customerAgentOption,
      customerChannelOption,
      customerSegmentOption,
      customerProfileOption,
    } = params;


    if (SPECIAl_CHAR.test(searchTerm)){
      this.$aiq.notify.error(this.$t('metrics_tab.search.notify_errors.no_special_characters'));
      this.$refs.managementPage.updateSearchTerm(searchTerm.replace(SPECIAl_CHAR,''));
      return;
    }

    this.params = {
      limit: this.searchPageSize,
      query: searchTerm,
      offset: searchOffset,
      date_type: this.datePickerOptions[datePickerOption],
    };

    this.$refs.customersTable.sort(this.params.date_type, 'ascending');

    if (dateRange) {
      if (dateRange[0]) {
        this.params.period_start = moment(dateRange[0]).unix();
      }
      if (dateRange[1]) {
        this.params.period_end = moment(dateRange[1]).unix();
      }
    }

    if (this.filterByAgent) {
      this.params.filter_agent_id = this.filterByAgent.id;
      this.params.filter_type = this.filterType;
    }

    if (customerAgentOption) {
      this.params.attribute = customerAgentOption in this.customerAgentOptions
        ? this.customerAgentOptions[customerAgentOption]
        : null;
    }

    if (customerChannelOption) {
      this.params.channel = customerChannelOption;
    }

    if (customerSegmentOption) {
      this.params.segments = customerSegmentOption;
    }

    if (customerProfileOption) {
      this.params.profile_field = customerProfileOption.field;
    }

    if (this.watchableTeamIds.length) {
      this.params.teams = this.watchableTeamIds;
    }
    return this.loadCustomers(this.params);
  }

  async loadAll() {
    this.isLoadingAll = true;
    while (this.isLoadingAll && this.customers.length < this.pagination.rowCount) {
      this.params.offset = this.pagination.offset + this.searchPageSize;
      await this.loadCustomers(this.params); // eslint-disable-line no-await-in-loop
    }
    this.stopLoadAll();
  }

  async stopLoadAll() {
    this.isLoadingAll = false;
    this.isLoading = false;
  }

  toggleOpen(isOpen) {
    this.isFilterOpen = isOpen;
  }

  downloadData() {
    downloadAsFile({
      dataType: 'table',
      data: this.customers.map(customer => ({
        fullname: this.getCustomerName(customer),
        email: this.getCustomerEmail(customer),
        joined_date: this.getFormattedJoinedDate(customer),
        last_chat_time: this.getFormattedLastChatTime(customer),
        customer_segment: this.getFormattedCustomerSegment(customer),
        primary_agent: this.getPrimaryAgentName(customer),
      })),
    });
  }

  async loadCustomers(params, refresh = true) {
    this.isLoading = true;
    const { customers, pagination } = await this.getCustomers({
      params,
      update: refresh && (get(params, 'offset') === 0),
    });
    this.actionInProgress = false;
    this.pagination = pagination;
    this.isLoading = false;
    return customers;
  }

  // Deprecated
  async removePrimaryAgentFromCustomers({ customers, message }) {
    await this.removePrimaryAgentFromSelectedCustomers({
      customers,
      message,
    });
    this.deselectAllCustomers();
  }

  updatePrimaryAgentFromCustomers({ customers, message, selectedOption, files }) {
    if (!selectedOption) {
      return this.updatePrimaryAgentFromSelectedCustomers({
        mode: 'remove',
        customers,
        message,
        files,
      });
    }

    const agent = this.agents.find(a => a.fullName === selectedOption);
    return this.updatePrimaryAgentFromSelectedCustomers({
      mode: 'replace',
      customers,
      message,
      files,
      agent,
    });
  }

  updateCustomerSegments({ customers, selectedOption, selectedMultiOption }) {
    return this.updateCustomerSegmentsAction({
      mode: selectedOption,
      segments: selectedMultiOption,
      customers,
    });
  }

  async updateAssignedAgents({ customers, selectedOption, selectedMultiOption }) {
    const agents = this.agents
      .filter(a => selectedMultiOption.includes(a.fullName))
      .map(a => a.id);
    return this.updateCustomerAssignedAgentsAction({
      mode: selectedOption,
      agents,
      customers,
    });
  }

  sendMessageToCustomers({ customers, message, selectedOption, files, expirationMessageDate }) {
    return this.sendMessageToSelectedCustomers({
      customers,
      message,
      files,
      channel: selectedOption,
      expirationMessageDate: expirationMessageDate ?
        moment(expirationMessageDate).utc().format('YYYY-MM-DD HH:mm:ss.SSSZ') :
        expirationMessageDate,
    });
  }

  sendSurveyToCustomers({ customers, message, selectedOption }) {
    return this.sendSurveyToSelectedCustomers({
      customers,
      message,
      surveyType: selectedOption,
    });
  }

  closeConversationToCustomers({ customers }) {
    return this.closeConversationToSelectedCustomers({ customers });
  }

  chooseAction(action) {
    if (this.actionOptions[action]) {
      this.chosenAction = action;
      this.actionFormShown = true;
    }
  }

  async commitAction({
    actionValue, items, message, selectedOption, selectedMultiOption, files, expirationMessageDate,
  }) {

    const customerList = actionValue.toLowerCase().includes('send message') ? this.getCustomerWithoutSelectedChannel(Object.values(items), selectedOption) : [];
    if (customerList.length) {
      const list = `<ul>${customerList.map(item => `<li>${item.id}</li>`).join('')}</ul>`;
      this.$aiq.confirm(
        this.$t('customer_dashboard.sending_message.error_title'),
          `${this.$t('customer_dashboard.sending_message.error_dialog', {selectedOption})}
          <br><br>
          ${list}`,
          { showCancelButton: false}
      );
      return;
    }
    this.chosenAction = null;

    this.actionInProgress = true;
    try {
      const res = await this.actionOptions[actionValue].commitActionFunction({
        customers: Object.values(items),
        message,
        files,
        selectedOption,
        selectedMultiOption,
        expirationMessageDate,
      });

      if (get(res, 'id')) {
        // the action will be running asynchronously.
        // and the given id is a job id
        this.$aiq.notify.info('The request accepted. You will be notified once the job is completed.');
      } else if (get(res, 'result')) {
        // The number of customers is small and the request is
        // completed
        this.$aiq.notify.success('Successful');
      } else {
        this.$aiq.notify.error('Unexpected response');
      }
    } catch (err) {
      this.$aiq.error.error('Failed');
    }

    this.actionInProgress = false;
    this.deselectAllCustomers();
    await this.loadCustomers(this.params);
  }

  getCustomerWithoutSelectedChannel(customers, channel) {
    return customers.filter(c => !c.conversation_channels.includes(channel));
  }

  removePreFilter() {
    this.filterByAgent = null;
    this.filterType = '';
    const query = Object.assign({}, this.$route.query);
    for (const key of ['agent-id', 'agent-name', 'filter-type']) {
      delete query[key];
    }
    this.$router.replace({ query });
  }
}
export default toNative(CustomersDashboard);
</script>

<style lang="scss" scoped>
@import "../../styles/aiq-mixins.scss";
@import "../../styles/media-queries.scss";

  .el-table--fit{
    height: calc(100% - 115px);
  }

  .status-indicator {
    &::before {
      margin-top: 1px;
    }
  }

  .agent-button {
    white-space: break-spaces;
    text-align: left;
    word-break: break-word;
    margin-left: 3px;
  }

  :deep(.aiq-table-totals) {
    .el-tag {
      .el-tag__close {
        color:#409eff;

        &:hover {
          color:white;
        }
      }
    }
  }

</style>

<style lang="scss">
@import "../../styles/aiq-mixins.scss";
@import "../../styles/media-queries.scss";

.customers-scrollbar {
  .el-table__body-wrapper {
    @include scrollable(calc(100vh - 247px));

    .date-button span{
      white-space: normal;
      word-break: break-all;
    }
  }
}
.add-edit-actions-panel {
  .el-dialog {
      @include mobile {
        min-width: 90%;
    }
  }
}
</style>
