<template lang="pug">
    .document-container
      aiq-document-icon(:mimeType="mimeType" :documentExtension="docExtension" :fileId="''+document.id")
      .document-content
        .document-title {{ name }}
        .inner-content(v-html="$filters.sanitize(highlight)")
      a.url__link(@click.prevent="onHandleView"
                  @click.stop
                  :href="url"
                  target="_blank") View
  </template>
<script>
import get from 'lodash/get';
import GetGlobalSource from '@/libs/mixins/GetGlobalSource';

export default {
  mixins: [GetGlobalSource],
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  computed: {
    highlight() {
      const highlight = get(this.document, 'payload.highlight["attachment.content"][0]', '');

      return `${highlight}&hellip;`;
    },
    name() {
      return get(this.document, 'payload._source.name', '');
    },
    url() {
      return get(this.document, 'payload._source.url', '');
    },
    filePayload() {
      // Getting it from two different location is not great but currently
      // suggested documents have the payload in different location.
      return get(this.document, 'file.payload', get(this.document, 'payload._source'));
    },
    docExtension() {
      const filename = get(this.filePayload, 'filename');
      return filename ? filename.split('.').pop() : '';
    },
    mimeType() {
      return get(this.filePayload, 'mime');
    },
  },
  methods: {
    async onHandleView() {
      const url = await this.getFileUrl(this.filePayload);
      if (url) {
        window.open(url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .document-container {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 3px 0 #bfcbd9;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    max-height: 93px;
    justify-content: space-evenly;
    line-height: 16px;
    overflow: hidden;
    padding: 12px 0;

    .document-content {
      width: 181px;

      .document-title {
        color: #324057;
        font-family: 'Museo Sans';
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .inner-content {
        color: #8492a5;
        font-size: 11px;
      }
      .url__link {
        line-height: 16px;
      }
    }
  }
</style>
