import merge from 'lodash/merge';
import { CRUDStoreBuilder } from '../StoreBuilder';

const routingAlias = 'settings.customerSegments';

const segmentsStore = {
  state: {
    selected: {},
  },

  getters: {
    segmentModel: () => ({
      name: null,
      payload: { team_ids: [], alias: null, automaticDLP: true },
    }),
  },

  mutations: {
    SELECT_SEGMENT(state, item) {
      state.selected = item;
    },
  },

  actions: {
    selectSegment({ commit }, item) {
      commit('SELECT_SEGMENT', item);
      return item;
    },
  },
};

export default merge(
  new CRUDStoreBuilder(
    routingAlias,
    'segment',
    [],
    'data.models',
    true,
  ).createStore(),
  segmentsStore,
);
