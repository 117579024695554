<template lang="pug">
  management-page.scrollable(title="Summary"
                :creatable="false"
                :searchable="false"
                :showHeader="false")
    template(v-slot:body)
      .chart-configuration
        .members-filter
        aiq-button-group.breakdown-options
          aiq-button(v-for="option in filteredBreakdownOptions"
                    :key="option.value"
                    size="small"
                    :class="{'selected': selectedBreakdownOption.value === option.value}"
                    @click="setBreakdown(option.value)"
                    ) {{ option.label}}
        .chart-toolbar(v-if="ONBOARDING_METRICS_FILTERS")
          aiq-row(:gutter="16")
            .dropdown-filters
              .dropdown-filter
                segment-select(:customer-segment-filter-options="CustomerSegmentFilterOptions"
                      :segment-alias="segmentAlias"
                      is-select-multiple
                      is-select-collapse-tags
                      @change="onCustomerSegmentFilterChange"
                      @on-segment-hover="onSegmentHover")
        .breakdown
        .time-range-filter
          aiq-date-picker(v-model="dateRange"
                          @change="handleDatePickerChange($event)"
                          type="daterange"
                          placeholder="Select date range",
                          popper-class="metrics-time-range__date-picker-popup"
                          :shortcuts="datePickerOptions.shortcuts"
                          :disabled-date="datePickerOptions.disabledDate"
                          :teleported="false"
                          format="YYYY/MM/DD"
                          :clearable="false")
      .chart-grid.onboard-grid
        aiq-row(:gutter="16", type="flex")
          aiq-col(:span="12")
              overview-chart(v-if="configs.onboarding_progress"
                  :dateRange="dateRangeUtc"
                  :breakdown="selectedBreakdownOption.value"
                  :filters="selectedGraphFilters"
                  :chartConfig="joinedProgressChartConfig"
                  :formatXTick="selectedBreakdownOption.xAxisFormat"
                  :formatYTick="intCountFormatter"
                  :formatY2Tick="percentFormatter"
                  yLabel="Count"
                  :y2Color="y2ColorSetter"
                  grouped
                  @forwardedClickedDataPoint='setFunnelDatesToClickedXvalues($event)'
                  :useDebounceForLoadMetrics="true"
                  )
          aiq-col(:span="12")
            .category-chart
                overview-chart(v-if="configs.onboarding_progress"
                              :dateRange="datesForFunnel"
                              :formatYTick="intCountFormatter"
                              :filters="selectedGraphFilters"
                              :breakdown="selectedBreakdownOption.value"
                              :chartConfig="onboardingProgressChartConfig"
                              yLabel="Count"
                              visualization="aiq-category-bar-per-line-chart"
                              :useDebounceForLoadMetrics="true"
                              )
      aiq-tabs(@tab-click="onTabChange")
          aiq-tab-pane(v-for="tab in OnboardingTableConfigs"
                      :key="tab.tabName"
                      :label="tab.label")
              .onboarding-table-toolbar
                .filters
                  .dropdown-onboarding
                      aiq-select(placeholder="Messages Sent"
                                               v-model="messageFilterOptions"
                                               collapse-tags
                                               @change="applyMessageFilter"
                                               clearable
                                               value-key='id'
                                               size="small")
                                aiq-option(v-for="option in filterOptions"
                                            :key="option.id"
                                            :label="option.name"
                                            :value="option")
                  .dropdown-onboarding
                      aiq-select(placeholder="Primary Agent"
                                               v-model="bankerFilterOptions"
                                               collapse-tags
                                               @change="applyPBFilter"
                                               clearable
                                               value-key='id'
                                               size="small")
                                aiq-option(v-for="option in filterOptions"
                                            :key="option.id"
                                            :label="option.name"
                                            :value="option")
                  .dropdown-onboarding
                      aiq-select(placeholder="Push Notifications"
                                               v-model="pushFilterOptions"
                                               collapse-tags
                                               @change="applyPNFilter"
                                               clearable
                                               value-key='id'
                                               size="small")
                                aiq-option(v-for="option in filterOptions"
                                            :key="option.id"
                                            :label="option.name"
                                            :value="option")
                  .flex-spacer
                    aiq-button.export(@click="exportTable")
                      i.iq-ico-export

              .onboarding_table
                aiq-table.scrollable(v-if="selectedTableConfig.tabName=='onboarding_table'"
                                    :data="tableData"
                                    v-loading="loading")
                      aiq-table-column(label="Name", :min-width="13",:sortable="true", :sort-method="onSortCustomerName", label-class-name="is-bordered")
                                        template(v-slot:default="scope") &nbsp;
                                            span {{ getFormattedRow(scope.row, 'customers_full_name') }} &nbsp;
                      aiq-table-column(label="Email", :min-width="13",:sortable="true", :sort-method="onSortCustomerEmail", label-class-name="is-bordered")
                                        template(v-slot:default="scope") &nbsp;
                                            span {{ getFormattedRow(scope.row, 'customer_email') }} &nbsp;
                      aiq-table-column(label="Primary Agent", :min-width="13",:sortable="true", :sort-method="onSortAgentName", label-class-name="is-bordered")
                                        template(v-slot:default="scope") &nbsp;
                                            span {{ getFormattedRow(scope.row, 'agents_full_name') }} &nbsp;
                      aiq-table-column(label="Start Date", :min-width="15",:sortable="true", :sort-method="onSortStartDate", label-class-name="is-bordered")
                                        template(v-slot:default="scope") &nbsp;
                                            span {{ getFormattedTimeRow(scope.row, 'joined_date') }} &nbsp;
                      aiq-table-column(label="Last Date", :min-width="15",:sortable="true", :sort-method="onSortEndDate", label-class-name="is-bordered")
                                        template(v-slot:default="scope") &nbsp;
                                            span {{ getFormattedTimeRow(scope.row, 'end_date') }} &nbsp;

                      aiq-table-column(label="Push Notifications", :min-width="13",:sortable="true", :sort-method="onSortPush", label-class-name="is-bordered")
                                        template(v-slot:default="scope") &nbsp;
                                          span {{ getCapitalizedRow(scope.row, labelledResponseFieldsForTable.push_notify) }} &nbsp;

                      aiq-table-column(label="Chose Agent", :min-width="13",:sortable="true", :sort-method="onSortSelection", label-class-name="is-bordered")
                                        template(v-slot:default="scope") &nbsp;
                                          span {{ getCapitalizedRow(scope.row, labelledResponseFieldsForTable.banker_chosen) }} &nbsp;

                      aiq-table-column(label="Sent Message", :min-width="13",:sortable="true", :sort-method="onSortMsg", label-class-name="is-bordered")
                                        template(v-slot:default="scope") &nbsp;
                                          span {{ getCapitalizedRow(scope.row, labelledResponseFieldsForTable.message_sent) }} &nbsp;




</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import OverviewChart from '@/components/Chart.vue';
import SegmentSelect from '@/components/SegmentsSelect/SegmentsSelect.vue';
import get from 'lodash/get';
import has from 'lodash/has';
import pickBy from 'lodash/pickBy';
import concat from 'lodash/concat';
import capitalize from 'lodash/capitalize';
import { downloadAsFile } from '@/libs';
import {
  datePickerDefaultOptions,
  selectedBreakdownOption,
  setToEndOfDay,
  defaultDateRange,
  filterBreakdownOptions,
  getNonReactiveDateRange,
  dstDateRangeUtc
} from '../../configs/dateRange';
import { textFormatter, percentFormatter, intTickFormatter, onSort } from '../../helpers/formatters';
import { upperFirstStrings } from '@/libs/profile';
import { dropDownToSelected } from '../../helpers/filterHelper';

// todo register colors globally from aiq-components
const COLORS = {
  red: '#FF6384',
  orange: '#FFBE50',
  yellow: '#FFEC71',
  grey: '#D7D9DE',
  blue: '#4671B3',
  green: '#4BC0C0',
  steel_blue: '#4671b3',
  steel_blue_2: '#4785b6',
  celestial_blue: '#4999ba',
  verdigris: '#4aacbd',
  medium_turquoise: '#4bc0c0',
  medium_turquoise_2: '#4bc0c6',
};

const loginProgressLinesConfigs = {
  login_count: { label: 'Number of people who joined',
    categoryLabel: 'Joined',
    color: COLORS.celestial_blue },
  push_auth_count: { label: 'Number of people who enabled push notifications',
    categoryLabel: 'Enabled Push Notifications',
    color: COLORS.verdigris },
  banker_chosen_count: { label: 'Number of people who chose a agent',
    categoryLabel: 'Chose Agent',
    color: COLORS.medium_turquoise },
  msg_sent_count: { label: 'Number of people who started sending messages',
    categoryLabel: 'Sent Message',
    color: COLORS.medium_turquoise_2 },
};


const OnboardingTableConfigs = [
  {
    tabName: 'onboarding_table',
    label: 'Onboarding',
    endpoint: 'per_customer_onboarding_progress',
    default: true,
  },
];

const joinedProgressChartConfig = {
  name: 'login_progress',
  metric: 'login_progress',
  lines: { login_count: loginProgressLinesConfigs.login_count },
  title: 'Onboarding Count',
  helpText:
    'Displays the onboarding count and onboarding ratio.',
};

const onboardingProgressChartConfig = {
  name: 'onboarding_progress',
  metric: 'login_progress',
  lines: loginProgressLinesConfigs,
  helpText:
    'Displays the count, for various onboarding steps, over a timeframe. <br/><b><i>Tip</i></b>: Chose Agent is the count for carousel selections only.',
  graphParams: {
    block: {
      dynamicHeight: true,
      minHeight: 15,
      width: 13,
      fill: { scale:
          Object.values(loginProgressLinesConfigs).map(stageDetails => stageDetails.color),
      type: 'solid',
      },
    },
  },
};

export default {
  pageResource: '/metrics',
  components: {
    ManagementPage,
    OverviewChart,
    SegmentSelect,
  },
  props: {
    filterOptions: {
      type: Array,
      default: () => [
        { id: 1, name: 'Yes' },
        { id: 2, name: 'No' },
      ],
    },
  },
  data() {
    const defaultTableConfig = OnboardingTableConfigs.find(d => d.default);

    const chartConfigs = [
      joinedProgressChartConfig,
      onboardingProgressChartConfig,
    ];

    for (const config of chartConfigs) {
      config.title = this.$t(`metrics_tab.customer_tab.onboard_tab.${config.name}.title`, config.title);
    }

    return {
      joinedProgressChartConfig,
      onboardingProgressChartConfig,
      OnboardingTableConfigs,
      defaultTableConfig,
      selectedTableConfig: defaultTableConfig,
      tableData: [],
      eventsFilteringStore: {},
      filterResponseFieldsForBar: [],
      filterResponseFieldsForFunnel: [],
      labelledResponseFieldsForTable: {
        login: 'login_bool',
        message_sent: 'msg_sent_bool',
        push_notify: 'push_auth_bool',
        banker_chosen: 'banker_chosen_bool' },
      funnelDateRange: defaultDateRange(),
      selectedTableFilters: [],
      selectedGraphFilters: [],
      y2ColorSetter: COLORS.medium_turquoise_2,
      percentFormatter,
      intCountFormatter: intTickFormatter,
      tableRowFormatter: textFormatter,
      durationFormatter: this.$aiq.formatters.timeDelta,
      dateRange: defaultDateRange(),
      selectedBreakdownOption: selectedBreakdownOption(),
      loading: true,
      messageFilterOptions: '',
      bankerFilterOptions: '',
      pushFilterOptions: '',
      segmentAlias: '',
    };
  },
  computed: {
    ...mapGetters({
      configs: 'configs/graphsConfigs',
      CustomerSegmentFilterOptions: 'configs/segments',
    }),
    ...mapState({
      // eslint-disable-next-line max-len
      ONBOARDING_METRICS_FILTERS: state => state.featureFlags.FEATURE_FLAGS.ONBOARDING_METRICS_FILTERS,
    }),
    datePickerOptions() {
      return datePickerDefaultOptions();
    },
    dateRangeUtc() {
      return dstDateRangeUtc(this.dateRange);
    },
    datesForFunnel() {
      // TODO (Akshay) format based on calendar or click case
      return this.funnelDateRange.map(date => moment(date).unix());
    },
    // TODO: Create a mixin that has this computed value,
    // along with other common properties and functions in metrics
    filteredBreakdownOptions() {
      return filterBreakdownOptions(this.dateRange);
    },
  },
  watch: {
    dateRange: debounce(function onDateRangeChange(newValue, oldValue) {
      if (newValue !== oldValue) {
        // since calendar date was changed, funnel chart dates should fallback to calendar dates
        this.setFunnelDateRange(this.dateRange);
        this.loadTableMetrics();
      }
    }, 1),
    filteredBreakdownOptions(newOptions) {
      if (!newOptions.find(o => o.value === this.selectedBreakdownOption.value)) {
        this.selectedBreakdownOption = newOptions.find(o => o.isDefault);
      }
    },
  },
  mounted() {
    this.loadTableMetrics();
    this.$store.dispatch('configs/getCustomerSegments');
  },
  methods: {
    setSelectedGraphFilters(filterName, chosenFilters) {
      this.selectedGraphFilters = dropDownToSelected(chosenFilters,
        this.selectedGraphFilters,
        filterName);
    },
    onCustomerSegmentFilterChange(chosenFilters) {
      this.setSelectedGraphFilters('customer_segments', chosenFilters);
      this.updateAndLoadTableFilters();
    },
    onSortCustomerName(a, b) {
      const aUPPER = upperFirstStrings(get(a, ['customers_full_name'], ''));
      const bUPPER = upperFirstStrings(get(b, ['customers_full_name'], ''));
      return onSort(aUPPER, bUPPER);
    },
    onSortCustomerEmail(a, b) {
      const aUPPER = upperFirstStrings(get(a, ['customer_email'], ''));
      const bUPPER = upperFirstStrings(get(b, ['customer_email'], ''));
      return onSort(aUPPER, bUPPER);
    },
    onSortAgentName(a, b) {
      const aUPPER = upperFirstStrings(get(a, ['agents_full_name'], ''));
      const bUPPER = upperFirstStrings(get(b, ['agents_full_name'], ''));
      return onSort(aUPPER, bUPPER);
    },
    onSortStartDate(a, b) {
      const adate = get(a, ['joined_date'], '');
      const bdate = get(b, ['joined_date'], '');
      return onSort(adate, bdate);
    },
    onSortEndDate(a, b) {
      const adate = get(a, ['end_date'], '');
      const bdate = get(b, ['end_date'], '');
      return onSort(adate, bdate);
    },
    onSortSelection(a, b) {
      const aval = get(a, [this.labelledResponseFieldsForTable.banker_chosen], '');
      const bval = get(b, [this.labelledResponseFieldsForTable.banker_chosen], '');
      return onSort(aval, bval);
    },
    onSortPush(a, b) {
      const aval = get(a, [this.labelledResponseFieldsForTable.push_notify], '');
      const bval = get(b, [this.labelledResponseFieldsForTable.push_notify], '');
      return onSort(aval, bval);
    },
    onSortMsg(a, b) {
      const aval = get(a, [this.labelledResponseFieldsForTable.message_sent], '');
      const bval = get(b, [this.labelledResponseFieldsForTable.message_sent], '');
      return onSort(aval, bval);
    },
    storeEventsForFiltering(chosenFilterOption, eventName) {
      if (isEmpty(chosenFilterOption)) {
        // eslint-disable-next-line max-len
        has(this.eventsFilteringStore, eventName) ? delete this.eventsFilteringStore[eventName] : null;
      } else if (chosenFilterOption.name === 'Yes') {
        this.eventsFilteringStore[eventName] = true;
      } else {
        this.eventsFilteringStore[eventName] = false;
      }
    },
    makeEventFilterPayload() {
      if (isEmpty(this.eventsFilteringStore)) {
        return [];
      }
      const filter = { };
      const eventsToShow = Object.keys(pickBy(this.eventsFilteringStore, value => value === true));
      !isEmpty(eventsToShow) ? filter.show = eventsToShow : null;
      const eventsToHide = Object.keys(pickBy(this.eventsFilteringStore, value => value === false));
      !isEmpty(eventsToHide) ? filter.hide = eventsToHide : null;
      return [{ onboarding_filter: filter }];
    },
    updateSelectedTableFilters() {
      // the assumption below is that there is only one segments filter on the page
      // we combine table specific filters with graph filters
      this.selectedTableFilters = concat(this.selectedGraphFilters, this.makeEventFilterPayload());
    },
    updateAndLoadTableFilters() {
      this.updateSelectedTableFilters();
      this.loadTableMetrics();
    },
    makeAndApplyFilterForEvent(dropDownOption, eventName) {
      this.storeEventsForFiltering(dropDownOption, eventName);
      this.updateAndLoadTableFilters();
    },
    applyMessageFilter(chosenFilterOption) {
      this.makeAndApplyFilterForEvent(chosenFilterOption, 'msg_sent');
    },
    applyPBFilter(chosenFilterOption) {
      this.makeAndApplyFilterForEvent(chosenFilterOption, 'banker_chosen');
    },
    applyPNFilter(chosenFilterOption) {
      this.makeAndApplyFilterForEvent(chosenFilterOption, 'push_auth');
    },
    exportTable() {
      downloadAsFile({
        dataType: 'table',
        dataSource: 'metrics',
        data: this.tableData,
      });
    },
    setFunnelDatesToClickedXvalues(clickedDatapoint) {
      // eslint-disable-next-line max-len
      this.setFunnelDateRange(this.selectedBreakdownOption.dateRangeWhenLineClicked(clickedDatapoint.x));
    },
    getFormattedTimeRow(datapoint, rowName) {
      const time_epoch = get(datapoint, rowName, '');
      if (!time_epoch) {
        return '';
      }
      return moment(time_epoch).format('YYYY MMM DD ha');
    },
    setFunnelDateRange(dateRange) {
      this.funnelDateRange = dateRange;
    },
    getFormattedRow(datapoint, rowName) {
      const value = get(datapoint, rowName, '');
      // value can be 0 here
      return value === '' ? value : this.tableRowFormatter(value);
    },
    getCapitalizedRow(datapoint, rowName) {
      const value = this.getFormattedRow(datapoint, rowName);
      return capitalize(value);
    },
    setBreakdown(value) {
      this.selectedBreakdownOption = this.filteredBreakdownOptions.find(
        option => option.value === value,
      );
      // since bucket was changed, funnel dates falling back to calendar dates
      this.setFunnelDateRange(this.dateRange);
    },
    onTabChange({index: tabIndex}) {
      this.selectedTableConfig = this.OnboardingTableConfigs[tabIndex];
      this.loadTableMetrics();
    },
    loadTableMetrics() {
      const periodStart = moment(this.dateRange[0]).unix();
      const periodEnd = moment(this.dateRange[1]).unix();
      if (!(periodStart && periodEnd && this.selectedBreakdownOption)) {
        return;
      }
      const params = {
        period_start: periodStart,
        period_end: periodEnd,
        bucket: this.selectedBreakdownOption.value,
      };
      if (!isEmpty(this.selectedTableFilters)) {
        params.filters = this.selectedTableFilters;
      }
      return this.$store
        .dispatch('metrics/getMetric', [this.selectedTableConfig.endpoint, params])
        .then(data => {
          this.loading = false;
          this.tableData = get(data, 'dataset.0.data_points', []);
        });
    },
    handleDatePickerChange(newDateRange) {
      this.dateRange = setToEndOfDay(getNonReactiveDateRange(newDateRange));
    },
    onSegmentHover(segment) {
      this.segmentAlias = get(segment, 'alias', '');
    },
  },
};
</script>

<style lang="scss">
.onboard-grid {
  .el-card {
    height: 100%;
  }
}
.onboarding-table-toolbar {
  .el-button.export {
    height: 30px;
    width: 40px;
    padding: 0;
    margin-left: 16px;

    &:after {
      clear: both;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "../../../../styles/aiq-variables.scss";

.scrollable {
  overflow-y: auto;
}

.chart-container {
  height: 40rem;
  width: 60rem;
}

.el-card:not(:first-of-type) {
  margin-top: 24px;
}

.chart-configuration {
  display: flex;
  margin-bottom: 24px;

  .members-filter {
    display: flex;
    align-items: center;
  }
  .category-chart {
      margin-bottom: 20px;
  }
  .iq-ico-explanation::before {
      width: 1rem;
      height: 1rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      margin-top: 0.45rem;
      display: inline-block;
      vertical-align: middle;
  }
  .breakdown {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  .export-command {
    display: flex;
    height: 30px;
    width: 40px;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    padding: 0;
  }
}

.onboarding-table-toolbar {
    display: flex;
    margin-bottom: 12px;
    justify-content: flex-end;
    .filters{
        display: flex;
        .dropdown-onboarding{
            margin-left: 12px;
            width: 10em;
            height: 25px;
        }
    }
    .flex-spacer {
      flex-grow: 1
    }
  }
.chart-toolbar {
  display: flex;
  margin-bottom: 20px;
  margin-left: 30px;
  .dropdown-filters {
    display: flex;
    align-items: flex-start;
    width: 60%;

    .dropdown-filter {
      margin-right: 12px;
      width: 40em;
      height: 30px;
      :deep(.el-select) {
        width: 75%;
      }
    }
  }
  .flex-spacer {
    flex-grow: 1;
  }
}

.time-range-filter {
  position: relative;
}
</style>
