<template lang="pug">
.document-integration
  .view-data-ready(v-if="integration")
    .auth-challenge(v-if="!authenticated")
      document-sign-authentication(:integration="integration"
                                   :config="authConfig"
                                   @authenticated="onAuthenticated"
                                   @failure="onFailed")
    .document-ui(v-else)
      aiq-dialog(:model-value="true" :title="$filters.toUpperFirst(integration)" :before-close="onClose")
        docu-sign-main(v-if="integration === 'docusign'" @close="onClose" @sign-message="onSignMessageRecieved" :customer="customer")
        adobe-sign-main(v-if="integration === 'adobesign'" @close="onClose" @sign-message="onSignMessageRecieved" :customer="customer")
</template>

<script>
import * as log from 'loglevel';
import { Component, Vue, toNative } from 'vue-facing-decorator';
import { DOCUMENT_INTEGRATION } from '@/constants/integration';
import DocumentSignAuthentication from './DocumentSignAuthentication.vue';
import DocuSignMain from './DocuSignMain.vue';
import AdobeSignMain from './AdobeSignMain.vue';

@Component({
  name: 'DocumentIntegrationDialog',
  components: {
    DocumentSignAuthentication,
    DocuSignMain,
    AdobeSignMain,
  },
  props: {
    customer: {
      type: Object,
      default: {},
    },
  },
  emits: ['close', 'sign-message'],
})
class DocumentIntegrationDialog extends Vue {
  integration = null;
  authConfig = null;
  authenticated = false;

  async mounted() {
    try {
      const res = await Promise.all([
        this.$store.dispatch('integrations/getIntegration', { name: DOCUMENT_INTEGRATION.DOCU_SIGN }),
        this.$store.dispatch('integrations/getIntegration', { name: DOCUMENT_INTEGRATION.ADOBE_SIGN }),
      ]);

      if (res[0].enabled) {
        this.integration = DOCUMENT_INTEGRATION.DOCU_SIGN;
        this.authConfig = res[0].oauth;
      } else if (res[1].enabled) {
        this.integration = DOCUMENT_INTEGRATION.ADOBE_SIGN;
        this.authConfig = res[1].oauth;
      }

      log.debug(`Use ${this.integration}`);
    } catch (err) {
      this.$aiq.notify.error(err.message);
      this.$emit('close');
    }
  }

  onAuthenticated() {
    this.authenticated = true;
  }

  onFailed(/* err */) {
    this.authenticated = false;
    this.$emit('close');
  }

  onClose(done) {
    this.$emit('close');
    if (done) {
      done();
    }
  }

  onSignMessageRecieved(payload) {
    this.$emit('sign-message', payload);
  }
}
export default toNative(DocumentIntegrationDialog);
</script>


<style lang="scss" scoped>
</style>
